/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { createRef, useEffect, useState } from 'react';
import clsx from 'clsx';
import backIcon from 'public/images/Chevron_Left.svg';
import { LOGIN_LANDING } from 'router/constants';
import { Logo, Footer } from 'components';
import { useWindowSize } from 'utils/hooks';
import {
  rightPanelCss,
  goBackCss,
  goBackTextCss,
  leftComponentContainerCss,
  logoContainerCss,
  logoCss,
  layoutPageContainerCss,
  layoutRowContainerCss,
  footerContainerCss,
} from './styles';
import type { FooterPropsInterface } from 'models/components/footer';
import BackgroundVideo from 'components/background-video';
import { DeviceTypeEnum } from 'models/enums';

interface LayoutClassNamesInterface {
  rightPanelClassName: string;
}

interface PropsInterface {
  AppBarComponent?: React.ReactNode;
  LeftComponent?: React.ReactNode;
  auth?: boolean;
  backButtonText?: string;
  className?: LayoutClassNamesInterface;
  footerFixed?: boolean;
  footerProps?: FooterPropsInterface;
  fullWidth?: boolean;
  hasSplashAnimation?: boolean;
  onBack?: any;
  showBack?: boolean;
  showTopBack?: boolean;
  styles?: any;
}

const LayoutContainer: React.FC<PropsInterface> = ({
  children,
  auth = false,
  showTopBack = false,
  showBack = false,
  fullWidth = false,
  onBack,
  LeftComponent,
  AppBarComponent,
  styles,
  className,
  footerProps,
  hasSplashAnimation = false,
  backButtonText = 'Go Back',
  footerFixed = true,
}) => {
  const windowSize = useWindowSize();
  const footerRef = createRef<HTMLElement>();
  const [footerHeight, setFooterHeight] = useState<number>();

  useEffect(() => {
    if (!footerProps?.isHidden && footerRef.current) {
      setFooterHeight(footerRef.current.clientHeight);
    }
  }, [footerProps, footerRef]);

  const renderLogo = () => {
    // only show splash for desktop, this will break the layout on tablet and mobile
    // the left component acts as the appbar on tablet and mobile
    if (hasSplashAnimation && windowSize.isDesktop) {
      return (
        <BackgroundVideo
          device={DeviceTypeEnum.DESKTOP}
          isHiddenOnComplete={false}
        />
      );
    } else {
      return (
        <Logo
          containerStyles={logoContainerCss(styles)}
          horizontal={windowSize.isMobile || windowSize.isTablet}
          logoStyles={logoCss}
          linkTo={LOGIN_LANDING}
        />
      );
    }
  };

  return (
    <div
      className="page"
      css={[layoutPageContainerCss, styles?.layoutPageContainerCss]}
    >
      {AppBarComponent}
      <div
        className="row"
        css={layoutRowContainerCss(fullWidth, styles)}
      >
        {!fullWidth && (
          <div
            className={clsx(
              'left-panel col-sm-12 col-md-12 col-lg-7',
              auth && 'center-sm start-md center-lg middle-lg',
            )}
            css={leftComponentContainerCss(styles)}
          >
            {auth && renderLogo()}
            {showTopBack && (
              <div
                className="go-back-btn go-back-btn--top"
                css={goBackCss(styles)}
              >
                <img src={backIcon} />
                <span
                  css={goBackTextCss(styles)}
                  onClick={onBack}
                >
                  {backButtonText}
                </span>
              </div>
            )}
            {LeftComponent}
          </div>
        )}
        <div
          className={clsx([
            'right-panel col-sm-12',
            !fullWidth && 'col-md-8 col-lg-5',
            className?.rightPanelClassName,
          ])}
          css={
            footerHeight &&
            rightPanelCss(
              {
                isFullWidth: fullWidth,
                footerHeight,
              },
              styles,
            )
          }
        >
          {showBack && (
            <div
              className="go-back-btn"
              css={goBackCss(styles)}
            >
              <img src={backIcon} />
              <button
                onClick={onBack}
                css={goBackTextCss(styles)}
              >
                {backButtonText}
              </button>
            </div>
          )}
          {children}
          {!footerProps?.isHidden && (
            <Footer
              styles={{
                footerContainerCss:
                  footerHeight &&
                  footerContainerCss(
                    {
                      isFullWidth: fullWidth,
                      footerHeight,
                    },
                    footerProps?.styles,
                  ),
              }}
              fixed={footerFixed}
              ref={footerRef}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default LayoutContainer;
