/** @jsx jsx */
import { jsx } from '@emotion/core';
import type { NSEntitlementItemInterface } from 'models/cms';
import React from 'react';
import {
  actionWrapperCss,
  wrapperCss,
  titleCss,
  nsImageContainerCss,
  videoActionsWrapperCss,
  runtimeRatingCss,
} from './styles';
import NsImage from 'components/ns-component/ns-image';
import clock from 'public/images/Clock.svg';
import { minsToTime } from 'utils';
import ExpiryDate from 'components/expiry-date';
import type { VideoActionsNdaInterface } from 'components/video-actions';
import VideoActions from 'components/video-actions';

interface PropsInterface {
  item: NSEntitlementItemInterface;
  ndaProps: VideoActionsNdaInterface;
}

const HeroEntitlement: React.FC<PropsInterface> = ({ item, ndaProps }) => {
  return (
    <div css={wrapperCss}>
      <NsImage
        alt={item.heroImage.alt}
        aspectRatio={'16/9'}
        url={item.heroImage.url}
        width={1024}
      />
      <div
        className="actionWrapper"
        css={actionWrapperCss}
      >
        <div css={titleCss}>
          {item.titleTreatmentImage ?
            <NsImage
              alt={item.titleTreatmentImage.alt}
              aspectRatio={item.titleTreatmentImage.aspectRatioFractions[0]}
              url={item.titleTreatmentImage.url}
              width={1024}
              styles={{ nsImageContainerCss }}
            />
          : item.title}
        </div>
        <div
          className="runtime-rating"
          css={runtimeRatingCss}
        >
          {typeof item.runtime === 'number' && item.runtime > 0 && (
            <span className="runtime-info">
              <img src={clock} />
              <span>{minsToTime(item.runtime)}</span>
            </span>
          )}
          {item.rating && <span className="rating">{item.rating}</span>}
        </div>
        <div>
          {/* @ts-ignore TODO: TS2322: Type NSEntitlementInterface | null | undefined is not assignable to type EntitlementInterface. */}
          <ExpiryDate entitlement={item.entitlement} />
        </div>
        <div
          className="videoActionsWrapper"
          css={videoActionsWrapperCss}
        >
          <VideoActions
            component={item}
            ndaProps={ndaProps}
            progressData={{ error: null }}
          />
        </div>
      </div>
    </div>
  );
};

export default HeroEntitlement;
