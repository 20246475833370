import React from 'react';
import styled from '@emotion/styled';
import theme from 'styles/theme';
import Typography from './typography';

const StyledLoader = styled.div`
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;
  position: fixed;
  z-index: ${theme.zIndex.loader};
  height: 100%;
  width: 100%;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  :before {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
  }

  .loader {
    border: 8px solid ${theme.palette.common.white}; /* Light grey */
    border-top: 8px solid ${theme.palette.primary.main}; /* Blue */
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 2s linear infinite;
  }

  .typography-default {
    position: relative;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

interface LoaderPropsInterface {
  text?: string;
}

const Loader: React.FC<LoaderPropsInterface> = ({ text = 'Loading' }) => {
  return (
    <StyledLoader>
      <div
        data-testid="loader-icon"
        className="loader"
      />
      {text && <Typography>{text}</Typography>}
    </StyledLoader>
  );
};

export default Loader;
