/** @jsx jsx */
import { EntitlementTypeEnum } from 'models/enums';
import React from 'react';
import { jsx } from '@emotion/core';
import {
  wrapperCss,
  contentCss,
  imgWrapperCss,
  rightCss,
  titleCss,
  nsImageExtendedCss,
  timeNratingCss,
  timeCss,
  ratingCss,
  clockCss,
  expiryWrapperCss,
  videoActionWrapperCss,
  progressWrapperCss,
  leftProxisMsgCss,
  leftCss,
} from './styles';
import NsImage from 'components/ns-component/ns-image';
import clock from 'public/images/Clock.svg';
import { minsToTime } from 'utils';
import ExpiryDate from 'components/expiry-date';
import { usePlayer } from 'providers/player-provider';
import Loader from 'components/loader';
import ExpiredModal from 'components/expired-modal';
import ProgressBar from 'components/progress-bar';
import { progressDataSelector } from 'store/slices/progress';
import { useSelector } from 'react-redux';
import VideoActions from 'components/video-actions';
import type { LoadStateInterface } from 'hocs/with-nda-service';
import { showProxisMsg } from '../helpers';

export interface NDAPropsInterface {
  handleAction?(templateId: string): Promise<any>;
  isLocked?: boolean;
  isNdaOpen: boolean;
  loadState?: LoadStateInterface;
  setIsNdaOpen: (bool: boolean) => void;
  text: string;
}

interface PropsInterface {
  data: any;
  hasPlain: boolean;
  ndaProps?: NDAPropsInterface;
}

const LargeBanner: React.FC<PropsInterface> = ({
  data,
  ndaProps,
  hasPlain,
}) => {
  const { isCheckingExpired, isExpired } = usePlayer();
  const progressData = useSelector(progressDataSelector);

  const componentType = data?.type.toLowerCase();

  return (
    <div css={wrapperCss}>
      <div css={contentCss(hasPlain)}>
        {!hasPlain && (
          <div css={leftCss}>
            <div css={imgWrapperCss}>
              <NsImage
                url={data.keyArtImage?.url}
                width={1500}
                aspectRatio={'16/9'}
                isBackground
                hasGradientOverlay={false}
              />
            </div>
            {showProxisMsg(data.entitlement?.tags) && (
              <div css={leftProxisMsgCss}>
                For the highest quality of this content, please watch on the
                Debut app available on Apple tvOS
              </div>
            )}
          </div>
        )}
        <div css={rightCss}>
          <div
            css={titleCss(hasPlain)}
            data-testid="title-treatment-item"
          >
            {data?.titleTreatmentImage ?
              <NsImage
                alt={data.titleTreatmentImage?.alt}
                aspectRatio={
                  data.titleTreatmentImage?.aspectRatioFractions[0] || '3/2'
                }
                url={data.titleTreatmentImage?.url}
                width={1024}
                styles={nsImageExtendedCss}
              />
            : data.title}
          </div>
          <div css={timeNratingCss}>
            {componentType !== 'series' && (
              <div css={timeCss}>
                <div css={clockCss}>
                  <img src={clock} />
                </div>
                <div className="time-text">{minsToTime(data.runtime)}</div>
              </div>
            )}

            {data.rating && (
              <div
                data-testid="rating"
                css={ratingCss}
              >
                {data.rating}
              </div>
            )}
          </div>
          <div css={expiryWrapperCss}>
            {isCheckingExpired && <Loader />}
            <ExpiredModal isOpen={isExpired} />
            <ExpiryDate entitlement={data.entitlement} />
          </div>
          <div
            className="progressWrapper"
            css={progressWrapperCss}
          >
            {componentType !== EntitlementTypeEnum.SERIES ?
              data.bookmark &&
              data.bookmark?.progress > 0 &&
              data.bookmark?.progress < 0.96 && (
                <ProgressBar
                  progress={progressData[data.bookmark.docId]?.progress ?? 0}
                  fullWidth={true}
                />
              )
            : null}
          </div>
          <div
            className="videoAction"
            css={videoActionWrapperCss(hasPlain)}
          >
            {componentType !== EntitlementTypeEnum.SERIES && (
              <VideoActions
                component={data}
                progressData={progressData}
                ndaProps={ndaProps}
                icon={true}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LargeBanner;
