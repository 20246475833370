import { css } from '@emotion/core';
import { mediaQuery, generateStyle } from 'utils/styles';
import theme from 'styles/theme';

export const containerCss = (styles) => {
  const defaultStyle = `
  margin-top: 2.8rem;
  position: relative;
  width: 100%;

  ${mediaQuery(theme.breakpoints.md)} {
    margin-top: 2.6rem;
    width: 20.6rem;
  }
  ${mediaQuery(theme.breakpoints.lg)} {
    padding: 0;
    margin-top: 2.4rem;
  }
`;
  return generateStyle({
    defaultStyle,
    extendedStyles: styles,
    key: 'containerCss',
    theme,
  });
};

export const inputCss = (theme, hasError, styles?) => {
  const defaultStyle = `
    width: 100%;
    height: 44px;
    padding: 0 3rem 0 1rem;
    color: ${theme.palette.text.primary};
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.1);
    border: ${hasError ? `1px solid ${theme.palette.text.error}` : 'none'};
    &:focus {
      border: 1px solid ${theme.palette.primary.main};
    }
    font-weight: 100;
    letter-spacing: 0.1;
  `;
  return generateStyle({
    defaultStyle,
    extendedStyles: styles,
    key: 'inputCss',
    theme,
  });
};

export const errorTextCss = (theme) => ({
  fontSize: '0.75rem',
  color: theme.palette.text.error,
});
export const labelCss = css`
  display: block;
  margin-bottom: 2rem;
`;
export const iconCss = css`
  position: absolute;
  display: flex;
  height: 44px;
  right: 1rem;
  cursor: pointer;
`;
export const labelIconCss = css`
  position: absolute;
  display: flex;
  height: 44px;
  left: 1rem;
`;
export const iconImageCss = css`
  width: 1.25rem;
  margin: auto;
`;

export const hintCss = css`
  font-size: 0.75rem;
  font-weight: 300;
  width: 100%;
  ${mediaQuery(theme.breakpoints.md)} {
    width: 20.6rem;
  }
`;

export const authInputContainerCss = css`
  margin-top: 24px;

  ${mediaQuery(theme.breakpoints.lg)} {
    padding: 0;
  }
`;
