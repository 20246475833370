/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import { Button } from 'components';
import Typography from 'components/typography';
import { TypographyStyleTypeEnum } from 'models/enums';
import lockIcon from 'public/images/Unlock.svg';
import { lockedContentButton } from './styles';

export interface ImagePropsInterface {
  onClick?(e): void;
}

const UnlockButton = ({
  onClick,
}: {
  onClick(e?: any): any;
}): React.ReactElement => (
  <Button
    className="button-unlock"
    onClick={onClick}
  >
    <img
      className="lock-icon"
      src={lockIcon}
    />
    <Typography
      className="button-unlock-text"
      style={TypographyStyleTypeEnum.BUTTON_TEXT}
      css={lockedContentButton}
    >
      Unlock
    </Typography>
  </Button>
);

export default UnlockButton;
