import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { mediaQuery, generateStyle } from 'utils/styles';
import theme from 'styles/theme';

export const containerCss = (fixed, styles?: any) => {
  const defaultCss = `
  position: ${fixed ? 'absolute' : 'relative'};
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1.5rem 0;
  border-top: 2px solid #3e4067;

  ${mediaQuery(theme.breakpoints.md)} {
    padding: 1.5rem 5%;
  }

  /* prevent overlapping on short device like iPhone SE and 6/7/8 */
  @media only screen and (max-height: 823px) {
    position: relative;
    ${fixed && 'margin: 2rem -9% 0 -9%;'};
  }
`;

  return generateStyle({
    defaultStyle: defaultCss,
    extendedStyles: styles,
    key: 'footerContainerCss',
    theme,
  });
};

export const FooterItem = styled.p`
  margin: 0.5rem 0;
  ${theme.typography.legalText}
`;

export const footerLinkCss = css`
  text-decoration: none;
  line-height: 1.5;
  cursor: pointer;
`;

export const linkCss = css`
  text-decoration: none;
`;

export const logoCss = css`
  margin-top: 2rem;

  ${mediaQuery(theme.breakpoints.md)} {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
`;

// hide logo for auth pages on desktop
export const logoHiddenCss = css`
  ${mediaQuery(theme.breakpoints.lg)} {
    display: none;
  }
`;

export const fluidContainerCss = (styles?: any) => {
  const defaultStyle = `padding: 0;`;

  return generateStyle({
    defaultStyle,
    extendedStyles: styles,
    key: 'footerFluidContainerCss',
    theme,
  });
};

export const oneTrustWrapperCss = css`
  padding-top: 0.5rem;
  #ot-sdk-btn.ot-sdk-show-settings {
    border: none;
    padding: 0;

    ${theme.typography.legalText}

    :hover {
      color: rgba(255, 255, 255, 0.67);
      background: none;
    }
  }
`;
