import type { SerializedStyles } from '@emotion/core';
import { css } from '@emotion/core';
import type { QuotesConfigInterface } from 'providers/fyc-theme-component-interfaces';
import { FycConfigsEnum } from 'providers/fyc-theme-component-interfaces';
import type { FycVersionType } from 'providers/fyc-theme-provider';
import { SEARCHLIGHT, SEARCHLIGHT_AWARDS } from 'providers/fyc-theme-provider';
import { getVersionedComponentConfig } from 'providers/versions/fyc-theme-provider-utils';

export const sourceWrapperCss = (version: FycVersionType): SerializedStyles => {
  const customCssConfig = getVersionedComponentConfig<
    QuotesConfigInterface['sourceWrapperCss']
  >({
    version,
    component: FycConfigsEnum.PRESS_PANEL_ITEM_CONFIG,
    selector: 'sourceWrapperCss',
  });

  let color = customCssConfig?.color ?? `rgba(255, 255, 255, 0.6)`;

  if (version === SEARCHLIGHT || version === SEARCHLIGHT_AWARDS) {
    color = `rgba(0, 0, 0, 0.6)`;
  }

  return css`
    color: ${color};
    font-size: 14px;
    font-weight: 400;
    margin-top: 1rem;
    label: sourceWrapperCss;
  `;
};
