import { ComponentTypeEnum } from 'models/enums';

export const hasProxisTagForMovie = (data) => {
  if (data.type !== ComponentTypeEnum.FYC_MOVIE_MARQUEE) {
    return false;
  }

  return data?.entitlement?.tags?.some(
    (t) => t.toLowerCase() === 'proxis' || t.toLowerCase() === 'proxis-hulu',
  );
};

export const hasProxisTagForSeries = (data) => {
  if (data.type !== ComponentTypeEnum.FYC_SERIES) {
    return false;
  }

  return data?.seasons[0]?.episodes[0]?.entitlement?.tags?.some(
    (t) => t.toLowerCase() === 'proxis' || t.toLowerCase() === 'proxis-hulu',
  );
};

export const hideAccessPrompt = (data) => {
  const tags = data.entitlement?.tags || [];

  if (tags.indexOf('hide-access') !== -1) {
    return true;
  }

  return false;
};
