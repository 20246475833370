/** @jsx jsx */
import type { SerializedStyles } from '@emotion/core';
import { jsx, css } from '@emotion/core';
import type { ContactUsConfigInterface } from 'providers/fyc-theme-component-interfaces';
import { FycConfigsEnum } from 'providers/fyc-theme-component-interfaces';
import { getVersionedComponentConfig } from 'providers/versions/fyc-theme-provider-utils';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import { FYC_MOBILE_WIDTH } from 'pages/fyc-page/helpers';
import { mediaQuery } from 'utils/styles';
import type { FycVersionType } from 'providers/fyc-theme-provider';
import {
  SEARCHLIGHT,
  SEARCHLIGHT_AWARDS,
  useFycThemeContext,
} from 'providers/fyc-theme-provider';

const containerCss = (version: FycVersionType): SerializedStyles => {
  const customCssConfig = getVersionedComponentConfig<
    ContactUsConfigInterface['containerCss']
  >({
    version,
    component: FycConfigsEnum.CONTACT_US_CONFIG,
    selector: 'containerCss',
  });

  let color = customCssConfig?.color ?? 'inherit';

  if (version === SEARCHLIGHT || version === SEARCHLIGHT_AWARDS) {
    color = 'black';
  }

  return css`
    color: ${color};
    margin-top: ${57 / 16}rem;
    margin-left: 3%;
    margin-bottom: ${252 / 16}rem;
    label: containerCss;

    ${mediaQuery(FYC_MOBILE_WIDTH)} {
      margin-left: ${(90 / 1440) * 100}%;
    }

    a {
      color: ${color};
    }

    h1,
    p {
      margin: 0;
    }

    h1 {
      font-size: ${24 / 16}rem;
      font-weight: 500;
      margin: 0;
      margin-bottom: ${8 / 16}rem;
    }

    p {
      font-size: ${16 / 16}rem;
      font-weight: 500;
      line-height: ${19.2 / 16}rem;
    }

    p:nth-of-type(2) {
      margin-top: ${40 / 16}rem;
      margin-bottom: ${48 / 16}rem;
    }

    p:nth-of-type(3) {
      margin-bottom: ${24 / 16}rem;
    }

    p:nth-of-type(5) {
      margin-bottom: ${32 / 16}rem;
      margin-top: ${8 / 16}rem;
    }

    p:nth-of-type(7) {
      margin-top: ${8 / 16}rem;
    }
  `;
};

interface PropsInterface {
  pageData: any;
}

const ContactUs: React.FC<PropsInterface> = ({ pageData }) => {
  const version = useFycThemeContext()?.version;

  return (
    <div css={containerCss(version)}>
      <ReactMarkdown>{pageData?.components?.[0]?.text}</ReactMarkdown>
    </div>
  );
};

export default ContactUs;
