import type { ComponentConfig } from 'bitmovin-player-ui/dist/js/framework/components/component';
import { Component } from 'bitmovin-player-ui/dist/js/framework/components/component';
import { DOM } from 'bitmovin-player-ui/dist/js/framework/dom';

interface CustomPosterConfigInterface extends ComponentConfig {
  imageData: any;
}

export class CustomPoster extends Component<ComponentConfig> {
  private imageUrl: any;

  constructor(config: CustomPosterConfigInterface) {
    super(config);
    this.imageUrl = config.imageData;
  }

  protected toDomElement(): DOM {
    const itemElement = new DOM('img', {
      class: this.getCssClasses(),
      src: this.imageUrl,
    });

    return itemElement;
  }
}
