/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import React from 'react';
import { Modal } from 'components';
import AccessCode from '../access-code';
import CloseIcon from 'public/images/close.svg';
import {
  wrapperCss,
  topPartCss,
  contentPartCss,
  accessCodeCss,
  modalExtendCss,
} from './styles';
import { useFycThemeContext } from 'providers/fyc-theme-provider';

interface PropsInterface {
  component: any;
  isOpen: boolean;
  setIsOpen: any;
}

const btnWrapperCss = css`
  width: ${(291 / 330) * 100}%;
  position: absolute;
  bottom: ${24 / 16}rem;
`;

const AccessCodeModal: React.FC<PropsInterface> = ({
  isOpen,
  setIsOpen,
  component,
}) => {
  const version = useFycThemeContext()?.version;

  return (
    <Modal
      isOpen={isOpen}
      extendedStyles={modalExtendCss(version)}
    >
      <div css={wrapperCss(version)}>
        <div className="mask"></div>
        <div css={topPartCss}>
          <span className="code-modal-title">Access Code</span>
          <div
            className="code-modal-close-button"
            onClick={() => {
              setIsOpen(false);
            }}
          >
            <img src={CloseIcon} />
          </div>
        </div>
        <div css={contentPartCss}>
          <p>Enter access code to begin watching</p>
          <div css={accessCodeCss}>
            <AccessCode
              btnStyles={btnWrapperCss}
              component={component}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AccessCodeModal;
