/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import React from 'react';

import NsActionLink from 'components/ns-component/ns-action-link';
import NsImage from 'components/ns-component/ns-image';

const container = css`
  margin: 0;
  padding: 0;
`;

const gridItem = {
  margin: 0,
  padding: 0,
  // display: 'inline-block',
};

interface PropsInterface {
  className?: string;
  component: any;
}

const NsImageGridComponent: React.FC<PropsInterface> = (props) => {
  const renderItem = (item) => {
    return (
      <div
        className="col-sm-3"
        key={item.id}
        css={gridItem}
      >
        <NsActionLink actions={item.actions}>
          <NsImage
            url={item.image.url}
            alt={item.image.alt}
            width={250}
            playable={item.action && item.action.type === 'PLAY'}
            aspectRatio={item.image.aspectRatioFractions[0]}
          />
        </NsActionLink>
      </div>
    );
  };

  return (
    <div
      css={container}
      className="row"
    >
      {props.component.items.map(renderItem)}
    </div>
  );
};

export default NsImageGridComponent;
