import type { SerializedStyles } from '@emotion/core';
import { css } from '@emotion/core';
import type { AccessCodeConfigInterface } from 'providers/fyc-theme-component-interfaces';
import { FycConfigsEnum } from 'providers/fyc-theme-component-interfaces';
import { mediaQuery } from 'utils/styles';
import theme from 'styles/theme';
import type { FycVersionType } from 'providers/fyc-theme-provider';
import { FX, HULU } from 'providers/fyc-theme-provider';
import { getVersionedComponentConfig } from 'providers/versions/fyc-theme-provider-utils';

export const wrapperCss = (
  styles?: SerializedStyles,
): Array<SerializedStyles | undefined> => {
  const defaultStyles = css`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    & .error-text {
      margin-top: ${(8 / 437) * 100}%;
      margin-bottom: ${(7 / 437) * 100}%;
    }
  `;

  return [defaultStyles, styles];
};

export const inputCss = (version: FycVersionType): SerializedStyles => {
  const customCssConfig = getVersionedComponentConfig<
    AccessCodeConfigInterface['inputCss']
  >({
    version,
    component: FycConfigsEnum.ACCESS_CODE_CONFIG,
    selector: 'inputCss',
  });

  const fontWeight = customCssConfig?.fontWeight ?? 400;
  let fontFamily = customCssConfig?.fontFamily ?? `inherit`;
  let color = customCssConfig?.color ?? 'inherit';
  let opacity = customCssConfig?.opacity ?? 'inherit';

  if (version === HULU) {
    fontFamily = `Graphik`;
    color = `#FFFFFF`;
  }

  if (version === FX) {
    fontFamily = `Bain`;
    opacity = 1;
  }

  return css`
    color: ${color};
    width: 100%;
    height: ${44 / 16}rem;
    font-weight: ${fontWeight};
    font-family: ${fontFamily};
    label: inputCss;

    ::placeholder {
      opacity: ${opacity};
    }
  `;
};

export const containerCss = css`
  margin-top: 0;
  width: 100%;

  ${mediaQuery(theme.breakpoints.md)} {
    margin-top: 0;
    width: 100%;
  }

  ${mediaQuery(theme.breakpoints.lg)} {
    margin-top: 0;
    width: 100%;
  }
`;

export const buttonCss = (version: FycVersionType): SerializedStyles => {
  const customCssConfig = getVersionedComponentConfig<
    AccessCodeConfigInterface['buttonCss']
  >({
    version,
    component: FycConfigsEnum.ACCESS_CODE_CONFIG,
    selector: 'buttonCss',
  });

  let fontFamily = customCssConfig?.fontFamily ?? 'inherit';
  let fontWeight = customCssConfig?.fontWeight ?? 400;

  if (version === HULU) {
    fontFamily = `Graphik Semibold`;
    fontWeight = 600;
  }

  if (version === FX) {
    fontWeight = 600;
  }

  return css`
    font-family: ${fontFamily};
    font-weight: ${fontWeight};
    margin-top: ${(9 / 437) * 100}% !important;
    width: 100% !important;
    height: ${44 / 16}rem;
    label: buttonCss;
  `;
};

export const buttonDisabledCss = (
  version: FycVersionType,
): SerializedStyles => {
  const customCssConfig = getVersionedComponentConfig<
    AccessCodeConfigInterface['buttonDisabledCss']
  >({
    version,
    component: FycConfigsEnum.ACCESS_CODE_CONFIG,
    selector: 'buttonDisabledCss',
  });

  let fontFamily = customCssConfig?.fontFamily ?? 'inherit';
  let fontWeight = customCssConfig?.fontWeight ?? 400;

  if (version === HULU) {
    fontFamily = `Graphik Semibold`;
    fontWeight = 600;
  }

  if (version === FX) {
    fontWeight = 600;
  }

  return css`
    font-family: ${fontFamily};
    font-weight: ${fontWeight};
    margin-top: ${(9 / 437) * 100}% !important;
    width: 100% !important;
    height: ${44 / 16}rem;

    background-color: #34343f;
    color: #777881;
    label: buttonDisabledCss;

    :hover {
      cursor: not-allowed;
      background-color: #34343f;
    }
  `;
};
