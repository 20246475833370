import { css } from '@emotion/core';
import theme from './theme';

const bitMovinPlayer = css`
  /* restyle the default loader css */
  .bmpui-ui-buffering-overlay
    .bmpui-ui-buffering-overlay-indicator:nth-of-type(even) {
    border: 8px solid ${theme.palette.common.white}; /* Light grey */
    border-top: 8px solid ${theme.palette.primary.main}; /* Blue */
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 2s linear infinite;
    background: none;
  }

  /* hide the first and third dots from default style */
  .bmpui-ui-buffering-overlay
    .bmpui-ui-buffering-overlay-indicator:nth-of-type(odd) {
    display: none;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export default bitMovinPlayer;
