interface ITransformValueInterface {
  transform: string;
  transition: string;
}

export const calculateMaxArrowClickedNumber = (
  data,
  setMaxArrowClickedNumber,
  isFYCMobile: boolean,
  isFYCTablet: boolean,
): void => {
  const totalCount = data?.items.length;
  if (isFYCMobile) {
    setMaxArrowClickedNumber(Math.ceil(totalCount / 1) - 1);
    return;
  }
  if (isFYCTablet) {
    setMaxArrowClickedNumber(Math.ceil(totalCount / 2) - 1);
    return;
  }
  setMaxArrowClickedNumber(Math.ceil(totalCount / 3) - 1);
};

export const calculateTransformValue = (
  scrollRef,
  arrowClickedNumber,
  isFYCMobile: boolean,
  isFYCTablet: boolean,
): ITransformValueInterface => {
  const { current } = scrollRef;
  const width = current?.clientWidth || 0;

  const result = {
    transform: `translateX(-${arrowClickedNumber * width * 0.98 * 0.26 * 3}px)`,
    transition: 'transform 1s ease 0s',
  };

  if (isFYCMobile) {
    result.transform = `translateX(-${
      arrowClickedNumber * (width - 16) * 0.54 * 1
    }px)`;
  }

  if (isFYCTablet) {
    result.transform = `translateX(-${
      arrowClickedNumber * width * 0.95 * 0.37 * 2
    }px)`;
  }

  return result;
};

export const resetSliderPosition = (setArrowClickedNumber): void => {
  setArrowClickedNumber(0);
};
