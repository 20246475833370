import { css } from '@emotion/core';
import theme from 'styles/theme';
import { mediaQuery } from 'utils/styles';

const defaultText = css`
  font-size: 1.25rem;
  font-weight: 300;
  line-height: 25px;
`;

const styles = css`
  min-height: 90%;

  .step-indicator {
    display: flex;
    justify-content: space-between;
    margin-top: 1.1875rem;
  }

  .step {
    height: 1px;
    border: 3px solid rgba(255, 255, 255, 0.5);
    border-radius: 1rem;
    width: 30%;
  }

  .step-fill {
    border-color: rgb(61, 116, 255);
  }

  .token-input {
    margin-right: 10px;
    &:nth-of-type(3) {
      margin-right: 2rem;
    }

    input {
      border-radius: 3px;
      background-color: rgba(255, 255, 255, 0.1);
      color: #fff;
    }

    /* Fix styles on smaller width devices like iPhone 5/SE */
    @media only screen and (max-width: 320px) {
      input {
        width: 30px !important;
      }
    }
  }

  .token-error {
    font-size: 0.75rem;
    color: #e4615d;
    margin: 1.25rem 0;
    text-transform: capitalize;

    ${mediaQuery(theme.breakpoints.md)} {
      max-width: 426px;
    }
  }

  .token-container {
    justify-content: center;
    margin-top: 36px;

    ${mediaQuery(theme.breakpoints.md)} {
      justify-content: start;
    }
  }

  .qrcode {
    canvas {
      border-radius: 1rem;
    }

    .body-copy {
      margin-bottom: 0.5rem;
    }
  }

  .sub-heading {
    ${defaultText}
    font-size: 1.625rem;
    line-height: 34px;
  }

  .code-container {
    position: relative;
  }

  .code-copied {
    position: absolute;
    right: 15%;
    top: -10px;
    width: 125px;
    height: 31px;
    background: rgb(255, 255, 255);
    border-radius: 4px;
    color: #000;
    line-height: 32px;
    text-align: center;
  }

  .body-copy {
    ${defaultText}
  }

  .store-links {
    margin-bottom: 0.5rem;
  }

  .image-container {
    display: flex;
    flex-flow: row nowrap;
    margin-top: 1.75rem;
  }

  .left-images {
    flex: 25%;
  }

  .right-images {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    flex: 75%;
  }

  .store-image {
    width: 170px;
  }

  .tfa-secret {
    margin: 1.55rem 0;
    font-size: 26px;
    height: 34px;
    line-height: 34px;
    color: #fff;
    background: none;
    width: 100%;
    text-align: center;
  }

  .info-container {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    min-height: 100%;
  }

  .info-copy {
    ${defaultText}
    color: rgba(255, 255, 255, 0.65);
    text-align: center;
    margin-bottom: 1rem;
  }

  &.hide {
    display: none;
  }

  ${mediaQuery(theme.breakpoints.md)} {
    min-height: unset;

    .right-images {
      align-items: flex-start;
      margin-left: 2rem;
    }

    .tablet.info-copy {
      width: 320px;
      height: 50px;
      color: rgba(255, 255, 255, 0.65);
      font-size: 1.25rem;
      font-weight: 300;
      line-height: 25px;
      text-align: left;
      margin-top: 2.5rem;
      margin-bottom: 5.125rem;
    }

    .sub-heading {
      color: rgb(255, 255, 255);
      font-size: 34px;
      font-weight: 300;
      line-height: 40px;
    }

    .body-copy {
      font-size: 16px;
      line-height: 24px;
      width: 466px;
    }

    .main-heading {
      font-size: 3rem;
      font-weight: 300;
      line-height: 67px;
    }

    .qrcode {
      .sub-heading {
        height: auto;
        font-size: 1.5rem;
      }
    }

    .step-indicator {
      max-width: 426px;
    }

    .code-copied {
      position: unset;
      order: 1;
    }

    .code-container {
      display: flex;
      flex-flow: row nowrap;
    }

    .qrcode {
      .body-copy {
        margin: 2rem 0;
      }
    }

    .qrcode-container {
      display: flex;
    }

    .qrcode-instructions {
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
    }

    .qrcode-instruction {
      font-size: 1rem;
      font-weight: 300;
      line-height: 24px;
    }

    .tfa-body {
      canvas {
        border-radius: 1rem;
      }
    }

    .tfa-secret {
      &.body-copy {
        margin: 0 1.25rem;
        text-align: left;
        width: 280px;
        font-size: 26px;
        line-height: 36px;
        height: 36px;
      }
    }
  }
`;

export const tokenInputStyle = {
  height: 52,
  width: 38,
};

export const actionBtnCss = css`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: ${theme.zIndex.appBar};
  width: 100%;
  padding: 0 5.5%;
  height: 7.7rem;
  background-image: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0) 0%,
    rgb(34, 35, 55) 100%
  );
  background-color: ${theme.palette.background.primary};
  border-top: 2px solid #3e4067;

  button {
    margin-top: 1rem;
  }

  ${mediaQuery(theme.breakpoints.md)} {
    position: relative;
    padding: 0;
    margin: 1rem 0 0 0;
    border: none;
    background: none;
    height: auto;
  }
`;

export default styles;
