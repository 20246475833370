import type { FunctionComponent } from 'react';
import React from 'react';
import clsx from 'clsx';
import { createUseStyles } from 'react-jss';

const makeElevations = (shadows) => {
  return shadows.reduce((acc, val, index) => {
    acc['elevation' + index] = {
      boxShadow: val,
    };
    return acc;
  }, {});
};

const useStyles = createUseStyles<any>((theme) => ({
  root: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
  },
  ...makeElevations(theme.shadows),
}));

interface PropsInterface {
  children: React.ReactNode;
  className?: string;
  elevation?: number;
}

const NsPaper: FunctionComponent<PropsInterface> = (props: PropsInterface) => {
  const { elevation = 1, className, children } = props;
  const classes = useStyles();
  const elevationStyle = classes['elevation' + elevation];
  const classNames = clsx(className, classes.root, elevationStyle);
  return <div className={classNames}>{children}</div>;
};

export default NsPaper;
