/** @jsx jsx */
import type { EntitlementInterface } from 'models/components/entitlement-component-interface';
import { jsx } from '@emotion/core';
import React, { useState } from 'react';
import List from './list';
import type { ItemPropsInterface } from './item';
import type { IOptionInterface } from 'components/fyc-component/episodes-panel/helpers';
import {
  buildOptions,
  getCurrentOption,
} from 'components/fyc-component/episodes-panel/helpers';

export const BONUS = 'BONUS';
export const EPISODES = 'EPISODES';

interface PropsInterface {
  data: any;
  type: 'BONUS' | 'EPISODES';
}

interface EpisodeInterface {
  bookmark?: {
    progress: number;
  };
  entitlement: EntitlementInterface;
  episodeId: string;
  episodeNumber: string;
  heroImage: {
    alt?: string;
    url: string;
  };
  runtime: string;
  synopsis: string;
  title: string;
  titleTreatmentImage?: {
    alt?: string;
    url: string;
  };
}

const DetailsEntitlementPanel: React.FC<PropsInterface> = ({ type, data }) => {
  const listData: ItemPropsInterface[] = [];

  // episodes states & data
  let options: IOptionInterface[] | undefined = [];
  const [currentSeason, setCurrentSeason] = useState(
    data?.component?.seasons[0]?.seasonNumber,
  );

  if (type === BONUS) {
    for (const item of data?.components[0].items) {
      const itemData: ItemPropsInterface = {
        id: item.id,
        hasPlain: data.isPlain,
        title: item.title,
        originalTitle: item.title,
        isLocked: !data?.verifyNdaCompletion(item.entitlement?.ndaTemplate),
        onClickAction: data?.handleNdaClick,
        entitlement: item.entitlement,
        imgUrl: '',
        alt: '',
        openPlayer: data?.openPlayer,
        item,
        progressData: {
          show:
            item.bookmark &&
            item.bookmark?.progress > 0 &&
            item.bookmark?.progress < 0.96,
          progress: item.bookmark?.progress ? item.bookmark?.progress * 100 : 0,
        },
      };

      if (data.isPlain) {
        if (item.titleTreatmentImage) {
          itemData.imgUrl = item.titleTreatmentImage.url;
          itemData.alt = item.titleTreatmentImage.alt;
        }
      } else {
        itemData.imgUrl = item.heroImage.url;
        itemData.alt = item.heroImage.alt;
      }

      listData.push(itemData);
    }
  }

  if (type === EPISODES) {
    options = buildOptions(data?.component);
    let episodesArr: EpisodeInterface[] = [];

    try {
      const currentSeasonData = data.component.seasons.find(
        (season) => season.seasonNumber === currentSeason,
      );

      const currentEpisodesData =
        currentSeasonData.episodes as EpisodeInterface[];

      if (currentEpisodesData && currentEpisodesData.length) {
        episodesArr = currentEpisodesData;
      }
    } catch (error) {}

    for (const episode of episodesArr) {
      const showProgress =
        episode.bookmark ?
          episode.bookmark?.progress > 0 && episode.bookmark?.progress < 0.96
        : false;

      const itemData: ItemPropsInterface = {
        id: episode.episodeId,
        hasPlain: data.isPlain,
        title: `${episode.episodeNumber}. ${episode.title} (${episode.runtime} min)`,
        originalTitle: episode.title,
        isLocked: !data?.verifyNdaCompletion(episode.entitlement?.ndaTemplate),
        onClickAction: data?.handleNdaClick,
        entitlement: episode.entitlement,
        imgUrl: '',
        alt: '',
        openPlayer: data?.openPlayer,
        item: episode,
        description: episode.synopsis,
        progressData: {
          show: showProgress,
          progress:
            episode.bookmark?.progress ? episode.bookmark?.progress * 100 : 0,
        },
      };

      if (data.isPlain) {
        if (episode.titleTreatmentImage) {
          itemData.imgUrl = episode.titleTreatmentImage.url;
          itemData.alt = episode.titleTreatmentImage.alt;
        }
      } else {
        itemData.imgUrl = episode.heroImage.url;
        itemData.alt = episode.heroImage.alt;
      }

      listData.push(itemData);
    }
  }

  return (
    <div>
      <List
        selectData={{
          show: type === EPISODES,
          options,
          value: { ...getCurrentOption(options, currentSeason) },
          onChange: (value) => setCurrentSeason(value),
        }}
        listData={listData}
      />
    </div>
  );
};

export default DetailsEntitlementPanel;
