import type { AllContentLandingConfigInterface } from 'providers/fyc-theme-component-interfaces';
import { FycConfigsEnum } from 'providers/fyc-theme-component-interfaces';
import { getVersionedComponentConfig } from 'providers/versions/fyc-theme-provider-utils';
import { FYC_SMALL_MOBILE_WIDTH } from './../helpers';
import { FYC_MOBILE_WIDTH, FYC_TABLET_WIDTH } from 'pages/fyc-page/helpers';
import type { SerializedStyles } from '@emotion/core';
import { css } from '@emotion/core';
import { mediaQuery } from 'utils/styles';
import type { FycVersionType } from 'providers/fyc-theme-provider';
import {
  ABC,
  DBT,
  DISNEYPLUS,
  DTS,
  FREEFORM,
  FX,
  HULU,
  NATGEO,
  SEARCHLIGHT,
  SEARCHLIGHT_AWARDS,
} from 'providers/fyc-theme-provider';

export const wrapperCss = (isSliders: boolean): SerializedStyles => {
  if (isSliders) {
    return css`
      display: flex;

      ${mediaQuery(FYC_MOBILE_WIDTH)} {
        padding-top: ${24 / 16}rem;
        padding-bottom: ${43 / 16}rem;
        margin-bottom: ${109 / 16}rem;
      }
    `;
  }

  return css`
    display: flex;
    padding-top: ${24 / 16}rem;
    padding-bottom: ${43 / 16}rem;
  `;
};

export const dropDownContainerCss = css`
  width: 94%;
  margin-left: 3%;
  max-width: ${336 / 16}rem;
  margin-top: ${24 / 16}rem;
  font-size: ${14 / 16}rem;

  ${mediaQuery(FYC_SMALL_MOBILE_WIDTH)} {
    max-width: ${336 / 16}rem;
  }

  ${mediaQuery(FYC_MOBILE_WIDTH)} {
    display: none;
  }
`;

export const leftColumnCss = (version: FycVersionType): SerializedStyles => {
  const customCssConfig = getVersionedComponentConfig<
    AllContentLandingConfigInterface['leftColumnCss']
  >({
    version,
    component: FycConfigsEnum.ALL_CONTENT_LANDING_CONFIG,
    selector: 'leftColumnCss',
  });

  let fontFamily = customCssConfig?.fontFamily ?? `proxima-nova`;
  let color = customCssConfig?.color ?? `white`;
  const borderRight =
    customCssConfig?.borderRight ?? `2px solid rgba(245, 245, 245, 0.31)`;

  if (version === ABC) {
    fontFamily = `gt-america-st`;
    color = `#F2F2F2`;
  }

  if (version === DTS) {
    fontFamily = 'Avenir';
  }

  if (version === HULU) {
    fontFamily = `Graphik Medium`;
  }

  if (version === SEARCHLIGHT || version === SEARCHLIGHT_AWARDS) {
    color = `#2E2E2E`;
    fontFamily = `'Open Sans', sans-serif`;
  }

  if (version === NATGEO) {
    fontFamily = `Geograph Regular`;
  }

  if (version === FX) {
    fontFamily = `Bain`;
  }

  return css`
    color: ${color};
    border-right: ${borderRight};
    display: flex;
    flex-direction: column;
    width: ${(332 / 1440) * 100}%;
    display: none;
    font-family: ${fontFamily};
    font-weight: 400;
    letter-spacing: 2px;
    label: leftColumnCss;

    ${mediaQuery(FYC_MOBILE_WIDTH)} {
      display: flex;
    }
  `;
};

export const cateContainer = (
  index: number,
  version: FycVersionType,
): SerializedStyles => {
  const customCssConfig = getVersionedComponentConfig<
    AllContentLandingConfigInterface['cateContainer']
  >({
    version,
    component: FycConfigsEnum.ALL_CONTENT_LANDING_CONFIG,
    selector: 'cateContainer',
  });

  let color = customCssConfig?.color ?? `rgba(193, 154, 65, 1)`;
  let fontWeight = customCssConfig?.fontWeight ?? 700;
  let selectedFontSize = customCssConfig?.selectedFontSize ?? `${18 / 16}rem`;
  let selectedFontFamily = customCssConfig?.selectedFontFamily ?? 'inherit';
  let unSelectedFontWeight = customCssConfig?.unSelectedFontWeight ?? 400;
  const letterSpacing = customCssConfig?.letterSpacing ?? '2px';

  if (version === ABC) {
    color = `#F2F2F2`;
  }

  if (version === FREEFORM) {
    color = `#FF44E1`;
    fontWeight = 500;
    selectedFontSize = `${20 / 16}rem`;
  }

  if (version === DBT || version === DISNEYPLUS) {
    color = `#BBF7FF`;
  }

  if (version === DTS) {
    color = `#E8B446`;
    fontWeight = 500;
    selectedFontSize = `1rem`;
  }

  if (version === HULU) {
    color = `#FFFFFF`;
    selectedFontSize = `1rem`;
    selectedFontFamily = `Graphik Semibold`;
    fontWeight = 600;
  }

  if (version === SEARCHLIGHT || version === SEARCHLIGHT_AWARDS) {
    color = `#907137`;
    selectedFontSize = `20px`;
    fontWeight = 700;
    selectedFontFamily = `'Open Sans', sans-serif`;
    unSelectedFontWeight = 600;
  }

  if (version === NATGEO) {
    color = `#FC0`;
    selectedFontSize = `20px`;
    fontWeight = 400;
    selectedFontFamily = `Geograph Medium`;
    unSelectedFontWeight = 400;
  }

  if (version === FX) {
    color = `#FFF`;
    selectedFontSize = `16px`;
    fontWeight = 600;
  }

  return css`
    padding-left: ${(90 / 1440) * 100}vw;
    padding-top: 1rem;
    letter-spacing: ${letterSpacing};
    font-weight: ${unSelectedFontWeight};
    font-size: 1rem;
    label: cateContainer;

    div:nth-of-type(${index + 1}) {
      color: ${color};
      font-weight: ${fontWeight};
      font-size: ${selectedFontSize};
      font-family: ${selectedFontFamily};
    }
  `;
};

export const rightColumnCss = css`
  width: 100%;
  padding-left: 3%;

  ${mediaQuery(FYC_MOBILE_WIDTH)} {
    width: ${(1 - 332 / 1440) * 100}%;
    padding-left: ${44 / 16}rem;
  }
`;

export const cateTitleCss = css`
  font-size: ${12 / 16}rem;
`;

export const cateListItemCss = (version: FycVersionType): SerializedStyles => {
  const customCssConfig = getVersionedComponentConfig<
    AllContentLandingConfigInterface['cateListItemCss']
  >({
    version,
    component: FycConfigsEnum.ALL_CONTENT_LANDING_CONFIG,
    selector: 'cateListItemCss',
  });
  let textTransform = customCssConfig?.textTransform ?? 'uppercase';

  if (version === FX) {
    textTransform = 'capitalize';
  }

  return css`
    font-size: 1rem;
    margin-top: ${24 / 16}rem;
    cursor: pointer;
    text-transform: ${textTransform};
    label: cateListItemCss;
  `;
};

export const noContentContainerCss = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: ${(147.5 / 877) * 100}vh;
  margin-bottom: ${(287 / 877) * 100}vh;

  .no-content-title {
    font-size: ${24 / 16}rem;
    font-weight: 500;
    margin-bottom: ${25 / 16}rem;
  }

  .no-content-body {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: ${51 / 16}rem;
  }
`;

export const buttonContainerCss = css`
  width: ${185 / 16}rem;
`;

export const bannerWrapperCss = css`
  padding: ${14 / 16}rem 3% 0 3%;

  ${mediaQuery(FYC_MOBILE_WIDTH)} {
    padding: ${24 / 16}rem ${(20 / 1024) * 100}% 0 ${(20 / 1024) * 100}%;
  }

  ${mediaQuery(FYC_TABLET_WIDTH)} {
    padding: ${30 / 16}rem ${(75 / 1440) * 100}%;
  }
`;
