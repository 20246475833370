/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import type { FunctionComponent } from 'react';
import React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

const rootCss = css`
  text-decoration: none;
  color: inherit;
`;

interface PropsInterface {
  children: any;
  className?: string;
  to: string;
}

const NsLink: FunctionComponent<PropsInterface> = (props: PropsInterface) => {
  return (
    <Link
      css={rootCss}
      className={clsx(props.className)}
      to={props.to}
    >
      {props.children}
    </Link>
  );
};

export default NsLink;
