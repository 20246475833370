import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

export interface HelmetDataInterface {
  metaTags: MetaTagsDataInterface[];
  titleText: string;
}

export interface MetaTagsDataInterface {
  content: string;
  name: string;
}

interface PropsInterface {
  data: HelmetDataInterface;
}

const FycHelmet: React.FC<PropsInterface> = ({ data }) => {
  useEffect(() => {
    // remove duplicated meta tags pre-defined in html if conflicts
    for (const tag of data?.metaTags) {
      const tagsInHtmlByName = document.querySelector(
        `meta[name=${tag.name}]:not([data-react-helmet])`,
      );
      tagsInHtmlByName?.parentNode?.removeChild(tagsInHtmlByName);
    }
  }, [data]);

  return (
    <Helmet>
      {data.titleText && <title>{data.titleText}</title>}
      {data?.metaTags?.map((t) => (
        <meta
          key={t.name}
          name={t.name}
          content={t.content}
        />
      ))}
    </Helmet>
  );
};

export default FycHelmet;
