/** @jsx jsx */
import { jsx } from '@emotion/core';
import type { SerializedStyles } from '@emotion/utils';
import clsx from 'clsx';
import React from 'react';
import { rootStyles } from './styles';

interface ComponentContainerPropsInterface {
  children: React.ReactNode | React.ReactNodeArray;
  className?: string;
  isFluid?: boolean;
  rootCss?: SerializedStyles | string;
}
const ComponentContainer: React.FC<ComponentContainerPropsInterface> = ({
  children,
  className,
  rootCss,
  isFluid = true,
  ...props
}) => {
  return (
    <div
      className={clsx([
        'component-container',
        isFluid && 'container-fluid',
        className,
      ])}
      css={[rootStyles, rootCss]}
      {...props}
    >
      {children}
    </div>
  );
};

export default ComponentContainer;
