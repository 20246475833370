import type {
  AllComponentTypesType,
  NSFycMovieMarqueeComponentInterface,
  NSFycSeriesComponentInterface,
  NSPageComponentInterface,
} from 'models/cms';
import { ComponentTypeEnum } from 'models/enums';

export const getDataByType = <T extends NSPageComponentInterface>(
  type: AllComponentTypesType,
  data?: NSPageComponentInterface[] | null,
): T | null | undefined => {
  if (!data) {
    return null;
  }

  return data.find((item) => item?.type === type) as T | undefined;
};

export const getDataArrayByType = <T extends NSPageComponentInterface>(
  type: AllComponentTypesType,
  data?: NSPageComponentInterface[] | null,
): T[] | null => {
  if (!data) {
    return null;
  }

  return data.filter((item) => item.type === type) as T[];
};

// @ts-ignore TODO: TS7006: Parameter 'item' implicitly has an 'any' type.
export const getPageTarget = (item) => {
  let pageAction;

  if (item?.actions?.length) {
    // @ts-ignore TODO: TS7006: Parameter 'a' implicitly has an 'any' type.
    pageAction = item.actions.find((a) => a.type === 'PAGE');
  }

  if (pageAction) {
    return pageAction.target;
  }
};

export function isNSFycSeriesComponent(
  component:
    | NSFycSeriesComponentInterface
    | NSFycMovieMarqueeComponentInterface,
): component is NSFycSeriesComponentInterface {
  return (
    (component as NSFycSeriesComponentInterface).type ===
    ComponentTypeEnum.FYC_SERIES
  );
}
