import type { SerializedStyles } from '@emotion/core';
import { css } from '@emotion/core';
import { mediaQuery } from 'utils/styles';
import {
  FYC_TABLET_WIDTH,
  FYC_MOBILE_WIDTH,
  FYC_SMALL_MOBILE_WIDTH,
} from 'pages/fyc-page/helpers';

export const imgWrapperCss = css`
  display: flex;
  flex-wrap: wrap;
  margin-top: ${27 / 16}rem;

  ${mediaQuery(FYC_MOBILE_WIDTH)} {
    margin-top: 1rem;
    padding-right: ${(20 / 1024) * 100}vw;
  }

  ${mediaQuery(FYC_TABLET_WIDTH)} {
    padding-right: ${(78 / 1440) * 100}vw;
  }
`;

export const imgContainerCss = (isPlain?: boolean): SerializedStyles[] => {
  const defaultCss = [
    css`
      display: flex;
      justify-content: center;
      align-items: center;
      aspect-ratio: 2 / 3;
      margin-bottom: ${24 / 16}rem;
      cursor: pointer;

      width: 30%;
      margin-right: 3%;

      :hover {
        transform: scale(1.08);
        box-shadow: 3px 3px 16px 0px rgba(255, 255, 255, 0.21);
      }

      &::before {
        content: '';
        display: block;
        padding-top: 66.67%;
      }

      position: relative;

      ${mediaQuery(FYC_SMALL_MOBILE_WIDTH)} {
        width: 22%;
        margin-right: 3%;
      }

      ${mediaQuery(FYC_MOBILE_WIDTH)} {
        width: 18%;
        margin-right: 2%;
        &:nth-of-type(5n) {
          margin-right: 0;
        }

        &:nth-of-type(6n) {
          margin-right: 2%;
        }
      }

      ${mediaQuery(FYC_TABLET_WIDTH)} {
        width: 15%;
        margin-right: 2%;
        &:nth-of-type(5n) {
          margin-right: 2%;
        }

        &:nth-of-type(6n) {
          margin-right: 0;
        }
      }
    `,
  ];

  if (isPlain) {
    defaultCss.push(css`
      background: radial-gradient(
        110.84% 110.84% at 48% -9.29%,
        #565d92 0%,
        #0c0d37 100%
      );
      border-radius: 6px;
    `);
  }

  return defaultCss;
};

export const titleContainerCss = css`
  text-align: center;
`;
