import type { FunctionComponent } from 'react';
import React from 'react';
import { Route, Switch } from 'react-router-dom';

import {
  Activate,
  ForgotPassword,
  LoginAccessCode,
  LoginEmail,
  LoginLanding,
  LoginOAuthReturn,
  NotFound,
  NsPage,
  ResetPassword,
  Terms,
  Welcome,
} from 'pages';
import FycPage from 'pages/fyc-page';
import * as routes from './constants';
import Loader from 'components/loader';
import ScrollToTop from 'components/scroll-to-top';
import PrivateRoute from './private-route';
import RedirectRoute from './redirect-route';
import { useStringsContext } from 'providers/strings-provider';
import BrowserCompatibility from 'containers/browser-compatibility';
import PlayerProvider from 'providers/player-provider';
import withChromecast from 'hocs/with-chromecast';
import { FycThemeProvider } from 'providers/fyc-theme-provider';
import { SSOProvider } from 'providers/sso-provider';
import { GTagsProvider } from 'providers/g-tags-provider';

const NsRouter: FunctionComponent = () => {
  const { isLoading: isStringsLoading } = useStringsContext();

  const WithChromecastPlayerProvider = withChromecast(PlayerProvider);

  if (isStringsLoading) {
    return <Loader />;
  }

  return (
    <SSOProvider>
      <BrowserCompatibility>
        <GTagsProvider>
          <WithChromecastPlayerProvider>
            <ScrollToTop>
              <Switch>
                <RedirectRoute
                  path={routes.LOGIN_LANDING}
                  exact
                >
                  <LoginLanding />
                </RedirectRoute>

                <RedirectRoute
                  path={routes.LOGIN_ACCESS_CODE}
                  exact
                >
                  <LoginAccessCode />
                </RedirectRoute>

                <RedirectRoute
                  path={routes.LOGIN_KIDS}
                  exact
                >
                  <LoginAccessCode isKidsLogin />
                </RedirectRoute>

                <RedirectRoute
                  path={routes.LOGIN_EMAIL}
                  exact
                >
                  <LoginEmail />
                </RedirectRoute>

                <RedirectRoute
                  path={routes.LOGIN_OAUTH_RETURN}
                  exact
                >
                  <LoginOAuthReturn />
                </RedirectRoute>

                <Route
                  path={routes.FORGOT_PASSWORD}
                  exact
                >
                  <ForgotPassword />
                </Route>

                <Route path={routes.RESET_PASSWORD}>
                  <ResetPassword />
                </Route>

                <Route
                  path={routes.NOT_FOUND}
                  exact
                >
                  <NotFound />
                </Route>

                <Route
                  path={routes.TERMS}
                  exact
                >
                  <Terms />
                </Route>

                <Route
                  path={[routes.WELCOME, routes.RESET_2FA]}
                  exact
                >
                  <Welcome />
                </Route>

                <Route
                  path={routes.ACTIVATE}
                  exact
                >
                  <Activate />
                </Route>

                <Route
                  path={[routes.BAFTA, routes.SSO, routes.HELP]}
                  exact
                >
                  <NsPage />
                </Route>

                <Route path={routes.FYC}>
                  <FycThemeProvider>
                    <FycPage />
                  </FycThemeProvider>
                </Route>

                <PrivateRoute>
                  <NsPage />
                </PrivateRoute>
              </Switch>
            </ScrollToTop>
          </WithChromecastPlayerProvider>
        </GTagsProvider>
      </BrowserCompatibility>
    </SSOProvider>
  );
};

export default NsRouter;
