import request from 'superagent';

import * as endpoints from './endpoints';
import type { PlayerStatesEnum } from 'models/enums';

interface UpdateSessionInputInterface {
  accessToken: string;
  movieId: string;
  moviePosition: number;
  movieType: string;
  overLimitCheck?: boolean;
  sessionId: string;
}

interface DeleteSessionInputInterface {
  accessToken: string;
  sessionId: string;
}

interface FYCPlayInputInterface {
  definition: string;
  playId: string;
  version: string;
}

export const updateSessionApi = async ({
  sessionId,
  accessToken,
  ...rest
}: UpdateSessionInputInterface) =>
  request
    .put(endpoints.SINGLE_SESSION(sessionId))
    .send(rest)
    .set('Authorization', `Bearer ${accessToken}`);

export const deleteSessionApi = async ({
  sessionId,
  accessToken,
}: DeleteSessionInputInterface) =>
  // need to use fetch for keepalive flag
  fetch(endpoints.SINGLE_SESSION(sessionId), {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    keepalive: true,
  });

export const sendVPPApi = async ({
  url,
  playerState,
}: {
  playerState: PlayerStatesEnum | undefined;
  url: string;
}) =>
  // need to use fetch for keepalive flag
  fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      state: playerState,
    }),
    keepalive: true,
  });

/**
 * Retry sendVPPApi a default 3 times if the HTTP error status code is included
 * in the statusCodes array.
 */
export const sendVPPApiWithRetry = async ({
  url,
  playerState,
  retries = 3,
  statusCodes = [],
}: {
  playerState: PlayerStatesEnum | undefined;
  retries?: number;
  statusCodes?: number[];
  url: string;
}) => {
  let lastError;

  for (let tries = 0; tries < retries; tries += 1) {
    try {
      return await sendVPPApi({ url, playerState });
    } catch (e) {
      lastError = e;

      if (!statusCodes.includes(e.response?.statusCode)) {
        throw e;
      }
    }
  }

  throw lastError;
};

export const playApi = (
  playId: string,
  query: { audio?: string; definition: string },
  authToken?: string,
) =>
  request
    .get(endpoints.PLAY(playId))
    .query({ ...query })
    .accept('json')
    .set('NS-Platform', 'WEB')
    .set('Access-Control-Allow-Origin', '*')
    .set('Authorization', `Bearer ${authToken}`);

export const bookmarkApi = ({
  authToken,
  bookmarkUrl,
  cognitoId,
  position,
}: {
  authToken: string;
  bookmarkUrl: string;
  cognitoId: string;
  position: number;
}) =>
  request.put(bookmarkUrl).set('Authorization', `Bearer ${authToken}`).send({
    cognitoId,
    position,
  });

export const fycPublicPlayApi = ({
  playId,
  version,
  definition,
}: FYCPlayInputInterface) =>
  request
    .get(endpoints.FYC_PLAY)
    .query({ playId, version, definition })
    .set('Content-Type', 'application/json')
    .accept('json');

export const getStringsApi = (query: string): Promise<any> =>
  request.post(endpoints.STRINGS_URL).send({ query });

export const activateApi = (
  code: string,
  accessToken?: string,
  refreshToken?: string,
) => {
  if (!accessToken && !refreshToken) {
    return Promise.reject('no access or refresh token provided');
  }
  // send the refresh token if available, otherwise send the access token
  return request
    .post(endpoints.ACCOUNT_ACTIVATE)
    .set('Authorization', `Bearer ${accessToken}`)
    .send({
      code,
      ...(refreshToken ? { refreshToken } : { accessToken }),
    });
};

export const forgotPasswordApi = (email: string) =>
  request.post(endpoints.FORGOT_PASSWORD).send({ email });

export const welcomeEnrollmentApi = (
  endpoint: string,
  givenName: string,
  familyName: string,
  password: string,
  token: string,
  twoFactorSecret?: string,
) =>
  fetch(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      givenName,
      familyName,
      password,
      token,
      twoFactorSecret,
    }),
    redirect: 'manual',
  });

export const searchByTemplateIdsApi = (
  accessToken: string,
  templateIds?: string[],
) =>
  request
    .post(endpoints.NDA_SEARCH_BY_TEMPLATEIDS)
    .set('Authorization', `Bearer ${accessToken}`)
    .send({ templateIds });

export const verifyOrCreateApi = (
  accessToken: string,
  templateId: string,
  returnUrl: string = window.location.href,
) =>
  request
    .put(endpoints.NDA_VERIFY_CREATE)
    .set('Authorization', `Bearer ${accessToken}`)
    .send({ templateId, doReturnUrl: returnUrl });

export const getAccountDetails = (accessToken: string) =>
  request
    .get(endpoints.ACCOUNT_DETAILS)
    .accept('json')
    .set('Content-Type', 'application/json')
    .set('Authorization', `Bearer ${accessToken}`);

export const getAccountStatus = (email: string) =>
  request
    .post(endpoints.ACCOUNT_STATUS)
    .accept('json')
    .set('Content-Type', 'application/json')
    .send({ what: email });

export const getSearchResults = (accessToken: string) =>
  request
    .get(endpoints.SEARCH_RESULTS)
    .accept('json')
    .set('Authorization', `Bearer ${accessToken}`)
    .set('NS-Platform', 'WEB');

export const getFycHelper = (accessToken: string) =>
  request
    .get(endpoints.FYC_HELPER)
    .accept('json')
    .set('Authorization', `Bearer ${accessToken}`)
    .set('NS-Platform', 'WEB');

export const reset2FA = ({
  subject,
  secret,
  token,
}: {
  secret: string;
  subject: string;
  token: string;
}) =>
  request
    .post(endpoints.ACCOUNT_RESET_2FA)
    .set('Content-Type', 'application/json')
    .accept('application/json')
    .send({ subject, secret, token });

export const getSwoogoEvents = (folderId: string) =>
  request
    .get(endpoints.SWOOGO_EVENTS)
    .query({ id: folderId })
    .accept('application/json');
