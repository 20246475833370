/** @jsx jsx */
import { jsx } from '@emotion/core';
import type { ReactNode } from 'react';
import clsx from 'clsx';
import NsImage from 'components/ns-component/ns-image';
import type { EntitlementComponentInterface } from 'models/components/entitlement-component-interface';
import { titleCss } from 'utils/styles';
import { TypographyStyleTypeEnum } from 'models/enums';
import Typography from 'components/typography';

const MAX_TITLE_TREATMENT_LENGTH = 10;
const MIN_TITLE_TREATMENT_TRIM_LENGTH = 40;

export const interpolateTitle = (title: string, end: number) => {
  if (title.length >= end) {
    title = title.slice(0, end).trim().concat('...');
  }

  return title;
};

const titleTreatmentClassNames = (title: string, isDesktop: boolean) => {
  const classNames = ['title-treatment'];
  if (title.length >= MAX_TITLE_TREATMENT_LENGTH && !isDesktop) {
    classNames.push('text-shrink');
  }

  return clsx(classNames);
};

export const renderTitle = (
  component: EntitlementComponentInterface,
  isPlain: boolean,
  isDesktop: boolean,
  shouldUseKeyArt = false,
): ReactNode => {
  if (!isPlain) {
    if (shouldUseKeyArt && component.keyArtImage) {
      return (
        <NsImage
          alt={component.keyArtImage.alt}
          aspectRatio={'16/9'}
          url={component.keyArtImage.url}
          width={500}
          isBackground
          hasGradientOverlay={false}
        />
      );
    } else {
      return (
        <NsImage
          /* @ts-ignore TODO: TS18048: component.heroImage is possibly undefined. */
          alt={component.heroImage.alt}
          aspectRatio={'16/9'}
          /* @ts-ignore TODO: TS18048: component.heroImage is possibly undefined. */
          url={component.heroImage.url}
          width={500}
          isBackground
          hasGradientOverlay={false}
        />
      );
    }
  }

  if (component.titleTreatmentImage) {
    return (
      <NsImage
        alt={component.titleTreatmentImage.alt}
        aspectRatio={'16/9'}
        url={component.titleTreatmentImage.url}
        width={500}
      />
    );
  }

  return (
    <h2
      /* @ts-ignore TODO: TS2345: Argument of type string | undefined is not assignable to parameter of type string. */
      className={titleTreatmentClassNames(component.title, isDesktop)}
      css={titleCss}
    >
      {/* @ts-ignore TODO: TS2345: Argument of type string | undefined is not assignable to parameter of type string. */}
      {interpolateTitle(component.title, MIN_TITLE_TREATMENT_TRIM_LENGTH)}
    </h2>
  );
};

export const renderSynopsis = (synopsis: string): ReactNode | undefined => {
  if (synopsis) {
    return (
      <Typography
        className="synopsis"
        isFullWidth
        style={TypographyStyleTypeEnum.BODY}
      >
        {synopsis}
      </Typography>
    );
  }
};
