/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import type {
  NSFycMovieMarqueeComponentInterface,
  NSFycSeriesComponentInterface,
} from 'models/cms';
import React, { useCallback, useState, useEffect } from 'react';
import { isNSFycSeriesComponent } from 'utils/fyc';
import {
  wrapperCss,
  imageContainerCss,
  plainViewCss,
  actionWrapperCss,
  titleInfoCss,
  maskCss,
  enterCodeBtnCss,
  titleTreatmentContainerCss,
} from './styles';
import NsImage from 'components/ns-component/ns-image';
import EnterCodeButton from '../enter-code-button';
import AccessCodeModal from '../access-code-modal';
import clock from 'public/images/Clock.svg';
import { minsToTime, numOfSeasons } from 'utils';
import {
  FYC_CODE_VALID,
  SHOW_FYC_T_AND_C,
} from 'components/fyc-component/access-code/helper';
import VideoActions from 'components/video-actions';
import { shouldShowVideoAction } from '../access-code/helper';
import TvEpisodicIcon from 'public/images/TV_Episodic.svg';
import {
  hasProxisTagForMovie,
  hasProxisTagForSeries,
  hideAccessPrompt,
} from 'components/fyc-component/fyc-details-banner/helpers';
import { useSelector } from 'react-redux';
import { fycDataSelector } from 'store/slices/fyc';

interface PropsInterface {
  data: NSFycSeriesComponentInterface | NSFycMovieMarqueeComponentInterface;
  hasPlain: boolean;
}

const DetailsBannerMobile: React.FC<PropsInterface> = ({ data, hasPlain }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [fycShowActions, setFycShowActions] = useState(false);
  const { title } = useSelector(fycDataSelector);
  const proxisMovie = hasProxisTagForMovie(data);
  const proxisSeries = hasProxisTagForSeries(data);
  const hideAccessCode = hideAccessPrompt(data);
  const isSeries = isNSFycSeriesComponent(data);

  const seriesComponent =
    isSeries ? (data as NSFycSeriesComponentInterface) : undefined;
  const movieMarquee =
    !isSeries ? (data as NSFycMovieMarqueeComponentInterface) : undefined;

  const onCodeValid = useCallback(() => {
    setIsOpen(false);

    if (shouldShowVideoAction(movieMarquee?.entitlement?.playId)) {
      setFycShowActions(true);
    } else {
      setFycShowActions(false);
    }
  }, [movieMarquee, setFycShowActions, setIsOpen]);

  const onShowTNC = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    window.addEventListener(SHOW_FYC_T_AND_C, onShowTNC);
    window.addEventListener(FYC_CODE_VALID, onCodeValid);

    onCodeValid();

    return () => {
      window.removeEventListener(FYC_CODE_VALID, onCodeValid);
      window.removeEventListener(SHOW_FYC_T_AND_C, onShowTNC);
    };
  }, [onCodeValid]);

  const actionPart = (absolute: boolean) => (
    <div css={actionWrapperCss(absolute)}>
      <div css={titleTreatmentContainerCss}>
        {data.titleTreatmentImage?.url ?
          <NsImage
            url={data.titleTreatmentImage?.url}
            width={1200}
            aspectRatio={'16/9'}
            hasGradientOverlay={false}
          />
        : <div>{title}</div>}
      </div>
      <div css={titleInfoCss}>
        {movieMarquee ?
          typeof movieMarquee.runtime === 'number' &&
          movieMarquee.runtime > 0 && (
            <div className="runtime">
              <img src={clock} />
              <span>{minsToTime(movieMarquee.runtime)}</span>
            </div>
          )
        : <div className="runtime">
            <img
              src={TvEpisodicIcon}
              className="clock-icon"
            />
            <span>{seriesComponent && numOfSeasons(seriesComponent)}</span>
          </div>
        }
        {data.rating && <div className="rating">{data.rating}</div>}
      </div>
      {!hideAccessCode && !fycShowActions && !hasPlain && movieMarquee && (
        <div>
          <EnterCodeButton
            customStyles={enterCodeBtnCss}
            onClick={() => setIsOpen(true)}
          />
        </div>
      )}

      {!hideAccessCode && fycShowActions && !hasPlain && data && (
        <div>
          <VideoActions
            component={data}
            progressData={{ error: null }}
          />
        </div>
      )}
      {(proxisMovie || proxisSeries) && (
        <div
          css={css`
            font-size: ${12 / 16}rem;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            margin-top: 1rem;
          `}
        >
          For the highest quality of this content, please watch on the Debut app
          available on Apple tvOS
        </div>
      )}
    </div>
  );

  return (
    <div css={wrapperCss}>
      {movieMarquee && (
        <AccessCodeModal
          component={data}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
      )}

      {hasPlain ?
        <div css={plainViewCss}>{actionPart(false)}</div>
      : <div>
          <div css={imageContainerCss}>
            {data.heroImage?.url && (
              <NsImage
                url={data.heroImage?.url}
                width={1500}
                aspectRatio={'2/3'}
                isBackground
                hasGradientOverlay={false}
              />
            )}
          </div>
          <div css={maskCss} />
          {actionPart(true)}
        </div>
      }
    </div>
  );
};

export default DetailsBannerMobile;
