/** @jsx jsx */
import type { SerializedStyles } from '@emotion/core';
import { css, jsx } from '@emotion/core';
import type {
  NSFycMovieMarqueeComponentInterface,
  NSFycSeriesComponentInterface,
} from 'models/cms';
import React, { useCallback, useState, useEffect } from 'react';
import NsImage from 'components/ns-component/ns-image';
import { isNSFycSeriesComponent } from 'utils/fyc';
import AccessCode from '../access-code';
import {
  FYC_CODE_VALID,
  shouldShowVideoAction,
  SHOW_FYC_T_AND_C,
} from '../access-code/helper';
import VideoActions from 'components/video-actions';
import clock from 'public/images/Clock.svg';
import { minsToTime, numOfSeasons } from 'utils';
import EnterCodeButton from '../enter-code-button';
import {
  hasProxisTagForMovie,
  hasProxisTagForSeries,
  hideAccessPrompt,
} from './helpers';

import {
  wrapperCss,
  containerCss,
  contentCss,
  imageContainerCss,
  actionsContainerCss,
  actionWrapperCss,
  titleInfoCss,
  videoActionCss,
  accessCodeWrapperCss,
  accessBtnWrapperCss,
  plainTitleCss,
  backgroundCss,
} from './styles';
import AccessCodeModal from '../access-code-modal';
import TvEpisodicIcon from 'public/images/TV_Episodic.svg';
import { useFycThemeContext } from 'providers/fyc-theme-provider';
import { useSelector } from 'react-redux';
import { fycDataSelector } from 'store/slices/fyc';

interface PropsInterface {
  data: NSFycMovieMarqueeComponentInterface | NSFycSeriesComponentInterface;
  hasPlain: boolean;
}

const proxisCss = (proxisSeries: boolean): SerializedStyles => {
  let marginBottom = 'inherit';
  if (proxisSeries) {
    marginBottom = `1rem`;
  }
  return css`
    font-size: ${12 / 16}rem;
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 1rem;
    margin-bottom: ${marginBottom};
  `;
};

const FycDetailsBanner: React.FC<PropsInterface> = ({ data, hasPlain }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [fycShowActions, setFycShowActions] = useState(false);

  const version = useFycThemeContext()?.version;
  const { title } = useSelector(fycDataSelector);

  const isSeries = isNSFycSeriesComponent(data);

  const seriesComponent =
    isSeries ? (data as NSFycSeriesComponentInterface) : undefined;

  const movieMarquee =
    !isSeries ? (data as NSFycMovieMarqueeComponentInterface) : undefined;

  const hideImageSection = hasPlain || (!hasPlain && !data.keyArtImage?.url);
  const proxisMovie = hasProxisTagForMovie(data);
  const proxisSeries = hasProxisTagForSeries(data);
  const hideAccessCode = hideAccessPrompt(data);

  const onCodeValid = useCallback(() => {
    setIsOpen(false);

    if (shouldShowVideoAction(movieMarquee?.entitlement?.playId)) {
      setFycShowActions(true);
    } else {
      setFycShowActions(false);
    }
  }, [movieMarquee, setFycShowActions]);

  const onShowTNC = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    window.addEventListener(SHOW_FYC_T_AND_C, onShowTNC);
    window.addEventListener(FYC_CODE_VALID, onCodeValid);

    onCodeValid();

    return () => {
      window.removeEventListener(SHOW_FYC_T_AND_C, onShowTNC);
      window.removeEventListener(FYC_CODE_VALID, onCodeValid);
    };
  }, [onCodeValid]);

  return (
    <React.Fragment>
      {movieMarquee && (
        <AccessCodeModal
          component={data}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
      )}

      <div css={backgroundCss(version)}>
        <div css={wrapperCss(data.heroImage?.url, hasPlain, version)}>
          <div css={containerCss}>
            <div css={contentCss(hideImageSection)}>
              {hideImageSection || hasPlain || !data.keyArtImage?.url ? null : (
                <div css={imageContainerCss}>
                  <NsImage
                    url={data.keyArtImage?.url}
                    width={1500}
                    aspectRatio={'16/9'}
                    isBackground
                    hasGradientOverlay={false}
                  />
                </div>
              )}

              <div
                css={actionsContainerCss(
                  hideImageSection,
                  version,
                  proxisSeries,
                )}
              >
                {!data.titleTreatmentImage && (
                  <div
                    className="plain-title"
                    css={plainTitleCss}
                  >
                    {title}
                  </div>
                )}
                <div
                  css={actionWrapperCss(
                    !data.titleTreatmentImage,
                    hideImageSection,
                  )}
                >
                  {data.titleTreatmentImage && (
                    <div style={{ width: '68%' }}>
                      <NsImage
                        alt={data.titleTreatmentImage?.alt}
                        aspectRatio={
                          data.titleTreatmentImage?.aspectRatioFractions?.[0] ||
                          '3/2'
                        }
                        url={data.titleTreatmentImage?.url}
                        width={1024}
                      />
                    </div>
                  )}

                  <div css={titleInfoCss(version)}>
                    {movieMarquee ?
                      typeof movieMarquee.runtime === 'number' &&
                      movieMarquee.runtime > 0 && (
                        <div className="runtime">
                          <img src={clock} />
                          <span>{minsToTime(movieMarquee.runtime)}</span>
                        </div>
                      )
                    : <div className="runtime">
                        <img
                          src={TvEpisodicIcon}
                          className="clock-icon"
                        />
                        <span>
                          {seriesComponent && numOfSeasons(seriesComponent)}
                        </span>
                      </div>
                    }

                    {movieMarquee && movieMarquee.rating && (
                      <div className="rating">{movieMarquee.rating}</div>
                    )}
                  </div>

                  {!hideAccessCode &&
                    !fycShowActions &&
                    !hasPlain &&
                    movieMarquee && (
                      <div css={accessBtnWrapperCss}>
                        <EnterCodeButton onClick={() => setIsOpen(true)} />
                      </div>
                    )}
                  {!hideAccessCode &&
                    !fycShowActions &&
                    !hasPlain &&
                    movieMarquee && (
                      <div css={accessCodeWrapperCss}>
                        <AccessCode component={data} />
                      </div>
                    )}
                  {!hideAccessCode && fycShowActions && !hasPlain && data && (
                    <div css={videoActionCss}>
                      <VideoActions
                        component={data}
                        progressData={{ error: null }}
                      />
                    </div>
                  )}
                </div>
                {(proxisMovie || proxisSeries) && (
                  <div css={proxisCss(proxisSeries)}>
                    For the highest quality of this content, please watch on the
                    Debut app available on Apple tvOS
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default FycDetailsBanner;
