import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import * as routes from './constants';
import { useDecodedJwt, useQuery } from 'utils/hooks';

interface PropsInterface {
  children: React.ReactNode;
  exact?: boolean;
  path?: string | string[];
}

interface StateHandlerOptionsInterface {
  decode?(e: string): string;
}
interface StateHandlerInterface {
  (state?: string | null, options?: StateHandlerOptionsInterface);
}

const stateHandler: StateHandlerInterface = (state = null) => {
  if (!state) {
    return;
  }

  try {
    // try to get license plate activate query param
    const { activate } = JSON.parse(state);

    if (activate) {
      return <Redirect to={`${routes.ACTIVATE}`} />;
    }

    return <Redirect to={state} />;
  } catch (e) {
    // redirect as intended
    return <Redirect to={state} />;
  }
};

const RedirectRoute: React.FC<PropsInterface> = ({ children, ...rest }) => {
  const decodedJwt = useDecodedJwt();
  const state = useQuery().get('state');

  const renderRoute = (props) => {
    const activate = props.location.state?.activate;
    const destination = state || props.location.state?.from;

    // redirect if user is authenticated
    if (decodedJwt) {
      // redirect to intended destination
      if (destination) {
        return stateHandler(destination);
      }

      // redirect back to activate
      if (activate) {
        return <Redirect to={`${routes.ACTIVATE}`} />;
      }

      // redirect to index page
      return (
        <Redirect
          to={{
            pathname: routes.INDEX,
            state: { from: props.location },
          }}
        />
      );
    }

    return children;
  };
  return (
    <Route
      render={renderRoute}
      {...rest}
    />
  );
};

export default RedirectRoute;
