/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import React from 'react';
import ProgressBar from '..';
import PlayButton from 'public/images/play-solid.svg';

interface PropsInterface {
  bookmark: { position: number; progress: number };
  progress: number;
}

const ProgressOverlayWrapper: React.FC<PropsInterface> = ({
  progress,
  bookmark,
}) => {
  const timeRemaining = () => {
    const { position, progress } = bookmark;
    const percentRemaining = 1 - progress;
    const runtimeInSeconds = position / progress;
    const remainingInMinutes = Math.ceil(
      (runtimeInSeconds * percentRemaining) / 60,
    );

    if (remainingInMinutes < 60) {
      return `${remainingInMinutes}m left`;
    }

    const remainingHours = Math.floor(remainingInMinutes / 60);
    const leftMins = remainingInMinutes - remainingHours * 60;

    return `${remainingHours}h ${leftMins}m left`;
  };

  // hide when no progress or progress >= 96
  if (!progress || progress >= 96) {
    return null;
  }

  return (
    <div
      css={css`
        position: absolute;
        z-index: 100;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: transparent;
        height: 100%;
        width: 100%;
        background: linear-gradient(0deg, #000 0%, rgba(14, 14, 14, 0) 35.71%);
        padding: 0 ${10 / 16}rem;
      `}
    >
      <div
        css={css`
          position: absolute;
          left: ${10 / 16}rem;
          right: ${10 / 16}rem;
          bottom: ${5 / 16}rem;
        `}
      >
        <div
          css={css`
            display: flex;
            justify-content: space-between;
            margin-bottom: ${6 / 16}rem;
          `}
        >
          <div
            css={css`
              position: relative;
              bottom: -2px;
            `}
          >
            <img src={PlayButton} />
          </div>
          <div
            css={css`
              font-size: ${12 / 16}rem;
              font-weight: 400;
              display: flex;
              flex-direction: column;
              justify-content: center;
            `}
          >
            <span
              css={css`
                margin-right: 5px;
              `}
            >
              {timeRemaining()}
            </span>
          </div>
        </div>
        <ProgressBar
          progress={progress}
          rootStyles={css`
            width: 100%;

            .progress-bar {
              width: 100%;
            }
          `}
          fullWidth={true}
        />
      </div>
    </div>
  );
};

export default ProgressOverlayWrapper;
