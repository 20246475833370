import type { SerializedStyles } from '@emotion/core';
import { css } from '@emotion/core';

export const btnCss = (
  style?: SerializedStyles,
): Array<SerializedStyles | undefined> => {
  const defaultCss = css`
    border-radius: ${4 / 16}rem;
    width: 100%;
    min-width: 105px;
    height: ${30 / 16}rem;
    line-height: ${30 / 16}rem;
    cursor: pointer;
    background-color: #212330;
    color: #fff;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `;

  return [defaultCss, style];
};
