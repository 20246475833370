import { getAccountDetails } from 'api';
import type { ProfileInterface } from 'models/profile-interface';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { authenticationSelector, tokenSelector } from 'store/slices/user';
import { useDecodedJwt } from './hooks';

const getProfile = (userId: string): ProfileInterface => {
  const profile = localStorage.getItem(userId);

  if (!profile) {
    return {};
  }

  try {
    return JSON.parse(profile) || {};
  } catch (e) {
    return {};
  }
};

const saveProfile = (userId: string, profile: ProfileInterface): void => {
  localStorage.setItem(userId, JSON.stringify(profile));
};

const deleteProfile = (userId: string): void => {
  localStorage.removeItem(userId);
};

/**
 * A hook used to grab profile data that is saved in local storage and then set as a state.
 * This hook will allow components to update, and re-render as neccesary.
 *
 * @returns Array - First index is the profile data and second to save profile udpates
 */
const useProfile = (): [
  profile: ProfileInterface,
  saveProfile: (profile: ProfileInterface) => void,
] => {
  const authentication = useSelector(authenticationSelector);
  const userId =
    typeof authentication.userId === 'function' ?
      // @ts-ignore TODO: TS2349: This expression is not callable.<br/>Type 'never' has no call signatures.
      authentication.userId()
    : authentication.userId;

  const { accessToken, refreshToken } = useSelector(tokenSelector);
  const decodedJwt = useDecodedJwt();

  const serialize = JSON.stringify;
  const deserialize = JSON.parse;
  const saveProfile = useCallback(
    (profile: ProfileInterface) => {
      if (!userId) {
        return;
      }

      localStorage.setItem(userId, serialize(profile));
    },
    [userId, serialize],
  );

  /**
   * Handles setting the initial state of the profile.
   * Will take care of bad values and set a default profile from local storage
   */
  const localProfile = useMemo(() => {
    if (!userId) {
      return {};
    }

    try {
      const local = localStorage.getItem(userId) || '{}';
      return deserialize(local) as ProfileInterface;
    } catch (e) {
      localStorage.setItem(userId, serialize({}));
      return {};
    }
  }, [userId, serialize, deserialize]);

  const [profile, setProfile] = useState<ProfileInterface>(() => localProfile);

  const fetchAccountDetails = useCallback(async () => {
    if (!accessToken) {
      return;
    }

    const { text } = await getAccountDetails(accessToken);
    const data = JSON.parse(text);
    profile.name = `${data.given_name} ${data.family_name}`;
    saveProfile(profile);
  }, [profile, saveProfile, accessToken]);

  /**
   * Handles fetching and saving the display name.
   * Access code users will just use the email/code provided in the JWT
   */
  const handleDisplayName = useCallback(() => {
    if (!profile.name && refreshToken) {
      fetchAccountDetails();
    } else if (!profile.name && !refreshToken) {
      profile.name = decodedJwt?.email;
      saveProfile(profile);
    }
  }, [fetchAccountDetails, profile, refreshToken, saveProfile, decodedJwt]);

  const handleInitialProfile = useCallback(() => {
    if (!profile.name) {
      handleDisplayName();
    }
  }, [handleDisplayName, profile]);

  /**
   * Handle our local storage profile, if none exist
   * Initiate a default profile
   */
  useEffect(() => {
    if (localProfile && localProfile.name) {
      setProfile(localProfile);
    } else {
      handleInitialProfile();
    }
  }, [localProfile, handleInitialProfile]);

  return [profile, saveProfile];
};

export { getProfile, saveProfile, useProfile, deleteProfile };
