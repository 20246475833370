import type { NSPageComponentInterface } from 'models/cms';
import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
} from 'react';
import { useLocation } from 'react-router-dom';
import { saveCurrentFycVersion } from 'pages/fyc-page/helpers';
import { Loader } from 'components';
import { useHistory } from 'react-router-dom';
import { NOT_FOUND } from 'router/constants';
import 'public/styles/fycFonts.css';
import { useStringsContext, CONFIG_KEYS } from './strings-provider';
import { getComponentsByType } from 'utils';
import { ComponentTypeEnum } from 'models/enums';
import { useSelector } from 'react-redux';
import { fycDataSelector } from 'store/slices/fyc';
import { removeQueryParam } from './sso-provider';

interface PropsInterface {
  children: React.ReactNode;
}

export const TWDS = 'twds';
export const POC = 'poc';
export const ABC = 'abc';
export const FREEFORM = 'freeform';
export const DTS = 'dts';
export const DBT = 'dbt';
export const HULU = 'hulu';
export const TWDS_AWARDS = 'twds-awards';
export const SEARCHLIGHT = 'searchlight';
export const NATGEO = 'natgeo';
export const SEARCHLIGHT_AWARDS = 'searchlight-awards';
export const FX = 'fx';
export const DISNEYPLUS = 'disneyplus';
export const ONYX = 'onyx';
export const ESPN = 'espn';

export type FycVersionType = string | null | undefined;

interface FycThemeContextInterface {
  version: FycVersionType;
}

export const FycThemeContext = createContext<FycThemeContextInterface | null>(
  null,
);

export const useFycThemeContext = () => useContext(FycThemeContext);

export const FycThemeProvider: React.FC<PropsInterface> = ({ children }) => {
  const [fycVersion, setFycVersion] = useState<FycVersionType | null>(null);
  const { getConfigByKey, isLoading } = useStringsContext();
  const { pathname } = useLocation();
  const history = useHistory();
  const { components } = useSelector(fycDataSelector);
  let favicon: NSPageComponentInterface | undefined;

  const faviconComponent = getComponentsByType(
    components,
    ComponentTypeEnum.FAVICON,
  );

  if (faviconComponent && faviconComponent.length) {
    favicon = faviconComponent[0];
  }

  const presentVersions = useMemo(() => {
    const presentVersionsJSONString = getConfigByKey(
      CONFIG_KEYS.FF_FYC_PORTALS,
    );

    if (presentVersionsJSONString) {
      try {
        return JSON.parse(presentVersionsJSONString)?.map((v) =>
          v.toLowerCase(),
        ) as string[];
      } catch (e) {}
    }
  }, [getConfigByKey]);

  useEffect(() => {
    if (isLoading) {
      return;
    }

    const exist =
      !!presentVersions && saveCurrentFycVersion(pathname, presentVersions);

    if (!exist) {
      history.push(NOT_FOUND);
    }
    setFycVersion(sessionStorage.getItem('fycVersion'));

    // dynamically change favicon
    if (favicon && favicon.image && favicon.image.url) {
      let link = document.querySelector("link[rel~='icon']") as HTMLLinkElement;
      if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
      }
      link.href = favicon.image.url;
    }
  }, [pathname, history, presentVersions, isLoading, favicon]);

  // remove exclusive tabs query between page switches
  useEffect(() => {
    const unListen = history.listen((location, action) => {
      // check when user click back button in browser
      if (action === 'POP') {
        const newQuery = removeQueryParam(history.location.search, 'tab');
        history.replace({
          ...history.location,
          search: newQuery,
        });
      }
    });

    return unListen;
  }, [history]);

  return (
    <FycThemeContext.Provider
      value={{
        version: fycVersion,
      }}
    >
      {fycVersion ? children : <Loader />}
    </FycThemeContext.Provider>
  );
};
