/** @jsx jsx */
import clsx from 'clsx';
import { jsx } from '@emotion/core';
import Typography from 'components/typography';
import React from 'react';
import appStoreLogo from 'public/images/Apple_App_Store_Button_Border.png';
import pixieDust from 'public/images/Pixie_Dust.png';
import { useStringsContext, STRING_KEYS } from 'providers/strings-provider';
import { appDownloadStyles } from './styles';

interface UnsupportedDevicePropsInterface {
  className?: string;
}

const UnsupportedDevice: React.FC<UnsupportedDevicePropsInterface> = ({
  className,
}) => {
  const { getStringByKey } = useStringsContext();
  const appStoreUrl = getStringByKey(STRING_KEYS.APP_STORE_BUILD_URL);

  const View: React.FC = ({ children }) => {
    return (
      <div
        data-testid="download-app"
        className={clsx(['plain-view-container', className])}
        css={appDownloadStyles}
      >
        {children}
      </div>
    );
  };

  return (
    <View>
      <div className="download-hero">
        <img
          src={pixieDust}
          className="download-pixie-dust"
        />
      </div>
      <Typography className="download-label">
        Download our iOS app to start streaming
      </Typography>
      <hr />
      <a
        target="_blank"
        rel="noreferrer"
        href={appStoreUrl}
      >
        <img
          src={appStoreLogo}
          width={175}
        />
      </a>
    </View>
  );
};

export default UnsupportedDevice;
