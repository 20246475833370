import type { ItemContainerInterface } from './brand-item';
import type { SerializedStyles } from '@emotion/core';
import { css } from '@emotion/core';
import theme from 'styles/theme';
import { mediaQuery } from 'utils/styles';

// base % width of col-md-2
const base = 16.667;

export const brandItemRootCss = (
  container: ItemContainerInterface | null | undefined,
  gridView: boolean | undefined,
): SerializedStyles => {
  if (gridView === true) {
    let aspectRatioCondition: string | undefined;

    if (container?.aspectRatio === '16:9') {
      aspectRatioCondition = '16/9';
    }

    if (container?.aspectRatio === '1:1') {
      aspectRatioCondition = '1/1';
    }

    return css`
      overflow: hidden;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 18%;
      aspect-ratio: ${aspectRatioCondition};

      ${mediaQuery(theme.breakpoints.md)} {
        width: 13%;
      }
      ${mediaQuery(theme.breakpoints.lg)} {
        max-width: 10%;
      }
    `;
  }

  return css`
    padding: 0;
    height: ${container ? `${container.height}px` : 'auto'};
    overflow: hidden;
    max-width: 18%;
    margin-right: 2.5%;
    width: 100%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1.5rem;

    ${mediaQuery(theme.breakpoints.md)} {
      margin-right: ${(100 - base * 5) / 4}%;
      max-width: ${base}%;
    }
  `;
};

const calculateMaxWidth = (brandsLength: number) => {
  if (brandsLength > 5) {
    return `${90 / brandsLength}%`;
  }

  return `${base}%`;
};

export const brandFilterRootCss = (
  brandsLength: number,
  cardGradients: string,
  cardBgColor: string,
): SerializedStyles => css`
  /**********************/
  /* Grid layout styles */
  /**********************/

  &.layout-style--grid {
    margin-top: 10px;
    padding: 0 1rem;

    .row {
      justify-content: center;
      row-gap: 15px;
      column-gap: 8px;
      width: 100%;
      margin: 0;
      ${mediaQuery(theme.breakpoints.md)} {
        padding: 0;
        column-gap: min(calc((100% - 7 * 13%) / 6), 8px);
      }
      ${mediaQuery(theme.breakpoints.lg)} {
        padding: 0 5.2%;
        column-gap: min(calc((100% - 9 * 10%) / 8), 8px);
      }
    }

    .brand-item {
      border: 1px solid ${theme.palette.background.primary};
      background: var(
        --new-brand-brand-card-bg,
        ${cardGradients},
        ${cardBgColor}
      );
      border-radius: 6px;
      overflow: hidden;
      box-shadow:
        0px 4px 5px 0px rgba(0, 0, 0, 0.14),
        0px 1px 10px 0px rgba(0, 0, 0, 0.12),
        0px 2px 4px -1px rgba(0, 0, 0, 0.2);

      &:hover {
        border: ${theme.border.cardFocus};
        transition: border 0.1s linear;
      }

      .brand-logo {
        width: 85%;
        padding: 10%;
        margin-left: 7%;
        ${mediaQuery(theme.breakpoints.md)} {
          width: 100%;
          margin-left: 0;
        }
      }
    }
  }

  /***********************/
  /* River layout styles */
  /***********************/

  &.layout-style--river {
    margin: 0;
    padding: 0;

    .row {
      justify-content: center;
      margin: 0;
      padding: 0;
      background-color: #161729;
      background-image: linear-gradient(136deg, #ffffff00, #222337);
    }

    .brand-item {
      margin: 0;
      transform: skewX(-20deg);
      padding: 0 0.1%;
      border-radius: 0;
      border-right: 2px solid #ffffff1f;
      border-left: 2px solid #00000061;
      max-width: ${calculateMaxWidth(brandsLength)};

      &:first-of-type {
        border-left: 2px solid #ffffff1f;
        box-shadow: inset 10px 0px 0px -8px #00000061;
        &:hover {
          box-shadow: inset 10px 0px 6px -6px black;
        }
      }

      &:last-of-type {
        border-right: 2px solid #00000061;
        box-shadow: inset -10px 0px 0px -8px #ffffff1f;
        &:hover {
          box-shadow: inset -10px 0px 6px -6px black;
        }
      }

      &:hover {
        border: ${theme.border.cardFocus};

        transition:
          padding 0.1s,
          border 0.1s;
        padding: 0 1.2%;
      }

      a {
        transform: skewX(20deg);
      }

      .brand-logo {
        width: 100%;
      }
    }

    ${mediaQuery(theme.breakpoints.md)} {
      .brand-item {
        padding: 0 2%;
      }
    }
  }
`;
