import type { SerializedStyles } from '@emotion/core';
import { css } from '@emotion/core';
import { mediaQuery } from 'utils/styles';
import {
  headerTextCss,
  loginInfoCss,
  actionButtonCss,
  actionButtonContainerCss,
  labelCss,
  checkboxCss,
  checkmarkCss,
} from '../styles';

import theme from 'styles/theme';
import { FYC_MOBILE_WIDTH, FYC_TABLET_WIDTH } from 'pages/fyc-page/helpers';

const headerStyle = () => {
  const styles = `
    letter-spacing: 0.6;

    ${mediaQuery(theme.breakpoints.md)} {
      letter-spacing: 1;
    }
    ${mediaQuery(theme.breakpoints.lg)} {
      letter-spacing: 0;
      margin-bottom: 0.5rem;
    }
  `;

  return headerTextCss({
    headerTextCss: styles,
  });
};

const subtitleCss = () => {
  const styles = `
    margin-top: 2.6rem; 
    margin-bottom: 0
    ${mediaQuery(theme.breakpoints.lg)} {
      margin: 0.5rem 0;
      font-size: ${20 / 16}rem;
      font-weight: 500;
      letter-spacing: -0.05;
    }
  `;

  return loginInfoCss({ loginInfoCss: styles });
};

const termsContentCss = css`
  color: ${theme.palette.text.secondary};
  margin-top: 1.75rem;
  margin-bottom: 3rem;
  line-height: 1.5;
  font-size: 1rem;
`;

const cancelButtonCss = css`
  margin-top: 1rem;
`;

const leftPanelCss = css`
  margin: 0;
  padding: 0 5%;
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: white transparent;
  text-align: left;

  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${theme.palette.common.white};
    border-radius: 2px;
  }
  ${mediaQuery(theme.breakpoints.md)} {
    padding: 0 7%;
    margin-right: 7%;
  }
  ${mediaQuery(theme.breakpoints.lg)} {
    padding: 0 7% 0 3%;
    margin: 6rem 7% 0 7%;
  }
`;

const leftComponentContainerCss = css`
  height: 80vh;

  ${mediaQuery(theme.breakpoints.lg)} {
    height: 100%;
    border-right: 2px solid ${theme.palette.common.darkblue};
    padding-right: 1.85rem;
  }
`;

const rightPanelCss = css`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  min-height: 20vh;
  border-top: 2px ${theme.palette.common.darkblue} solid;

  ${mediaQuery(theme.breakpoints.md)} {
    border: none;
    align-items: start;
    justify-content: start;
    padding: 2rem 9%;
  }

  ${mediaQuery(theme.breakpoints.lg)} {
    height: 100%;
    padding: 0 4.7%;
  }
`;

const actionButtonContainerStyle = () => {
  const styles = `
    width: 100%;
    ${mediaQuery(theme.breakpoints.md)} {
      margin-top: 2.6rem;
    }
  `;
  return actionButtonContainerCss({ actionButtonContainerCss: styles });
};

const goBackCss = css`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 0 3.5%;
  margin: 1.7rem 0;

  ${mediaQuery(theme.breakpoints.md)} {
    padding: 0 6%;
  }
  ${mediaQuery(theme.breakpoints.lg)} {
    margin: 3.6rem 0 1.5rem -0.5rem;
    padding: 0;
  }
`;

const goBackTextCss = css`
  line-height: 1.5;
  ${mediaQuery(theme.breakpoints.lg)} {
    margin-left: 0.45rem;
    letter-spacing: 1;
    line-height: 2;
  }
`;

const labelStyles = (isChecked) => {
  const styles = `
    margin-top: 0;
    ${mediaQuery(theme.breakpoints.lg)} {
      margin-top: 1rem;
    }
  `;

  return labelCss(isChecked, { labelCss: styles });
};

const logoContainerCss = css`
  ${mediaQuery(theme.breakpoints.lg)} {
    display: none;
  }
`;

const footerRootCss = css`
  margin: 0 -9%;

  .footer-fluid {
    padding: 0 8%;
  }

  label {
    margin-top: 2rem;
  }

  ${mediaQuery(theme.breakpoints.md)} {
    margin: 0 -11%;
    padding: 1.5rem 2rem;
  }

  ${mediaQuery(theme.breakpoints.lg)} {
    padding: 1.5rem 1rem;

    .footer-fluid {
      padding: 0 9.33%;
    }
  }
`;

const termsFooterRootCss = css`
  margin: 0 -9%;

  .footer-fluid {
    padding: 0 8%;
  }

  ${mediaQuery(theme.breakpoints.md)} {
    margin: 0 -11%;
    padding: 1.5rem 0;

    .footer-fluid {
      padding: 0 9%;
    }
  }

  ${mediaQuery(theme.breakpoints.lg)} {
    padding: 1.5rem 1rem;
    position: absolute;
    bottom: 0px;
    margin: 0;
    left: 0;

    .footer-fluid {
      padding: 0 7.33%;
    }
  }
`;

const layoutPageContainerCss = css`
  .terms-label {
    margin-top: 2rem;
  }

  .terms-action-container {
    margin: 1.55rem 0;
  }
`;

// 6rem is the margin around the goback button and 24px is height of the go back button
const fullWidthContainerCss = css`
  overflow-y: scroll;
  height: 75%;
  padding-right: 1rem;
`;

const fullWidthRightPanelCss = css`
  max-height: 100vh;
`;

const styles = {
  rightPanelCss,
  headerTextCss: headerStyle,
  subtitleCss,
  actionButtonCss,
  actionButtonContainerCss: actionButtonContainerStyle,
  leftPanelCss,
  termsContentCss,
  labelCss: labelStyles,
  checkboxCss,
  checkmarkCss,
  cancelButtonCss,
  leftComponentContainerCss,
  goBackCss,
  logoContainerCss,
  goBackTextCss,
  footerRootCss,
  fullWidthContainerCss,
  fullWidthRightPanelCss,
  termsFooterRootCss,
  layoutPageContainerCss,
};

export default styles;

///////////////////////////////
export const termsPageCss = css`
  height: 100vh;
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  ${mediaQuery(FYC_MOBILE_WIDTH)} {
    width: ${(698 / 1024) * 100}%;
  }

  ${mediaQuery(FYC_TABLET_WIDTH)} {
    width: ${(868 / 1440) * 100}%;
  }
`;

export const termsTopCss = css`
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  padding: 0 23px;
`;

export const termsTitleCss = css`
  display: flex;
  flex-direction: column;
  margin-bottom: ${30 / 16}rem;

  .title-text {
    margin-top: ${28 / 16}rem;
    margin-bottom: 1rem;
    font-size: ${20 / 16}rem;
    ${mediaQuery(FYC_MOBILE_WIDTH)} {
      font-size: ${24 / 16}rem;
    }
  }

  .waring-info {
    padding: ${10 / 16}rem;
    gap: ${10 / 16}rem;
    background: #fac266;
    border-radius: ${4 / 16}rem;
    display: flex;

    font-style: italic;
    font-size: ${12 / 16}rem;
    color: #1f1f20;

    max-width: ${630 / 16}rem;
  }
`;

export const termsContentsCss = css`
  flex: 1;
  overflow-y: auto;
  padding-bottom: ${200 / 16}rem;

  ::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
`;

export const termsBottomCss = css`
  height: ${195 / 16}rem;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #236df5;
  display: flex;
  flex-direction: column;
  padding: 1rem 1rem ${24 / 16}rem 1rem;

  ${mediaQuery(FYC_MOBILE_WIDTH)} {
    height: ${100 / 16}rem;
    flex-direction: row;
    justify-content: space-between;
    padding-left: ${(48 / 1024) * 100}%;
    padding-right: ${(48 / 1024) * 100}%;
  }

  ${mediaQuery(FYC_MOBILE_WIDTH)} {
    padding-left: ${(117 / 1440) * 100}%;
    padding-right: ${(117 / 1440) * 100}%;
  }

  .checkboxes {
    display: flex;
    flex-direction: column;
    gap: ${8 / 16}rem;
    font-size: ${14 / 16}rem;
  }

  .buttons {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 1rem;
    align-items: center;

    ${mediaQuery(FYC_MOBILE_WIDTH)} {
      flex-direction: row;
      gap: ${40 / 16}rem;
      margin-top: 0;
    }

    .cancel-button {
      margin: 0;
      display: inline-flex;
      font-size: 1rem;
      width: fit-content;
      padding: 0;
      margin: 0;
      background: none;
      color: white;
      cursor: pointer;
      margin-top: ${18 / 16}rem;

      ${mediaQuery(FYC_MOBILE_WIDTH)} {
        flex-shrink: 0;
        margin-top: 0;
      }
    }

    .terms-button {
      margin: 0;
      width: 100%;
      background: red;
      height: ${44 / 16}rem;
      background: #192de7;
      border-radius: ${4 / 16}rem;
      color: #ffffff;
      cursor: pointer;

      ${mediaQuery(FYC_MOBILE_WIDTH)} {
        width: ${250 / 16}rem;
      }

      :disabled {
        background: rgba(52, 52, 63, 1);
        color: #777881;
      }
    }
  }
`;

export const checkLabelCss = (enable: boolean): SerializedStyles => css`
  display: flex;
  align-items: center;
  justify-content: start;

  ${!enable && 'color: #A6A6A6;'}

  input[type='checkbox'] {
    position: relative;
    cursor: pointer;
    flex-shrink: 0;
  }
  input[type='checkbox']:before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border: 2px solid #d2d2d2;
    border-radius: 2px;
    background-color: white;
    padding: 1px;
    ${!enable && 'border: 2px solid rgba(255, 255, 255, 0.2);'}

    cursor: pointer;
    ${!enable && 'cursor: not-allowed;'}
  }
  input[type='checkbox']:checked::before {
    background: #4dcdd5;
  }

  input[type='checkbox']:checked::after {
    content: '';
    display: block;
    width: 7px;
    height: 12px;
    border: solid white;
    border-radius: 2px;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    top: 2px;
    left: 6px;
  }
`;

export const labelCheckboxCss = css`
  width: 20px;
  height: 20px;
  border-radius: 2px;
  cursor: pointer;
  margin-right: 10px;
`;

export const contentBodyCss = css`
  font-size: ${10 / 16}rem;

  ${mediaQuery(FYC_MOBILE_WIDTH)} {
    font-size: ${12 / 16}rem;
  }
`;

export const subTitleCss = css`
  font-size: 1rem;
  ${mediaQuery(FYC_MOBILE_WIDTH)} {
    font-size: ${20 / 16}rem;
  }
`;
