/** @jsx jsx */
import type { SerializedStyles } from '@emotion/core';
import { jsx, css } from '@emotion/core';
import React from 'react';
import { useHistory } from 'react-router-dom';

const wrapperCss = (
  styles?: SerializedStyles,
): Array<SerializedStyles | undefined> => {
  const defaultCss = css`
    height: 47px;
    border-radius: 2px;
    font-size: ${20 / 16}rem;
    font-weight: 500;
    letter-spacing: 2px;
    display: flex;
    align-items: center;
    width: ${123 / 16}rem;
    flex-direction: column;
    font-family: futura-pt-condensed;
    background: radial-gradient(
      65% 65% at 50% 50%,
      rgba(106, 106, 106, 0.42) 0%,
      rgba(83, 83, 83, 0) 100%
    );
    border: 1px solid rgba(254, 254, 254, 0.05);
    height: 60px;
    width: 123px;

    overflow: hidden;
    cursor: pointer;

    :hover {
      filter: drop-shadow(0px 4px 4px rgba(79, 79, 79, 0.25));
      background: rgba(251, 251, 251, 0.2);
    }

    & span {
      height: 43px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1;
    }

    & div {
      height: 4px;
      background: linear-gradient(90.04deg, #644d1b 0.71%, #c19a41 120.93%);
      transform: matrix(1, 0, 0, -1, 0, 0);
      width: 100%;
      item-align: start;
    }
  `;

  return [defaultCss, styles];
};

interface PropsInterface {
  displayValue: string;
  styles?: SerializedStyles;
  target: string;
}

const FycButton: React.FC<PropsInterface> = ({
  displayValue,
  target,
  styles,
}) => {
  const history = useHistory();

  return (
    <div
      css={wrapperCss(styles)}
      onClick={() => {
        history.push(`/${target}`);
      }}
    >
      <div />
      <span>{displayValue}</span>
    </div>
  );
};

export default FycButton;
