/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import React, { useState } from 'react';
import { Document, Page } from 'react-pdf';

import { pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

interface PropsInterface {
  fileUrl: string;
}

const PdfViewer: React.FC<PropsInterface> = ({ fileUrl }) => {
  const [numPages, setNumPages] = useState<number>();

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }): void => {
    setNumPages(numPages);
  };

  const displayPages = () => {
    if (!numPages) {
      return null;
    }

    const pages: JSX.Element[] = [];
    for (let i = 1; i <= numPages; i++) {
      pages.push(
        <div
          css={css`
            margin-bottom: 8px;
          `}
          key={i}
        >
          <Page
            pageNumber={i}
            renderAnnotationLayer={false}
          />
        </div>,
      );
    }

    return pages;
  };

  return (
    <div
      className="test"
      css={css`
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        overflow: auto;
        padding-top: 48px;
        padding-bottom: 16px;
      `}
    >
      <Document
        file={fileUrl}
        onLoadSuccess={onDocumentLoadSuccess}
        renderTextLayer={false}
      >
        {displayPages()}
      </Document>
    </div>
  );
};

export default PdfViewer;
