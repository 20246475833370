/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { generateStyle } from 'utils/styles';
import React from 'react';
import clsx from 'clsx';

const nsImageContainerCss = (theme, styles?: any) => {
  const defaultCss = `position: relative;`;

  return generateStyle({
    defaultStyle: defaultCss,
    extendedStyles: styles,
    key: 'nsImageContainerCss',
    theme,
  });
};

const nsImageCss = (theme, styles?: any) => {
  const defaultCss = `
  height: auto;
  width: 100%;
  z-index: 1;`;

  return generateStyle({
    defaultStyle: defaultCss,
    extendedStyles: styles,
    key: 'nsImageCss',
    theme,
  });
};

const backgroundCss = ({
  url,
  aspectRatio,
  width,
  hasGradientOverlay = true,
}: {
  aspectRatio: string;
  hasGradientOverlay: boolean;
  url: string;
  width: number;
}) => {
  let backgroundImage = 'background-image: ';

  if (hasGradientOverlay) {
    backgroundImage = backgroundImage.concat(`linear-gradient(
      -90deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.4) 59%,
      rgba(0, 0, 0, 0.73) 100%
    ), `);
  }

  backgroundImage = backgroundImage.concat(
    `url(${url}?r=${aspectRatio}&w=${width});`,
  );

  return css`
    background-size: cover;
    background-position: center;
    ${backgroundImage}
    width: 100%;
    height: 100%;
  `;
};

interface PropsInterface {
  alt?: string;
  aspectRatio: string;
  className?: string;
  css?: any;
  hasGradientOverlay?: boolean;
  isBackground?: boolean;
  playable?: any;
  styles?: any;
  url: string;
  width?: number;
}

const NsImage: React.FC<PropsInterface> = (props: PropsInterface) => {
  const {
    className,
    alt,
    aspectRatio,
    url,
    width,
    css,
    styles,
    isBackground,
    hasGradientOverlay,
  } = props;
  return (
    <div
      className={clsx(['nsimage', className])}
      css={(theme) => [
        css,
        nsImageContainerCss(theme, styles),
        isBackground &&
          /* @ts-ignore TODO: TS2322: Type number | undefined is not assignable to type number. */
          /* @ts-ignore TODO: TS2322: Type boolean | undefined is not assignable to type boolean. */
          backgroundCss({ url, aspectRatio, width, hasGradientOverlay }),
      ]}
    >
      {!isBackground && (
        <img
          css={(theme) => nsImageCss(theme, styles)}
          src={`${url}?r=${aspectRatio}` + (width ? `&w=${width}` : '')}
          alt={alt}
        />
      )}
    </div>
  );
};

export default NsImage;
