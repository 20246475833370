/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import React from 'react';
import Slider from 'react-slick';
import NsImage from '../ns-image';
import ComponentContainer from 'components/component-container';
import ChevronRightIcon from 'public/images/Slider_Chevron_Right.png';
import NsActionLink from '../ns-action-link';

interface PropsInterface {
  component: any;
}

/* calculate the aspect ratio that is closest to the preferred 3x1 aspect ratio */
const selectAspectRatio = (image) => {
  const preferred = 3;
  const distances = image.aspectRatioFractions.map((arf) => {
    const [w, h] = arf.split('/');
    return Math.abs(preferred - +w / +h);
  });
  const index = distances.indexOf(Math.min(...distances));
  return image.aspectRatioFractions[index];
};

const SliderArrow: React.FC<{ isPrev: boolean; onClick?: any }> = ({
  isPrev,
  onClick,
}) => {
  const className = isPrev ? 'prev' : 'next';
  const justifyContent = isPrev ? 'start' : 'end';
  const leftNRight = isPrev ? 'left: 0' : 'right: 0';
  const rotate = isPrev ? '180deg' : '0';

  return (
    <div
      className={`slick-arrow ${className}`}
      css={css`
        position: absolute;
        top: 50%;
        ${leftNRight};
        transform: translateY(-50%) rotate(${rotate});
        background: transparent;
        z-index: 1;
        display: flex;
        justify-content: ${justifyContent};
        align-items: center;
        opacity: 0;
      `}
    >
      <div
        css={css`
          display: 'block';
          cursor: pointer;
        `}
        onClick={onClick}
      >
        <img src={ChevronRightIcon} />
      </div>
    </div>
  );
};

const NsCarousel: React.FC<PropsInterface> = ({ component }) => {
  const sliderSettings = {
    infinite: true,
    autoplay: false,
    dots: true,
    prevArrow: <SliderArrow isPrev={true} />,
    nextArrow: <SliderArrow isPrev={false} />,
    // eslint-disable-next-line react/display-name
    appendDots: (dots) => (
      <div>
        <ul>{dots}</ul>
      </div>
    ),
    // eslint-disable-next-line react/display-name
    customPaging: () => (
      <button
        css={css`
          ::before {
            content: none !important;
          }
        `}
      ></button>
    ),
  };

  return (
    <ComponentContainer>
      <div
        className="row"
        css={css`
          overflow: hidden;

          .slick-slider {
            width: 100%;
            overflow: hidden;
          }

          .slick-dots {
            display: flex !important;
            justify-content: end;
            height: ${73 / 16}rem;
            background: linear-gradient(
              0deg,
              #000 -40.18%,
              rgba(35, 35, 35, 0) 100%
            );

            bottom: 0;

            ul {
              height: 0.5rem;
              display: flex;
              align-items: center;
              position: absolute;
              bottom: 1rem;
              right: 1rem;
              margin: 0;
              padding: 0;
            }

            li {
              width: 8px;
              height: 8px;
              background: rgba(255, 255, 255, 0.3);
              opacity: 0.5;
              border-radius: 50%;
              margin-right: 0;
              margin-left: 1rem;

              button {
                width: 8px;
                height: 8px;
                padding: 0;
              }
            }

            .slick-active {
              opacity: 1;
              background: #f2f2f2;
            }
          }

          :hover {
            .slick-arrow {
              opacity: 1;
            }
          }
        `}
      >
        <Slider {...sliderSettings}>
          {component.items.map((item) => (
            <div
              key={item.id}
              css={css`
                width: 100%;
                cursor: pointer;
              `}
            >
              <NsActionLink actions={item.actions}>
                <NsImage
                  styles={{
                    nsImageContainerCss: css`
                      border-radius: 6px;
                      overflow: hidden;
                      width: 100%;
                    `,
                  }}
                  alt={item.image.alt}
                  url={item.image.url}
                  aspectRatio={selectAspectRatio(item.image)}
                  width={1200}
                />
              </NsActionLink>
            </div>
          ))}
        </Slider>
      </div>
    </ComponentContainer>
  );
};

export default NsCarousel;
