import type { NSBannerComponentInterface } from 'models/cms';
import React, { useEffect, useRef, useState } from 'react';
import type { SerializedStyles } from '@emotion/core';
import styled from '@emotion/styled';
import theme from 'styles/theme';
import { useComponentTypeFinder, useWindowSize } from 'utils/hooks';
import type { HeroSettingsInterface } from 'utils/styles';
import { generateHeroStyle, generateStyle, mediaQuery } from 'utils/styles';
import chevronsIcon from 'public/images/Chevrons_Down.svg';
import { ComponentTypeEnum } from 'models/enums';

interface PropsInterface {
  className?: string;
  component: NSBannerComponentInterface;
  height?: number;
  isFycGrid?: boolean;
  styles?: SerializedStyles;
}

const renderBanner = ({ component, settings, styles, isgrid, isFycGrid }) => {
  // overwrite default styling
  const defaultStyle = `
    padding: 0;
    ${mediaQuery(theme.breakpoints.md)} {
      padding: 0;
    }
    ${mediaQuery(theme.breakpoints.lg)} {
      padding: 0;
    }
    ${isgrid ? gridBannerCss : ''}
    ${isFycGrid && 'border:none'}
  `;

  const extendedStyles = generateStyle({
    defaultStyle,
    extendedStyles: styles,
    key: 'bannerCss',
    theme,
  });

  const Banner = styled.div([
    generateHeroStyle({
      heroImage: component.image,
      settings,
    }),
    extendedStyles,
  ]);

  return (
    <div className="row">
      <Banner className="welcome-banner col-md-12 col-sm-12" />
    </div>
  );
};

const ChevronIcon = ({ className }) => {
  return (
    <div className={className}>
      <img
        className={className}
        src={chevronsIcon}
        alt="scroll for more content"
      />
    </div>
  );
};

const StyledChevron = styled(ChevronIcon)`
  display: none;

  ${mediaQuery(theme.breakpoints.lg)} {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 20%;
    transform: translate(-50%, -50%);
    right: 50%;
    left: 50%;

    img {
      width: 24px;
      height: 24px;
      animation-duration: 1.5s;
      animation-iteration-count: infinite;
      animation-name: bounce;
      cubic-bezier(0.280, 0.840, 0.420, 1);
    }
  }
  
  @keyframes bounce {
      0%   { transform: scale(1,1)      translateY(0); }
      10%  { transform: scale(1.1,.9)   translateY(0); }
      30%  { transform: scale(.9,1.1)   translateY(-7px); }
      50%  { transform: scale(1.05,.95) translateY(0); }
      57%  { transform: scale(1,1)      translateY(-1.5px); }
      64%  { transform: scale(1,1)      translateY(0); }
      100% { transform: scale(1,1)      translateY(0); }
      }
`;

const gridInfoContainerCss = `
    padding: 0 1rem;
    margin-top: 24px;

    .row {
      padding: 0 1rem;
    }

    ${mediaQuery(theme.breakpoints.md)} {
      .row {
        padding: 0 1.9%;
        margin-top: 2.5rem;
      }
    }

    ${mediaQuery(theme.breakpoints.lg)} {
      padding: 0 2rem;

      .row {
        padding: 0 5.2%;
        margin-top: 2.5rem;
      }
    }
`;

const gridBannerCss = `
  border: 1px solid ${theme.palette.background.primary};
  border-radius: 6px;
  overflow: hidden;
  width: 100%;
`;

const NsBannerComponent: React.FC<PropsInterface> = ({
  component,
  styles,
  isFycGrid,
  ...rest
}) => {
  const entitlements = useComponentTypeFinder(ComponentTypeEnum.ENTITLEMENTS);
  const windowSize = useWindowSize();
  const [containerWidth, setContainerWidth] = useState<number | null>(null);

  // const isGridView = isFycGrid || view === EntitlementViewTypeEnum.GRID;
  // always show grid view
  const isGridView = true;

  const remUnit = 16;
  const calculatedDesktopARHeight = 9 / 16; // height calculated based on a 16:9 aspect ratio
  const calculatedMobileARHeight = 3 / 4; // height calculated based on a 4:3 aspect ratio
  const desktopTabletHeight =
    /* @ts-ignore TODO: TS18048: windowSize.windowWidth is possibly undefined. */
    windowSize.windowWidth * calculatedDesktopARHeight;
  /* @ts-ignore TODO: TS18048: windowSize.windowWidth is possibly undefined. */
  const mobileHeight = windowSize.windowWidth * calculatedMobileARHeight;
  const containerRef = useRef<HTMLDivElement>(null);

  const heroSettings: HeroSettingsInterface = {
    xs: { ratio: '4/3', height: mobileHeight / remUnit },
    md: { ratio: '16/9', height: desktopTabletHeight / remUnit },
    lg: {
      ratio: '16/9',
      height: desktopTabletHeight / remUnit,
    },
  };

  if (isGridView) {
    const gridCalculatedDesktopARheight = 1 / 3; // height calculated based on a 3:1 aspect ratio
    //@ts-ignore TODO: TS18047: containerWidth is possibly null
    const gridDesktopHeight = containerWidth * gridCalculatedDesktopARheight;
    const gridCalculatedTabletARheight = 9 / 16; // height calculated based on a 16:9 aspect ratio
    //@ts-ignore TODO: TS18047: 'containerWidth' is possibly 'null'.
    const gridTabletHeight = containerWidth * gridCalculatedTabletARheight;
    const gridCalculatedMobileARheight = 3 / 4; // height calculated based on a 4:3 aspect ratio
    //@ts-ignore TODO: TS18047: 'containerWidth' is possibly 'null'.
    const gridMobileHeight = containerWidth * gridCalculatedMobileARheight;
    heroSettings.lg = { ratio: '3/1', height: gridDesktopHeight / remUnit };
    heroSettings.md = { ratio: '16/9', height: gridTabletHeight / remUnit };
    heroSettings.xs = { ratio: '4/3', height: gridMobileHeight / remUnit };
  }

  const InfoContainer = styled.div`
    position: relative;
    padding: 0;
    ${isGridView ? gridInfoContainerCss : ''}
  `;

  useEffect(() => {
    const el = containerRef.current?.querySelector('.welcome-banner');
    /* @ts-ignore TODO: TS2345: Argument of type Element | null | undefined is not assignable to parameter of type Element. */
    const { width } = window.getComputedStyle(el);
    setContainerWidth(Number(width.replace('px', '')));
  }, [containerRef, windowSize]);

  return component.image ?
      <InfoContainer
        ref={containerRef}
        className="info container-fluid center-sm"
      >
        {renderBanner({
          component,
          settings: heroSettings,
          styles,
          isgrid: isGridView,
          isFycGrid,
        })}
        {/*
        Don't show the Chevron if there are no entitlements,
        since there's nothing to indicate to the user to scroll down to.
      */}
        {entitlements && !isGridView && (
          <StyledChevron className={rest.className} />
        )}
      </InfoContainer>
    : <p>Not Available</p>;
};

export default NsBannerComponent;
