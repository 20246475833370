/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import React, { useState } from 'react';
import { itemWrapperCss, downloadWrapperCss } from './styles';
import PdfViewer from '../pdf-viewer';
import Modal from 'components/modal';
import CloseIcon from 'public/images/close.svg';
import downloadSymbol from 'public/images/downloadSymbol.svg';

interface PropsInterface {
  fileLink: string;
  filename: string;
  isLast: boolean;
  usePdfViewer: boolean;
}

const Item: React.FC<PropsInterface> = ({
  filename,
  fileLink,
  isLast,
  usePdfViewer,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <React.Fragment>
      <Modal
        isOpen={isOpen}
        extendedStyles={css`
          padding: 0;
        `}
      >
        <div
          css={css`
            position: absolute;
            top: 20px;
            right: 30px;
            cursor: pointer;
          `}
          onClick={() => setIsOpen(false)}
        >
          <img src={CloseIcon} />
        </div>
        <PdfViewer fileUrl={fileLink} />
      </Modal>
      <div css={itemWrapperCss(isLast)}>
        <div className="fileTitle">{filename}</div>
        {usePdfViewer ?
          <a
            css={downloadWrapperCss}
            onClick={() => {
              setIsOpen(true);
            }}
          >
            <span>VIEW</span>
          </a>
        : <a
            href={fileLink}
            download
            css={downloadWrapperCss}
            rel="noreferrer"
            target="_blank"
          >
            <div className="downloadIcon">
              <img src={downloadSymbol} />
            </div>
            <span>VIEW</span>
          </a>
        }
      </div>
    </React.Fragment>
  );
};

export default Item;
