/** @jsx jsx */
import type { SerializedStyles } from '@emotion/core';
import { jsx } from '@emotion/core';
import type { NdaServicePropsInterface } from 'hocs/with-nda-service';
import type { NSEntitlementItemInterface } from 'models/cms';
import React, { useState } from 'react';
import type { ProgressStateInterface } from 'store/slices/progress';
import type { RiverViewEntitlementItemNdaPropsInterface } from '../river-view-entitlement-item';
import RiverViewEntitlementItem from '../river-view-entitlement-item';
import { wrapperCss, contentCss, containerStyle } from './styles';
import ComponentContainer from 'components/component-container';
import { ComponentTypeEnum } from 'models/enums';
import { getComponentsByType } from 'utils';
import { useSelector } from 'react-redux';
import { pageDataSelector } from 'store/slices/page';

interface RiverViewEntitlementWrapperNdaInterface
  extends Omit<RiverViewEntitlementItemNdaPropsInterface, 'isLocked'> {
  verifyNdaCompletion: NdaServicePropsInterface['verifyNdaCompletion'];
}

interface PropsInterface {
  items: NSEntitlementItemInterface[];
  ndaProps: RiverViewEntitlementWrapperNdaInterface;
  progressData: ProgressStateInterface;
  rootContainerCss: SerializedStyles;
}

const RiverViewEntitlementWrapper: React.FC<PropsInterface> = ({
  items,
  ndaProps,
  progressData,
  rootContainerCss,
}) => {
  const [toggleIndex, setToggleIndex] = useState<number | null>(null);

  const { components } = useSelector(pageDataSelector);

  const hasBannerOrHeroOrBrand = [
    ComponentTypeEnum.BANNER,
    ComponentTypeEnum.HERO,
    ComponentTypeEnum.BRAND_GRID,
    ComponentTypeEnum.BANNER_HIDE,
  ].some((type) => getComponentsByType(components, type)?.length !== 0);

  const divCss = contentCss(
    toggleIndex,
    hasBannerOrHeroOrBrand,
    items.length === 2,
  );

  const content = (
    <div css={divCss}>
      {items.map((item, index) => (
        <RiverViewEntitlementItem
          key={item.id}
          item={item}
          index={index}
          ndaProps={{
            ...ndaProps,
            isLocked: !ndaProps.verifyNdaCompletion(
              item.entitlement?.ndaTemplate ?? '',
            ),
          }}
          rootContainerCss={rootContainerCss}
          progressData={progressData}
          setToggleIndex={setToggleIndex}
          toggleIndex={toggleIndex}
          onlyOneItem={items.length === 1}
          totalTwoItems={items.length === 2}
        />
      ))}
    </div>
  );

  return (
    <React.Fragment>
      <ComponentContainer rootCss={containerStyle(hasBannerOrHeroOrBrand)}>
        <div
          className="row"
          css={wrapperCss(hasBannerOrHeroOrBrand)}
        >
          {content}
        </div>
      </ComponentContainer>
    </React.Fragment>
  );
};

export default RiverViewEntitlementWrapper;
