export const LOGIN_LANDING = '/login';
export const LOGIN_EMAIL = '/login/email';
export const LOGIN_ACCESS_CODE = '/login/access-code';
export const LOGIN_OAUTH_RETURN = '/sentry/return';
export const LOGIN_KIDS = '/login/family';
export const ACTIVATE = '/activate';
export const TERMS = '/terms';
export const INDEX = '/';
export const MOVIE_DETAILS = '/related/:slug';
export const FORGOT_PASSWORD = '/forgot-password';
export const RESET_PASSWORD = '/reset-password/:token';
export const NOT_FOUND = '/not-found';
export const BAFTA = '/bafta/:playId';
export const SSO = '/sso/:playId';
export const HELP = '/settings/help-center';
export const WELCOME = '/welcome/:token';
export const UNSUPPORTED_BROWSER = `/unsupported-browser`;
export const RESET_2FA = '/reset-2fa/:resetToken';
export const FYC = '/fyc';
