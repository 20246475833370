import { css } from '@emotion/core';
import theme from 'styles/theme';
import { mediaQuery } from 'utils/styles';

export const wrapperCss = css`
  height: 100%;
  position: relative;

  ${mediaQuery(theme.breakpoints.lg)} {
    width: ${(983 / 1440) * 100}vw;
  }
`;

export const actionWrapperCss = css`
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  padding-left: 2rem;
  padding-bottom: 1rem;
`;

export const runtimeRatingCss = css`
  display: flex;
  font-size: ${14 / 16}rem;
  gap: 1rem;
  margin: 1rem 0;

  .runtime-info {
    img {
      width: 1rem;
      height: 1rem;
      margin-right: 0.5rem;
    }

    span {
      vertical-align: text-bottom;
    }
  }

  .rating {
    border-radius: 18px;
    border: 1px solid #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 1px;
    padding-left: 8px;
    padding-right: 8px;
    flex-wrap: wrap;
    margin-left: 0;
  }
`;

export const titleCss = css`
  font-size: ${24 / 16}rem;
  font-wight: 600;
`;

export const nsImageContainerCss = css`
  img {
    max-height: ${(100 / 1440) * 100}vw;
    width: auto;
  }
`;

export const videoActionsWrapperCss = css`
  display: flex;
  flex-direction: row;
  height: auto !important;
  margin: 0 !important;
  gap: 1rem;

  .action-button-container {
    order: initial !important;
    min-width: initial !important;
    flex-basis: initial !important;
    margin: 0 !important;
  }

  .action-btn {
    background: rgba(150, 150, 160, 1);
  }

  button {
    width: auto !important;
    max-height: 2rem;

    font
  }
`;
