/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import React, { useMemo } from 'react';

import NsLink from 'components/ns-component/ns-link';

import { appBarHeight } from 'styles/theme';
import { Logo } from 'components';
import { mediaQuery, generateStyle } from 'utils/styles';
import Settings from 'components/settings';
import withDeviceProtection from 'hocs/with-device-protection';
import { DeviceTypeEnum } from 'models/enums';
import SearchBar from 'components/search-bar';
import { useStringsContext, CONFIG_KEYS } from 'providers/strings-provider';
import { tokenSelector } from 'store/slices/user';
import { useSelector } from 'react-redux';
import { useDecodedJwt } from 'utils/hooks';

const rootCss: any = (theme) => ({
  display: 'flex',
  justifyContent: 'space-between',
  height: appBarHeight,
  width: '100%',
  position: 'fixed',
  zIndex: theme.zIndex.appBar,
  color: theme.palette.primary.contrastText,
  padding: `0 ${theme.spacing.unit * 3} 0 ${theme.spacing.unit * 3}`,
  boxShadow: theme.shadows[1],
  background: `var(--new-brand-page-bg-23, linear-gradient(136deg, rgba(8, 5, 73, 0.00) 0%, #050524 105.01%), #060528)`,
  backdropFilter: `blur(27.182817459106445px)`,
});

const appbarContainer = (theme, styles?: any) => {
  const defaultStyle = `
  padding: 0 4%;

  .nav-left-container, .nav-right-container {
    display: flex;
    align-items: center;

    .screen-together-btn {
      display: none;
      height: 2.25rem;
    }
    
    .search-bar {
      display: none;
    }
  }

  ${mediaQuery(theme.breakpoints.md)} {
    padding: 0 8.33%;

    .nav-left-container, .nav-right-container {
      .screen-together-btn {
        display: block;
        height: 2.25rem;
      }
      
      .search-bar {
        display: flex;
      }
    }
  }
`;
  return generateStyle({
    defaultStyle,
    extendedStyles: styles,
    key: 'appbarContainer',
    theme,
  });
};

const logoCss = css`
  width: 130px;
  cursor: pointer;
`;

const brandingCss = css`
  display: flex;
  flex-flow: column;
  justify-content: center;
`;

const navCss = (theme) => css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  ${mediaQuery(theme.breakpoints.lg)} {
    margin-right: 2.3%;
  }
`;

interface PropsInterface {
  showSettings?: boolean;
  styles?: any;
}

const NsAppBar: React.FC<PropsInterface> = (props) => {
  const { styles, showSettings } = props;
  const { getConfigByKey } = useStringsContext();
  const { accessToken } = useSelector(tokenSelector);
  const decodedJwt = useDecodedJwt();

  const SearchBarInput = useMemo(
    () =>
      withDeviceProtection(SearchBar, {
        devices: [DeviceTypeEnum.DESKTOP],
      }),
    [],
  );

  return (
    <div
      data-testid="top-nav-bar"
      css={(theme) => [rootCss(theme), appbarContainer(theme, styles)]}
    >
      <div className="nav-left-container">
        <div css={brandingCss}>
          <NsLink to="/">
            <Logo
              horizontal
              logoStyles={logoCss}
            />
          </NsLink>
        </div>
      </div>
      <div className="nav-right-container">
        {accessToken &&
          decodedJwt &&
          getConfigByKey(CONFIG_KEYS.FF_SEARCH_WEB) === 'true' && (
            <SearchBarInput />
          )}
        {showSettings && (
          <nav css={navCss}>
            <Settings />
          </nav>
        )}
      </div>
    </div>
  );
};

export default NsAppBar;
