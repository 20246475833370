/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import type { AllContentConfigInterface } from 'providers/fyc-theme-component-interfaces';
import { FycConfigsEnum } from 'providers/fyc-theme-component-interfaces';
import { getVersionedComponentConfig } from 'providers/versions/fyc-theme-provider-utils';
import React from 'react';
import NsImage from 'components/ns-component/ns-image';
import { imgContainerCss, imgWrapperCss, titleContainerCss } from './styles';
import { useHistory } from 'react-router-dom';
import { getPageTarget } from 'utils/fyc';
import type { FycVersionType } from 'providers/fyc-theme-provider';
import { TWDS_AWARDS, useFycThemeContext } from 'providers/fyc-theme-provider';
import { TWDS_AWARDS_CARD_BG } from 'styles/fyctheme';

interface PropsInterface {
  hasPlain?: boolean;
  items?: any;
}

const nsImageExtendedCss = {
  nsImageContainerCss: css`
    border-radius: 6px;
    box-shadow: 10px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 13.3%;
  `,
};

const plainViewCss = (version: FycVersionType) => {
  const customCssConfig = getVersionedComponentConfig<
    AllContentConfigInterface['plainViewCss']
  >({
    version,
    component: FycConfigsEnum.ALL_CONTENT_CONFIG,
    selector: 'plainViewCss',
  });

  let topBg =
    customCssConfig?.topBg ??
    `linear-gradient(
    359.43deg,
    rgba(0, 78, 146, 0.46) -294.8%,
    #000428 34.06%,
    #004e92 276.33%
  )`;

  if (version === TWDS_AWARDS) {
    topBg = TWDS_AWARDS_CARD_BG;
  }

  return css`
    // shadow layer
    width: 100%;
    height: 100%;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;

    background: linear-gradient(180deg, #404461 0%, #232434 100%);
    box-shadow:
      0px 4px 5px rgba(0, 0, 0, 0.14),
      0px 1px 10px rgba(0, 0, 0, 0.12),
      0px 2px 4px -1px rgba(0, 0, 0, 0.2);

    // rectangle layer
    .top {
      width: 100%;
      height: 100%;

      background: ${topBg};

      border-radius: 6px;

      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 1rem;
    }
  `;
};

const AllContent: React.FC<PropsInterface> = ({ items, hasPlain }) => {
  const history = useHistory();
  const version = useFycThemeContext()?.version;

  return (
    <React.Fragment>
      <div css={imgWrapperCss}>
        {items?.length > 0 &&
          items?.map((en) => (
            <div
              key={en.id}
              onClick={() => history.push(`/${getPageTarget(en)}`)}
              css={imgContainerCss(hasPlain)}
            >
              {hasPlain ?
                <div css={plainViewCss(version)}>
                  <div className="top">
                    {en.titleTreatmentImage?.url ?
                      <NsImage
                        url={en.titleTreatmentImage?.url}
                        width={800}
                        aspectRatio={'2/3'}
                        styles={nsImageExtendedCss}
                      />
                    : <div css={titleContainerCss}>{en.title}</div>}
                  </div>
                </div>
              : <NsImage
                  url={en.keyArtImage?.url}
                  width={800}
                  aspectRatio={'2/3'}
                  isBackground
                  hasGradientOverlay={false}
                  styles={nsImageExtendedCss}
                />
              }
            </div>
          ))}
      </div>
    </React.Fragment>
  );
};

export default AllContent;
