/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useEffect, useRef, useState } from 'react';
import type { FormikProps, FormikValues } from 'formik';
import { Field, Formik, Form } from 'formik';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
  userErrorSelector,
  resetPassword,
  passwordResetSelector,
} from 'store/slices/user';
import { Input, Button, Loader } from 'components';
import { LayoutContainer } from 'containers';
import * as routes from 'router/constants';
import eyeIcon from 'public/images/Eye.svg';
import eyeOffIcon from 'public/images/Eye_Off.svg';

import {
  headerTextCss,
  actionButtonCss,
  actionButtonContainerCss,
  successInfoContainerCss,
  loginInfoCss,
  hintCss,
} from './styles';
import { useStringsContext, STRING_KEYS } from 'providers/strings-provider';
import Typography from 'components/typography';

interface RouteParamsInterface {
  token: string;
}

const ResetPassword: React.FC = () => {
  const dispatch = useDispatch();
  const [isPwdVisible, setIsPwdVisible] = useState<boolean>(false);
  const { token } = useParams<RouteParamsInterface>();
  const { getStringByKey } = useStringsContext();
  const userError = useSelector(userErrorSelector);
  const resetState = useSelector(passwordResetSelector);
  const formikRef = useRef<FormikProps<FormikValues>>();

  const togglePwdVisible = () => setIsPwdVisible((prevState) => !prevState);

  const onSubmit = ({ password }) => {
    dispatch(resetPassword(password, token));
  };

  useEffect(() => {
    if (resetState) {
      formikRef.current?.setSubmitting(false);
    }
  }, [resetState]);

  useEffect(() => {
    if (userError) {
      const message = getStringByKey(STRING_KEYS.AUTH_WELCOME_INVALID_PW);
      formikRef.current?.setSubmitting(false);
      formikRef.current?.setFieldError('password', message);
    }
  }, [getStringByKey, userError]);

  const form = (
    <Formik
      initialValues={{
        password: '',
      }}
      onSubmit={onSubmit}
      innerRef={formikRef as any}
    >
      {({ isSubmitting, errors, values }) => (
        <Form noValidate>
          {isSubmitting && <Loader />}
          <h2
            css={headerTextCss}
            data-testid="reset-password-title"
          >
            Reset your Password
          </h2>
          <Field name="password">
            {({ field }) => (
              <Input
                type={isPwdVisible ? 'text' : 'password'}
                placeholder="Enter your password"
                disabled={isSubmitting}
                data-testid="reset-password-input"
                error={errors.password}
                icon={{
                  src: isPwdVisible ? eyeOffIcon : eyeIcon,
                  onClick: togglePwdVisible,
                  'data-testid': 'reset-password-toggle',
                }}
                autoFocus
                {...field}
              />
            )}
          </Field>
          <p
            css={hintCss}
            data-testid="reset-password-hint"
          >
            {getStringByKey(STRING_KEYS.AUTH_RESET_PASSWORD)}
          </p>
          <div css={actionButtonContainerCss}>
            <Button
              type="submit"
              theme="primary"
              data-testid="reset-password-submit"
              disabled={!values.password}
              css={actionButtonCss}
            >
              Reset Password
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );

  const successInfo = (
    <div
      css={successInfoContainerCss}
      className="col-sm-12"
    >
      <h2
        css={headerTextCss}
        data-testid="reset-password-success-title"
      >
        Your Password has been reset
      </h2>
      <Typography additionalCss={loginInfoCss()}>
        {getStringByKey(STRING_KEYS.AUTH_RESET_PASSWORD_SUCCESS_DESCRIPTION)}
      </Typography>
      <div css={actionButtonContainerCss}>
        <Link
          to={routes.LOGIN_EMAIL}
          data-testid="reset-password-success-link"
        >
          <Button
            css={actionButtonCss}
            theme="primary"
          >
            Proceed to Log In
          </Button>
        </Link>
      </div>
    </div>
  );

  return (
    <LayoutContainer auth>{resetState ? successInfo : form}</LayoutContainer>
  );
};

export default ResetPassword;
