/** @jsx jsx */
import { jsx } from '@emotion/core';
import { getSessionIdParam } from 'components/player/utilities';
import React, { useEffect, useState } from 'react';

const getCastContext = () =>
  window?.cast?.framework?.CastContext?.getInstance();
const getCastMediaByContext = (castContext = getCastContext()) =>
  castContext?.getCurrentSession()?.getMediaSession();

export interface CastSessionStateInterface {
  castSessionId: string | null;
}

export interface CastPlaybackStateInterface {
  playbackSessionId: string;
}

export interface CastStateInterface {
  data: CastPlaybackStateInterface;
  getContext(): any;
  getMedia(): any;
  session: CastSessionStateInterface;
}

const initialSessionState: CastSessionStateInterface = {
  castSessionId: null,
};

const initialPlaybackState: CastPlaybackStateInterface = {
  /* @ts-ignore TODO: TS2322: Type null is not assignable to type string. */
  playbackSessionId: null,
};

const withChromecast = (
  WrappedComponent: React.ComponentType<any>,
): React.FC<any> => {
  const WithChromecast = (props: any) => {
    const [sessionState, setSessionState] =
      useState<CastSessionStateInterface>(initialSessionState);
    const [playbackState, setPlaybackState] =
      useState<CastPlaybackStateInterface>(initialPlaybackState);
    const castContext = getCastContext();
    const media = castContext && getCastMediaByContext(castContext);

    useEffect(() => {
      if (media) {
        const url = media.media.customData?.drm?.licenseUrl;
        const sessionId = getSessionIdParam(url);

        setSessionState((state) => ({
          ...state,
          castSessionId: media.sessionId,
        }));

        setPlaybackState((state) => ({
          ...state,
          playbackSessionId: sessionId,
        }));
      }
    }, [media]);

    return (
      <WrappedComponent
        {...props}
        cast={{
          session: sessionState,
          data: playbackState,
          getContext: getCastContext,
          getMedia: getCastMediaByContext,
        }}
      />
    );
  };

  // prevent the linter from complaining about a missing display name
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || 'Component';
  WithChromecast.displayName = `WithChromecast(${displayName})`;
  return WithChromecast;
};

export default withChromecast;
