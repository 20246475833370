import type { SerializedStyles } from '@emotion/core';
import { css } from '@emotion/core';

export const contentWrapperCss = css`
  .terms-content {
    overflow-y: auto;

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;

    ::-webkit-scrollbar {
      display: none; /* for Chrome, Safari, and Opera */
    }
  }

  .terms-title {
    font-weight: 500;
    font-size: ${24 / 16}rem;
  }
`;

export const labelCss = (enable: boolean): SerializedStyles => css`
  flex-shrink: 4;

  display: flex;
  align-items: center;
  justify-content: start;
  margin-top: ${16 / 16}rem;

  ${!enable && 'color: #A6A6A6;'}

  input[type='checkbox'] {
    position: relative;
    cursor: pointer;
  }
  input[type='checkbox']:before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border: 2px solid rgba(255, 255, 255, 0.65);
    border-radius: 3px;
    background-color: #212330;
    padding: 1px;
    ${!enable && 'border: 2px solid rgba(255, 255, 255, 0.2);'}

    cursor: pointer;
    ${!enable && 'cursor: not-allowed;'}
  }
  input[type='checkbox']:checked::before {
    background-color: #5e94f7;
  }

  input[type='checkbox']:checked::after {
    content: '';
    display: block;
    width: 7px;
    height: 12px;
    border: solid white;
    border-radius: 1px;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    top: 2px;
    left: 6px;
  }
`;

export const checkboxCss = css`
  width: 20px;
  height: 20px;
  border-radius: 2px;
  cursor: pointer;
  margin-right: 10px;
`;

export const messageWrapperCss = css`
  display: inline-flex;
  justify-content: start;
  align-items: center;
  width: auto;
  background: #272727;
  border-radius: 4px;
  padding: ${9 / 16}rem;
  margin-top: 1rem;
`;

export const imgWrapperCss = css`
  width: ${17 / 16}rem;
  height: ${17 / 16}rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${9 / 16}rem;
`;
