/** @jsx jsx */
import { jsx } from '@emotion/core';
import { EntitlementTypeEnum } from 'models/enums';
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import type { NsActionInterface } from 'models/cms/ns-action-interface-old';
import type { NsEntitlementCastingInterface } from 'models/cms/ns-entitlement-interface-old';
import type { EntitlementComponentInterface } from 'models/components/entitlement-component-interface';
import { ActionTypeEnum } from 'models/enums';
import type { ProgressStateInterface } from 'store/slices/progress';
import {
  buildTitle,
  calculateAspectRatioOfHeight,
  treatmentContainerStyles,
} from 'utils';
import {
  interpolateTitle,
  renderSynopsis,
  renderTitle,
} from './helpers/renderers';
import styles, { titleCss, dividedLineCss } from './styles';
import clsx from 'clsx';
import Typography from 'components/typography';
import { useStringsContext, STRING_KEYS } from 'providers/strings-provider';
import type { EntitlementComponentCastingInterface } from 'models/components/entitlement-component-interface';
import { ComponentTypeEnum } from 'models/enums';
import UnlockButton from 'components/unlock-button';
import ExpiryDate from 'components/expiry-date';
import { usePlayer } from 'providers/player-provider';
import type { OpenPlayerInterface } from 'models/interfaces/player-provider';
import { useComponentTypeFinder } from 'utils/hooks';
import { NDATemplateTypesEnum } from 'models/enums';
import Alert from 'components/alert';
import ProgressOverlayWrapper from 'components/progress-bar/progress-overlay-wrapper';

const MAX_TITLE_LENGTH = 60;

const toggleGlow = (e) => {
  e.preventDefault();
  e.currentTarget.classList.toggle('glow');
};

export interface EntitlementGridComponentInterface {
  component?: NsEntitlementCastingInterface;
  handleNdaClick(templateId: string): Promise<void>;
  isDesktop?: boolean;
  isPlain?: boolean;
  progressData: ProgressStateInterface;
  shouldUseKeyArt?: boolean;
  type?: ComponentTypeEnum;
  verifyNdaCompletion(templateId: string): boolean;
}

const defaultLayout = (item) => (
  <React.Fragment>
    <div className="entitlement-title">
      {interpolateTitle(item.title, MAX_TITLE_LENGTH)}
    </div>
    {renderSynopsis(item.synopsis)}
    {item.docType === EntitlementTypeEnum.MOVIE && (
      <ExpiryDate entitlement={item.entitlement} />
    )}
  </React.Fragment>
);

const seriesLayout = (item, type = ComponentTypeEnum.SERIES) => {
  return (
    <React.Fragment>
      <Typography
        className="episode-title"
        isFullWidth
      >
        {type === ComponentTypeEnum.RELATED_CONTENT ?
          item?.title
        : buildTitle(item)}
      </Typography>
      <ExpiryDate entitlement={item.entitlement} />
      {renderSynopsis(item.synopsis)}
    </React.Fragment>
  );
};

const showSeriesLayout = (type: ComponentTypeEnum) => {
  return (
    type === ComponentTypeEnum.SERIES ||
    type === ComponentTypeEnum.RELATED_CONTENT
  );
};

const EntitlementGrid: React.FC<EntitlementGridComponentInterface> = ({
  component,
  isPlain,
  type = ComponentTypeEnum.ENTITLEMENTS,
  verifyNdaCompletion,
  handleNdaClick,
  isDesktop,
  progressData,
  shouldUseKeyArt,
}) => {
  const [container, setContainer] = useState<{
    aspectRatio: string | '16:9' | '4:3' | '3:1';
    height: number;
    width: number;
  } | null>(null);
  const treatmentContainerRef = useRef<HTMLDivElement>();
  const { getConfigByKey, getStringByKey } = useStringsContext();
  const { openPlayer } = usePlayer();
  const [showNDAAlert, setShowNDAAlert] = useState(false);
  const [currentTarget, setCurrentTarget] = useState<string | null>(null);

  const onResize = () => {
    if (treatmentContainerRef.current) {
      const width = Number(
        window
          .getComputedStyle(treatmentContainerRef.current)
          .width.replace('px', ''),
      );
      const containerSize = calculateAspectRatioOfHeight('16:9', width);
      /* @ts-ignore TODO: TS2345: Argument of type { aspectRatio: string; height: number; width: number; } | undefined is not assignable to parameter of type SetStateAction<{ aspectRatio: string; height: number; width: number; }>. */
      setContainer(containerSize);
    }
  };

  const PlayActionWrapper = ({ target, component, children }) => {
    return (
      <div
        className="entitlement-link"
        onClick={() => {
          if (
            component.entitlement?.ndaTemplate?.toLowerCase() ===
              NDATemplateTypesEnum.CLICK_THROUGH &&
            !progressData[component.bookmark?.docId]?.progress
          ) {
            setShowNDAAlert(true);
            setCurrentTarget(target);
          } else {
            openPlayer(target, component);
          }
        }}
      >
        {children}
      </div>
    );
  };

  const ActionWrapper = ({ type, action, component, children }) => {
    if (showSeriesLayout(type)) {
      return (
        <PlayActionWrapper
          target={action}
          component={component}
        >
          {children}
        </PlayActionWrapper>
      );
    } else {
      return (
        <Link
          className="entitlement-link"
          to={action}
        >
          {children}
        </Link>
      );
    }
  };

  const UnlockedLayout = (props: {
    children: React.ReactNode;
    component: EntitlementComponentCastingInterface;
    pageAction: NsActionInterface;
  }) => {
    let currentComponent;
    /* @ts-ignore TODO: TS18048: component is possibly undefined. */
    if (component.type === EntitlementTypeEnum.SERIES.toUpperCase()) {
      currentComponent = {
        ...props.component,
        /* @ts-ignore TODO: TS18048: component is possibly undefined. */
        seasons: component.seasons,
        /* @ts-ignore TODO: TS18048: component is possibly undefined. */
        seriesTitle: component.title,
        /* @ts-ignore TODO: TS18048: component is possibly undefined. */
        type: component.type,
        /* @ts-ignore TODO: TS18048: component is possibly undefined. */
        keyArtImage: component.keyArtImage,
      };
    } else if (
      /* @ts-ignore TODO: TS18048: component is possibly undefined. */
      component.type === ComponentTypeEnum.RELATED_CONTENT.toUpperCase()
    ) {
      currentComponent = props.component;
    } else {
      currentComponent = component;
    }

    return (
      <ActionWrapper
        component={currentComponent}
        type={type}
        action={props.pageAction?.target || ''}
      >
        <div
          className={clsx(['treatment-container', !isPlain && 'no-padding'])}
          css={[container && treatmentContainerStyles(container)]}
          /* @ts-ignore TODO: TS2322: Type MutableRefObject<HTMLDivElement | undefined> is not assignable to type LegacyRef<HTMLDivElement> | undefined. */
          ref={treatmentContainerRef}
          onMouseEnter={toggleGlow}
          onMouseLeave={toggleGlow}
        >
          {props.children}
        </div>
      </ActionWrapper>
    );
  };

  const LockedLayout = (props: {
    children: React.ReactNode;
    component: EntitlementComponentInterface;
  }) => {
    return (
      <div className="entitlement-locked">
        <UnlockButton
          onClick={() =>
            /* @ts-ignore TODO: TS2345: Argument of type string | null | undefined is not assignable to parameter of type string. */
            handleNdaClick(props.component.entitlement?.ndaTemplate)
          }
        />
        <div
          /* @ts-ignore TODO: TS18048: props.component.title is possibly undefined. */
          data-testid={`locked-treatment-container_${props.component.title
            .replace(/\s+/g, '-')
            .toLowerCase()}`}
          className={clsx([
            'treatment-container no-border',
            !isPlain && 'no-padding',
          ])}
          css={[container && treatmentContainerStyles(container)]}
          /* @ts-ignore TODO: TS2322: Type MutableRefObject<HTMLDivElement | undefined> is not assignable to type LegacyRef<HTMLDivElement> | undefined. */
          ref={treatmentContainerRef}
        >
          {props.children}
        </div>
      </div>
    );
  };

  const Container = ({
    children,
    isLocked,
    ...props
  }: {
    children: React.ReactNode;
    component: EntitlementComponentInterface;
    isLocked: boolean;
    openPlayer: OpenPlayerInterface;
    pageAction: NsActionInterface;
  }) => {
    return isLocked ?
        <LockedLayout {...props}>{children}</LockedLayout>
      : <UnlockedLayout {...props}>{children}</UnlockedLayout>;
  };

  useEffect(() => {
    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  useEffect(() => {
    if (treatmentContainerRef.current) {
      const width = Number(
        window
          .getComputedStyle(treatmentContainerRef.current)
          .width.replace('px', ''),
      );
      const containerSize = calculateAspectRatioOfHeight('16:9', width);
      /* @ts-ignore TODO: TS2345: Argument of type { aspectRatio: string; height: number; width: number; } | undefined is not assignable to parameter of type SetStateAction<{ aspectRatio: string; height: number; width: number; }>. */
      setContainer(containerSize);
    }
  }, [treatmentContainerRef]);

  const sliderExists = useComponentTypeFinder(ComponentTypeEnum.GALLERY);

  return (
    <div style={{ width: '100%' }}>
      <Alert
        isOpen={showNDAAlert}
        onClick={() => {
          setShowNDAAlert(false);
          openPlayer(currentTarget || '', component);
        }}
        primaryButtonText="Accept"
        secondaryButtonText="Cancel"
        infoText={getStringByKey(STRING_KEYS.PLAYBACK_ACCEPT)}
        secondaryAction={() => {
          setShowNDAAlert(false);
          /* @ts-ignore TODO: TS2345: Argument of type null is not assignable to parameter of type SetStateAction<string>. */
          setCurrentTarget(null);
        }}
      />

      {sliderExists && (
        <div>
          <div css={dividedLineCss}></div>
          <div css={titleCss}>All Content</div>
        </div>
      )}
      <div
        data-testid="entitlements-grid"
        className="entitlements-grid"
        /* @ts-ignore TODO: TS2345: Argument of type (key: string) => string | undefined is not assignable to parameter of type (key: string) => string. */
        css={styles(getConfigByKey)}
      >
        {/* @ts-ignore TODO: TS18048: component is possibly undefined. */}

        {component.items.map((item) => {
          /* @ts-ignore TODO: TS18049: item.actions is possibly null or undefined. */
          const page = item.actions.find((action) =>
            action.type.includes(
              showSeriesLayout(type) ?
                ActionTypeEnum.PLAY
              : ActionTypeEnum.PAGE,
            ),
          );
          /* @ts-ignore TODO: TS2345: Argument of type string | null | undefined is not assignable to parameter of type string. */
          const isLocked = !verifyNdaCompletion(item.entitlement?.ndaTemplate);
          return (
            <div
              /* @ts-ignore TODO: TS18048: item.title is possibly undefined. */
              data-testid={`entitlement-item-${item.title
                .replace(/\s+/g, '-')
                .replace(',', '')
                .toLowerCase()}`}
              key={`key-${item.id || item.episodeNumber}`}
              className="entitlement-item"
            >
              <Container
                isLocked={isLocked}
                // @ts-ignore TODO: TS2322: Type 'NSActionInterface' is not assignable to type...
                pageAction={page}
                component={item}
                openPlayer={openPlayer}
              >
                {/* @ts-ignore TODO: TS2345: Argument of type boolean | undefined is not assignable to parameter of type boolean. */}
                {renderTitle(item, isPlain, isDesktop, shouldUseKeyArt)}
                {item.bookmark && (
                  <ProgressOverlayWrapper
                    /* @ts-ignore TODO: TS2322: Type number | undefined is not assignable to type number. */
                    progress={progressData[item.bookmark.docId]?.progress}
                    bookmark={item.bookmark}
                  />
                )}
              </Container>
              {showSeriesLayout(type) ?
                seriesLayout(item, type)
              : defaultLayout(item)}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default EntitlementGrid;
