import React from 'react';
import { JssProvider } from 'react-jss';

import ThemeProvider from 'providers/theme-provider';
import StringsProvider from 'providers/strings-provider';
import RootRouter from 'router';
import { BrowserRouter } from 'react-router-dom';

const App: React.FC = () => {
  return (
    <JssProvider>
      <BrowserRouter>
        <StringsProvider>
          <ThemeProvider>
            <RootRouter />
          </ThemeProvider>
        </StringsProvider>
      </BrowserRouter>
    </JssProvider>
  );
};

export default App;
