/** @jsx jsx */
import { jsx } from '@emotion/core';
import type { EpisodesInfoModalConfigInterface } from 'providers/fyc-theme-component-interfaces';
import { FycConfigsEnum } from 'providers/fyc-theme-component-interfaces';
import { getVersionedComponentConfig } from 'providers/versions/fyc-theme-provider-utils';
import React, { useCallback, useEffect, useState } from 'react';
import { Modal } from 'components';
import {
  wrapperCss,
  contentWrapperCss,
  titleCss,
  contentBodyCss,
  contentLeftCss,
  contentRightCss,
  episodeTitleCss,
  accessCodeCss,
  videoActionCss,
  modalExtendCss,
} from './styles';
import type {
  NSEpisodeInterface,
  NSFycSeriesComponentInterface,
} from 'models/cms';
import CloseIcon from 'public/images/close.svg';
import NsImage from 'components/ns-component/ns-image';
import AccessCode from '../access-code';
import { FYC_CODE_VALID, showVideoActions } from '../access-code/helper';
import VideoActions from 'components/video-actions';
import { useFycThemeContext } from 'providers/fyc-theme-provider';

interface PropsInterface {
  component: NSFycSeriesComponentInterface;
  currentSeason: number;
  details: any;
  entitlementComponent: NSEpisodeInterface;
  hideAccessCode?: boolean;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

const EpisodesInfoModal: React.FC<PropsInterface> = ({
  isOpen,
  setIsOpen,
  component,
  currentSeason,
  entitlementComponent,
  details,
  hideAccessCode,
}) => {
  const [fycShowActions, setFycShowActions] = useState(false);

  const version = useFycThemeContext()?.version;

  const episodesInfoModalConfig =
    getVersionedComponentConfig<EpisodesInfoModalConfigInterface>({
      version,
      component: FycConfigsEnum.EPISODES_INFO_MODAL_CONFIG,
    });

  let modalTitle = `${component.title} season ${currentSeason}`.toUpperCase();

  if (episodesInfoModalConfig?.titleFormatter) {
    modalTitle =
      episodesInfoModalConfig.titleFormatter({
        showName: component.title,
        currentSeason,
        episodeNumber: entitlementComponent.episodeNumber,
      }) ?? modalTitle;
  }

  let episodeTitle = `${entitlementComponent?.episodeNumber}. ${entitlementComponent?.title}`;

  if (episodesInfoModalConfig?.episodeTitleFormatter) {
    episodeTitle =
      episodesInfoModalConfig.episodeTitleFormatter({
        episodeTitle: entitlementComponent?.title,
      }) ?? episodeTitle;
  }

  const showActions = !!showVideoActions(
    entitlementComponent.entitlement?.playId,
  );

  const onCodeValid = useCallback(() => {
    if (showActions) {
      setFycShowActions(true);

      return;
    }

    setFycShowActions(false);
  }, [showActions]);

  useEffect(() => {
    window.addEventListener(FYC_CODE_VALID, onCodeValid);

    onCodeValid();

    return () => {
      window.removeEventListener(FYC_CODE_VALID, onCodeValid);
    };
  }, [onCodeValid]);

  return (
    <Modal
      isOpen={isOpen}
      extendedStyles={modalExtendCss(version)}
    >
      <div css={wrapperCss}>
        <div css={contentWrapperCss(details.hasPlain, version)}>
          <div className="mask">
            <div className="mask1" />
            <div css={titleCss(details.hasPlain, version)}>
              <div className="title">{modalTitle}</div>
              <span className="close">
                <img
                  src={CloseIcon}
                  onClick={() => setIsOpen(false)}
                />
              </span>
            </div>
            <div css={episodeTitleCss(details.hasPlain, version)}>
              {episodeTitle}
            </div>
            <div css={contentBodyCss(details.hasPlain)}>
              {!details.hasPlain && (
                <div css={contentLeftCss}>
                  {entitlementComponent.heroImage?.url && (
                    <NsImage
                      url={entitlementComponent.heroImage.url}
                      width={800}
                      aspectRatio={'16/9'}
                    />
                  )}
                </div>
              )}

              <div css={contentRightCss(details.hasPlain, version)}>
                <div className="content">
                  <div className="content-title">Episode Synopsis</div>
                  <div className="content-info">
                    {entitlementComponent.synopsis}
                  </div>
                </div>
                {!hideAccessCode && !fycShowActions && !details.hasPlain && (
                  <div className="input">
                    <div className="input-prompt">
                      Enter access code to begin watching
                    </div>

                    <div>
                      <AccessCode
                        styles={accessCodeCss(details.hasPlain)}
                        component={entitlementComponent}
                      />
                    </div>
                  </div>
                )}
                {!hideAccessCode && fycShowActions && !details.hasPlain && (
                  <div
                    css={videoActionCss(details.hasPlain)}
                    onClick={() => setIsOpen(false)}
                  >
                    <VideoActions
                      component={entitlementComponent}
                      progressData={{ error: null }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EpisodesInfoModal;
