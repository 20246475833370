/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import { mediaQuery } from 'utils/styles';
import { FYC_MOBILE_WIDTH, FYC_TABLET_WIDTH } from 'pages/fyc-page/helpers';

const containerCss = css`
  ${mediaQuery(FYC_MOBILE_WIDTH)} {
    padding-left: ${60 / 16}rem;
    padding-right: ${60 / 16}rem;
  }

  ${mediaQuery(FYC_TABLET_WIDTH)} {
    padding-left: ${30 / 16}rem;
    padding-right: ${30 / 16}rem;
  }
`;

interface PropsInterface {
  data: any;
}

export const HowToWatchPanel: React.FC<PropsInterface> = ({ data }) => {
  return (
    <div css={containerCss}>
      <ReactMarkdown>{data}</ReactMarkdown>
    </div>
  );
};
