/** @jsx jsx */
import { jsx } from '@emotion/core';
import css from '@emotion/css';
import React from 'react';

const leftArrowImageStyles = css({
  width: 'var(--fyc-slider-arrow-width, 50px)',
  ['.fyc-slider-arrow__circle']: {
    fill: ' var(--fyc-slider-arrow-circle, white)',
  },
  ['.fyc-slider-arrow__arrow']: {
    fill: 'var(--fyc-slider-arrow-arrow, #040405)',
  },
});

interface LeftArrowIconInterface {
  arrowColor?: string;
  circleFill?: string;
  iconWidth?: string;
}

// https://emotion.sh/docs/best-practices#advanced-css-variables-with-style
const LeftArrowIcon: React.FunctionComponent<LeftArrowIconInterface> = (
  props,
) => {
  const { arrowColor, circleFill, iconWidth } = props;
  return (
    <svg
      css={leftArrowImageStyles}
      viewBox="0 0 50 50"
      className="fyc-slider-arrow"
      style={{
        ['--fyc-slider-arrow-arrow' as string]: arrowColor,
        ['--fyc-slider-arrow-circle' as string]: circleFill,
        ['--fyc-slider-arrow-width' as string]: iconWidth,
      }}
    >
      <g clipPath="url(#clip0_222_37760)">
        <path
          d="M24.6484 49.2969C38.2614 49.2969 49.2969 38.2614 49.2969 24.6484C49.2969 11.0355 38.2614 0 24.6484 0C11.0355 0 0 11.0355 0 24.6484C0 38.2614 11.0355 49.2969 24.6484 49.2969Z"
          className="fyc-slider-arrow__circle"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.8196 14.9141C25.2422 15.379 25.1702 16.2013 25.6587 16.7506L31.2838 23.0764H15.0633C14.3071 23.0764 13.6938 23.6598 13.6938 24.3795C13.6938 25.0993 14.3071 25.6827 15.0633 25.6827H31.2833L25.6587 32.0078C25.1702 32.5572 25.2422 33.3795 25.8196 33.8444C26.397 34.3093 27.261 34.2407 27.7497 33.6914L35.2818 25.2209C35.7138 24.7351 35.7138 24.0233 35.2818 23.5375L27.7497 15.0672C27.261 14.5177 26.397 14.4492 25.8196 14.9141Z"
          className="fyc-slider-arrow__arrow"
        />
      </g>
      <defs>
        <clipPath id="clip0_222_37760">
          <rect
            width="50"
            height="50"
            fill="red"
            className="circle"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LeftArrowIcon;
