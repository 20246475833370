import { SeekBar } from 'bitmovin-player-ui';

export default class CustomSeekBar extends SeekBar {
  protected isHost: boolean;
  protected isCoview: boolean;

  constructor(config: any = {}) {
    super(config);

    this.isHost = config.isHost;
    this.isCoview = config.isCoview;
  }

  protected toDomElement(): any {
    const seekBarContainer = super.toDomElement();

    if (this.isCoview && !this.isHost) {
      // only the host should be able to interact with the seek bar
      seekBarContainer.addClass('disabled');
    }

    return seekBarContainer;
  }
}
