/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import React, { useMemo } from 'react';
import {
  wrapperCss,
  contentCss,
  titleCss,
  descCss,
  registerBtn,
  infoCss,
} from './styles';
import AventriLocation from 'public/images/aventri-location.svg';
import AventriCalendar from 'public/images/aventri-calendar.svg';
import AventriClock from 'public/images/aventri-clock.svg';
import { useStringsContext, STRING_KEYS } from 'providers/strings-provider';
import { getCurrentFycVersion } from 'pages/fyc-page/helpers';
import type { SwoogoEventTypeInterface } from '..';
import parse from '../../../../../node_modules/html-react-parser/dist/html-react-parser.js';
import moment from 'moment-timezone/builds/moment-timezone-with-data.js';
interface PropsInterface {
  eventData: SwoogoEventTypeInterface;
}

const Card: React.FC<PropsInterface> = ({ eventData }) => {
  const { getStringByKey } = useStringsContext();

  const localTime = moment.tz(
    eventData.start_date + ' ' + eventData.start_time,
    eventData.timezone,
  );

  function formatDate(date) {
    const dayOfMonth = date.date();
    let suffix;

    if (dayOfMonth > 3 && dayOfMonth < 21) {
      suffix = 'th';
    } else {
      switch (dayOfMonth % 10) {
        case 1:
          suffix = 'st';
          break;
        case 2:
          suffix = 'nd';
          break;
        case 3:
          suffix = 'rd';
          break;
        default:
          suffix = 'th';
      }
    }
    const splitted = date.format(`MMMM D, YYYY`).split(',');
    const fistPart = splitted[0] + suffix + ',';
    return fistPart + splitted[1];
  }

  const rsvpCardStyles = useMemo(() => {
    const stylesObj = JSON.parse(
      /* @ts-ignore TODO: TS2345: Argument of type string | undefined is not assignable to parameter of type string. */
      getStringByKey(STRING_KEYS.FYC_RSVP_CARD_THEME),
    );

    const currentVersion = getCurrentFycVersion();

    if (currentVersion && currentVersion in stylesObj) {
      return stylesObj[currentVersion];
    } else {
      return stylesObj['FALLBACK'];
    }
  }, [getStringByKey]);

  return (
    <div css={wrapperCss}>
      <div css={contentCss(rsvpCardStyles)}>
        <div>
          <div
            className="title"
            css={titleCss}
          >
            {eventData.location.name}
          </div>
          <div
            className="desc"
            css={descCss}
          >
            {parse(eventData.description)}
          </div>
          <div
            className="info"
            css={infoCss}
          >
            <div className="location infoDetails">
              <img
                css={css`
                  width: 14px;
                `}
                src={AventriLocation}
                alt=""
              />
              <div>{`${eventData.location.line_1}, ${eventData.location.city} ${eventData.location.state} ${eventData.location.zip}`}</div>
            </div>
            <div className="date infoDetails">
              <img
                css={css`
                  width: 16px;
                `}
                src={AventriCalendar}
                alt=""
              />
              <div>{formatDate(localTime)}</div>
            </div>
            <div className="time infoDetails">
              <img
                css={css`
                  width: 18px;
                `}
                src={AventriClock}
                alt=""
              />
              <div>Starts: {localTime.format('h:mm A')}</div>
            </div>
          </div>
        </div>
        <a
          href={`${eventData.domain}/${eventData.url}`}
          css={registerBtn(rsvpCardStyles)}
          target="_blank"
          rel="noopener noreferrer"
        >
          Register
        </a>
      </div>
    </div>
  );
};

export default Card;
