import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import type { Action } from '@reduxjs/toolkit';
import { configureStore } from '@reduxjs/toolkit';
import type { ThunkAction } from 'redux-thunk';
import reduxCookiesMiddleware, {
  getStateFromCookies,
} from 'redux-cookies-middleware';
import type { RootStateType } from './root-reducer';
import rootReducer from './root-reducer';
import { getCookie, setCookie } from './cookies';

const logger = createLogger({
  collapsed: true,
  level: 'info',
});

// state to persist in cookies
const paths = {
  'user.accessToken': { name: 'access_token' },
  'user.refreshToken': { name: 'refresh_token' },
  'user.isKidsLogin': { name: 'is_kids_login' },
};

let initialState = {
  user: undefined,
  page: undefined,
  entitlementProgress: undefined,
};

initialState = getStateFromCookies(initialState, paths, getCookie);

const middlewares = [
  thunkMiddleware,
  reduxCookiesMiddleware(paths, {
    setCookie,
    defaultDeleteCheck: (value: any) => value === null,
  }),
];

if (process.env.NODE_ENV !== 'production') {
  middlewares.push(logger);
}

const initStore = () =>
  configureStore({
    reducer: rootReducer,
    preloadedState: initialState,
    middleware: middlewares,
  });

// export type AppDispatch = typeof initStore({ page: {} }).dispatch;
export type AppThunkType = ThunkAction<
  void,
  RootStateType,
  unknown,
  Action<string>
>;

export default initStore;
