/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import React, { useState } from 'react';
import { headerWrapperCss, rightSectionCss, imgWrapperCss } from './styles';
import ArrowIcon from 'public/images/caret-down.svg';

interface PropsInterface {
  children: React.ReactNode;
  flatView?: boolean;
  subtitle: string;
  title: string;
}

const Accordion: React.FC<PropsInterface> = ({
  children,
  title,
  subtitle,
  flatView,
}) => {
  const [show, setShow] = useState(false);

  const toggle = () => {
    setShow((pre) => !pre);
  };

  if (flatView) {
    return <React.Fragment>{children}</React.Fragment>;
  }

  return (
    <div
      css={css`
        ${!show && 'margin-bottom:8px;'}
      `}
    >
      <div
        css={headerWrapperCss}
        onClick={toggle}
      >
        <div className="title">{title}</div>
        <div css={rightSectionCss}>
          <div className="subtitle">{subtitle}</div>
          <div css={imgWrapperCss(!show)}>
            <img src={ArrowIcon} />
          </div>
        </div>
      </div>
      {show && children}
    </div>
  );
};

export default Accordion;
