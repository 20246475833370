/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import clsx from 'clsx';
import { useStringsContext, CONFIG_KEYS } from 'providers/strings-provider';
import React from 'react';
import { mediaQuery } from 'utils/styles';
import themeConfig from 'styles/theme';

interface PropsInterface {
  children?: React.ReactNode;
  className?: string;
  css?: any;
  disabled?: boolean;
  onClick?(e: any): any;
  theme?: string;
  type?: 'button' | 'submit' | 'reset';
}

const buttonCss = (theme) => css`
  width: 100%;
  height: 44px;
  border-radius: 4px;
  margin-top: 2rem;

  :hover {
    cursor: pointer;
  }

  :disabled {
    background-color: ${theme.palette.disabled.main};
    color: ${theme.palette.disabled.contrastText};
    pointer-events: none;
  }

  ${mediaQuery(theme.breakpoints.md)} {
    width: 20rem;
  }
`;

const Button = React.forwardRef(
  (
    {
      children,
      onClick,
      type,
      theme: themeProp,
      className,
      css: additionalButtonCss,
      ...rest
    }: PropsInterface,
    ref: React.MutableRefObject<HTMLButtonElement>,
  ) => {
    const { getConfigByKey } = useStringsContext();

    let buttonTheme;
    if (themeProp === 'primary') {
      buttonTheme = {
        ...themeConfig.button[themeProp],
        background: getConfigByKey(CONFIG_KEYS.PRIMARY_COLOR),
        '&:hover': {
          background: getConfigByKey(CONFIG_KEYS.PRIMARY_HOVER_COLOR),
        },
      };
    }

    if (themeProp === 'secondary') {
      buttonTheme = {
        ...themeConfig.button[themeProp],
        background: getConfigByKey(CONFIG_KEYS.SECONDARY_COLOR),
        '&:hover': {
          background: getConfigByKey(CONFIG_KEYS.SECONDARY_HOVER_COLOR),
        },
      };
    }

    return (
      <button
        ref={ref}
        onClick={onClick}
        type={type}
        css={(theme) => [buttonCss(theme), buttonTheme, additionalButtonCss]}
        className={clsx(['action-button', className])}
        {...rest}
      >
        {children}
      </button>
    );
  },
);

export default Button;
