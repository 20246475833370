/** @jsx jsx */
import { jsx } from '@emotion/core';
import { EntitlementTypeEnum } from 'models/enums';
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import type { NsActionInterface } from 'models/cms/ns-action-interface-old';
import type { NsEntitlementCastingInterface } from 'models/cms/ns-entitlement-interface-old';
import type { EntitlementComponentInterface } from 'models/components/entitlement-component-interface';
import { ActionTypeEnum } from 'models/enums';
import type { ProgressStateInterface } from 'store/slices/progress';
import {
  buildTitle,
  calculateAspectRatioOfHeight,
  treatmentContainerStyles,
} from 'utils';
import {
  interpolateTitle,
  renderSynopsis,
  renderTitle,
} from '../entitlement-grid/helpers/renderers';
import styles, {
  sliderCss,
  leftArrowCss,
  rightArrowCss,
  arrowIconCss,
  iconWrapperCss,
  titleCss,
  MARGINS,
} from './styles';
import clsx from 'clsx';
import Typography from 'components/typography';
import { useStringsContext } from 'providers/strings-provider';
import type { EntitlementComponentCastingInterface } from 'models/components/entitlement-component-interface';
import { ComponentTypeEnum } from 'models/enums';
import UnlockButton from 'components/unlock-button';
import ExpiryDate from 'components/expiry-date';
import { usePlayer } from 'providers/player-provider';
import type { OpenPlayerInterface } from 'models/interfaces/player-provider';
import { useWindowSize } from 'utils/hooks';
import ProgressOverlayWrapper from 'components/progress-bar/progress-overlay-wrapper';

const MAX_TITLE_LENGTH = 60;

const toggleGlow = (e) => {
  e.preventDefault();
  e.currentTarget.classList.toggle('glow');
};

export interface EntitlementSliderComponentInterface {
  component?: NsEntitlementCastingInterface;
  handleNdaClick(templateId: string): Promise<void>;
  isDesktop?: boolean;
  isPlain?: boolean;
  progressData: ProgressStateInterface;
  shouldUseKeyArt?: boolean;
  title: string;
  type?: ComponentTypeEnum;
  verifyNdaCompletion(templateId: string): boolean;
}

const defaultLayout = (item) => (
  <React.Fragment>
    <div className="entitlement-title">
      {interpolateTitle(item.title, MAX_TITLE_LENGTH)}
    </div>
    {renderSynopsis(item.synopsis)}
    {item.docType === EntitlementTypeEnum.MOVIE && (
      <ExpiryDate entitlement={item.entitlement} />
    )}
  </React.Fragment>
);

const seriesLayout = (item, type = ComponentTypeEnum.SERIES) => {
  return (
    <React.Fragment>
      <Typography
        className="episode-title"
        isFullWidth
      >
        {type === ComponentTypeEnum.RELATED_CONTENT ?
          item?.title
        : buildTitle(item)}
      </Typography>
      <ExpiryDate entitlement={item.entitlement} />
      {renderSynopsis(item.synopsis)}
    </React.Fragment>
  );
};

const showSeriesLayout = (type: ComponentTypeEnum) => {
  return (
    type === ComponentTypeEnum.SERIES ||
    type === ComponentTypeEnum.RELATED_CONTENT
  );
};

const EntitlementSlider: React.FC<EntitlementSliderComponentInterface> = ({
  component,
  isPlain,
  type = ComponentTypeEnum.ENTITLEMENTS,
  verifyNdaCompletion,
  handleNdaClick,
  isDesktop,
  progressData,
  shouldUseKeyArt,
  title,
}) => {
  const [container, setContainer] = useState<{
    aspectRatio: string | '16:9' | '4:3' | '3:1';
    height: number;
    width: number;
    /* @ts-ignore TODO: TS2345: Argument of type null is not assignable to parameter of type { aspectRatio: string; height: number; width: number; } | (() => { aspectRatio: string; height: number; width: number; }). */
  }>(null);
  const treatmentContainerRef = useRef<HTMLDivElement>();
  const { getConfigByKey } = useStringsContext();
  const { openPlayer } = usePlayer();
  const clientXRef = useRef(null);

  const onResize = () => {
    // reset slider position
    setArrowClickedNumber(0);
    sessionStorage.removeItem('sliderState');

    if (treatmentContainerRef.current) {
      const width = Number(
        window
          .getComputedStyle(treatmentContainerRef.current)
          .width.replace('px', ''),
      );
      const containerSize = calculateAspectRatioOfHeight('16:9', width);
      /* @ts-ignore TODO: TS2345: Argument of type { aspectRatio: string; height: number; width: number; } | undefined is not assignable to parameter of type SetStateAction<{ aspectRatio: string; height: number; width: number; }>. */
      setContainer(containerSize);
    }
  };

  const PlayActionWrapper = ({ target, component, children }) => {
    return (
      <div
        className="entitlement-link"
        onClick={() => openPlayer(target, component)}
      >
        {children}
      </div>
    );
  };

  const ActionWrapper = ({ type, action, component, children }) => {
    if (showSeriesLayout(type)) {
      return (
        <PlayActionWrapper
          target={action}
          component={component}
        >
          {children}
        </PlayActionWrapper>
      );
    } else {
      return (
        <Link
          className="entitlement-link"
          to={action}
        >
          {children}
        </Link>
      );
    }
  };

  const UnlockedLayout = (props: {
    children: React.ReactNode;
    component: EntitlementComponentCastingInterface;
    pageAction: NsActionInterface;
  }) => {
    let currentComponent;
    /* @ts-ignore TODO: TS18048: component is possibly undefined. */
    if (component.type === EntitlementTypeEnum.SERIES.toUpperCase()) {
      currentComponent = {
        ...props.component,
        /* @ts-ignore TODO: TS18048: component is possibly undefined. */
        seasons: component.seasons,
        /* @ts-ignore TODO: TS18048: component is possibly undefined. */
        seriesTitle: component.title,
        /* @ts-ignore TODO: TS18048: component is possibly undefined. */
        type: component.type,
        /* @ts-ignore TODO: TS18048: component is possibly undefined. */
        keyArtImage: component.keyArtImage,
      };
    } else {
      currentComponent = component;
    }

    return (
      <ActionWrapper
        component={currentComponent}
        type={type}
        action={props.pageAction?.target || ''}
      >
        <div
          className={clsx(['treatment-container', !isPlain && 'no-padding'])}
          css={[container && treatmentContainerStyles(container)]}
          /* @ts-ignore TODO: TS2322: Type MutableRefObject<HTMLDivElement | undefined> is not assignable to type LegacyRef<HTMLDivElement> | undefined. */
          ref={treatmentContainerRef}
          onMouseEnter={toggleGlow}
          onMouseLeave={toggleGlow}
        >
          {props.children}
        </div>
      </ActionWrapper>
    );
  };

  const LockedLayout = (props: {
    children: React.ReactNode;
    component: EntitlementComponentInterface;
  }) => {
    return (
      <div className="entitlement-locked">
        <UnlockButton
          onClick={() =>
            /* @ts-ignore TODO: TS2345: Argument of type string | null | undefined is not assignable to parameter of type string. */
            handleNdaClick(props.component.entitlement?.ndaTemplate)
          }
        />
        <div
          /* @ts-ignore TODO: TS18048: props.component.title is possibly undefined. */
          data-testid={`locked-treatment-container_${props.component.title
            .replace(/\s+/g, '-')
            .toLowerCase()}`}
          className={clsx([
            'treatment-container no-border',
            !isPlain && 'no-padding',
          ])}
          css={[container && treatmentContainerStyles(container)]}
          /* @ts-ignore TODO: TS2322: Type MutableRefObject<HTMLDivElement | undefined> is not assignable to type LegacyRef<HTMLDivElement> | undefined. */
          ref={treatmentContainerRef}
        >
          {props.children}
        </div>
      </div>
    );
  };

  const Container = ({
    children,
    isLocked,
    ...props
  }: {
    children: React.ReactNode;
    component: EntitlementComponentInterface;
    isLocked: boolean;
    openPlayer: OpenPlayerInterface;
    pageAction: NsActionInterface;
  }) => {
    return isLocked ?
        <LockedLayout {...props}>{children}</LockedLayout>
      : <UnlockedLayout {...props}>{children}</UnlockedLayout>;
  };

  useEffect(() => {
    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  useEffect(() => {
    if (treatmentContainerRef.current) {
      const width = Number(
        window
          .getComputedStyle(treatmentContainerRef.current)
          .width.replace('px', ''),
      );
      const containerSize = calculateAspectRatioOfHeight('16:9', width);
      /* @ts-ignore TODO: TS2345: Argument of type { aspectRatio: string; height: number; width: number; } | undefined is not assignable to parameter of type SetStateAction<{ aspectRatio: string; height: number; width: number; }>. */
      setContainer(containerSize);
    }
  }, [treatmentContainerRef]);

  const [maxArrowClickedNumber, setMaxArrowClickedNumber] = useState(0);

  const getSliderArrowClickedNum = () => {
    let result = 0;
    const stateStr = sessionStorage.getItem('sliderState');
    let stateObj;

    if (stateStr !== null && stateStr !== '' && stateStr !== undefined) {
      stateObj = JSON.parse(stateStr);
      if (stateObj[title] !== null && stateObj[title] !== undefined) {
        result = stateObj[title];
      }
    }

    return result;
  };

  const [arrowClickedNumber, setArrowClickedNumber] = useState(
    getSliderArrowClickedNum() || 0,
  );
  const { isMobile, isTablet } = useWindowSize();

  useEffect(() => {
    const totalCount = component?.items?.length || 0;
    if (isDesktop) {
      setMaxArrowClickedNumber(Math.ceil(totalCount / 4) - 1);
    }
    if (isTablet) {
      setMaxArrowClickedNumber(Math.ceil(totalCount / 3) - 1);
    }
    if (isMobile) {
      setMaxArrowClickedNumber(Math.ceil(totalCount / 2) - 1);
    }

    if (
      (isDesktop && arrowClickedNumber > Math.ceil(totalCount / 4) - 1) ||
      (isTablet && arrowClickedNumber > Math.ceil(totalCount / 3) - 1) ||
      (isMobile && arrowClickedNumber > Math.ceil(totalCount / 2) - 1)
    ) {
      setArrowClickedNumber(0);
    }
  }, [
    isDesktop,
    isMobile,
    isTablet,
    component,
    arrowClickedNumber,
    maxArrowClickedNumber,
  ]);

  const arrowClicked = (isRightClicked: boolean) => {
    if (!component?.items?.length) {
      return;
    }
    if (arrowClickedNumber === 0 && !isRightClicked) {
      return;
    }

    const state = sessionStorage.getItem('sliderState');
    let stateObj = {};
    if (state != null && state !== '') {
      stateObj = JSON.parse(state);
    }

    if (isRightClicked) {
      if (arrowClickedNumber + 1 > maxArrowClickedNumber) {
        return;
      }

      setArrowClickedNumber(arrowClickedNumber + 1);
      stateObj[title] = arrowClickedNumber + 1;
    } else {
      setArrowClickedNumber(arrowClickedNumber - 1);
      stateObj[title] = arrowClickedNumber - 1;
    }

    sessionStorage.setItem('sliderState', JSON.stringify(stateObj));
  };

  const getOpacityIndex = () => {
    let rightIndex;
    let leftIndex = -1;

    if (isDesktop) {
      leftIndex = arrowClickedNumber * 4 - 1;
      rightIndex = (arrowClickedNumber + 1) * 4;
    }

    if (isTablet) {
      leftIndex = arrowClickedNumber * 3 - 1;
      rightIndex = (arrowClickedNumber + 1) * 3;
    }

    if (isMobile) {
      leftIndex = arrowClickedNumber * 2 - 1;
      rightIndex = (arrowClickedNumber + 1) * 2;
    }

    return [leftIndex, rightIndex];
  };

  const transformValue = () => {
    let result: { transform: string; transition?: string };
    if (isMobile) {
      result = {
        transform: `translateX(calc(${-arrowClickedNumber} * (100% + ${
          MARGINS.mobile
        }%)))`,
      };
    }
    if (isTablet) {
      result = {
        transform: `translateX(calc(${-arrowClickedNumber} * (100% + ${
          MARGINS.tablet
        }%)))`,
      };
    }
    if (isDesktop) {
      result = {
        transform: `translateX(calc(${-arrowClickedNumber} * (100% + ${
          MARGINS.desktop
        }%)))`,
      };
    }

    /* @ts-ignore TODO: TS2454: Variable result is used before being assigned. */
    return { ...result, transition: 'transform 1s ease 0s' };
  };

  return (
    <div
      data-testid="entitlements-slider"
      className="entitlements-slider"
      css={sliderCss}
      onTouchStart={(e) => {
        /* @ts-ignore TODO: TS2322: Type number is not assignable to type null. */
        clientXRef.current = e.touches[0].clientX;
      }}
      onTouchEnd={(e) => {
        /* @ts-ignore TODO: TS18047: clientXRef.current is possibly null. */
        if (e.changedTouches[0].clientX - clientXRef.current > 0) {
          arrowClicked(false);
        }

        /* @ts-ignore TODO: TS18047: clientXRef.current is possibly null. */
        if (e.changedTouches[0].clientX - clientXRef.current < 0) {
          arrowClicked(true);
        }

        clientXRef.current = null;
      }}
    >
      <div
        className="sliderTitle"
        css={titleCss}
      >
        {title}
      </div>
      <div
        className="left-arrow"
        style={arrowClickedNumber <= 0 ? { visibility: 'hidden' } : {}}
        css={leftArrowCss}
        onClick={() => arrowClicked(false)}
      >
        <div
          className="icon-wrapper"
          css={iconWrapperCss(container?.height)}
        >
          <div css={arrowIconCss}></div>
        </div>
      </div>
      <div
        className="right-arrow"
        style={
          arrowClickedNumber === maxArrowClickedNumber ?
            {
              visibility: 'hidden',
            }
          : {}
        }
        css={rightArrowCss}
        onClick={() => arrowClicked(true)}
      >
        <div
          className="icon-wrapper"
          css={iconWrapperCss(container?.height)}
        >
          <div css={arrowIconCss}></div>
        </div>
      </div>
      <div
        style={transformValue()}
        /* @ts-ignore TODO: TS2345: Argument of type (key: string) => string | undefined is not assignable to parameter of type (key: string) => string. */
        css={styles(getConfigByKey)}
      >
        {/* @ts-ignore TODO: TS18048: component is possibly undefined. */}

        {component.items.map((item, index) => {
          /* @ts-ignore TODO: TS18049: item.actions is possibly null or undefined. */
          const page = item.actions.find((action) =>
            action.type.includes(
              showSeriesLayout(type) ?
                ActionTypeEnum.PLAY
              : ActionTypeEnum.PAGE,
            ),
          );
          /* @ts-ignore TODO: TS2345: Argument of type string | null | undefined is not assignable to parameter of type string. */
          const isLocked = !verifyNdaCompletion(item.entitlement?.ndaTemplate);
          return (
            <div
              /* @ts-ignore TODO: TS18048: item.title is possibly undefined. */
              data-testid={`entitlement-item-${item.title
                .replace(/\s+/g, '-')
                .toLowerCase()}`}
              key={`key-${item.id || item.episodeNumber}`}
              className="entitlement-item"
              style={{ opacity: getOpacityIndex().includes(index) ? 0.4 : 1 }}
            >
              <Container
                isLocked={isLocked}
                // @ts-ignore TODO: TS2322: Type 'NSActionInterface' is not assignable to type...
                pageAction={page}
                component={item}
                openPlayer={openPlayer}
              >
                {/* @ts-ignore TODO: TS2345: Argument of type boolean | undefined is not assignable to parameter of type boolean. */}
                {renderTitle(item, isPlain, isDesktop, shouldUseKeyArt)}
                {item.bookmark && (
                  <ProgressOverlayWrapper
                    bookmark={item.bookmark}
                    /* @ts-ignore TODO: TS2322: Type number | undefined is not assignable to type number. */
                    progress={progressData[item.bookmark.docId]?.progress}
                  />
                )}
              </Container>
              {showSeriesLayout(type) ?
                seriesLayout(item, type)
              : defaultLayout(item)}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default EntitlementSlider;
