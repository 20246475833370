import Typography from 'components/typography';
import { FullWidthLayoutContainer } from 'containers';
import { DeviceTypeEnum } from 'models/enums';
import { TypographyStyleTypeEnum } from 'models/enums';
import React from 'react';
import { useBowser } from 'utils/hooks';

const GenericFullPageFallbackComponent = ({ text }) => (
  <FullWidthLayoutContainer showAppBar>
    <Typography style={TypographyStyleTypeEnum.SUBHEADING}>{text}</Typography>
  </FullWidthLayoutContainer>
);

interface FallbackOptionsInterface {
  text?: string;
  useGenericFullPage?: boolean;
}

interface WithDeviceProtectionPropsInterface {
  platform?: DeviceTypeEnum;
}

const withDeviceProtection = <T extends WithDeviceProtectionPropsInterface>(
  WrappedComponent: React.ComponentType<T>,
  {
    FallbackComponent,
    fallbackOptions = {
      useGenericFullPage: false,
    },
    devices = [
      DeviceTypeEnum.DESKTOP,
      DeviceTypeEnum.TABLET,
      DeviceTypeEnum.MOBILE,
    ],
  }: {
    FallbackComponent?: React.ComponentType<T>;
    devices?: DeviceTypeEnum[];
    fallbackOptions?: FallbackOptionsInterface;
  } = {},
): React.ComponentType<T> => {
  const WithDeviceProtection = (props: T) => {
    const bowser = useBowser();

    // see if the device exists in the array
    const isAllowed = devices.find((device) => {
      // iPads use a desktop useragent by default,
      // make sure it doesn't bypass the tablet restrictions
      if (
        bowser.platform.type === DeviceTypeEnum.DESKTOP &&
        bowser.platform.vendor === 'Apple' &&
        navigator.maxTouchPoints > 0
      ) {
        if (device === DeviceTypeEnum.TABLET) {
          return true;
        }
      } else if (device === bowser.platform.type) {
        return true;
      }
    });

    if (!isAllowed) {
      if (FallbackComponent) {
        // display an optional fallback component if it exist
        return <FallbackComponent {...props} />;
      } else if (fallbackOptions.useGenericFullPage) {
        // display generic fallback
        return (
          <GenericFullPageFallbackComponent
            text={fallbackOptions.text || 'Sorry this device is not supported'}
          />
        );
      } else {
        // don't display the component if there's no fallback
        return null;
      }
    }

    return (
      <WrappedComponent
        {...props}
        platform={bowser.platform.type}
      />
    );
  };

  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || 'Component';
  WithDeviceProtection.displayName = `WithDeviceProtection(${displayName})`;

  return WithDeviceProtection;
};

export default withDeviceProtection;
