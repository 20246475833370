import { FYC_MOBILE_WIDTH, FYC_TABLET_WIDTH } from 'pages/fyc-page/helpers';
import type { SerializedStyles } from '@emotion/core';
import { css } from '@emotion/core';
import type { CategoriesPanelItemConfigInterface } from 'providers/fyc-theme-component-interfaces';
import { FycConfigsEnum } from 'providers/fyc-theme-component-interfaces';
import { getVersionedComponentConfig } from 'providers/versions/fyc-theme-provider-utils';
import { mediaQuery } from 'utils/styles';
import type { FycVersionType } from 'providers/fyc-theme-provider';
import { SEARCHLIGHT, SEARCHLIGHT_AWARDS } from 'providers/fyc-theme-provider';

export const categoryCss = (version: FycVersionType): SerializedStyles => {
  const customCssConfig = getVersionedComponentConfig<
    CategoriesPanelItemConfigInterface['categoryCss']
  >({
    version,
    component: FycConfigsEnum.CATEGORY_ITEM_CONFIG,
    selector: 'categoryCss',
  });

  let fontFamily = customCssConfig?.fontFamily ?? `futura-pt-condensed`;
  let color = customCssConfig?.color ?? `rgba(255, 255, 255, 0.65)`;
  const fontWeight = customCssConfig?.fontWeight ?? 400;

  if (version === SEARCHLIGHT || version === SEARCHLIGHT_AWARDS) {
    fontFamily = `'Open Sans', sans-serif`;
    color = 'inherit';
  }

  return css`
    color: ${color};
    font-size: ${20 / 16}rem;
    font-weight: ${fontWeight};
    margin-top: ${0 / 16}rem;
    font-family: ${fontFamily};
    letter-spacing: 1px;
    label: categoryCss;

    ${mediaQuery(FYC_MOBILE_WIDTH)} {
      margin-top: ${24 / 16}rem;
    }
  `;
};
export const recipientsWrapperCss = css`
  font-size: 1rem;
  font-weight: 500;

  .recipientLine {
    margin-top: ${8 / 16}rem;
  }
`;

export const rowWrapperCss = css`
  display: flex;
  flex-direction: column;

  gap: 32px;

  margin-bottom: 32px;

  ${mediaQuery(FYC_MOBILE_WIDTH)} {
    flex-direction: row;
    gap: 0;
    margin-bottom: 0;
  }

  .left {
    width: 100%;

    ${mediaQuery(FYC_MOBILE_WIDTH)} {
      margin-right: ${(112 / 890) * 100}%;
      width: 50%;
    }

    ${mediaQuery(FYC_TABLET_WIDTH)} {
      margin-right: ${(112 / 1179) * 100}%;
    }
  }

  .right {
    width: 100%;
    ${mediaQuery(FYC_MOBILE_WIDTH)} {
      width: 50%;
    }
  }
`;
