import { css } from '@emotion/core';
import { FYC_MOBILE_WIDTH } from 'pages/fyc-page/helpers';
import { mediaQuery } from 'utils/styles';

export const itemWrapperCss = (isLast: boolean) => {
  return css`
    ${!isLast && `border-bottom: 1px solid rgba(255, 255, 255, 0.1);`}
    padding: ${8 / 16}rem ${5 / 16}rem;

    display: flex;
    justify-content: space-between;

    .fileTitle {
      font-size: 12px;
      font-weight: 500;
    }

    img {
      width: 1rem;
    }

    ${mediaQuery(FYC_MOBILE_WIDTH)} {
      padding: ${16 / 16}rem ${8 / 16}rem;

      .fileTitle {
        font-size: 1rem;
        font-weight: 500;
      }

      img {
        width: ${20 / 16}rem;
      }
    }
  `;
};

export const downloadWrapperCss = css`
  display: flex;
  flex-wrap: nowrap;
  gap: 8px;
  cursor: pointer;

  text-decoration: none;

  span {
    color: rgba(193, 154, 65, 1);
  }

  span {
    display: none;
  }

  ${mediaQuery(FYC_MOBILE_WIDTH)} {
    span {
      display: initial;
    }
  }
`;
