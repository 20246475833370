/*eslint sort-keys: [error, asc, {caseSensitive: true, natural: false}] */

import type { CSSObject } from '@emotion/core';
import type { FycComponentConfigurationTypeInterface } from 'providers/fyc-theme-component-interfaces';
import { FycConfigsEnum } from 'providers/fyc-theme-component-interfaces';
import { convertPixelsToRems } from 'utils/styles';

const ESPN_COLORS = {
  BLACK: '#000000',
  BLACK_GREEN: '#1E2020',
  EBONY: '#181A1A',
  IVORY: '#D8D0C1',
  LIGHT_BLACK: '#202020',
  ONYX: '#101113',
  RED: '#EF4135',
  WHITE: '#FFF',
};

const ESPN_FONT_FAMILIES = {
  ROBOTO: 'Roboto',
};

const ESPN_FONT_WEIGHTS = {
  LIGHT: 300,
  MEDIUM: 500,
  REGULAR: 400,
  THIN: 100,
};

const brandFont = ESPN_FONT_FAMILIES.ROBOTO;
const brandAccentColor = ESPN_COLORS.RED;
const fontSize14px: CSSObject['fontSize'] = convertPixelsToRems(14);
const detailsPartsBackground: CSSObject['background'] = `linear-gradient(17deg, ${'rgba(24, 26, 26, 0.32)'} -23.2%, ${ESPN_COLORS.LIGHT_BLACK} 100.4%)`;
const episodeInfoModalBoxShadow: CSSObject['boxShadow'] = `0 4px 5px 0 ${'rgba(0, 0, 0, 0.14)'}, 0 1px 10px 0 ${'rgba(0, 0, 0, 0.12)'}, 0 2px 4px -1px ${'rgba(0, 0, 0, 0.20)'}`;
const episodeInfoModalMaskBg: CSSObject['background'] = ESPN_COLORS.LIGHT_BLACK;
const episodeInfoModalMaskBg1: CSSObject['background'] =
  'rgba(255, 255, 255, 0.07)';

const fycPageBackground: CSSObject['background'] = `${ESPN_COLORS.ONYX} linear-gradient(3deg, #00080B -213.54%, rgba(66, 66, 67, 0.00) 76.83%) 0% 60px / 100% 46px no-repeat`;

const accessCodeConfig: FycComponentConfigurationTypeInterface['ACCESS_CODE_CONFIG'] =
  {
    buttonCss: {
      fontWeight: ESPN_FONT_WEIGHTS.LIGHT,
    },
    inputCss: {
      fontWeight: ESPN_FONT_WEIGHTS.LIGHT,
    },
  };

const allContentLandingConfig: FycComponentConfigurationTypeInterface['ALL_CONTENT_LANDING_CONFIG'] =
  {
    cateContainer: {
      color: brandAccentColor,
      fontWeight: ESPN_FONT_WEIGHTS.MEDIUM,
      letterSpacing: '1px',
      selectedFontSize: convertPixelsToRems(20),
      unSelectedFontWeight: ESPN_FONT_WEIGHTS.REGULAR,
    },
    cateListItemCss: {
      textTransform: 'capitalize',
    },
    leftColumnCss: {
      borderRight: 'none',
      fontFamily: brandFont,
    },
  };

const bannerConfig: FycComponentConfigurationTypeInterface['BANNER_CONFIG'] = {
  imageContainerCss: {
    borderRadius: convertPixelsToRems(30),
  },
  indicatorItemCss: {
    activeStyle: {
      background: ESPN_COLORS.WHITE,
      opacity: 1,
    },
    defaultStyle: {
      background: ESPN_COLORS.WHITE,
      opacity: 0.5,
    },
  },
};

const footerConfig: FycComponentConfigurationTypeInterface['FOOTER_CONFIG'] = {
  contactCss: {
    fontFamily: brandFont,
    fontWeight: ESPN_FONT_WEIGHTS.LIGHT,
  },
  copyRightCss: {
    fontFamily: brandFont,
    fontSize: convertPixelsToRems(12),
    fontWeight: ESPN_FONT_WEIGHTS.LIGHT,
    letterSpacing: '0.25px',
    textTransform: 'uppercase',
  },
  tosCss: {
    fontWeight: ESPN_FONT_WEIGHTS.LIGHT,
  },
};

const categoryItemConfig: FycComponentConfigurationTypeInterface['CATEGORY_ITEM_CONFIG'] =
  {
    categoryCss: {
      color: ESPN_COLORS.IVORY,
      fontFamily: brandFont,
      fontWeight: ESPN_FONT_WEIGHTS.MEDIUM,
    },
  };

const categorySelectConfig: FycComponentConfigurationTypeInterface['CATEGORY_SELECT_CONFIG'] =
  {
    selectStyles: {
      placeholder: {
        color: brandAccentColor,
      },
      singleValue: {
        color: brandAccentColor,
      },
    },
  };

const detailsBannerConfig: FycComponentConfigurationTypeInterface['DETAILS_BANNER_CONFIG'] =
  {
    actionsContainerCss: {
      background: 'rgba(255, 255, 255, 0.03)',
    },
    backgroundCss: {
      background: ESPN_COLORS.BLACK_GREEN,
    },
    titleInfoCss: {
      fontFamily: brandFont,
      fontWeight: ESPN_FONT_WEIGHTS.MEDIUM,
    },
    wrapperCss: {
      background: ESPN_COLORS.BLACK_GREEN,
    },
  };

const detailsPageConfig: FycComponentConfigurationTypeInterface['DETAILS_PAGE_CONFIG'] =
  {
    bodyDefaultTextCss: {
      textColor: ESPN_COLORS.WHITE,
    },
    selectStyles: {
      bg: '#25252B',
      fontSize: fontSize14px,
      singleValueColor: ESPN_COLORS.WHITE,
      singleValueFontFamily: brandFont,
      singleValueFontWeight: ESPN_FONT_WEIGHTS.LIGHT,
    },
  };

const detailsPartConfig: FycComponentConfigurationTypeInterface['DETAILS_PART_CONFIG'] =
  {
    contentCss: {
      background: detailsPartsBackground,
      color: ESPN_COLORS.IVORY,
      fontWeight: ESPN_FONT_WEIGHTS.LIGHT,
    },
    reactTabsCss: {
      border: `2px solid ${ESPN_COLORS.IVORY}`,
      color: 'rgba(255, 255, 255, 0.47)',
      fontSize: fontSize14px,
      fontWeight: ESPN_FONT_WEIGHTS.LIGHT,
      selectFontFamily: brandFont,
      selectFontWeight: ESPN_FONT_WEIGHTS.REGULAR,
      selectedBg: brandAccentColor,
      selectedColor: ESPN_COLORS.IVORY,
      selectedTabBg: ESPN_COLORS.ONYX,
    },
    tabTitleCss: {
      textTransform: 'none',
    },
  };

const episodesInfoModalConfig: FycComponentConfigurationTypeInterface['EPISODES_INFO_MODAL_CONFIG'] =
  {
    contentRightCss: {
      contentIntoFontSize: fontSize14px,
      contentTitleFontSize: fontSize14px,
      fontFamily: brandFont,
      infoFontWeight: ESPN_FONT_WEIGHTS.LIGHT,
      inputPromptFontWeight: ESPN_FONT_WEIGHTS.LIGHT,
      synopsisTitleTextTransform: 'uppercase',
      titleFontWeight: ESPN_FONT_WEIGHTS.THIN,
    },
    contentWrapperCss: {
      boxShadow: episodeInfoModalBoxShadow,
      mask1Bg: episodeInfoModalMaskBg1,
      maskBg: episodeInfoModalMaskBg,
    },
    episodeTitleCss: {
      fontSize: convertPixelsToRems(20),
      fontWeight: ESPN_FONT_WEIGHTS.REGULAR,
      textTransform: 'uppercase',
    },
    episodeTitleFormatter: ({ episodeTitle }) => episodeTitle ?? undefined,
    modalExtendCss: {
      background: 'rgba(0, 0, 0, 0.50)',
    },
    titleCss: {
      fontWeight: ESPN_FONT_WEIGHTS.REGULAR,
    },
    titleFormatter: ({ showName }) => showName ?? undefined,
  };

const episodesPanelItemConfig: FycComponentConfigurationTypeInterface['EPISODES_PANEL_ITEM_CONFIG'] =
  {
    contentCss: {
      fontWeight: ESPN_FONT_WEIGHTS.LIGHT,
    },
    episodeTitleFormatter: ({ episodeTitle }) => episodeTitle ?? undefined,
    titleCss: {
      fontSize: convertPixelsToRems(16),
      fontWeight: ESPN_FONT_WEIGHTS.MEDIUM,
      textTransform: 'uppercase',
    },
  };

const fycPageConfig: FycComponentConfigurationTypeInterface['FYC_PAGE_CONFIG'] =
  {
    pageBackground: fycPageBackground,
    rootCss: {
      fontFamily: brandFont,
    },
  };

const navbarConfig: FycComponentConfigurationTypeInterface['NAVBAR_CONFIG'] = {
  fycSpanCss: {
    color: ESPN_COLORS.IVORY,
    fontFamily: brandFont,
    fontSizeTablet: convertPixelsToRems(16),
    fontWeight: ESPN_FONT_WEIGHTS.REGULAR,
  },
  lineCss: {
    borderLeft: `2px solid ${ESPN_COLORS.IVORY}`,
  },
  titleCss: {
    color: brandAccentColor,
  },
  wrapperCss: {
    background: ESPN_COLORS.ONYX,
    borderBottom: 'none',
  },
};

const sliderConfig: FycComponentConfigurationTypeInterface['SLIDER_CONFIG'] = {
  hasOverlay: true,
  imgNTitleWrapperCss: {
    titleFontFamily: brandFont,
    titleFontWeight: ESPN_FONT_WEIGHTS.LIGHT,
  },
  leftArrowIcon: {
    arrowColor: ESPN_COLORS.WHITE,
    circleFill: ESPN_COLORS.EBONY,
    iconWidth: '56px',
  },
  titleNameCss: {
    color: ESPN_COLORS.WHITE,
    textTransform: 'capitalize',
  },
};

const contactUsConfig: FycComponentConfigurationTypeInterface['CONTACT_US_CONFIG'] =
  {
    containerCss: {
      color: ESPN_COLORS.IVORY,
    },
  };

const ESPNConfig: FycComponentConfigurationTypeInterface = {
  [FycConfigsEnum.ACCESS_CODE_CONFIG]: accessCodeConfig,
  [FycConfigsEnum.ALL_CONTENT_LANDING_CONFIG]: allContentLandingConfig,
  [FycConfigsEnum.BANNER_CONFIG]: bannerConfig,
  [FycConfigsEnum.CATEGORY_ITEM_CONFIG]: categoryItemConfig,
  [FycConfigsEnum.CATEGORY_SELECT_CONFIG]: categorySelectConfig,
  [FycConfigsEnum.DETAILS_BANNER_CONFIG]: detailsBannerConfig,
  [FycConfigsEnum.DETAILS_PAGE_CONFIG]: detailsPageConfig,
  [FycConfigsEnum.DETAILS_PART_CONFIG]: detailsPartConfig,
  [FycConfigsEnum.EPISODES_INFO_MODAL_CONFIG]: episodesInfoModalConfig,
  [FycConfigsEnum.EPISODES_PANEL_ITEM_CONFIG]: episodesPanelItemConfig,
  [FycConfigsEnum.FOOTER_CONFIG]: footerConfig,
  [FycConfigsEnum.FYC_PAGE_CONFIG]: fycPageConfig,
  [FycConfigsEnum.NAVBAR_CONFIG]: navbarConfig,
  [FycConfigsEnum.SLIDER_CONFIG]: sliderConfig,
  [FycConfigsEnum.CONTACT_US_CONFIG]: contactUsConfig,
};

export default ESPNConfig;
