/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import type {
  NSConfigurationComponentInterface,
  NSEntitlementDetailsComponentInterface,
  NSFycMovieMarqueeComponentInterface,
  NSFycSeriesComponentInterface,
  NSRelatedContentComponentInterface,
} from 'models/cms';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { categoriesTabData, isMDPDataValid } from 'utils';
import { isNSFycSeriesComponent } from 'utils/fyc';
import {
  contentCss,
  wrapperCss,
  reactTabsCss,
  tabsWrapperCss,
  tabTopCss,
  ulWrapperCss,
  tabTitleCss,
} from './styles';
import { Tab, Tabs as ReactTabs, TabList, TabPanel } from 'react-tabs';
import MovieDetailsSynopsis from '../../details-page-component/movie-details-synopsis';
import EpisodesPanel from 'components/fyc-component/episodes-panel';
import ScreeningsPanel from '../screenings-panel';
import type { TabInterface } from './helpers';
import CategoriesPanel from '../categories-panel';
import { useQuery } from 'utils/hooks';
import BonusContentPanel from '../bonus-content-panel';
import { HowToWatchPanel } from '../how-to-watch-panel';
import { ScorePanel } from '../score-panel';
import { STRING_KEYS, useStringsContext } from 'providers/strings-provider';
import { useSelector } from 'react-redux';
import { ComponentTypeEnum } from 'models/enums';
import { fycDataSelector } from 'store/slices/fyc';
import { TabTypeEnum } from 'models/enums';
import {
  SEARCHLIGHT,
  SEARCHLIGHT_AWARDS,
  useFycThemeContext,
} from 'providers/fyc-theme-provider';
import { useHistory } from 'react-router-dom';
import { AUTOPLAY } from 'pages/fyc-page/details-page';
import { isPlainView } from 'pages/fyc-page/helpers';
import DocumentsPanel from '../documents-panel';
import Quotes from '../quotes';
import SimpleSlider from '../simple-slider';
import PressPanel from '../press-panel';
import _ from 'lodash';
import {
  screeningsTabData,
  bonusContentTabData,
  hasHowToWatchData,
  hasScreenplayData,
  hasQuotesData,
  hasPressData,
  getHideTags,
  hideCurrentTab,
  removeHideTabs,
} from './helpers';

const SWOOGO = 'swoogo';

interface PropsInterface {
  data: NSFycMovieMarqueeComponentInterface | NSFycSeriesComponentInterface;
  details?: NSEntitlementDetailsComponentInterface;
  relatedContentData?: NSRelatedContentComponentInterface;
}

const FycDetailsPart: React.FC<PropsInterface> = ({
  data,
  details,
  relatedContentData,
}) => {
  const { components } = useSelector(fycDataSelector);
  const version = useFycThemeContext()?.version;
  const isPlain = isPlainView(components);
  const queryParams = useQuery();
  const swoogoVip = queryParams.get(SWOOGO);
  const { synopsis } = data;
  const autoPlayId = queryParams.get(AUTOPLAY);
  const isSeries = isNSFycSeriesComponent(data);

  const tabDisplayName = useMemo(
    () => queryParams.getAll('tab'),
    [queryParams],
  );

  const hasEpisode = isMDPDataValid({
    series: data as NSFycSeriesComponentInterface,
  });

  const hasSynopsis = isMDPDataValid({ synopsis });

  const { display: hasCategories, result: categoriesList } = categoriesTabData(
    data,
    false,
  );

  const { display: hasAccolades, result: accoladesList } = categoriesTabData(
    data,
    true,
    isPlain,
  );

  const { display: hasScreening, swoogoId } = screeningsTabData(
    data,
    swoogoVip,
  );

  const { display: hasBonusTab, result: bonusContentData } =
    bonusContentTabData(relatedContentData);

  const { display: hasScoreTab, result: scoreTabData } = bonusContentTabData(
    relatedContentData,
    true,
  );

  const { display: hasHowToWatch, result: howToWatchData } =
    hasHowToWatchData(data);

  const { display: hasScreenplay, result: screenplayData } =
    hasScreenplayData(relatedContentData);

  const { display: hasPress, result: pressData } =
    hasPressData(relatedContentData);

  const { display: hasQuotes, result: quotesData } = hasQuotesData(data);

  const { getStringByKey } = useStringsContext();

  const history = useHistory();
  const [selectedIndex, setSelectedIndex] = useState(0);

  const seriesComponent =
    isSeries ? (data as NSFycSeriesComponentInterface) : undefined;

  let defaultTabIndex = 0;

  // get hide-<tab> tags
  const hideTags = useMemo(() => getHideTags(data), [data]);

  const tabConfiguration = (
    components?.find((c) => c.type === ComponentTypeEnum.CONFIGURATION) as
      | NSConfigurationComponentInterface
      | undefined
  )?.tabConfiguration;

  const parsedTabConfiguration = useMemo(() => {
    if (!tabConfiguration) {
      return;
    }

    try {
      return JSON.parse(tabConfiguration) as TabInterface[];
    } catch (err) {
      return;
    }
  }, [tabConfiguration]);

  const tabNames = useMemo(() => {
    if (!parsedTabConfiguration) {
      return;
    }

    const tabNameList: string[] = [];

    // remove hide tabs
    const tabConfigObj = removeHideTabs(
      hideTags,
      parsedTabConfiguration,
      tabDisplayName,
    );

    for (const tab of tabConfigObj) {
      if (tab.tabId === TabTypeEnum.EPISODES && hasEpisode) {
        tabNameList.push(tab.displayName.toLowerCase());
      }

      if (tab.tabId === TabTypeEnum.RELATED && hasBonusTab) {
        tabNameList.push(tab.displayName.toLowerCase());
      }

      if (tab.tabId === TabTypeEnum.RSVP && hasScreening) {
        tabNameList.push(tab.displayName.toLowerCase());
      }

      if (tab.tabId === TabTypeEnum.SYNOPSIS && synopsis) {
        tabNameList.push(tab.displayName.toLowerCase());
      }

      if (tab.tabId === TabTypeEnum.CATEGORIES && hasCategories) {
        tabNameList.push(tab.displayName.toLowerCase());
      }

      if (tab.tabId === TabTypeEnum.ACCOLADES && hasAccolades) {
        tabNameList.push(tab.displayName.toLowerCase());
      }

      if (tab.tabId === TabTypeEnum.SCORE && hasScoreTab) {
        tabNameList.push(tab.displayName.toLowerCase());
      }

      if (tab.tabId === TabTypeEnum.WAYSTOWATCH && hasHowToWatch) {
        tabNameList.push(tab.displayName.toLowerCase());
      }

      if (tab.tabId === TabTypeEnum.SCREENPLAY && hasScreenplay) {
        tabNameList.push(tab.displayName.toLowerCase());
      }

      if (tab.tabId === TabTypeEnum.PRESS && hasPress) {
        tabNameList.push(tab.displayName.toLowerCase());
      }
    }

    return tabNameList;
  }, [
    hasAccolades,
    hasBonusTab,
    hasCategories,
    hasEpisode,
    hasHowToWatch,
    hasPress,
    hasScoreTab,
    hasScreening,
    hasScreenplay,
    hideTags,
    parsedTabConfiguration,
    synopsis,
    tabDisplayName,
  ]);

  const getDefaultTabIndex = useCallback(() => {
    // rsvp exists
    if (swoogoVip) {
      if (hasScreening) {
        return defaultTabIndex;
      } else {
        return 0;
      }
    }

    // autoplay exists
    if (autoPlayId && tabConfiguration) {
      if (!tabNames) {
        return 0;
      }

      const displayName = parsedTabConfiguration
        ?.find((tab) => tab.tabId === TabTypeEnum.RELATED)
        ?.displayName?.toLowerCase();

      if (displayName && tabNames.indexOf(displayName) !== -1) {
        return tabNames.indexOf(displayName);
      } else {
        return 0;
      }
    }

    // tab query exists
    if (tabDisplayName) {
      if (!tabNames) {
        return 0;
      }
      if (tabNames.indexOf(tabDisplayName[0]) !== -1) {
        return tabNames.indexOf(tabDisplayName[0]);
      } else {
        return 0;
      }
    }

    return defaultTabIndex;
  }, [
    autoPlayId,
    defaultTabIndex,
    hasScreening,
    parsedTabConfiguration,
    swoogoVip,
    tabConfiguration,
    tabDisplayName,
    tabNames,
  ]);

  const handleSelect = useCallback(
    (index: number) => {
      if (!tabNames) {
        return;
      }

      const currentParams = new URLSearchParams(window.location.search);
      let tabDisplayNameCopy = [...tabDisplayName];

      const shouldAppend: string[] = [];

      for (const tab of tabDisplayNameCopy) {
        if (hideTags.indexOf(tab) !== -1) {
          shouldAppend.push(tab);
        }
      }

      tabDisplayNameCopy = _.uniq([tabNames[index], ...shouldAppend]);

      currentParams.delete('tab');
      for (const tab of tabDisplayNameCopy) {
        currentParams.append('tab', tab);
      }

      history.replace(
        window.location.pathname + '?' + currentParams.toString(),
      );
    },
    [hideTags, history, tabDisplayName, tabNames],
  );

  // listen to tabs query modification
  useEffect(() => {
    if (!tabNames) {
      return;
    }

    const index = tabNames.indexOf(tabDisplayName[0]);

    if (index === -1) {
      handleSelect(getDefaultTabIndex());
      return;
    }

    setSelectedIndex(index);
  }, [
    getDefaultTabIndex,
    handleSelect,
    selectedIndex,
    tabDisplayName,
    tabNames,
  ]);

  const generateReactTabsByOrder = () => {
    if (!parsedTabConfiguration) {
      return null;
    }

    const tabListItems: JSX.Element[] = [];
    const tabPanelItems: JSX.Element[] = [];

    for (const tab of parsedTabConfiguration) {
      if (
        tab.tabId === TabTypeEnum.EPISODES &&
        hasEpisode &&
        seriesComponent &&
        !hideCurrentTab(tab.displayName, hideTags, tabDisplayName)
      ) {
        tabListItems.push(
          <Tab key={tab.tabId + 'list'}>
            <div
              css={tabTopCss}
              className="tab-selected"
            />
            <div css={tabTitleCss(version)}>{tab.displayName}</div>
          </Tab>,
        );

        tabPanelItems.push(
          <TabPanel key={tab.tabId + 'panel'}>
            <EpisodesPanel
              component={seriesComponent}
              details={details}
            />
          </TabPanel>,
        );
      }

      if (
        tab.tabId === TabTypeEnum.RELATED &&
        hasBonusTab &&
        !hideCurrentTab(tab.displayName, hideTags, tabDisplayName)
      ) {
        tabListItems.push(
          <Tab key={tab.tabId + 'list'}>
            <div
              css={tabTopCss}
              className="tab-selected"
            />
            <div css={tabTitleCss(version)}>{tab.displayName}</div>
          </Tab>,
        );
        tabPanelItems.push(
          <TabPanel key={tab.tabId + 'panel'}>
            <BonusContentPanel data={bonusContentData} />
          </TabPanel>,
        );
      }

      if (
        tab.tabId === TabTypeEnum.RSVP &&
        hasScreening &&
        !hideCurrentTab(tab.displayName, hideTags, tabDisplayName)
      ) {
        tabListItems.push(
          <Tab key={tab.tabId + 'list'}>
            <div
              css={tabTopCss}
              className="tab-selected"
            />
            <div css={tabTitleCss(version)}>{tab.displayName}</div>
          </Tab>,
        );
        if (!!swoogoVip) {
          defaultTabIndex = tabListItems.length - 1;
        }

        tabPanelItems.push(
          <TabPanel key={tab.tabId + 'panel'}>
            <ScreeningsPanel swoogoId={swoogoId} />
          </TabPanel>,
        );
      }

      if (
        tab.tabId === TabTypeEnum.SYNOPSIS &&
        synopsis &&
        !hideCurrentTab(tab.displayName, hideTags, tabDisplayName)
      ) {
        let color = 'white';

        if (version === SEARCHLIGHT || version === SEARCHLIGHT_AWARDS) {
          color = `#262626`;
        }

        tabListItems.push(
          <Tab key={tab.tabId + 'list'}>
            <div
              css={tabTopCss}
              className="tab-selected"
            />
            <div css={tabTitleCss(version)}>{tab.displayName}</div>
          </Tab>,
        );

        tabPanelItems.push(
          <TabPanel key={tab.displayName + 'panel'}>
            <MovieDetailsSynopsis
              center
              color={color}
              synopsis={synopsis}
            />
          </TabPanel>,
        );
      }

      if (
        tab.tabId === TabTypeEnum.CATEGORIES &&
        hasCategories &&
        !hideCurrentTab(tab.displayName, hideTags, tabDisplayName)
      ) {
        tabListItems.push(
          <Tab key={tab.tabId + 'list'}>
            <div
              css={tabTopCss}
              className="tab-selected"
            />
            <div css={tabTitleCss(version)}>{tab.displayName}</div>
          </Tab>,
        );

        tabPanelItems.push(
          <TabPanel key={tab.tabId + 'panel'}>
            <CategoriesPanel data={categoriesList} />
          </TabPanel>,
        );
      }

      if (
        tab.tabId === TabTypeEnum.ACCOLADES &&
        hasAccolades &&
        !hideCurrentTab(tab.displayName, hideTags, tabDisplayName)
      ) {
        tabListItems.push(
          <Tab key={tab.tabId + 'list'}>
            <div
              css={tabTopCss}
              className="tab-selected"
            />
            <div css={tabTitleCss(version)}>{tab.displayName}</div>
          </Tab>,
        );

        tabPanelItems.push(
          <TabPanel key={tab.tabId + 'panel'}>
            <CategoriesPanel data={accoladesList} />
          </TabPanel>,
        );
      }

      if (
        tab.tabId === TabTypeEnum.SCORE &&
        hasScoreTab &&
        !hideCurrentTab(tab.displayName, hideTags, tabDisplayName)
      ) {
        tabListItems.push(
          <Tab key={tab.tabId + 'list'}>
            <div
              css={tabTopCss}
              className="tab-selected"
            />
            <div css={tabTitleCss(version)}>{tab.displayName}</div>
          </Tab>,
        );

        tabPanelItems.push(
          <TabPanel key={tab.tabId + 'panel'}>
            <ScorePanel data={scoreTabData} />
          </TabPanel>,
        );
      }

      if (
        tab.tabId === TabTypeEnum.WAYSTOWATCH &&
        hasHowToWatch &&
        !hideCurrentTab(tab.displayName, hideTags, tabDisplayName)
      ) {
        tabListItems.push(
          <Tab key={tab.tabId + 'list'}>
            <div
              css={tabTopCss}
              className="tab-selected"
            />
            <div css={tabTitleCss(version)}>{tab.displayName}</div>
          </Tab>,
        );
        tabPanelItems.push(
          <TabPanel key={tab.tabId + 'panel'}>
            <HowToWatchPanel data={howToWatchData} />
          </TabPanel>,
        );
      }

      if (
        tab.tabId === TabTypeEnum.SCREENPLAY &&
        hasScreenplay &&
        !hideCurrentTab(tab.displayName, hideTags, tabDisplayName)
      ) {
        tabListItems.push(
          <Tab key={tab.tabId + 'list'}>
            <div
              css={tabTopCss}
              className="tab-selected"
            />
            <div css={tabTitleCss(version)}>{tab.displayName}</div>
          </Tab>,
        );
        tabPanelItems.push(
          <TabPanel key={tab.tabId + 'panel'}>
            <DocumentsPanel data={screenplayData} />
          </TabPanel>,
        );
      }

      if (
        tab.tabId === TabTypeEnum.PRESS &&
        hasPress &&
        !hideCurrentTab(tab.displayName, hideTags, tabDisplayName)
      ) {
        tabListItems.push(
          <Tab key={tab.tabId + 'list'}>
            <div
              css={tabTopCss}
              className="tab-selected"
            />
            <div css={tabTitleCss(version)}>{tab.displayName}</div>
          </Tab>,
        );
        tabPanelItems.push(
          <TabPanel key={tab.tabId + 'panel'}>
            <PressPanel data={pressData} />
          </TabPanel>,
        );
      }
    }

    return (
      <div css={wrapperCss}>
        <div css={contentCss(version)}>
          <div css={tabsWrapperCss(hasQuotes)}>
            {hasQuotes && (
              <div
                css={css`
                  overflow: hidden;
                  .slick-slider {
                    margin-top: 2rem;
                    margin-bottom: 50px;
                  }
                  .slick-track {
                    display: flex;
                    align-items: center;
                  }
                `}
              >
                <SimpleSlider
                  settings={{
                    infinite: true,
                    speed: 500,
                    autoplay: true,
                    autoplaySpeed: 10000,
                    arrows: false,
                  }}
                >
                  {quotesData?.map((item, index) => (
                    <Quotes
                      key={`quotes-${index}`}
                      content={item.quote}
                      source={item.author}
                    />
                  ))}
                </SimpleSlider>
              </div>
            )}

            <ReactTabs
              css={reactTabsCss(version)}
              selectedIndex={selectedIndex}
              onSelect={(index) => handleSelect(index)}
            >
              <TabList>
                <div css={ulWrapperCss}>{tabListItems}</div>
              </TabList>
              {tabPanelItems}
            </ReactTabs>
          </div>
        </div>
      </div>
    );
  };

  if (tabConfiguration) {
    return generateReactTabsByOrder();
  }

  if (
    !hasCategories &&
    !hasAccolades &&
    !hasEpisode &&
    !hasSynopsis &&
    !hasScreening &&
    !hasBonusTab &&
    !hasHowToWatch &&
    !hasScoreTab
  ) {
    return null;
  }

  return (
    <div css={wrapperCss}>
      <div css={contentCss(version)}>
        <div css={tabsWrapperCss}>
          <ReactTabs
            css={reactTabsCss(version)}
            selectedIndex={selectedIndex}
            onSelect={(index) => handleSelect(index)}
          >
            <TabList>
              <div css={ulWrapperCss}>
                {hasEpisode && (
                  <Tab>
                    <div
                      css={tabTopCss}
                      className="tab-selected"
                    />
                    <div css={tabTitleCss(version)}>{'Episodes'}</div>
                  </Tab>
                )}{' '}
                {hasCategories && (
                  <Tab>
                    <div
                      css={tabTopCss}
                      className="tab-selected"
                    />
                    <div css={tabTitleCss(version)}>{'Categories'}</div>
                  </Tab>
                )}{' '}
                {hasAccolades && (
                  <Tab>
                    <div
                      css={tabTopCss}
                      className="tab-selected"
                    />
                    <div css={tabTitleCss(version)}>{'Accolades'}</div>
                  </Tab>
                )}{' '}
                {hasHowToWatch && (
                  <Tab>
                    <div
                      css={tabTopCss}
                      className="tab-selected"
                    />
                    <div css={tabTitleCss(version)}>{'How to watch'}</div>
                  </Tab>
                )}{' '}
                {synopsis && (
                  <Tab>
                    <div
                      css={tabTopCss}
                      className="tab-selected"
                    />
                    <div css={tabTitleCss(version)}>{'Synopsis'}</div>
                  </Tab>
                )}{' '}
                {hasScreening && (
                  <Tab>
                    <div
                      css={tabTopCss}
                      className="tab-selected"
                    />
                    <div css={tabTitleCss(version)}>{'Screenings'}</div>
                  </Tab>
                )}{' '}
                {hasBonusTab && (
                  <Tab>
                    <div
                      css={tabTopCss}
                      className="tab-selected"
                    />
                    <div css={tabTitleCss(version)}>
                      {getStringByKey(STRING_KEYS.RELATED_CONTENT_TAB_LABEL)}
                    </div>
                  </Tab>
                )}{' '}
                {hasScoreTab && (
                  <Tab>
                    <div
                      css={tabTopCss}
                      className="tab-selected"
                    />
                    <div css={tabTitleCss(version)}>{'Score'}</div>
                  </Tab>
                )}
              </div>
            </TabList>
            {seriesComponent && hasEpisode && (
              <TabPanel>
                <EpisodesPanel
                  component={seriesComponent}
                  details={details}
                />
              </TabPanel>
            )}{' '}
            {hasCategories && (
              <TabPanel>
                <CategoriesPanel data={categoriesList} />
              </TabPanel>
            )}{' '}
            {hasAccolades && (
              <TabPanel>
                <CategoriesPanel data={accoladesList} />
              </TabPanel>
            )}{' '}
            {hasHowToWatch && (
              <TabPanel>
                <HowToWatchPanel data={howToWatchData} />
              </TabPanel>
            )}{' '}
            {synopsis && (
              <TabPanel>
                <MovieDetailsSynopsis
                  center
                  color={'white'}
                  synopsis={synopsis}
                />
              </TabPanel>
            )}{' '}
            {hasScreening && (
              <TabPanel>
                <ScreeningsPanel swoogoId={swoogoId} />
              </TabPanel>
            )}{' '}
            {hasBonusTab && (
              <TabPanel>
                <BonusContentPanel data={bonusContentData} />
              </TabPanel>
            )}{' '}
            {hasScoreTab && (
              <TabPanel>
                <ScorePanel data={scoreTabData} />
              </TabPanel>
            )}
          </ReactTabs>
        </div>
      </div>
    </div>
  );
};

export default FycDetailsPart;
