import type { NSTitleComponentInterface } from 'models/cms';
import type { FunctionComponent } from 'react';
import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles<any>((theme) => ({
  title: {
    ...theme.typography.headline,
    margin: `${theme.spacing.unit * 2} ${theme.spacing.unit * 5}`,
  },
}));

interface PropsInterface {
  component: NSTitleComponentInterface;
}

const NsTitleComponent: FunctionComponent<PropsInterface> = (
  props: PropsInterface,
) => {
  const classes = useStyles();
  return <h1 className={classes.title}>{props.component.title}</h1>;
};

export default NsTitleComponent;
