import type { PlayerConfig } from 'bitmovin-player';
import { HttpRequestType } from 'bitmovin-player';
import type { ErrorStateInterface } from 'models/interfaces/player-provider';
import { STRING_KEYS } from 'providers/strings-provider';

interface PlayerConfigOptionsInterface {
  autoplay: boolean;
  castReceiverConfig?: {
    cdnToken: string;
    movieId: string;
    movieType: string;
    playData: any;
  };
  enableCast: boolean;
  getStringByKey(key: string): string;
  setError(e: ErrorStateInterface): void;
  volume?: number;
}

export const RECEIVER_NAMESPACE = window.__CONFIG__?.receiverNamespace;
export const RECEIVER_APPLICATION_ID = window.__CONFIG__?.receiverApplicationId;
const BITMOVIN_KEY = window.__CONFIG__?.bitmovinKey;
const RECEIVER_VERSION = 'v3';
const CAST_TYPE = 'googlecast';

export const playerConfig = ({
  setError,
  getStringByKey,
  autoplay,
  volume = 100,
  castReceiverConfig,
  enableCast,
}: PlayerConfigOptionsInterface): PlayerConfig => {
  // bitmovin license key

  let retryCount = 0;

  return {
    key: BITMOVIN_KEY,
    licensing: {
      delay: 10000, // delay license request by 10 seconds
    },
    analytics: {
      config: {
        enabled: false,
        cookiesEnabled: false,
      },
    },
    network: {
      /* @ts-ignore TODO: TS2322: Type <T extends HttpResponseBody>(type: HttpRequestType, response: HttpResponse<T>) => Promise<HttpRequest> | undefined is not assignable to type <T extends HttpResponseBody>(type: HttpRequestType, response: HttpResponse<T>, retry: number) => Promise<HttpRequest>. */
      retryHttpRequest: function (type, response) {
        // Error event isn't captured properly in Safari, manually display error state for this context
        if (type === HttpRequestType.MANIFEST_HLS_MASTER) {
          return new Promise(function (resolve) {
            const timer = setTimeout(function () {
              resolve(response.request);
              retryCount++;
            }, 1000);

            if (retryCount === 2) {
              clearTimeout(timer);
              setError({
                title: 'An error occurred',
                message: getStringByKey(STRING_KEYS.PLAY_TITLE_ERROR),
                isOpen: true,
              });
            }
          });
        }
      },
    },
    location: {
      ui_css: 'styles/global-styles.tsx',
    },
    logs: {
      bitmovin: false, // turn off Bitmovin' credits in console
    },
    playback: {
      autoplay,
      volume,
    },
    tweaks: {
      disable_retry_for_response_status: {
        // disable retry on Apollo concurrency errors
        [HttpRequestType.DRM_LICENSE_FAIRPLAY]: [429],
        [HttpRequestType.DRM_LICENSE_WIDEVINE]: [429],
      },
      fairplay_ignore_duplicate_init_data_key_errors: true,
    },
    ui: false, // required when using WebPack bundle,
    remotecontrol:
      enableCast ?
        {
          type: CAST_TYPE,
          receiverApplicationId: RECEIVER_APPLICATION_ID,
          receiverVersion: RECEIVER_VERSION,
          messageNamespace: RECEIVER_NAMESPACE,
          customReceiverConfig: castReceiverConfig,
          rejoinActiveSession: true,
        }
      : undefined,
  };
};
