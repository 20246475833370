/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { reset2FA } from 'api';
import clsx from 'clsx';
import { Button } from 'components';
import Loader from 'components/loader';
import Logo from 'components/logo';
import type { WithErrorBoundaryPropsInterface } from 'hocs/with-error-boundary';
import withErrorBoundary from 'hocs/with-error-boundary';
import { WelcomeStepperEnum } from 'models/enums';
import { authenticator } from 'otplib';
import {
  actionButtonContainerCss,
  actionButtonCss,
  headerTextCss,
} from 'pages/styles';
import { STRING_KEYS, useStringsContext } from 'providers/strings-provider';
import appStoreButton from 'public/images/Apple_App_Store_Button.png';
import googleAuthApp from 'public/images/Google_Auth_Mobile.png';
import playStoreButton from 'public/images/Google_Play_Button.png';
import QRCode from 'qrcode.react';
import React, { useCallback, useEffect, useState } from 'react';
import OtpInput from 'react-otp-input';
import { useParams } from 'react-router-dom';
import { copyInputValue } from 'utils';
import { useQuery, useWindowSize } from 'utils/hooks';
import rootCss, { actionBtnCss, tokenInputStyle } from './styles';

interface PropsInterface {
  currentStep: number;
  fieldData: FieldDataInterface | null;
  handleFieldErrors(e: any): any;
  isReset2FA?: boolean | null;
  onNext(): void;
  sendData(data: FieldDataInterface, error?: any): any;
  setStep(step: number): void;
}

interface TFADataInterface {
  otpauth: string;
  secret: string;
}

interface FieldDataInterface {
  email: string;
  errors?: any;
  familyName: string;
  givenName: string;
  password: string;
  token: string;
  twoFactorSecret?: string;
}

const TwoFactorAuth: React.FC<
  PropsInterface & WithErrorBoundaryPropsInterface
> = ({
  currentStep,
  fieldData,
  onNext,
  sendData,
  isReset2FA,
  errorBoundary,
  setStep,
}) => {
  const [tfaData, setTfaData] = useState<TFADataInterface | null>(null);
  const [otpToken, setOtpToken] = useState<string | null>(null);
  const [errors, setErrors] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isCopied, setIsCopied] = useState<boolean>(false);
  const windowSize = useWindowSize();
  const { getStringByKey } = useStringsContext();
  const { resetToken } = useParams<{ resetToken: string }>();
  const query = useQuery();
  const subject = query.get('subject');
  const email = query.get('email');

  const handleCodeClick = (ev: React.MouseEvent<HTMLInputElement>) =>
    copyInputValue(ev).then(() => setIsCopied(true));

  const handleOtpTokenChange = (value: string) => {
    setOtpToken(value);
    setErrors(null);
  };

  const generateTfaData = useCallback(
    (user: string) => {
      // don't generate new codes if we already have one incase users use the back function
      if (tfaData) {
        return;
      }

      const secret = authenticator.generateSecret();

      const otpauth = authenticator.keyuri(
        user,
        getStringByKey(STRING_KEYS.AUTH_2FA_APP_LABEL) ?? '',
        secret,
      );

      setTfaData((prevState) => ({ ...prevState, secret, otpauth }));
    },
    [getStringByKey, tfaData],
  );

  const verifyToken = () => {
    if (tfaData?.secret && otpToken) {
      return authenticator.check(otpToken, tfaData.secret);
    }
  };

  const handleSubmit = async () => {
    const isVerified = verifyToken();
    const { retries } = errorBoundary;

    if (!isVerified) {
      if (retries.value === 0) {
        setErrors({
          token: { message: getStringByKey(STRING_KEYS.AUTH_2FA_FAIL) },
        });

        retries.set(1);
      } else if (retries.value >= 1) {
        setErrors({
          token: { message: getStringByKey(STRING_KEYS.AUTH_2FA_FAIL_X2) },
        });
      }
    }

    if (isVerified && !isReset2FA && fieldData && tfaData) {
      setIsLoading(true);
      sendData({ ...fieldData, twoFactorSecret: tfaData.secret });
    }

    if (isVerified && isReset2FA && tfaData && subject) {
      const { secret } = tfaData;

      try {
        setIsLoading(true);
        await reset2FA({ subject, secret, token: resetToken });
        setIsLoading(false);
        setStep(WelcomeStepperEnum.SUCCESS_STEP);
      } catch (e) {
        setIsLoading(false);
        console.error(e);
      }
    }
  };

  const renderStep = () => {
    switch (currentStep) {
      case WelcomeStepperEnum.INFO_STEP:
        return infoStep();
      case WelcomeStepperEnum.APP_INSTALL_STEP:
        return stepOne();
      case WelcomeStepperEnum.LINK_APP_STEP:
        return stepTwo();
      case WelcomeStepperEnum.ENTER_CODE_STEP:
        return stepThree();
      default:
        return;
    }
  };

  useEffect(() => {
    const user = isReset2FA ? email : `${fieldData?.email}`;

    if (user) {
      generateTfaData(user);
    }
  }, [email, fieldData, generateTfaData, isReset2FA]);

  const renderStepIndicator = () => {
    return (
      <div className="step-indicator">
        <div
          className={clsx([
            'step install-step',
            currentStep >= WelcomeStepperEnum.APP_INSTALL_STEP && 'step-fill',
          ])}
        ></div>
        <div
          className={clsx([
            'step link-step',
            currentStep >= WelcomeStepperEnum.LINK_APP_STEP && 'step-fill',
          ])}
        ></div>
        <div
          className={clsx([
            'step code-step',
            currentStep >= WelcomeStepperEnum.ENTER_CODE_STEP && 'step-fill',
          ])}
        ></div>
      </div>
    );
  };

  const infoStep = () => {
    const mobileView = () => {
      return (
        <div className="info-container">
          <Logo containerStyles={{ textAlign: 'center', marginTop: '2rem' }} />
          <p className="info-copy">
            {getStringByKey(STRING_KEYS.AUTH_TWO_FACTOR_AUTH_SETUP_DESC)}
          </p>
        </div>
      );
    };

    // info
    return (
      <React.Fragment>
        {windowSize.isMobile ?
          mobileView()
        : <React.Fragment>
            <h2
              className="main-heading"
              css={headerTextCss}
              data-testid="welcome-success-title"
            >
              {getStringByKey(STRING_KEYS.AUTH_TWO_FACTOR_AUTH_SETUP_TITLE)}
            </h2>
            <p className="tablet info-copy">
              {getStringByKey(STRING_KEYS.AUTH_TWO_FACTOR_AUTH_SETUP_DESC)}
            </p>
          </React.Fragment>
        }
      </React.Fragment>
    );
  };

  const stepOne = () => {
    const title = getStringByKey(
      STRING_KEYS.AUTH_TWO_FACTOR_AUTH_INSTALL_TITLE,
    );

    const subtitle = getStringByKey(
      STRING_KEYS.AUTH_TWO_FACTOR_AUTH_INSTALL_TITLE2,
    );

    const desc = getStringByKey(STRING_KEYS.AUTH_TWO_FACTOR_AUTH_INSTALL_DESC);

    const desc2 = getStringByKey(
      STRING_KEYS.AUTH_TWO_FACTOR_AUTH_INSTALL_DESC2,
    );

    // step 1
    return (
      <React.Fragment>
        <h2
          className="main-heading"
          css={headerTextCss}
          data-testid="welcome-success-title"
        >
          {title}
        </h2>
        {renderStepIndicator()}
        <p className="sub-heading">{subtitle}</p>
        {windowSize.isMobile ?
          <p className="body-copy">{desc}</p>
        : <React.Fragment>
            <p className="body-copy">{desc}</p>
            <p className="body-copy">{desc2}</p>
          </React.Fragment>
        }
        <div className="image-container">
          <div className="left-images">
            <img
              className="app-image"
              src={googleAuthApp}
            />
          </div>
          <div className="right-images">
            <a
              rel="noreferrer"
              target="_blank"
              className="store-links"
              href="https://apps.apple.com/us/app/google-authenticator/id388497605"
            >
              <img
                className="store-image"
                src={appStoreButton}
                alt="Google Authenticator App Store"
              />
            </a>
            <a
              rel="noreferrer"
              target="_blank"
              className="store-links"
              href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en"
            >
              <img
                className="store-image"
                src={playStoreButton}
                alt="Google Authenticator Play Store"
              />
            </a>
          </div>
        </div>
      </React.Fragment>
    );
  };

  const stepTwo = () => {
    // step 2
    const title = getStringByKey(STRING_KEYS.AUTH_TWO_FACTOR_AUTH_SCANQR_TITLE);

    const subtitle = getStringByKey(
      STRING_KEYS.AUTH_TWO_FACTOR_AUTH_SCANQR_TITLE2,
    );

    const mobileView = () => {
      return (
        <React.Fragment>
          <div className="tfa-body">
            <p className="sub-heading">{subtitle}</p>
            <p className="body-copy">
              Copy and paste this code into Google Authenticator
            </p>
            {tfaData?.secret && (
              <div className="code-container">
                {isCopied && <div className="code-copied">Code Copied!</div>}
                <input
                  className="tfa-secret body-copy"
                  onClick={handleCodeClick}
                  defaultValue={tfaData.secret}
                  readOnly
                />
              </div>
            )}
          </div>
          <div className="qrcode">
            <p className="body-copy">Or, scan this QR code</p>
            <QRCode
              value={tfaData?.otpauth ?? ''}
              size={185}
              includeMargin
            />
          </div>
        </React.Fragment>
      );
    };

    const view = () => {
      return (
        <React.Fragment>
          <div className="qrcode tfa-body">
            <p className="sub-heading">{subtitle}</p>
            <div className="qrcode-container">
              <QRCode
                value={tfaData?.otpauth ?? ''}
                size={185}
                includeMargin
              />
              <ol className="qrcode-instructions">
                <li className="qrcode-instruction">
                  Open Google Authenticator.
                </li>
                <li className="qrcode-instruction">
                  Touch &#39;Begin Set Up&#39;
                </li>
                <li className="qrcode-instruction">
                  Select &#39;Scan barcode&#39;
                </li>
                <li className="qrcode-instruction">
                  Allow the app to access your camera.
                </li>
                <li className="qrcode-instruction">Scan this Code</li>
              </ol>
            </div>
          </div>
          <div className="qrcode">
            <p className="body-copy">
              Or, copy and paste this code into Google Authenticator
            </p>

            {tfaData?.secret && (
              <div className="code-container">
                {isCopied && <div className="code-copied">Code Copied!</div>}
                <input
                  className="tfa-secret body-copy"
                  onClick={handleCodeClick}
                  defaultValue={tfaData.secret}
                  readOnly
                />
              </div>
            )}
          </div>
        </React.Fragment>
      );
    };

    return (
      <React.Fragment>
        <h2
          className="main-heading"
          css={headerTextCss}
          data-testid="welcome-success-title"
        >
          {title}
        </h2>
        {renderStepIndicator()}
        {tfaData && (windowSize.isMobile ? mobileView() : view())}
      </React.Fragment>
    );
  };

  const stepThree = () => {
    // step 3
    const title = getStringByKey(
      STRING_KEYS.AUTH_TWO_FACTOR_AUTH_ENTER_CODE_TITLE,
    );

    const subtitle = getStringByKey(
      STRING_KEYS.AUTH_TWO_FACTOR_AUTH_ENTER_CODE_TITLE2,
    );

    const desc = getStringByKey(
      STRING_KEYS.AUTH_TWO_FACTOR_AUTH_ENTER_CODE_DESC,
    );

    return (
      <React.Fragment>
        <h2
          className="main-heading"
          css={headerTextCss}
          data-testid="welcome-success-title"
        >
          {title}
        </h2>
        {renderStepIndicator()}
        <div className="tfa-body">
          <p className="sub-heading">{subtitle}</p>
          <p className="body-copy">{desc}</p>
        </div>
        <OtpInput
          className="token-input"
          value={otpToken ?? undefined}
          onChange={handleOtpTokenChange}
          numInputs={6}
          inputStyle={tokenInputStyle}
          containerStyle="token-container"
          shouldAutoFocus
          css={css`
            ${errors?.token && 'border: 1px solid #e4615d'}
          `}
        />
        {errors?.token && <p className="token-error">{errors.token.message}</p>}
      </React.Fragment>
    );
  };

  const renderButtonText = () => {
    const info = getStringByKey(STRING_KEYS.AUTH_TWO_FACTOR_AUTH_SETUP_CTA);
    const link = getStringByKey(STRING_KEYS.AUTH_TWO_FACTOR_AUTH_SCANQR_CTA);

    const install = getStringByKey(
      STRING_KEYS.AUTH_TWO_FACTOR_AUTH_INSTALL_CTA,
    );

    const code = getStringByKey(
      STRING_KEYS.AUTH_TWO_FACTOR_AUTH_ENTER_CODE_CTA,
    );

    if (currentStep === WelcomeStepperEnum.INFO_STEP) {
      return info;
    }

    if (currentStep === WelcomeStepperEnum.APP_INSTALL_STEP) {
      return install;
    }

    if (currentStep === WelcomeStepperEnum.LINK_APP_STEP) {
      return link;
    }

    if (currentStep === WelcomeStepperEnum.ENTER_CODE_STEP) {
      return code;
    }
  };

  const renderAction = () => {
    let action = onNext;

    if (currentStep === WelcomeStepperEnum.ENTER_CODE_STEP) {
      action = handleSubmit;
    }

    return (
      <Button
        onClick={action}
        type="button"
        theme="primary"
        data-testid="welcome-submit"
        disabled={
          currentStep === WelcomeStepperEnum.ENTER_CODE_STEP &&
          (otpToken?.length !== 6 || errors)
        }
        css={actionButtonCss}
      >
        {renderButtonText()}
      </Button>
    );
  };

  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <div
        className="tfa tfa-container"
        css={rootCss}
      >
        {renderStep()}
      </div>
      <div
        css={actionButtonContainerCss({
          actionButtonContainerCss: actionBtnCss,
        })}
      >
        {renderAction()}
      </div>
    </React.Fragment>
  );
};

export default withErrorBoundary(TwoFactorAuth);
