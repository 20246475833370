import type { SerializedStyles } from '@emotion/core';
import { css } from '@emotion/core';
import { mediaQuery, mediaQueryV2 } from 'utils/styles';
import { TABLET, TABLET_SMALL } from '../helpers';

export const wrapperCss = css`
  position: relative;
  z-index: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: ${30 / 16}rem;
  background: #181a25;
  backdrop-filter: blur(44.4583px);
`;

export const contentCss = (hasPlain: boolean): SerializedStyles => css`
  width: ${(919 / 1024) * 100}vw;
  ${!hasPlain && `height: ${(353 / 1024) * 100}vw;`}

  display: flex;

  ${hasPlain &&
  `
    max-height: 390px;
  `}

  // screen width <= 699px
  ${mediaQueryV2(TABLET_SMALL - 1)} {
    width: ${(552 / 600) * 100}vw;
    height: ${(229 / 600) * 100}vw;
  }
`;

export const leftCss = css`
  // screen width <= 699px
  ${mediaQueryV2(TABLET_SMALL - 1)} {
    width: ${(295.5 / 600) * 100}vw;
    background: rgba(255, 255, 255, 0.04);
  }
`;

export const imgWrapperCss = css`
  border-radius: 8px 0px 0px 0px;
  overflow: hidden;

  // 353px is the height of the img in Tablet design
  width: ${(353 / 1024 / 9) * 16 * 100}vw;
  height: ${(353 / 1024) * 100}vw;

  // screen width <= 699px
  ${mediaQueryV2(TABLET_SMALL - 1)} {
    width: ${(295.5 / 600) * 100}vw;
    height: ${(166 / 600) * 100}vw;
  }
`;

export const leftProxisMsgCss = css`
  display: none;

  ${mediaQueryV2(TABLET_SMALL - 1)} {
    display: flex;
    font-size: 10px;
    text-align: center;
    padding: 1rem 0.5rem;
  }
`;

export const rightCss = css`
  flex: 1;
  overflow-x: hidden;

  // width: calc(${(919 / 1024) * 100}vw - ${(353 / 1024 / 9) * 16 * 100}vw);
  // height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgba(255, 255, 255, 0.04);
  padding-bottom: 8px;
`;

export const titleCss = (hasPlain: boolean): SerializedStyles => {
  return css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    ${hasPlain ? `height: 100px;` : `height: ${(58 / 289) * 100}%;`}

    margin-top: ${(15 / 250) * 100}%;
    font-size: ${18 / 16}rem;
    font-weight: 600;
    text-align: center;

    img {
      height: 100%;
      width: auto;
      max-width: 100%;
    }

    ${hasPlain &&
    `
      margin-top: 40px;
      font-size: 32px;
    `}
  `;
};

export const nsImageExtendedCss = {
  nsImageContainerCss: css`
    width: auto;
    height: 100%;
  `,
};

export const timeNratingCss = css`
  font-size: 12px;
  display: flex;
  justify-content: center;
  height: ${14.67 / 16}rem;
  gap: 24px;
  margin-top: 1rem;

  ${mediaQuery(TABLET)} {
    font-size: 14px;
  }

  // screen width <= 699px
  ${mediaQueryV2(TABLET_SMALL - 1)} {
    margin-top: 1rem;
  }
`;

export const timeCss = css`
  display: flex;

  .time-text {
    height: 100%;
    align-self: end;
    flex-shrink: 0;
  }
`;

export const clockCss = css`
  height: ${14.67 / 16}rem;
  width: ${14.67 / 16}rem;

  margin-right: 8px;

  img {
    width: 100%;
    height: 100%;
  }
`;

export const ratingCss = css`
  align-self: end;

  border-radius: 18px;
  border: 1px solid #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 1px;
  padding-left: 8px;
  padding-right: 8px;
  flex-wrap: wrap;
  margin-left: 0;
  font-size: 12px;
`;

export const expiryWrapperCss = css`
  font-weight: 400;

  ${mediaQuery(TABLET)} {
    margin-top: 1rem;
  }

  // screen width <= 699px
  ${mediaQueryV2(TABLET_SMALL - 1)} {
    margin-top: 0.5rem;
  }

  p {
    margin: 0;
    letter-spacing: 0.2px;
  }

  .content-expiry-container span {
    font-size: 10px;

    ${mediaQuery(TABLET)} {
      font-size: 14px;
    }
  }
`;

export const videoActionWrapperCss = (
  hasPlain: boolean,
): SerializedStyles => css`
  width: ${(220 / 250) * 100}%;
  max-width: 224px;
  margin-top: 1rem;

  ${mediaQuery(TABLET)} {
    max-width: 320px;
  }

  .buttonsWrapper {
    display: flex;
    margin: 0 auto;
    justify-content: space-between;
    width: ${220 / 16}rem;
    height: 44px;
    padding: 0;
    algin-items: center;
  }

  button {
    width: 120px;
    text-transform: uppercase;
    font-size: ${14 / 16}rem;
    height: ${44 / 16}rem;

    ${mediaQuery(TABLET)} {
      width: 170px;
      font-size: 1rem;
    }
  }

  .action-button-container {
    justify-content: space-between;
    margin: 0;
    overflow: hidden;
    flex-wrap: wrap;

    // screen width <= 699px
    ${mediaQueryV2(TABLET_SMALL - 1)} {
      flex-direction: row;
    }
  }

  .proxis-message {
    ${hasPlain &&
    `
      display: flex;
    `}
  }
`;

export const progressWrapperCss = css`
  width: ${(220 / 256) * 100}%;
  max-width: ${224 / 16}rem;
  display: flex;
  justify-content: center;

  ${mediaQuery(TABLET)} {
    max-width: 320px;
  }

  .progress-bar-container {
    margin-bottom: 0;

    ${mediaQuery(TABLET)} {
      margin-top: 2rem;
      max-width: 220px;
    }

    // screen width <= 699px
    ${mediaQueryV2(TABLET_SMALL - 1)} {
      margin-top: ${24 / 16}rem;
      max-width: ${220 / 16}rem;
    }
  }

  .progress-bar {
    height: 4px;

    ${mediaQuery(TABLET)} {
      height: 8px;
    }
  }
`;
