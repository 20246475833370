import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import type { NSPageComponentInterface, NSPageInterface } from 'models/cms';
import type { ResponseError } from 'superagent';
import request from 'superagent';

import type { RootStateType } from '../root-reducer';
import type { CMSComponentInterface } from 'models/cms/cms-component-interface';
import JwtDecode from 'jwt-decode';
import type { TokenInfoInterface } from 'models/token-info-interface';
import * as endpoints from 'api/endpoints';
import type { AppThunkType } from 'store';

export interface PageStateInterface {
  components: NSPageInterface['components'] | null;
  entitlementCount: number | null;
  error?: ResponseError | null;
  isFetching: boolean | null;
  selectedEntitlement?: CMSComponentInterface | null;
}

export const initialState: PageStateInterface = {
  isFetching: null,
  components: null,
  entitlementCount: null,
  error: null,
  selectedEntitlement: null,
};

export interface RequestHeadersInterface {
  Authorization?: string;
  'NS-Platform': string;
}

interface OptionsInterface {
  accessToken?: string | null;
  callback?: any;
  slug: string;
}

export const isValidToken = (accessToken: string): boolean => {
  try {
    // only return a valid JWT
    const jwt = JwtDecode<TokenInfoInterface>(accessToken);
    return Date.now() < jwt.exp * 1000;
  } catch {
    return false;
  }
};

// thunks
export const fetchPage = createAsyncThunk<
  NSPageInterface,
  OptionsInterface,
  {
    rejectValue: PageStateInterface['error'];
  }
>(
  'page/fetch',
  async (
    { slug, accessToken, callback }: OptionsInterface,
    { rejectWithValue },
  ) => {
    const url = ['/', '/index', '/index.html'].includes(slug) ? '/' : slug;
    try {
      const headers: RequestHeadersInterface = { 'NS-Platform': 'WEB' };
      // only attach a valid token
      if (accessToken && isValidToken(accessToken)) {
        headers.Authorization = `Bearer ${accessToken}`;
      }
      const res = await request
        .get(endpoints.PAGES(url))
        .accept('json')
        .set(headers);

      // run callback after other synchronous code (state updates)
      if (callback) {
        setTimeout(() => callback(), 0);
      }

      return res.body as NSPageInterface;
    } catch (err) {
      const rejectValue =
        err instanceof Error ? (err as ResponseError) : new Error(String(err));
      return rejectWithValue(rejectValue);
    }
  },
);

const pageSlice = createSlice({
  name: 'page',
  initialState,
  reducers: {
    userSelectedEntitlement(state, action) {
      state.selectedEntitlement = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPage.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchPage.fulfilled, (state, action) => {
      state.isFetching = false;
      state.components = action.payload.components;
      state.entitlementCount =
        action.payload.userProfile?.entitlementCount ??
        initialState.entitlementCount;
      state.error = initialState.error;
    });
    builder.addCase(fetchPage.rejected, (state, action) => {
      state.isFetching = false;
      state.components = initialState.components;
      state.entitlementCount = initialState.entitlementCount;
      state.error = action.payload;
    });
  },
});

export const { userSelectedEntitlement } = pageSlice.actions;

export const entitlementSelected =
  (component: NSPageComponentInterface): AppThunkType =>
  (dispatch) => {
    dispatch(userSelectedEntitlement(component));
  };

// selectors
const pageSelector = (state: RootStateType): PageStateInterface | undefined =>
  state.page;

export const pageDataSelector = createSelector(pageSelector, (page) => ({
  components: page?.components,
  entitlementCount: page?.entitlementCount,
}));

export const selectedEntitlement = createSelector(
  pageSelector,
  (page: PageStateInterface) => page.selectedEntitlement,
);

export const pageFetchingSelector = createSelector(
  pageSelector,
  (page: PageStateInterface) => page.isFetching,
);

export const pageErrorSelector = createSelector(
  pageSelector,
  (page: PageStateInterface) => page.error,
);

export default pageSlice.reducer;
