import { css } from '@emotion/core';

const reactTabs = css`
  .react-tabs {
    -webkit-tap-highlight-color: transparent;
  }

  .react-tabs__tab-list {
    padding: 0;
    border-bottom: 2px solid #ffffff70;

    @media only screen and (min-width: 1025px) {
      margin-top: -5.55rem;
    }
  }

  .react-tabs__tab {
    display: inline-block;
    border: 1px solid transparent;
    border-bottom: none;
    bottom: -1px;
    position: relative;
    list-style: none;
    padding: 0.7rem 0.3rem 1rem 0.3rem;
    cursor: pointer;
    font-size: 1rem;
    color: #ffffff70;
    margin-right: 1rem;

    @media only screen and (min-width: 1025px) {
      margin-right: 3rem;
    }
  }

  .react-tabs__tab--selected {
    border-bottom: 2px solid white;
    color: #fff;
  }

  .react-tabs__tab--disabled {
    color: GrayText;
    cursor: default;
  }

  .react-tabs__tab:focus {
    box-shadow: 0 0 5px hsl(208, 99%, 50%);
    border-color: hsl(208, 99%, 50%);
    outline: none;
  }

  .react-tabs__tab:focus:after {
    content: '';
    position: absolute;
    height: 5px;
    left: -4px;
    right: -4px;
    bottom: -5px;
    background: #fff;
  }

  .react-tabs__tab-panel {
    display: none;
  }

  .react-tabs__tab-panel--selected {
    margin-top: 2rem;
    display: block;
  }
`;

export default reactTabs;
