/** @jsx jsx */
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import type { SerializedStyles } from '@emotion/core';
import type { NDAPropsInterface } from './details-banner/large-banner';
import { jsx } from '@emotion/core';
import { rootCss as rootStyles } from './styles';
import { getEntitlementProgress, isNSSeriesComponent } from 'utils';
import clsx from 'clsx';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import type { ProgressDataInterface } from 'store/slices/progress';
import { setProgress } from 'store/slices/progress';
import { STRING_KEYS, useStringsContext } from 'providers/strings-provider';
import { useComponentTypeFinder, useQuery, useWindowSize } from 'utils/hooks';
import type { LoadStateInterface } from 'hocs/with-nda-service';
import withNdaService from 'hocs/with-nda-service';
import { NdaTemplateStatusTypeEnum, ComponentTypeEnum } from 'models/enums';
import type {
  MovieDocTypeInterface,
  NSEntitlementDetailsComponentInterface,
  NSMovieMarqueeComponentInterface,
  NSRelatedContentComponentInterface,
  NSSeriesComponentInterface,
  NSUserBookmarkInterface,
} from 'models/cms';
import { pageDataSelector } from 'store/slices/page';
import { usePlayer } from 'providers/player-provider';
import DetailsBanner from './details-banner';
import DetailsPart from './details-part';
import DetailsPageLockView from './details-page-lock-view';
import {
  findEpisodeByPlayIdFromSeason,
  isMovieDetailsPage,
  isSeriesDetailsPage,
} from 'pages/ns-page/helpers';
import { AUTOPLAY } from 'pages/fyc-page/details-page';
import { removeQueryParam } from 'providers/sso-provider';
import { PlayerEmitterEventEnum } from 'models/interfaces/player-provider';

interface PropsInterface {
  className?: string;
  component: NSMovieMarqueeComponentInterface | NSSeriesComponentInterface;
  handleNdaClick(templateId: string): Promise<void>;
  isFYC?: boolean;
  loadState: LoadStateInterface;
  rootCss?: SerializedStyles;
  verifyNdaCompletion(templateId: string | undefined | null): boolean;
}

const DetailsPageComponent: React.FC<PropsInterface> = (props) => {
  const { component, loadState, verifyNdaCompletion, handleNdaClick, rootCss } =
    props;

  const entitlementDetails =
    useComponentTypeFinder<NSEntitlementDetailsComponentInterface>(
      ComponentTypeEnum.ENTITLEMENT_DETAILS,
    );
  const location = useLocation();
  const windowSize = useWindowSize();
  const dispatch = useDispatch();
  const { getStringByKey } = useStringsContext();
  const [isNdaOpen, setIsNdaOpen] = useState<boolean>(false);
  const { openPlayer } = usePlayer();
  const isSeries = isNSSeriesComponent(component);

  const seriesComponent =
    isSeries ? (component as NSSeriesComponentInterface) : undefined;

  const movieMarquee =
    !isSeries ? (component as NSMovieMarqueeComponentInterface) : undefined;

  const hasProgress = (
    bookmark: NSUserBookmarkInterface | undefined,
    runtime: number | undefined | null,
  ) => bookmark && (bookmark.progress || (bookmark.position && runtime));

  const { components: pageComponents } = useSelector(pageDataSelector);

  // Memoized to stop re-renders
  const relatedContents = useMemo(() => {
    return pageComponents?.filter(
      (component) => component?.type === ComponentTypeEnum.RELATED_CONTENT,
    ) as NSRelatedContentComponentInterface[];
  }, [pageComponents]);

  useEffect(() => {
    const createProgressItem = (
      bookmark: NSUserBookmarkInterface,
      runtime: number,
    ): ProgressDataInterface => {
      return {
        id: bookmark.docId,
        progress:
          bookmark.progress * 100 ||
          getEntitlementProgress({
            runtime: runtime,
            position: bookmark.position,
          }),
      };
    };

    const progressList: ProgressDataInterface[] = [];

    if (seriesComponent) {
      seriesComponent.seasons?.forEach((season) => {
        season.episodes?.forEach((episode) => {
          if (
            episode.bookmark &&
            episode.runtime &&
            hasProgress(episode.bookmark, episode.runtime)
          ) {
            progressList.push(
              createProgressItem(episode.bookmark, episode.runtime),
            );
          }
        });
      });
    }

    if (relatedContents) {
      relatedContents.forEach((relatedContent) => {
        relatedContent.items?.forEach((item) => {
          if (!item.bookmark) {
            return;
          }

          const playableContent = item as MovieDocTypeInterface;
          if (playableContent.bookmark && playableContent.runtime) {
            if (
              hasProgress(playableContent.bookmark, playableContent.runtime)
            ) {
              progressList.push(
                createProgressItem(
                  playableContent.bookmark,
                  playableContent.runtime,
                ),
              );
            }
          }
        });
      });
    }

    if (movieMarquee && movieMarquee.bookmark) {
      const itemHasProgress = hasProgress(
        movieMarquee.bookmark,
        movieMarquee.runtime,
      );

      if (itemHasProgress && movieMarquee.runtime) {
        progressList.push(
          createProgressItem(movieMarquee.bookmark, movieMarquee.runtime),
        );
      }
    }

    // Update progress
    if (progressList?.length > 0) {
      dispatch(setProgress(progressList));
    }
  }, [component, dispatch, movieMarquee, relatedContents, seriesComponent]);

  // handle auto play for movie/episode
  const autoPlayId = useQuery().get(AUTOPLAY);
  const history = useHistory();

  useEffect(() => {
    if (!autoPlayId) {
      return;
    }

    if (isMovieDetailsPage(location.pathname)) {
      openPlayer(autoPlayId, component);
    }

    if (isSeriesDetailsPage(location.pathname) && seriesComponent) {
      const episode = findEpisodeByPlayIdFromSeason(
        autoPlayId,
        seriesComponent,
      );

      if (episode) {
        openPlayer(autoPlayId, episode);
      } else {
        history.replace(location.pathname);
      }
    }
  }, [
    autoPlayId,
    component,
    seriesComponent,
    history,
    location.pathname,
    openPlayer,
  ]);

  const onPlayerClose = useCallback(() => {
    history.push(
      location.pathname + removeQueryParam(history.location.search, AUTOPLAY),
    );
  }, [history, location.pathname]);

  useEffect(() => {
    window.addEventListener(PlayerEmitterEventEnum.PLAYER_CLOSE, onPlayerClose);

    return () =>
      window.removeEventListener(
        PlayerEmitterEventEnum.PLAYER_CLOSE,
        onPlayerClose,
      );
  }, [onPlayerClose]);

  const ContentView: React.FC = () => (
    <Fragment>
      <DetailsBanner
        ndaProps={{
          isNdaOpen,
          setIsNdaOpen,
          text: getStringByKey(STRING_KEYS.PLAYBACK_ACCEPT) || '',
          isLocked:
            'entitlement' in component && component.entitlement?.ndaTemplate ?
              !verifyNdaCompletion(component.entitlement?.ndaTemplate)
            : false,
          handleAction:
            'entitlement' in component && component.entitlement?.ndaTemplate ?
              () => handleNdaClick(component.entitlement?.ndaTemplate ?? '')
            : undefined,
          loadState: loadState,
        }}
        data={component}
        hasPlain={!!entitlementDetails?.hasPlain}
      />
      <DetailsPart
        accolades={component.accolades}
        synopsis={component.synopsis}
        credits={component.credits}
        awardCategories={component.awardCategories}
        series={{
          component: component,
          handleNdaClick: handleNdaClick,
          verifyNdaCompletion: verifyNdaCompletion,
          openPlayer: openPlayer,
          isPlain: entitlementDetails?.hasPlain,
          isDesktop: windowSize.isDesktop,
        }}
        relatedContents={{
          components: relatedContents,
          handleNdaClick: handleNdaClick,
          verifyNdaCompletion: verifyNdaCompletion,
          openPlayer: openPlayer,
          isPlain: entitlementDetails?.hasPlain,
          isDesktop: windowSize.isDesktop,
          componentId: component.id,
        }}
      />
    </Fragment>
  );

  const renderContentView = () => {
    if (!movieMarquee && !seriesComponent) {
      return null;
    }

    const isLocked = !verifyNdaCompletion(
      movieMarquee?.entitlement?.ndaTemplate,
    );

    if (
      (!isLocked && !loadState.isLoading) ||
      isSeriesDetailsPage(location.pathname)
    ) {
      return <ContentView />;
    }

    if (
      isMovieDetailsPage(location.pathname) &&
      isLocked &&
      !loadState.isLoading
    ) {
      const ndaProps: NDAPropsInterface = {
        isNdaOpen,
        setIsNdaOpen,
        text: getStringByKey(STRING_KEYS.PLAYBACK_ACCEPT) || '',
        isLocked: !verifyNdaCompletion(component.entitlement?.ndaTemplate),
        loadState: loadState,
      };

      if (component.entitlement?.ndaTemplate) {
        const ndaTemplate = component.entitlement.ndaTemplate;

        ndaProps.handleAction = () => handleNdaClick(ndaTemplate);
      }

      return (
        <DetailsPageLockView
          component={component}
          hasPlain={!!entitlementDetails?.hasPlain}
          ndaProps={ndaProps}
        />
      );
    }
  };

  return (
    <React.Fragment>
      <div
        data-testid="DetailsPageComponent-item"
        className={
          !isSeries ?
            clsx(
              location.pathname.split('/')[1].includes('series') ?
                'sdp'
              : 'mdp',
            )
          : undefined
        }
        css={!isSeries && [rootStyles, rootCss]}
      >
        {renderContentView()}
      </div>
    </React.Fragment>
  );
};

const WithNdaServer = withNdaService(
  DetailsPageComponent,
  NdaTemplateStatusTypeEnum.COMPLETED,
);

export default WithNdaServer;
