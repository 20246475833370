/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useState } from 'react';
import { Field, Formik, Form } from 'formik';
import { Link } from 'react-router-dom';

import { Input, Button, Loader } from 'components';
import { LayoutContainer } from 'containers';
import * as routes from 'router/constants';
import deleteIcon from 'public/images/X_Circle.svg';

import {
  headerTextCss,
  actionButtonCss,
  actionButtonContainerCss,
  successInfoContainerCss,
  loginInfoCss,
} from './styles';
import { forgotPasswordApi } from 'api';
import { STRING_KEYS, useStringsContext } from 'providers/strings-provider';
import { isEmailValid } from 'utils';

const ForgotPassword: React.FC = () => {
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const { getStringByKey } = useStringsContext();

  const handleVerifyEmail = async ({ email }, setFieldError) => {
    if (isEmailValid(email)) {
      await forgotPasswordApi(email);
      setIsSuccess(true);
    } else {
      setFieldError(
        'email',
        getStringByKey(STRING_KEYS.AUTH_LOGIN_EMAIL_INVALID),
      );
    }
  };

  const onContinue = async (values, { setFieldError }) => {
    try {
      await handleVerifyEmail({ email: values.email }, setFieldError);
    } catch (error) {
      setFieldError(
        'email',
        getStringByKey(STRING_KEYS.AUTH_LOGIN_EMAIL_INVALID),
      );
    }
  };

  const form = (
    <Formik
      initialValues={{
        email: '',
      }}
      onSubmit={onContinue}
    >
      {({ isSubmitting, errors, values, setFieldValue }) => (
        <Form noValidate>
          {isSubmitting && <Loader />}
          <h2
            css={headerTextCss}
            data-testid="forgot-password-title"
          >
            Forgot Password
          </h2>
          <Field name="email">
            {({ field }) => (
              <Input
                type="email"
                placeholder="Enter your email"
                disabled={isSubmitting}
                data-testid="forgot-password-input"
                error={errors.email}
                icon={
                  !!values.email ?
                    {
                      src: deleteIcon,
                      onClick: () => setFieldValue('email', ''),
                      'data-testid': 'forgot-password-clear',
                    }
                  : undefined
                }
                {...field}
              />
            )}
          </Field>
          <div css={actionButtonContainerCss}>
            <Button
              type="submit"
              theme="primary"
              data-testid="forgot-password-submit"
              disabled={!values.email}
              css={actionButtonCss}
            >
              Continue
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );

  const successInfo = (
    <div
      css={successInfoContainerCss}
      className="col-sm-12"
    >
      <h2
        css={headerTextCss}
        data-testid="forgot-password-success-title"
      >
        Please check your email
      </h2>
      <p css={loginInfoCss}>To reset your password click on the email link.</p>
      <div css={actionButtonContainerCss}>
        <Link
          to={routes.LOGIN_LANDING}
          data-testid="forgot-password-success-link"
        >
          <Button
            css={actionButtonCss}
            theme="primary"
          >
            Back to Log In
          </Button>
        </Link>
      </div>
    </div>
  );

  return (
    <LayoutContainer auth>{isSuccess ? successInfo : form}</LayoutContainer>
  );
};

export default ForgotPassword;
