/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import RSVPList from '../rsvp-list';

interface PropsInterface {
  swoogoId: string; // swoogo folder id
}

const ScreeningsPanel: React.FC<PropsInterface> = ({ swoogoId }) => {
  return (
    <React.Fragment>
      <div>
        <RSVPList folderId={swoogoId} />
      </div>
    </React.Fragment>
  );
};

export default ScreeningsPanel;
