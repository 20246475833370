/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import React, { useEffect } from 'react';
import Logo from 'components/logo';

import {
  containerCss,
  footerLinkCss,
  FooterItem,
  logoCss,
  logoHiddenCss,
  fluidContainerCss,
  oneTrustWrapperCss,
} from './styles';
import clsx from 'clsx';
import { Link, useLocation } from 'react-router-dom';
import { TERMS, HELP } from 'router/constants';
import { STRING_KEYS, useStringsContext } from 'providers/strings-provider';
import { useSelector } from 'react-redux';
import { isKidsLoginSelector } from 'store/slices/user';
import { setCookie } from 'store/cookies';

interface PropsInterface {
  className?: any;
  fixed?: boolean;
  ref?: any;
  styles?: any;
}

export interface LinkObjectInterface {
  class?: string;
  id?: string;
  isOneTrust?: string;
  url: string;
  value: string;
}

const Footer: React.FC<PropsInterface> = React.forwardRef<
  HTMLElement,
  PropsInterface
>(({ fixed, styles, className }, ref) => {
  const { getStringByKey } = useStringsContext();
  // html entities in dynamic content are double escaped
  const copyright = getStringByKey(STRING_KEYS.GEN_COPYRIGHT);
  const WEB_COPPA_FOOTER_LINKS = getStringByKey(
    STRING_KEYS.WEB_COPPA_FOOTER_LINKS,
  );
  const WEB_FOOTER_LINKS = getStringByKey(STRING_KEYS.WEB_FOOTER_LINKS);

  const location = useLocation();
  const isKidsLogin =
    useSelector(isKidsLoginSelector) ||
    location.pathname.split('/')[2] === 'family'; // account for urls that end with a "/"

  const footerLinks = () => {
    setCookie('one_trust', 'empty');

    const results: JSX.Element[] = [];

    let links: LinkObjectInterface[] = [];

    try {
      links = JSON.parse(
        /* @ts-ignore TODO: TS2345: Argument of type string | undefined is not assignable to parameter of type string. */
        !!isKidsLogin ? WEB_COPPA_FOOTER_LINKS : WEB_FOOTER_LINKS,
      );
    } catch (error) {
      console.error(error);
    }

    links.map((l, i) => {
      if (!!l.isOneTrust) {
        results.push(
          <div
            className="col-sm-6"
            key={i}
            css={oneTrustWrapperCss}
          >
            <a
              rel="noreferrer"
              target="_blank"
              href={l.url}
              id={l.id}
              className={l.class}
            >
              {l.value}
            </a>
          </div>,
        );
      } else {
        results.push(
          <div
            className="col-sm-6"
            key={i}
          >
            <a
              rel="noreferrer"
              target="_blank"
              href={l.url}
              id={l.id}
              className={l.class}
              css={footerLinkCss}
            >
              <FooterItem>{l.value}</FooterItem>
            </a>
          </div>,
        );
      }
    });

    return results;
  };

  useEffect(() => {
    function reloadOTBanner() {
      const otConsentSdk = document.getElementById('onetrust-consent-sdk');
      if (otConsentSdk) {
        otConsentSdk.remove();
      }

      if (window.OneTrust != null) {
        window.OneTrust.Init();

        setTimeout(function () {
          window.OneTrust.LoadBanner();

          const toggleDisplay = document.getElementsByClassName(
            'ot-sdk-show-settings',
          ) as HTMLCollectionOf<HTMLElement>;

          for (let i = 0; i < toggleDisplay.length; i++) {
            toggleDisplay[i].onclick = function (event) {
              event.stopImmediatePropagation();
              window.OneTrust.ToggleInfoDisplay();
            };
          }
        }, 1000);
      }
    }

    reloadOTBanner();
  }, []);

  return (
    <footer
      className={clsx(['footer', className])}
      css={containerCss(fixed, styles)}
      ref={ref}
    >
      <div
        className="footer-fluid container-fluid"
        css={fluidContainerCss(styles)}
      >
        <div className="row">
          <div
            className={clsx(['col-sm-12', 'col-md-8', fixed && 'col-lg-12'])}
          >
            <div className="row">
              <div className="col-sm-6">
                <Link
                  to={TERMS}
                  css={footerLinkCss}
                >
                  <p>The Walt Disney Studios Debut Agreement</p>
                </Link>
              </div>
              <div className="col-sm-6">
                <Link
                  to={HELP}
                  css={footerLinkCss}
                >
                  <p>Help Center</p>
                </Link>
              </div>
              {footerLinks()}
            </div>
          </div>
          <div
            className="col-sm-12 col-md-4"
            css={[logoCss, fixed && logoHiddenCss]}
          >
            <Logo
              white
              logoStyles={css`
                height: ${24 / 16}rem;
              `}
            />
            <FooterItem>{copyright}</FooterItem>
          </div>
        </div>
      </div>
    </footer>
  );
});

export default Footer;
