import { ComponentTypeEnum } from 'models/enums';

const SWOOGO = 'swoogo';
const SWOOGO_VIP = 'swoogo_vip';
const AUDIO = 'audio';
const FILE = 'file';
const BONUS_CONTENT = 'bonus_content';
const ARTICLE = 'article';

export interface IScreeningsInterface {
  display: boolean;
  id: string;
}

export interface IRSVPResultInterface {
  display: boolean;
  swoogoId: string;
}

export interface IBonusContentInterface {
  display: boolean;
  result?: any;
}

export const screeningsTabData = (
  data: any,
  swoogo_vip?: string | null,
): IRSVPResultInterface => {
  const type = data?.type;
  let tags: string[] = [];

  if (type === ComponentTypeEnum.FYC_SERIES) {
    tags = data?.seasons[0]?.episodes[0]?.entitlement?.tags || [];
  } else if (type === ComponentTypeEnum.FYC_MOVIE_MARQUEE) {
    tags = data?.entitlement?.tags || [];
  }

  const { id: swoogoId, display: displaySwoogo } = createSwoogoResult(
    tags,
    swoogo_vip,
  );

  return {
    swoogoId,
    display: displaySwoogo,
  };
};

const createSwoogoResult = (tags, swoogo_vip) => {
  const result = tags.find((tag: string) => tag.includes(SWOOGO + '='));
  const resultVips = tags
    .filter((tag: string) => tag.includes(SWOOGO_VIP + '='))
    .map((tag) => getRSVPId(tag));

  // has swoogo id in url
  if (!!swoogo_vip) {
    if (!resultVips || !resultVips.includes(swoogo_vip)) {
      return { id: '', display: false };
    }

    return {
      id: swoogo_vip,
      display: true,
    };
  }

  // no swoogo id in url
  if (!result) {
    return {
      id: '',
      display: false,
    };
  }

  return {
    id: result ? getRSVPId(result) : '',
    display: true,
  };
};

const getRSVPId = (data: string): string => {
  // example: rsvp=156835
  const id = data.split('=')[1];
  return id;
};

export const bonusContentTabData = (
  data: any,
  isAudio?,
): IBonusContentInterface => {
  const relatedContentArr = data?.items || [];
  const bonusContentArr = relatedContentArr.filter((c) => {
    if (isAudio) {
      return c?.fycDocType === AUDIO;
    }
    return c?.fycDocType === BONUS_CONTENT;
  });

  if (bonusContentArr.length === 0) {
    return { display: false };
  }

  return { display: true, result: bonusContentArr };
};

export const hasHowToWatchData = (data: any) => {
  const howToWatch = data?.howToWatch || '';

  return {
    display: !!howToWatch,
    result: howToWatch,
  };
};

export const hasScreenplayData = (data: any) => {
  const relatedContentArr = data?.items || [];
  const documentsContentArr = relatedContentArr.filter(
    (c) => c.fycDocType === FILE,
  );

  return {
    display: !!documentsContentArr.length,
    result: documentsContentArr,
  };
};

export const hasQuotesData = (data: any) => {
  const quotes = data?.quotes || [];

  return {
    display: !!quotes.length,
    result: quotes,
  };
};

export const hasPressData = (data: any) => {
  const relatedContentArr = data?.items || [];
  const articlesContentArr = relatedContentArr.filter(
    (c) => c.fycDocType === ARTICLE,
  );

  return {
    display: !!articlesContentArr.length,
    result: articlesContentArr,
  };
};

// tabs are hide by display name
export const getHideTags = (data: any): string[] => {
  if (!data) {
    return [];
  }

  if (data.type === ComponentTypeEnum.FYC_MOVIE_MARQUEE) {
    const tags = data?.entitlement?.tags || [];
    const hideTags = tags.filter((tag: string) => tag.startsWith('hide-'));

    if (hideTags.length === 0) {
      return [];
    }

    const tabs = hideTags.map((tag: string) => {
      const tab = tag.split('-')[1].toUpperCase();
      return tab;
    });

    return tabs;
  }

  if (data.type === ComponentTypeEnum.FYC_SERIES) {
    let tags = [];
    try {
      tags = data.seasons[0].episodes[0].entitlement.tags || [];
    } catch (error) {}
    const hideTags = tags.filter((tag: string) => tag.startsWith('hide-'));

    if (hideTags.length === 0) {
      return [];
    }

    const tabs = hideTags.map((tag: string) => {
      const tab = tag.split('-')[1].toUpperCase();
      return tab;
    });

    return tabs;
  }

  return [];
};

export const hideCurrentTab = (
  tab: string,
  hideTabs: string[],
  tabsQuery: string[],
): boolean => {
  const uppercaseTabsQuery = tabsQuery.map((x) => x.toUpperCase());

  if (!tab || hideTabs.length === 0) {
    return false;
  }

  if (hideTabs.indexOf(tab.toUpperCase()) === -1) {
    return false;
  }

  if (uppercaseTabsQuery.indexOf(tab.toUpperCase()) !== -1) {
    return false;
  }

  return true;
};

export interface TabInterface {
  displayName: string;
  tabId: string;
}

export const removeHideTabs = (
  hideTabs: string[],
  tabs: TabInterface[],
  tabQuery: string[],
): { displayName: string; tabId: string }[] => {
  const newTabs: TabInterface[] = [];

  const upperCasedTabQuery = tabQuery.map((tab) => tab.toUpperCase());

  for (const tab of tabs) {
    if (
      hideTabs.indexOf(tab.displayName.toUpperCase()) === -1 ||
      upperCasedTabQuery.indexOf(tab.displayName.toUpperCase()) !== -1
    ) {
      newTabs.push(tab);
    }
  }

  return newTabs;
};
