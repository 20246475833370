export const formatCategoryToOptions = (
  cates: { title: string }[],
): { label: string; value: string }[] => {
  return cates.map((cate) => {
    return {
      value: cate.title,
      label: cate.title.toUpperCase(),
    };
  });
};
