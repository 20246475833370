import css from '@emotion/css';
import theme from 'styles/theme';
import { mediaQuery } from 'utils/styles';

export const resultsContainerCss = css`
  padding: 1rem ${21 / 16}rem;
  position: absolute;
  right: 0;
  top: ${49 / 16}rem;
  width: ${484 / 16}rem;
  height: ${442 / 16}rem;
  background-color: #31323d;
  border-radius: ${4 / 16}rem;
  box-shadow: ${theme.shadows[6]};
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 18px;
  }

  ::-webkit-scrollbar-thumb {
    background: #1c1c1c;
    border-radius: 9px;
    border-color: transparent;
    border-style: solid;
    border-width: ${6 / 16}rem;
    background-clip: padding-box;
  }

  p {
    font: ${14 / 16}rem ${theme.typography.fontFamily};
    color: #b6b6bb;
    margin: 0 0 1rem 0;
  }
`;

export const noResultsContainerCss = css`
  padding: 1rem ${24 / 16}rem;
  position: absolute;
  right: 0;
  top: ${49 / 16}rem;
  width: ${484 / 16}rem;
  height: ${174 / 16}rem;
  background-color: #31323d;
  border-radius: ${4 / 16}rem;
  box-shadow: ${theme.shadows[6]};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p:first-of-type {
    padding: 0 ${24 / 16}rem;
    font-size: ${18 / 16}rem;
    color: #ffffff;
    font-weight: 800;
    margin: 0;
    overflow: hidden;
    max-width: 44ch;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  & :nth-of-type(even) {
    font-size: ${18 / 16}rem;
    text-align: center;
    color: #b6b6bb;
    font-weight: 500;
  }
`;

export const resultCss = css`
  display: flex;
  position: relative;
  margin-bottom: ${9.66 / 16}rem;
  text-decoration: none;
`;

export const imageContainerCss = css`
  width: ${110 / 16}rem;
  margin-right: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: ${6 / 16}rem;

  .button-unlock-filter {
    filter: blur(11px);
    width: ${110 / 16}rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.0932583);
  }

  .button-unlock {
    margin-top: 0;
    display: flex;
    background: rgb(0 0 0 / 80%);
    width: ${93 / 16}rem;
    height: ${27 / 16}rem;
    position: absolute;
    justify-content: center;
    align-items: center;

    .lock-icon {
      height: 14px;
      width: 14px;
    }

    .button-unlock-text {
      display: flex;
      align-items: center;
      justify-content: center;
      ${mediaQuery(theme.breakpoints.md)} {
        margin: ${4 / 16}rem 0 0 ${9 / 16}rem;
        font-size: ${12 / 16}rem;
      }
    }
  }
`;

export const detailsContainerCss = css`
  p:first-of-type {
    font-size: ${14 / 16}rem;
    color: #ffffff;
    line-height: ${18 / 16}rem;
    margin-bottom: 0;
    overflow: hidden;
    max-width: 24ch;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  & :nth-of-type(even) {
    font-size: ${12 / 16}rem;
    color: #b6b6bb;
    line-height: 1rem;
  }
`;
