/** @jsx jsx */
import type {
  NSMovieMarqueeComponentInterface,
  NSSeriesComponentInterface,
} from 'models/cms';
import type { ReactNode } from 'react';
import React, { useEffect, useState } from 'react';
import { jsx } from '@emotion/core';
import { TABLET_X_SMALL } from './helpers';
import LargeBanner from './large-banner';
import type { LoadStateInterface } from 'hocs/with-nda-service';
import SmallBanner from './small-banner';

interface NDAPropsInterface {
  handleAction?(templateId: string): Promise<any>;
  isLocked?: boolean;
  isNdaOpen: boolean;
  loadState?: LoadStateInterface;
  setIsNdaOpen: (bool: boolean) => void;
  text: string;
}

interface PropsInterface {
  data: NSMovieMarqueeComponentInterface | NSSeriesComponentInterface;
  hasPlain: boolean;
  ndaProps?: NDAPropsInterface;
}

const DetailsBanner: React.FC<PropsInterface> = ({
  data,
  ndaProps,
  hasPlain,
}) => {
  const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  const viewRender = (width: number): ReactNode => {
    if (width >= TABLET_X_SMALL) {
      return (
        <LargeBanner
          ndaProps={ndaProps}
          data={data}
          hasPlain={hasPlain}
        />
      );
    }

    return (
      <SmallBanner
        ndaProps={ndaProps}
        data={data}
        hasPlain={hasPlain}
      />
    );
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return <div>{viewRender(screenWidth)}</div>;
};

export default DetailsBanner;
