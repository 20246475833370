/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { wrapperCss, titleCss, rowCss, itemCss } from './styles';

interface RowDataInterface {
  items: string[];
  title: string;
}

interface PropsInterface {
  data: RowDataInterface[];
}

const CreditsItemsList: React.FC<PropsInterface> = ({ data }) => {
  // each row contains two data
  return (
    <div css={wrapperCss}>
      {data?.map((row) => (
        <div
          key={row.title}
          css={rowCss}
        >
          <div
            className="title"
            css={titleCss}
          >
            {row.title}
          </div>
          <div className="itemWrapper">
            {row.items?.map((item, i) => (
              <div
                className="item"
                key={row.title + i}
                css={itemCss}
              >
                {item}
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default CreditsItemsList;
