import clsx from 'clsx';
import type {
  AllComponentTypesType,
  NSPageComponentInterface,
} from 'models/cms';
import React from 'react';
import { createUseStyles } from 'react-jss';

import { useWindowSize } from 'utils/hooks';

interface PropsInterface {
  children: React.ReactNode;
  layout: NSPageComponentInterface['layout'];
  type?: AllComponentTypesType;
}

const MAX_COLUMNS = 12;
//TODO: CSS in JS should be removed
const useStyles = createUseStyles<any>((theme) => ({
  root: {
    verticalAlign: 'top',
    width: (props) =>
      props.windowWidth < theme.breakpoints.md ?
        `${(props.xs / MAX_COLUMNS) * 100}%`
      : `${(props.md / MAX_COLUMNS) * 100}%`,
    // don't display entitlement details component
    '&.entitlement_details': {
      display: 'none',
    },
  },
}));

const NsComponentLayout: React.FC<PropsInterface> = (props: PropsInterface) => {
  const { windowWidth } = useWindowSize();
  const classes = useStyles({
    windowWidth,
    xs:
      props.layout?.grid ?
        props.layout.grid.find((g) => g.name === 'xs')?.columns
      : MAX_COLUMNS,
    md:
      props.layout?.grid ?
        props.layout.grid.find((g) => g.name === 'md')?.columns
      : MAX_COLUMNS,
  });

  return (
    <div className={clsx([classes.root, props.type?.toLowerCase()])}>
      {props.children}
    </div>
  );
};
export default NsComponentLayout;
