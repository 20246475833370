/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import * as routes from 'router/constants';
import { Button } from 'components';
import { LayoutContainer } from 'containers';
import {
  headerTextCss,
  loginInfoCss,
  actionButtonCss,
  actionButtonContainerCss,
  loginMyIDText,
} from '../styles';
import { userErrorSelector, logout } from 'store/slices/user';
import { useStringsContext, STRING_KEYS } from 'providers/strings-provider';
import { OAUTH_AUTHORIZE, CLIENT_ID } from 'api/endpoints';
import BackgroundVideo from 'components/background-video';
import { useWindowSize } from 'utils/hooks';
import { DeviceTypeEnum } from 'models/enums';
import ErrorModal from 'components/error-modal';

const isRedirectObject = (
  state: unknown,
): state is Record<string, { pathname: string; search: string }> => {
  return !!(state && typeof state === 'object' && 'from' in state);
};

const LoginLanding: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { getStringByKey } = useStringsContext();
  const error = useSelector(userErrorSelector);
  const windowSize = useWindowSize();
  const [isSplashOpen, setIsSplashOpen] = useState<boolean>(true);

  // redirect state
  const locationState: unknown = history.location.state;
  const redirectState = isRedirectObject(locationState) ? locationState : null;

  const hasActivation = !!(
    locationState &&
    typeof locationState === 'object' &&
    'activate' in locationState
  );

  const buildAuthorizeUrl = (): string => {
    const url = new URL(OAUTH_AUTHORIZE);
    url.searchParams.append('client_id', CLIENT_ID);
    url.searchParams.append('response_type', 'code');
    url.searchParams.append('provider', 'MYID');
    url.searchParams.append(
      'redirect_uri',
      window.location.origin + routes.LOGIN_OAUTH_RETURN,
    );

    // add the redirect destination url to state param
    if (redirectState?.from) {
      url.searchParams.append(
        'state',
        redirectState.from.pathname + redirectState.from.search,
      );
    } else if (hasActivation) {
      url.searchParams.append('state', JSON.stringify({ activate: true }));
    }

    return url.toString();
  };

  const handleSplashClose = () => {
    setIsSplashOpen(false);
  };

  const resetParamsAndErrorState = () => {
    dispatch(logout());
    history.push('/login');
    // force refresh just in case the TOC updated
    window.location.reload();
  };

  const renderSplashAnimation = () => {
    return (
      <BackgroundVideo
        onComplete={handleSplashClose}
        device={DeviceTypeEnum.MOBILE}
      />
    );
  };

  const shouldShowSplashAnimation = () => {
    return isSplashOpen && windowSize.isMobile;
  };

  // show splash animation before landing page render on mobile
  return shouldShowSplashAnimation() ?
      renderSplashAnimation()
    : <React.Fragment>
        <LayoutContainer
          auth
          hasSplashAnimation
        >
          {/* Show an alert for users logged-out */}
          {error && (
            <ErrorModal
              error={error}
              onClick={resetParamsAndErrorState}
            />
          )}

          <h2 css={headerTextCss}>Log In</h2>

          <p css={loginInfoCss}>
            {getStringByKey(STRING_KEYS.AUTH_LOGIN_START)}
          </p>

          <div css={actionButtonContainerCss}>
            <Button
              onClick={() =>
                history.push(routes.LOGIN_ACCESS_CODE, {
                  ...redirectState,
                  activate: hasActivation,
                })
              }
              theme="primary"
              data-testid="login-code-button"
              css={actionButtonCss}
            >
              Log In with Access Code
            </Button>
          </div>

          <div css={actionButtonContainerCss}>
            <Button
              onClick={() =>
                history.push(routes.LOGIN_EMAIL, {
                  ...redirectState,
                  activate: hasActivation,
                })
              }
              theme="primary"
              data-testid="login-email-button"
              css={actionButtonCss}
            >
              Log In with Email
            </Button>
          </div>

          <div
            css={[actionButtonContainerCss(), `text-align: center;`]}
            className="link container"
          >
            <a
              css={loginMyIDText}
              href={buildAuthorizeUrl()}
              data-testid="login-myid-button"
            >
              Log In with MyID
            </a>
          </div>
        </LayoutContainer>
      </React.Fragment>;
};

export default LoginLanding;
