/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Link } from 'react-router-dom';
import React from 'react';
import logo from 'public/images/Logo.svg';
import logoHorizontal from 'public/images/Logo_Horizontal.svg';
import logoFooter from 'public/images/Logo_Footer.svg';
import whiteLogo from 'public/images/Debut_Logo_AppBar_LightGrey.svg';
import blackLogo from 'public/images/blackLogo.svg';

interface PropsInterface {
  black?: boolean;
  containerStyles?: any;
  footer?: boolean;
  horizontal?: boolean;
  linkTo?: string;
  logoStyles?: any;
  white?: boolean;
}

const Logo: React.FC<PropsInterface> = ({
  horizontal,
  footer,
  white,
  containerStyles,
  logoStyles,
  linkTo,
  black,
}) => {
  const getLogoSrc = () => {
    if (horizontal) {
      return logoHorizontal;
    }

    if (footer) {
      return logoFooter;
    }

    if (white) {
      return whiteLogo;
    }

    if (black) {
      return blackLogo;
    }

    return logo;
  };

  const generateTag = (linkTo?: string) => {
    const imgTag = (
      <img
        src={getLogoSrc()}
        css={logoStyles}
      />
    );
    if (linkTo) {
      return <Link to={linkTo}>{imgTag}</Link>;
    }

    return imgTag;
  };

  return <div css={containerStyles}>{generateTag(linkTo)}</div>;
};

export default Logo;
