import { css } from '@emotion/core';

export const containerCss = css`
  width: 100%;
  padding-bottom: 1rem;
  background: rgba(241, 241, 241, 0.15);
  border: 1px solid #70767d;
  border-radius: 4px;

  display: flex;
  justify-content: space-between;
`;

export const leftContainerCss = css`
  margin-left: ${(25.33 / 1289) * 100}%;
  margin-top: ${17.33 / 16}rem;
  margin-right: min(1rem);

  img {
    width: 2rem;
  }
`;

export const contentCss = css`
  width: ${(1165 / 1289) * 100}%;
  margin-top: 1rem;

  .title {
    margin-bottom: ${4 / 16}rem;
    font-size: ${14 / 16}rem;
    font-weight: 600;
  }

  .body {
    font-size: ${12 / 16}rem;
    font-style: italic;
  }
`;

export const rightContainerCss = css`
  margin-right: ${(19.75 / 1289) * 100}%;
  margin-top: ${19.75 / 16}rem;
  width: ${14 / 16}rem;
  height: ${14 / 16}rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  img {
    width: 100%;
  }
`;
