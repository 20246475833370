/** @jsx jsx */
import { jsx } from '@emotion/core';
import type { NSActionInterface, NSImageInterface } from 'models/cms';

import React, { useRef, useEffect, useState, useCallback } from 'react';
import NsImage from '../ns-image';
import type { WindowSizeInterface } from 'utils/hooks';
import { useWindowSize } from 'utils/hooks';
import { calculateAspectRatioOfHeight } from 'utils';
import { brandItemRootCss } from './styles';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

export interface BrandItemPropsInterface {
  actions?: NSActionInterface[];
  className?: string;
  id: string;
  image?: NSImageInterface;
  isGridView?: boolean;
  title?: string;
  windowSize: WindowSizeInterface;
}

export interface ItemContainerInterface {
  aspectRatio: string;
  height: number;
  width: number;
}

const BrandItem: React.FC<BrandItemPropsInterface> = (props) => {
  const itemRef = useRef<HTMLDivElement>(null);

  const [itemContainer, setItemContainer] = useState<
    ItemContainerInterface | null | undefined
  >(null);

  const { isMobile, isTablet, isDesktop } = useWindowSize();

  const adjustSize = useCallback(() => {
    if (!itemRef.current) {
      return;
    }

    const width = Number(
      window.getComputedStyle(itemRef.current).width.replace('px', ''),
    );

    let r: undefined | string;

    if (isMobile) {
      r = '1:1';
    }

    if (isTablet || isDesktop) {
      r = '16:9';
    }

    const size = r ? calculateAspectRatioOfHeight(r, width) : null;

    setItemContainer(size);
  }, [isMobile, isTablet, isDesktop]);

  const findTarget = () =>
    props.actions?.find((action) => action.type.includes('PAGE'));

  useEffect(() => {
    adjustSize();
  }, [adjustSize]);

  // fix aspect ratio in Safari
  useEffect(() => {
    const ele = itemRef.current;

    if (ele) {
      const display = ele.style.display;
      ele.style.display = 'none';

      setTimeout(() => {
        ele.style.display = display;
      }, 0);
    }
  }, []);

  if (!props.image) {
    return null;
  }

  return (
    <div
      ref={itemRef}
      className={clsx([
        'brand-item border-rounded',
        props.isGridView,
        props.className,
      ])}
      css={[brandItemRootCss(itemContainer, props.isGridView)]}
      data-testid={`brand-item_${props.title}`}
    >
      <Link to={findTarget()?.target || '#'}>
        <NsImage
          className="brand-logo"
          url={props.image.url}
          aspectRatio={isMobile ? '1/1' : '16/9'}
          width={500}
          hasGradientOverlay={false}
        />
      </Link>
    </div>
  );
};

export default BrandItem;
