import type { SerializedStyles } from '@emotion/core';
import theme from 'styles/theme';
import { mediaQuery, generateStyle } from 'utils/styles';

export const buttonActionContainerCss = (styles?: SerializedStyles) => {
  const defaultCss = `
    display: flex;
    margin: 1rem 0 0 0;
    padding: 0;
    order: 2;

    min-width:100%;
    gap:1rem;

    flex-shrink:0;

    flex-direction: column;

    ${mediaQuery(theme.breakpoints.md)} {
      flex-direction: row;
    }

    ${mediaQuery(theme.breakpoints.xl)} {
      width: 35%;
    }
  `;

  return generateStyle({
    defaultStyle: defaultCss,
    extendedStyles: styles,
    key: 'buttonActionContainerCss',
    theme,
  });
};

export const pageActionContainerCss = (styles?: SerializedStyles) => {
  const defaultCss = `
      margin-bottom: 0.7rem;
      margin-top: 0.5rem;
      padding: 0;
      `;

  return generateStyle({
    defaultStyle: defaultCss,
    extendedStyles: styles,
    key: 'pageActionContainerCss',
    theme,
  });
};

// Same width declared in breakpoint md because default button styling overwrites this style.
export const playButtonCss = (styles?: SerializedStyles) => {
  const defaultCss = `
  position: relative;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  ${mediaQuery(theme.breakpoints.md)} {
    width: 14.6rem;
  }
  ${mediaQuery(theme.breakpoints.xl)} {
    height: 6.25rem;
    border-radius: 10.5px;
    width: 35%;
  }
`;

  return generateStyle({
    defaultStyle: defaultCss,
    extendedStyles: styles,
    key: 'playButtonCss',
    theme,
  });
};

export const playIconCss = (styles?: SerializedStyles) => {
  const defaultCss = `
    width: 1.3rem;
    margin-right: 0.3rem;

    ${mediaQuery(theme.breakpoints.xl)} {
      width: 3.3rem;
    }
  `;
  return generateStyle({
    defaultStyle: defaultCss,
    extendedStyles: styles,
    key: 'playIconCss',
    theme,
  });
};

export const buttonTextCss = (styles?: SerializedStyles) => {
  const defaultCss = `
    margin: 3px 0 0 0rem;
    letter-spacing: 0.8;

    ${mediaQuery(theme.breakpoints.xl)} {
      font-size: 2.1rem;
      line-height: 1.5;
    }
`;
  return generateStyle({
    defaultStyle: defaultCss,
    extendedStyles: styles,
    key: 'buttonTextCss',
    theme,
  });
};

export const buttonContentCss = (styles?: SerializedStyles) => {
  const defaultStyle = `
    width: 100%;
    display: flex;
    justify-content: center;
  `;

  return generateStyle({
    defaultStyle,
    extendedStyles: styles,
    key: 'buttonContentCss',
    theme,
  });
};

export const linkCss = (styles?: SerializedStyles) => {
  const defaultCss = `
    color: ${theme.palette.primary.light};
    text-decoration: underline;

    ${mediaQuery(theme.breakpoints.xl)} {
      font-size: 2.1rem;
    }
  `;

  return generateStyle({
    defaultStyle: defaultCss,
    extendedStyles: styles,
    key: 'linkCss',
    theme,
  });
};
