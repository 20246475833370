import theme from 'styles/theme';
import { css } from '@emotion/core';
import { mediaQuery } from 'utils/styles';
import captionsIcon from 'public/images/Message_Square.svg';
import { NORMAL_MODE_WIDTH } from './custom-ui/theater-mode-button';
import ChevronUp from '../../public/images/Chevron_Up.svg';
import ChevronDown from '../../public/images/Chevron_Down.svg';
import RewindIcon from '../../public/images/gobackward.10.svg';
import ForwardIcon from '../../public/images/goforward.10.svg';
import PauseIcon from '../../public/images/Video_Pause.svg';
import PlayIcon from '../../public/images/Video_Play.svg';

export const containerCss = css`
  width: 100%;
  margin: auto;
  position: relative;

  ${mediaQuery(theme.breakpoints.lg)} {
    width: ${NORMAL_MODE_WIDTH};
  }

  .player-close-button {
    background-color: transparent;
    background-origin: content-box;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 1.5em;
    border: 0;
    box-sizing: content-box;
    cursor: pointer;
    font-size: 1em;
    height: 1.5em;
    min-width: 1.5em;
    padding: 0.25em;
  }

  .close-container {
    text-align: right;
  }

  .entitlement-titleBar {
    display: flex;
    position: absolute;
    font-size: 1.125rem;
    width: fit-content;
    left: 18%;
    top: 8%;
    z-index: 3;
    align-items: end;
  }
`;

export const playerCss = (imageUrl) => css`
  .bmpui-ui-watermark,
  .bmpui-ui-settingspanelpageopenbutton {
    display: none;
  }

  .bmpui-ui-seekbar.disabled,
  .bmpui-ui-seekbar.disabled * {
    pointer-events: none;
  }

  .bmpui-ui-settings-panel {
    z-index: 2;
  }

  .bmpui-ui-titlebar {
    display: flex;
    justify-content: end;
    z-index: 1;
  }

  .bmpui-ui-cast-status-overlay {
    background: #111 url(${imageUrl.url}?r=2/3&w=235);
    background-position: center;
    background-repeat: no-repeat;

    .bmpui-container-wrapper {
      display: flex;
      align-items: center;
      margin-top: 0.75em;
      margin-left: 1.3em;

      .bmpui-ui-container {
        .bmpui-container-wrapper {
          flex-direction: column;
          align-items: flex-start;
        }
      }
    }

    .bmpui-ui-cast-entitlement-title {
      font-size: 1.125rem;
      font-family: ${theme.typography.fontFamily};
    }

    .bmpui-ui-cast-label-deviceName {
      font-size: 0.875rem;
      font-weight: 200;
      font-family: ${theme.typography.fontFamily};
    }
  }

  .bmpui-player-ui-toggle-button {
    background-image: url(${ChevronDown});
    background-color: transparent;
    background-origin: content-box;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 1.5em;
    border: 0;
    box-sizing: content-box;
    cursor: pointer;
    font-size: 1em;
    height: 1.5em;
    min-width: 1.5em;
    padding: 0.25em;
  }

  .bmpui-ui-cast-status-label {
    font-size: 0.875em;
    top: 15%;
    left: 4%;
    right: unset;
  }

  span,
  i {
    color: white;
    * {
      color: white !important;
    }
    c.cyan {
      color: red;
    }
  }

  .bmpui-ui-subtitle-overlay {
    font-family: Arial;
  }

  .bmpui-ui-subtitle-overlay.bmpui-controlbar-visible {
    bottom: 0;
  }

  .bmpui-ui-settings-panel-page .bmpui-container-wrapper > .bmpui-ui-label {
    width: 20%;
  }

  .bmpui-ui-language-select-box {
    margin-left: 0%;
    width: 80%;
    background-color: transparent;
    border: 0;
    color: #1fabe2;
    cursor: pointer;
    font-size: 0.8em;
    padding: 0.3em;
  }

  .bmpui-ui-subtitle-overlay .bmpui-ui-subtitle-label {
    font-size: 1.2em;
    @include media($xlarge) {
      font-size: 1.4em;
    }
  }

  .bmpui-fullscreen .bmpui-ui-subtitle-overlay .bmpui-ui-subtitle-label {
    font-size: 2em;
  }

  .bmpui-ui-fullscreentogglebutton .bmpui-label {
    position: absolute;
    right: 0;
  }

  .bitmovinplayer-container embed,
  .bitmovinplayer-container img,
  .bitmovinplayer-container object {
    height: auto;
  }

  .bmpui-ui-settings-panel-item {
    min-width: 320px;
  }

  .bmpui-ui-subtitlestogglebutton {
    background-image: url(${captionsIcon});
    background-color: transparent;
    background-origin: content-box;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 1.5em;
    border: 0;
    box-sizing: content-box;
    cursor: pointer;
    font-size: 1em;
    height: 1.5em;
    min-width: 1.5em;
    padding: 0.25em;
  }

  .bmpui-ui-cast-controls-overlay {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;

    &.bmpui-hidden {
      opacity: 0;
      transition:
        opacity 0.3s,
        visibility 0.3s;
      visibility: hidden;
    }

    .bmpui-custom-overlay-buttons {
      .bmpui-container-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .bmpui-ui-forwardbutton {
      background-image: url(${ForwardIcon});
      margin: 0 1rem;

      /* animation c is defined by BitMovin */
      &:hover {
        animation: c 3s ease-in-out infinite;
      }
    }

    .bmpui-ui-rewindbutton {
      background-image: url(${RewindIcon});
      margin: 0 1rem;

      /* animation c is defined by BitMovin */
      &:hover {
        animation: c 3s ease-in-out infinite;
      }
    }

    .ui-custom-play-overlay-button {
      &.ui-player-playing {
        .bmpui-image {
          background-image: url(${PauseIcon});
        }
      }
      &.ui-player-paused {
        .bmpui-image {
          background-image: url(${PlayIcon});
        }
      }
    }
  }

  .bmpui-ui-casttogglebutton.cast-highlight {
    background-color: rgba(97, 107, 207, 0.5);
    border-radius: 50px;
    border: 1px solid rgba(70, 88, 255, 0.4);
  }

  &:before {
    display: block;
    content: '';
    width: 100%;
    padding-bottom: 56.25%;
  }
`;

export const castMessageCss = css`
  position: absolute;
  right: 1rem;
  bottom: 3.25rem;
  background: rgba(70, 88, 255, 1);
  font-size: 0.875rem;
  height: 2rem;

  ${mediaQuery(theme.breakpoints.xs)} {
    width: 15rem;
  }

  ${mediaQuery(theme.breakpoints.md)} {
    width: 15.5rem;
  }

  .content {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
`;

export const playerToggleCss = css`
  position: relative;
  background: none;
  color: #fff;
  top: -3px;
  left: 30px;
  z-index: 5;
`;

export const entitlementTitleLargeCss = css`
  font-size: 2rem;
  position: absolute;
  top: 1.3em;
  left: 1em;
  z-index: 1;
`;

export const miniPlayerToggleCss = css`
  position: absolute;
  background: none;
  color: #fff;
  margin-right: 21px;
  margin-left: 21px;
  z-index: 5;
  top: 20%;
  left: 26%;
`;

export const closeIcon = css`
  width: 1.125rem;
  height: 1.125rem;
`;

export const modalOuterCss = css`
  width: 100%;
  height: 100%;
`;

// 88px is the height of bitmovin player controls
export const attendeeNotificationsCss = css`
  margin: 0;
  opacity: 67%;
  position: absolute;
  text-align: center;
  bottom: 88px;
  left: 0;
  right: 0;
  z-index: 1;

  p {
    margin: 0;
    margin-top: 3px;
  }
`;

/**
 * Converts a control bar button label into a help tip that displays on hover over icon
 */
export const buttonHelpTip = (buttonName: string, top = 1, right = 2.5) => css`
  .${buttonName} .bmpui-label {
    display: block;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.2s linear;
    position: absolute;
    top: ${top}rem;
    right: ${right}rem;
    background-color: #212330;
    padding: 0.5rem 1.1rem;
    border-radius: 0.4rem;
    text-align: center;
  }

  .${buttonName}:hover .bmpui-label {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.2s linear;
  }
`;

export const miniContainerCss = css`
  position: absolute;
  right: 4vw;
  bottom: 12vh;
  width: 375px;
  height: 62px;

  .bitmovinplayer-container {
    min-height: 62px;
    background: rgba(31, 31, 32, 1);
  }

  .small-collapsed-player {
    width: 375px;
    height: 62px !important;
  }

  .player-close-button {
    background-color: transparent;
    background-origin: content-box;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 1.5em;
    border: 0;
    box-sizing: content-box;
    cursor: pointer;
    font-size: 1em;
    height: 1.5em;
    min-width: 1.5em;
    padding: 0.25em;
  }

  .close-container {
    text-align: right;
  }
`;

export const miniPlayerCss = css`
  #bitmovinplayer-video-null {
    visibility: hidden;
  }

  .bmpui-ui-uicontainer {
    .bmpui-ui-container {
      .bmpui-container-wrapper {
        display: flex;
      }
    }
  }

  .bmpui-player-ui-toggle-button {
    background-image: url(${ChevronUp});
    background-color: transparent;
    background-origin: content-box;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 1.5em;
    border: 0;
    box-sizing: content-box;
    cursor: pointer;
    font-size: 1em;
    height: 1.5em;
    min-width: 1.5em;
    padding: 0.75em 0 0 0.75em;
  }

  .bmpui-ui-seekbar.disabled,
  .bmpui-ui-seekbar.disabled * {
    pointer-events: none;
  }

  .bmpui-seekbar-playbackposition-marker {
    visibility: hidden;
  }
  .bmpui-ui-seekbar {
    position: absolute;
    bottom: -6px;
    left: 0px;
    z-index: 2;
  }

  .bmpui-ui-settings-panel {
    z-index: 2;
  }

  .bmpui-ui-titlebar {
    background: none;
    display: flex;
    position: relative;
    visibility: unset !important;
    opacity: unset !important;

    .bmpui-container-wrapper {
      position: inital;
    }
  }

  .bmpui-ui-titlebar .bmpui-ui-label.bmpui-mini-player-title {
    font-size: 14px;
    font-weight: 600;
    font-family: ${theme.typography.fontFamily};
    min-width: 100px;
    max-width: 163px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .bmpui-ui-titlebar .bmpui-container-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
  }

  .bmpui-ui-titlebar .bmpui-ui-cast-entitlement-title {
    font-size: 18px;
    font-family: ${theme.typography.fontFamily};
  }

  .bmpui-ui-titlebar .bmpui-ui-cast-label-deviceName {
    font-size: 12px;
    margin-top: 3px;
    font-weight: 200;
    font-family: ${theme.typography.fontFamily};
  }

  span,
  i {
    color: white;
    * {
      color: white !important;
    }
    c.cyan {
      color: red;
    }
  }

  &:before {
    display: block;
    content: '';
    width: 100%;
    padding-bottom: 56.25%;
  }

  .cast-status-label {
    top: 15%;
    left: 5%;
    right: 0;
  }

  .bmpui-ui-playbacktogglebutton {
    position: absolute;
    transform: scale(1.25);
    right: 6%;
    top: 25%;
  }

  .bmpui-ui-buffering-overlay {
    top: -52px;
    transform: scale(0.5);
    background-color: unset;
  }

  .bmpui-miniplayer-poster {
    width: 105px;
    height: 58px;
    position: relative;
    background-color: #27283c;
    padding: 8px;
  }
`;

export const hiddenCss = css`
  display: none;
`;
