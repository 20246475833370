import type { NSMovieAdditionalInfoComponentInterface } from 'models/cms';
import type { FunctionComponent } from 'react';
import React from 'react';
import { createUseStyles } from 'react-jss';
import clsx from 'clsx';

const useStyles = createUseStyles<any>((theme) => ({
  root: {
    margin: `${theme.spacing.unit * 2} ${theme.spacing.unit * 5}`,
    ...theme.typography.copyrightText,
  },
}));

interface PropsInterface {
  className?: string;
  component: NSMovieAdditionalInfoComponentInterface;
}

const NsMovieAdditionalInfoComponent: FunctionComponent<PropsInterface> = (
  props: PropsInterface,
) => {
  const { className, component } = props;
  const classes = useStyles();

  const copyrightText = component?.movie?.copyrightInfo;

  if (!copyrightText) {
    return null;
  }

  return <p className={clsx(className, classes.root)}>{copyrightText}</p>;
};

export default NsMovieAdditionalInfoComponent;
