import Bowser from 'bowser';
import { deleteAllDismissedAnnouncement } from 'components/ns-component/ns-announcement-component/announcement/helpers';
import JwtDecode from 'jwt-decode';
import type {
  NSEntitlementDetailsComponentInterface,
  NSPageComponentInterface,
} from 'models/cms';
import { ComponentTypeEnum } from 'models/enums';
import { debounce } from 'lodash';
import { DeviceTypeEnum } from 'models/enums';
import { EntitlementViewTypeEnum } from 'models/enums';
import type React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { pageDataSelector } from 'store/slices/page';
import { tokenSelector } from 'store/slices/user';

import theme from 'styles/theme';
import { getEntitlementLayout } from 'utils';

export const useQuery = () => {
  const { search } = useLocation();

  return useMemo(() => {
    return new URLSearchParams(search);
  }, [search]);
};

export interface WindowSizeInterface {
  isDesktop: boolean;
  isMobile: boolean;
  isTablet: boolean;
  windowHeight: number | undefined;
  windowWidth: number | undefined;
}

export const useWindowSize = (): WindowSizeInterface => {
  const isClient = typeof window === 'object';

  const [windowWidth, setWindowWidth] = useState(
    isClient ? window.innerWidth : undefined,
  );

  const [windowHeight, setWindowHeight] = useState(
    isClient ? window.innerHeight : undefined,
  );

  useEffect(() => {
    if (isClient) {
      const setSize = debounce(() => {
        setWindowWidth(window.innerWidth);
        setWindowHeight(window.innerHeight);
      }, 100);

      window.addEventListener('resize', setSize);

      return () => {
        window.removeEventListener('resize', setSize);
      };
    }
  }, [isClient]);

  let isMobile = false;
  let isTablet = false;
  let isDesktop = false;

  if (windowWidth) {
    isMobile =
      windowWidth >= theme.breakpoints.xs && windowWidth < theme.breakpoints.md;

    isTablet =
      windowWidth >= theme.breakpoints.md && windowWidth < theme.breakpoints.lg;

    isDesktop = windowWidth >= theme.breakpoints.lg;
  }

  return {
    windowWidth,
    windowHeight,
    isMobile: isMobile,
    isTablet: isTablet,
    isDesktop: isDesktop,
  };
};

export interface TokenInfoInterface {
  aud: string;
  client_id: string;
  email: string;
  exp: number;
  iss: string;
  orgs?: [string];
  sub: string;
}

export const useDecodedJwt = (fycToken?: string): TokenInfoInterface | null => {
  let accessToken = useSelector(tokenSelector).accessToken;
  if (fycToken) {
    accessToken = fycToken;
  }

  if (!accessToken) {
    return null;
  }

  try {
    // only return a valid JWT
    const jwt = JwtDecode<TokenInfoInterface>(accessToken);
    if (Date.now() < jwt.exp * 1000) {
      return jwt;
    } else {
      // delete all dismissed announcements when token expires
      deleteAllDismissedAnnouncement();

      return null;
    }
  } catch {
    return null;
  }
};

export const useBowser = (): Bowser.Parser.ParsedResult => {
  const [details, setDetails] = useState<Bowser.Parser.ParsedResult>(() =>
    Bowser.parse(window.navigator.userAgent),
  );

  const updateDetails = () => {
    const bowser = Bowser.parse(window.navigator.userAgent);
    setDetails(bowser);
  };

  useEffect(() => {
    window.addEventListener('resize', updateDetails);
    return () => window.removeEventListener('resize', updateDetails);
  });

  return details;
};

/**
 * Grabs the page data from Redux and finds the target component
 *
 * @param {string} targetComponent - Name of the component type
 */
export const useComponentTypeFinder = <
  T extends NSPageComponentInterface = NSPageComponentInterface,
>(
  targetComponent: ComponentTypeEnum,
): T | undefined => {
  const { components } = useSelector(pageDataSelector);
  return useMemo(
    () =>
      components?.find((component) => component?.type === targetComponent) as
        | T
        | undefined,
    [components, targetComponent],
  );
};

/**
 * Checks to see if the device is supported. iPhone and iPad are not supported.
 *
 * @returns {boolean} true or false
 */
export const useIsDeviceSupported = (): boolean => {
  const { platform } = useBowser();
  const { maxTouchPoints } = window.navigator;
  const APPLE_VENDOR = 'Apple';

  let isDeviceSupported = true;

  if (platform.type === DeviceTypeEnum.DESKTOP) {
    if (maxTouchPoints > 0 && platform.vendor === APPLE_VENDOR) {
      isDeviceSupported = false;
    }
  } else if (
    platform.type === DeviceTypeEnum.MOBILE &&
    platform.vendor === APPLE_VENDOR
  ) {
    isDeviceSupported = false;
  } else if (
    platform.type === DeviceTypeEnum.TABLET &&
    platform.vendor === APPLE_VENDOR
  ) {
    isDeviceSupported = false;
  }

  return isDeviceSupported;
};

export const useView = (): EntitlementViewTypeEnum | undefined => {
  const [view, setView] = useState<EntitlementViewTypeEnum | undefined>();
  const { entitlementCount, components } = useSelector(pageDataSelector);

  const entitlementDetails =
    useComponentTypeFinder<NSEntitlementDetailsComponentInterface>(
      ComponentTypeEnum.ENTITLEMENT_DETAILS,
    );

  const isDeviceSupported = useIsDeviceSupported();

  const isGridView = useMemo(
    () => getEntitlementLayout(entitlementDetails?.hasPlain, entitlementCount),
    [entitlementDetails, entitlementCount],
  );

  useEffect(() => {
    if (isGridView) {
      setView(EntitlementViewTypeEnum.GRID);
    } else {
      setView(EntitlementViewTypeEnum.RIVER);
    }
  }, [isGridView, isDeviceSupported]);

  // if a slider exists, always show the grid view
  const sliderExists = !!components?.find(
    (c) => c.type === ComponentTypeEnum.GALLERY,
  );

  if (sliderExists) {
    return EntitlementViewTypeEnum.GRID;
  }
  return view;
};

/*
 * detect clicks outside the passed ref
 * */
export const useOutsideClick = (ref: React.RefObject<HTMLElement>): boolean => {
  const [clickOutside, setClickOutside] = useState(false);

  const handleClick = useCallback(
    (e: MouseEvent) => {
      if (ref.current && !ref.current.contains(e.target as Node)) {
        setClickOutside(true);
        return;
      }
      setClickOutside(false);
    },
    [ref],
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => document.removeEventListener('mousedown', handleClick);
  }, [ref, handleClick]);

  return clickOutside;
};
