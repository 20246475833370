import React from 'react';
import Item from '../item';

interface PropsInterface {
  items: any[];
}

const ItemList: React.FC<PropsInterface> = ({ items }) => {
  return (
    <div>
      {items.map((item, index) => {
        return (
          <div key={index}>
            <Item itemData={item} />
          </div>
        );
      })}
    </div>
  );
};

export default ItemList;
