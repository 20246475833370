import { css } from '@emotion/core';
import theme from 'styles/theme';
import { mediaQuery } from 'utils/styles';

export const lockedContentButton = css`
  margin: 0 0.5rem;

  ${mediaQuery(theme.breakpoints.md)} {
    height: 22px;
  }

  ${mediaQuery(theme.breakpoints.xl)} {
    height: 1.218vw;
    font-size: 1.018vw;
  }
`;
