import { css } from '@emotion/core';
import { FYC_MOBILE_WIDTH } from 'pages/fyc-page/helpers';
import { mediaQuery } from 'utils/styles';

export const selectContainerCss = css`
  width: 100%;

  ${mediaQuery(FYC_MOBILE_WIDTH)} {
    max-width: ${270 / 16}rem;
  }
`;
