/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import type { ICategoriesItemInterface } from 'models/interfaces';
import React from 'react';
import CategoryList from './list';
import { mediaQuery } from 'utils/styles';
import { FYC_MOBILE_WIDTH, FYC_TABLET_WIDTH } from 'pages/fyc-page/helpers';

interface PropsInterface {
  data: ICategoriesItemInterface[][];
}

const containerCss = css`
  ${mediaQuery(FYC_MOBILE_WIDTH)} {
    padding: 0 ${(88 / 890) * 100}%;
  }

  ${mediaQuery(FYC_TABLET_WIDTH)} {
    padding: 0 ${(100 / 890) * 100}%;
  }
`;

const CategoriesPanel: React.FC<PropsInterface> = ({ data }) => {
  return (
    <div css={containerCss}>
      {data?.map((list, index) => (
        <CategoryList
          key={index}
          data={list}
          index={index}
        />
      ))}
    </div>
  );
};

export default CategoriesPanel;
