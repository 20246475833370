/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import type { ReactElement } from 'react';
import React, { useCallback, useEffect, useRef } from 'react';
import { Track } from './track';
import { listTitleCss, wrapperCss, accordionWrapperCss } from './styles';
import { AUDIO_ENDED, AUDIO_PLAY } from 'components/audio-player';
import _ from 'lodash';
import Accordion from './accordion';

interface PropsInterface {
  data: any;
}

const lineCss = css`
  width: 100%;
  height: 1px;
  background: rgba(255, 255, 255, 0.1);
  margin-top: ${8 / 16}rem;
  margin-bottom: ${16 / 16}rem;
`;

export const ScorePanel: React.FC<PropsInterface> = ({ data }) => {
  const audioRefs = useRef<HTMLAudioElement[]>([]);
  const audioIndexRefs = useRef(0);

  const handleRef = useCallback((index, ref) => {
    audioRefs.current[index] = ref;
  }, []);

  const setAudioIndex = () => {
    const index = audioIndexRefs.current;
    audioIndexRefs.current++;
    return index;
  };

  const handleAudioEnded = useCallback((e) => {
    const index = e.detail.index;
    if (index + 1 < audioRefs.current.length) {
      audioRefs.current[index + 1].play();
    }
  }, []);

  const handleAudioPlay = useCallback((e) => {
    const index = e.detail.index;

    audioRefs.current.forEach((ref, i) => {
      if (index !== i) {
        ref.pause();
      }
    });
  }, []);

  const formatData = (data) => {
    const result: { [key: string]: string[] } = {};
    const otherTags = [];
    for (const item of data) {
      const tags = item?.entitlement?.tags || [];
      if (!tags.length) {
        // Temp until we can get more information on the schema
        // @ts-ignore TODO: TS2345: Argument of type 'any' is not assignable to parameter of type 'never'.
        otherTags.push(item);
      } else {
        for (const tag of tags) {
          if (tag.startsWith('score-cat-')) {
            if (tag in result) {
              result[tag].push(item);
            } else {
              result[tag] = [item];
            }
          } else {
            // Temp until we can get more information on the schema
            // @ts-ignore TODO: TS2345: Argument of type 'any' is not assignable to parameter of type 'never'.
            otherTags.push(item);
          }
        }
      }
    }

    // put item without any categories at the bottom
    if (otherTags.length) {
      result['Others'] = [...otherTags];
    }

    return result;
  };

  data = formatData(data);

  const generateAccordion = (category, data, flatView?): ReactElement => {
    let title = '';
    if (category === 'Others') {
      title = category;
    } else {
      title = category.replace(/^score-cat-/, '');
    }
    let subTitle = '1 Track';
    if (data.length > 1) {
      subTitle = `${data.length} Tracks`;
    }

    return (
      <div
        css={accordionWrapperCss}
        key={title + data.length}
      >
        <Accordion
          title={title}
          subtitle={subTitle}
          flatView={flatView}
        >
          <div css={wrapperCss}>
            <div className="score-title">
              <div css={listTitleCss}>Track List</div>
              <div className="audio-player-list">
                {data?.map((item, index) => (
                  <div key={index}>
                    <Track
                      url={item.audioUrl}
                      title={item.title}
                      index={setAudioIndex()}
                      artist={item.audioDetails}
                      passRef={handleRef}
                    />
                    <div
                      className="line"
                      css={lineCss}
                    ></div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Accordion>
      </div>
    );
  };

  const generateAccordionGroup = (data) => {
    // if there is no categories defined, display flat view
    let flatView = false;
    const keys = _.keys(data);

    if ('Others' in data && keys.length === 1) {
      flatView = true;
    }

    const result: ReactElement[] = [];
    _.forIn(data, function (value, key) {
      result.push(generateAccordion(key, value, flatView));
    });
    return result;
  };

  useEffect(() => {
    window.addEventListener(AUDIO_ENDED, handleAudioEnded);
    window.addEventListener(AUDIO_PLAY, handleAudioPlay);

    return () => {
      window.removeEventListener(AUDIO_ENDED, handleAudioEnded);
      window.removeEventListener(AUDIO_PLAY, handleAudioPlay);
    };
  }, [handleAudioEnded, handleAudioPlay]);

  return <React.Fragment>{generateAccordionGroup(data)}</React.Fragment>;
};
