import type { NSMarketingComponentInterface } from 'models/cms';
import type { FunctionComponent } from 'react';
import React from 'react';

import NsActionLink from './ns-action-link';
import NsImage from './ns-image';

interface PropsInterface {
  className?: string;
  component: NSMarketingComponentInterface;
}

const NsMarketingComponent: FunctionComponent<PropsInterface> = (
  props: PropsInterface,
) => {
  const { actions, image } = props.component;

  if (!image) {
    return null;
  }

  return (
    <NsActionLink actions={actions}>
      <NsImage
        alt={image.alt}
        url={image.url}
        width={1200}
        aspectRatio="24/5"
      />
    </NsActionLink>
  );
};

export default NsMarketingComponent;
