/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import type { FycPageConfigInterface } from 'providers/fyc-theme-component-interfaces';
import { FycConfigsEnum } from 'providers/fyc-theme-component-interfaces';
import { getVersionedComponentConfig } from 'providers/versions/fyc-theme-provider-utils';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import {
  fetchFycPage,
  fycIsFetchingSelector,
  fycDataSelector,
  fycErrorSelector,
} from 'store/slices/fyc';
import * as routes from 'router/constants';
import { useVerifyBrowserSupport } from 'containers/browser-compatibility/utils';
import ComponentContainer from 'components/component-container';
import ToastNotification from 'components/toast-nofication';
import { Loader } from 'components';
import PageLayout from './page-layout';
import { Navbar, FycFooter } from 'components/fyc-component';
import {
  MainPage,
  DetailsPage,
  ContactUsPage,
  getPageMetaData,
} from './helpers';
import type { FycVersionType } from 'providers/fyc-theme-provider';
import {
  ABC,
  DBT,
  DISNEYPLUS,
  DTS,
  FREEFORM,
  FX,
  HULU,
  NATGEO,
  SEARCHLIGHT,
  SEARCHLIGHT_AWARDS,
  useFycThemeContext,
} from 'providers/fyc-theme-provider';
import { ComponentTypeEnum } from 'models/enums';
import FycHelmet from './fyc-helmet';

const rootCss = (version: FycVersionType) => {
  const customCssConfig = getVersionedComponentConfig<
    FycPageConfigInterface['rootCss']
  >({
    version,
    component: FycConfigsEnum.FYC_PAGE_CONFIG,
    selector: 'rootCss',
  });

  let fontFamily = customCssConfig?.fontFamily ?? `futura-pt`;

  if (version === ABC) {
    fontFamily = 'gt-america-st';
  }

  if (version === DBT || version === DISNEYPLUS) {
    fontFamily = 'proxima-nova';
  }

  if (version === DTS) {
    fontFamily = 'Avenir';
  }

  if (version === HULU) {
    fontFamily = 'Graphik';
  }

  if (version === SEARCHLIGHT || version === SEARCHLIGHT_AWARDS) {
    fontFamily = `'Open Sans', sans-serif`;
  }

  if (version === NATGEO) {
    fontFamily = `Geograph Regular`;
  }

  if (version === FX) {
    fontFamily = `Bain`;
  }

  return css`
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    z-index: -10;

    font-family: ${fontFamily};
    color: #ffffff;
    font-weight: 400;
    label: rootCss;
  `;
};

const pageContainerCss = () => {
  return css`
    ::-webkit-scrollbar {
      display: none; /* for Chrome, Safari, and Opera */
    }

    overflow-y: scroll;
    flex: 1;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
  `;
};

const Index: React.FC = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const history = useHistory();
  const browser = useVerifyBrowserSupport();
  const isFetching = useSelector(fycIsFetchingSelector);
  const pageData = useSelector(fycDataSelector);
  const error = useSelector(fycErrorSelector);
  const [showNavbar, setShowNavbar] = useState(true);
  const isDetailsPage = DetailsPage(pathname);
  const isContactUsPage = ContactUsPage(pathname);

  const version = useFycThemeContext()?.version;

  useEffect(() => {
    // Remove trailing slash
    let path = pathname;
    if (pathname[pathname.length - 1] === '/') {
      path = pathname.slice(0, pathname.length - 1);
    }

    dispatch(fetchFycPage({ slug: path }));
  }, [dispatch, pathname]);

  useEffect(() => {
    const showNav = pageData?.components?.find(
      (component: any) =>
        component.type === ComponentTypeEnum.FYC_TOP_NAVIGATION,
    );
    setShowNavbar(!!showNav);
  }, [pathname, pageData]);

  useEffect(() => {
    const htmlStyle = document.documentElement.style;

    const backgroundFromConfig = getVersionedComponentConfig<
      FycPageConfigInterface['pageBackground']
    >({
      version,
      component: FycConfigsEnum.FYC_PAGE_CONFIG,
      selector: 'pageBackground',
    });

    // TODO: This is temporary to avoid effecting brands without configs
    if (backgroundFromConfig) {
      const isMainPage = !!MainPage(pathname);
      htmlStyle.minHeight = '100%';

      if (isMainPage) {
        htmlStyle.backgroundSize = '100% 100%';
        htmlStyle.backgroundRepeat = 'no-repeat';
      }
      htmlStyle.background = backgroundFromConfig;

      return;
    }

    if (!!MainPage(pathname)) {
      htmlStyle.background = '';
      if (version === ABC) {
        htmlStyle.background = `rgba(10, 19, 32, 1)`;
      } else if (version === FREEFORM) {
        htmlStyle.background = `rgba(0, 0, 0, 1)`;
      } else if (version === DBT || version === DISNEYPLUS) {
        htmlStyle.background = `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(343.11deg, #00D5F4 -139.25%, #0C1D49 -8.2%, #000000 89.84%)`;
      } else if (version === DTS) {
        htmlStyle.background = `linear-gradient(149.07deg, #000428 -0.03%, #004887 146.68%)`;
      } else if (version === HULU) {
        htmlStyle.background = `linear-gradient(136.53deg, #040405 -19.92%, #183949 88.86%)`;
      } else if (version === SEARCHLIGHT || version === SEARCHLIGHT_AWARDS) {
        htmlStyle.background = `#F1EDE5`;
      } else if (version === NATGEO || version === FX) {
        htmlStyle.background = `#000`;
      } else {
        htmlStyle.background = `linear-gradient(0.48deg, #01182F 0.41%, #000000 84.74%), linear-gradient(72.65deg, #000428 0%, #004E92 137.08%)`;
      }
      htmlStyle.backgroundSize = '100% 100%';
      htmlStyle.backgroundRepeat = 'no-repeat';
      htmlStyle.minHeight = '100%';
    } else {
      htmlStyle.background = '';
      if (version === ABC) {
        htmlStyle.background = `rgba(10, 19, 32, 1)`;
      } else if (version === FREEFORM) {
        htmlStyle.background = `rgba(0, 0, 0, 1)`;
      } else if (version === DBT || version === DISNEYPLUS) {
        htmlStyle.background = `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(343.11deg, #00D5F4 -139.25%, #0C1D49 -8.2%, #000000 89.84%)`;
      } else if (version === DTS) {
        htmlStyle.background = `linear-gradient(149.07deg, #000428 -0.03%, #004887 146.68%)`;
      } else if (version === HULU) {
        htmlStyle.background = `linear-gradient(136.53deg, #040405 -19.92%, #183949 88.86%)`;
      } else if (version === SEARCHLIGHT || version === SEARCHLIGHT_AWARDS) {
        htmlStyle.background = `#F1EDE5`;
      } else if (version === NATGEO || version === FX) {
        htmlStyle.background = `#000`;
      } else {
        htmlStyle.backgroundImage = `linear-gradient(0.48deg, #01182F 0.41%, #000000 84.74%), linear-gradient(72.65deg, #000428 0%, #004E92 137.08%)`;
      }
      htmlStyle.minHeight = '100%';
    }
  }, [version, pathname]);

  useEffect(() => {
    if (error?.status === 404) {
      history.push(routes.NOT_FOUND);
    }
  }, [error, history]);

  return (
    <div
      className="page"
      css={rootCss(version)}
    >
      <FycHelmet data={getPageMetaData(pageData)} />
      {showNavbar && (
        <Navbar
          pageData={pageData}
          showCategory={!isContactUsPage}
        />
      )}
      <div css={pageContainerCss()}>
        {!browser.isCompatible && (
          <ComponentContainer>
            <div className="row">
              <ToastNotification
                head={browser.data?.header}
                body={browser.data?.body_message}
              />
            </div>
          </ComponentContainer>
        )}
        {isFetching ?
          <Loader />
        : <PageLayout
            isDetailsPage={isDetailsPage}
            pageData={pageData}
            pathname={pathname}
          />
        }
        <FycFooter />
      </div>
    </div>
  );
};

export default Index;
