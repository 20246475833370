/** @jsx jsx */
import type {
  NSEntitlementDetailsComponentInterface,
  NSEntitlementsComponentInterface,
} from 'models/cms';
import React, { useEffect, useMemo, useState } from 'react';
import type {
  LoadStateInterface,
  NdaServicePropsInterface,
} from 'hocs/with-nda-service';
import withNdaService from 'hocs/with-nda-service';
import { rootContainerCss } from './styles';
import { jsx } from '@emotion/core';
import { progressDataSelector, setProgress } from 'store/slices/progress';
import { useDispatch, useSelector } from 'react-redux';
import { STRING_KEYS, useStringsContext } from 'providers/strings-provider';
import { NdaTemplateStatusTypeEnum } from 'models/enums';
import { getEntitlementProgress } from 'utils';
import { useComponentTypeFinder, useView, useWindowSize } from 'utils/hooks';
import EntitlementGrid from 'components/entitlement-grid';
import ComponentContainer from 'components/component-container';
import { TagEnum } from 'models/enums';
import { ComponentTypeEnum } from 'models/enums';
import { EntitlementViewTypeEnum } from 'models/enums';
import EntitlementSlider from 'components/entitlement-slider';
import RiverViewEntitlementWrapper from './river-view-entitlement-wrapper';
import { usePlayer } from 'providers/player-provider';

interface NsEntitlementsComponentPropsInterface {
  closePlayer(): void;
  component: NSEntitlementsComponentInterface;
  handleNdaClick(
    templateId: string,
    options?: {
      onSecondaryClick?(): void;
    },
  ): Promise<void>;
  isSlider?: boolean;
  loadState: LoadStateInterface;
  verifyNdaCompletion: NdaServicePropsInterface['verifyNdaCompletion'];
}

const NsEntitlementsComponent: React.FC<
  NsEntitlementsComponentPropsInterface
> = (props) => {
  const { handleNdaClick, verifyNdaCompletion, component, isSlider } = props;
  const progressData = useSelector(progressDataSelector);
  const { getStringByKey } = useStringsContext();
  const dispatch = useDispatch();
  const entitlementDetails =
    useComponentTypeFinder<NSEntitlementDetailsComponentInterface>(
      ComponentTypeEnum.ENTITLEMENT_DETAILS,
    );

  const view = useView();

  const { isDesktop } = useWindowSize();

  const [isNdaOpen, setIsNdaOpen] = useState<boolean>(false);

  const { closePlayer } = usePlayer();

  // Filter out content with the viewer tag
  const filteredItems = useMemo(
    () =>
      component.items?.filter(
        (item) => !item.entitlement?.tags?.includes(TagEnum.VIEWER),
      ),
    [component],
  );

  useEffect(() => {
    if (component.items?.length && component.items.length > 0) {
      component.items.forEach((item) => {
        if (item.bookmark && item.runtime) {
          dispatch(
            setProgress({
              id: item.bookmark.docId,
              progress: getEntitlementProgress({
                runtime: item.runtime,
                position: item.bookmark.position,
              }),
            }),
          );
        }
      });
    }
  }, [dispatch, component]);

  if (!filteredItems) {
    return null;
  }

  switch (view) {
    case EntitlementViewTypeEnum.GRID:
      return (
        <ComponentContainer>
          <div className="row">
            {isSlider ?
              <EntitlementSlider
                isDesktop={isDesktop}
                isPlain={entitlementDetails?.hasPlain}
                verifyNdaCompletion={verifyNdaCompletion}
                handleNdaClick={handleNdaClick}
                // @ts-ignore TODO: TS2322: Type 'NSEntitlementItemInterface[]' is not assignable to type 'EntitlementComponentInterface[]'.
                component={{ items: filteredItems }}
                progressData={progressData}
                shouldUseKeyArt
                // TODO: Temp fix
                title={component.title ?? ''}
              />
            : <EntitlementGrid
                isDesktop={isDesktop}
                isPlain={entitlementDetails?.hasPlain}
                verifyNdaCompletion={verifyNdaCompletion}
                handleNdaClick={handleNdaClick}
                // @ts-ignore TODO: TS2322: Type 'NSEntitlementItemInterface[]' is not assignable to type 'EntitlementComponentInterface[]'.
                component={{ items: filteredItems }}
                progressData={progressData}
                shouldUseKeyArt
              />
            }
          </div>
        </ComponentContainer>
      );
    case EntitlementViewTypeEnum.RIVER:
      return (
        <RiverViewEntitlementWrapper
          items={filteredItems}
          progressData={progressData}
          ndaProps={{
            isNdaOpen,
            setIsNdaOpen,
            text: getStringByKey(STRING_KEYS.PLAYBACK_ACCEPT),
            handleAction: (template) =>
              handleNdaClick(template, {
                onSecondaryClick: closePlayer,
              }),
            verifyNdaCompletion,
          }}
          rootContainerCss={rootContainerCss}
        />
      );
    default:
      return null;
  }
};

const WithNdaService = withNdaService(
  NsEntitlementsComponent,
  NdaTemplateStatusTypeEnum.COMPLETED,
);

export default WithNdaService;
