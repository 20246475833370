import type { SerializedStyles } from '@emotion/core';
import { css } from '@emotion/core';
import { FYC_TABLET_WIDTH } from 'pages/fyc-page/helpers';
import type { BonusContentPanelItemConfigInterface } from 'providers/fyc-theme-component-interfaces';
import { FycConfigsEnum } from 'providers/fyc-theme-component-interfaces';
import type { FycVersionType } from 'providers/fyc-theme-provider';
import {
  SEARCHLIGHT,
  SEARCHLIGHT_AWARDS,
  TWDS_AWARDS,
} from 'providers/fyc-theme-provider';
import { getVersionedComponentConfig } from 'providers/versions/fyc-theme-provider-utils';
import { TWDS_AWARDS_CARD_BG } from 'styles/fyctheme';
import { mediaQuery } from 'utils/styles';

const MDP_MOBILE_WIDTH = 599;

export const wrapperCss = css`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  padding: 0 1rem;

  ${mediaQuery(MDP_MOBILE_WIDTH)} {
    flex-direction: row;
    margin-top: ${19 / 16}rem;
    padding: 0 ${65 / 16}rem;
  }

  ${mediaQuery(FYC_TABLET_WIDTH)} {
    padding: 0 ${122 / 16}rem;
    margin-top: ${25 / 16}rem;
  }
`;

export const imageWrapperCss = (version: FycVersionType): SerializedStyles => {
  const customCssConfig = getVersionedComponentConfig<
    BonusContentPanelItemConfigInterface['imageWrapperCss']
  >({
    version,
    component: FycConfigsEnum.BONUS_CONTENT_PANEL_ITEM_CONFIG,
    selector: 'imageWrapperCss',
  });

  let topColor = customCssConfig?.topColor ?? 'inherit';
  let topBg =
    customCssConfig?.topBg ??
    `linear-gradient(
    359.43deg,
    rgba(0, 78, 146, 0.46) -294.8%,
    #000428 34.06%,
    #004e92 276.33%
  )`;

  if (version === SEARCHLIGHT || version === SEARCHLIGHT_AWARDS) {
    topColor = 'white';
  }

  if (version === TWDS_AWARDS) {
    topBg = TWDS_AWARDS_CARD_BG;
  }

  return css`
    width: 100%;
    aspect-ratio: 16 / 9;
    cursor: pointer;
    overflow: hidden;
    flex-shrink: 0;

    background: linear-gradient(180deg, #404461 0%, #232434 100%);
    box-shadow:
      0px 4px 5px rgba(0, 0, 0, 0.14),
      0px 1px 10px rgba(0, 0, 0, 0.12),
      0px 2px 4px -1px rgba(0, 0, 0, 0.2);
    border-radius: 2px;
    label: imageWrapperCss;

    ${mediaQuery(MDP_MOBILE_WIDTH)} {
      width: ${288 / 16}rem;
    }

    .top {
      color: ${topColor};
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 1rem;

      background: ${topBg};
      border-radius: 2px;
    }
  `;
};

export const textWrapperCss = css`
  font-size: 1rem;
  font-weight: 500;
  padding-top: ${10 / 16}rem;
  font-family: inherit;

  ${mediaQuery(MDP_MOBILE_WIDTH)} {
    padding-top: ${12 / 16}rem;
    margin-left: ${32 / 16}rem;
  }
`;
