import type { SerializedStyles } from '@emotion/core';
import css from '@emotion/css';
import type { FooterComponentConfigInterface } from 'providers/fyc-theme-component-interfaces';
import { FycConfigsEnum } from 'providers/fyc-theme-component-interfaces';
import { getVersionedComponentConfig } from 'providers/versions/fyc-theme-provider-utils';
import { mediaQuery } from 'utils/styles';
import { FYC_MOBILE_WIDTH, FYC_TABLET_WIDTH } from 'pages/fyc-page/helpers';
import type { FycVersionType } from 'providers/fyc-theme-provider';
import {
  DBT,
  DISNEYPLUS,
  DTS,
  FX,
  HULU,
  NATGEO,
  SEARCHLIGHT,
  SEARCHLIGHT_AWARDS,
} from 'providers/fyc-theme-provider';

export const footerCss = css`
  margin-top: ${56 / 16}rem;
  padding: 0 1rem;

  ${mediaQuery(FYC_MOBILE_WIDTH)} {
    padding: 0 2rem;
  }

  ${mediaQuery(FYC_TABLET_WIDTH)} {
    padding: 0 ${74 / 16}rem;
  }
`;

export const wrapperCss = (): SerializedStyles => {
  return css`
    display: flex;
    flex-direction: column;
    width: 100%;

    .linksPart {
      margin-right: ${3 / 16}rem;
    }

    ${mediaQuery(FYC_MOBILE_WIDTH)} {
      flex-direction: row;
      justify-content: space-between;
    }
  `;
};

export const logoCss = css`
  width: ${77 / 16}rem;
  margin-top: ${14 / 16}rem;
  display: flex;
  justify-content: end;

  ${mediaQuery(FYC_MOBILE_WIDTH)} {
    margin-top: ${28 / 16}rem;
  }

  ${mediaQuery(FYC_TABLET_WIDTH)} {
    margin-top: 0rem;
  }
`;

export const copyRightCss = (version: FycVersionType): SerializedStyles => {
  const customCssConfig = getVersionedComponentConfig<
    FooterComponentConfigInterface['copyRightCss']
  >({
    version,
    component: FycConfigsEnum.FOOTER_CONFIG,
    selector: 'copyRightCss',
  });

  let color = `rgba(255, 255, 255, 0.67)`;
  let fontSize = `${10 / 16}rem`;

  // TODO: Temp fix to avoid effecting brands without a config
  if (customCssConfig) {
    return css({
      fontSize: customCssConfig?.fontSize ?? fontSize,
      fontWeight: customCssConfig?.fontWeight,
      letterSpacing: customCssConfig?.letterSpacing,
      fontFamily: customCssConfig?.fontFamily,
      lineHeight: `${20 / 16}rem`,
      marginTop: `${8 / 16}rem`,
      whiteSpace: 'nowrap',
      color: customCssConfig?.color ?? color,
      textTransform: customCssConfig?.textTransform ?? 'uppercase',
      label: 'copyRightCss',
    });
  }

  if (version === DTS) {
    fontSize = `${12 / 16}rem`;
  }

  if (version === SEARCHLIGHT || version === SEARCHLIGHT_AWARDS) {
    color = `rgba(0, 0, 0, 0.67)`;
  }

  return css`
    font-size: ${fontSize};
    line-height: ${20 / 16}rem;
    margin-top: ${8 / 16}rem;
    white-space: nowrap;
    color: ${color};
    text-transform: uppercase;
    label: copyRightCss;
  `;
};

export const contactCss = (version: FycVersionType): SerializedStyles => {
  const customCssConfig = getVersionedComponentConfig<
    FooterComponentConfigInterface['contactCss']
  >({
    version,
    component: FycConfigsEnum.FOOTER_CONFIG,
    selector: 'contactCss',
  });

  let fontWeight = customCssConfig?.fontWeight ?? 500;
  let fontSize = customCssConfig?.fontSize ?? `${14 / 16}rem`;
  let color = customCssConfig?.color ?? 'inherit';
  let fontFamily = customCssConfig?.fontFamily ?? 'inherit';

  if (version === DBT || version === DISNEYPLUS) {
    fontWeight = 400;
  }

  if (version === HULU) {
    fontSize = `${12 / 16}rem`;
    fontWeight = 400;
  }

  if (version === SEARCHLIGHT || version === SEARCHLIGHT_AWARDS) {
    color = 'rgba(41, 41, 41, 1)';
    fontWeight = 600;
  }

  if (version === NATGEO) {
    fontFamily = `Geograph Medium`;
    fontWeight = 400;
  }

  return css`
    font-family: ${fontFamily};
    color: ${color};
    margin-bottom: ${18 / 16}rem;
    font-weight: ${fontWeight};
    font-size: ${fontSize};
    line-height: ${18 / 16}rem;
    text-transform: uppercase;
    label: contactCss;

    .contact-us {
      margin-right: ${23 / 16}rem;
    }
  `;
};

export const tosCss = (version: FycVersionType): SerializedStyles => {
  const customCssConfig = getVersionedComponentConfig<
    FooterComponentConfigInterface['tosCss']
  >({
    version,
    component: FycConfigsEnum.FOOTER_CONFIG,
    selector: 'tosCss',
  });

  let color = customCssConfig?.color ?? `rgba(255, 255, 255, 0.67)`;
  let fontWeight = customCssConfig?.fontWeight ?? 'inherit';

  if (version === SEARCHLIGHT || version === SEARCHLIGHT_AWARDS) {
    color = `rgba(41, 41, 41, 1)`;
  }

  if (version === FX) {
    fontWeight = 200;
  }

  return css`
    a,
    span {
      text-decoration: none;
      text-transform: uppercase;
      font-size: ${10 / 16}rem;
      line-height: ${12 / 16}rem;
      margin-bottom: ${20 / 16}rem;
      color: ${color};
      font-weight: ${fontWeight};
    }

    ${mediaQuery(FYC_MOBILE_WIDTH)} {
      max-width: ${533 / 16}rem;
    }

    ${mediaQuery(FYC_TABLET_WIDTH)} {
      max-width: initial;
    }
    display: flex;
    flex-wrap: wrap;
    gap: ${4 / 16}rem;
    label: tosCss;

    #ot-sdk-btn.ot-sdk-show-settings {
      color: ${color};
      border: none;
      padding: 0;
      font-size: ${10 / 16}rem;
      font-weight: ${fontWeight};
      line-height: ${12 / 16}rem;
      transition: none;
      :hover {
        color: rgba(255, 255, 255, 0.67);
        background: none;
      }
    }
  `;
};

export const logoPartCss = css`
  .fyc-logo-link {
    display: flex;
    justify-content: start;
  }

  ${mediaQuery(FYC_MOBILE_WIDTH)} {
    .fyc-logo-link {
      justify-content: end;
    }
  }
`;

export const termsLabelCss = (version: FycVersionType): SerializedStyles => {
  const customCssConfig = getVersionedComponentConfig<
    FooterComponentConfigInterface['termsLabelCss']
  >({
    version,
    component: FycConfigsEnum.FOOTER_CONFIG,
    selector: 'tosCss',
  });

  let color = customCssConfig?.color;
  let fontWeight = customCssConfig?.fontWeight;

  if (version === SEARCHLIGHT || version === SEARCHLIGHT_AWARDS) {
    color = 'rgba(41, 41, 41, 1)';
    fontWeight = 600;
  }

  return css({
    cursor: 'pointer',
    color,
    fontWeight,
  });
};
