/** @jsx jsx */
import { EntitlementTypeEnum } from 'models/enums';
import type { Dispatch, SetStateAction } from 'react';
import { Fragment } from 'react';
import { jsx } from '@emotion/core';
import type { ProgressStateInterface } from 'store/slices/progress';
import NsAction from 'components/ns-component/ns-action';
import { ActionTypeEnum } from 'models/enums';
import { NDATemplateTypesEnum } from 'models/enums';
import Alert from 'components/alert';
import {
  buttonActionContainerCss,
  buttonContentCss,
  buttonTextCss,
  linkCss,
  pageActionContainerCss,
  playButtonCss,
  playIconCss,
} from './styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  entitlementSelected,
  fetchPage,
  selectedEntitlement,
} from 'store/slices/page';
import { usePlayer } from 'providers/player-provider';
import { tokenCheckNReload } from 'components/fyc-component/access-code/helper';
import { FYCPage } from 'pages/fyc-page/helpers';
import { useLocation } from 'react-router-dom';
import { tokenSelector } from 'store/slices/user';

export interface VideoActionsNdaInterface {
  isNdaOpen?: boolean;
  setIsNdaOpen?: Dispatch<SetStateAction<boolean>>;
  text?: string;
}

interface PropsInterface {
  actionContainerClassName?: string;
  component: any;
  icon?: boolean;
  ndaProps?: VideoActionsNdaInterface;
  progressData?: ProgressStateInterface;
  styles?: any;
}

const VideoActions: React.FC<PropsInterface> = ({
  component,
  progressData,
  styles,
  actionContainerClassName,
  ndaProps,
  icon,
}): React.ReactElement => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const chosenEntitlement = useSelector(selectedEntitlement);
  const { openPlayer, setIsFyc, setFycAccessToken } = usePlayer();

  const { accessToken } = useSelector(tokenSelector);

  const modifyComponent = (component) => {
    if (
      component.type === EntitlementTypeEnum.SERIES.toUpperCase() &&
      !component.seriesTitle
    ) {
      return {
        ...component,
        seriesTitle: component.title,
      };
    } else {
      return component;
    }
  };

  return component.actions ?
      component.actions.map((action) => {
        let containerCss;
        // PLAY action comes before PAGE in CMS structure, but should come after PAGE in design.
        if (action.type === ActionTypeEnum.PLAY) {
          containerCss = buttonActionContainerCss;

          if (component.docType === EntitlementTypeEnum.SERIES) {
            return;
          }
        }

        if (action.type === ActionTypeEnum.PAGE) {
          containerCss = pageActionContainerCss;

          if (component.docType === EntitlementTypeEnum.SERIES) {
            containerCss = buttonActionContainerCss;
          }
        }

        if (!containerCss) {
          return;
        }

        const onActionClick = {
          play: () => {
            // check token for FYC
            if (!!FYCPage(pathname)) {
              tokenCheckNReload(component, setIsFyc, setFycAccessToken);
            } else {
              setIsFyc(false);
            }

            if (
              component.entitlement?.ndaTemplate?.toLowerCase() ===
                NDATemplateTypesEnum.CLICK_THROUGH &&
              /* @ts-ignore TODO: TS18048: progressData is possibly undefined. */
              !progressData[component.bookmark?.docId]?.progress
            ) {
              // save entitlement user wants to view to global store
              dispatch(entitlementSelected(component));
              /* @ts-ignore TODO: TS2722: Cannot invoke an object which is possibly undefined. */
              ndaProps?.setIsNdaOpen(true);
            } else {
              // check entitlement type
              const currentComponent = modifyComponent(component);

              if (!!FYCPage(pathname)) {
                openPlayer(action.target, currentComponent);
                return;
              }

              // refetch current url to get the latest bookmark
              const restart = currentComponent.bookmark?.progress >= 0.96;
              const callback = () =>
                openPlayer(action.target, currentComponent, { restart });
              dispatch(fetchPage({ slug: pathname, accessToken, callback }));
            }
          },
          restart: () => {
            // check token for FYC
            if (!!FYCPage(pathname)) {
              tokenCheckNReload(component, setIsFyc, setFycAccessToken);
            } else {
              setIsFyc(false);
            }

            // check entitlement type
            const currentComponent = modifyComponent(component);
            openPlayer(action.target, currentComponent, { restart: true });
          },
        };

        return (
          <Fragment key={action.id}>
            {ndaProps?.isNdaOpen && action.type === ActionTypeEnum.PLAY && (
              <Alert
                isOpen={ndaProps?.isNdaOpen}
                onClick={() => {
                  // Grab the entitlement user wants to view from global store
                  const chosenEntitlementAction =
                    /* @ts-ignore TODO: TS18049: chosenEntitlement is possibly null or undefined. */
                    /* @ts-ignore TODO: TS18048: chosenEntitlement.actions is possibly undefined. */
                    chosenEntitlement.actions.filter(
                      (action) => action.type === 'PLAY',
                    );
                  /* @ts-ignore TODO: TS2722: Cannot invoke an object which is possibly undefined. */
                  ndaProps?.setIsNdaOpen(false);
                  openPlayer(
                    chosenEntitlementAction[0].target,
                    chosenEntitlement,
                  );
                }}
                infoText={ndaProps?.text}
                primaryButtonText="Accept"
                secondaryButtonText="Cancel"
                /* @ts-ignore TODO: TS2722: Cannot invoke an object which is possibly undefined. */
                secondaryAction={() => ndaProps?.setIsNdaOpen(false)}
              />
            )}
            <NsAction
              {...action}
              onClick={action.type === ActionTypeEnum.PLAY && onActionClick}
              buttonCss={playButtonCss(styles)}
              buttonIconCss={playIconCss(styles)}
              buttonTextCss={buttonTextCss(styles)}
              buttonContentCss={buttonContentCss(styles)}
              containerStyles={containerCss(styles)}
              containerClassName={actionContainerClassName}
              linkStyles={linkCss(styles)}
              icon={icon}
              isLinkStyle={false}
              componentType={component.type}
              /*
              MDP component doesn't have an entitlement object,
              pass this component's entitlement object to location's optional state
              to access it in the MDP page
              */
              meta={{
                entitlement: component.entitlement,
                /* @ts-ignore TODO: TS18048: progressData is possibly undefined. */
                bookmark: progressData[component.bookmark?.docId],
              }}
            />
          </Fragment>
        );
      })
    : null;
};

export default VideoActions;
