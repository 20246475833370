import * as Cookies from 'es-cookie';

const attributes: Cookies.CookieAttributes = {
  secure: location.protocol === 'https:',
  sameSite: 'lax',
};

export const getCookie = (name: string): string | undefined => {
  return Cookies.get(name);
};

export const setCookie = (
  name: string,
  value: string,
  expiry?: number,
): void => {
  if (expiry === 0) {
    Cookies.remove(name);
  } else {
    Cookies.set(name, value, attributes);
  }
};
