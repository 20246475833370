const config = window.__CONFIG__;

// client id
export const CLIENT_ID = config?.clientId;

// service urls
export const SENTRY_URL = config?.sentryUrl;
export const STRINGS_URL = config?.stringsUrl;
export const GATEWAY_URL = config?.gatewayUrl;
export const LICENSE_PLATE_URL = config?.licensePlateUrl;
export const CONCURRENCY_URL = config?.concurrencyUrl;
export const NDA_URL = config?.ndaUrl;
export const SSO_URL = config?.ssoUrl;

export const PLAY = (playId: string): string => `${GATEWAY_URL}/play/${playId}`;
export const PAGES = (slug: string): string => `${GATEWAY_URL}/pages${slug}`;

export const FORGOT_PASSWORD = `${SENTRY_URL}/api/account/email/send-reset`;
export const RESET_PASSWORD = `${SENTRY_URL}/api/account/email/reset-password`;
export const WELCOME_ENROLLMENT = `${SENTRY_URL}/email/enroll`;
export const OAUTH_AUTHORIZE = `${SENTRY_URL}/oauth/authorize`;
export const OAUTH_TOKEN = `${SENTRY_URL}/oauth/token`;
export const ACCOUNT_ACTIVATE = `${LICENSE_PLATE_URL}/grant`;
export const NDA_SEARCH_BY_TEMPLATEIDS = `${NDA_URL}/nda/search`;
export const NDA_VERIFY_CREATE = `${NDA_URL}/nda`;
export const SSO_AUTHORIZE = `${SSO_URL}/sso/jwt`;
export const SEARCH_RESULTS = `${GATEWAY_URL}/pages/search`;
export const FYC_HELPER = `${GATEWAY_URL}/pages/settings/fyc-helper`;

// concurrency
export const SINGLE_SESSION = (sessionId: string): string =>
  `${CONCURRENCY_URL}/sessions/${sessionId}`;

// proxied calls
export const ACCOUNT_TOKEN = '/auth/token';
export const ACCOUNT_DETAILS = '/account/details';
export const ACCOUNT_STATUS = '/account/whatsup';
export const ACCOUNT_RESET_2FA = `/account/reset2fa`;
export const FYC_PAGE = `/account/fyc`;
export const FYC_PLAY = `/account/fyc/play`;
export const SWOOGO_EVENTS = `/swoogo/events`;
