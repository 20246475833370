import type {
  NSEntitlementDetailsComponentInterface,
  NSFycMovieMarqueeComponentInterface,
  NSFycSeriesComponentInterface,
  NSRelatedContentComponentInterface,
} from 'models/cms';
import React, { useEffect, useState } from 'react';
import { ComponentTypeEnum } from 'models/enums';
import FycDetailsBanner from 'components/fyc-component/fyc-details-banner';
import FycDetailsPart from 'components/fyc-component/fyc-details-part';
import DetailsBannerMobile from 'components/fyc-component/details-banner-mobile';
import { TNCModal } from 'components/fyc-component/tnc-modal';
import {
  FYC_CODE_VALID,
  SHOW_FYC_T_AND_C,
} from 'components/fyc-component/access-code/helper';
import { saveFycCodes } from 'components/fyc-component/access-code/helper';
import { useQuery } from 'utils/hooks';
import { useSelector } from 'react-redux';
import { fycDataSelector } from 'store/slices/fyc';
import { ActionTypeEnum } from 'models/enums';
import { usePlayer } from 'providers/player-provider';
import { PlayerEmitterEventEnum } from 'models/interfaces/player-provider';
import { useHistory } from 'react-router-dom';
import { removeQueryParam } from 'providers/sso-provider';
import { getCurrentFycVersion } from 'pages/fyc-page/helpers';

interface PropsInterface {
  hasPlain: boolean;
  pageData: any;
}

export const AUTOPLAY = 'autoplay';

const DetailsPage: React.FC<PropsInterface> = ({ pageData, hasPlain }) => {
  const MDP_MOBILE_WIDTH = 600;
  const { innerWidth: width } = window;

  const [isMobile, setIsMobile] = useState(width < MDP_MOBILE_WIDTH);
  const [isTNCOpen, setIsTNCOpen] = useState(false);
  const [fycCodeDetails, setFycCodeDetails] = useState<any>(null);

  const autoPlayId = useQuery().get(AUTOPLAY);
  const { components } = useSelector(fycDataSelector);
  const { openPlayer } = usePlayer();
  const history = useHistory();
  const { pathname } = history.location;

  // handle auto play for related content
  useEffect(() => {
    if (autoPlayId && components && components.length !== 0) {
      const relatedContent =
        (
          components?.find(
            (c) => c.type === ComponentTypeEnum.FYC_RELATED_CONTENT,
          ) as NSRelatedContentComponentInterface | undefined
        )?.items || [];

      const item = relatedContent.find(
        (c) => c?.entitlement?.playId === autoPlayId,
      );
      const action = item?.actions?.find((i) => i.type === ActionTypeEnum.PLAY);

      if (action && action.target) {
        openPlayer(action.target, item, {
          isFycPublic: true,
        });
      }
    }
  }, [autoPlayId, components, openPlayer]);

  const onPlayerClose = () => {
    history.push(
      pathname + removeQueryParam(history.location.search, AUTOPLAY),
    );
  };

  useEffect(() => {
    window.addEventListener(PlayerEmitterEventEnum.PLAYER_CLOSE, onPlayerClose);

    return () =>
      window.removeEventListener(
        PlayerEmitterEventEnum.PLAYER_CLOSE,
        onPlayerClose,
      );
  }, []);

  const onResize = () => {
    const { innerWidth: currentWidth } = window;

    if (currentWidth < MDP_MOBILE_WIDTH) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  const onShowTNC = (e) => {
    setIsTNCOpen(true);
    setFycCodeDetails(e.detail);
  };

  const handleAcceptTNC = () => {
    if (!fycCodeDetails) {
      return;
    }

    saveFycCodes(
      fycCodeDetails.access_token,
      fycCodeDetails.playIds,
      fycCodeDetails.code,
    );

    setFycCodeDetails(null);
    setIsTNCOpen(false);

    window.dispatchEvent(new Event(FYC_CODE_VALID));
  };

  useEffect(() => {
    window.addEventListener('resize', onResize);
    window.addEventListener(SHOW_FYC_T_AND_C, onShowTNC);

    return () => {
      window.removeEventListener('resize', onResize);
      window.removeEventListener(SHOW_FYC_T_AND_C, onShowTNC);
    };
  }, []);

  const detailsData = pageData?.components?.find(
    (c) => c.type === ComponentTypeEnum.ENTITLEMENT_DETAILS,
  ) as NSEntitlementDetailsComponentInterface | undefined;

  const relatedContentData = pageData?.components?.find(
    (c) => c.type === ComponentTypeEnum.FYC_RELATED_CONTENT,
  ) as NSRelatedContentComponentInterface | undefined;

  let viewData:
    | NSFycMovieMarqueeComponentInterface
    | NSFycSeriesComponentInterface
    | undefined;

  const movieData = pageData?.components?.find(
    (c) => c.type === ComponentTypeEnum.FYC_MOVIE_MARQUEE,
  ) as NSFycMovieMarqueeComponentInterface | undefined;

  const seriesData = pageData?.components?.find(
    (c) => c.type === ComponentTypeEnum.FYC_SERIES,
  ) as NSFycSeriesComponentInterface | undefined;

  if (movieData) {
    viewData = movieData;
  } else if (seriesData) {
    viewData = seriesData;
  }

  if (!viewData) {
    return null;
  }

  return (
    <div>
      <TNCModal
        isOpen={isTNCOpen}
        setIsOpen={setIsTNCOpen}
        handleAccept={handleAcceptTNC}
        fycVersion={getCurrentFycVersion()}
      />
      {isMobile ?
        <DetailsBannerMobile
          hasPlain={hasPlain}
          data={viewData}
        />
      : <FycDetailsBanner
          hasPlain={hasPlain}
          data={viewData}
        />
      }

      <FycDetailsPart
        data={viewData}
        relatedContentData={relatedContentData}
        details={detailsData}
      />
    </div>
  );
};

export default DetailsPage;
