import type { PlayerAPI } from 'bitmovin-player';
import { ViewMode } from 'bitmovin-player';
import Bowser from 'bowser';
import { PlayerEventsEnum } from 'models/enums';
import { STRING_KEYS } from 'providers/strings-provider';
import type React from 'react';

export const disablePictureInPicture = ({
  playerRef,
}: {
  playerRef: React.RefObject<PlayerAPI>;
}): void => {
  /* @ts-ignore TODO: TS18047: playerRef.current is possibly null. */
  const el = playerRef.current.getVideoElement();
  el.setAttribute('disablepictureinpicture', '');
  // el.disablepictureinpicture = true;
};

export const adjustHeight = ({ containerRef, fullViewportView }): void => {
  if (containerRef.current && !fullViewportView) {
    const { paddingBottom } = window.getComputedStyle(
      containerRef.current,
      ':before',
    );

    window.dispatchEvent(new Event(PlayerEventsEnum.HEIGHT_RESIZED));
    containerRef.current.style.height = paddingBottom;
  }
};

// Prevent fullscreen when using iOS mobile and tablet devices
export const handleEnterFullscreen = ({
  isFullscreen,
  playerRef,
  getStringByKey,
  setError,
}): void => {
  const browserAgent = Bowser.parse(window.navigator.userAgent);
  if (
    isFullscreen &&
    browserAgent.platform.type !== 'desktop' &&
    browserAgent.os.name === 'iOS' &&
    playerRef.current
  ) {
    playerRef.current.pause();
    playerRef.current.setViewMode(ViewMode.Inline);

    // wait a moment and let the player exit fullscreen before displaying alert
    setTimeout(() => {
      setError({
        isOpen: true,
        message: getStringByKey(STRING_KEYS.MOBILE_FULLSCREEN_UNAVAILABLE),
        title: 'Notice',
      });
    }, 100);
  }
};

export const handleSafariPiP = ({ playerRef, setError }) => {
  const video = playerRef.current?.getVideoElement();
  if (
    video?.webkitSupportsPresentationMode &&
    typeof video?.webkitSetPresentationMode === 'function'
  ) {
    video.addEventListener('enterpictureinpicture', function () {
      video.webkitSetPresentationMode('inline');
      playerRef.current.pause();
      setError({
        message: 'This video does not support Picture-in-Picture.',
        isOpen: true,
        title: 'Notice',
      });
    });
  }
};

export const addTestAttributes = ({ containerRef, playId }) => {
  // play button in the controls
  const playButton = {
    node: containerRef.current?.querySelector('.bmpui-ui-playbacktogglebutton'),
    name: 'player-play-button',
  };

  // play button in the center of the video when paused
  const hugePlayButton = {
    node: containerRef.current?.querySelector(
      '.bmpui-ui-hugeplaybacktogglebutton',
    ),
    name: 'player-huge-play-button',
  };

  [playButton, hugePlayButton].forEach((el) => {
    el.node?.setAttribute('data-testid', el.name);

    if (el.name === 'player-play-button') {
      el.node?.setAttribute('data-titleid', playId);
    }

    if (el.name === 'player-huge-play-button') {
      el.node?.setAttribute('data-titleid', `H-${playId}`);
    }
  });
};

export const hidePlayerControls = (isHidden = true, containerRef) => {
  const controlBar = containerRef.current?.querySelector(
    '.bmpui-ui-controlbar',
  );

  if (isHidden) {
    controlBar?.classList.add('hide');
  } else {
    controlBar?.classList.remove('hide');
  }
};

export const addCastingButtonHighlight = (containerRef) => {
  const castButton = containerRef.current?.querySelector(
    '.bmpui-ui-casttogglebutton',
  );

  if (castButton) {
    castButton.classList.add('cast-highlight');
  }
};

export const removeCastingButtonHighlight = (containerRef) => {
  const castButton = containerRef.current?.querySelector(
    '.bmpui-ui-casttogglebutton',
  );

  if (castButton) {
    castButton.classList.remove('cast-highlight');
  }
};

export const calculateRotationAngle = (
  width: number,
  height: number,
): number => {
  if (!width && !height) {
    return 0;
  }
  return (Math.atan(height / width) * 180) / Math.PI;
};

export const parseComputedStyleWidthAndHeight = (
  width: string,
  height: string,
): number[] => {
  if (!width && !height) {
    return [0, 0];
  }

  const parsedWidth = Number(width.split('px')[0]);
  const parsedHeight = Number(height.split('px')[0]);

  return [parsedWidth, parsedHeight];
};

export function getSessionIdParam(url: string): string {
  if (!url) {
    /* @ts-ignore TODO: TS2322: Type null is not assignable to type string. */
    return null;
  }

  const params = new URL(url).searchParams;
  const SESSION_ID = 'playbackSessionId';

  if (!params.has(SESSION_ID)) {
    /* @ts-ignore TODO: TS2322: Type null is not assignable to type string. */
    return null;
  }

  /* @ts-ignore TODO: TS2531: Object is possibly null. */
  return params.get(SESSION_ID).toString();
}
