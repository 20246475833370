/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import type { LinkObjectInterface } from 'components/footer';
import React, { useEffect, useState } from 'react';
import {
  wrapperCss,
  logoCss,
  copyRightCss,
  contactCss,
  tosCss,
  logoPartCss,
  footerCss,
  termsLabelCss,
} from './styles';
import Logo from '../../logo';
import NsLink from 'components/ns-component/ns-link';
import { STRING_KEYS, useStringsContext } from 'providers/strings-provider';
import { getCurrentFycVersion } from 'pages/fyc-page/helpers';
import { TNCModal } from 'components/fyc-component/tnc-modal';
import { setCookie } from 'store/cookies';
import {
  DTS,
  SEARCHLIGHT,
  SEARCHLIGHT_AWARDS,
  useFycThemeContext,
} from 'providers/fyc-theme-provider';
import DTSLogo from 'public/images/dts-logo.svg';

// TODO: Refactor Debut FycFooter Logo to accept configuration

const FycFooter: React.FC = () => {
  const { getStringByKey } = useStringsContext();
  const [isTNCOpen, setIsTNCOpen] = useState(false);

  const version = useFycThemeContext()?.version;

  const FYC_FOOTER_LINKS =
    getStringByKey(`${STRING_KEYS.FYC_FOOTER_LINKS}_${version}`) ||
    getStringByKey(STRING_KEYS.FYC_FOOTER_LINKS);
  const FYC_FOOTER_TERMS_LABEL = getStringByKey(
    STRING_KEYS.FYC_FOOTER_TERMS_LABEL,
  );

  const generateTosPart = () => {
    setCookie('fyc_one_trust', 'empty');
    const results: JSX.Element[] = [];
    let links: LinkObjectInterface[];
    try {
      /* @ts-ignore TODO: TS2345: Argument of type string | undefined is not assignable to parameter of type string. */
      links = JSON.parse(FYC_FOOTER_LINKS) as LinkObjectInterface[];
    } catch (e) {
      return;
    }

    links.map((l, i) => {
      results.push(
        <a
          rel="noreferrer"
          target="_blank"
          href={l.url}
          id={l.id}
          className={l.class}
          key={i}
        >
          {l.value}
        </a>,
      );
      if (i < links.length - 1) {
        results.push(
          <span
            className="spans-group"
            key={`span-${i}`}
          >
            |
          </span>,
        );
      }
    });

    return results;
  };

  const hiddenVerticalLine = () => {
    const spansGroup =
      (document.getElementsByClassName(
        'spans-group',
      ) as HTMLCollectionOf<HTMLElement>) || [];
    for (const span of spansGroup) {
      if (!span.style) {
        return;
      }

      span.style.display = 'inline-block';
      const preEle = span.previousElementSibling as HTMLElement | null;
      const nextEle = span.nextElementSibling as HTMLElement | null;
      if (
        span.offsetTop !== nextEle?.offsetTop ||
        span.offsetTop !== preEle?.offsetTop
      ) {
        span.style.display = 'none';
      }
    }
  };

  useEffect(() => {
    hiddenVerticalLine();
    window.addEventListener('resize', () => {
      hiddenVerticalLine();
    });

    return () => {
      window.removeEventListener('resize', () => {
        hiddenVerticalLine();
      });
    };
  }, []);

  return (
    <footer css={footerCss}>
      {version === DTS && (
        <div
          css={css`
            margin-bottom: ${14 / 16}rem;
          `}
        >
          <NsLink to={`/fyc/${getCurrentFycVersion()}`}>
            <img src={DTSLogo} />
          </NsLink>
        </div>
      )}
      <div css={wrapperCss()}>
        <div className="linksPart">
          <TNCModal
            isOpen={isTNCOpen}
            setIsOpen={setIsTNCOpen}
            handleAccept={() => {
              // do nothing;
            }}
            fycVersion={getCurrentFycVersion()}
            readOnly={true}
          />

          <div
            id="fycFooterWrapper"
            css={contactCss(version)}
          >
            <NsLink
              className="contact-us"
              to={`/fyc/${getCurrentFycVersion()}/contact-us`}
            >
              CONTACT US
            </NsLink>
            <a
              css={termsLabelCss(version)}
              onClick={() => {
                setIsTNCOpen(true);
              }}
            >
              {FYC_FOOTER_TERMS_LABEL}
            </a>
          </div>
          <div css={tosCss(version)}>{generateTosPart()}</div>
        </div>
        <div
          className="logoPart"
          css={logoPartCss}
        >
          <NsLink
            className="fyc-logo-link"
            to={`/fyc/${getCurrentFycVersion()}`}
          >
            <Logo
              white={version !== SEARCHLIGHT && version !== SEARCHLIGHT_AWARDS}
              black={version === SEARCHLIGHT || version === SEARCHLIGHT_AWARDS}
              logoStyles={logoCss}
            />
          </NsLink>

          <div css={copyRightCss(version)}>
            Disney &copy; All Rights Reserved
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FycFooter;
