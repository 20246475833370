import type { SerializedStyles } from '@emotion/core';
import { css } from '@emotion/core';
import { mediaQuery } from 'utils/styles';

export const wrapperCss = css`
  width: 100%;
  padding: 0 15px;
  margin-bottom: 30px;
  font-family: inspire;

  ${mediaQuery(693)} {
    width: 50%;
  }

  ${mediaQuery(1652)} {
    width: 33%;
  }
`;

export const contentCss = (rsvpCardStyles): SerializedStyles => css`
  background: ${rsvpCardStyles.cardBg};
  color: ${rsvpCardStyles.fontColor};
  border-radius: 4px;
  padding: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const titleCss = css`
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: 20px;
`;

export const descCss = css`
  font-size: 14px;
  margin-bottom: 30px;

  p {
    margin: 0;
  }
`;

export const registerBtn = (rsvpCardStyles): SerializedStyles => css`
  border: ${rsvpCardStyles.btnBorder};
  border-radius: 4px;
  padding: 12px 24px;
  text-align: center;
  background: ${rsvpCardStyles.btnBg};
  cursor: pointer;
  font-weight: 500;
  text-decoration: none;

  :hover {
    border-color: ${rsvpCardStyles.btnHoverBorderColor};
  }
`;

export const infoCss = css`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;

  .infoDetails {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  img {
    position: relative;
    top: -3px;
  }
`;
