/** @jsx jsx */
import type {
  NSMovieMarqueeComponentInterface,
  NSSeriesComponentInterface,
} from 'models/cms';
import React from 'react';
import { css, jsx } from '@emotion/core';
import type { NDAPropsInterface } from '../details-banner/large-banner';
import {
  textWrapperCss,
  containerCss,
  imgWrapperCss,
  unlockButtonCss,
} from './styles';
import NsImage from 'components/ns-component/ns-image';
import lockIcon from 'public/images/Unlock.svg';
import lockIconClosed from 'public/images/lock-closed.svg';

interface PropsInterface {
  component: NSSeriesComponentInterface | NSMovieMarqueeComponentInterface;
  hasPlain: boolean;
  ndaProps: NDAPropsInterface;
}

const nsImageContainerCss = css`
  border-radius: 6px;
`;

export const UnlockButton: React.FC<{
  closedIcon?: boolean;
  onclick: (e?) => any;
}> = ({ onclick, closedIcon = false }) => {
  return (
    <button
      className="button-unlock"
      css={unlockButtonCss}
      onClick={onclick}
    >
      <img
        className="lock-icon"
        src={closedIcon ? lockIconClosed : lockIcon}
      />
      <span>Unlock</span>
    </button>
  );
};

const DetailsPageLockView: React.FC<PropsInterface> = ({
  component,
  hasPlain,
  ndaProps,
}) => {
  const title = `Locked Content`;
  const content = `To view all exclusive content, please click on the button below to
  sign and agree to our terms and conditions.`;

  const renderImage = () => {
    if (hasPlain) {
      if (!component.titleTreatmentImage) {
        return component.title;
      }

      return (
        <NsImage
          alt={component.titleTreatmentImage.alt}
          aspectRatio={'16/9'}
          url={component.titleTreatmentImage.url}
          width={500}
          styles={{
            nsImageContainerCss,
          }}
        />
      );
    }

    if (!component.keyArtImage) {
      return null;
    }

    return (
      <NsImage
        alt={component.keyArtImage.alt}
        aspectRatio={'16/9'}
        url={component.keyArtImage.url}
        width={500}
        isBackground
        hasGradientOverlay={false}
        styles={{
          nsImageContainerCss,
        }}
      />
    );
  };

  const onClick = () => {
    // @ts-ignore TODO: TS2339: Property 'entitlement' does not exist on type
    if (!component.entitlement?.ndaTemplate || !ndaProps.handleAction) {
      return;
    }

    // @ts-ignore TODO: TS2339: Property 'entitlement' does not exist on type
    ndaProps.handleAction(component.entitlement.ndaTemplate);
  };

  return (
    <div css={containerCss}>
      <div
        className="textWrapper"
        css={textWrapperCss}
      >
        <div className="title">{title}</div>
        <div className="content">{content}</div>
      </div>
      <div
        className="imgWrapper"
        css={imgWrapperCss}
      >
        {renderImage()}
        <UnlockButton onclick={onClick} />
        <div className="blur"></div>
      </div>
      <div className="entitlement-title">{component.title}</div>
    </div>
  );
};

export default DetailsPageLockView;
