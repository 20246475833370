import { createSlice, createSelector } from '@reduxjs/toolkit';
import type { ResponseError } from 'superagent';
import request from 'superagent';

import type { RootStateType } from '../root-reducer';
import {
  ACCOUNT_TOKEN,
  RESET_PASSWORD,
  CLIENT_ID,
  SSO_AUTHORIZE,
} from 'api/endpoints';
import type { AppThunkType } from 'store';
import { LoginFailureCodesEnum } from 'models/enums';
import jwtDecode from 'jwt-decode';
import type { TokenInfoInterface } from 'models/token-info-interface';
import { deleteAllDismissedAnnouncement } from 'components/ns-component/ns-announcement-component/announcement/helpers';

interface UserStateInterface {
  accessToken?: string | null;
  checkTosStatus?: boolean;
  error?: (ResponseError & { code: LoginFailureCodesEnum }) | null;
  isKidsLogin?: boolean;
  isPwResetSuccessful?: boolean;
  isSSOLoading?: boolean;
  refreshToken?: string | null;
}

interface TokenInterface {
  access_token?: string | null;
  expires_in?: number | null;
  refresh_token?: string | null;
}

export const initialState: UserStateInterface = {
  accessToken: null,
  refreshToken: null,
  isKidsLogin: false,
  error: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    resetSucceeded(state) {
      state.accessToken = null;
      state.refreshToken = null;
      state.isPwResetSuccessful = true;
      state.error = null;
    },
    resetFailed(state, action) {
      state.accessToken = null;
      state.refreshToken = null;
      state.isPwResetSuccessful = false;
      state.error = action.payload;
    },
    loginSucceeded(state, action) {
      // delete all dismissed announcements when login succeeds
      deleteAllDismissedAnnouncement();

      if (!action.payload.refresh) {
        state.refreshToken = action.payload.refresh_token;
      }

      state.accessToken = action.payload.access_token;
      state.isKidsLogin = action.payload.isKidsLogin;
      state.error = null;
      state.isSSOLoading = false;
      state.checkTosStatus = true;
    },
    loginFailed(state, action) {
      state.accessToken = initialState.accessToken;
      state.refreshToken = initialState.refreshToken;
      state.isKidsLogin = initialState.isKidsLogin;
      state.error = action.payload;
      state.isSSOLoading = false;
    },
    logout(state) {
      // delete all dismissed announcements when logout
      deleteAllDismissedAnnouncement();

      state.accessToken = initialState.accessToken;
      state.refreshToken = initialState.refreshToken;
      state.isKidsLogin = initialState.isKidsLogin;
      state.error = initialState.error;
    },
    checkingLoginWithSSO(state, action) {
      const { checking } = action.payload;
      state.isSSOLoading = checking;
    },
    turnOffCheckTosStatus(state) {
      state.checkTosStatus = false;
    },
  },
});

export const {
  resetSucceeded,
  resetFailed,
  loginSucceeded,
  loginFailed,
  logout,
  checkingLoginWithSSO,
  turnOffCheckTosStatus,
} = userSlice.actions;

export const loginWithPassword =
  (username: string, password: string): AppThunkType =>
  async (dispatch) => {
    try {
      const res = await request
        .post(ACCOUNT_TOKEN)
        .send({ grant_type: 'password', username, password });
      dispatch(loginSucceeded(res.body));
    } catch (error) {
      dispatch(loginFailed(error));
    }
  };

export const loginWithTFA =
  (context: string, challenge: string): AppThunkType =>
  async (dispatch) => {
    try {
      const res = await request
        .post(ACCOUNT_TOKEN)
        .send({ grant_type: 'two_factor', context, challenge });
      dispatch(loginSucceeded(res.body));
    } catch (error) {
      dispatch(loginFailed(error));
    }
  };

interface LoginWithAccessCodeInterface {
  code: string;
  isKidsLogin?: boolean;
}

export const logoutWithError =
  ({
    message,
    code,
  }: {
    code: LoginFailureCodesEnum;
    message?: string;
  }): AppThunkType =>
  async (dispatch) => {
    dispatch(
      loginFailed({
        message,
        code,
      }),
    );
  };

export const loginWithAccessCode =
  ({ code, isKidsLogin = false }: LoginWithAccessCodeInterface): AppThunkType =>
  async (dispatch) => {
    try {
      const res = await request
        .post(ACCOUNT_TOKEN)
        .send({ grant_type: 'code', code });

      const tokens: TokenInterface = {
        access_token: res.body.access_token || null,
        refresh_token: res.body.refresh_token || null,
      };

      dispatch(loginSucceeded({ ...tokens, isKidsLogin }));
    } catch (error) {
      dispatch(loginFailed(error));
    }
  };

export const loginWithCodeExchange =
  (code: string, redirect_uri: string): AppThunkType =>
  async (dispatch) => {
    try {
      const res = await request
        .post(ACCOUNT_TOKEN)
        .send({ grant_type: 'authorization_code', code, redirect_uri });
      dispatch(loginSucceeded(res.body));
    } catch (error) {
      dispatch(loginFailed(error));
    }
  };

export const loginWithSSO =
  (token: string): AppThunkType =>
  async (dispatch) => {
    try {
      dispatch(checkingLoginWithSSO({ checking: true }));
      const res = await request
        .post(SSO_AUTHORIZE)
        .set('Accept', 'json')
        .set('Authorization', `bearer ${token}`);
      dispatch(loginSucceeded(res.body));
    } catch (error) {
      const { errorCode } = error.response?.body;
      if (
        errorCode.split(':')[1].includes(LoginFailureCodesEnum.SSO_UNAUTHORIZED)
      ) {
        dispatch(loginFailed({ code: LoginFailureCodesEnum.SSO_UNAUTHORIZED }));
      } else {
        dispatch(loginFailed(error));
      }
    }
  };

export const refreshTokens = (): AppThunkType => async (dispatch, getState) => {
  try {
    const state = getState();
    const res = await request.post(ACCOUNT_TOKEN).send({
      grant_type: 'refresh_token',
      refresh_token: state.user.refreshToken,
    });
    dispatch(loginSucceeded({ ...res.body, refresh: true }));
  } catch (error) {
    dispatch(loginFailed(error));
  }
};

export const resetPassword =
  (password: string, token: string): AppThunkType =>
  async (dispatch) => {
    try {
      await request
        .post(RESET_PASSWORD)
        .accept('json')
        .send({ token, password, clientId: CLIENT_ID });

      dispatch(resetSucceeded());
    } catch (error) {
      dispatch(resetFailed(error));
    }
  };

// selectors
const userSelector = (state: RootStateType) => state.user;

export const authenticationSelector = createSelector(
  userSelector,
  (user: UserStateInterface) => {
    const decodedToken =
      user.accessToken && jwtDecode<TokenInfoInterface>(user.accessToken);
    return {
      isAuthenticated: !!(decodedToken && decodedToken.exp * 1000 > Date.now()),
      userId: decodedToken?.sub as string | undefined,
    };
  },
);

export const tokenSelector = createSelector(
  userSelector,
  (user: UserStateInterface) => ({
    accessToken: user.accessToken,
    refreshToken: user.refreshToken,
  }),
);

export const passwordResetSelector = createSelector(
  userSelector,
  (user: UserStateInterface) => user.isPwResetSuccessful,
);

export const userErrorSelector = createSelector(
  userSelector,
  (user: UserStateInterface) => user.error,
);

export const userSSOLoadingSelector = createSelector(
  userSelector,
  (user: UserStateInterface) => user.isSSOLoading,
);

export const isKidsLoginSelector = createSelector(
  userSelector,
  (user: UserStateInterface) => user.isKidsLogin,
);

export const checkTosStatusSelector = createSelector(
  userSelector,
  (user: UserStateInterface) => user.checkTosStatus,
);

export default userSlice.reducer;
