import { css } from '@emotion/core';
import theme from 'styles/theme';

export default css`
  &.ui-mini {
    position: static;

    .video-player {
      position: fixed;
      z-index: ${theme.zIndex.modal};
    }
  }
`;
