/** @jsx jsx */
import { css } from '@emotion/core';
import theme from 'styles/theme';
import type { PlayerAPI } from 'bitmovin-player';
import { PlayerEvent, ViewMode } from 'bitmovin-player';
import type { UIInstanceManager } from 'bitmovin-player-ui';
import type { ToggleButtonConfig } from 'bitmovin-player-ui/dist/js/framework/components/togglebutton';
import { ToggleButton } from 'bitmovin-player-ui/dist/js/framework/components/togglebutton';
import { PLAYER_DIV_NAME } from '..';
import type { FullScreenModeButton } from './full-screen-mode-button';

// Below images are converted to Base64 in the CSS so they are not used
// but are still in the code base
//import PlayerTheaterMode from 'public/images/Player_Theater_Mode.svg';
//import NormalTheaterMode from 'public/images/Player_Normal_Mode.svg';

export interface TheaterModeButtonConfigInterface extends ToggleButtonConfig {
  fullScreenModeButton: FullScreenModeButton;
  offText: string;
  onText: string;
}

export const NORMAL_MODE_WIDTH = '62%';
export const THEATER_MODE_CLASS = 'theater-mode';
export const THEATER_BUTTON_NAME = 'ui-theatermodebutton';

/**
 * Styles to setup theater mode in the modal (which just adds/removes padding
 * from the modal to change player size)
 */
export const playerModalStylesWithTheaterMode = css`
  padding: 0 0;
  width: 100%;

  .${THEATER_MODE_CLASS} {
    padding: 0 0 !important;
    width: 100% !important;

    @media (min-aspect-ratio: ${theme.aspectRatios.lg}) {
      padding: 0 0 !important;
      width: 84% !important;
    }

    @media (min-aspect-ratio: ${theme.aspectRatios.xl}) {
      padding: 0 0 !important;
      width: ${NORMAL_MODE_WIDTH} !important;
    }
  }
`;

/**
 * Styles for the theater button on the player
 */
export const theaterModeButtonStyles = css`
  .bmpui-ui-theatermodebutton {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxOCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE1Ljc1IDAuMjVDMTYuOTkyNiAwLjI1IDE4IDEuMjU3MzYgMTggMi41VjExLjVDMTggMTIuNzQyNiAxNi45OTI2IDEzLjc1IDE1Ljc1IDEzLjc1SDIuMjVDMS4wMDczNiAxMy43NSAwIDEyLjc0MjYgMCAxMS41VjIuNUMwIDEuMjU3MzYgMS4wMDczNiAwLjI1IDIuMjUgMC4yNUgxNS43NVpNMTYuNSA0Ljc1SDEuNVYxMS41QzEuNSAxMS45MTQyIDEuODM1NzkgMTIuMjUgMi4yNSAxMi4yNUgxNS43NUMxNi4xNjQyIDEyLjI1IDE2LjUgMTEuOTE0MiAxNi41IDExLjVWNC43NVpNMTUuNzUgMS43NUgyLjI1QzEuODM1NzkgMS43NSAxLjUgMi4wODU3OSAxLjUgMi41VjQuNzVIMTYuNVYyLjVDMTYuNSAyLjA4NTc5IDE2LjE2NDIgMS43NSAxNS43NSAxLjc1WiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==);
    background-color: transparent;
    background-origin: content-box;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 1.5em;
    border: 0;
    box-sizing: content-box;
    cursor: pointer;
    font-size: 1em;
    height: 1.5em;
    min-width: 1.5em;
    padding: 0.25em;
  }

  .bmpui-${THEATER_BUTTON_NAME}.bmpui-on {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxNiAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE0IDBDMTUuMTA0NiAwIDE2IDAuODk1NDMxIDE2IDJWMTBDMTYgMTEuMTA0NiAxNS4xMDQ2IDEyIDE0IDEySDJDMC44OTU0MzEgMTIgMCAxMS4xMDQ2IDAgMTBWMkMwIDAuODk1NDMxIDAuODk1NDMxIDAgMiAwSDE0Wk0xNC42NjY3IDRIMS4zMzMzM1YxMEMxLjMzMzMzIDEwLjM2ODIgMS42MzE4MSAxMC42NjY3IDIgMTAuNjY2N0gxNEMxNC4zNjgyIDEwLjY2NjcgMTQuNjY2NyAxMC4zNjgyIDE0LjY2NjcgMTBWNFpNMTQgMS4zMzMzM0gyQzEuNjMxODEgMS4zMzMzMyAxLjMzMzMzIDEuNjMxODEgMS4zMzMzMyAyVjRIMTQuNjY2N1YyQzE0LjY2NjcgMS42MzE4MSAxNC4zNjgyIDEuMzMzMzMgMTQgMS4zMzMzM1oiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=);
    background-size: 0.9em;
  }
`;

export class TheaterModeButton extends ToggleButton<TheaterModeButtonConfigInterface> {
  constructor(config: TheaterModeButtonConfigInterface) {
    super(config);

    this.config = this.mergeConfig(
      config,
      {
        cssClass: THEATER_BUTTON_NAME,
        text: 'Full Window Mode',
        role: 'button',
        ariaLabel: 'Full Window Mode',
      } as TheaterModeButtonConfigInterface,
      this.config,
    );

    this.off();
  }

  configure(player: PlayerAPI, uimanager: UIInstanceManager): void {
    super.configure(player, uimanager);

    const config = this.getConfig();

    this.onClick.subscribe(() => {
      if (this.isOn()) {
        this.off();
      } else {
        this.on();
      }
    });

    this.onToggleOn.subscribe(() => {
      super.onToggleOn;
      switchToTheaterView(config.onText, player);
      this.config.fullScreenModeButton.off();
    });
    this.onToggleOff.subscribe(() => {
      super.onToggleOff;
      switchToNormalView(config.offText, player);
      this.config.fullScreenModeButton.off();
    });
  }
}

const switchToTheaterView = (text: string, player: PlayerAPI): void => {
  player.setViewMode(ViewMode.Inline);
  const playerDiv = document.querySelector(
    `.${PLAYER_DIV_NAME}`,
  ) as HTMLElement;
  if (playerDiv) {
    playerDiv.classList.add(THEATER_MODE_CLASS);
  }

  const buttonLabel = document.querySelector(
    `.bmpui-${THEATER_BUTTON_NAME} .bmpui-label`,
  ) as HTMLElement;
  if (buttonLabel) {
    buttonLabel.textContent = text;
  }

  window.dispatchEvent(new Event(PlayerEvent.PlayerResized));
  window.dispatchEvent(new Event('resize'));
};

const switchToNormalView = (text: string, player: PlayerAPI): void => {
  player.setViewMode(ViewMode.Inline);
  const playerDiv = document.querySelector(
    `.${PLAYER_DIV_NAME}`,
  ) as HTMLElement;
  if (playerDiv) {
    playerDiv.classList.remove(THEATER_MODE_CLASS);
  }

  const buttonLabel = document.querySelector(
    `.bmpui-${THEATER_BUTTON_NAME} .bmpui-label`,
  ) as HTMLElement;
  if (buttonLabel) {
    buttonLabel.textContent = text;
  }

  window.dispatchEvent(new Event(PlayerEvent.PlayerResized));
  window.dispatchEvent(new Event('resize'));
};
