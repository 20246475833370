import type { SerializedStyles } from '@emotion/utils';
import { css } from '@emotion/core';
import { FYC_MOBILE_WIDTH, FYC_TABLET_WIDTH } from 'pages/fyc-page/helpers';
import type { EpisodesInfoModalConfigInterface } from 'providers/fyc-theme-component-interfaces';
import { FycConfigsEnum } from 'providers/fyc-theme-component-interfaces';
import { getVersionedComponentConfig } from 'providers/versions/fyc-theme-provider-utils';
import { mediaQuery } from 'utils/styles';
import type { FycVersionType } from 'providers/fyc-theme-provider';
import {
  ABC,
  DBT,
  DISNEYPLUS,
  DTS,
  FX,
  HULU,
  NATGEO,
  SEARCHLIGHT,
  SEARCHLIGHT_AWARDS,
} from 'providers/fyc-theme-provider';

export const modalExtendCss = (version: FycVersionType): SerializedStyles => {
  const customCssConfig = getVersionedComponentConfig<
    EpisodesInfoModalConfigInterface['modalExtendCss']
  >({
    version,
    component: FycConfigsEnum.EPISODES_INFO_MODAL_CONFIG,
    selector: 'modalExtendCss',
  });

  let background = customCssConfig?.background;

  if (background) {
    return css({
      background,
      padding: '0 !important',
      label: 'modalExtendCss',
    });
  }

  if (version === DTS) {
    background = `background: rgba(0, 0, 0, 0.5);`;
  }

  if (version === HULU) {
    background = `background: rgba(30, 30, 30, 0.644641);`;
  }

  if (version === SEARCHLIGHT || version === SEARCHLIGHT_AWARDS) {
    background = `background: rgba(108, 108, 108, 0.5);`;
  }

  if (version === NATGEO) {
    background = `background: rgba(0, 0, 0, 0.50);`;
  }

  if (version === FX) {
    background = `background: rgba(30, 30, 30, 0.64);`;
  }

  return css`
    padding: 0 !important;
    label: modalExtendCss;
    ${background}
  `;
};

export const wrapperCss = css`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 -5%;
`;

export const contentWrapperCss = (
  hasPlain: boolean,
  version: FycVersionType,
): SerializedStyles => {
  const customCssConfig = getVersionedComponentConfig<
    EpisodesInfoModalConfigInterface['contentWrapperCss']
  >({
    version,
    component: FycConfigsEnum.EPISODES_INFO_MODAL_CONFIG,
    selector: 'contentWrapperCss',
  });

  let background = customCssConfig?.background ?? `#27283c`;
  let boxShadow = customCssConfig?.boxShadow ?? ``;
  let maskBg = customCssConfig?.maskBg ?? 'none';
  let mask1Bg = customCssConfig?.mask1Bg ?? 'none';
  let color = customCssConfig?.color ?? 'inherit';

  if (version === ABC) {
    background = `#040405`;
    boxShadow = `0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px -1px rgba(0, 0, 0, 0.2)`;
  }

  if (version === DBT || version === DISNEYPLUS) {
    background = `#080824`;
    boxShadow = `0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px -1px rgba(0, 0, 0, 0.2)`;
  }

  if (version === DTS) {
    background = `#080824`;
    boxShadow = `0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px -1px rgba(0, 0, 0, 0.2)`;
    maskBg = `rgba(255, 255, 255, 0.07)`;
  }

  if (version === HULU) {
    background = `#040405`;
    boxShadow = `0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px -1px rgba(0, 0, 0, 0.2)`;
    maskBg = `rgba(255, 255, 255, 0.07)`;
  }

  if (
    version === SEARCHLIGHT ||
    version === NATGEO ||
    version === SEARCHLIGHT_AWARDS
  ) {
    background = `rgba(22, 22, 22, 1)`;
    maskBg = `rgba(255, 255, 255, 0.07)`;
    color = `#FFF`;
  }

  if (version === FX) {
    background = `rgba(27, 27, 32, 1)`;
    maskBg = `#1B1B20`;
    mask1Bg = `rgba(255, 255, 255, 0.07)`;
  }

  return css`
    // default is the plain view

    color: ${color};
    display: inline-flex;
    flex-direction: column;
    width: ${(330 / 374) * 100}%;
    margin: 0 auto;
    background: ${background};
    box-shadow: ${boxShadow};
    border-radius: ${4 / 16}rem;
    label: contentWrapperCss;

    .mask {
      height: 100%;
      width: 100%;
      padding-top: ${17 / 16}rem;
      background: ${maskBg};
      position: relative;

      ${mediaQuery(FYC_MOBILE_WIDTH)} {
        padding-top: ${24 / 16}rem;
        ${!hasPlain && `padding-top: 18px;`}
        ${!hasPlain && `padding-bottom: 21px;`}
      }
    }

    .mask1 {
      position: absolute;
      pointer-events: none;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: ${mask1Bg};
    }

    ${mediaQuery(FYC_MOBILE_WIDTH)} {
      width: 506px;
    }

    ${mediaQuery(FYC_TABLET_WIDTH)} {
      ${!hasPlain && `width: 979px;`}
    }
  `;
};

export const titleCss = (
  hasPlain: boolean,
  version: FycVersionType,
): SerializedStyles => {
  const customCssConfig = getVersionedComponentConfig<
    EpisodesInfoModalConfigInterface['titleCss']
  >({
    version,
    component: FycConfigsEnum.EPISODES_INFO_MODAL_CONFIG,
    selector: 'titleCss',
  });

  let fontFamily = customCssConfig?.fontFamily ?? 'inherit';
  let fontWeight = customCssConfig?.fontWeight ?? 500;

  if (version === HULU) {
    fontFamily = `Graphik Medium`;
    fontWeight = 400;
  }

  if (version === NATGEO) {
    fontFamily = `Geograph Medium`;
    fontWeight = 400;
  }

  if (version === FX) {
    fontWeight = 400;
  }

  return css`
    margin: 0 1rem;
    display: flex;
    label: titleCss;

    ${mediaQuery(FYC_MOBILE_WIDTH)} {
      margin: 0 2rem;
      ${!hasPlain && `margin: 0 24px;`}
    }

    .title {
      font-size: ${18 / 16}rem;
      font-family: ${fontFamily};
      font-weight: ${fontWeight};
      flex: 1;
      display: flex;
      flex-wrap: wrap;
    }

    .close {
      width: ${18 / 16}rem;
      height: ${18 / 16}rem;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;

      img {
        width: 100%;
        cursor: pointer;
      }
    }
  `;
};

export const episodeTitleCss = (
  hasPlain: boolean,
  version: FycVersionType,
): SerializedStyles[] => {
  const customCssConfig = getVersionedComponentConfig<
    EpisodesInfoModalConfigInterface['episodeTitleCss']
  >({
    version,
    component: FycConfigsEnum.EPISODES_INFO_MODAL_CONFIG,
    selector: 'episodeTitleCss',
  });

  let fontSize = customCssConfig?.fontSize ?? '1rem';
  let fontFamily = customCssConfig?.fontFamily ?? 'inherit';
  let fontWeight = customCssConfig?.fontWeight ?? 500;
  let textTransform = customCssConfig?.textTransform ?? 'none';

  if (version === DTS) {
    fontSize = `${20 / 16}rem`;
  }

  if (version === HULU) {
    fontFamily = `Graphik Medium`;
    fontWeight = 400;
    fontSize = `${20 / 16}rem`;
  }

  if (version === SEARCHLIGHT || version === SEARCHLIGHT_AWARDS) {
    textTransform = 'uppercase';
  }

  if (version === NATGEO) {
    textTransform = 'uppercase';
    fontWeight = 400;
    fontSize = `${20 / 16}rem`;
    fontFamily = `Geograph Medium`;
  }

  if (version === FX) {
    fontSize = `${20 / 16}rem`;
    fontWeight = 600;
  }

  const defaultCss = css`
    font-family: ${fontFamily};
    font-size: ${fontSize};
    font-weight: ${fontWeight};
    margin: 1rem 19px 0 19px;
    text-transform: ${textTransform};
    label: episodeTitleCss;

    ${mediaQuery(FYC_MOBILE_WIDTH)} {
      margin: 22px 2rem 0 2rem;
    }
  `;

  const nonPlainCss = css`
    margin-bottom: 1rem;
  `;

  if (hasPlain) {
    return [defaultCss];
  }

  return [defaultCss, nonPlainCss];
};

export const contentBodyCss = (hasPlain: boolean): SerializedStyles[] => {
  // default is the plain view
  const defaultCss = css`
    width: 100%;
    display: flex;
    flex-direction: row;
  `;

  const nonPlainCss = css`
    flex-direction: column;
    ${mediaQuery(FYC_TABLET_WIDTH)} {
      flex-direction: row;
    }
  `;

  if (hasPlain) {
    return [defaultCss];
  }

  return [defaultCss, nonPlainCss];
};

export const contentLeftCss = css`
  width: 100%;
  padding: 0rem ${19 / 16}rem;

  ${mediaQuery(FYC_MOBILE_WIDTH)} {
    padding: 16px 58px;
  }

  ${mediaQuery(FYC_TABLET_WIDTH)} {
    padding: 27px 32px;
    padding-bottom: 0;
    width: ${564 / 16}rem;
  }
`;

export const contentRightCss = (
  hasPlain: boolean,
  version: FycVersionType,
): SerializedStyles => {
  const customCssConfig = getVersionedComponentConfig<
    EpisodesInfoModalConfigInterface['contentRightCss']
  >({
    version,
    component: FycConfigsEnum.EPISODES_INFO_MODAL_CONFIG,
    selector: 'contentRightCss',
  });

  let fontFamily = customCssConfig?.fontFamily ?? `futura-pt`;
  let titleFontWeight = customCssConfig?.titleFontWeight ?? 500;
  let infoFontWeight = customCssConfig?.infoFontWeight ?? 400;
  let contentTitleFontSize = customCssConfig?.contentTitleFontSize ?? '1rem';
  let contentIntoFontSize = customCssConfig?.contentIntoFontSize ?? '1rem';
  let synopsisTitleTextTransform =
    customCssConfig?.synopsisTitleTextTransform ?? 'none';
  let synopsisTitleFontFamily =
    customCssConfig?.synopsisTitleFontFamily ?? 'inherit';
  let inputPromptFontFamily =
    customCssConfig?.inputPromptFontFamily ?? 'inherit';
  const inputPromptFontWeight = customCssConfig?.inputPromptFontWeight ?? 400;

  if (version === ABC) {
    fontFamily = `gt-america-co`;
    titleFontWeight = 400;
    infoFontWeight = 400;
  }

  if (version === DTS) {
    fontFamily = 'Avenir';
    titleFontWeight = 400;
    contentTitleFontSize = `${14 / 16}rem`;
    contentIntoFontSize = `${14 / 16}rem`;
  }

  if (version === HULU) {
    fontFamily = 'Graphik';
    titleFontWeight = 400;
    contentTitleFontSize = `${14 / 16}rem`;
    contentIntoFontSize = `${14 / 16}rem`;
  }

  if (version === SEARCHLIGHT || version === SEARCHLIGHT_AWARDS) {
    synopsisTitleTextTransform = 'uppercase';
    synopsisTitleFontFamily = `'Open Sans', sans-serif`;
    titleFontWeight = 400;
    contentIntoFontSize = `${14 / 16}rem`;
    infoFontWeight = 400;
    inputPromptFontFamily = `'Open Sans', sans-serif`;
  }

  if (version === NATGEO) {
    contentTitleFontSize = `${14 / 16}rem`;
    titleFontWeight = 400;
    synopsisTitleFontFamily = `Geograph Regular`;
    infoFontWeight = 400;
    fontFamily = `Geograph Regular`;
    contentIntoFontSize = `${14 / 16}rem`;
  }

  if (version === FX) {
    contentTitleFontSize = `${14 / 16}rem`;
    titleFontWeight = 400;
    contentIntoFontSize = `${14 / 16}rem`;
    infoFontWeight = 400;
    synopsisTitleFontFamily = `Bain`;
    fontFamily = `Bain`;
  }

  return css`
    font-family: ${fontFamily};
    width: 100%;
    padding: 1rem ${19 / 16}rem;
    ${!hasPlain && `padding-top: 8px;`}
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    label: contentRightCss;

    ${mediaQuery(FYC_MOBILE_WIDTH)} {
      padding: 24px 2rem 2rem 2rem;
      ${!hasPlain && `padding:0 24px;`}
    }

    ${mediaQuery(FYC_TABLET_WIDTH)} {
      ${!hasPlain && `padding: 27px 39px 18px 0;`}
      ${!hasPlain && `width: ${979 - 564}px;`}
    }

    .content {
      .content-title {
        font-size: ${contentTitleFontSize};
        font-weight: ${titleFontWeight};
        color: rgba(255, 255, 255, 0.63);
        margin-bottom: ${10 / 16}rem;
        text-transform: ${synopsisTitleTextTransform};
        font-family: ${synopsisTitleFontFamily};
      }

      .content-info {
        font-size: ${contentIntoFontSize};
        font-weight: ${infoFontWeight};
      }
    }

    .input {
      margin-top: ${60 / 16}rem;

      ${mediaQuery(FYC_MOBILE_WIDTH)} {
        margin-top: ${44 / 16}rem;
      }

      .input-prompt {
        font-size: ${14 / 16}rem;
        font-weight: ${inputPromptFontWeight};
        color: rgba(255, 255, 255, 0.63);
        margin-bottom: ${10 / 16}rem;
        font-family: ${inputPromptFontFamily};
      }
    }
  `;
};

export const accessCodeCss = (hasPlain: boolean): SerializedStyles => css`
  display: flex;
  flex-direction: column;
  align-items: start;

  ${mediaQuery(FYC_MOBILE_WIDTH)} {
    flex-direction: row;
  }

  .input-container {
    margin-right: ${18 / 16}rem;
    width: 100%;
    ${mediaQuery(FYC_MOBILE_WIDTH)} {
      width: ${(276 / 415) * 100}%;
      ${!hasPlain && `width: ${280 / 16}rem;`}
    }
    ${mediaQuery(FYC_TABLET_WIDTH)} {
      ${!hasPlain && `width: ${250 / 16}rem;`}
      ${!hasPlain && `margin-right: ${8 / 16}rem;`}
    }
  }

  button {
    margin-top: 1rem;
    width: 100%;

    ${mediaQuery(FYC_MOBILE_WIDTH)} {
      margin-top: 0 !important;
      width: ${(104 / 415) * 100}% !important;
    }
  }
`;

export const videoActionCss = (hasPlain: boolean): SerializedStyles => css`
  ${mediaQuery(FYC_MOBILE_WIDTH)} {
    ${!hasPlain && `height: 42px;`}
    ${!hasPlain && `margin-top: 61px;`}
  }

  .action-button-container {
    ${mediaQuery(FYC_MOBILE_WIDTH)} {
      ${!hasPlain && `margin-top: 0;`}
    }
  }

  .buttonsWrapper {
    width: 100%;
    display: flex;
  }

  button {
    width: 100%;
    margin-top: ${130 / 16}rem;
    text-transform: uppercase;
    margin-left: 0px;

    ${mediaQuery(FYC_MOBILE_WIDTH)} {
      width: ${(144 / 415) * 100}% !important;
      ${!hasPlain && `margin-top: 0;`}
    }
  }
`;
