import type { CSSObject } from '@emotion/core';
import type { CategorySelectConfigInterface } from 'providers/fyc-theme-component-interfaces';
import { FycConfigsEnum } from 'providers/fyc-theme-component-interfaces';
import type { FycVersionType } from 'providers/fyc-theme-provider';
import { SEARCHLIGHT, SEARCHLIGHT_AWARDS } from 'providers/fyc-theme-provider';
import { getVersionedComponentConfig } from 'providers/versions/fyc-theme-provider-utils';

interface SelectStylesReturnTypeInterface {
  control: (base: CSSObject) => CSSObject;
  indicatorSeparator: (base: CSSObject) => CSSObject;
  menu: (base: CSSObject) => CSSObject;
  option: (
    base: CSSObject,
    options: { isDisabled: boolean; isSelected: boolean },
  ) => CSSObject;
  placeholder: (base: CSSObject) => CSSObject;
  singleValue: (base: CSSObject) => CSSObject;
}

export const selectStyles = (
  version: FycVersionType,
): SelectStylesReturnTypeInterface => {
  const customCssConfig = getVersionedComponentConfig<
    CategorySelectConfigInterface['selectStyles']
  >({
    version,
    component: FycConfigsEnum.CATEGORY_SELECT_CONFIG,
    selector: 'selectStyles',
  });

  return {
    control: (base) => ({
      ...base,
      background: 'transparent',
      color: '#fff',
      borderWidth: '1px',
      borderRadius: '6px',
      borderStyle: 'solid',
      boxShadow: 'none',
    }),
    menu: (base) => ({
      ...base,
      backgroundColor: '#313145',
      border: '1px solid #5e94f7',
      marginTop: 1,
    }),
    option: (base, { isDisabled, isSelected }) => ({
      ...base,
      backgroundColor: !isDisabled && isSelected ? '#404052' : undefined,
    }),
    singleValue: (base) => {
      let color = customCssConfig?.singleValue?.color ?? '#fff';

      if (version === SEARCHLIGHT || version === SEARCHLIGHT_AWARDS) {
        color = 'black';
      }
      return {
        ...base,
        color: color,
      };
    },
    indicatorSeparator: (base) => ({
      ...base,
      display: 'none',
    }),
    placeholder: (base) => {
      let color = customCssConfig?.placeholder?.color ?? '#fff';

      if (version === SEARCHLIGHT || version === SEARCHLIGHT_AWARDS) {
        color = 'black';
      }
      return {
        ...base,
        color: color,
      };
    },
  };
};
