/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import Item from './item';

interface PressDataInterface {
  articleDate: string;
  articleDescription: string;
  articleOutlet: string;
  articleUrl: string;
  heroImage: {
    alt: string;
    aspectRationFractions: string[];
    url: string;
  };
  id: string;
}

interface PropsInterface {
  data: PressDataInterface[];
}

const PressPanel: React.FC<PropsInterface> = ({ data }) => {
  return (
    <div>
      {data.map((item) => (
        <Item
          key={item.id}
          imgUrl={item.heroImage.url}
          date={item.articleDate}
          title={item.articleOutlet}
          description={item.articleDescription}
          fileUrl={item.articleUrl}
          ratio={
            item.heroImage.aspectRationFractions &&
            item.heroImage.aspectRationFractions[0]
          }
        />
      ))}
    </div>
  );
};

export default PressPanel;
