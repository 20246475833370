import type { SerializedStyles } from '@emotion/core';
import { css } from '@emotion/core';
import { FYC_MOBILE_WIDTH } from 'pages/fyc-page/helpers';
import type { EpisodesPanelItemConfigInterface } from 'providers/fyc-theme-component-interfaces';
import { FycConfigsEnum } from 'providers/fyc-theme-component-interfaces';
import type { FycVersionType } from 'providers/fyc-theme-provider';
import {
  DTS,
  FX,
  SEARCHLIGHT,
  SEARCHLIGHT_AWARDS,
  TWDS_AWARDS,
} from 'providers/fyc-theme-provider';
import { getVersionedComponentConfig } from 'providers/versions/fyc-theme-provider-utils';
import { TWDS_AWARDS_CARD_BG } from 'styles/fyctheme';
import { mediaQuery } from 'utils/styles';

export const wrapperCss = css`
  margin-top: ${32 / 16}rem;
  display: flex;
  flex-direction: column;

  ${mediaQuery(FYC_MOBILE_WIDTH)} {
    flex-direction: row;
  }
`;

export const imageWrapperCss = css`
  width: 100%;
  aspect-ratio: 16 / 9;
  cursor: pointer;
  overflow: hidden;
  border-radius: 6px;

  ${mediaQuery(FYC_MOBILE_WIDTH)} {
    width: ${231 / 16}rem;
    height: ${128 / 16}rem;
  }
`;

export const nsImageCss = {
  nsImageCss: css`
    border-radius: 6px;
  `,
};

export const textWrapperCss = css`
  width: 100%;
  padding-top: ${12 / 16}rem;

  ${mediaQuery(FYC_MOBILE_WIDTH)} {
    max-width: ${(436 / 890) * 100}%;
    padding-left: ${16 / 16}rem;
    padding-top: ${4 / 16}rem;
  }
`;

export const titleCss = (version: FycVersionType): SerializedStyles => {
  const customCssConfig = getVersionedComponentConfig<
    EpisodesPanelItemConfigInterface['titleCss']
  >({
    version,
    component: FycConfigsEnum.EPISODES_PANEL_ITEM_CONFIG,
    selector: 'titleCss',
  });
  let fontWeight = customCssConfig?.fontWeight ?? 500;
  let fontSize = customCssConfig?.fontSize ?? '16px';
  const textTransform = customCssConfig?.textTransform ?? 'none';

  if (version === SEARCHLIGHT || version === SEARCHLIGHT_AWARDS) {
    fontWeight = 600;
  }

  if (version === FX) {
    fontWeight = 600;
    fontSize = '14px';
  }

  return css`
    margin-bottom: ${14 / 16}rem;
    font-size: ${fontSize};
    font-weight: ${fontWeight};
    text-transform: ${textTransform};
    label: titleCss;
  `;
};

export const contentCss = (version: FycVersionType): SerializedStyles => {
  const customCssConfig = getVersionedComponentConfig<
    EpisodesPanelItemConfigInterface['contentCss']
  >({
    version,
    component: FycConfigsEnum.EPISODES_PANEL_ITEM_CONFIG,
    selector: 'contentCss',
  });

  let fontSize = customCssConfig?.fontSize ?? '1rem';
  let color = customCssConfig?.color ?? 'inherit';
  const fontWeight = customCssConfig?.fontWeight ?? 400;

  if (version === DTS) {
    fontSize = `${14 / 16}rem`;
  }

  if (version === SEARCHLIGHT || version === SEARCHLIGHT_AWARDS) {
    color = `rgba(38, 38, 38, 1)`;
    fontSize = `${14 / 16}rem`;
  }

  if (version === FX) {
    fontSize = `${12 / 16}rem`;
  }

  return css`
    font-size: ${fontSize};
    font-weight: ${fontWeight};
    color: ${color};
    label: contentCss;
  `;
};

export const plainImageCss = (version: FycVersionType): SerializedStyles => {
  const customCssConfig = getVersionedComponentConfig<
    EpisodesPanelItemConfigInterface['plainImageCss']
  >({
    version,
    component: FycConfigsEnum.EPISODES_PANEL_ITEM_CONFIG,
    selector: 'plainImageCss',
  });

  let color = customCssConfig?.color ?? 'inherit';
  let topBg =
    customCssConfig?.topBg ??
    `linear-gradient(
    359.43deg,
    rgba(0, 78, 146, 0.46) -294.8%,
    #000428 34.06%,
    #004e92 276.33%
  )`;

  if (version === SEARCHLIGHT || version === SEARCHLIGHT_AWARDS) {
    color = '#FFF';
  }

  if (version === TWDS_AWARDS) {
    topBg = TWDS_AWARDS_CARD_BG;
  }

  return css`
    color: ${color};
    background: linear-gradient(180deg, #404461 0%, #232434 100%);
    box-shadow:
      0px 4px 5px rgba(0, 0, 0, 0.14),
      0px 1px 10px rgba(0, 0, 0, 0.12),
      0px 2px 4px -1px rgba(0, 0, 0, 0.2);
    border-radius: 2px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    label: plainImageCss;

    .top {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 0 1rem;

      background: ${topBg};
      border-radius: 2px;
    }
  `;
};
