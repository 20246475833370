/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useState } from 'react';
import { AudioPlayer } from 'components/audio-player';
import {
  audioTitleCss,
  wrapperCss,
  contentWrapperCss,
  playerWrapperCss,
} from './styles';
import { useFycThemeContext } from 'providers/fyc-theme-provider';

interface PropsInterface {
  artist: string;
  index: number;
  passRef: any;
  title: string;
  url: string;
}

export const Track: React.FC<PropsInterface> = ({
  url,
  title,
  artist,
  passRef,
  index,
}) => {
  const [isPlaying, setIsPlaying] = useState(false);

  const version = useFycThemeContext()?.version;

  return (
    <div css={wrapperCss}>
      <div css={contentWrapperCss}>
        <div css={audioTitleCss(isPlaying, version)}>
          <span className="audio-title">{title && title}</span>
          <span className="audio-artist">{artist && artist}</span>
        </div>
        <div css={playerWrapperCss}>
          <AudioPlayer
            setStatus={setIsPlaying}
            src={url}
            passRef={passRef}
            index={index}
          />
        </div>
      </div>
    </div>
  );
};
