/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import type { FycVersionType } from 'providers/fyc-theme-provider';
import {
  NATGEO,
  SEARCHLIGHT,
  SEARCHLIGHT_AWARDS,
  useFycThemeContext,
} from 'providers/fyc-theme-provider';
import React from 'react';
import { sourceWrapperCss } from './styles';
import quotationMarks from 'public/images/quotationMarks.svg';
import quotationMarksBlack from 'public/images/quotationMarksBlack.svg';
import quotationMarksYellow from 'public/images/quotationMarksYellow.svg';

interface PropsInterface {
  content: string;
  source: string;
}

const Quotes: React.FC<PropsInterface> = ({ content, source }) => {
  const version = useFycThemeContext()?.version;

  const chooseQuotationMarks = (version: FycVersionType) => {
    let result = quotationMarks;

    if (version === SEARCHLIGHT || version === SEARCHLIGHT_AWARDS) {
      result = quotationMarksBlack;
    }

    if (version === NATGEO) {
      result = quotationMarksYellow;
    }

    return result;
  };

  return (
    <div
      css={css`
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding-top: 8px;
        max-width: 756px;
        margin: 0 auto;
      `}
    >
      <div
        css={css`
          align-self: start;
          margin-right: 1.5rem;
          img {
            width: 20px;
          }
        `}
      >
        <img src={chooseQuotationMarks(version)} />
      </div>
      <div>
        <div
          css={css`
            font-size: 1rem;
            font-weight: 500;
          `}
        >
          {content}
        </div>
        <div css={sourceWrapperCss(version)}>{source}</div>
      </div>
      <div
        css={css`
          align-self: start;
          transform: rotate(180deg);
          margin-left: 1.5rem;
          img {
            width: 20px;
          }
        `}
      >
        <img src={chooseQuotationMarks(version)} />
      </div>
    </div>
  );
};

export default Quotes;
