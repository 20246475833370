/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useState, useEffect } from 'react';
import Typography from 'components/typography';
import type { EntitlementInterface } from 'models/components/entitlement-component-interface';
import {
  expiryContainerRootCss,
  expiryText,
  iconSpanCss,
  playsSpanCss,
  dateSpanCss,
} from './styles';
import { hasExpiry, generatePlayCountInfo, generateDateInfo } from './helpers';
import WarningIcon from '../../public/images/Expire_warning.svg';
import { useLocation } from 'react-router-dom';

export interface ExpiryDatePropsInterface {
  entitlement: EntitlementInterface;
}

const ExpiryDate: React.FC<ExpiryDatePropsInterface> = ({
  entitlement,
}): React.ReactElement | null => {
  const { pathname } = useLocation();

  const [isSeries, setIsSeries] = useState(
    () => pathname.split('/')[1] === 'series',
  );

  useEffect(() => {
    setIsSeries(() => pathname.split('/')[1] === 'series');
  }, [pathname]);

  const display = (html: JSX.Element, showIcon: boolean) => {
    return (
      <div
        className="content-expiry-container"
        css={expiryContainerRootCss}
      >
        <Typography
          className="content-plays-and-expiry"
          additionalCss={expiryText}
        >
          {showIcon && (
            <span css={iconSpanCss}>
              <img src={WarningIcon} />
            </span>
          )}
          {html}
        </Typography>
      </div>
    );
  };

  const renderDisplayText = (entitlement: EntitlementInterface) => {
    let text;

    const {
      show: showPlaybacks,
      text: plays,
      warning: showPlaysWarning,
    } = generatePlayCountInfo(entitlement);
    const {
      show: showDate,
      text: expiryDate,
      warning: showDateWarning,
    } = generateDateInfo(entitlement);

    const playsSpan = <span css={playsSpanCss(showPlaysWarning)}>{plays}</span>;
    const dateSpan = (
      <span css={dateSpanCss(showDateWarning)}>{expiryDate}</span>
    );

    const separatorSpan = <span className="separator">|</span>;

    // show both
    if (showPlaybacks && showDate) {
      text =
        !isSeries ?
          <span>
            {dateSpan}
            {separatorSpan}
            {playsSpan}
          </span>
        : <span>
            {dateSpan}
            <br />
            {playsSpan}
          </span>;

      return display(text, showPlaysWarning || showDateWarning);
    }

    if (showPlaybacks) {
      return display(playsSpan, showPlaysWarning);
    }

    if (showDate) {
      return display(dateSpan, showDateWarning);
    }

    return null;
  };

  return hasExpiry(entitlement) ? renderDisplayText(entitlement) : null;
};

export default ExpiryDate;
