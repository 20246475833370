import type { SerializedStyles } from '@emotion/core';
import { css } from '@emotion/core';
import { FYC_MOBILE_WIDTH, FYC_TABLET_WIDTH } from 'pages/fyc-page/helpers';
import type { SliderConfigInterface } from 'providers/fyc-theme-component-interfaces';
import { FycConfigsEnum } from 'providers/fyc-theme-component-interfaces';
import type { FycVersionType } from 'providers/fyc-theme-provider';
import {
  ABC,
  DBT,
  DISNEYPLUS,
  DTS,
  FREEFORM,
  FX,
  HULU,
  NATGEO,
  SEARCHLIGHT,
  SEARCHLIGHT_AWARDS,
} from 'providers/fyc-theme-provider';
import { getVersionedComponentConfig } from 'providers/versions/fyc-theme-provider-utils';
import { mediaQuery } from 'utils/styles';

export const wrapperCss = css`
  position: relative;
  margin-left: -1rem; // remove the effect defined in AllContent page
  overflow: hidden;
  padding-bottom: 150px;
`;

export const leftArrow = css`
  z-index: 10;
  position: absolute;
  left: 0;
  top: ${34 / 16}rem;
  height: calc(100% - 68px - 2rem - 150px);
  display: none;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: linear-gradient(
    270deg,
    #000000 0%,
    #1a1919 0.01%,
    rgba(0, 0, 0, 0.42) 81.53%,
    rgba(0, 0, 0, 0) 111.02%
  );
  border-radius: 0px 4px 4px 0px;

  transform: rotate(-180deg);

  ${mediaQuery(FYC_MOBILE_WIDTH)} {
    display: flex;
  }
`;

export const rightArrow = css`
  z-index: 10;
  position: absolute;
  right: 0;
  top: ${34 / 16}rem;
  height: calc(100% - 68px - 2rem - 150px);
  display: none;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: linear-gradient(
    270deg,
    #000000 0%,
    #1a1919 0.01%,
    rgba(0, 0, 0, 0.42) 81.53%,
    rgba(0, 0, 0, 0) 111.02%
  );
  border-radius: 0px 4px 4px 0px;

  ${mediaQuery(FYC_MOBILE_WIDTH)} {
    display: flex;
  }
`;

export const imgWrapperCss = css`
  padding: ${34 / 16}rem 0;
  padding-left: 1rem;
  display: flex;
  flex-direction: row;

  ${mediaQuery(FYC_MOBILE_WIDTH)} {
    padding-top: 7%;
    padding-bottom: 7%;
    padding-left: 5%;
  }

  ${mediaQuery(FYC_TABLET_WIDTH)} {
    padding-top: 3%;
    padding-bottom: 3%;
    padding-left: 2%;
  }
`;

export const imgNTitleWrapperCss = (
  version: FycVersionType,
): SerializedStyles => {
  const customCssConfig = getVersionedComponentConfig<
    SliderConfigInterface['imgNTitleWrapperCss']
  >({
    version,
    component: FycConfigsEnum.SLIDER_CONFIG,
    selector: 'imgNTitleWrapperCss',
  });

  let titleFontFamily = customCssConfig?.titleFontFamily ?? 'inherit';
  let titleFontWeight = customCssConfig?.titleFontWeight ?? 'inherit';

  if (version === HULU) {
    titleFontFamily = `Graphik Semibold`;
    titleFontWeight = 600;
  }

  if (version === NATGEO) {
    titleFontFamily = `Geograph Medium`;
    titleFontWeight = 400;
  }

  if (version === FX) {
    titleFontFamily = `Bain`;
    titleFontWeight = 600;
  }

  return css`
    flex-shrink: 0;
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    aspect-ratio: 2 / 3;
    cursor: pointer;

    width: 48%;
    margin-right: 6%;
    label: imgNTitleWrapperCss;

    ${mediaQuery(FYC_MOBILE_WIDTH)} {
      width: 32%;
      margin-right: 5%;
    }

    ${mediaQuery(FYC_TABLET_WIDTH)} {
      width: 24%;
      margin-right: 2%;
    }

    transition: transform 0.3s ease-in-out;

    :hover {
      transform: scale(1.12);
      box-shadow: 5px;

      .fyc-slider-en-title {
        visibility: visible;
      }
    }

    .fyc-slider-en-title {
      width: 100%;
      height: 1rem;
      display: flex;
      justify-content: center;
      text-align: center;
      margin-top: 1rem;
      visibility: hidden;
      font-size: ${14 / 16}rem;
      font-family: ${titleFontFamily};
      font-weight: ${titleFontWeight};
    }
  `;
};

export const imgContainerCss = (
  isPlain: boolean,
  version: FycVersionType,
): SerializedStyles[] => {
  const hasOverlay = getVersionedComponentConfig<boolean>({
    version,
    component: FycConfigsEnum.SLIDER_CONFIG,
    selector: 'hasOverlay',
  });

  let overlay = '';

  if (
    version === ABC ||
    version === FREEFORM ||
    version === DBT ||
    version === DISNEYPLUS ||
    version === DTS ||
    version === HULU ||
    version === SEARCHLIGHT ||
    version === SEARCHLIGHT_AWARDS ||
    version === NATGEO ||
    version === FX ||
    hasOverlay
  ) {
    overlay = `
    label: imgContainerCss;
    
    .overlay {
      display: none;
    }
  
    :hover {
      .overlay {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        z-index: 100;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        color: red;
        background: linear-gradient(
          72.49deg,
          rgba(0, 2, 9, 0.5) 0%,
          rgba(24, 26, 37, 0) 112.03%
        );
      }
    }
    
    `;
  }

  const defaultCss = [
    css`
      position: relative;
      flex-shrink: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      aspect-ratio: 2 / 3;
      width: 100%;
      cursor: pointer;

      ${overlay}
    `,
  ];

  if (isPlain) {
    defaultCss.push(css`
      background: radial-gradient(
        110.84% 110.84% at 48% -9.29%,
        #565d92 0%,
        #0c0d37 100%
      );
      border-radius: 6px;
    `);
  }

  return defaultCss;
};

export const titleNameCss = (version: FycVersionType): SerializedStyles => {
  const customCssConfig = getVersionedComponentConfig<
    SliderConfigInterface['titleNameCss']
  >({
    version,
    component: FycConfigsEnum.SLIDER_CONFIG,
    selector: 'titleNameCss',
  });

  let color = customCssConfig?.color ?? 'white';
  const textTransform = customCssConfig?.textTransform ?? 'uppercase';

  if (version === SEARCHLIGHT || version === SEARCHLIGHT_AWARDS) {
    color = 'rgba(10, 19, 32, 1)';
  }

  return css`
    color: ${color};
    text-transform: ${textTransform};
    label: titleNameCss;
  `;
};
