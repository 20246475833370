/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useState, useEffect } from 'react';
import NsImage from 'components/ns-component/ns-image';
import {
  wrapperCss,
  imgsWrapperCss,
  imageContainerCss,
  actionsWrapperCss,
  prevBtnCss,
  nextBtnCss,
  indicatorsCss,
  indicatorItemCss,
} from './styles';
import ChevronRightIcon from 'public/images/Slider_Chevron_Right.png';
import {
  FYC_SMALL_MOBILE_WIDTH,
  FYC_MOBILE_WIDTH,
  FYC_TABLET_WIDTH,
} from 'pages/fyc-page/helpers';
import { useFycThemeContext } from 'providers/fyc-theme-provider';
import { ActionTypeEnum } from 'models/enums';
import { useHistory } from 'react-router-dom';

interface PropsInterface {
  component: any;
  hasPlain?: boolean;
}

export const FycBanner: React.FC<PropsInterface> = ({
  component,
  hasPlain,
}) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [ratio, setRatio] = useState('3/1');
  const [componentJustUpdated, setComponentJustUpdated] = useState(false);

  const history = useHistory();

  const version = useFycThemeContext()?.version;

  const calculateRatio = (): void => {
    const { innerWidth } = window;
    if (innerWidth < FYC_SMALL_MOBILE_WIDTH) {
      setRatio('335/256');
      return;
    }
    if (innerWidth < FYC_MOBILE_WIDTH) {
      setRatio('567/258');
      return;
    }
    if (innerWidth < FYC_TABLET_WIDTH) {
      setRatio('983/320');
      return;
    }

    setRatio('3/1');
  };

  const handlePrev = () => {
    setCurrentSlide((currentSlide) => {
      if (currentSlide === 0) {
        return component.items.length - 1;
      }
      return currentSlide - 1;
    });
  };

  const handleNext = () => {
    setCurrentSlide((currentSlide) => {
      if (currentSlide === component.items.length - 1) {
        return 0;
      }
      return currentSlide + 1;
    });
  };

  const handleIndicatorClick = (index: number) => {
    setCurrentSlide(index);
  };

  const checkBannerClickable = (item: any): boolean => {
    if (!item.actions) {
      return false;
    }

    const action = item.actions?.find(
      (a) =>
        a.type === ActionTypeEnum.PAGE || a.type === ActionTypeEnum.EXTERNAL,
    );

    if (
      (action?.type === ActionTypeEnum.PAGE && action?.target) ||
      (action?.type === ActionTypeEnum.EXTERNAL && action?.target)
    ) {
      return true;
    }

    return false;
  };

  const handleClick = (item: any) => {
    if (!item.actions) {
      return;
    }

    const action = item.actions?.find(
      (a) =>
        a.type === ActionTypeEnum.PAGE || a.type === ActionTypeEnum.EXTERNAL,
    );

    if (action?.type === ActionTypeEnum.PAGE && action?.target) {
      history.push(`/${action.target}`);
    }

    if (action?.type === ActionTypeEnum.EXTERNAL && action?.target) {
      window.open(action.target, '_blank');
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setComponentJustUpdated(false);
      setCurrentSlide((currentSlide) => {
        if (currentSlide === component.items.length - 1) {
          return 0;
        }
        return currentSlide + 1;
      });
    }, 5000);

    return () => clearInterval(intervalId);
  }, [currentSlide, component, component.items?.length]);

  useEffect(() => {
    const onResize = (): void => {
      calculateRatio();
    };

    calculateRatio();

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  // reset current slide when component update
  useEffect(() => {
    setCurrentSlide(0);
    setComponentJustUpdated(true);
  }, [component]);

  return (
    <div
      className="banner-wrapper"
      css={wrapperCss(version, hasPlain)}
    >
      {component.items.length !== 1 && (
        <div
          className="actionsWrapper"
          css={actionsWrapperCss}
        >
          <div
            className="prev-btn"
            onClick={handlePrev}
            css={prevBtnCss}
          >
            <img src={ChevronRightIcon} />
          </div>
          <div
            className="indicators"
            css={indicatorsCss}
          >
            {component.items?.map((item, index) => (
              <div
                css={indicatorItemCss(currentSlide === index, version)}
                key={index}
                onClick={() => {
                  handleIndicatorClick(index);
                }}
              />
            ))}
          </div>
          <div
            className="next-btn"
            onClick={handleNext}
            css={nextBtnCss}
          >
            <img src={ChevronRightIcon} />
          </div>
        </div>
      )}

      <div css={imgsWrapperCss(currentSlide, componentJustUpdated)}>
        {component.items?.map((item) => (
          <div
            key={item.id}
            css={imageContainerCss(version, checkBannerClickable(item))}
            onClick={() => handleClick(item)}
          >
            <NsImage
              url={item.image?.url}
              width={1200}
              aspectRatio={ratio}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
