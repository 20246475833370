import { css } from '@emotion/core';
import { FYC_MOBILE_WIDTH, FYC_TABLET_WIDTH } from 'pages/fyc-page/helpers';
import { mediaQuery } from 'utils/styles';

export const wrapperCss = css`
  padding: 0 ${8 / 16}rem;

  ${mediaQuery(FYC_MOBILE_WIDTH)} {
    padding: 0 ${26 / 16}rem;
  }
`;

export const listTitleCss = css`
  font-weight: 500;
  font-size: ${14 / 16}rem;
  line-height: ${19 / 16}rem;
  display: none;

  ${mediaQuery(FYC_MOBILE_WIDTH)} {
    margin-bottom: 1rem;
    font-size: 1rem;
    line-height: ${21 / 16}rem;
    display: block;
  }
`;

export const trackCountTitleCss = css`
  font-size: 12px;
  line-height: 14px;
  color: #686869;
  margin-bottom: 1rem;

  ${mediaQuery(FYC_MOBILE_WIDTH)} {
    font-size: ${14 / 16}rem};
    line-height: 1rem;
  }
`;

export const accordionWrapperCss = css`
  ${mediaQuery(FYC_MOBILE_WIDTH)} {
    padding: 0 1rem;
  }

  ${mediaQuery(FYC_TABLET_WIDTH)} {
    padding: 0 2rem;
  }
`;
