/** @jsx jsx */
import type { SerializedStyles } from '@emotion/core';
import { jsx } from '@emotion/core';
import React from 'react';
import { btnCss } from './styles';

interface PropsInterface {
  customStyles?: SerializedStyles;
  onClick?: () => void;
}

const EnterCodeButton: React.FC<PropsInterface> = ({
  onClick,
  customStyles,
}) => {
  return (
    <button
      css={btnCss(customStyles)}
      onClick={onClick}
    >
      Enter Access Code
    </button>
  );
};

export default EnterCodeButton;
