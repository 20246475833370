/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import Logo from 'components/logo';
import NsLink from 'components/ns-component/ns-link';
import type { ReactNode } from 'react';
import React, { useEffect } from 'react';
import { contactCss, footerCss, wrapperCss, tosCss } from './styles';

interface LinkInterface {
  text: string;
  url: string;
}

interface PropsInterface {
  links: LinkInterface[];
  tosData: ReactNode[];
}

const Footer: React.FC<PropsInterface> = ({ links, tosData }) => {
  // remove the additional vertical line at the end
  const hiddenVerticalLine = () => {
    const spansGroup =
      (document.getElementsByClassName(
        'spans-group',
      ) as HTMLCollectionOf<HTMLElement>) || [];
    for (const span of spansGroup) {
      span.style.display = 'inline-block';
      const preEle = span.previousElementSibling as HTMLElement | null;
      const nextEle = span.nextElementSibling as HTMLElement | null;

      if (
        span.offsetTop !== nextEle?.offsetTop ||
        span.offsetTop !== preEle?.offsetTop
      ) {
        span.style.display = 'none';
      }
    }
  };

  useEffect(() => {
    hiddenVerticalLine();
    window.addEventListener('resize', () => {
      hiddenVerticalLine();
    });

    return () => {
      window.removeEventListener('resize', () => {
        hiddenVerticalLine();
      });
    };
  }, []);

  return (
    <footer css={footerCss}>
      <div css={wrapperCss()}>
        <div className="linksPart">
          <div css={contactCss()}>
            {links.map((link, i) => (
              <NsLink
                key={i}
                className="contact-us"
                to={link.url}
              >
                {link.text}
              </NsLink>
            ))}
          </div>
          <div css={tosCss}>{tosData}</div>
        </div>
        <div className="logoPart">
          <NsLink
            className="fyc-logo-link"
            to={`/`}
          >
            <Logo
              white
              logoStyles={css`
                height: ${20 / 16}rem;
              `}
            />
          </NsLink>

          <div
            css={css`
              font-size: ${12 / 16}rem;
              font-weight: 400;
              margin-top: ${10 / 16}rem;
              color: rgba(255, 255, 255, 0.67);
            `}
          >
            Disney &copy; All Rights Reserved
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
