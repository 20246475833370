/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import React from 'react';
import Select from 'react-select';
import { formatCategoryToOptions } from 'components/fyc-component/category-select/helpers';
import { selectStyles } from './styles';
import DownArrow from 'public/images/Select_Arrow_Down.svg';
import DownArrowDark from 'public/images/DownArrowDark.svg';
import {
  SEARCHLIGHT,
  SEARCHLIGHT_AWARDS,
  useFycThemeContext,
} from 'providers/fyc-theme-provider';

interface PropsInterface {
  categories: { title: string }[];
  currentCate?: string | null;
  handleSelectCate: (title: string) => void;
}

const downIndicatorCss = css`
  margin-right: 14px;
`;

export const DropdownIndicator: React.FC = () => {
  const version = useFycThemeContext()?.version;

  let src = DownArrow;

  if (version === SEARCHLIGHT || version === SEARCHLIGHT_AWARDS) {
    src = DownArrowDark;
  }

  return (
    <div css={downIndicatorCss}>
      <img src={src} />
    </div>
  );
};

const CategorySelect: React.FC<PropsInterface> = ({
  categories,
  handleSelectCate,
  currentCate,
}) => {
  const version = useFycThemeContext()?.version;

  const options = formatCategoryToOptions([
    { title: 'ALL CONTENT' },
    ...categories,
  ]);

  return (
    <div>
      <Select
        options={options}
        placeholder={options[0].label}
        isSearchable={false}
        styles={selectStyles(version)}
        components={{ DropdownIndicator }}
        // @ts-ignore TODO: TS7006: Parameter 'option' implicitly has an 'any' type.
        onChange={(option) => handleSelectCate(option.value)}
        // @ts-ignore TODO: TS2769: No overload matches this call.Overload 1 of 2...
        value={
          currentCate &&
          options.find((option: any) => option.value === currentCate)
        }
      />
    </div>
  );
};

export default CategorySelect;
