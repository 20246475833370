import type { FunctionComponent } from 'react';
import React from 'react';
import { useSelector } from 'react-redux';
import { ThemeProvider as ThemeProviderJss } from 'react-jss';
import { ThemeProvider as ThemeProviderEmotion } from 'emotion-theming';
import defaultTheme from 'styles/theme';
import GlobalStyles from 'styles/global-styles';

interface PropsInterface {
  children: any;
}

const ThemeProvider: FunctionComponent<PropsInterface> = (
  props: PropsInterface,
) => {
  const theme =
    useSelector((state: any) => state.page && state.page.theme) || defaultTheme;

  return (
    // TODO: remove jss
    <ThemeProviderJss theme={theme}>
      <ThemeProviderEmotion theme={theme}>
        <GlobalStyles />
        {props.children}
      </ThemeProviderEmotion>
    </ThemeProviderJss>
  );
};

export default ThemeProvider;
