import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { userErrorSelector, loginWithCodeExchange } from 'store/slices/user';
import * as routes from 'router/constants';
import { useQuery } from 'utils/hooks';
import { Button, Loader } from 'components';
import { LayoutContainer } from 'containers';
import { headerTextCss, loginInfoCss } from 'pages/styles';

const LoginOAuthReturn: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const loginError = useSelector(userErrorSelector);
  const query = useQuery();
  const code = query.get('code');

  useEffect(() => {
    if (code) {
      // call back-end to perform code exchange
      const redirectUri = window.location.href.split('?')[0];
      dispatch(loginWithCodeExchange(code, redirectUri));
    }
  }, [code, dispatch]);

  useEffect(() => {
    if (loginError) {
      setIsLoading(false);
    }
  }, [loginError]);

  if (isLoading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  return (
    <LayoutContainer auth>
      <h2 css={headerTextCss}>Oops!</h2>
      <p css={loginInfoCss}>
        An error occurred while attempting to log in. Please try again later.
      </p>
      <Link to={routes.INDEX}>
        <Button theme="primary">Go to Login</Button>
      </Link>
    </LayoutContainer>
  );
};

export default LoginOAuthReturn;
