import { css } from '@emotion/core';
import { mediaQuery } from 'utils/styles';
import theme from 'styles/theme';
import mobileImage from 'public/images/App_Download_iPhone.png';
import mobileBackground from 'public/images/App_Download_Background_Mobile.png';
import tabletImage from 'public/images/App_Download_iPad.png';
import tabletBackground from 'public/images/App_Download_Background_Tablet.png';

export const appDownloadStyles = css`
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column nowrap;

  &.plain-view-container {
    padding-bottom: 2.45rem;

    hr {
      margin-bottom: 2rem;
    }
  }

  .download-hero {
    background: center no-repeat;

    img {
      position: relative;
    }
  }

  .download-label {
    text-align: center;
  }

  ${mediaQuery(theme.breakpoints.xs)} {
    background-image: url(${mobileBackground});

    &.plain-view-container {
      padding-top: 6.5rem;

      hr {
        width: 82%;
      }
    }

    .download-hero {
      background-image: url(${mobileImage});
      background-size: 6rem;
      min-width: 13rem;
      min-height: 14rem;

      img {
        top: -3.8rem;
        left: -1rem;
      }
    }

    .download-label {
      line-height: 1.4rem;
      max-width: 60%;
    }
  }

  ${mediaQuery(theme.breakpoints.md)} {
    background-image: url(${tabletBackground});

    &.plain-view-container {
      min-height: calc(100vh - ${theme.height.footer.minHeight.md});

      hr {
        width: 63%;
      }
    }

    .download-hero {
      background-image: url(${tabletImage});
      background-size: 13rem;
      min-width: 18rem;
      min-height: 23rem;

      img {
        left: -2.5rem;
      }
    }

    .download-label {
      font-size: 1.2rem;
      line-height: 1.3rem;
      max-width: 25%;
    }
  }
`;
