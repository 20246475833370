/** @jsx jsx */
import { jsx } from '@emotion/core';
import type { ICategoriesItemInterface } from 'models/interfaces';
import React from 'react';
import { categoryCss, recipientsWrapperCss, rowWrapperCss } from './styles';
import { useFycThemeContext } from 'providers/fyc-theme-provider';

interface PropsInterface {
  rowData: any[];
}

const CategoryItem: React.FC<PropsInterface> = ({ rowData }) => {
  const version = useFycThemeContext()?.version;

  const recipientsView = (item: ICategoriesItemInterface) => {
    if (item?.recipients?.length === 1) {
      return <div className="recipientLine">{item.recipients[0]}</div>;
    }

    return (
      <div>
        {item.recipients?.map((recipient, index) => (
          <div
            className="recipientLine"
            key={recipient + index}
          >
            {recipient}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div css={rowWrapperCss}>
      <div className="left">
        <div css={categoryCss(version)}>{rowData[0].category}</div>
        <div css={recipientsWrapperCss}>{recipientsView(rowData[0])}</div>
      </div>
      <div className="right">
        {rowData[1] && (
          <React.Fragment>
            <div css={categoryCss(version)}>{rowData[1].category}</div>
            <div css={recipientsWrapperCss}>{recipientsView(rowData[1])}</div>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default CategoryItem;
