import { css } from '@emotion/core';

export const wrapperCss = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: ${130 / 16}rem;
  justify-content: center;
  align-items: center;
  background: linear-gradient(136deg, rgba(8, 25, 92, 0.1) 0%, #050524 100%),
    #060528;
  padding: 0 1rem;
  gap: ${12 / 16}rem;
`;

export const textCss = css`
  font-size: 2rem;
  font-weight: 600;
  text-align: center;
  display: flex;
  flex-wrap: nowrap;
`;
