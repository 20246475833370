import { css } from '@emotion/core';
import { FYC_MOBILE_WIDTH } from 'pages/fyc-page/helpers';
import { mediaQuery } from 'utils/styles';

export const wrapperCss = css`
  .title {
    font-size: ${14 / 16}rem;
    font-weight: 500;
  }
  .subtitle {
    font-size: ${12 / 16}rem;
    color: rgba(104, 104, 105, 1);
    margin-bottom: 1rem;
  }

  ${mediaQuery(FYC_MOBILE_WIDTH)} {
    .title {
      font-size: ${16 / 16}rem;
      font-weight: 500;
    }
    .subtitle {
      font-size: ${14 / 16}rem;
      margin-bottom: ${24 / 16}rem;
    }
  }
`;
