/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import {
  wrapperCss,
  titleCss,
  lineCss,
  fycSpanCss,
  containerCss,
  logoContainerCss,
} from './styles';
import { useHistory } from 'react-router-dom';
import { ComponentTypeEnum } from 'models/enums';
import { useFycThemeContext } from 'providers/fyc-theme-provider';

interface PropsInterface {
  pageData: any;
  showCategory?: boolean;
}

interface NavSizeInterface {
  height: string;
  width: string;
}

interface NavSizeOptionsInterface {
  lg?: NavSizeInterface;
  md?: NavSizeInterface;
  sm?: NavSizeInterface;
}

const Navbar: React.FC<PropsInterface> = ({
  pageData,
  showCategory = true,
}) => {
  const history = useHistory();

  const version = useFycThemeContext()?.version;

  const navData = pageData?.components?.find(
    (c) => c.type === ComponentTypeEnum.FYC_TOP_NAVIGATION,
  );

  let showImage = false;
  let size: NavSizeOptionsInterface | null = null;

  if (navData?.size) {
    try {
      size = JSON.parse(navData.size);
    } catch (e) {}
  }

  if (navData?.image?.url && size && size.lg && size.md && size.sm) {
    showImage = true;
  }

  const handleTitleClick = () => {
    if (navData?.actions[0].target && navData?.actions[0].target !== '') {
      history.push(`/${navData?.actions[0].target}`);
    } else {
      history.push('/');
    }
  };

  if (showCategory) {
    return (
      <div css={wrapperCss(version)}>
        <div css={containerCss}>
          {showImage ?
            <span
              onClick={handleTitleClick}
              css={logoContainerCss(size)}
            >
              <img src={navData?.image?.url} />
            </span>
          : <span
              onClick={handleTitleClick}
              css={titleCss(version)}
              style={navData?.fontSize && { fontSize: `${navData?.fontSize}` }}
            >
              {navData?.actions[0].displayValue || ''}
            </span>
          }

          <span css={lineCss(version)} />
          <span css={fycSpanCss(version)}>{navData?.titleText}</span>
        </div>
      </div>
    );
  }

  return (
    <div css={wrapperCss(version)}>
      <div css={containerCss}>
        <span css={fycSpanCss(version)}>{navData?.titleText}</span>
      </div>
    </div>
  );
};

export default Navbar;
