import type { SerializedStyles } from '@emotion/core';
import { css } from '@emotion/core';
import { FYC_TABLET_WIDTH } from 'pages/fyc-page/helpers';
import type { DetailsPartConfigInterface } from 'providers/fyc-theme-component-interfaces';
import { FycConfigsEnum } from 'providers/fyc-theme-component-interfaces';
import type { FycVersionType } from 'providers/fyc-theme-provider';
import {
  ABC,
  DBT,
  DISNEYPLUS,
  DTS,
  FREEFORM,
  FX,
  HULU,
  NATGEO,
  SEARCHLIGHT,
  SEARCHLIGHT_AWARDS,
} from 'providers/fyc-theme-provider';
import { getVersionedComponentConfig } from 'providers/versions/fyc-theme-provider-utils';
import { mediaQuery } from 'utils/styles';

const MDP_MOBILE_WIDTH = 599;

export const wrapperCss = css`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const ulWrapperCss = css`
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  padding-top: 2px;
  margin-top: -2px;
`;

export const contentCss = (version: FycVersionType): SerializedStyles => {
  const customCssConfig = getVersionedComponentConfig<
    DetailsPartConfigInterface['contentCss']
  >({
    version,
    component: FycConfigsEnum.DETAILS_PART_CONFIG,
    selector: 'contentCss',
  });

  const fontWeight = customCssConfig?.fontWeight ?? 'inherit';

  let color = customCssConfig?.color ?? 'white';
  let bg =
    customCssConfig?.background ??
    `linear-gradient(1deg, #01182f 16.24%, #000000 99.17%),
  linear-gradient(
    180.02deg,
    rgba(24, 24, 24, 0.675) -233.5%,
    rgba(135, 104, 32, 0) 99.99%,
    rgba(135, 104, 32, 0) 99.99%
  )`;

  if (version === ABC) {
    bg =
      'linear-gradient(195.37deg, rgba(10, 19, 32, 0.8) 12.21%, rgba(32, 32, 51, 0.8) 89.21%)';
  }

  if (version === FREEFORM) {
    bg = 'linear-gradient(16.55deg, #252525 -23.2%, #000000 100.4%)';
  }

  if (version === DBT || version === DISNEYPLUS) {
    bg = `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(343.11deg, #00D5F4 -139.25%, #0C1D49 -8.2%, #000000 89.84%)`;
  }

  if (version === DTS) {
    bg = `linear-gradient(149.07deg, #000428 -0.03%, #004887 146.68%)`;
  }

  if (version === HULU) {
    bg = `linear-gradient(136.53deg, rgba(4, 4, 5, 0.8) -19.92%, rgba(24, 57, 73, 0.8) 88.86%)`;
  }

  if (version === SEARCHLIGHT || version === SEARCHLIGHT_AWARDS) {
    bg = `linear-gradient(180deg, #EAE4D7 0%, rgba(242, 238, 229, 0.06) 100%)`;
    color = `rgba(38, 38, 38, 1)`;
  }

  if (version === NATGEO) {
    bg = `linear-gradient(341deg, #252525 0%, #000 100%)`;
  }

  if (version === FX) {
    bg = `linear-gradient(154deg, rgba(19, 27, 40, 0.80) -19.27%, rgba(4, 4, 5, 0.80) 53.77%, rgba(32, 32, 51, 0.00) 290.12%)`;
  }

  return css`
    color: ${color};
    width: 100%;
    display: flex;
    justify-content: center;
    border-radius: 6px 6px 0px 0px;
    padding-bottom: ${60 / 16}rem;
    font-weight: ${fontWeight};

    background: ${bg};
    label: contentCss;

    ${mediaQuery(MDP_MOBILE_WIDTH)} {
      width: ${(890 / 1024) * 100}%;
    }

    ${mediaQuery(FYC_TABLET_WIDTH)} {
      width: ${(1178 / 1440) * 100}%;
    }
  `;
};

export const tabsWrapperCss = (hasQuotes: boolean): SerializedStyles => {
  let marginTop = `${23 / 16}rem`;

  if (hasQuotes) {
    marginTop = `0rem`;
  }

  return css`
    width: ${(354 / 375) * 100}%;

    ${mediaQuery(MDP_MOBILE_WIDTH)} {
      width: 100%;
    }

    ${mediaQuery(FYC_TABLET_WIDTH)} {
      width: ${(895 / 1179) * 100}%;
      margin-top: ${marginTop};
    }
  `;
};

export const reactTabsCss = (version: FycVersionType): SerializedStyles => {
  const customCssConfig = getVersionedComponentConfig<
    DetailsPartConfigInterface['reactTabsCss']
  >({
    version,
    component: FycConfigsEnum.DETAILS_PART_CONFIG,
    selector: 'reactTabsCss',
  });

  const fontWeight = customCssConfig?.fontWeight ?? 'inherit';
  const fontFamily = customCssConfig?.fontFamily ?? 'inherit';
  const selectedFontSize = customCssConfig?.selectedFontSize ?? '1rem';

  let border = customCssConfig?.border ?? `2px solid #ffffff`;
  let color = customCssConfig?.color ?? 'white';
  let fontSize = customCssConfig?.fontSize ?? `1rem`;
  let selectedBg =
    customCssConfig?.selectedBg ??
    `linear-gradient(90.04deg, #644d1b 0.71%, #c19a41 120.93%)`;
  let selectFontWeight = customCssConfig?.selectFontWeight ?? 500;
  let selectFontFamily = customCssConfig?.selectFontFamily ?? `inherit`;
  let selectedColor = customCssConfig?.selectedColor ?? `#fff`;
  let selectedTabBg = customCssConfig?.selectedTabBg;

  if (version === ABC) {
    selectedBg = `#D9B148`;
  }

  if (version === FREEFORM) {
    selectedBg = `#FF44E1`;
  }

  if (version === DBT || version === DISNEYPLUS) {
    selectedBg = `#00D5F4`;
    selectFontWeight = 600;
  }

  if (version === DTS) {
    selectedBg = `#D9B148`;
    fontSize = `${14 / 16}rem`;
  }

  if (version === HULU) {
    selectedBg = `#1CE783`;
    selectFontFamily = `Graphik Semibold`;
    selectFontWeight = 600;
    fontSize = `${14 / 16}rem`;
    color = `rgba(255, 255, 255, 0.47)`;
  }

  if (version === SEARCHLIGHT || version === SEARCHLIGHT_AWARDS) {
    selectedBg = `#907137`;
    border = `2px solid rgba(125, 122, 116, 1)`;
    color = `rgba(0, 0, 0, 0.47)`;
    selectedColor = `rgba(38, 38, 38, 1)`;
    selectFontWeight = 600;
    fontSize = `${14 / 16}rem`;
    selectedTabBg = `rgba(201, 201, 201, 0.21)`;
  }

  if (version === NATGEO) {
    selectedBg = `#FC0`;
    selectFontFamily = `Geograph Medium`;
    selectFontWeight = 400;
    fontSize = `${14 / 16}rem`;
  }

  if (version === FX) {
    selectedBg = `#FFF`;
    border = `2px solid rgba(255, 255, 255, 0.47)`;
    selectFontWeight = 600;
    color = `rgba(255, 255, 255, 0.47)`;
    selectedColor = `#FFF`;
  }

  return css`
    label: reactTabsCss;

    .react-tabs__tab {
      user-select: none;
      color: ${color};
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border: none;
      bottom: 0px;
      list-style: none;
      padding: 0;
      height: 100%;
      cursor: pointer;
      font-size: ${fontSize};
      font-family: ${fontFamily};
      font-weight: ${fontWeight};
      margin-right: 0rem;

      @media only screen and (min-width: 1025px) {
        margin-right: 0rem;
      }
    }

    .react-tabs__tab--selected {
      border: none;
      color: ${selectedColor};
      font-weight: ${selectFontWeight};
      font-size: ${selectedFontSize};
      font-family: ${selectFontFamily};
      background: ${selectedTabBg};

      .tab-selected {
        position: absolute;

        background: ${selectedBg};
        border-radius: 2px;

        top: -2px;
        z-index: 100;
      }
    }

    .react-tabs__tab:focus {
      box-shadow: 0;
      border: none;
      outline: none;
    }

    .react-tabs__tab:focus:after {
      content: '';
      position: inherit;
      height: 0px;
      width: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      background: transparent;
      border: none;
    }

    .react-tabs__tab-list {
      white-space: nowrap;
      padding: 0;
      border: none;
      display: flex;
      gap: 25px;
      justify-content: center;
      height: ${45 / 16}rem;

      border-radius: 2px;
      border-top: ${border};

      @media only screen and (min-width: 1025px) {
        margin-top: 0;
      }
    }
  `;
};

export const tabTopCss = css`
  width: 100%;
  height: 4px;
  background: transparent;
  border-radius: 2px;
`;

export const tabTitleCss = (version: FycVersionType): SerializedStyles => {
  const customCssConfig = getVersionedComponentConfig<
    DetailsPartConfigInterface['tabTitleCss']
  >({
    version,
    component: FycConfigsEnum.DETAILS_PART_CONFIG,
    selector: 'tabTitleCss',
  });

  const textTransform = customCssConfig?.textTransform ?? 'uppercase';

  return css`
    padding: 0 8px;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: ${textTransform};
    label: tabTitleCss;
  `;
};
