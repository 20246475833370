import { ComponentTypeEnum } from 'models/enums';
import { matchPath } from 'react-router-dom';
import { getComponentsByType } from 'utils';
import _ from 'lodash';
import type {
  NSBannerComponentInterface,
  NSHeroInterface,
  NSPageComponentInterface,
  NSSeriesComponentInterface,
} from 'models/cms';

export const isDetailsPage = (pathname: string): boolean =>
  !!matchPath(pathname, {
    path: '/movie',
    exact: false,
    strict: false,
  }) ||
  !!matchPath(pathname, {
    path: '/series',
    exact: false,
    strict: false,
  });

export const isMovieDetailsPage = (pathname: string): boolean =>
  !!matchPath(pathname, {
    path: '/movie',
    exact: false,
    strict: false,
  });

export const isSeriesDetailsPage = (pathname: string): boolean =>
  !!matchPath(pathname, {
    path: '/series',
    exact: false,
    strict: false,
  });

type HeroItemType = NonNullable<NSHeroInterface['items']>[number];

interface BannerHeroCombinedInterface extends NSPageComponentInterface {
  autoRotateInterval: number | undefined;
  id: string;
  items: Array<HeroItemType | NSBannerComponentInterface>;
  type: ComponentTypeEnum.BANNER_HERO_COMBINE;
}

type CombineBannerNHeroReturnType = undefined | NSPageComponentInterface[];

export const combineBannerNHero = (
  components: NSPageComponentInterface[],
): CombineBannerNHeroReturnType => {
  if (!components) {
    return;
  }

  const bannerHeroCombine: BannerHeroCombinedInterface = {
    id: 'bannerHeroCombine',
    autoRotateInterval: 0,
    items: [],
    type: ComponentTypeEnum.BANNER_HERO_COMBINE,
  };

  const banners = getComponentsByType(components, ComponentTypeEnum.BANNER) as
    | NSBannerComponentInterface[]
    | null;

  const heroes = getComponentsByType(components, ComponentTypeEnum.HERO) as
    | NSHeroInterface[]
    | null;

  // if only one banner, display Banner component
  if (banners?.length === 1 && (heroes === null || heroes.length === 0)) {
    return components;
  }

  if (banners !== null) {
    for (const banner of banners) {
      bannerHeroCombine.items.push(banner);
    }
  }

  if (heroes !== null) {
    for (const hero of heroes) {
      bannerHeroCombine.autoRotateInterval = hero.autoRotateInterval;
      bannerHeroCombine.items = [
        ...bannerHeroCombine.items,
        ...(hero.items ?? []),
      ];
    }
  }

  const index = components.findIndex(
    (c) =>
      c.type === ComponentTypeEnum.BANNER || c.type === ComponentTypeEnum.HERO,
  );

  if (bannerHeroCombine.items.length === 0) {
    return components;
  }

  const copy = _.cloneDeep(components);

  copy.splice(index, 0, bannerHeroCombine);

  // change BANNER to BANNER_HIDE after combination
  for (const item of copy) {
    if (item.type === ComponentTypeEnum.BANNER) {
      (item as NSBannerComponentInterface).type = ComponentTypeEnum.BANNER_HIDE;
    }
  }

  return copy;
};

export const customComponents = (
  components: NSPageComponentInterface[],
): ReturnType<typeof combineBannerNHero> => {
  return combineBannerNHero(components);
};

export const findEpisodeByPlayIdFromSeason = (
  id: string,
  component: NSSeriesComponentInterface,
) => {
  try {
    const seasons = component.seasons ?? [];
    for (const season of seasons) {
      for (const episode of season.episodes ?? []) {
        const actions = episode.actions;
        for (const a of actions ?? []) {
          if (a.type === 'PLAY' && a.target === id) {
            return episode;
          }
        }
      }
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    return null;
  }
};
