import type { SerializedStyles } from '@emotion/core';
import { css } from '@emotion/core';
import theme from 'styles/theme';
import { useWindowSize } from './hooks';

/**
 * Used to extended components by adding optional styles. Default styles and
 * extended styles should be using Emotion's syntax.
 * @param styles (e.g {'color: red;', 'font-weight: 700;', 'mediaCss', theme})
 */
export interface GenerateStyleInterface {
  defaultStyle: any;
  extendedStyles: any;
  key: any;
  theme: any;
}
export interface HeroCssInterface {
  hasGradient?: boolean;
  heroImage: any;
  isLazyLoaded?: any;
  settings: HeroSettingsInterface;
  styles?: SerializedStyles;
}

export interface HeroSettingsInterface {
  desktopHeight?: number;
  desktopWidth?: number;
  lg: { height: number; ratio: string };
  md: { height: number; ratio: string };
  xs: { height: number; ratio: string };
}

/**
 * get css media query string by width
 * @param width (e.g. 1024)
 * @returns string (e.g. @media only screen and (min-width: 1024px)`)
 */
export const mediaQuery = (width: number): string =>
  `@media only screen and (min-width: ${width}px)`;

// only screen small and equal to width
export const mediaQueryV2 = (width: number): string =>
  `@media only screen and (max-width: ${width - 1}px)`;

export const generateStyle = ({
  defaultStyle,
  extendedStyles,
  key,
  theme,
}: GenerateStyleInterface) => {
  const cssArr = [defaultStyle];

  if (extendedStyles && extendedStyles[key]) {
    extendedStyles[key] instanceof Function ?
      cssArr.push(extendedStyles[key](theme))
    : cssArr.push(extendedStyles[key]);
  }
  return css(cssArr);
};

export const generateHeroBackgroundCss = (
  heroImage,
  r: string,
  w: number,
  isLazyLoaded: boolean,
  hasGradient?: boolean,
) => {
  return `background-image: ${
    hasGradient ?
      'linear-gradient(-90deg,rgba(0, 0, 0, 0) 0%,rgba(0, 0, 0, 0.4) 59%,rgba(0, 0, 0, 0.73) 100%),'
    : ''
  } ${
    heroImage && !isLazyLoaded ? `url(${heroImage.url}?r=${r}&w=${w});` : ';'
  }`;
};

export const generateHeroStyle = ({
  heroImage,
  settings,
  styles,
  hasGradient,
  isLazyLoaded,
}: HeroCssInterface) => {
  const defaultCss = `
    ${generateHeroBackgroundCss(
      heroImage,
      settings.xs.ratio,
      630,
      isLazyLoaded,
      hasGradient,
    )};
    background-size: cover;
    background-position: center;
    height: ${settings.xs.height}rem;
    padding: 0 1rem;

    ${mediaQuery(theme.breakpoints.md)} {
      ${generateHeroBackgroundCss(
        heroImage,
        settings.md.ratio,
        1024,
        isLazyLoaded,
        hasGradient,
      )}
      height: ${settings.md.height}rem;
    }
    ${mediaQuery(theme.breakpoints.lg)} {
      ${generateHeroBackgroundCss(
        heroImage,
        settings.lg.ratio,
        1920,
        isLazyLoaded,
        hasGradient,
      )}
      height: ${settings.lg.height}rem;
    }`;

  return generateStyle({
    defaultStyle: defaultCss,
    extendedStyles: styles,
    key: 'heroCss',
    theme,
  });
};

export const titleCss = (styles?: SerializedStyles) => {
  const style = `
    ${mediaQuery(theme.breakpoints.xl)} {
      font-size: 7rem;
    }
  `;
  const defaultCss = [{ ...theme.typography.headline }, style];

  return generateStyle({
    defaultStyle: defaultCss,
    extendedStyles: styles,
    key: 'titleCss',
    theme,
  });
};

export const useHeroSettings = (): HeroSettingsInterface | undefined => {
  const calculatedARHeight = 9 / 16; // height calculated based on a 16:9 aspect ratio
  const { windowWidth } = useWindowSize();

  if (!windowWidth) {
    return;
  }
  const desktopHeight: number = windowWidth * calculatedARHeight;
  const REM = 16; // need to convert pixels to rem since styles are measured in rem

  return {
    desktopHeight: desktopHeight,
    xs: { ratio: '2/3', height: 630 / REM },
    md: { ratio: '4/3', height: 630 / REM },
    lg: {
      ratio: '16/9',
      height: desktopHeight / REM,
    },
  };
};

const BASE_FONT_SIZE = 16;

export const convertPixelsToRems = (pixels: number): string =>
  `${pixels / BASE_FONT_SIZE}rem`;
