/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useEffect, useRef, useState } from 'react';
import {
  contentWrapperCss,
  labelCss,
  checkboxCss,
  messageWrapperCss,
  imgWrapperCss,
} from './styles';
import infoWhite from 'public/images/info-white.svg';
import Markdown from 'react-markdown/with-html';

interface PropsInterface {
  agreeText: string;
  content: string;
  header?: string | null;
  isChecked: boolean;
  readOnly?: boolean;
  subHeader?: string | null;
  toggleCheck: any;
}

export const TermsContent: React.FC<PropsInterface> = ({
  content,
  header,
  subHeader,
  isChecked,
  toggleCheck,
  agreeText,
  readOnly,
}) => {
  const [isScrolledToBottom, setIsScrolledToBottom] = useState<boolean>(false);

  const contentRef = useRef<HTMLDivElement | null>(null);

  const termsContent = (
    <div
      className="terms-content"
      ref={contentRef}
    >
      <div className="terms-title">{header && header}</div>
      <p data-testid="terms-subtitle">{subHeader && subHeader}</p>
      <Markdown
        source={content}
        escapeHtml={false}
      />
    </div>
  );

  useEffect(() => {
    // initial check when ref current is available
    if (!contentRef.current) {
      return;
    }
    setIsScrolledToBottom(
      contentRef.current.scrollHeight -
        contentRef.current.clientHeight -
        contentRef.current.scrollTop <=
        1,
    );

    const handleScroll = () => {
      if (!contentRef.current) {
        return;
      }

      const scrolledHeight =
        contentRef.current.scrollHeight - contentRef.current.clientHeight;
      setIsScrolledToBottom((prev) => {
        if (prev) {
          return prev;
        }

        if (!contentRef.current) {
          return false;
        }
        return scrolledHeight - contentRef.current.scrollTop <= 1;
      });
    };

    contentRef.current.addEventListener('scroll', handleScroll);

    const cleanUp = () => {
      if (!contentRef.current) {
        return;
      }

      contentRef.current.removeEventListener('scroll', handleScroll);
    };

    return cleanUp;
  }, []);

  return (
    <div>
      <div css={contentWrapperCss}>{termsContent}</div>
      {!readOnly && (
        <div>
          <div css={messageWrapperCss}>
            <div css={imgWrapperCss}>
              <img src={infoWhite} />
            </div>
            Please read the entire Terms and Conditions before checking the box
            below.
          </div>
          <label
            css={labelCss(isScrolledToBottom)}
            className="terms-label"
          >
            <input
              css={checkboxCss}
              onChange={toggleCheck}
              checked={isChecked}
              type="checkbox"
              name="terms"
              disabled={!isScrolledToBottom}
            />
            <span className="checkmark" />
            {agreeText}
          </label>
        </div>
      )}
    </div>
  );
};
