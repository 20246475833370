import type {
  NSFycMovieMarqueeComponentInterface,
  NSFycSeriesComponentInterface,
  NSGoogleTagComponentInterface,
  NSGoogleTagEventComponentInterface,
} from 'models/cms';
import {
  ComponentTypeEnum,
  GoogleComponentTypesEnum,
  GoogleTagEventTypeEnum,
} from 'models/enums';
import { DetailsPage, FYCPage } from 'pages/fyc-page/helpers';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { fycDataSelector } from 'store/slices/fyc';
import { useStringsContext } from './strings-provider';

export const GTagsContext = createContext(null);

export const useGTagsProvider = () => useContext(GTagsContext);

export const GTagsProvider: React.FC = ({ children }) => {
  const { pathname } = useLocation();
  const { components } = useSelector(fycDataSelector);
  const { getStringByKey } = useStringsContext();
  const gTagsScriptRef = useRef(false);

  const extractGtagParams = (params: string | undefined) => {
    let obj: Record<string, unknown> | undefined;

    if (!params) {
      return;
    }

    try {
      obj = JSON.parse(params);
    } catch (error) {}

    if (!obj) {
      return;
    }

    return obj;
  };

  const addToDataLayer = useCallback(
    (tags: string[] | undefined) => {
      const uniqTags = tags?.filter((v, i) => tags.indexOf(v) === i) || [];

      for (const tag of uniqTags) {
        let config: Record<string, unknown> | undefined;

        const tagJSONString = getStringByKey(tag);

        if (!tagJSONString) {
          return;
        }
        try {
          config = JSON.parse(tagJSONString);
        } catch (error) {}

        if (!config) {
          return;
        }

        const gtagParamsConfig =
          (config['gtagParams'] as Record<string, string> | undefined) ?? {};

        if (config.type === GoogleTagEventTypeEnum.DC) {
          gtag('event', 'conversion', {
            allow_custom_scripts: true,
            send_to: config.send_to,
            ...gtagParamsConfig,
          });
        }

        if (config.type === GoogleTagEventTypeEnum.AW) {
          gtag('event', 'conversion', {
            send_to: config.send_to,
            ...gtagParamsConfig,
          });
        }
      }
    },
    [getStringByKey],
  );

  const addTagsFromEntitlement = useCallback(() => {
    if (!!DetailsPage(pathname)) {
      const component = components?.find(
        (c) =>
          c.type === ComponentTypeEnum.FYC_SERIES ||
          c.type === ComponentTypeEnum.FYC_MOVIE_MARQUEE,
      ) as
        | NSFycMovieMarqueeComponentInterface
        | NSFycSeriesComponentInterface
        | undefined;

      if (component?.type === ComponentTypeEnum.FYC_SERIES) {
        try {
          const tags =
            component.seasons?.[0]?.episodes?.[0]?.entitlement?.tags?.filter(
              (t) => t.includes('GTAG'),
            );
          addToDataLayer(tags);
        } catch (error) {
          console.error(error);
        }
      }

      if (component?.type === ComponentTypeEnum.FYC_MOVIE_MARQUEE) {
        try {
          const tags = component.entitlement?.tags?.filter((t) =>
            t.includes('GTAG'),
          );
          addToDataLayer(tags);
        } catch (error) {
          console.error(error);
        }
      }
    } else {
      const eventsArr =
        (components?.filter(
          (c) => c.type === GoogleComponentTypesEnum.GOOGLE_TAGS_EVENT,
        ) as NSGoogleTagEventComponentInterface[]) || [];

      for (const config of eventsArr) {
        if (config.eventType === GoogleTagEventTypeEnum.DC) {
          gtag('event', 'conversion', {
            allow_custom_scripts: true,
            send_to: config.sendTo,
            ...extractGtagParams(config['gtagParams']),
          });
        }

        if (config.eventType === GoogleTagEventTypeEnum.AW) {
          gtag('event', 'conversion', {
            send_to: config.sendTo,
            ...extractGtagParams(config['gtagParams']),
          });
        }
      }
    }
  }, [addToDataLayer, components, pathname]);

  const handleScriptLoaded = useCallback(
    (gTagsComponent: NSGoogleTagComponentInterface) => {
      gTagsScriptRef.current = true;

      gtag('js', new Date());

      try {
        if (gTagsComponent.configs) {
          const configs = JSON.parse(gTagsComponent.configs);
          for (const config of configs) {
            gtag('config', config);
          }
        }
      } catch (err) {}

      addTagsFromEntitlement();
    },
    [addTagsFromEntitlement],
  );

  useEffect(() => {
    const isFycPage = !!FYCPage(pathname);

    if (!isFycPage || !components || !components.length) {
      return;
    }

    //To check if UserInterface has opted out, see if group C0004 is active. If it isn't, they are opt-out
    if (
      !window.OnetrustActiveGroups ||
      !window.OnetrustActiveGroups?.includes('C0004')
    ) {
      //UserInterface opt-out. We cannot load gtags
      console.info('UserInterface opt-out. G-Tags will not be loaded.');
      return;
    }

    const gTagsComponent = components.find(
      (c) => c.type === ComponentTypeEnum.GOOGLE_TAGS,
    ) as NSGoogleTagComponentInterface | undefined;

    const head = document.getElementsByTagName('head')[0];
    const script = document.createElement('script');

    // load g_tags scripts when GOOGLE_TAGS component found and has not loaded yet
    if (gTagsComponent && !gTagsScriptRef?.current) {
      const url = gTagsComponent.script_src;
      script.src = url || '';
      script.async = true;
      script.onload = () => handleScriptLoaded(gTagsComponent);
      head.appendChild(script);
      return;
    }

    // if g_tags scripts has been loaded
    if (gTagsScriptRef?.current) {
      addTagsFromEntitlement();
    }
  }, [addTagsFromEntitlement, components, handleScriptLoaded, pathname]);

  return <GTagsContext.Provider value={null}>{children}</GTagsContext.Provider>;
};
