/** @jsx jsx */
import type { SerializedStyles } from '@emotion/core';
import { css, jsx } from '@emotion/core';
import type { VideoActionsNdaInterface } from 'components/video-actions';
import type { NSEntitlementItemInterface } from 'models/cms';
import type { Dispatch, SetStateAction } from 'react';
import { useCallback } from 'react';
import React, { useEffect, useRef, useState } from 'react';
import NsImage from 'components/ns-component/ns-image';
import type { ProgressStateInterface } from 'store/slices/progress';
import {
  heroWrapperCss,
  imgWrapperCss,
  nonHeroWrapperCss,
  nonHoverLockWrapperCss,
  hoverLockWrapperCss,
} from './styles';
import HeroEntitlement from './hero-entitlement';
import { getPageTarget } from 'utils/fyc';
import NsLink from 'components/ns-component/ns-link';
import { UnlockButton } from 'components/details-page-component/details-page-lock-view';
import theme from 'styles/theme';

export interface RiverViewEntitlementItemNdaPropsInterface
  extends VideoActionsNdaInterface {
  handleAction: (template: string) => Promise<void>;
  isLocked: boolean;
}

interface PropsInterface {
  index: number;
  item: NSEntitlementItemInterface;
  ndaProps: RiverViewEntitlementItemNdaPropsInterface;
  onlyOneItem: boolean;
  progressData: ProgressStateInterface;
  rootContainerCss: SerializedStyles;
  setToggleIndex: Dispatch<SetStateAction<number | null>>;
  toggleIndex: number | null;
  totalTwoItems: boolean;
}

const RiverViewEntitlementItem: React.FC<PropsInterface> = ({
  item,
  index,
  ndaProps,
  setToggleIndex,
  toggleIndex,
  onlyOneItem,
  totalTwoItems,
}) => {
  const hoverRef = useRef<HTMLDivElement | null>(null);
  const [heroState, setHeroState] = useState(onlyOneItem);

  const handleResize = useCallback(() => {
    if (window.innerWidth <= 1024) {
      setHeroState(false);
      return;
    }

    if (onlyOneItem) {
      setHeroState(true);
    }
  }, [onlyOneItem]);

  const handleUnlockButtonClick = () => {
    if (item.entitlement?.ndaTemplate) {
      ndaProps.handleAction(item.entitlement?.ndaTemplate);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  useEffect(() => {
    if (onlyOneItem) {
      return;
    }

    let timer: number | null;

    const handleMouseMove = (event: MouseEvent) => {
      if (window.innerWidth < theme.breakpoints.lg) {
        return;
      }

      const div = hoverRef?.current?.getBoundingClientRect();
      if (!div) {
        return;
      }

      const isMouseInside =
        event.clientX >= div.left &&
        event.clientX <= div.right &&
        event.clientY <= div.bottom &&
        event.clientY >= div.top;

      if (isMouseInside) {
        if (heroState) {
          return;
        }
        if (timer) {
          return;
        }

        timer = window.setTimeout(() => {
          setHeroState(true);
          setToggleIndex(index);
        }, 1000);
      } else {
        setHeroState(false);
        if (toggleIndex === index) {
          setToggleIndex(null);
        }
        if (timer) {
          window.clearTimeout(timer);
          timer = null;
        }
      }
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      if (timer) {
        window.clearTimeout(timer);
        timer = null;
      }
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, [heroState, index, onlyOneItem, setToggleIndex, toggleIndex]);

  return (
    <div
      data-testid="NsHeroContainer-item"
      key={item.id}
      css={imgWrapperCss(heroState, index, totalTwoItems)}
      ref={hoverRef}
      className={item.title ?? undefined}
    >
      {/* heroState controls which one to show */}
      <div
        data-testid={item.title}
        css={heroWrapperCss(heroState, onlyOneItem)}
      >
        <HeroEntitlement
          item={item}
          ndaProps={ndaProps}
        />
        {ndaProps.isLocked && (
          <div css={hoverLockWrapperCss}>
            {item.titleTreatmentImage ?
              <NsImage
                alt={item.titleTreatmentImage.alt}
                aspectRatio={item.titleTreatmentImage.aspectRatioFractions[0]}
                url={item.titleTreatmentImage.url}
                width={1024}
              />
            : item.title}
            <div
              css={css`
                font-weight: ${18 / 16}rem;
                font-weight: 600;
                margin: 40px 0 31px 0;
              `}
            >
              Locked Content
            </div>
            <div
              css={css`
                max-width: ${466 / 16}rem;
                text-align: center;
              `}
            >
              To view all exclusive content, please click on the button below to
              sign and agree to our terms and conditions.
            </div>
            <div
              css={css`
                position: relative;
                height: 41px;
                width: 100%;
                margin-top: ${20 / 16}rem;
              `}
            >
              <UnlockButton
                closedIcon={true}
                onclick={handleUnlockButtonClick}
              />
            </div>
          </div>
        )}
      </div>

      <div
        data-testid="protected-hero-image"
        css={nonHeroWrapperCss(heroState, onlyOneItem)}
      >
        <NsLink to={`/${getPageTarget(item)}`}>
          <NsImage
            alt={item.keyArtImage.alt}
            aspectRatio={'2/3'}
            url={item.keyArtImage.url}
            width={1024}
            isBackground={true}
            hasGradientOverlay={false}
          />
        </NsLink>
        {ndaProps.isLocked && (
          <div
            className="protect-layer"
            css={nonHoverLockWrapperCss}
          >
            <UnlockButton
              closedIcon={true}
              onclick={handleUnlockButtonClick}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default RiverViewEntitlementItem;
