import type { SerializedStyles } from '@emotion/core';
import { css } from '@emotion/core';
import type { AccessCodeModalConfigInterface } from 'providers/fyc-theme-component-interfaces';
import { FycConfigsEnum } from 'providers/fyc-theme-component-interfaces';
import type { FycVersionType } from 'providers/fyc-theme-provider';
import {
  DTS,
  FX,
  HULU,
  NATGEO,
  SEARCHLIGHT,
  SEARCHLIGHT_AWARDS,
} from 'providers/fyc-theme-provider';
import { getVersionedComponentConfig } from 'providers/versions/fyc-theme-provider-utils';

export const wrapperCss = (version: FycVersionType): SerializedStyles => {
  const customCssConfig = getVersionedComponentConfig<
    AccessCodeModalConfigInterface['wrapperCss']
  >({
    version,
    component: FycConfigsEnum.ACCESS_CODE_MODAL_CONFIG,
    selector: 'wrapperCss',
  });

  let background = customCssConfig?.background ?? `#27283c`;
  let maskBg = customCssConfig?.maskBg ?? 'none';

  if (version === SEARCHLIGHT || version === SEARCHLIGHT_AWARDS) {
    background = `rgba(22, 22, 22, 1)`;
  }

  if (version === NATGEO) {
    background = `rgba(22, 22, 22, 1)`;
    maskBg = `rgba(255, 255, 255, 0.07)`;
  }

  if (version === FX) {
    background = `rgba(27, 27, 32, 1)`;
    maskBg = `rgba(255, 255, 255, 0.07)`;
  }

  return css`
    display: flex;
    flex-direction: column;
    width: ${330 / 16}rem;
    height: ${237 / 16}rem;
    background: ${background};
    border-radius: ${4 / 16}rem;
    position: relative;
    label: wrapperCss;

    .mask {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: ${maskBg};
    }
  `;
};

export const topPartCss = css`
  z-index: 1;
  width: ${(298 / 330) * 100}%;
  margin: ${17 / 16}rem auto 0 auto;
  display: flex;
  font-size: ${18 / 16}rem;
  font-weight: 500;

  .code-modal-title {
    flex: 1;
  }

  .code-modal-close-button {
    width: ${14 / 16}rem;
    width: ${14 / 16}rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
`;

export const contentPartCss = css`
  .error-text {
    margin-left: 0;
  }

  p {
    margin-top: ${24 / 16}rem;
    margin-left: ${19 / 16}rem;
    font-size: ${14 / 16}rem;
    font-weight: 400;
  }
`;

export const accessCodeCss = css`
  width: ${(291 / 330) * 100}%;
  margin: auto;
`;

export const modalExtendCss = (version: FycVersionType): SerializedStyles => {
  const customCssConfig = getVersionedComponentConfig<
    AccessCodeModalConfigInterface['modalExtendCss']
  >({
    version,
    component: FycConfigsEnum.ACCESS_CODE_MODAL_CONFIG,
    selector: 'modalExtendCss',
  });

  let background = customCssConfig?.background ?? '';

  if (version === DTS || version === NATGEO) {
    background = `background: rgba(0, 0, 0, 0.5);`;
  }

  if (version === HULU) {
    background = `background: rgba(30, 30, 30, 0.644641);`;
  }

  if (version === SEARCHLIGHT || version === SEARCHLIGHT_AWARDS) {
    background = `background: rgba(108, 108, 108, 0.5);`;
  }

  if (version === FX) {
    background = `background: rgba(30, 30, 30, 0.64);`;
  }

  return css`
    padding: 0 !important;
    ${background}
    label: modalExtendCss;
  `;
};
