import { mediaQuery, generateStyle } from 'utils/styles';
import theme from 'styles/theme';
import type { SerializedStyles } from '@emotion/core';

export const goBackCss = (styles?: SerializedStyles) => {
  const defaultStyle = `
  text-align: left;
  margin: 3rem 0 3rem -0.5rem;

  ${mediaQuery(theme.breakpoints.lg)} {
    margin-left: 0.5rem;
  }
`;

  return generateStyle({
    defaultStyle,
    extendedStyles: styles,
    key: 'goBackCss',
    theme,
  });
};

export const rightPanelCss = (
  hasAppBar?: boolean,
  styles?: SerializedStyles,
) => {
  const paddingTop = hasAppBar ? '3.75rem' : 0;
  const defaultStyle = `
    position: relative;
    padding: ${paddingTop} 9%;
    ${mediaQuery(theme.breakpoints.md)} {
      padding: ${paddingTop} 10%;
    }
    ${mediaQuery(theme.breakpoints.lg)} {
      padding: ${paddingTop} 8.33%;
      border: none;
    }
  `;

  return generateStyle({
    defaultStyle,
    extendedStyles: styles,
    key: 'rightPanelCss',
    theme,
  });
};

export const footerContainerCss = (styles?: SerializedStyles) => {
  const defaultStyle = `
  /* overwrite default short device fix */
  @media only screen and (max-height: 640px) {
    position: absolute;
    margin: 0;
  }
  padding: 1.5rem ${theme.padding.footer.sides.xs};
  ${mediaQuery(theme.breakpoints.md)} {
    padding: 1.5rem ${theme.padding.footer.sides.md};
  }
  ${mediaQuery(theme.breakpoints.lg)} {
    padding: 1.5rem ${theme.padding.footer.sides.lg};
  }
`;

  return generateStyle({
    defaultStyle,
    extendedStyles: styles,
    key: 'footerContainerCss',
    theme,
  });
};
