import type { DRMConfig, PlayerAPI, SourceConfig } from 'bitmovin-player';
import { HttpResponseType, Player, ViewMode } from 'bitmovin-player';
import Bowser from 'bowser';
import { KeyCodesEnum } from 'models/enums';
import { LoginFailureCodesEnum } from 'models/enums';
import { loginFailed, refreshTokens } from 'store/slices/user';
import type { TokenInfoInterface } from 'utils/hooks';
import type { Dispatch } from 'redux';

export const CAST_CUSTOM_MESSAGE_NAMESPACE = 'urn:x-cast:custommessage';

export class CustomPlayer extends Player {
  iOS(): boolean {
    return this.getAgent().os.name === 'iOS';
  }

  getAgent(): Bowser.Parser.ParsedResult {
    return Bowser.parse(window.navigator.userAgent);
  }

  // override Player class to return false for fullscreen on iOS
  // disable PictureInPicture
  isViewModeAvailable(viewMode: ViewMode): boolean {
    return (
        (viewMode === ViewMode.Fullscreen && this.iOS()) ||
          viewMode === ViewMode.PictureInPicture
      ) ?
        false
      : super.isViewModeAvailable(viewMode);
  }

  // override Player class to disable AirPlay in Safari
  isAirplayAvailable(): boolean {
    return false;
  }
}

export const detectFairPlay = (): boolean =>
  ('WebKitMediaKeys' in window &&
    window.WebKitMediaKeys.isTypeSupported &&
    window.WebKitMediaKeys.isTypeSupported('com.apple.fps.1_0', 'video/mp4')) ||
  false;

export const isMediaSourceSupported = (mediaType: string): boolean => {
  return (
    ('MediaSource' in window && MediaSource.isTypeSupported(mediaType)) ||
    ('WebKitMediaSource' in window &&
      window.WebKitMediaSource.isTypeSupported(mediaType))
  );
};

interface OptionsInterface {
  accessToken: string;
  asset: any;
  bookmark?: any;
  isVPP: boolean;
  sessionId: string;
}

const configureFairPlay = (
  asset: any,
  accessToken: string,
  sessionId: string,
  isVPP: boolean,
): DRMConfig => {
  const config = {
    fairplay: {
      certificateURL: asset.drm.fairplayCert,
      headers: undefined,
      prepareMessage: undefined,
      licenseResponseType: HttpResponseType.TEXT,
      prepareLicenseAsync: undefined,

      getLicenseServerUrl: (skdUrl) => {
        const keyId = skdUrl.match(/keyId=([^&]+)/i)[1];
        const licenseUrl = new URL(asset.drm.fairplay);
        licenseUrl.searchParams.append('keyId', keyId);
        licenseUrl.searchParams.append('playbackSessionId', sessionId);
        return licenseUrl.toString();
      },
      prepareContentId: (url) => {
        return url.match(/contentId=([^&]+)/i)[1];
      },
    },
  };
  if (!isVPP) {
    /* @ts-ignore TODO: TS2322: Type { Authorization: string; } is not assignable to type undefined. */
    config.fairplay.headers = { Authorization: `Bearer ${accessToken}` };
  } else {
    config.fairplay.licenseResponseType = HttpResponseType.BLOB;
    /* @ts-ignore TODO: TS2322: Type { content-type: string; } is not assignable to type undefined. */
    config.fairplay.headers = { 'content-type': 'application/octet-stream' };
    /* @ts-ignore TODO: TS2322: Type (event: any) => Blob is not assignable to type undefined. */
    config.fairplay.prepareMessage = (event) => {
      return new Blob([event.message], { type: 'application/octet-binary' });
    };
    /* @ts-ignore TODO: TS2322: Type (ckc: any) => Promise<unknown> is not assignable to type undefined. */
    config.fairplay.prepareLicenseAsync = (ckc) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.addEventListener('loadend', () => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const array = new Uint8Array(reader.result);
          resolve(array);
        });
        reader.addEventListener('error', () => {
          reject(reader.error);
        });
        reader.readAsArrayBuffer(ckc);
      });
    };
  }
  return config;
};

const configureWidevine = (
  asset: any,
  accessToken: string,
  sessionId: string,
  isVPP: boolean,
): any => {
  const licenseUrl = new URL(asset.drm.widevine);
  licenseUrl.searchParams.append('playbackSessionId', sessionId);
  const config = {
    widevine: {
      LA_URL: licenseUrl.toString(),
      headers: undefined,
    },
  };
  if (!isVPP) {
    /* @ts-ignore TODO: TS2322: Type { Authorization: string; } is not assignable to type undefined. */
    config.widevine.headers = { Authorization: `Bearer ${accessToken}` };
  }
  return config;
};

export const configureSource = ({
  asset,
  bookmark,
  accessToken,
  sessionId,
  isVPP,
}: OptionsInterface): SourceConfig => {
  const hlsUrl = new URL(asset.url);

  const config: SourceConfig = {
    hls: hlsUrl.toString(),
  };

  // assign the start position from the bookmark
  if (bookmark?.position) {
    config.options = {
      startOffset: bookmark.position,
    };
  }

  config.drm =
    detectFairPlay() ?
      configureFairPlay(asset, accessToken, sessionId, isVPP)
    : configureWidevine(asset, accessToken, sessionId, isVPP);

  return config;
};

export const exitPlayerOnEsc = (
  e: KeyboardEvent,
  playerRef,
  closePlayer,
): void => {
  // don't close the player when we're in fullscreen mode
  if (
    playerRef.current?.getViewMode() !== ViewMode.Fullscreen &&
    (e.key === KeyCodesEnum.ESCAPE_KEY || e.code === KeyCodesEnum.ESCAPE_KEY)
  ) {
    closePlayer();
  }
};

export const verifyAccessToken = ({
  runtimeEnd,
  decodedToken,
  refreshToken,
  player,
  dispatch,
  failMessage,
}: {
  decodedToken: TokenInfoInterface;
  dispatch: Dispatch<any>;
  failMessage: string | undefined;
  player: PlayerAPI;
  refreshToken: string | null | undefined;
  runtimeEnd: number;
}) => {
  // kick user out of playback if access token expires before end of runtime
  if (decodedToken?.exp < runtimeEnd && !refreshToken) {
    player.pause();
    dispatch(
      loginFailed({
        message: failMessage,
        code: LoginFailureCodesEnum.SESSION_EXPIRED,
      }),
    );
  }

  // get new tokens if access token expires before total runtime and we have a refresh token
  if (decodedToken?.exp < runtimeEnd && refreshToken) {
    dispatch(refreshTokens());
  }
};

export const getCurrentCastSession = () =>
  window.cast.framework.CastContext.getInstance().getCurrentSession();
