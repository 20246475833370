import { useEffect, useRef, useState } from 'react';
import { bookmarkApi, sendVPPApi, sendVPPApiWithRetry } from 'api';
import type { PlaybackEvent, PlayerAPI, SeekEvent } from 'bitmovin-player';
import { PlayerEvent } from 'bitmovin-player';
import type { NSPlayDataType } from 'models/cms';
import {
  HttpStatusCodeEnum,
  PlayerStatesEnum,
  PlaySourceEnum,
} from 'models/enums';
import { PlayerEmitterEventEnum } from 'models/interfaces/player-provider';
import type { ConcurrencyConfigInterface } from './use-concurrency';
import {
  ConcurrencyError,
  ShouldRefetchPlayerDataError,
} from './use-concurrency';
import { verifyAccessToken } from '../helpers';
import { useDispatch, useSelector } from 'react-redux';
import { tokenSelector } from 'store/slices/user';
import { useDecodedJwt } from 'utils/hooks';
import { STRING_KEYS, useStringsContext } from 'providers/strings-provider';
import type { TokenInfoInterface } from 'utils/hooks';
import type { Dispatch } from 'redux';

export default function useConcurrencyVPP(config: ConcurrencyConfigInterface) {
  const { data, error, isFycPublic, player, token } = config;

  const dispatch = useDispatch();
  const { refreshToken } = useSelector(tokenSelector);
  const { getStringByKey } = useStringsContext();
  const failMessage = getStringByKey(STRING_KEYS.AUTH_LOGIN_EXPIRED) ?? '';
  const decodedToken = useDecodedJwt();

  const concurrencyServiceRef = useRef<VPPConcurrencyService | null>(null);
  const [concurrencyServiceReady, setConcurrencyServiceReady] = useState(false);

  useEffect(() => {
    if (isFycPublic || !data || !player || data.source !== PlaySourceEnum.VPP) {
      return;
    }

    concurrencyServiceRef.current = new VPPConcurrencyService({
      data,
      dispatch,
      error,
      failMessage,
      player,
    });

    setConcurrencyServiceReady(true);

    return () => {
      concurrencyServiceRef.current?.destroy();
      concurrencyServiceRef.current = null;
      setConcurrencyServiceReady(false);
    };
  }, [
    data,
    dispatch,
    error,
    failMessage,
    isFycPublic,
    player,
    setConcurrencyServiceReady,
  ]);

  // re-actively set the tokens without triggering a useEffect cleanup that would destroy and
  // reconstruct the concurrency service
  useEffect(() => {
    if (concurrencyServiceReady) {
      concurrencyServiceRef.current?.setDecodedToken(decodedToken);
    }
  }, [concurrencyServiceReady, decodedToken]);

  useEffect(() => {
    if (concurrencyServiceReady) {
      concurrencyServiceRef.current?.setToken(token);
    }
  }, [concurrencyServiceReady, token]);

  useEffect(() => {
    if (concurrencyServiceReady) {
      concurrencyServiceRef.current?.setRefreshToken(refreshToken);
    }
  }, [concurrencyServiceReady, refreshToken]);

  return concurrencyServiceRef;
}

class VPPConcurrencyService {
  private data: NSPlayDataType;
  private decodedToken: TokenInfoInterface | null;
  private dispatch: Dispatch<any>;
  private error: (error: Error) => void;
  private failMessage: string;
  private interval: number;
  private intervalId: number | null;
  private playbackStarted: boolean;
  private player: PlayerAPI;
  private playerStatus: PlayerStatesEnum | undefined;
  private refreshToken: string | null | undefined;
  private token: string | null | undefined;

  constructor({
    data,
    dispatch,
    error,
    failMessage,
    player,
  }: {
    data: NSPlayDataType;
    dispatch: Dispatch<any>;
    error: (error: Error) => void;
    failMessage: string;
    player: PlayerAPI;
  }) {
    this.data = data;
    this.dispatch = dispatch;
    this.error = error;
    this.failMessage = failMessage;
    this.playbackStarted = false;
    this.player = player;

    this.clearInterval = this.clearInterval.bind(this);
    this.delete = this.delete.bind(this);
    this.handleEnd = this.handleEnd.bind(this);
    this.handlePaused = this.handlePaused.bind(this);
    this.handlePlay = this.handlePlay.bind(this);
    this.handlePlaying = this.handlePlaying.bind(this);
    this.handleSeeked = this.handleSeeked.bind(this);

    this.player.on(PlayerEvent.CastStart, this.handleEnd);
    this.player.on(PlayerEvent.CastStarted, this.handleEnd);
    this.player.on(PlayerEvent.CastStopped, this.handleEnd);
    this.player.on(PlayerEvent.Destroy, this.handleEnd);
    this.player.on(PlayerEvent.Error, this.clearInterval);
    this.player.on(PlayerEvent.Paused, this.handlePaused);
    this.player.on(PlayerEvent.Play, this.handlePlay);
    this.player.on(PlayerEvent.Playing, this.handlePlaying);
    this.player.on(PlayerEvent.Seeked, this.handleSeeked);

    this.interval = 30000;

    if (
      this.data.source === PlaySourceEnum.VPP &&
      Number.isFinite(this.data.concurrencyPeriod) &&
      this.data.concurrencyPeriod < this.interval
    ) {
      this.interval = this.data.concurrencyPeriod;
    }

    // Try to delete the session ID before the window is unloaded
    window.addEventListener('beforeunload', this.delete);
  }

  setDecodedToken(decodedToken: TokenInfoInterface | null) {
    this.decodedToken = decodedToken;
  }

  setToken(token: string | null | undefined) {
    this.token = token;
  }

  setRefreshToken(refreshToken: string | null | undefined) {
    this.refreshToken = refreshToken;
  }

  destroy() {
    this.clearInterval();

    // If you attempt to remove listeners after the bitmovin player (PlayerAPI) has been destroyed,
    // it throws an exception. If its destroyed the listeners are already removed, so this is just a
    // failsafe.
    try {
      this.player.off(PlayerEvent.CastStart, this.handleEnd);
      this.player.off(PlayerEvent.CastStarted, this.handleEnd);
      this.player.off(PlayerEvent.CastStopped, this.handleEnd);
      this.player.off(PlayerEvent.Destroy, this.handleEnd);
      this.player.off(PlayerEvent.Error, this.clearInterval);
      this.player.off(PlayerEvent.Paused, this.handlePaused);
      this.player.off(PlayerEvent.Play, this.handlePlay);
      this.player.off(PlayerEvent.Playing, this.handlePlaying);
      this.player.off(PlayerEvent.Seeked, this.handleSeeked);
    } catch (e) {
      /* do nothing */
    }

    window.removeEventListener('beforeunload', this.delete);
  }

  private startInterval() {
    if (this.intervalId) {
      return;
    }

    this.intervalId = window.setInterval(() => {
      if (this.playerStatus) {
        this.ping(this.playerStatus);
      }
    }, this.interval);
  }

  private resetInterval() {
    // you can only reset the interval if it had been started already
    if (!this.intervalId) {
      return;
    }

    this.clearInterval();
    this.startInterval();
  }

  private clearInterval() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
  }

  async close() {
    this.clearInterval();
    return this.end(this.player.getCurrentTime() ?? 0);
  }

  private handleEnd() {
    this.end(this.player.getCurrentTime() ?? 0);
    this.clearInterval();
  }

  private handlePaused() {
    this.ping(PlayerStatesEnum.PAUSED);

    this.resetInterval();
  }

  private handlePlay(event: PlaybackEvent) {
    if (
      event.issuer !== PlayerEmitterEventEnum.CAST_ISSUER &&
      // `remote`` is not defined on PlaybackEvent, but it was referenced in the old code. In
      // testing it was never seen, but opted to leave it in in case it shows up in odd situations
      // that were not tested. Given the condition, if its not present, its skipped, but if it is
      // present in those odd situations, it will be read properly.
      !(event as PlaybackEvent & { remote?: unknown }).remote
    ) {
      // We only want to send ping on PlayerEvent.Play for VPP if we've started actual playback
      if (!this.playbackStarted) {
        return;
      }

      this.ping(PlayerStatesEnum.PLAYING);

      this.resetInterval();
    }
  }

  private handlePlaying(event: PlaybackEvent) {
    if (
      event.issuer !== PlayerEmitterEventEnum.CAST_ISSUER &&
      // `remote`` is not defined on PlaybackEvent, but it was referenced in the old code. In
      // testing it was never seen, but opted to leave it in in case it shows up in odd situations
      // that were not tested. Given the condition, if its not present, its skipped, but if it is
      // present in those odd situations, it will be read properly.
      !(event as PlaybackEvent & { remote?: unknown }).remote
    ) {
      if (!this.playbackStarted) {
        this.playbackStarted = true;
      }

      this.ping(PlayerStatesEnum.PLAYING);

      // start interval if not started, otherwise reset to run again in this.interval ms
      if (!this.intervalId) {
        this.startInterval();
      } else {
        this.resetInterval();
      }
    }
  }

  private handleSeeked(event: SeekEvent) {
    if (
      event.issuer !== PlayerEmitterEventEnum.CAST_ISSUER &&
      // `remote`` is not defined on SeekEvent, but it was referenced in the old code. In
      // testing it was never seen, but opted to leave it in in case it shows up in odd situations
      // that were not tested. Given the condition, if its not present, its skipped, but if it is
      // present in those odd situations, it will be read properly.
      !(event as SeekEvent & { remote?: unknown }).remote
    ) {
      this.ping(
        this.player.isPaused() ?
          PlayerStatesEnum.PAUSED
        : PlayerStatesEnum.PLAYING,
      );

      this.resetInterval();
    }
  }

  private async delete() {
    return sendVPPApi({
      url: this.data.concurrencyUrl,
      playerState: PlayerStatesEnum.STOPPED,
    });
  }

  private async end(currentTime: number) {
    await this.delete();

    if (this.data.bookmarkUrl && this.token) {
      await bookmarkApi({
        authToken: this.token,
        bookmarkUrl: this.data.bookmarkUrl,
        cognitoId: this.data.id,
        position: currentTime,
      });
    }
  }

  private async ping(playerStatus: PlayerStatesEnum) {
    this.playerStatus = playerStatus;

    try {
      if (this.decodedToken) {
        const runtimeEnd = this.player.getDuration() + Date.now() / 1000;
        verifyAccessToken({
          decodedToken: this.decodedToken,
          dispatch: this.dispatch,
          failMessage: this.failMessage,
          player: this.player,
          refreshToken: this.refreshToken,
          runtimeEnd,
        });
      }

      await sendVPPApiWithRetry({
        url: this.data.concurrencyUrl,
        playerState: this.playerStatus,
        statusCodes: [HttpStatusCodeEnum.GONE],
      });

      if (
        this.data.bookmarkUrl &&
        this.token &&
        (this.playerStatus === PlayerStatesEnum.PAUSED ||
          this.playerStatus === PlayerStatesEnum.PLAYING)
      ) {
        await bookmarkApi({
          authToken: this.token,
          bookmarkUrl: this.data.bookmarkUrl,
          cognitoId: this.data.id,
          position: this.player.getCurrentTime(),
        });
      }
    } catch (e) {
      if (e.data?.responseCode === HttpStatusCodeEnum.TOO_MANY_REQUESTS) {
        this.error(new ConcurrencyError());
      } else if (e.data?.responseCode === HttpStatusCodeEnum.GONE) {
        this.error(new ShouldRefetchPlayerDataError());
      }
    }
  }
}
