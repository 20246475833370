import { isEmailValid } from 'utils';

export const validatePassword = (password, message) => {
  let error;
  if (!password.match(/^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).{8,}$/)) {
    error = message;
  }
  return error;
};

export const validateEmail = (email, message) => {
  let error;
  if (!isEmailValid(email)) {
    error = message;
  }
  return error;
};
