/** @jsx jsx */
import type { RefObject, ChangeEvent, FocusEvent } from 'react';
import React, { useRef, useEffect } from 'react';
import { jsx } from '@emotion/core';
import {
  containerCss,
  inputCss,
  errorTextCss,
  labelCss,
  iconCss,
  labelIconCss,
  iconImageCss,
  hintCss,
} from './styles';
import clsx from 'clsx';

export interface InputPropsInterface {
  autoFocus?: boolean;
  className?: string;
  classNameContainer?: string;
  css?: any;
  disabled?: boolean;
  error?: string;
  hint?: string;
  icon?: {
    'data-testid': string;
    onClick: () => void;
    ref?: RefObject<HTMLDivElement>;
    src: string;
  };
  id?: string;
  label?: string;
  labelIcon?: {
    onClick?: () => void;
    ref?: RefObject<HTMLDivElement>;
    src: string;
  };
  maxLength?: number;
  name: string;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (e: FocusEvent<HTMLInputElement>) => void;
  placeholder?: string;
  readOnly?: boolean;
  spellCheck?: boolean;
  styles?: any;
  type?: string;
  value: string | number;
}
const Input: React.FC<InputPropsInterface> = (props) => {
  const {
    autoFocus = false,
    className,
    classNameContainer,
    css,
    error,
    hint,
    icon,
    id,
    label,
    labelIcon,
    styles,
    ...rest
  } = props;

  const inputEl = useRef<HTMLInputElement | null>(null);

  /**
   * Autofocus only works on initial render, we need to manual focus.
   * Will not work if the page has multiple elements with autofocus.
   * Step must be a new copy of the state or effect will not re-run
   */
  useEffect(() => {
    autoFocus && inputEl.current && inputEl.current.focus();
  }, [autoFocus]);

  return (
    <div
      css={containerCss(styles)}
      className={clsx(['input-container', classNameContainer])}
    >
      {!!label && (
        <label
          css={labelCss}
          htmlFor={id}
        >
          {label}
        </label>
      )}
      {!!icon && (
        <div
          css={iconCss}
          onClick={icon.onClick}
          data-testid={icon['data-testid']}
          ref={icon.ref}
        >
          <img
            css={iconImageCss}
            src={icon.src}
          />
        </div>
      )}
      {!!labelIcon && (
        <div
          css={labelIconCss}
          onClick={labelIcon.onClick}
          data-testid={labelIcon['data-testid']}
          ref={labelIcon.ref}
        >
          <img
            css={iconImageCss}
            src={labelIcon.src}
          />
        </div>
      )}
      <input
        css={(theme) => [inputCss(theme, error, styles), css]}
        className={clsx(className)}
        id={id}
        ref={inputEl}
        {...rest}
      />
      {hint && <p css={hintCss}>{hint}</p>}
      {!!error && (
        <p
          className="error-text"
          css={errorTextCss}
          data-testid="input-error"
        >
          {error}
        </p>
      )}
    </div>
  );
};

export default Input;
