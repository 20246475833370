import type { CastStateInterface } from 'hocs/with-chromecast';
import type { PlayerStatesEnum } from 'models/enums';
import type React from 'react';

export interface PlayerUIInterface {
  isMiniPlayer: boolean;
  miniPlayerOff(): void;
  miniPlayerOn(): void;
  toggleMiniPlayer(): void;
}

export interface PlayerContextPropsInterface {
  closePlayer(): void;
  isCheckingExpired: boolean;
  isExpired: boolean;
  openPlayer: OpenPlayerInterface;
  setFycAccessToken: (accessToken: string) => void;
  setIsExpired: (expired: boolean) => void;
  setIsFyc: (isFyc: boolean) => void;
  ui: PlayerUIInterface;
}

export interface PlayerProviderPropsInterface {
  cast: CastStateInterface;
  children: React.ReactNode;
}

export interface PingSessionOptionsInterface {
  playerStatus: PlayerStatesEnum;
  shouldCheckOverLimit?: boolean;
}

export interface PlayerPropsInterface extends ErrorStateInterface {
  authToken?: string;
  autoplay?: boolean;
  cast?: CastStateInterface;
  fullViewportView?: boolean;
  fycAccessToken?: string;
  isFyc?: boolean;
  isFycPublic?: boolean;
  playData?: any;
  playId: string;
  restart?: boolean;
  slug?: string;
  ui: PlayerUIInterface;
}

export interface ErrorStateInterface {
  isOpen: boolean;
  message: string | null;
  title?: string;
}

export interface OpenPlayerInterface {
  (target: string, component: any, options?: PlayerOptionsInterface);
}

export enum PlayerEmitterEventEnum {
  CAST_ISSUER = 'cast_issuer',
  PLAYER_CLOSE = 'player_close',
  SESSION_DELETING = 'session_deleting',
  SESSION_DELETED = 'session_deleted',
}

export interface PlayerOptionsInterface {
  isFycPublic?: boolean;
  restart?: boolean;
}
