/** @jsx jsx */
import type { SerializedStyles } from '@emotion/core';
import { jsx, css } from '@emotion/core';
import clsx from 'clsx';
import React from 'react';

interface PropsInterface {
  classNames?: string;
  css?: SerializedStyles;
  extendedStyles?: SerializedStyles | string | SerializedStyles[];
  isOpen: boolean;
  onClose?(): void;
}

const modalContainerCss = (theme) => css`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(27, 52, 77, 0.8);
  z-index: ${theme.zIndex.modal};
  display: none;
  transition: opacity 0.5s;
  padding: 0 5%;

  &.visible {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const Modal: React.FC<PropsInterface> = ({
  children,
  isOpen,
  onClose,
  extendedStyles,
  classNames,
  css,
}) => {
  return (
    <div
      css={(theme) => [modalContainerCss(theme), extendedStyles, css]}
      className={clsx([classNames, isOpen && 'visible'])}
      onClick={onClose}
    >
      {children}
    </div>
  );
};

export default Modal;
