/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import React, { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, Link } from 'react-router-dom';
import type { FormikProps, FormikValues } from 'formik';
import { Formik, Form, Field } from 'formik';

import {
  loginWithPassword,
  userErrorSelector,
  loginWithTFA,
} from 'store/slices/user';
import { Button, Input, Loader } from 'components';
import { LayoutContainer } from 'containers';
import * as routes from 'router/constants';
import deleteIcon from 'public/images/X_Circle.svg';
import eyeIcon from 'public/images/Eye.svg';
import eyeOffIcon from 'public/images/Eye_Off.svg';

import {
  headerTextCss,
  actionButtonCss,
  actionButtonContainerCss,
  errorLabelText,
} from 'pages/styles';
import { mediaQuery } from 'utils/styles';
import { useStringsContext, STRING_KEYS } from 'providers/strings-provider';
import { SentryCodeTypeEnum } from 'models/enums';
import { useDecodedJwt } from 'utils/hooks';
import { validateEmail } from 'utils/formValidation';
import theme from 'styles/theme';
import { HttpStatusCodeEnum } from 'models/enums';
import OtpInput from 'react-otp-input';

import rootCss, {
  actionBtnCss,
  tokenInputStyle,
} from 'components/two-factor-auth/styles';
import type { WithErrorBoundaryPropsInterface } from 'hocs/with-error-boundary';
import withErrorBoundary from 'hocs/with-error-boundary';

const forgotPwdContainerCss = css`
  margin-top: 1.7rem;
`;

const forgotPwdCss = (theme) => css`
  color: ${theme.palette.primary.light};
  text-decoration: none;
  letter-spacing: 0.45;

  ${mediaQuery(theme.breakpoints.md)} {
    letter-spacing: -0.15;
  }
`;

const passwordContainerCss = css`
  margin-top: 1rem;
  /* Override default styles */
  ${mediaQuery(theme.breakpoints.md)} {
    margin-top: 1rem;
  }
`;

const actionContainerCss = css`
  margin-top: 1rem;
  ${mediaQuery(theme.breakpoints.md)} {
    width: 20.6rem;
  }
`;

const layoutContainerRootCss = (isTfa) => {
  if (isTfa) {
    return css`
      footer {
        display: none;
      }

      ${mediaQuery(theme.breakpoints.md)} {
        footer {
          display: block;
        }
      }
    `;
  }
};

interface LocationStateInterface {
  from: string;
}

interface TFAStateInterface {
  context: string | null;
  isRequired: boolean;
}

interface ErrorInterface {
  message: string | null | undefined;
}

const LoginEmail: React.FC<WithErrorBoundaryPropsInterface> = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation<LocationStateInterface>();
  const [isPwdVisible, setIsPwdVisible] = useState<boolean>(false);
  const { getStringByKey } = useStringsContext();
  const loginError = useSelector(userErrorSelector);
  const decodedJwt = useDecodedJwt();
  const [otpToken, setOtpToken] = useState<string | null>(null);
  const [errors, setErrors] = useState<ErrorInterface | null>(null);
  const formikRef = useRef<FormikProps<FormikValues>>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const initialTfaState: TFAStateInterface = useMemo(
    () => ({
      isRequired: false,
      context: null,
    }),
    [],
  );

  const [tfaState, setTfaState] = useState<TFAStateInterface>(initialTfaState);

  const togglePwdVisible = () => setIsPwdVisible((prevState) => !prevState);

  const shouldDisableSubmit = ({ email, password }: FormikValues): boolean => {
    return !email || !password;
  };

  const handleLogin = ({ email, password }: FormikValues) => {
    dispatch(loginWithPassword(email, password));
  };

  const onSubmit = (values: FormikValues) => {
    handleLogin(values);
    setErrors(null);
  };

  const handleTfaSubmit = () => {
    if (tfaState.context) {
      setIsLoading(true);
      dispatch(loginWithTFA(tfaState.context, otpToken || ''));
    }
  };

  const onOtpChange = (value) => {
    setOtpToken(value);
    setErrors(null);
  };

  useEffect(() => {
    if (loginError) {
      const sentryError: string = loginError.response?.body?.error || '';

      let message = getStringByKey(STRING_KEYS.AUTH_LOGIN_EMAIL_ERROR);

      if (sentryError === SentryCodeTypeEnum.ACCOUNT_DISABLED) {
        message = getStringByKey(STRING_KEYS.AUTH_LOGIN_INACTIVE);
      }

      if (
        loginError.status === HttpStatusCodeEnum.UNAUTHORIZED &&
        sentryError === SentryCodeTypeEnum.ACCOUNT_2FA_REQUIRED
      ) {
        setTfaState((prevState) => ({
          ...prevState,
          isRequired: true,
          context: loginError.response?.body.context,
        }));
      }

      if (formikRef.current) {
        formikRef.current.setSubmitting(false);
        formikRef.current.setFieldError('password', message);
      } else {
        let description: string | undefined =
          loginError.response?.body?.error_description;

        const { retries, reset } = props.errorBoundary;

        if (tfaState.isRequired) {
          if (retries.value < 1) {
            description = getStringByKey(STRING_KEYS.AUTH_2FA_FAIL);
            retries.set(retries.value + 1);
          } else {
            description = getStringByKey(STRING_KEYS.AUTH_2FA_FAIL_X2);
            reset();
          }
        }

        setErrors({ message: description });
        setTfaState(initialTfaState);
        setIsLoading(false);
      }
    }
  }, [loginError]);

  useEffect(() => {
    if (decodedJwt) {
      formikRef.current?.setSubmitting(false);
      history.push(location.state?.from || routes.INDEX);
    }
  }, [decodedJwt, history, location.state]);

  const form = (
    <Formik
      initialValues={{
        email: '',
        password: '',
      }}
      onSubmit={onSubmit}
      innerRef={formikRef as any}
      validateOnBlur={false}
    >
      {({
        errors: formErrors,
        isSubmitting,
        values,
        isValid,
        setFieldValue,
      }) => (
        <Form noValidate>
          {isSubmitting && <Loader />}
          <h2 css={headerTextCss}>Log In</h2>
          <Field
            name="email"
            validate={(email) =>
              validateEmail(
                email,
                getStringByKey(STRING_KEYS.AUTH_LOGIN_EMAIL_INVALID),
              )
            }
          >
            {({ field }) => (
              <Input
                type="email"
                placeholder="Enter your email"
                disabled={isSubmitting}
                data-testid="login-email-input"
                error={formErrors.email}
                icon={
                  !!values.email ?
                    {
                      src: deleteIcon,
                      onClick: () => setFieldValue('email', ''),
                      'data-testid': 'login-email-clear',
                    }
                  : undefined
                }
                autoFocus
                {...field}
              />
            )}
          </Field>

          <Field name="password">
            {({ field }) => (
              <Input
                type={isPwdVisible ? 'text' : 'password'}
                placeholder="Enter your password"
                disabled={isSubmitting}
                data-testid="login-password-input"
                error={formErrors.password}
                styles={{ containerCss: passwordContainerCss }}
                icon={{
                  src: isPwdVisible ? eyeOffIcon : eyeIcon,
                  onClick: togglePwdVisible,
                  'data-testid': 'login-password-toggle',
                }}
                {...field}
              />
            )}
          </Field>
          {errors && <p css={errorLabelText}>{errors.message}</p>}
          <div css={actionContainerCss}>
            <Button
              type="submit"
              data-testid="login-submit"
              theme="primary"
              disabled={shouldDisableSubmit(values) || !isValid}
              css={actionButtonCss}
            >
              Log In
            </Button>
          </div>
          <div css={forgotPwdContainerCss}>
            <Link
              data-testid="forget-password-link"
              to={routes.FORGOT_PASSWORD}
              css={forgotPwdCss}
            >
              Forgot Password?
            </Link>
          </div>
        </Form>
      )}
    </Formik>
  );

  const twoFactorAuthentication = () => {
    const title = 'Enter your verification code to complete log in';
    const desc = getStringByKey(
      STRING_KEYS.AUTH_TWO_FACTOR_AUTH_ENTER_CODE_DESC,
    );
    return (
      <React.Fragment>
        {isLoading && <Loader />}
        <div
          className="tfa tfa-container"
          css={rootCss}
        >
          <div className="tfa-body">
            <p className="sub-heading">{title}</p>
            <p className="body-copy">{desc}</p>
          </div>
          <OtpInput
            className="token-input"
            value={otpToken ?? undefined}
            onChange={onOtpChange}
            numInputs={6}
            inputStyle={tokenInputStyle}
            containerStyle="token-container"
            shouldAutoFocus
          />
        </div>
        <div
          css={actionButtonContainerCss({
            actionButtonContainerCss: actionBtnCss,
          })}
        >
          <Button
            onClick={handleTfaSubmit}
            type="submit"
            theme="primary"
            data-testid="login-tfa-submit"
            disabled={otpToken?.length !== 6 || !!errors || isLoading}
            css={actionButtonCss}
          >
            {getStringByKey(
              STRING_KEYS.AUTH_TWO_FACTOR_AUTH_ENTER_CODE_LOGIN_CTA,
            )}
          </Button>
        </div>
      </React.Fragment>
    );
  };

  return (
    <LayoutContainer
      auth
      styles={{
        layoutRowContainerCss: layoutContainerRootCss(tfaState.isRequired),
      }}
    >
      {tfaState.isRequired ? twoFactorAuthentication() : form}
    </LayoutContainer>
  );
};

export default withErrorBoundary(LoginEmail);
