import React from 'react';
import { NotFound } from 'pages';
import AllContentLanding from '../all-content-landing';
import DetailsPage from '../details-page';
import ContactUs from '../contact-us';
import Landing from '../landing';
import { isPlainView, ContactUsPage, MainPage, LandingPage } from '../helpers';
interface PropsInterface {
  isDetailsPage: any;
  pageData: any;
  pathname: string;
}

const PageLayout: React.FC<PropsInterface> = ({
  pageData,
  pathname,
  isDetailsPage,
}) => {
  if (!!ContactUsPage(pathname)) {
    return <ContactUs pageData={pageData} />;
  }
  const showLanding = !pageData?.components?.find(
    (c) => c.type === 'ENTITLEMENTS',
  );

  if (!!MainPage(pathname)) {
    if (showLanding) {
      return <Landing pageData={pageData} />;
    }

    return <AllContentLanding pageData={pageData} />;
  }

  if (!!LandingPage(pathname)) {
    return <AllContentLanding pageData={pageData} />;
  }

  if (isDetailsPage) {
    return (
      <DetailsPage
        hasPlain={isPlainView(pageData?.components)}
        pageData={pageData}
      />
    );
  }

  return <NotFound />;
};

export default PageLayout;
