/** @jsx jsx */
import type { SerializedStyles } from '@emotion/core';
import { jsx } from '@emotion/core';
import clsx from 'clsx';
import React from 'react';
import styles from './styles';

export interface ProgressBarPropsInterface {
  className?: string;
  rootStyles?: SerializedStyles | string;
}

interface PropsInterface {
  className?: string;
  fullWidth?: boolean;
  progress: number;
  rootStyles?: SerializedStyles | string;
  styles?: SerializedStyles;
}

const ProgressBar: React.FC<PropsInterface> = ({
  progress,
  className,
  rootStyles,
  styles: extendedStyles,
  fullWidth,
}): React.ReactElement | null => {
  const progressBarComponent = (
    <div
      className={clsx(['progress-bar', className])}
      css={[rootStyles, styles(progress)]}
    >
      <div className="progress"></div>
    </div>
  );

  if (progress && progress > 0) {
    if (fullWidth) {
      return (
        <div
          className="progress-bar-container"
          css={[extendedStyles, rootStyles]}
        >
          {progressBarComponent}
        </div>
      );
    } else {
      return progressBarComponent;
    }
  }
  return null;
};

export default ProgressBar;
