/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import type { NSCastInterface } from 'models/cms';
import _ from 'lodash';
import CreditsItemsList from './items-list';

interface RowDataInterface {
  items: string[];
  title: string;
}

const CreditsPanel: React.FC<{ credits: NSCastInterface[] }> = ({
  credits,
}) => {
  const data = _.groupBy(credits, 'type');

  const generateRowData = () => {
    const rowData: RowDataInterface[] = [];

    _.mapKeys(data, function (val, key) {
      // items only contains names
      const items: string[] = [];
      val.map((v) => {
        items.push(v.name);
      });

      rowData.push({
        title: key,
        items,
      });
    });

    return _.chunk(rowData, 2);
  };

  return (
    <div>
      {generateRowData()?.map((row, i) => (
        <div key={i}>
          <CreditsItemsList data={row} />
        </div>
      ))}
    </div>
  );
};

export default CreditsPanel;
