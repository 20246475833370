import type { SerializedStyles } from '@emotion/core';
import { css } from '@emotion/core';
import theme from 'styles/theme';
import { mediaQuery, mediaQueryV2 } from 'utils/styles';

export const imgWrapperCss = (
  heroState: boolean,
  index: number,
  totalTwoItems: boolean,
): SerializedStyles => {
  return css`
    overflow: hidden;
    flex-shrink: 0;

    transition: width 0.5s;

    width: calc((100vw - 32px - 18px) / 332 * 100);
    height: calc((100vw - 32px - 18px) / 332 * 100 / 2 * 3);

    // ${index === 1 && `margin: 0 ${(16 / 375) * 100}vw;`}
    ${index === 1 && `margin: 0 calc((100vw - 32px - 18px) / 332 * 16);`}

    ${mediaQuery(theme.breakpoints.sm)} {
      ${index === 1 && !totalTwoItems && `margin: 0 ${(24 / 1024) * 100}vw;`}
      ${index === 1 &&
      totalTwoItems &&
      `
        margin: 0 ${(24 / 1024) * 100}vw;
        margin-right: 0;
      `}
    }

    ${mediaQuery(theme.breakpoints.lg)} {
      height: ${(558 / 1440) * 100}vw;

      ${index === 1 && !totalTwoItems && `margin: 0 ${(24 / 1440) * 100}vw;`}
      ${index === 1 &&
      totalTwoItems &&
      `
        margin: 0 ${(24 / 1440) * 100}vw;
        margin-right: 0;
      `}

      ${heroState ?
        `width: ${(983 / 1440) * 100}vw;`
      : `width: ${(373 / 1440) * 100}vw;`}
    }
  `;
};

export const heroWrapperCss = (
  heroState: boolean,
  onlyOneItem: boolean,
): SerializedStyles => {
  if (onlyOneItem && heroState) {
    return css`
      ${mediaQueryV2(theme.breakpoints.sm)} {
        display: none;
      }
      border: 2px solid rgba(182, 182, 184, 1);
      border-radius: ${8 / 16}rem;
      overflow: hidden;
      position: relative;
    `;
  }

  // preload imgs
  return css`
    border: 2px solid rgba(182, 182, 184, 1);
    border-radius: ${8 / 16}rem;
    overflow: hidden;

    position: relative;

    ${!heroState &&
    `
      position: absolute;
      z-index: -100;
      opacity: 0;
    `}
  `;
};

export const nonHeroWrapperCss = (
  heroState: boolean,
  onlyOneItem: boolean,
): SerializedStyles => {
  if (onlyOneItem && heroState) {
    return css`
      ${mediaQuery(theme.breakpoints.sm)} {
        display: none;
      }
    `;
  }

  // preload imgs

  return css`
    height: 100%;
    border-radius: ${8 / 16}rem;
    overflow: hidden;

    position: relative;

    ${heroState &&
    `
      position: absolute;
      z-index: -100;
      opacity: 0;
    `}
  `;
};

export const nonHoverLockWrapperCss = css`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(20px);
  cursor: pointer;

  .button-unlock {
    padding: 11px 1rem;

    img {
      width: ${14 / 16}rem;
      height: ${14 / 16}rem;
    }

    span {
      display: none;
      font-size: 1rem;
    }

    width: 26px;
    height: 26px;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      margin: 0;
    }

    ${mediaQuery(theme.breakpoints.sm)} {
      width: 50px;
      height: 40px;
      img {
        width: ${18 / 16}rem;
        height: ${18 / 16}rem;
      }
    }

    ${mediaQuery(theme.breakpoints.lg)} {
      width: 107px;
      height: 41px;

      span {
        display: block;
      }

      img {
        margin-right: 0.5rem;
      }
    }
  }
`;

export const hoverLockWrapperCss = css`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(20px);
  cursor: pointer;

  .nsimage {
    img {
      max-height: 137px;
      width: auto;
    }
  }

  .button-unlock {
    padding: 11px 1rem;

    img {
      width: ${18 / 16}rem;
      height: ${18 / 16}rem;
    }

    span {
      font-size: 1rem;
    }
  }
`;
