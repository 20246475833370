import type { LabelConfig } from 'bitmovin-player-ui/dist/js/framework/components/label';
import { Label } from 'bitmovin-player-ui/dist/js/framework/components/label';
import type { UIInstanceManager } from 'bitmovin-player-ui/dist/js/framework/uimanager';
import type { CastStartedEvent, PlayerAPI } from 'bitmovin-player';
import { i18n } from 'bitmovin-player-ui/dist/js/framework/localization/i18n';
import { CastStatusOverlay } from 'bitmovin-player-ui';
import type { ContainerConfig } from 'bitmovin-player-ui/dist/js/framework/components/container';
import { Container } from 'bitmovin-player-ui/dist/js/framework/components/container';
import { CustomPlayerToggleButton } from './custom-player-toggle-button';
export interface CustomCastOverlayConfigInterface extends ContainerConfig {
  entitlementTitle: string;
  onClick: () => void;
}

/**
 * Overlays the player and displays the status of a Cast session.
 */
export class CustomCastOverlay extends CastStatusOverlay {
  private labelStatus: Label<LabelConfig>;
  private labelTitle: Label<LabelConfig>;
  private contentContainer: Container<ContainerConfig>;

  private deviceName: string;
  private toggleButton: CustomPlayerToggleButton;

  constructor(config?: CustomCastOverlayConfigInterface) {
    super(config);

    this.labelStatus = new Label<LabelConfig>({
      cssClass: 'ui-cast-label-deviceName',
    });

    this.labelTitle = new Label<LabelConfig>({
      /* @ts-ignore TODO: TS18048: config is possibly undefined. */
      text: config.entitlementTitle,
      cssClass: 'ui-cast-entitlement-title',
    });

    this.contentContainer = new Container<ContainerConfig>({
      components: [this.labelTitle, this.labelStatus],
    });

    this.deviceName =
      window.cast?.framework.CastContext?.getInstance()
        ?.getCurrentSession()
        ?.getCastDevice().friendlyName ?? 'Loading';

    this.toggleButton = new CustomPlayerToggleButton({
      /* @ts-ignore TODO: TS18048: config is possibly undefined. */
      onClick: config.onClick,
      cssClasses: ['custom-toggle-button'],
    });

    /* @ts-ignore TODO: TS2322: Type ContainerConfig | undefined is not assignable to type ContainerConfig. */
    this.config = this.mergeConfig(
      config,
      {
        cssClass: 'ui-cast-status-overlay',
        components: [this.toggleButton, this.contentContainer],
        hidden: true,
      },
      this.config,
    );
  }

  configure(player: PlayerAPI, uimanager: UIInstanceManager): void {
    super.configure(player, uimanager);

    if (player.isCasting()) {
      this.show();
      this.labelStatus.setText(
        i18n.getLocalizer('playingOn', { castDeviceName: this.deviceName }),
      );
    }

    player.on(player.exports.PlayerEvent.CastWaitingForDevice, () => {
      this.labelStatus.setText(
        i18n.getLocalizer('', { castDeviceName: 'Loading' }),
      );
    });

    player.on(
      player.exports.PlayerEvent.CastStarted,
      (event: CastStartedEvent) => {
        this.deviceName = event.deviceName;
        this.show();
        this.labelStatus.setText(
          i18n.getLocalizer('playingOn', { castDeviceName: event.deviceName }),
        );
      },
    );
  }
}
export default CustomCastOverlay;
