import type { NSMessagingComponentInterface } from 'models/cms';
import type { FunctionComponent } from 'react';
import React from 'react';
import NsPaper from './ns-paper';
import NsImage from './ns-image';
import NsActionLink from './ns-action-link';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles<any>((theme) => ({
  root: {
    margin: `${theme.spacing.unit * 2} ${theme.spacing.unit * 5}`,
  },
  content: {
    padding: theme.spacing.unit * 3,
  },
  title: {
    ...theme.typography.title,
  },
  text: {
    ...theme.typography.defaultText,
  },
  header: {
    textAlign: 'left',
  },
  body: {
    textAlign: 'left',
  },
}));

interface PropsInterface {
  component: NSMessagingComponentInterface;
}

const NsMessagingComponent: FunctionComponent<PropsInterface> = (
  props: PropsInterface,
) => {
  const component = props.component;
  const classes = useStyles();

  const { image, actions } = component;

  return (
    <NsPaper className={classes.root}>
      <NsActionLink actions={actions}>
        {image && (
          <NsImage
            alt={image.alt}
            aspectRatio={image.aspectRatioFractions[0]}
            url={image.url}
            width={1200}
          />
        )}
        <div className={classes.content}>
          <div className={classes.header}>
            <h2 className={classes.title}>{component.title}</h2>
          </div>
          <div className={classes.body}>
            <p className={classes.text}>{component.text}</p>
          </div>
        </div>
      </NsActionLink>
    </NsPaper>
  );
};

export default NsMessagingComponent;
