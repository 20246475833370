import { FYC_TABLET_WIDTH } from 'pages/fyc-page/helpers';
import type { SerializedStyles } from '@emotion/core';
import { css } from '@emotion/core';
import { mediaQuery } from 'utils/styles';
import { TABLET_SMALL } from '../../details-banner/helpers';

const MDP_MOBILE_WIDTH = 599;

export const wrapperCss = css`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const ulWrapperCss = css`
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  padding-top: 2px;
  margin-top: -2px;
`;

export const contentCss = (): SerializedStyles => {
  const bg = `linear-gradient(180deg, rgba(25, 27, 41, 0.9) 0%, rgba(51, 49, 139, 0) 100%)`;

  return css`
    width: 100%;
    display: flex;
    justify-content: center;
    border-radius: 6px 6px 0px 0px;
    padding-bottom: ${60 / 16}rem;

    background: ${bg};

    ${mediaQuery(TABLET_SMALL)} {
      width: ${(919 / 1024) * 100}vw;
    }
  `;
};

export const tabsWrapperCss = css`
  width: ${(354 / 375) * 100}%;

  ${mediaQuery(MDP_MOBILE_WIDTH)} {
    width: 100%;
  }

  ${mediaQuery(FYC_TABLET_WIDTH)} {
    width: ${(895 / 1179) * 100}%;
    margin-top: ${23 / 16}rem;
  }
`;

export const reactTabsCss = (): SerializedStyles => {
  const selectedBg = `linear-gradient(129.18deg, #2EEFFF 8.68%, #061AFF 106.16%)`;
  const selectFontWeight = 500;
  const fontSize = `1rem`;
  const selectedFontSize = `1rem`;
  const fontFamily = 'inherit';
  const selectFontFamily = `inherit`;

  return css`
    .react-tabs__tab {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border: none;
      bottom: 0px;
      list-style: none;
      padding: 0;
      height: 100%;
      cursor: pointer;
      font-size: ${fontSize};
      font-family: ${fontFamily};
      margin-right: 0rem;

      @media only screen and (min-width: 1025px) {
        margin-right: 0rem;
      }
    }

    .react-tabs__tab--selected {
      border: none;
      color: #fff;
      font-weight: ${selectFontWeight};
      font-size: ${selectedFontSize};
      font-family: ${selectFontFamily};

      .tab-selected {
        position: absolute;

        background: ${selectedBg};
        border-radius: 2px;

        top: -2px;
        // z-index: 10;
      }
    }

    .react-tabs__tab:focus {
      box-shadow: 0;
      border: none;
      outline: none;
    }

    .react-tabs__tab:focus:after {
      content: '';
      position: inherit;
      height: 0px;
      width: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      background: transparent;
      border: none;
    }

    .react-tabs__tab-list {
      white-space: nowrap;
      padding: 0;
      border: none;
      display: flex;
      gap: 25px;
      justify-content: center;
      height: ${45 / 16}rem;

      border-radius: 2px;
      border-top: 2px solid #ffffff;

      @media only screen and (min-width: 1025px) {
        margin-top: 0;
      }
    }
  `;
};

export const tabTopCss = css`
  width: 100%;
  height: 4px;
  background: transparent;
  border-radius: 2px;
`;

export const tabTitleCss = css`
  padding: 0 8px;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;
