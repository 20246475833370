import type { SerializedStyles } from '@emotion/core';
import { css } from '@emotion/core';
import { FYC_TABLET_WIDTH } from 'pages/fyc-page/helpers';
import type { PressPanelItemConfigInterface } from 'providers/fyc-theme-component-interfaces';
import { FycConfigsEnum } from 'providers/fyc-theme-component-interfaces';
import type { FycVersionType } from 'providers/fyc-theme-provider';
import {
  DTS,
  FREEFORM,
  HULU,
  SEARCHLIGHT,
  TWDS,
} from 'providers/fyc-theme-provider';
import { getVersionedComponentConfig } from 'providers/versions/fyc-theme-provider-utils';
import { mediaQuery } from 'utils/styles';

const MDP_MOBILE_WIDTH = 599;

export const itemWrapperCss = css`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  padding: 0 1rem;

  ${mediaQuery(MDP_MOBILE_WIDTH)} {
    flex-direction: row;
    margin-top: ${19 / 16}rem;
    padding: 0 ${65 / 16}rem;
  }

  ${mediaQuery(FYC_TABLET_WIDTH)} {
    padding: 0 ${122 / 16}rem;
    margin-top: ${25 / 16}rem;
  }
`;

export const imageWrapperCss = (): SerializedStyles => {
  return css`
    width: 100%;
    aspect-ratio: 16 / 9;
    cursor: pointer;
    overflow: hidden;
    flex-shrink: 0;

    background: linear-gradient(180deg, #404461 0%, #232434 100%);
    box-shadow:
      0px 4px 5px rgba(0, 0, 0, 0.14),
      0px 1px 10px rgba(0, 0, 0, 0.12),
      0px 2px 4px -1px rgba(0, 0, 0, 0.2);
    border-radius: 10px;

    ${mediaQuery(MDP_MOBILE_WIDTH)} {
      width: ${288 / 16}rem;
    }
  `;
};

export const textWrapperCss = (version: FycVersionType): SerializedStyles => {
  const customCssConfig = getVersionedComponentConfig<
    PressPanelItemConfigInterface['textWrapperCss']
  >({
    version,
    component: FycConfigsEnum.PRESS_PANEL_ITEM_CONFIG,
    selector: 'textWrapperCss',
  });

  let titleColor = customCssConfig?.titleColor ?? `rgba(255, 255, 255)`;
  let dateFontWeight = customCssConfig?.dateFontWeight ?? 400;
  let titleFontWeight = customCssConfig?.titleFontWeight ?? 400;

  if (version === HULU) {
    titleColor = `rgba(255, 255, 255, 0.47)`;
  }

  if (version === TWDS) {
    dateFontWeight = 500;
  }

  if (version === SEARCHLIGHT) {
    dateFontWeight = 600;
    titleColor = `rgba(38, 38, 38, 1)`;
  }

  if (version === DTS) {
    dateFontWeight = 500;
  }

  if (version === FREEFORM) {
    dateFontWeight = 300;
    titleFontWeight = 300;
  }

  return css`
    font-size: 1rem;
    font-weight: 500;
    padding-top: ${10 / 16}rem;
    font-family: inherit;
    label: textWrapperCss;

    ${mediaQuery(MDP_MOBILE_WIDTH)} {
      padding-top: 0;
      margin-left: ${32 / 16}rem;
    }

    .date {
      font-size: ${13 / 16}rem;
      font-weight: ${dateFontWeight};
      text-transform: uppercase;
    }

    .title {
      margin-top: 1rem;
      font-size: ${14 / 16}rem;
      font-weight: ${titleFontWeight};
      color: ${titleColor};
      text-transform: uppercase;
    }

    .description {
      margin-top: ${8 / 16}rem;
      font-weight: 400;
    }
  `;
};
