/** @jsx jsx */
/* eslint react-hooks/rules-of-hooks: 2, react-hooks/exhaustive-deps: 2 */
import type { SerializedStyles } from '@emotion/core';
import { jsx } from '@emotion/core';
import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import defaultStyles from './styles';
import mobileSplash from 'public/animations/DEBUT_Splash_375x812_iOSmobile.json';
import tabletSplash from 'public/animations/DEBUT_Splash_1280x800.json';
import desktopSplash from 'public/animations/DEBUT_Splash_1920x1080.json';
import type { AnimationItem } from 'lottie-web';
import Lottie from 'lottie-web';
import { BackgroundVideoEnum, DeviceTypeEnum } from 'models/enums';

interface BackgroundVideoPropsInterface {
  className?: {
    container: string;
  };
  device:
    | DeviceTypeEnum.MOBILE
    | DeviceTypeEnum.TABLET
    | DeviceTypeEnum.DESKTOP;
  isHiddenOnComplete?: boolean;
  onComplete?(): void;
  styles?: {
    container: SerializedStyles;
  };
}

const BackgroundVideo: React.FC<BackgroundVideoPropsInterface> = ({
  onComplete,
  styles,
  className,
  isHiddenOnComplete = true,
  device,
}) => {
  const playerRef = useRef<HTMLDivElement | null>(null);
  const [player, setPlayer] = useState<AnimationItem | null>(null);
  const [isHidden, setIsHidden] = useState<boolean>(false);

  const fadeOut = () => {
    return isHidden && 'fade-out';
  };

  useEffect(() => {
    const getAnimation = () => {
      switch (device) {
        case DeviceTypeEnum.MOBILE:
          return mobileSplash;
        case DeviceTypeEnum.TABLET:
          return tabletSplash;
        case DeviceTypeEnum.DESKTOP:
          return desktopSplash;
      }
    };

    if (!playerRef.current) {
      return;
    }

    setPlayer(
      Lottie.loadAnimation({
        container: playerRef.current,
        renderer: 'canvas',
        autoplay: true,
        animationData: getAnimation(),
        loop: false,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
        },
      }),
    );
  }, [device, playerRef]);

  useEffect(() => {
    const handleHide = () => {
      if (isHiddenOnComplete) {
        setIsHidden(true);
        setTimeout(() => {
          onComplete && onComplete();
        }, BackgroundVideoEnum.FADE_TIME * 1500);
      }
    };

    player?.addEventListener('complete', handleHide);
    return () => {
      player?.removeEventListener('complete', handleHide);
    };
  }, [isHiddenOnComplete, onComplete, player]);

  return (
    <div
      className={clsx([
        'background-video-container',
        fadeOut(),
        className?.container,
      ])}
      css={[defaultStyles, styles?.container]}
    >
      <div
        ref={playerRef}
        className="lottie"
      ></div>
    </div>
  );
};

export default BackgroundVideo;
