/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import ComponentContainer from 'components/component-container';
import type { NSBannerComponentInterface } from 'models/cms';
import { wrapperCss, textCss } from './styles';
import NsImage from 'components/ns-component/ns-image';

interface PropsInterface {
  component: NSBannerComponentInterface;
}

const nsImageExtendedCss = {
  nsImageContainerCss: css`
    height: 43px;
    width: auto;

    img {
      height: 100%;
      width: auto;
    }
  `,
};

const ShortBannerComponent: React.FC<PropsInterface> = ({ component }) => {
  return (
    <ComponentContainer>
      <div className="row">
        <div css={wrapperCss}>
          <div>
            {component.image && (
              <NsImage
                url={component.image?.url}
                width={800}
                aspectRatio={component.image?.aspectRatioFractions[0]}
                styles={nsImageExtendedCss}
              />
            )}
          </div>
          <div css={textCss}>{component.displayText || ''}</div>
        </div>
      </div>
    </ComponentContainer>
  );
};

export default ShortBannerComponent;
