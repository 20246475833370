import {
  createAsyncThunk,
  createSlice,
  createSelector,
} from '@reduxjs/toolkit';
import type { ResponseError } from 'superagent';
import request from 'superagent';
import type { NSPageInterface } from 'models/cms';
import * as endpoints from 'api/endpoints';
import type { RootStateType } from 'store/root-reducer';

export interface FycStateInterface {
  components?: NSPageInterface['components'] | null;
  error?: ResponseError | null;
  isFetching: boolean | null;
  title?: string;
}

export const initialState: FycStateInterface = {
  isFetching: null,
  components: null,
  error: null,
  title: '',
};

interface OptionsInterface {
  slug: string;
}

// thunks
export const fetchFycPage = createAsyncThunk<
  NSPageInterface,
  OptionsInterface,
  {
    rejectValue: FycStateInterface['error'];
  }
>('fyc/fetch', async ({ slug }: OptionsInterface, { rejectWithValue }) => {
  try {
    const res = await request
      .get(endpoints.FYC_PAGE)
      .set('Content-Type', 'application/json')
      .accept('json')
      .query({ slug });

    return res.body as NSPageInterface;
  } catch (err) {
    const rejectValue =
      err instanceof Error ? (err as ResponseError) : new Error(String(err));
    return rejectWithValue(rejectValue);
  }
});

const fycSlice = createSlice({
  name: 'fyc',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchFycPage.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchFycPage.fulfilled, (state, action) => {
      state.isFetching = false;
      state.components = action.payload?.components;
      state.title = action.payload?.title;
    });
    builder.addCase(fetchFycPage.rejected, (state, action) => {
      state.isFetching = false;
      state.components = null;
      state.error = action.payload;
      state.title = '';
    });
  },
});

// selectors
const fycSelector = (state: RootStateType): FycStateInterface | undefined =>
  state.fyc;

export const fycErrorSelector = createSelector(
  fycSelector,
  (fyc: FycStateInterface) => fyc.error,
);

export const fycDataSelector = createSelector(
  fycSelector,
  (fyc: FycStateInterface) => ({
    components: fyc.components,
    title: fyc.title,
  }),
);

export const fycIsFetchingSelector = createSelector(
  fycSelector,
  (fyc: FycStateInterface) => fyc.isFetching,
);

export default fycSlice.reducer;
