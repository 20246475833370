/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useState } from 'react';
import type { CMSComponentInterface } from 'models/cms/cms-component-interface';
import type { ProgressStateInterface } from 'store/slices/progress';
import DownArrow from 'public/images/Select_Arrow_Down.svg';
import clsx from 'clsx';
import Select from 'react-select';
import { selectStyles, seriesRootCss } from './styles';
import EntitlementGrid from 'components/entitlement-grid';
import { ComponentTypeEnum } from 'models/enums';
import Loader from 'components/loader';

interface MovieDetailsRelatedPropsInterface {
  components: CMSComponentInterface[] | null | undefined;
  handleNdaClick(templateId: string): Promise<void>;
  isDesktop?: boolean;
  isPlain?: boolean;
  isSeries?: boolean;
  progressData: ProgressStateInterface;
  verifyNdaCompletion(templateId: string): boolean;
}

export const DropdownIndicator = (props) => {
  return (
    <div
      className={clsx([
        'select-indicator',
        props?.selectProps.menuIsOpen && 'collapsed',
      ])}
      data-testid={`drop_downlist`}
    >
      <img src={DownArrow} />
    </div>
  );
};

const MovieDetailsRelated: React.FC<MovieDetailsRelatedPropsInterface> = ({
  components,
  handleNdaClick,
  verifyNdaCompletion,
  progressData,
  isPlain,
  isDesktop,
}) => {
  const createLabel = (component: CMSComponentInterface) =>
    `${component.headerTitle || component.title} (${
      component.items?.length || 0
    } Videos)`;

  const selectOptions = components?.map((component, i) => ({
    value: i,
    label: createLabel(component),
  }));
  const [indexSelection, setIndexSelection] = useState<number>(0);
  const [showLoader, setShowLoader] = useState<boolean>(false);

  return showLoader ?
      <Loader text={'Loading your content...'} />
    : <div
        className={'series-details-grid'}
        css={seriesRootCss}
      >
        <div className="series-select-container">
          <Select
            /* @ts-ignore TODO: TS2339: Property value does not exist on type { value: number; label: string | undefined; } | null. */
            onChange={({ value }) => {
              setShowLoader(true);
              setIndexSelection(value);
              /* TODO: Singleton loader for mobile view
            setTimeout(() => {
              setShowLoader(false);
            }, 200);
            */
              setShowLoader(false);
            }}
            value={{
              value: indexSelection,
              label:
                components?.[indexSelection].headerTitle ||
                components?.[indexSelection].title,
            }}
            components={{
              DropdownIndicator,
            }}
            options={selectOptions}
            styles={selectStyles('')}
          />
        </div>
        <EntitlementGrid
          component={components?.[indexSelection]}
          type={ComponentTypeEnum.RELATED_CONTENT}
          handleNdaClick={handleNdaClick}
          verifyNdaCompletion={verifyNdaCompletion}
          progressData={progressData}
          isPlain={isPlain}
          isDesktop={isDesktop}
        />
      </div>;
};

export default MovieDetailsRelated;
