/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useEffect, useState } from 'react';
import { Field, Formik, Form } from 'formik';
import { Link, Redirect, useLocation, useParams } from 'react-router-dom';

import { Input, Button, Loader } from 'components';
import { useStringsContext, STRING_KEYS } from 'providers/strings-provider';
import { LayoutContainer } from 'containers';

import { WELCOME_ENROLLMENT } from 'api/endpoints';
import { getAccountStatus, welcomeEnrollmentApi } from 'api';
import { validatePassword } from 'utils/formValidation';
import { HttpStatusCodeEnum } from 'models/enums';
import { useQuery, useWindowSize } from 'utils/hooks';

import { WelcomeStepperEnum } from 'models/enums';
import TwoFactorAuth from 'components/two-factor-auth';
import * as routes from 'router/constants';
import deleteIcon from 'public/images/X_Circle.svg';
import eyeIcon from 'public/images/Eye.svg';
import eyeOffIcon from 'public/images/Eye_Off.svg';

import {
  headerTextCss,
  actionButtonCss,
  actionButtonContainerCss,
  successInfoContainerCss,
  loginInfoCss,
} from '../styles';

import {
  containerCss,
  rootCss,
  descriptionCss,
  formContainerCss,
  formCss,
  successContainerCss,
} from './styles';
import { AccountStatusEnum } from 'models/enums';
import Alert from 'components/alert';

interface RouteParamsInterface {
  TFA: string;
  token: string;
}

interface FieldDataInterface {
  email: string;
  error?: any;
  familyName: string;
  givenName: string;
  password: string;
  token: string;
  twoFactorSecret?: string;
}

const Welcome: React.FC = () => {
  const [isPwdVisible, setIsPwdVisible] = useState<boolean>(false);
  const [step, setStep] = useState<number>(WelcomeStepperEnum.ENROLL_STEP);
  const [fieldData, setFieldData] = useState<FieldDataInterface | null>(null);
  const [accountStatus, setAccountStatus] = useState<AccountStatusEnum | null>(
    null,
  );
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [isReset2FA, setIsReset2FA] = useState<boolean | null>(null);

  const windowSize = useWindowSize();

  const query = useQuery();
  const { getStringByKey } = useStringsContext();
  const { token } = useParams<RouteParamsInterface>();
  const hasTfa = query.has('tfa');
  const email = query.get('email');
  const togglePwdVisible = () => setIsPwdVisible((prevState) => !prevState);

  const { pathname } = useLocation();

  useEffect(() => {
    const splitPath = pathname.split('/');
    if (splitPath.length > 2 && splitPath[1] === 'reset-2fa') {
      setIsReset2FA(true);
      setStep(WelcomeStepperEnum.INFO_STEP);
    } else {
      setIsReset2FA(false);
    }
  }, [pathname]);

  useEffect(() => {
    const checkAccountStatus = async (email: string): Promise<void> => {
      try {
        const { text } = await getAccountStatus(email);
        const body = JSON.parse(text);
        if (body?.status === 'ENABLED') {
          setAccountStatus(AccountStatusEnum.ENABLED);
        } else if (body?.status === 'DISABLED') {
          setAccountStatus(AccountStatusEnum.DISABLED);
        }
        setShowAlert(true);
      } catch (e) {
        setAccountStatus(AccountStatusEnum.NOT_REGISTERED);
      }
    };

    if (accountStatus === null && email) {
      // prevent check both isReset2FA is null and is true
      if (isReset2FA === null || isReset2FA === true) {
        return;
      }

      checkAccountStatus(email);
    }
  }, [accountStatus, email, isReset2FA]);

  const buildRedirectUrl = (): string => {
    const url = new URL(WELCOME_ENROLLMENT);
    url.searchParams.append('redirectUrl', window.location.origin);
    return url.toString();
  };

  const nextStep = () => {
    if (step < WelcomeStepperEnum.ENTER_CODE_STEP) {
      setStep(step + 1);
    }
  };

  const previousStep = () => {
    if (step > WelcomeStepperEnum.ENROLL_STEP) {
      setStep(step - 1);
    }
  };

  const handleFieldErrors = (error) => {
    setFieldData((prevState) =>
      prevState ?
        {
          ...prevState,
          error,
        }
      : null,
    );
  };

  const sendFormData = async (formData: FieldDataInterface, handlers) => {
    const { givenName, familyName, password, token, twoFactorSecret } =
      formData;

    const message = getStringByKey(STRING_KEYS.AUTH_WELCOME_SUBMISSION_FAIL);

    try {
      // submit to north
      const endpoint = buildRedirectUrl();

      const req = await welcomeEnrollmentApi(
        endpoint,
        givenName,
        familyName,
        password,
        token,
        twoFactorSecret,
      );

      if (req.status < HttpStatusCodeEnum.BAD_REQUEST) {
        setStep(WelcomeStepperEnum.SUCCESS_STEP);
      } else {
        (handlers?.setFieldError &&
          handlers.setFieldError('password', message)) ||
          handleFieldErrors({ message });

        setStep(WelcomeStepperEnum.ENROLL_STEP);
      }
    } catch (error) {
      (handlers?.setFieldError &&
        handlers.setFieldError('password', message)) ||
        handleFieldErrors({ message });

      setStep(WelcomeStepperEnum.ENROLL_STEP);
    } finally {
      handlers?.setSubmitting(false);
    }
  };

  const onSubmit = (
    formFields: {
      familyName: string;
      givenName: string;
      password: string;
    },
    formikHelpers,
  ) => {
    const { givenName, familyName, password } = formFields;

    const { setSubmitting } = formikHelpers;

    if (hasTfa) {
      setFieldData({
        email: email || '',
        givenName,
        familyName,
        password,
        token,
      });

      setSubmitting(false);
      nextStep();
    } else {
      sendFormData(
        {
          email: email || '',
          givenName,
          familyName,
          password,
          token,
        },
        formikHelpers,
      );
    }
  };

  const form = (
    <Formik
      initialValues={{
        givenName: fieldData?.givenName || '',
        familyName: fieldData?.familyName || '',
        password: fieldData?.password || '',
      }}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, errors, values, setFieldValue, touched }) => (
        <Form
          noValidate
          css={formCss}
        >
          {isSubmitting && <Loader />}
          <div
            className={'form-container'}
            css={formContainerCss}
          >
            <h2
              css={headerTextCss}
              data-testid="welcome-title"
            >
              Welcome to Debut
            </h2>
            <p css={[loginInfoCss(), descriptionCss]}>
              {getStringByKey(STRING_KEYS.AUTH_WELCOME)}
            </p>
            <Field name="givenName">
              {({ field }) => (
                <Input
                  type="text"
                  placeholder="Enter your first name"
                  disabled={isSubmitting}
                  data-testid="welcome-givenName"
                  error={errors.givenName}
                  autoFocus
                  icon={
                    !!values.givenName ?
                      {
                        src: deleteIcon,
                        onClick: () => setFieldValue('givenName', ''),
                        'data-testid': 'welcome-clear',
                      }
                    : undefined
                  }
                  {...field}
                />
              )}
            </Field>
            <Field name="familyName">
              {({ field }) => (
                <Input
                  type="text"
                  placeholder="Enter your last name"
                  disabled={isSubmitting}
                  data-testid="welcome-familyName"
                  error={errors.familyName}
                  styles={{ containerCss }}
                  icon={
                    !!values.familyName ?
                      {
                        src: deleteIcon,
                        onClick: () => setFieldValue('familyName', ''),
                        'data-testid': 'welcome-clear',
                      }
                    : undefined
                  }
                  {...field}
                />
              )}
            </Field>
            <Field
              name="password"
              validate={(password) =>
                validatePassword(
                  password,
                  getStringByKey(STRING_KEYS.AUTH_WELCOME_INVALID_PW),
                )
              }
            >
              {({ field }) => (
                <Input
                  type={isPwdVisible ? 'text' : 'password'}
                  placeholder="Enter your password"
                  disabled={isSubmitting}
                  data-testid="login-password-input"
                  error={touched.password && errors.password}
                  styles={{ containerCss }}
                  hint={getStringByKey(STRING_KEYS.AUTH_WELCOME_PW_HINT)}
                  icon={{
                    src: isPwdVisible ? eyeOffIcon : eyeIcon,
                    onClick: togglePwdVisible,
                    'data-testid': 'login-password-toggle',
                  }}
                  {...field}
                />
              )}
            </Field>
          </div>
          {hasTfa && fieldData?.error && (
            <p className="field-error-text">{fieldData.error.message}</p>
          )}
          <div css={actionButtonContainerCss}>
            <Button
              type="submit"
              theme="primary"
              data-testid="welcome-submit"
              disabled={
                (
                  !values.givenName ||
                  !values.familyName ||
                  !values.password ||
                  errors.password
                ) ?
                  true
                : false
              }
              css={actionButtonCss}
            >
              Register
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );

  const successInfo = (
    <div
      css={[successInfoContainerCss, successContainerCss]}
      className="success-info col-sm-12"
    >
      <h2
        css={headerTextCss}
        data-testid="welcome-success-title"
      >
        {getStringByKey(STRING_KEYS.AUTH_WELCOME_SUCCESS_TITLE)}
      </h2>
      <p css={[loginInfoCss(), descriptionCss]}>
        {getStringByKey(STRING_KEYS.AUTH_WELCOME_SUCCESS)}
      </p>
      <div css={actionButtonContainerCss}>
        <Link
          to={routes.LOGIN_LANDING}
          data-testid="welcome-success-link"
        >
          <Button
            css={actionButtonCss}
            theme="primary"
          >
            Back to Log In
          </Button>
        </Link>
      </div>
    </div>
  );

  const renderPage = () => {
    if (step === WelcomeStepperEnum.ENROLL_STEP) {
      return form;
    }

    if (
      step >= WelcomeStepperEnum.INFO_STEP &&
      step <= WelcomeStepperEnum.ENTER_CODE_STEP
    ) {
      return (
        <TwoFactorAuth
          currentStep={step}
          fieldData={fieldData}
          onNext={nextStep}
          sendData={sendFormData}
          handleFieldErrors={handleFieldErrors}
          isReset2FA={isReset2FA}
          setStep={setStep}
        />
      );
    }

    if (step === WelcomeStepperEnum.SUCCESS_STEP) {
      return successInfo;
    }
  };

  const renderLayout = () => {
    if (accountStatus === AccountStatusEnum.ENABLED) {
      return <Redirect to={routes.LOGIN_LANDING} />;
    } else {
      return (
        <LayoutContainer
          showTopBack={
            step > WelcomeStepperEnum.ENROLL_STEP &&
            step !== WelcomeStepperEnum.SUCCESS_STEP &&
            !windowSize.isDesktop
          }
          onBack={previousStep}
          showBack={
            windowSize.isDesktop && step > WelcomeStepperEnum.ENROLL_STEP
          }
          auth
          styles={{ layoutRowContainerCss: rootCss({ step }) }}
          className={{
            rightPanelClassName: 'col-md-12',
          }}
        >
          {showAlert && (
            <Alert
              isOpen={showAlert}
              onClick={() => setShowAlert(false)}
              title="Your account is disabled"
              infoText={getStringByKey(STRING_KEYS.ERROR_ACCOUNT_DISABLED)}
              primaryButtonText="Ok"
              data-testid="account-disabled-alert"
            />
          )}
          {renderPage()}
        </LayoutContainer>
      );
    }
  };

  return renderLayout();
};

export default Welcome;
