import { EntitlementTypeEnum } from 'models/enums';
import React, { useCallback, useEffect, useState } from 'react';
import type { AlertModalInterface } from 'components/alert';
import Alert from 'components/alert';
import {
  CONFIG_KEYS,
  STRING_KEYS,
  useStringsContext,
} from 'providers/strings-provider';
import { getBrowserCompatability } from 'containers/browser-compatibility/utils';
import { useDispatch, useSelector } from 'react-redux';
import { tokenSelector } from 'store/slices/user';
import { useLocation } from 'react-router-dom';
import { fetchPage } from 'store/slices/page';
import { Modal, Player } from 'components';
import { playerModalStylesWithTheaterMode } from 'components/player/custom-ui/theater-mode-button';
import type {
  OpenPlayerInterface,
  PlayerContextPropsInterface,
  PlayerProviderPropsInterface,
} from 'models/interfaces/player-provider';
import { PlayerEmitterEventEnum } from 'models/interfaces/player-provider';
import clsx from 'clsx';
import styles from './styles';
import { css } from '@emotion/core';
import { changePageOpacity, checkIsExpired } from './helpers';

export const PlayerContext = React.createContext<PlayerContextPropsInterface>({
  /* @ts-ignore TODO: TS2322: Type null is not assignable to type OpenPlayerInterface. */
  openPlayer: null,
  /* @ts-ignore TODO: TS2322: Type null is not assignable to type () => void. */
  closePlayer: null,
  ui: {
    isMiniPlayer: false,
    /* @ts-ignore TODO: TS2322: Type null is not assignable to type () => void. */
    toggleMiniPlayer: null,
    /* @ts-ignore TODO: TS2322: Type null is not assignable to type () => void. */
    miniPlayerOff: null,
    /* @ts-ignore TODO: TS2322: Type null is not assignable to type () => void. */
    miniPlayerOn: null,
  },
  /* @ts-ignore TODO: TS2322: Type null is not assignable to type (isFyc: boolean) => void. */
  setIsFyc: null,
  /* @ts-ignore TODO: TS2322: Type null is not assignable to type (accessToken: string) => void. */
  setFycAccessToken: null,
  isCheckingExpired: false,
  isExpired: false,
  /* @ts-ignore TODO: TS2322: Type null is not assignable to type (expired: boolean) => void. */
  setIsExpired: null,
});

export const usePlayer = (): PlayerContextPropsInterface => {
  const context = React.useContext(PlayerContext);
  if (context === undefined) {
    throw new Error('usePlayer must be used within a PlayerProvider');
  }
  return context;
};

const PlayerProvider: React.FC<PlayerProviderPropsInterface> = ({
  children,
  cast,
}) => {
  const [isFyc, setIsFyc] = useState(false);
  const [isFycPublic, setIsFycPublic] = useState(false);
  const [fycAccessToken, setFycAccessToken] = useState('');
  const [isMiniPlayer, setIsMiniPlayer] = useState<boolean>(false);
  const [alertState, setAlertState] = useState<AlertModalInterface>({
    isOpen: false,
  });
  const [showPlayer, setShowPlayer] = useState<boolean>(false);
  /* @ts-ignore TODO: TS2345: Argument of type null is not assignable to parameter of type string | (() => string). */
  const [playTarget, setPlayTarget] = useState<string>(null);
  const [playData, setPlayData] = useState<any>(null);
  const [restart, setRestart] = useState<boolean>(false);
  let { accessToken } = useSelector(tokenSelector);
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { getStringByKey, getConfigByKey } = useStringsContext();

  if (isFyc) {
    accessToken = fycAccessToken;
  }

  let unsupportedBrowsersList = [];

  try {
    unsupportedBrowsersList = JSON.parse(
      /* @ts-ignore TODO: TS2345: Argument of type string | undefined is not assignable to parameter of type string. */
      getConfigByKey(CONFIG_KEYS.UNSUPPORTED_BROWSERS_LIST),
    );
  } catch (e) {
    unsupportedBrowsersList = [];
  }

  const toggleMiniPlayer = () => {
    setIsMiniPlayer((prev) => !prev);
  };

  const miniPlayerOff = () => {
    setIsMiniPlayer(false);
  };

  const miniPlayerOn = () => {
    setIsMiniPlayer(true);
  };

  const openPlayer: OpenPlayerInterface = (
    target,
    component,
    options,
  ): void => {
    let playbackSeason,
      episodeTitle,
      currentComponent = component;

    if (component.type === EntitlementTypeEnum.SERIES.toUpperCase()) {
      playbackSeason = component.seasons?.find((season) => {
        return season.episodes?.find((episode) => {
          if (episode?.episodeId === component?.episodeId) {
            episodeTitle = episode.title;
          }
          return episode?.episodeId === component?.episodeId;
        });
      });

      currentComponent = {
        ...component,
        seasonNumber: playbackSeason.seasonNumber,
        episodeTitle,
      };
    }

    if (options?.isFycPublic) {
      setIsFycPublic(true);
    } else {
      setIsFycPublic(false);
    }

    if (getBrowserCompatability(unsupportedBrowsersList, currentComponent)) {
      setShowPlayer(true);
      setPlayTarget(target);
      setPlayData(currentComponent);

      if (options?.restart) {
        setRestart(true);
      } else {
        setRestart(false);
      }
    } else {
      setAlertState({
        isOpen: true,
        title: 'Browser not supported',
        infoText: getStringByKey(STRING_KEYS.BROWSER_UNSUPPORTED),
        onClick: () =>
          setAlertState((prevState) => ({ ...prevState, isOpen: false })),
        primaryButtonText: 'Dismiss',
      });
    }

    // fix SR-7984 Chrome issue
    changePageOpacity(
      getConfigByKey(CONFIG_KEYS.FF_CHROME_UI_ISSUE) === 'true',
      '0.99',
    );
  };

  const closePlayer = (): void => {
    window.dispatchEvent(
      new CustomEvent(PlayerEmitterEventEnum.PLAYER_CLOSE, {
        detail: {
          type: PlayerEmitterEventEnum.PLAYER_CLOSE,
        },
      }),
    );

    // fix SR-7984 Chrome issue
    changePageOpacity(
      getConfigByKey(CONFIG_KEYS.FF_CHROME_UI_ISSUE) === 'true',
      '',
    );
  };

  const [isCheckingExpired, setIsCheckingExpired] = useState(false);
  const [isExpired, setIsExpired] = useState(false);
  const onSessionDeleted = useCallback(async () => {
    setIsCheckingExpired(true);
    let expired;
    if (!isFycPublic) {
      /* @ts-ignore TODO: TS2345: Argument of type string | null | undefined is not assignable to parameter of type string. */
      expired = await checkIsExpired(pathname, accessToken);
    }
    setIsCheckingExpired(false);

    setShowPlayer(false);
    /* @ts-ignore TODO: TS2345: Argument of type null is not assignable to parameter of type SetStateAction<string>. */
    setPlayTarget(null);
    setPlayData(null);
    // if expired, not allow the next dispatch
    if (expired) {
      setIsExpired(true);
      return;
    }

    if (isFycPublic) {
      // dispatch(fetchFycPage({ slug: pathname }));
      return;
    }

    dispatch(
      fetchPage({
        slug: pathname,
        accessToken,
      }),
    );
  }, [accessToken, dispatch, pathname, isFycPublic]);

  useEffect(() => {
    setIsExpired(false);
  }, [pathname]);

  useEffect(() => {
    // Only do things if we are logged in
    if (accessToken || isFycPublic) {
      window.addEventListener(
        PlayerEmitterEventEnum.SESSION_DELETED,
        onSessionDeleted,
      );
      return () => {
        window.removeEventListener(
          PlayerEmitterEventEnum.SESSION_DELETED,
          onSessionDeleted,
        );
      };
    }
  }, [onSessionDeleted, accessToken, isFycPublic]);

  return (
    <PlayerContext.Provider
      value={{
        openPlayer: openPlayer,
        closePlayer: closePlayer,
        ui: {
          isMiniPlayer: isMiniPlayer,
          toggleMiniPlayer: toggleMiniPlayer,
          miniPlayerOff: miniPlayerOff,
          miniPlayerOn: miniPlayerOn,
        },
        setIsFyc,
        setFycAccessToken,
        isCheckingExpired,
        isExpired,
        setIsExpired,
      }}
    >
      {alertState?.isOpen && <Alert {...alertState} />}

      {(accessToken || isFycPublic) && showPlayer && (
        <Modal
          css={css([playerModalStylesWithTheaterMode, styles])}
          isOpen={showPlayer}
          onClose={closePlayer}
          classNames={clsx(['video-player-modal', isMiniPlayer && 'ui-mini'])}
        >
          <Player
            playId={playTarget}
            autoplay={true}
            playData={playData}
            restart={restart}
            closePlayer={closePlayer}
            cast={cast}
            isFyc={isFyc}
            fycAccessToken={fycAccessToken}
            isFycPublic={isFycPublic}
          />
        </Modal>
      )}
      {children}
    </PlayerContext.Provider>
  );
};

export default PlayerProvider;
