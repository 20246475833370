/** @jsx jsx */
import { css } from '@emotion/core';
import { mediaQuery, generateStyle } from 'utils/styles';
import theme from 'styles/theme';

export const headerTextCss = (styles?: any) => {
  const defaultCss = `
  font-size: 2rem;
  margin: 0.85rem 0;
  letter-spacing: 1;

  ${mediaQuery(theme.breakpoints.lg)} {
    font-size: 3rem;
    margin: 1.3rem 0;
    letter-spacing: 0;
  }
`;

  return generateStyle({
    defaultStyle: defaultCss,
    extendedStyles: styles,
    key: 'headerTextCss',
    theme,
  });
};

export const actionButtonCss = css`
  font-weight: 100;
  margin-top: 0;
  ${mediaQuery(theme.breakpoints.md)} {
    width: 100%;
  }
`;

export const actionButtonContainerCss = (styles?: any) => {
  const defaultCss = `
  margin-top: 1.55rem;
  ${mediaQuery(theme.breakpoints.md)} {
    width: 20.6rem;
  }
`;
  return generateStyle({
    defaultStyle: defaultCss,
    extendedStyles: styles,
    key: 'actionButtonContainerCss',
    theme,
  });
};

export const loginInfoCss = (styles?: any) => {
  const defaultCss = `
  margin-top: 2.6rem;
  margin-bottom: 0;
`;

  return generateStyle({
    defaultStyle: defaultCss,
    extendedStyles: styles,
    key: 'loginInfoCss',
    theme,
  });
};

export const successInfoContainerCss = css`
  padding: 0;
  ${mediaQuery(theme.breakpoints.md)} {
    padding: 0 4rem 0 0;
  }
`;

export const hintCss = css`
  font-size: 0.75rem;
  opacity: 0.67;

  ${mediaQuery(theme.breakpoints.md)} {
    max-width: 20.6rem;
  }
`;

export const contentCss = {
  color: theme.palette.text.secondary,
  marginTop: '3rem',
  marginBottom: '3rem',
};

export const labelCss = (isChecked, styles?: any) => {
  const defaultCss = `
  position: relative;
  color: ${!isChecked && theme.palette.text.disabled};
  display: flex;
  align-items: center;
  margin-top: 2rem;
  font-size: 0.875rem;
  width: 100%;
`;

  return generateStyle({
    defaultStyle: defaultCss,
    extendedStyles: styles,
    key: 'labelCss',
    theme,
  });
};

export const checkboxCss = css`
  appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid ${theme.palette.text.secondary};
  border-radius: 2px;
  cursor: pointer;
  margin-right: 10px;

  :checked ~ .checkmark {
    background: ${theme.palette.secondary.light};
  }

  :checked ~ .checkmark:after {
    display: block;
    left: 7px;
    top: 3px;
    width: 7px;
    height: 12px;
    border: solid black;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

export const checkmarkCss = css`
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 2px;

  :after {
    content: '';
    position: absolute;
    display: none;
  }
`;

export const footerContainerCss = (styles?: any) => {
  const defaultStyle = `
  padding-left: ${theme.padding.footer.sides.xs};
  padding-right: ${theme.padding.footer.sides.xs};

  ${mediaQuery(theme.breakpoints.md)} {
    padding-left: ${theme.padding.footer.sides.md};
    padding-right: ${theme.padding.footer.sides.md};
  }
  ${mediaQuery(theme.breakpoints.lg)} {
    padding-left: ${theme.padding.footer.sides.lg};
    padding-right: ${theme.padding.footer.sides.lg};
  }
`;
  return generateStyle({
    defaultStyle,
    extendedStyles: styles,
    key: 'footerContainerCss',
    theme,
  });
};

export const modalOuterCss = css`
  width: 100%;
`;

export const loginMyIDText = css`
  font-weight: 300;
  font-size: ${17 / 16}rem;
  color: ${theme.palette.common.blue};
  letter-spacing: 0px;
  line-height: 22px;
  text-decoration: none;
`;

export const errorLabelText = css`
  font-size: 0.75rem;
  color: #e4615d;
  margin: 1rem 0;
  text-transform: capitalize;
`;

export const submitOptionCss = css`
  opacity: 0.67;
  text-align: center;
  margin: 1rem 0 0;
`;
export const formCss = css`
  max-width: 350px;
`;

export const unsupportedBrowserContainer = css`
  color: #f2f2f2;
`;

export const optionsBox = css`
  color: #fff;
  border: 0.5px solid #e4e4e4;
  border-radius: 4px;
  // box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05), 0px 3px 1px -2px rgba(0, 0, 0, 0.07), 0px 1px 5px rgba(0, 0, 0, 0.1);
`;
