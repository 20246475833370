import type {
  NSEpisodeInterface,
  NSFycSeriesComponentInterface,
} from 'models/cms';

export interface IOptionInterface {
  label: string;
  value: number;
}

// @ts-ignore TODO: TS7006: Parameter 'series' implicitly has an 'any' type.
export const buildLabel = (series) => {
  let label = `Season ${series.seasonNumber} (${series.episodes.length}`;

  if (series.episodes.length <= 1) {
    label = label.concat(' Episode)');
  } else {
    label = label.concat(' Episodes)');
  }

  return label;
};

export const buildOptions = (
  component: NSFycSeriesComponentInterface,
): IOptionInterface[] | undefined => {
  if (!component?.seasons) {
    return;
  }

  return component.seasons.map((season) => ({
    value: season.seasonNumber ?? 0,
    label: buildLabel(season),
  }));
};

export const getCurrentOption = (
  options: IOptionInterface[] | undefined,
  currentSeason: number | undefined,
): IOptionInterface | undefined => {
  if (!options) {
    return;
  }

  const option = options.find((o) => o.value === currentSeason);
  return option ? option : options[0];
};

export const getCurrentEpisodesArr = (
  component: NSFycSeriesComponentInterface,
  seasonNum: number,
): NSEpisodeInterface[] | null | undefined => {
  const season = component.seasons?.find((s) => s.seasonNumber === seasonNum);
  return season ? season.episodes : [];
};
