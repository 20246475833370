import React from 'react';
import Typography from 'components/typography';
import { TypographyStyleTypeEnum } from 'models/enums';
import { css } from '@emotion/core';

export const toastTypographyStyles = css`
  color: black;
  margin: 0;
`;

const ToastTypography: React.FC<{
  children: React.ReactNode;
  className?: string;
}> = ({ children, ...props }) => {
  return (
    <Typography
      style={TypographyStyleTypeEnum.BODY}
      additionalCss={toastTypographyStyles}
      {...props}
    >
      {children}
    </Typography>
  );
};

export default ToastTypography;
