import css from '@emotion/css';
import type { SerializedStyles } from '@emotion/utils';
import type LocalStorageKeysEnum from 'models/enums/local-storage-keys-enum';
import { isArray } from 'lodash';
import type {
  NSAwardsCategoryInterface,
  NSCastInterface,
  NSFycSeriesComponentInterface,
  NSImageInterface,
  NSPageComponentInterface,
  NSRelatedContentComponentInterface,
  NSSeriesComponentInterface,
} from 'models/cms';
import type { EntitlementComponentInterface } from 'models/components/entitlement-component-interface';
import { ComponentTypeEnum } from 'models/enums';
import type {
  ICategoriesInterface,
  ICategoriesItemInterface,
  ICategoriesListInterface,
} from 'models/interfaces';
import type * as React from 'react';

export const setLocalStorage = (key: LocalStorageKeysEnum, value: unknown) => {
  try {
    const stringified = JSON.stringify(value);
    window?.localStorage.setItem(key, stringified);
  } catch (e) {}
};

export const getLocalStorage = (
  key: LocalStorageKeysEnum,
): undefined | unknown => {
  const value = window?.localStorage.getItem(key);
  if (!value) {
    return;
  }

  try {
    return JSON.parse(value);
  } catch (e) {
    return;
  }
};

export const removeLocalStorage = (key: LocalStorageKeysEnum) => {
  window?.localStorage.removeItem(key);
};

export const retrieveAndRemoveLocalStorageValue = (
  key: LocalStorageKeysEnum,
) => {
  const value = getLocalStorage(key);

  if (!value) {
    return undefined;
  }

  removeLocalStorage(key);
  return value;
};

/**
 * converts number of mintues to hours and mitnutes
 * @param mins (e.g. 90)
 * @returns string (e.g. 1h 30min)
 */
export const minsToTime = (mins: number): string | null => {
  if (!mins) {
    return null;
  }

  if (mins < 60) {
    return `${mins}min`;
  }

  const hours = Math.floor(mins / 60);
  const minutes = mins % 60;
  return `${hours}h` + (minutes ? ` ${minutes}min` : '');
};

/**
 * Generate random integer in range
 * @param minInput (e.g. 10)
 * @param maxInput (e.g. 50)
 * @returns number (e.g. 35)
 */
export const getRandomInt = (minInput, maxInput) => {
  const min = Math.ceil(minInput);
  const max = Math.floor(maxInput);

  return Math.floor(Math.random() * (max - min)) + min;
};

interface MDPDataInterface {
  accolades?: NSAwardsCategoryInterface[] | null;
  awardCategories?: NSAwardsCategoryInterface[] | null;
  credits?: NSCastInterface[] | null;
  relatedContents?: NSRelatedContentComponentInterface[] | null;
  series?: NSSeriesComponentInterface | NSFycSeriesComponentInterface;
  synopsis?: string | null;
}

/** @deprecated */
export const isMDPDataValid = ({
  credits,
  synopsis,
  awardCategories,
  series,
  relatedContents,
  accolades,
}: MDPDataInterface): boolean => {
  if (credits && isArray(credits) && credits.length > 0) {
    return true;
  } else if (synopsis && typeof synopsis === 'string' && synopsis.length > 0) {
    return true;
  } else if (
    awardCategories &&
    isArray(awardCategories) &&
    awardCategories.length > 0
  ) {
    return true;
  } else if (accolades && isArray(accolades) && accolades.length > 0) {
    return true;
  } else if (
    series &&
    isArray(series.seasons) &&
    series.seasons.length > 0 &&
    isArray(series.seasons[0].episodes) &&
    // @ts-ignore TODO: TS18049: 'season.episodes' is possibly 'null' or 'undefined'.
    series.seasons.some((season) => season.episodes.length > 0)
  ) {
    return true;
  } else if (
    relatedContents &&
    relatedContents.length &&
    relatedContents.length > 0 &&
    relatedContents[0].type === ComponentTypeEnum.RELATED_CONTENT &&
    isArray(relatedContents[0].items) &&
    relatedContents[0].items.length > 0
  ) {
    return true;
  }

  return false;
};

interface ProgressInterface {
  inSeconds?: boolean;
  position: number;
  runtime: number;
}
export const getEntitlementProgress = ({
  position,
  runtime,
  inSeconds,
}: ProgressInterface): number | undefined => {
  if (position && runtime) {
    return (position / (runtime * (inSeconds ? 1 : 60))) * 100;
  }
  return undefined;
};

export const isHeroImageValid = (heroImage: NSImageInterface): boolean => {
  if (!heroImage) {
    return false;
  }

  return true;
};

export const getEntitlements = (components) => {
  if (Array.isArray(components)) {
    return components.find((c) => c.type === ComponentTypeEnum.ENTITLEMENTS);
  } else {
    if (components.type.includes(ComponentTypeEnum.ENTITLEMENTS)) {
      return components;
    }
  }
};

export const getEntitlementLayout = (hasPlainTag, totalEntitlementCount) => {
  const MAX_ENTITLEMENTS_LIMIT = 3;

  if (hasPlainTag) {
    return true;
  }

  if (typeof totalEntitlementCount === 'number') {
    return totalEntitlementCount > MAX_ENTITLEMENTS_LIMIT;
  }
};

export const calculateAspectRatioOfHeight = (
  aspectRatio: string | '16:9' | '4:3' | '3:1' | '2:3',
  width: number,
): { aspectRatio: string; height: number; width: number } | undefined => {
  if (
    !aspectRatio ||
    !width ||
    typeof aspectRatio !== 'string' ||
    typeof width !== 'number'
  ) {
    return;
  }

  const list = aspectRatio.split(':');
  const height = (width * Number(list[1])) / Number(list[0]);

  return {
    aspectRatio,
    width,
    height,
  };
};

export const treatmentContainerStyles = ({
  height,
}: {
  height: number;
}): string | SerializedStyles => css`
  height: ${height}px;
`;

export const buildTitle = (episode: EntitlementComponentInterface): string => {
  let title = `${episode.episodeNumber}. ${episode.title}`;

  if (episode.runtime) {
    title = title.concat(` (${episode.runtime} min)`);
  }

  return title;
};

/**
 * Return true if the system supports the FairPlay DRM encryption scheme.
 */
export const detectFairPlay = (): boolean => {
  const webKitMediaKeys = (window as any).WebKitMediaKeys;
  return typeof webKitMediaKeys?.isTypeSupported === 'function' ?
      webKitMediaKeys.isTypeSupported('com.apple.fps.1_0', 'video/mp4')
    : false;
};

/**
 * Return true if the specified media type is supported by the sytem.
 * @param mediaType example: video/mp4; codecs="ec-3"
 */
export const isMediaSourceSupported = (mediaType: string): boolean => {
  return (
    (window.MediaSource && window.MediaSource.isTypeSupported(mediaType)) ||
    ((window as any).WebKitMediaSource &&
      (window as any).WebKitMediaSource.isTypeSupported(mediaType))
  );
};

/**
 * Return the play id rather than the full string
 * @param playTarget example: play/7df07a26-dada-444d-8391-4883c3e53fb
 */
export const getPlayId = (playTarget: string): string | null | undefined => {
  if (!playTarget) {
    return null;
  }

  return playTarget.split('/')?.pop();
};

/**
 * Return boolean for whether email is valid or not
 * @param email Email address
 */
export const isEmailValid = (email: string): boolean => {
  return email.match(/^\S+@\S+$/) !== null;
};

export const getComponentsByType = (
  components: NSPageComponentInterface[] | null | undefined,
  type: ComponentTypeEnum,
): NSPageComponentInterface[] | null => {
  if (!components || components.length === 0) {
    return null;
  }

  return components.filter((c) => c.type === type);
};

export function isNSSeriesComponent(
  component: NSPageComponentInterface,
): component is NSSeriesComponentInterface {
  return (
    (component as NSSeriesComponentInterface).type === ComponentTypeEnum.SERIES
  );
}

export const numOfSeasons = (
  component: NSSeriesComponentInterface | NSFycSeriesComponentInterface,
) => {
  const num = component.seasons?.length;

  if (!num) {
    return null;
  }

  return `${num} Season${num > 1 ? 's' : ''}`;
};

export const categoriesTabData = (
  data: any,
  accolades?: boolean,
  isPlainView?: boolean,
): ICategoriesInterface => {
  if (accolades && isPlainView) {
    return { display: false };
  }

  const categories = accolades ? data?.accolades || [] : data?.categories || [];

  if (!categories.length) {
    return {
      display: false,
    };
  }

  const lists: ICategoriesListInterface = {};
  const result: ICategoriesItemInterface[][] = [];

  categories.forEach((c: ICategoriesItemInterface) => {
    if (c.organization in lists) {
      lists[c.organization].push(c);
    } else {
      lists[c.organization] = [c];
    }
  });

  for (const list of Object.entries(lists)) {
    result.push(list[1]);
  }

  return {
    display: true,
    result,
  };
};

export async function copyInputValue(ev: React.MouseEvent<HTMLInputElement>) {
  const inputEl = ev.currentTarget;

  try {
    const text = inputEl.value;
    await navigator.clipboard.writeText(text);
  } catch (err) {
    inputEl.select();
    document.execCommand('copy');
  }
}
