/** @jsx jsx */
import type { SerializedStyles } from '@emotion/core';
import { jsx } from '@emotion/core';
import clsx from 'clsx';
import { textFitCss } from './styles';
import React from 'react';
import TextFit from 'react-textfit';

interface WatermarkTextPropsInterface {
  center?: boolean;
  children: string | null | undefined;
  className?: any;
  css?: SerializedStyles | SerializedStyles[] | string;
  styles?: any;
}

const WatermarkText: React.FC<WatermarkTextPropsInterface> = ({
  children,
  css,
  className,
  center = false,
}): React.ReactElement<HTMLParagraphElement> => {
  return (
    <TextFit
      mode="single"
      forceSingleModeWidth={false}
      className={clsx([
        'watermark-textfit',
        center && 'watermark-textfit--center',
      ])}
      max={300}
    >
      <p
        className={clsx(['watermark-text', className])}
        css={[textFitCss, css]}
      >
        {children}
      </p>
    </TextFit>
  );
};

export default WatermarkText;
