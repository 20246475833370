import type { PlayerAPI } from 'bitmovin-player';
import type { UIInstanceManager } from 'bitmovin-player-ui';
import type { ButtonConfig } from 'bitmovin-player-ui/dist/js/framework/components/button';
import { Button } from 'bitmovin-player-ui/dist/js/framework/components/button';

export interface CustomPlayerToggleButtonConfigInterface extends ButtonConfig {
  onClick: () => void;
}

export class CustomPlayerToggleButton extends Button<CustomPlayerToggleButtonConfigInterface> {
  constructor(config: CustomPlayerToggleButtonConfigInterface) {
    super(config);

    this.config = this.mergeConfig(
      config,
      {
        cssClass: 'player-ui-toggle-button',
      } as CustomPlayerToggleButtonConfigInterface,
      this.config,
    );
  }

  configure(player: PlayerAPI, uimanager: UIInstanceManager): void {
    super.configure(player, uimanager);

    const config = this.getConfig();

    this.onClick.subscribe(() => {
      config.onClick();
    });
  }
}
