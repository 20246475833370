/** @jsx jsx */
import type { SerializedStyles } from '@emotion/core';
import { jsx, css } from '@emotion/core';
import LeftArrowIcon from 'components/fyc-component/fyc-slider/left-arrow-icon';
import type { SliderConfigInterface } from 'providers/fyc-theme-component-interfaces';
import { FycConfigsEnum } from 'providers/fyc-theme-component-interfaces';
import { getVersionedComponentConfig } from 'providers/versions/fyc-theme-provider-utils';
import React, { useState, useRef, useEffect, useMemo } from 'react';
import NsImage from 'components/ns-component/ns-image';
import {
  imgWrapperCss,
  imgContainerCss,
  rightArrow,
  wrapperCss,
  leftArrow,
  imgNTitleWrapperCss,
  titleNameCss,
} from './styles';
import ChevronRightIcon from 'public/images/Slider_Chevron_Right.png';
import { getPageTarget } from '../../../utils/fyc';
import { useHistory } from 'react-router-dom';
import {
  calculateMaxArrowClickedNumber,
  calculateTransformValue,
  resetSliderPosition,
} from './helpers';
import { FYC_MOBILE_WIDTH, FYC_TABLET_WIDTH } from 'pages/fyc-page/helpers';
import Bowser from 'bowser';
import type { FycVersionType } from 'providers/fyc-theme-provider';
import {
  ABC,
  DBT,
  DISNEYPLUS,
  DTS,
  FREEFORM,
  FX,
  HULU,
  NATGEO,
  SEARCHLIGHT,
  SEARCHLIGHT_AWARDS,
  TWDS_AWARDS,
  useFycThemeContext,
} from 'providers/fyc-theme-provider';
import OverlayNextArrow from 'public/images/fyc-arrow-next.svg';
import OverlayNextArrowWhite from 'public/images/fyc-arrow-next-white.svg';
import ArrowYellow from 'public/images/ArrowYellow.svg';
import { TWDS_AWARDS_CARD_BG } from 'styles/fyctheme';

interface PropsInterface {
  category: string;
  data: any;
  hasPlain: boolean;
}

const nsImageExtendedCss = {
  nsImageContainerCss: css`
    border-radius: 6px;
    box-shadow: 10px;
  `,
};

const plainViewCss = (version: FycVersionType): SerializedStyles => {
  const customCssConfig = getVersionedComponentConfig<
    SliderConfigInterface['plainViewCss']
  >({
    version,
    component: FycConfigsEnum.SLIDER_CONFIG,
    selector: 'plainViewCss',
  });

  let topBg =
    customCssConfig?.background ??
    `linear-gradient(
    359.43deg,
    rgba(0, 78, 146, 0.46) -294.8%,
    #000428 34.06%,
    #004e92 276.33%
  )`;

  if (version === TWDS_AWARDS) {
    topBg = TWDS_AWARDS_CARD_BG;
  }

  return css`
    width: 100%;
    height: 100%;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;

    background: linear-gradient(180deg, #404461 0%, #232434 100%);
    box-shadow:
      0px 4px 5px rgba(0, 0, 0, 0.14),
      0px 1px 10px rgba(0, 0, 0, 0.12),
      0px 2px 4px -1px rgba(0, 0, 0, 0.2);
    label: plainViewCss;

    .top {
      width: 100%;
      height: 100%;
      border-radius: 3px;
      padding: 0 13.3%;

      display: flex;
      justify-content: center;
      align-items: center;

      background: ${topBg};
    }
  `;
};

const FycSlider: React.FC<PropsInterface> = ({ data, hasPlain }) => {
  const version = useFycThemeContext()?.version;
  const parentRef = useRef<HTMLDivElement | null>(null);
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const clientXRef = useRef<number | null>(null);

  const [arrowClickedNumber, setArrowClickedNumber] = useState(0);
  const [maxArrowClickedNumber, setMaxArrowClickedNumber] = useState(0);

  const [isFYCMobile, setIsFYCMobile] = useState(false);
  const [isFYCTablet, setIsFYCTablet] = useState(false);
  const [hasOverlay, setHasOverlay] = useState(false);

  const history = useHistory();

  const sliderVersionConfig = useMemo(
    () =>
      getVersionedComponentConfig<SliderConfigInterface>({
        version,
        component: FycConfigsEnum.SLIDER_CONFIG,
      }),
    [version],
  );

  useEffect(() => {
    setHasOverlay(!!sliderVersionConfig?.hasOverlay);
  }, [sliderVersionConfig]);

  const arrowClick = (isRightClicked: boolean): void => {
    if (isRightClicked) {
      if (arrowClickedNumber + 1 > maxArrowClickedNumber) {
        return;
      }
      setArrowClickedNumber(arrowClickedNumber + 1);
    } else {
      if (arrowClickedNumber - 1 < 0) {
        return;
      }
      setArrowClickedNumber(arrowClickedNumber - 1);
    }
  };

  const getDeviceWithType = (): void => {
    setArrowClickedNumber(0);
    setIsFYCMobile(false);
    setIsFYCTablet(false);

    const { innerWidth } = window;
    if (innerWidth < FYC_MOBILE_WIDTH) {
      setIsFYCMobile(true);
      return;
    }
    if (innerWidth < FYC_TABLET_WIDTH) {
      setIsFYCTablet(true);
      return;
    }
  };

  const onTouchOrMouseStart = (e: React.SyntheticEvent): void => {
    if (e.nativeEvent instanceof TouchEvent) {
      clientXRef.current = e.nativeEvent.touches[0].clientX;
    }

    if (e.nativeEvent instanceof MouseEvent) {
      clientXRef.current = e.nativeEvent.clientX;
    }
  };

  const onTouchOrMouseEnd = (e: React.SyntheticEvent): void => {
    if (!clientXRef.current) {
      return;
    }

    if (e.nativeEvent instanceof TouchEvent) {
      if (e.nativeEvent.changedTouches[0].clientX - clientXRef.current > 40) {
        arrowClick(false);
      } else if (
        e.nativeEvent.changedTouches[0].clientX - clientXRef.current <
        -40
      ) {
        arrowClick(true);
      }
    }

    if (e.nativeEvent instanceof MouseEvent) {
      if (e.nativeEvent.clientX - clientXRef.current > 0) {
        arrowClick(false);
      }

      if (e.nativeEvent.clientX - clientXRef.current < 0) {
        arrowClick(true);
      }
    }

    clientXRef.current = null;
  };

  useEffect(() => {
    resetSliderPosition(setArrowClickedNumber);

    setArrowClickedNumber(0);
    setMaxArrowClickedNumber(0);

    calculateMaxArrowClickedNumber(
      data,
      setMaxArrowClickedNumber,
      isFYCMobile,
      isFYCTablet,
    );
  }, [data, isFYCMobile, isFYCTablet]);

  useEffect(() => {
    const onResize = (): void => {
      resetSliderPosition(setArrowClickedNumber);
      getDeviceWithType();
    };

    getDeviceWithType();

    if (Bowser.parse(window.navigator.userAgent).platform.type !== 'desktop') {
      return;
    }

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return (
    <div
      ref={parentRef}
      css={wrapperCss}
      onTouchStart={(e) => onTouchOrMouseStart(e)}
      onTouchEnd={(e) => onTouchOrMouseEnd(e)}
      onMouseDown={(e) => onTouchOrMouseStart(e)}
      onMouseUp={(e) => onTouchOrMouseEnd(e)}
    >
      {arrowClickedNumber > 0 && (
        <div
          css={leftArrow}
          onClick={() => arrowClick(false)}
        >
          <img src={ChevronRightIcon} />
        </div>
      )}
      {arrowClickedNumber < maxArrowClickedNumber && (
        <div
          css={rightArrow}
          onClick={() => arrowClick(true)}
        >
          <img src={ChevronRightIcon} />
        </div>
      )}
      <div
        ref={scrollRef}
        css={imgWrapperCss}
        style={calculateTransformValue(
          scrollRef,
          arrowClickedNumber,
          isFYCMobile,
          isFYCTablet,
        )}
      >
        {data?.items?.length > 0 &&
          data?.items.map((item) => (
            <div
              css={imgNTitleWrapperCss(version)}
              key={item.id}
            >
              <div
                onClick={() => {
                  history.push(`/${getPageTarget(item)}`);
                }}
                css={imgContainerCss(hasPlain, version)}
                key={item.id}
              >
                {(version === ABC ||
                  version === FREEFORM ||
                  version === DBT ||
                  version === DISNEYPLUS ||
                  version === DTS ||
                  version === HULU ||
                  version === SEARCHLIGHT ||
                  version === SEARCHLIGHT_AWARDS ||
                  version === NATGEO ||
                  version === FX ||
                  hasOverlay) && (
                  <div className="overlay">
                    <div>
                      {hasOverlay && (
                        <LeftArrowIcon
                          arrowColor={
                            sliderVersionConfig?.leftArrowIcon?.arrowColor
                          }
                          circleFill={
                            sliderVersionConfig?.leftArrowIcon?.circleFill
                          }
                          iconWidth={
                            sliderVersionConfig?.leftArrowIcon?.iconWidth
                          }
                        />
                      )}
                      {(version === ABC ||
                        version === DBT ||
                        version === DISNEYPLUS ||
                        version === DTS ||
                        version === HULU ||
                        version === SEARCHLIGHT ||
                        version === SEARCHLIGHT_AWARDS ||
                        version === FX) && <img src={OverlayNextArrow} />}
                      {version === FREEFORM && (
                        <img src={OverlayNextArrowWhite} />
                      )}
                      {version === NATGEO && <img src={ArrowYellow} />}
                    </div>
                  </div>
                )}
                {hasPlain ?
                  <div css={plainViewCss(version)}>
                    <div className="top">
                      {item.titleTreatmentImage?.url ?
                        <NsImage
                          url={item.titleTreatmentImage?.url}
                          width={1200}
                          aspectRatio={'2/3'}
                          styles={nsImageExtendedCss}
                        />
                      : <div style={{ textAlign: 'center' }}>{item.title}</div>}
                    </div>
                  </div>
                : <NsImage
                    key={item.id}
                    url={item.keyArtImage?.url}
                    width={1200}
                    aspectRatio={'2/3'}
                    isBackground
                    hasGradientOverlay={false}
                    styles={nsImageExtendedCss}
                  />
                }
              </div>

              <div
                className="fyc-slider-en-title"
                css={titleNameCss(version)}
              >
                {item.title}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default FycSlider;
