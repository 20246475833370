/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import type { NSEntitlementDetailsComponentInterface } from 'models/cms';
import React, { useMemo } from 'react';
import NsImage from 'components/ns-component/ns-image';
import { wrapperCss, imageWrapperCss, textWrapperCss } from './styles';
import { usePlayer } from 'providers/player-provider';
import { useSelector } from 'react-redux';
import { fycDataSelector } from 'store/slices/fyc';
import { ComponentTypeEnum } from 'models/enums';
import { useFycThemeContext } from 'providers/fyc-theme-provider';

interface PropsInterface {
  itemData: any;
}

const PLAY = 'PLAY';

const Item: React.FC<PropsInterface> = ({ itemData }) => {
  const version = useFycThemeContext()?.version;

  const { components } = useSelector(fycDataSelector);
  const { openPlayer } = usePlayer();

  const details = useMemo(() => {
    return components?.find(
      (c) => c.type === ComponentTypeEnum.ENTITLEMENT_DETAILS,
    ) as NSEntitlementDetailsComponentInterface | undefined;
  }, [components]);

  const getPlayerTarget = (itemData: any): string => {
    const actions = itemData?.actions || [];
    const playAction = actions.find((action: any) => action?.type === PLAY);
    return playAction?.target || '';
  };

  return (
    <div css={wrapperCss}>
      <div
        css={imageWrapperCss(version)}
        onClick={() => {
          // TODO: refactor for bonus content playback
          openPlayer(getPlayerTarget(itemData), itemData, {
            isFycPublic: true,
          });
        }}
      >
        {details?.hasPlain ?
          <div className="top">{itemData.title}</div>
        : <NsImage
            url={itemData.heroImage.url}
            width={800}
            aspectRatio={'16/9'}
            styles={{
              nsImageCss: css`
                border-radius: ${6 / 16}rem;
              `,
            }}
          />
        }
      </div>
      <div css={textWrapperCss}>{itemData.title}</div>
    </div>
  );
};

export default Item;
