import { css } from '@emotion/core';

export const entitlementStyles = css`
  margin: ${24 / 16}rem 0;
`;

export const progressBarStyles = css`
  .progress-bar {
    width: 100%;
    max-width: calc(14.6rem * 2 + 0.75rem);
  }
`;

export const ampasUserProgressBarStyles = css`
  position: absolute;
  width: 100%;
  bottom: 0.5rem;
  padding: 0 0.5rem;

  .progress-bar {
    width: 100%;
  }
`;

export const rootContainerCss = css`
  margin: 0;
  padding: 0;
  position: relative;

  .action-row {
    padding: 0;
  }
`;
