/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import type { FormikProps, FormikValues } from 'formik';
import { Formik, Form, Field } from 'formik';

import { userErrorSelector, loginWithAccessCode } from 'store/slices/user';
import { Button, Input, Loader } from 'components';
import { LayoutContainer } from 'containers';

import {
  headerTextCss,
  actionButtonCss,
  actionButtonContainerCss,
  loginInfoCss,
} from '../styles';

import { useStringsContext, STRING_KEYS } from 'providers/strings-provider';
import { useDecodedJwt, useQuery } from 'utils/hooks';
import Alert from 'components/alert';

import { LOGIN_ACCESS_CODE } from 'router/constants';

interface LocationStateInterface {
  from?: string;
}

const inputCss = css`
  letter-spacing: 5px;
`;

interface LoginAccessCodePropsInterface {
  isKidsLogin?: boolean;
}

const LoginAccessCode: React.FC<LoginAccessCodePropsInterface> = ({
  isKidsLogin = false,
} = {}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation<LocationStateInterface>();
  const { getStringByKey } = useStringsContext();
  const loginError = useSelector(userErrorSelector);
  const decodedJwt = useDecodedJwt();
  const formikRef = useRef<FormikProps<FormikValues>>();
  const code = useQuery().get('code');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null | undefined>(
    null,
  );

  const onSubmit = ({ code }: { code: string }) => {
    dispatch(loginWithAccessCode({ code: code.trim(), isKidsLogin }));
  };

  const handleAlertClose = () => {
    setErrorMessage(null);
    history.push(LOGIN_ACCESS_CODE);
  };

  useEffect(() => {
    if (loginError) {
      const message = getStringByKey(STRING_KEYS.AUTH_LOGIN_CODE_ERROR);

      if (formikRef.current) {
        formikRef.current.setSubmitting(false);
        formikRef.current.setFieldError('code', message);
      } else {
        setErrorMessage(message);
        setIsLoading(false);
      }
    }
  }, [getStringByKey, loginError]);

  useEffect(() => {
    if (decodedJwt && formikRef.current) {
      formikRef.current.setSubmitting(false);
      history.push(location.state?.from || '/');
    }
  }, [decodedJwt, history, location]);

  useEffect(() => {
    if (code) {
      setIsLoading(true);
      dispatch(loginWithAccessCode({ code, isKidsLogin }));
    }

    return () => {
      setIsLoading(false);
    };
  }, [code, dispatch, isKidsLogin]);

  const form = (
    <Formik
      initialValues={{
        code: '',
      }}
      onSubmit={onSubmit}
      innerRef={formikRef as any}
    >
      {({ errors, isSubmitting, values, isValid }) => (
        <LayoutContainer auth>
          <Form noValidate>
            {isSubmitting && <Loader />}
            <h2 css={headerTextCss}>Access Code</h2>
            <Field name="code">
              {({ field }) => (
                <Input
                  type="text"
                  disabled={isSubmitting}
                  label={getStringByKey(STRING_KEYS.AUTH_LOGIN_CODE)}
                  placeholder="Enter your code"
                  data-testid="login-code-input"
                  error={errors.code}
                  css={!!values.code && inputCss}
                  autoFocus
                  {...field}
                />
              )}
            </Field>
            <div css={actionButtonContainerCss}>
              <Button
                type="submit"
                data-testid="login-submit"
                theme="primary"
                disabled={!values.code || !isValid}
                css={actionButtonCss}
              >
                Next
              </Button>
            </div>
          </Form>
        </LayoutContainer>
      )}
    </Formik>
  );

  const autoLogin = (
    <React.Fragment>
      {errorMessage && (
        <Alert
          onClick={handleAlertClose}
          isOpen={!!errorMessage}
          title="Oops!"
          infoText={errorMessage}
          primaryButtonText="Back to Log In"
        />
      )}
      <LayoutContainer auth>
        <h2 css={headerTextCss}>Access Code</h2>
        <p css={loginInfoCss}>Automatically Logging you in...</p>
        {isLoading && <Loader />}
      </LayoutContainer>
    </React.Fragment>
  );

  return code ? autoLogin : form;
};

export default LoginAccessCode;
