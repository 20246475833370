/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import type { FycVersionType } from 'providers/fyc-theme-provider';
import React, { useState } from 'react';
import { Modal } from 'components';
import { Button } from 'components';
import { useStringsContext, STRING_KEYS } from 'providers/strings-provider';
import { containerCss, buttonsCss, VPPAWrapperCss } from './styles';
import theme from 'styles/theme';
import { TermsContent } from 'components/fyc-component/terms-content';

interface PropsInterface {
  fycVersion: FycVersionType;
  handleAccept: any;
  isOpen: boolean;
  readOnly?: boolean;
  setIsOpen: any;
}

export const TNCModal: React.FC<PropsInterface> = ({
  isOpen,
  setIsOpen,
  handleAccept,
  fycVersion,
  readOnly,
}) => {
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [isVPPAChecked, setIsVPPAChecked] = useState<boolean>(false);

  const { getStringByKey } = useStringsContext();
  const toggleCheck = () => setIsChecked((prevState) => !prevState);
  const toggleIsVPPACheck = () => setIsVPPAChecked((prevState) => !prevState);

  return (
    <Modal
      isOpen={isOpen}
      extendedStyles={css`
        background: linear-gradient(0.48deg, #01182f 0.41%, #000000 84.74%),
          linear-gradient(72.65deg, #000428 0%, #004e92 137.08%);
        z-index: ${theme.zIndex.tcModal};
        &.visible {
          justify-content: start;
        }
      `}
    >
      {isOpen && (
        <div css={containerCss}>
          {!!readOnly && (
            <div css={buttonsCss}>
              <Button
                className="cancel-button"
                theme="secondary"
                onClick={() => {
                  setIsOpen(false);
                }}
              >
                Close
              </Button>
            </div>
          )}
          <div>
            <TermsContent
              header={`Terms and Conditions`}
              subHeader={`Please read and agree to our Terms and Conditions.`}
              /* @ts-ignore TODO: TS2322: Type string | undefined is not assignable to type string. */
              content={
                getStringByKey(
                  `${STRING_KEYS.PRIVACY_TC_MSG}_fyc_${fycVersion}`,
                ) || getStringByKey(STRING_KEYS.PRIVACY_TC_MSG)
              }
              isChecked={isChecked}
              toggleCheck={toggleCheck}
              agreeText={'I have read and agree to the terms'}
              readOnly={readOnly}
            />
          </div>
          {!readOnly && (
            <div css={VPPAWrapperCss}>
              <TermsContent
                header={getStringByKey(STRING_KEYS.WEB_VPPA_TC_TITLE)}
                subHeader={''}
                /* @ts-ignore TODO: TS2322: Type string | undefined is not assignable to type string. */
                content={getStringByKey(STRING_KEYS.PRIVACY_TC_VPPA_MSG)}
                isChecked={isVPPAChecked}
                toggleCheck={toggleIsVPPACheck}
                /* @ts-ignore TODO: TS2322: Type string | undefined is not assignable to type string. */
                agreeText={getStringByKey(STRING_KEYS.WEB_VPPA_TC_BTN)}
                readOnly={readOnly}
              />
            </div>
          )}
          {!!readOnly && (
            <div css={buttonsCss}>
              <Button
                className="cancel-button"
                theme="secondary"
                onClick={() => {
                  setIsOpen(false);
                }}
              >
                Close
              </Button>
            </div>
          )}
          {!readOnly && (
            <div css={buttonsCss}>
              <Button
                disabled={!isChecked || !isVPPAChecked}
                theme="primary"
                onClick={handleAccept}
                className="terms-button"
              >
                Continue
              </Button>
              <Button
                className="cancel-button"
                theme="secondary"
                onClick={() => {
                  setIsOpen(false);
                  setIsChecked(false);
                  setIsVPPAChecked(false);
                }}
              >
                Cancel
              </Button>
            </div>
          )}
        </div>
      )}
    </Modal>
  );
};
