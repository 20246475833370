import type { SerializedStyles } from '@emotion/core';
import { css } from '@emotion/core';
import { FYC_MOBILE_WIDTH } from 'pages/fyc-page/helpers';
import { mediaQuery } from 'utils/styles';

export const wrapperCss = (index: number): SerializedStyles => {
  return css`
    margin-top: ${index === 0 ? '32px' : '0'};
    width: 100%;
    padding: 0 13px;

    ${mediaQuery(FYC_MOBILE_WIDTH)} {
      padding: 0;
      margin-top: ${32 / 16}rem;
    }
  `;
};

export const titleCss = css`
  font-size: ${18 / 16}rem;
  font-weight: 500;
  margin-bottom: ${24 / 16}rem;

  ${mediaQuery(FYC_MOBILE_WIDTH)} {
    margin-bottom: ${10 / 16}rem;
  }
`;
