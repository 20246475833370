/** @jsx jsx */
import type { SerializedStyles } from '@emotion/core';
import { jsx } from '@emotion/core';
import React, { useEffect, useState, useRef } from 'react';
import {
  wrapperCss,
  inputCss,
  containerCss,
  buttonCss,
  buttonDisabledCss,
} from './styles';
import Input from 'components/input';
import Button from 'components/button';
import request from 'superagent';
import { ACCOUNT_TOKEN } from 'api/endpoints';
import { Loader } from 'components';
import { getFycHelper } from 'api';
import { ComponentTypeEnum } from 'models/enums';
import {
  SHOW_FYC_T_AND_C,
  checkTermsIsAccepted,
  FYC_CODE_VALID,
  saveFycCodes,
} from './helper';
import { FX, useFycThemeContext } from 'providers/fyc-theme-provider';

interface PropsInterface {
  btnStyles?: SerializedStyles;
  component: any;
  onSuccessfulCode?: () => void;
  styles?: SerializedStyles;
}

const AccessCode: React.FC<PropsInterface> = ({
  component,
  btnStyles,
  styles,
}) => {
  const { playId } = component.entitlement;
  const [accessCode, setAccessCode] = useState('');
  const [codeError, setCodeError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const version = useFycThemeContext()?.version;

  const componentMounted = useRef(true);

  useEffect(() => {
    return () => {
      componentMounted.current = false;
    };
  }, []);

  const handleAccessCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCodeError(false);
    const code = e.target.value?.toUpperCase().trim();
    setAccessCode(code);
  };

  const handleAccessCodeSubmit = async (code: string) => {
    code = code?.toUpperCase().trim() + '';
    if (!code) {
      return;
    }

    setIsLoading(true);

    try {
      const res = await request
        .post(ACCOUNT_TOKEN)
        .send({ grant_type: 'code', code });
      const { access_token } = res.body;
      // const { body } = await getSearchResults(access_token);
      const { body } = await getFycHelper(access_token);
      if (!componentMounted.current) {
        return;
      }

      const entitlements = body?.components?.find(
        (c) => c.type === ComponentTypeEnum.FYC_SUPPORT,
      );

      const playIds = entitlements?.playIds || [];

      // check if the code is valid for the current docId
      const exist = !!playIds.find((id) => {
        return id === playId;
      });

      if (exist) {
        // check if the T&C fot the code has been accepted before (to handle the case of the accessToken of the code is expired but the user has accepted the T&C)
        if (checkTermsIsAccepted(code)) {
          saveFycCodes(access_token, playIds, code);
          if (!componentMounted.current) {
            return;
          }
          window.dispatchEvent(new Event(FYC_CODE_VALID));
        } else {
          // dispatch custom event to notify the listeners the code is valid and show terms and conditions
          window.dispatchEvent(
            new CustomEvent(SHOW_FYC_T_AND_C, {
              detail: {
                access_token,
                playIds,
                code,
              },
            }),
          );
        }
      } else {
        if (!componentMounted.current) {
          return;
        }
        setCodeError(true);
      }

      if (!componentMounted.current) {
        return;
      }
      setIsLoading(false);
    } catch (error) {
      setCodeError(true);
      setIsLoading(false);
    }
  };

  const renderBtn = () => {
    const defaultBtn = (
      <Button
        onClick={async () => await handleAccessCodeSubmit(accessCode)}
        theme="primary"
        css={
          accessCode === '' ? buttonDisabledCss(version) : buttonCss(version)
        }
      >
        {version === FX ? 'Sign In' : 'SIGN IN'}
      </Button>
    );

    if (btnStyles) {
      return <div css={btnStyles}>{defaultBtn}</div>;
    }

    return defaultBtn;
  };

  return (
    <div css={wrapperCss(styles)}>
      {isLoading && <Loader />}
      <Input
        onChange={handleAccessCodeChange}
        value={accessCode}
        name="fyc-code"
        placeholder="Enter Access Code"
        styles={{ inputCss: inputCss(version), containerCss }}
        error={
          codeError ? 'Incorrect access code. Please try again' : undefined
        }
      />
      {renderBtn()}
    </div>
  );
};

export default AccessCode;
