import { mediaQuery } from 'utils/styles';
import theme from 'styles/theme';
import css from '@emotion/css';

export const wrapperCss = css`
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: flex-end;
  width: ${484 / 16}rem;
  margin: 0 2rem;
`;

export const containerCss = css`
  margin: 0;
  width: ${368 / 16}rem;
  padding: 0;

  ${mediaQuery(theme.breakpoints.md)} {
    margin: 0;
    width: ${368 / 16}rem;
    padding: 0;
  }

  ${mediaQuery(theme.breakpoints.lg)} {
    padding: 0;
    margin: 0;
    width: ${368 / 16}rem;
  }
`;

export const inputCss = css`
  padding-left: 3rem;
  background-color: #373945;
`;
