import { css } from '@emotion/core';
import { mediaQuery, generateStyle } from 'utils/styles';
import theme from 'styles/theme';

export const layoutPageContainerCss = css`
  height: 100vh;
`;

export const layoutRowContainerCss = (fullWidth?: boolean, styles?: any) => {
  const defaultStyle = `
  ${mediaQuery(theme.breakpoints.lg)} {
    height: ${fullWidth ? 'auto' : '100%'};
  }
`;

  return generateStyle({
    defaultStyle,
    extendedStyles: styles,
    key: 'layoutRowContainerCss',
    theme,
  });
};

interface FullWidthInterface {
  footerHeight: number;
  isFullWidth: boolean;
}

export const rightPanelCss = (fullWidth?: FullWidthInterface, styles?: any) => {
  const { isFullWidth, footerHeight } = fullWidth || {};
  const defaultCss = `
  padding-top: 0;
  padding: 0 9%;
  min-height: calc(100vh ${isFullWidth ? '- ' + footerHeight : '- 80'}px);

  ${mediaQuery(theme.breakpoints.md)} {
    padding: 1.45rem 0 0 10%;
  }

  ${mediaQuery(theme.breakpoints.lg)} {
    position: relative;
    border-left: 2px solid #3e4067;
    padding-left: 4.2rem;
    padding-right: 9%;
    padding-top: 7rem;
    min-height: 600px;
  }
`;
  return generateStyle({
    defaultStyle: defaultCss,
    extendedStyles: styles,
    key: 'rightPanelCss',
    theme,
  });
};

export const goBackCss = (styles?: any) => {
  const defaultCss = `
  margin-bottom: 3rem;
`;

  return generateStyle({
    defaultStyle: defaultCss,
    extendedStyles: styles,
    key: 'goBackCss',
    theme,
  });
};

export const goBackTextCss = (styles?: any) => {
  const defaultCss = `
  font-size: 0.875rem;
  color: ${theme.palette.text.secondary};
  background: transparent;
  cursor: pointer;
`;

  return generateStyle({
    defaultStyle: defaultCss,
    extendedStyles: styles,
    key: 'goBackTextCss',
    theme,
  });
};

export const leftComponentContainerCss = (styles?: any) => {
  const defaultCss = `
  display: flex;
  flex-flow: column nowrap;

  ${mediaQuery(theme.breakpoints.lg)} {
    overflow: hidden;
  }
`;

  return generateStyle({
    defaultStyle: defaultCss,
    extendedStyles: styles,
    key: 'leftComponentContainerCss',
    theme,
  });
};

export const logoContainerCss = (styles?: any) => {
  const defaultCss = `
  margin: 1.2rem 0 1rem 0;
  padding-right: 0.6rem;

  ${mediaQuery(theme.breakpoints.md)} {
    margin: 1.65rem 0 0 7%;
  }

  ${mediaQuery(theme.breakpoints.lg)} {
    margin: 0;
    padding: 0;
    width: 20%;
  }
`;

  return generateStyle({
    defaultStyle: defaultCss,
    extendedStyles: styles,
    key: 'logoContainerCss',
    theme,
  });
};

export const logoCss = css`
  max-height: ${43 / 16}rem;

  ${mediaQuery(theme.breakpoints.lg)} {
    width: 100%;
    max-height: none;
  }
`;

export const footerContainerCss = (
  fullWidth?: FullWidthInterface,
  styles?: any,
) => {
  const { isFullWidth, footerHeight } = fullWidth || {};
  const defaultStyle = `
  padding-left: ${theme.padding.footer.sides.xs};
  padding-right: ${theme.padding.footer.sides.xs};
  min-height: ${theme.height.footer.minHeight.xs};

  ${isFullWidth ? 'bottom: -' + footerHeight + 'px;' : ''}

  ${mediaQuery(theme.breakpoints.md)} {
    padding-left: ${theme.padding.footer.sides.xs};
    padding-right: ${theme.padding.footer.sides.xs};
    min-height: ${theme.height.footer.minHeight.md};
  }
  ${mediaQuery(theme.breakpoints.lg)} {
    padding-left: 4.2rem;
    padding-right: 4.2rem;
    min-height: ${theme.height.footer.minHeight.lg};
  }
`;
  return generateStyle({
    defaultStyle,
    extendedStyles: styles,
    key: 'footerContainerCss',
    theme,
  });
};
