import type { NSPageComponentInterface } from 'models/cms';
import React from 'react';

import NsComponent from 'components/ns-component';
import NsComponentLayout from './ns-component-layout';
import { customComponents } from './helpers';

interface PropsInterface {
  components: NSPageComponentInterface[];
}

const NsPageComponents: React.FC<PropsInterface> = (props: PropsInterface) => {
  const components = customComponents(props.components);

  if (!components) {
    return null;
  }

  return (
    <>
      {components.map((c) => (
        <NsComponentLayout
          key={c.id}
          layout={c.layout}
          type={c.type}
        >
          <NsComponent
            key={c.id}
            component={c}
          />
        </NsComponentLayout>
      ))}
    </>
  );
};
export default NsPageComponents;
