import React from 'react';
import styled from '@emotion/styled';
import { LayoutContainer } from 'containers';
import { Button } from 'components';
import { STRING_KEYS, useStringsContext } from 'providers/strings-provider';
import theme from 'styles/theme';

const InfoBlock = styled.p({
  ...theme.typography.defaultText,
  fontSize: '1rem',
});

const NotFound: React.FC = () => {
  const { getStringByKey } = useStringsContext();
  return (
    <LayoutContainer auth>
      <h2>404 Not Found</h2>
      <InfoBlock>{getStringByKey(STRING_KEYS.GEN_404_ERROR)}</InfoBlock>
      <Button
        onClick={() => window.location.replace('/')}
        theme="primary"
      >
        Go to Browse
      </Button>
    </LayoutContainer>
  );
};

export default NotFound;
