import { combineReducers } from '@reduxjs/toolkit';

import pageReducer, { initialState as pageInitialState } from './slices/page';
import userReducer from './slices/user';
import entitlementProgressReducer from './slices/progress';
import fycReducer, { initialState as fycInitialState } from './slices/fyc';

const appReducer = combineReducers({
  page: pageReducer,
  user: userReducer,
  entitlementProgress: entitlementProgressReducer,
  fyc: fycReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'user/logout') {
    state.page = { ...pageInitialState };
    state.fyc = { ...fycInitialState };
  }

  return appReducer(state, action);
};

export type RootStateType = ReturnType<typeof rootReducer>;

export default rootReducer;
