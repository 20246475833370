export const MOBILE_VIEW = 375;
export const TABLET_X_SMALL = 600;
export const TABLET_SMALL = 700;
export const TABLET = 1024;

export const showProxisMsg = (tags: string[]): boolean => {
  if (!tags) {
    return false;
  }

  for (const tag of tags) {
    if (tag.includes('proxis')) {
      return true;
    }
  }

  return false;
};
