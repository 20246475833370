import type { SerializedStyles } from '@emotion/core';
import { css } from '@emotion/core';
import theme from 'styles/theme';
import { mediaQuery } from 'utils/styles';

export const wrapperCss = (
  hasBannerOrHeroOrBrand: boolean,
): SerializedStyles[] => {
  const defaultCss = [
    css`
      display: flex;
      padding-bottom: ${4 / 16}rem;

      ${mediaQuery(theme.breakpoints.lg)} {
        padding-bottom: ${44 / 16}rem;
      }

      ${hasBannerOrHeroOrBrand &&
      `
        margin: 0 -1rem;
        padding-left: 5.2%;
        padding-right: 5.2%;

        ${mediaQuery(theme.breakpoints.xs)} {
          padding-left: 0;
          padding-right: 0;
        }
      `}
    `,
  ];

  return defaultCss;
};

export const contentCss = (
  index: number | null,
  hasBannerOrHeroOrBrand: boolean,
  totalTwoItems: boolean,
): SerializedStyles => {
  if (hasBannerOrHeroOrBrand) {
    return css`
      display: flex;
      justify-content: center;
      transition: justify-content 0.5s;
      width: 100%;
      overflow: hidden;
      // normal & hover on the first one
      ${index === 0 && `justify-content: start`};
      // hover on the second one
      ${index === 1 && !totalTwoItems && `justify-content: center`};
      ${index === 1 && totalTwoItems && `justify-content: end`};
      // hover on the third one
      ${index === 2 && `justify-content: end`};
    `;
  }

  return css`
    overflow: hidden;
    transition: justify-content 0.5s;
    display: flex;
    justify-content: center;
    width: 100%;
    position: relative;
    margin-top: ${39 / 16}rem;

    ${mediaQuery(theme.breakpoints.sm)} {
      margin-top: ${51 / 16}rem;
    }

    ${mediaQuery(theme.breakpoints.lg)} {
      // normal & hover on the first one
      ${index === 0 && `justify-content: start`};
      // hover on the second one
      ${index === 1 && !totalTwoItems && `justify-content: center`};
      ${index === 1 && totalTwoItems && `justify-content: end`};
      // hover on the third one
      ${index === 2 && `justify-content: end`};

      margin-top: ${66 / 16}rem;
    }
  `;
};

export const containerStyle = (
  hasBannerOrHeroOrBrand: boolean,
): SerializedStyles => {
  if (!hasBannerOrHeroOrBrand) {
    return css`
      margin-top: 0;
    `;
  }

  return css`
    margin-top: 1rem;

    ${mediaQuery(theme.breakpoints.sm)} {
      margin-top: ${24 / 16}rem;
    }

    ${mediaQuery(theme.breakpoints.lg)} {
      margin-top: ${32 / 16}rem;
    }
  `;
};
