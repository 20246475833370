import React from 'react';
import ItemList from './item-list';

interface PropsInterface {
  data: any[];
}

const BonusContentPanel: React.FC<PropsInterface> = ({ data }) => {
  return (
    <div>
      <ItemList items={data} />
    </div>
  );
};

export default BonusContentPanel;
