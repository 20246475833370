/** @jsx jsx */
import { jsx } from '@emotion/core';
import { fycPublicPlayApi, playApi } from 'api';
import type {
  ErrorEvent,
  PlaybackEvent,
  PlayerAPI,
  ViewModeChangedEvent,
} from 'bitmovin-player';
import { HttpRequestType, PlayerEvent, ViewMode } from 'bitmovin-player';
import type { UIManager } from 'bitmovin-player/bitmovinplayer-ui';
import 'bitmovin-player/bitmovinplayer-ui.css';
import clsx from 'clsx';
import Alert from 'components/alert';
import Button from 'components/button';
import Loader from 'components/loader';
import { isSafari } from 'containers/browser-compatibility/utils';
import type { NdaServicePropsInterface } from 'hocs/with-nda-service';
import withNdaService from 'hocs/with-nda-service';
import type {
  NSEntitlementDetailsComponentInterface,
  NSPlayDataType,
} from 'models/cms';
import {
  ComponentTypeEnum,
  HttpStatusCodeEnum,
  LoginFailureCodesEnum,
  PlaySourceEnum,
  WatermarkTypeEnum,
} from 'models/enums';
import type {
  ErrorStateInterface,
  PlayerPropsInterface,
} from 'models/interfaces/player-provider';
import { PlayerEmitterEventEnum } from 'models/interfaces/player-provider';

import { usePlayer } from 'providers/player-provider';
import {
  CONFIG_KEYS,
  STRING_KEYS,
  useStringsContext,
} from 'providers/strings-provider';
import CloseIcon from 'public/images/X.svg';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useHistory, useLocation } from 'react-router-dom';
import { LOGIN_LANDING } from 'router/constants';

import { logoutWithError, tokenSelector } from 'store/slices/user';

import theme from 'styles/theme';

import { getPlayId } from 'utils';
import { useComponentTypeFinder, useDecodedJwt } from 'utils/hooks';
import { v4 as uuidv4 } from 'uuid';

import { playerConfig } from './config';
import type { ComponentConfigInterface } from './custom-ui';
import { buildMiniUI, buildModernUI } from './custom-ui';
import { castButtonStyles } from './custom-ui/custom-chromecast-toggle-button';
import {
  FULLSCREEN_BUTTON_NAME,
  fullscreenButtonStyles,
} from './custom-ui/full-screen-mode-button';
import {
  THEATER_BUTTON_NAME,
  THEATER_MODE_CLASS,
  theaterModeButtonStyles,
} from './custom-ui/theater-mode-button';
import {
  CAST_CUSTOM_MESSAGE_NAMESPACE,
  configureSource,
  CustomPlayer,
  detectFairPlay,
  exitPlayerOnEsc,
  getCurrentCastSession,
  isMediaSourceSupported,
} from './helpers';
import {
  buttonHelpTip,
  castMessageCss,
  closeIcon,
  containerCss,
  miniContainerCss,
  miniPlayerCss,
  modalOuterCss,
  playerCss,
} from './styles';
import {
  addCastingButtonHighlight,
  addTestAttributes,
  adjustHeight,
  disablePictureInPicture,
  handleEnterFullscreen,
  handleSafariPiP,
  hidePlayerControls,
  removeCastingButtonHighlight,
} from './utilities';
import Watermark from './watermark';
import useConcurrency, {
  ConcurrencyError,
  ShouldRefetchPlayerDataError,
} from './hook/use-concurrency';

type PlayDataType = NSPlayDataType & {
  _definition_: string;
  _isVPP_: boolean;
};

export const PLAYER_DIV_NAME = 'video-player';
export const preventDefault = (e: Event): void => e.preventDefault();

const Player: React.FC<PlayerPropsInterface & NdaServicePropsInterface> = ({
  playId,
  fullViewportView,
  authToken,
  autoplay = true,
  playData,
  restart,
  loadState: ndaLoadState,
  handleNdaClick,
  verifyNdaCompletion,
  cast,
  slug,
  isFyc,
  fycAccessToken,
  isFycPublic,
}) => {
  const { isLoading: ndaIsLoading } = ndaLoadState;
  const sessionId = useRef<string>(uuidv4());
  const { getStringByKey } = useStringsContext();

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<PlayDataType | null>(null);
  const [currentRuntime, setCurrentRuntime] = useState<number | null>(null);
  const [playerReady, setPlayerReady] = useState(false);
  const [errorState, setErrorState] = useState<ErrorStateInterface>({
    message: null,
    isOpen: false,
  });
  const [isFullscreen, setIsFullscreen] = useState<boolean>(false);
  const normalVideoRef = useRef<HTMLDivElement>();
  const miniVideoRef = useRef<HTMLDivElement>();
  const { pathname } = useLocation();
  const { getConfigByKey } = useStringsContext();

  const hideTheaterModeButton = useMemo(() => {
    const parsedPathname = pathname?.split('/');
    if (parsedPathname?.length < 2) {
      return false;
    }
    return parsedPathname[1] === 'sso' || parsedPathname[1] === 'bafta';
  }, [pathname]);

  const [showCastOnboarding, setShowCastOnboarding] = useState(false);
  const showOnboadingMsgFlag =
    getConfigByKey(CONFIG_KEYS.CHROMECAST_ONBOARDING_ENABLED) === 'true';

  const dispatch = useDispatch();

  // dynamically set token based on whether it is FYC or not
  // eslint-disable-next-line prefer-const
  let { accessToken } = useSelector(tokenSelector);
  if (isFyc) {
    accessToken = fycAccessToken;
  }

  let decodedToken = useDecodedJwt();
  const fycDecodedToken = useDecodedJwt(fycAccessToken);
  if (isFyc) {
    decodedToken = fycDecodedToken;
  }

  const history = useHistory();

  const containerRef = useRef<HTMLDivElement>(null);
  const playerRef = useRef<PlayerAPI | null>(null);
  const uiManagerRef = useRef<UIManager>(null);
  const hasPlain =
    useComponentTypeFinder<NSEntitlementDetailsComponentInterface>(
      ComponentTypeEnum.ENTITLEMENT_DETAILS,
    )?.hasPlain;

  const { ui, closePlayer } = usePlayer();
  const { isMiniPlayer, miniPlayerOff, toggleMiniPlayer } = ui;

  /**
   * hide/show the volume controls based on the cast devices
   * reference: https://developers.google.com/cast/docs/web_sender/integrate
   */
  const [isCasting, setIsCasting] = useState(false);

  const hideVolumeSettings = useCallback(() => {
    const buttons = document.getElementsByClassName(
      'bmpui-ui-volumetogglebutton',
    ) as HTMLCollectionOf<HTMLElement>;
    const sliders = document.getElementsByClassName(
      'bmpui-ui-volumeslider',
    ) as HTMLCollectionOf<HTMLElement>;

    if (!isCasting) {
      if (buttons[0]) {
        buttons[0].style.visibility = 'visible';
      }
      if (sliders[0]) {
        sliders[0].style.visibility = 'visible';
      }
      return;
    }

    const player = new window.cast.framework.RemotePlayer();
    new window.cast.framework.RemotePlayerController(player);

    const hide = !player.canControlVolume;

    if (buttons[0] && hide) {
      buttons[0].style.visibility = 'hidden';
    }
    if (sliders[0] && hide) {
      sliders[0].style.visibility = 'hidden';
    }
  }, [isCasting]);

  useEffect(() => {
    hideVolumeSettings();
  }, [isCasting, hideVolumeSettings]);

  const componentConfigRef = useRef<ComponentConfigInterface>({
    disableCloseButton: !!authToken,
    closePlayer,
    loadMiniPlayer: miniPlayerOff,
  });

  const closeErrorModal = () => {
    setErrorState({ ...errorState, isOpen: false });
    closePlayer();
  };

  /**
   * Construct and loads the player UI
   */
  const loadPlayerUi = () => {
    /* @ts-ignore TODO: TS18047: playerRef.current is possibly null. */
    setCurrentRuntime(playerRef.current.getCurrentTime());
    hidePlayerControls(false, containerRef);

    // We construct the UI here otherwise to ensure crossbrowser functionality works correctly
    componentConfigRef.current = {
      ...componentConfigRef.current,
      /* @ts-ignore TODO: TS18047: playerRef.current is possibly null. */
      isAudioTracksAvailable: playerRef.current.getAvailableAudio()?.length > 1,
      /* @ts-ignore TODO: TS18047: playerRef.current is possibly null. */
      isSubtitlesAvailable: playerRef.current.subtitles?.list()?.length > 0,
      /* @ts-ignore TODO: TS2339: Property entitlement does not exist on type never. */
      enablePlaybackSpeedControls: !!data?.entitlement?.tags?.find((tag) =>
        tag.includes('speed'),
      ),
      /* @ts-ignore TODO: TS2339: Property entitlement does not exist on type never. */
      tenTimesSpeedOptions: !!data?.entitlement?.tags?.find((tag) =>
        tag.includes('speed-non-studio'),
      ),
      metadata: {
        keyArtImage: playData?.keyArtImage,
        titleTreatmentImage: playData?.titleTreatmentImage,
        entitlementTitle: playData?.title,
      },
      loadMiniPlayer: toggleMiniPlayer,
      hideTheaterModeButton,
    };

    checkMiniPlayer(componentConfigRef.current);

    const lastCheck = checkLocalStorage();
    if (!lastCheck) {
      addCastingButtonHighlight(containerRef);
    }

    setIsLoading(false);

    /* @ts-ignore TODO: TS18047: playerRef.current is possibly null. */
    if (playerRef.current.isCasting()) {
      setIsCasting(true);
      hideVolumeSettings();
    }
  };

  const resetPlayerData = useCallback(() => {
    playerRef.current = null;
    setData(null);
  }, [setData]);

  const fetchData = useCallback(
    async (forceDefinition: string | null = null) => {
      try {
        // request 1080p manifest for Fairplay and 720p for Widevine L3
        let definition;

        if (forceDefinition) {
          definition = forceDefinition;
        } else {
          definition = detectFairPlay() ? 'hdp' : 'hd';
        }

        setIsLoading(true);

        let res;
        // case for fyc public play
        if (isFycPublic) {
          res = await fycPublicPlayApi({
            /* @ts-ignore TODO: TS2322: Type string | null | undefined is not assignable to type string. */
            playId: getPlayId(playId),
            definition,
            /* @ts-ignore TODO: TS2322: Type string | null is not assignable to type string. */
            version: sessionStorage.getItem('fycVersion'),
          });
        } else {
          let query;
          if (!isMediaSourceSupported('video/mp4; codecs="ec-3"')) {
            query = {
              definition,
              audio: 'stereo',
            };
          } else {
            query = { definition };
          }
          // fetch playback data from Northstar
          res = await playApi(
            /* @ts-ignore TODO: TS2345: Argument of type string | null | undefined is not assignable to parameter of type string. */
            getPlayId(playId),
            query,
            authToken || accessToken,
          );
        }

        const resData = res.body as NSPlayDataType;

        //make sure player is null before load/reload data
        if (playerRef.current) {
          playerRef.current = null;
        }

        setData({
          _definition_: definition,
          _isVPP_: resData.source === PlaySourceEnum.VPP,
          ...resData,
        });
      } catch (e) {
        console.log(`fetchData Error : ${e}`);
        if (e.response?.statusCode === HttpStatusCodeEnum.NOT_FOUND) {
          resetPlayerData();
          setIsLoading(true);
        } else if (
          e.response?.statusCode === HttpStatusCodeEnum.TOO_MANY_REQUESTS
        ) {
          window.addEventListener(
            PlayerEmitterEventEnum.PLAYER_CLOSE,
            onPlayerClose,
          );
          setErrorState({
            title: 'An error occurred',
            isOpen: true,
            /* @ts-ignore TODO: TS2322: Type string | undefined is not assignable to type string. */
            message: getStringByKey(STRING_KEYS.PLAY_CONCURRENCY_ERROR),
          });
          setIsLoading(false);
        }
      }
    },
    [playId, accessToken, authToken, isFycPublic],
  );

  const closeConcurrencySession = useConcurrency({
    data,
    error: useCallback(
      (e) => {
        if (e instanceof ConcurrencyError) {
          playerRef.current?.pause();
          setErrorState({
            title: 'An error occurred',
            isOpen: true,
            /* @ts-ignore TODO: TS2322: Type string | undefined is not assignable to type string. */
            message: getStringByKey(STRING_KEYS.PLAY_CONCURRENCY_ERROR),
          });
        } else if (
          e instanceof ShouldRefetchPlayerDataError &&
          !playerRef.current?.isPaused() &&
          !playerRef.current?.isPlaying()
        ) {
          // UserInterface finished watching and clicked on Restart button on player.
          // Player need to fetch new license in order to playback again
          fetchData();
        }
      },
      [fetchData, getStringByKey, setErrorState],
    ),
    isFycPublic: !!isFycPublic,
    player: playerRef.current,
    sessionId: sessionId.current,
    token: authToken || accessToken,
  });

  // on escape handler, this deals with making sure full screen users don't close the player
  const onEscape = useCallback(
    (e) => exitPlayerOnEsc(e, playerRef, closePlayer),
    [closePlayer],
  );

  // on player close handler, cleans up the sessions and player
  const onPlayerClose = useCallback(() => {
    try {
      window.removeEventListener('keydown', onEscape);

      // // set a timeout here to prevent race conditions between chromecast and bitmovin
      setTimeout(async () => {
        await playerRef.current?.unload();
      }, 500);

      // set a timeout here to ensure the delete session is executed last
      setTimeout(async () => {
        await closeConcurrencySession();

        window.dispatchEvent(
          new CustomEvent(PlayerEmitterEventEnum.SESSION_DELETED),
        );

        const playerDiv = document.querySelector(
          `.${PLAYER_DIV_NAME}`,
        ) as HTMLElement;
        if (playerDiv) {
          playerDiv.classList.remove(THEATER_MODE_CLASS);
        }
      }, 700);
    } catch (e) {
      console.log(`onPlayerClose Error: ${e}`);
    }
  }, [
    accessToken,
    authToken,
    closeConcurrencySession,
    data,
    isFycPublic,
    onEscape,
  ]);

  const errorHandler = (err: ErrorEvent) => {
    /* @ts-ignore TODO: TS18047: data is possibly null. */
    if (err?.code === 2009 && data._definition_ == 'hdp') {
      fetchData('hd');
      return;
    }

    console.log(err);
    let message = getStringByKey(STRING_KEYS.PLAY_TITLE_ERROR);
    if (err.data?.responseCode === HttpStatusCodeEnum.TOO_MANY_REQUESTS) {
      message = getStringByKey(STRING_KEYS.PLAY_CONCURRENCY_ERROR);
    }
    setErrorState({
      title: 'An error occurred',
      /* @ts-ignore TODO: TS2322: Type string | undefined is not assignable to type string. */
      message,
      isOpen: true,
    });
    setIsLoading(false);
  };

  // disable pip for BAFTA
  if (hideTheaterModeButton) {
    const el = playerRef.current?.getVideoElement();
    if (el) {
      el.setAttribute('disablepictureinpicture', '');
    }
  }

  /**
   * Get data from Northstar whenever the playId changes
   */
  useEffect(() => {
    if (!playId) {
      return;
    }

    if (decodedToken || authToken || isFycPublic) {
      fetchData();
    } else {
      if (isFycPublic) {
        return;
      }
      history.push(LOGIN_LANDING);
      dispatch(
        logoutWithError({
          code: LoginFailureCodesEnum.TOKEN_EXPIRED,
        }),
      );
    }
  }, [playId, isFycPublic, fetchData]);

  /**
   * Creates a player instance and mounts it to the containerRef.
   * There is no media loaded. Reuse the same player, but call the load() function.
   */
  const mountPlayer = useCallback(async () => {
    const config = playerConfig({
      autoplay,
      /* @ts-ignore TODO: TS2322: Type (key: string) => string | undefined is not assignable to type (key: string) => string. */
      getStringByKey,
      setError: setErrorState,
      enableCast:
        isFycPublic ?
          getConfigByKey(CONFIG_KEYS.FF_CHROMECAST_FYC_BONUS_WEB) === 'true'
        : getConfigByKey(CONFIG_KEYS.FF_CHROMECAST_WEB) === 'true',
    });

    if (!isSafari()) {
      /* @ts-ignore TODO: TS18048: config.network is possibly undefined. */
      config.network.preprocessHttpResponse = async (type, response) => {
        if (type === HttpRequestType.MANIFEST_HLS_VARIANT) {
          // Edit the duration to be 12 hours on webvtt hls variant playlists to avoid a bitmovin
          // bug that prevents seeking past the maximum seekable range, which is determined by the
          // duration in playlist files, including the captions playlist files
          if (
            typeof response.body === 'string' &&
            response.body.includes('webvtt')
          ) {
            response.body = response.body.replace(
              /#EXTINF:([0-9.]+),\n/,
              `#EXTINF:${Number(12 * 60 * 60).toFixed(4)},\n`,
            ) as any;
          }
        }

        return response;
      };
    }

    /* @ts-ignore TODO: TS18047: data is possibly null. */
    if (data.cdnAuthToken) {
      /* @ts-ignore TODO: TS18048: config.tweaks is possibly undefined. */
      config.tweaks.query_parameters = {
        /* @ts-ignore TODO: TS18047: data is possibly null. */
        wmt: data.cdnAuthToken,
      };
    }

    /* @ts-ignore TODO: TS18047: data is possibly null. */
    if (!data._isVPP_) {
      /* @ts-ignore TODO: TS18048: config.tweaks is possibly undefined. */
      config.tweaks.native_hls_parsing = true;
    }

    if (playerRef.current) {
      await playerRef.current?.destroy();
    }

    /* @ts-ignore TODO: TS2540: Cannot assign to current because it is a read-only property. */
    /* @ts-ignore TODO: TS2345: Argument of type HTMLDivElement | null is not assignable to parameter of type HTMLElement. */
    playerRef.current = new CustomPlayer(containerRef.current, config);

    attachEvents();

    setPlayerReady(true);
  }, [data]);

  /**
   * Regular player events
   */
  const eventHandlers = useMemo(
    () => ({
      [PlayerEvent.CastStopped]: function castStopped() {
        setIsCasting(false);
        miniPlayerOff();
      },
      [PlayerEvent.ModuleReady]: function moduleReady() {
        setIsLoading(true);
        addTestAttributes({
          containerRef,
          playId: playData.id,
        });
        hidePlayerControls(true, containerRef);
        disablePictureInPicture({ playerRef });
        handleSafariPiP({ playerRef, setError: setErrorState });
      },
      [PlayerEvent.Error]: function error(error: ErrorEvent) {
        errorHandler(error);
      },
      [PlayerEvent.ViewModeChanged]: function viewModeChanged(
        event: ViewModeChangedEvent,
      ) {
        event.to !== ViewMode.Fullscreen ?
          setIsFullscreen(false)
        : setIsFullscreen(true);
        // fullscreen doesn't trigger a resize event cause the window size doesn't change
        // manually dispatch a resize event here to scale our watermarks
        window.dispatchEvent(new Event('resize'));
      },
      [PlayerEvent.SourceLoaded]: function sourceLoaded() {
        // Only do this if we are casting
        // Ready event doesn't fire on resumed cast sessions
        /* @ts-ignore TODO: TS18047: playerRef.current is possibly null. */
        if (playerRef.current.isCasting()) {
          setIsCasting(true);
          loadPlayerUi();
        }
      },
      [PlayerEvent.CastStart]: function castStart() {
        // Is fired when the Cast app is either launched successfully
        //or an active Cast session is resumed successfully.
        if (playerRef.current?.isCasting()) {
          setIsCasting(true);
        }
        playerRef.current?.setVolume(20);
      },
      [PlayerEvent.CastStarted]: function castStarted() {
        // Is fired when the user has chosen a cast device
        //and the player is waiting for the device to get ready for playbackFinish.
        if (playerRef.current?.isCasting()) {
          setIsCasting(true);
        }
        playerRef.current?.setVolume(20);

        // add message listener from receiver
        const castSession = getCurrentCastSession();
        castSession.addMessageListener(
          CAST_CUSTOM_MESSAGE_NAMESPACE,
          (namespace, message) => {
            console.log(namespace, message);
          },
        );
      },
      [PlayerEvent.Seeked]: function seeked(event) {
        if (
          event.issuer !== PlayerEmitterEventEnum.CAST_ISSUER &&
          !event.remote
        ) {
          setCurrentRuntime(playerRef.current?.getCurrentTime() ?? null);
        }
      },
      [PlayerEvent.Playing]: function playing(event) {
        if (
          event.issuer !== PlayerEmitterEventEnum.CAST_ISSUER &&
          !event.remote
        ) {
          setCurrentRuntime(playerRef.current?.getCurrentTime() ?? null);
        }
      },
      [PlayerEvent.Ready]: function ready() {
        loadPlayerUi();
      },
      [PlayerEvent.TimeChanged]: function timeChanged(event: PlaybackEvent) {
        setCurrentRuntime(event.time);
      },
    }),
    [
      data,
      isFycPublic,
      miniPlayerOff,
      setCurrentRuntime,
      setIsCasting,
      setIsFullscreen,
    ],
  );

  /**
   * Iterate through all the event handlers and remove them from the player
   */
  const cleanUpEvents = () => {
    Object.entries(eventHandlers).forEach(([event, handler]) => {
      playerRef.current?.off(event as PlayerEvent, handler);
    });
  };

  /**
   * Iterate through all the vent handlers and attach them to the player
   */
  const attachEvents = () => {
    Object.entries(eventHandlers).forEach(([event, handler]) => {
      /* @ts-ignore TODO: TS18047: playerRef.current is possibly null. */
      playerRef.current.on(event as PlayerEvent, handler);
    });
  };

  /**
   * Different entitlements can have different aspect ratios, when new media
   * is loaded into the player, we need to recalculate the height
   */
  const resizePlayerHeight = useCallback(() => {
    if (fullViewportView) {
      /* @ts-ignore TODO: TS18047: containerRef.current is possibly null. */
      containerRef.current.style.height = '100vh';
    } else {
      adjustHeight({ fullViewportView, containerRef });
    }
  }, [fullViewportView]);

  const checkLocalStorage = () => {
    if (!showOnboadingMsgFlag) {
      return true;
    }

    let inlocalStorage = false;

    const castButton = containerRef.current?.querySelector(
      '.bmpui-ui-casttogglebutton',
    );

    const isButtonHidden = castButton?.classList?.contains('bmpui-hidden');

    if (
      !localStorage.getItem('chromecast_onboarding_message') &&
      castButton &&
      !isButtonHidden
    ) {
      setShowCastOnboarding(true);
    } else {
      setShowCastOnboarding(false);
      inlocalStorage = true;
    }
    return inlocalStorage;
  };

  const onClose = () => {
    localStorage.setItem(
      'chromecast_onboarding_message',
      new Date().toISOString(),
    );
    setShowCastOnboarding(false);
    removeCastingButtonHighlight(containerRef);
  };

  const checkMiniPlayer = (componentConfig) => {
    try {
      // release the previous player
      uiManagerRef.current?.release();
      if (!isMiniPlayer) {
        uiManagerRef.current = buildModernUI(
          /* @ts-ignore TODO: TS2345: Argument of type PlayerAPI | null is not assignable to parameter of type PlayerAPI. */
          playerRef.current,
          {
            disableAutoHideWhenHovered: true,
          },
          componentConfig,
        );
      } else {
        uiManagerRef.current = buildMiniUI(
          /* @ts-ignore TODO: TS2345: Argument of type PlayerAPI | null is not assignable to parameter of type PlayerAPI. */
          playerRef.current,
          {
            disableAutoHideWhenHovered: true,
          },
          componentConfig,
        );
      }
    } catch (e) {
      console.error(e);
    }
  };

  /**
   * [Player Events] ply:events
   *
   * Attach all the player events
   */
  useEffect(() => {
    if (data && playerReady) {
      // setIsLoading(false);
      // TODO: abstract out the taphandler to somewhere else
      let timeout;
      let lastTap = 0;

      function tapHandler(event) {
        const currentTime = new Date().getTime();
        const tapLength = currentTime - lastTap;
        clearTimeout(timeout);
        if (tapLength < 500 && tapLength > 0) {
          event.preventDefault();
        } else {
          timeout = setTimeout(function () {
            clearTimeout(timeout);
          }, 500);
        }
        lastTap = currentTime;
      }

      // disable fullscreen by preventing double tap
      normalVideoRef.current?.addEventListener('touchstart', tapHandler);

      // prevent context menu of video player
      normalVideoRef.current?.addEventListener('contextmenu', preventDefault);

      window.addEventListener(
        PlayerEmitterEventEnum.PLAYER_CLOSE,
        onPlayerClose,
      );
      window.addEventListener('keydown', onEscape);

      return () => {
        window.removeEventListener('keydown', onEscape);
        window.removeEventListener(
          PlayerEmitterEventEnum.PLAYER_CLOSE,
          onPlayerClose,
        );
      };
    }
  }, [data, playerReady]);

  /**
   * Fix the player size and aspect ratio when it's ready and mounted
   */
  useEffect(() => {
    resizePlayerHeight();
  }, [resizePlayerHeight]);

  /**
   * Handle loading the media into the player when play data changes.
   * Will check for NDA requirements before loading the player
   */
  useEffect(() => {
    if (data && !ndaIsLoading) {
      setPlayerReady(false);
      // Check to see if there's a current cast session
      // If so, we need to use the playback session id from the cast
      // to prevent extra sessions from being created
      if (cast?.data?.playbackSessionId) {
        sessionId.current = cast.data.playbackSessionId;
      }

      async function load() {
        try {
          const source = configureSource({
            /* @ts-ignore TODO: TS18047: data is possibly null. */
            asset: data.assets[0],
            bookmark:
              restart ?
                /* @ts-ignore TODO: TS18047: data is possibly null. */
                { docId: data.entitlement.docId, progress: 0, position: 0 }
              : /* @ts-ignore TODO: TS18047: data is possibly null. */
                data.bookmark,
            /* @ts-ignore TODO: TS2322: Type string | null | undefined is not assignable to type string. */
            accessToken: authToken || accessToken,
            sessionId: sessionId.current,
            /* @ts-ignore TODO: TS18047: data is possibly null. */
            isVPP: data._isVPP_,
          });

          // Ensure none of these are missing, playback won't start if so

          // reduce playData size for series
          if (playData?.episodeId) {
            const currentSeasonNum = playData?.seasonNumber;
            const currentEpisodeNum = playData?.episodeNumber;

            // remove other seasons
            let seasons = playData.seasons?.filter(
              (s) => s.seasonNumber === currentSeasonNum,
            );

            if (seasons && seasons.length) {
              // remove other episodes
              let episodes = seasons[0].episodes?.filter(
                (e) => e.episodeNumber === currentEpisodeNum,
              );

              if (episodes && episodes.length) {
                episodes = [
                  {
                    ...episodes[0],
                    entitlement: { ...episodes[0]?.entitlement, plays: [] },
                  },
                ];

                seasons = [{ ...seasons[0], episodes: [{ ...episodes[0] }] }];
              }

              // remove plays records in episodes

              playData.seasons = seasons;
            }
          }

          source.metadata = {
            playData: {
              ...playData,
              accessToken,
            } || {
              /* @ts-ignore TODO: TS18047: data is possibly null. */
              entitlement: data.entitlement,
            },
            /* @ts-ignore TODO: TS18047: data is possibly null. */
            movieType: data.type,
            /* @ts-ignore TODO: TS18047: data is possibly null. */
            movieId: data.id,
            /* @ts-ignore TODO: TS2322: Type string | undefined is not assignable to type string. */
            plainView: hasPlain?.toString(),
            /* @ts-ignore TODO: TS2322: Type string | undefined is not assignable to type string. */
            pageTarget: slug,
          };

          // Create and mount the player here
          await mountPlayer();

          // prevent playing if watermark is not supported
          const watermarkType = data?.entitlement.watermarkType;
          const types = Object.values(WatermarkTypeEnum);
          if (watermarkType && !types?.includes(watermarkType)) {
            setErrorState({
              isOpen: true,
              /* @ts-ignore TODO: TS2322: Type string | undefined is not assignable to type string. */
              message: getStringByKey(STRING_KEYS.UNSUPPORTED_WATERMARK_ERROR),
            });
            return;
          }

          // Config can only be reconfigured after load is done resolving
          // Always clear and set new params on new media load
          /* @ts-ignore TODO: TS18047: playerRef.current is possibly null. */
          await playerRef.current.load(source);

          /* @ts-ignore TODO: TS18047: data is possibly null. */
          if (data.cdnAuthToken) {
            /* @ts-ignore TODO: TS18047: playerRef.current is possibly null. */
            playerRef.current.clearQueryParameters();
            /* @ts-ignore TODO: TS18047: playerRef.current is possibly null. */
            playerRef.current.setQueryParameters({
              /* @ts-ignore TODO: TS18047: data is possibly null. */
              wmt: data.cdnAuthToken,
            });
          }
        } catch (e) {
          console.error('Load Error:', e);
          // temporary fix for the player frozen and watermarks issue
          // window.location.reload();
        }
      }

      // Check for NDA requirements
      /* @ts-ignore TODO: TS2339: Property entitlement does not exist on type never. */
      if (data.entitlement?.ndaTemplate) {
        /* @ts-ignore TODO: TS2339: Property entitlement does not exist on type never. */
        const isVerified = verifyNdaCompletion(data.entitlement.ndaTemplate);
        if (isVerified) {
          load();
        } else {
          // Redirect users to go sign
          setIsLoading(false);
          /* @ts-ignore TODO: TS2339: Property entitlement does not exist on type never. */
          handleNdaClick(data.entitlement.ndaTemplate, {
            onSecondaryClick: closePlayer,
          });
        }
      } else {
        load();
      }

      return () => {
        cleanUpEvents();
      };
    }
  }, [data, accessToken, ndaIsLoading]);

  useEffect(() => {
    handleEnterFullscreen({
      setError: setErrorState,
      getStringByKey,
      playerRef,
      isFullscreen,
    });
  }, [isFullscreen, getStringByKey]);

  useEffect(() => {
    if (uiManagerRef.current) {
      componentConfigRef.current = {
        ...componentConfigRef.current,
        loadMiniPlayer: toggleMiniPlayer,
        hideTheaterModeButton,
      };

      // Reset our player ui if we aren't casting and state is in mini mode
      if (!playerRef.current?.isCasting()) {
        miniPlayerOff();
      }

      checkMiniPlayer(componentConfigRef.current);
      hideVolumeSettings();
    }
  }, [isMiniPlayer]);

  const normalPlayerCss = [
    containerCss,
    theaterModeButtonStyles,
    fullscreenButtonStyles,
    castButtonStyles,
    buttonHelpTip(`bmpui-${THEATER_BUTTON_NAME}`),
    buttonHelpTip(`bmpui-${FULLSCREEN_BUTTON_NAME}`, 1, 0),
    buttonHelpTip('bmpui-ui-subtitlestogglebutton'),
    buttonHelpTip('bmpui-ui-fullscreentogglebutton', -2, -1),
    buttonHelpTip('bmpui-ui-casttogglebutton', -2, 0),
  ];

  const normalPlayerContainer = () => {
    return (
      <React.Fragment>
        {playerReady && !playerRef.current?.isCasting() && (
          <Watermark
            /* @ts-ignore TODO: TS2339: Property entitlement does not exist on type never. */
            entitlement={data?.entitlement}
            playData={{ runtime: playerRef.current?.getDuration() }}
            meta={{ currentTime: currentRuntime }}
          />
        )}
        {playerReady && showCastOnboarding && (
          <Button
            data-testid="chromcast-exit-button"
            onClick={onClose}
            theme="primary"
            css={castMessageCss}
          >
            <div className="content">
              {getStringByKey(STRING_KEYS.CHROMECAST_ONBOARDING_TITLE)}
              <img
                src={CloseIcon}
                css={closeIcon}
              />
            </div>
          </Button>
        )}
      </React.Fragment>
    );
  };

  return (
    <div
      className={clsx([PLAYER_DIV_NAME, isMiniPlayer && 'ui-mini-on'])}
      css={isMiniPlayer ? miniContainerCss : normalPlayerCss}
      onClick={(e) => e.stopPropagation()}
      /* @ts-ignore TODO: TS2322: Type MutableRefObject<HTMLDivElement | undefined> is not assignable to type LegacyRef<HTMLDivElement> | undefined. */
      ref={isMiniPlayer ? miniVideoRef : normalVideoRef}
      /* @ts-ignore TODO: TS2339: Property id does not exist on type never. */
      key={data?.id ?? 'player'}
    >
      {/* Consider removing,.. */}
      {isLoading && <Loader />}
      {errorState.isOpen && (
        <Alert
          isOpen={errorState.isOpen}
          title={errorState.title}
          infoText={errorState.message ?? undefined}
          onClick={closeErrorModal}
          primaryButtonText="Ok"
          styles={{ outerCss: modalOuterCss }}
          zIndex={theme.zIndex.alert}
        />
      )}
      <div
        className={clsx([
          'bitmovinplayer-container',
          isMiniPlayer ? 'small-collapsed-player' : 'normal-player',
        ])}
        css={
          isMiniPlayer ? miniPlayerCss : (
            playerCss(
              hasPlain ?
                playData?.titleTreatmentImage ?? ''
              : playData?.keyArtImage ?? '',
            )
          )
        }
        ref={containerRef}
      >
        {!isMiniPlayer && normalPlayerContainer()}
      </div>
    </div>
  );
};

export default withNdaService(Player, undefined, true);
