/** @jsx jsx */
import { jsx } from '@emotion/core';
import type { ICategoriesItemInterface } from 'models/interfaces';
import React, { useMemo } from 'react';
import CategoryItem from '../item';
import { wrapperCss, titleCss } from './styles';

interface PropsInterface {
  data: ICategoriesItemInterface[];
  index: number;
}

const CategoryList: React.FC<PropsInterface> = ({ data, index }) => {
  const formattedData = useMemo(() => {
    const result: Array<ICategoriesItemInterface[]> = [];

    data.forEach((item, index) => {
      const temp: ICategoriesItemInterface[] = [];
      if (index % 2 === 0) {
        temp.push(item);
        temp.push(data[index + 1]);
        result.push(temp);
      }
    });

    return result;
  }, [data]);

  return (
    <div css={wrapperCss(index)}>
      <div css={titleCss}>{data[0]?.organization}</div>
      {formattedData.map((value, index) => (
        <CategoryItem
          key={value.toString() + index}
          rowData={value}
        />
      ))}
    </div>
  );
};

export default CategoryList;
