/*eslint sort-keys: [error, asc, {caseSensitive: true, natural: false}] */

import type { CSSObject } from '@emotion/core';
import type { FycComponentConfigurationTypeInterface } from 'providers/fyc-theme-component-interfaces';
import { FycConfigsEnum } from 'providers/fyc-theme-component-interfaces';
import { convertPixelsToRems } from 'utils/styles';

const ONYX_COLORS = {
  BLACK: '#000000',
  BLACK_BLUE: '#25252B',
  BLACK_GREEN: '#1E2020',
  EBONY: '#181A1A',
  GOLD: '#CCAE75',
  IVORY: '#D8D0C1',
  LIGHT_BLACK: '#202020',
  WHITE: '#FFF',
};

const ONYX_FONT_FAMILIES = {
  POPPINS: 'Poppins',
};

const ONYX_FONT_WEIGHTS = {
  EXTRA_LIGHT: 200,
  LIGHT: 300,
  MEDIUM: 500,
  REGULAR: 400,
};

const brandFont = ONYX_FONT_FAMILIES.POPPINS;
const brandAccentColor = ONYX_COLORS.GOLD;
const fontSize14px: CSSObject['fontSize'] = convertPixelsToRems(14);
const detailsPartsBackground: CSSObject['background'] = `linear-gradient(17deg, ${'rgba(24, 26, 26, 0.32)'} -23.2%, ${ONYX_COLORS.LIGHT_BLACK} 100.4%)`;

const episodeInfoModalBoxShadow: CSSObject['boxShadow'] = `0 4px 5px 0 ${'rgba(0, 0, 0, 0.14)'}, 0 1px 10px 0 ${'rgba(0, 0, 0, 0.12)'}, 0 2px 4px -1px ${'rgba(0, 0, 0, 0.20)'}`;
const episodeInfoModalMaskBg: CSSObject['background'] = ONYX_COLORS.LIGHT_BLACK;
const episodeInfoModalMaskBg1: CSSObject['background'] =
  'rgba(255, 255, 255, 0.07)';

const fycPageBackground: CSSObject['background'] = `${
  ONYX_COLORS.EBONY
} linear-gradient(180deg, ${
  'rgba(0, 8, 11,' + ' .5)'
} 5%, ${'rgba(66, 66, 67, 0)'})`;

const accessCodeConfig: FycComponentConfigurationTypeInterface['ACCESS_CODE_CONFIG'] =
  {
    inputCss: {
      fontWeight: ONYX_FONT_WEIGHTS.LIGHT,
    },
  };

const allContentLandingConfig: FycComponentConfigurationTypeInterface['ALL_CONTENT_LANDING_CONFIG'] =
  {
    cateContainer: {
      color: brandAccentColor,
      fontWeight: ONYX_FONT_WEIGHTS.MEDIUM,
      letterSpacing: '1px',
      selectedFontSize: convertPixelsToRems(20),
      unSelectedFontWeight: ONYX_FONT_WEIGHTS.REGULAR,
    },
    cateListItemCss: {
      textTransform: 'capitalize',
    },
    leftColumnCss: {
      borderRight: 'none',
      color: ONYX_COLORS.IVORY,
      fontFamily: ONYX_FONT_FAMILIES.POPPINS,
    },
  };

const bannerConfig: FycComponentConfigurationTypeInterface['BANNER_CONFIG'] = {
  imageContainerCss: {
    borderRadius: convertPixelsToRems(30),
  },
  indicatorItemCss: {
    activeStyle: {
      background: brandAccentColor,
    },
    defaultStyle: {
      background: ONYX_COLORS.WHITE,
      opacity: 0.5,
    },
  },
};

const footerConfig: FycComponentConfigurationTypeInterface['FOOTER_CONFIG'] = {
  contactCss: {
    fontFamily: brandFont,
    fontWeight: ONYX_FONT_WEIGHTS.LIGHT,
  },
  copyRightCss: {
    fontFamily: brandFont,
    fontSize: convertPixelsToRems(12),
    fontWeight: ONYX_FONT_WEIGHTS.LIGHT,
    letterSpacing: '0.25px',
    textTransform: 'uppercase',
  },
  tosCss: {
    fontWeight: ONYX_FONT_WEIGHTS.LIGHT,
  },
};

const categoryItemConfig: FycComponentConfigurationTypeInterface['CATEGORY_ITEM_CONFIG'] =
  {
    categoryCss: {
      color: ONYX_COLORS.IVORY,
      fontFamily: brandFont,
      fontWeight: ONYX_FONT_WEIGHTS.MEDIUM,
    },
  };

const categorySelectConfig: FycComponentConfigurationTypeInterface['CATEGORY_SELECT_CONFIG'] =
  {
    selectStyles: {
      placeholder: {
        color: brandAccentColor,
      },
      singleValue: {
        color: brandAccentColor,
      },
    },
  };

const detailsBannerConfig: FycComponentConfigurationTypeInterface['DETAILS_BANNER_CONFIG'] =
  {
    actionsContainerCss: {
      background: 'rgba(255, 255, 255, 0.03)',
    },
    backgroundCss: {
      background: ONYX_COLORS.BLACK_GREEN,
    },
    titleInfoCss: {
      fontFamily: brandFont,
      fontWeight: ONYX_FONT_WEIGHTS.MEDIUM,
    },
    wrapperCss: {
      background: ONYX_COLORS.BLACK_GREEN,
    },
  };

const detailsPageConfig: FycComponentConfigurationTypeInterface['DETAILS_PAGE_CONFIG'] =
  {
    bodyDefaultTextCss: {
      textColor: ONYX_COLORS.WHITE,
    },
    selectStyles: {
      bg: ONYX_COLORS.BLACK_BLUE,
      fontSize: fontSize14px,
      singleValueColor: ONYX_COLORS.WHITE,
      singleValueFontFamily: brandFont,
      singleValueFontWeight: ONYX_FONT_WEIGHTS.LIGHT,
    },
  };

const detailsPartConfig: FycComponentConfigurationTypeInterface['DETAILS_PART_CONFIG'] =
  {
    contentCss: {
      background: detailsPartsBackground,
      color: ONYX_COLORS.IVORY,
      fontWeight: ONYX_FONT_WEIGHTS.LIGHT,
    },
    reactTabsCss: {
      border: `2px solid ${ONYX_COLORS.IVORY}`,
      color: 'rgba(255, 255, 255, 0.47)',
      fontSize: fontSize14px,
      fontWeight: ONYX_FONT_WEIGHTS.LIGHT,
      selectFontFamily: brandFont,
      selectFontWeight: ONYX_FONT_WEIGHTS.REGULAR,
      selectedBg: brandAccentColor,
      selectedColor: ONYX_COLORS.IVORY,
      selectedTabBg: ONYX_COLORS.EBONY,
    },
    tabTitleCss: {
      textTransform: 'none',
    },
  };

const episodesInfoModalConfig: FycComponentConfigurationTypeInterface['EPISODES_INFO_MODAL_CONFIG'] =
  {
    contentRightCss: {
      contentIntoFontSize: fontSize14px,
      contentTitleFontSize: fontSize14px,
      fontFamily: brandFont,
      infoFontWeight: ONYX_FONT_WEIGHTS.LIGHT,
      inputPromptFontWeight: ONYX_FONT_WEIGHTS.LIGHT,
      synopsisTitleTextTransform: 'uppercase',
      titleFontWeight: ONYX_FONT_WEIGHTS.EXTRA_LIGHT,
    },
    contentWrapperCss: {
      boxShadow: episodeInfoModalBoxShadow,
      mask1Bg: episodeInfoModalMaskBg1,
      maskBg: episodeInfoModalMaskBg,
    },
    episodeTitleCss: {
      fontSize: convertPixelsToRems(20),
      fontWeight: ONYX_FONT_WEIGHTS.REGULAR,
      textTransform: 'uppercase',
    },
    episodeTitleFormatter: ({ episodeTitle }) => episodeTitle ?? undefined,
    modalExtendCss: {
      background: 'rgba(0, 0, 0, 0.50)',
    },
    titleCss: {
      fontWeight: ONYX_FONT_WEIGHTS.REGULAR,
    },
    titleFormatter: ({ showName, currentSeason, episodeNumber }) =>
      `${showName}: S${currentSeason} E${episodeNumber}`,
  };

const episodesPanelItemConfig: FycComponentConfigurationTypeInterface['EPISODES_PANEL_ITEM_CONFIG'] =
  {
    contentCss: {
      fontWeight: ONYX_FONT_WEIGHTS.LIGHT,
    },
    episodeTitleFormatter: ({ episodeNumber, episodeTitle }) =>
      `Episode ${episodeNumber} | ${episodeTitle}`,
    titleCss: {
      fontSize: convertPixelsToRems(16),
      fontWeight: ONYX_FONT_WEIGHTS.MEDIUM,
    },
  };

const fycPageConfig: FycComponentConfigurationTypeInterface['FYC_PAGE_CONFIG'] =
  {
    pageBackground: fycPageBackground,
    rootCss: {
      fontFamily: brandFont,
    },
  };

const navbarConfig: FycComponentConfigurationTypeInterface['NAVBAR_CONFIG'] = {
  fycSpanCss: {
    color: ONYX_COLORS.IVORY,
    fontFamily: brandFont,
    fontSizeTablet: convertPixelsToRems(16),
    fontWeight: ONYX_FONT_WEIGHTS.LIGHT,
  },
  lineCss: {
    borderLeft: `2px solid ${ONYX_COLORS.IVORY}`,
  },
  titleCss: {
    color: brandAccentColor,
  },
  wrapperCss: {
    background: ONYX_COLORS.EBONY,
    borderBottom: 'none',
  },
};

const sliderConfig: FycComponentConfigurationTypeInterface['SLIDER_CONFIG'] = {
  hasOverlay: true,
  imgNTitleWrapperCss: {
    titleFontFamily: brandFont,
    titleFontWeight: ONYX_FONT_WEIGHTS.LIGHT,
  },
  leftArrowIcon: {
    arrowColor: brandAccentColor,
    circleFill: ONYX_COLORS.EBONY,
    iconWidth: '56px',
  },
  titleNameCss: {
    color: brandAccentColor,
    textTransform: 'capitalize',
  },
};

const contactUsConfig: FycComponentConfigurationTypeInterface['CONTACT_US_CONFIG'] =
  {
    containerCss: {
      color: ONYX_COLORS.IVORY,
    },
  };

const onyxConfig: FycComponentConfigurationTypeInterface = {
  [FycConfigsEnum.ACCESS_CODE_CONFIG]: accessCodeConfig,
  [FycConfigsEnum.ALL_CONTENT_LANDING_CONFIG]: allContentLandingConfig,
  [FycConfigsEnum.BANNER_CONFIG]: bannerConfig,
  [FycConfigsEnum.CATEGORY_ITEM_CONFIG]: categoryItemConfig,
  [FycConfigsEnum.CATEGORY_SELECT_CONFIG]: categorySelectConfig,
  [FycConfigsEnum.DETAILS_BANNER_CONFIG]: detailsBannerConfig,
  [FycConfigsEnum.DETAILS_PAGE_CONFIG]: detailsPageConfig,
  [FycConfigsEnum.DETAILS_PART_CONFIG]: detailsPartConfig,
  [FycConfigsEnum.EPISODES_INFO_MODAL_CONFIG]: episodesInfoModalConfig,
  [FycConfigsEnum.EPISODES_PANEL_ITEM_CONFIG]: episodesPanelItemConfig,
  [FycConfigsEnum.FOOTER_CONFIG]: footerConfig,
  [FycConfigsEnum.FYC_PAGE_CONFIG]: fycPageConfig,
  [FycConfigsEnum.NAVBAR_CONFIG]: navbarConfig,
  [FycConfigsEnum.SLIDER_CONFIG]: sliderConfig,
  [FycConfigsEnum.CONTACT_US_CONFIG]: contactUsConfig,
};

export default onyxConfig;
