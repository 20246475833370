/** @jsx jsx */
import type { SerializedStyles } from '@emotion/core';
import { jsx } from '@emotion/core';
import clsx from 'clsx';
import { wmContainerCss } from './styles';

interface WatermarkContainerPropsInterface {
  children: React.ReactNode;
  classNames?: string;
  css?: SerializedStyles | string;
}

const WatermarkContainer: React.FC<WatermarkContainerPropsInterface> = ({
  children,
  classNames,
  css,
}) => {
  return (
    <div
      className={clsx(['watermark-container', classNames])}
      css={[wmContainerCss, css]}
    >
      {children}
    </div>
  );
};

export default WatermarkContainer;
