import type { SerializedStyles } from '@emotion/core';
import { css } from '@emotion/core';
import type { DetailsBannerConfigInterface } from 'providers/fyc-theme-component-interfaces';
import { FycConfigsEnum } from 'providers/fyc-theme-component-interfaces';
import { getVersionedComponentConfig } from 'providers/versions/fyc-theme-provider-utils';
import { mediaQuery } from 'utils/styles';
import { FYC_TABLET_WIDTH } from 'pages/fyc-page/helpers';
import type { FycVersionType } from 'providers/fyc-theme-provider';
import {
  ABC,
  DBT,
  DISNEYPLUS,
  DTS,
  FREEFORM,
  FX,
  HULU,
  NATGEO,
  SEARCHLIGHT,
  SEARCHLIGHT_AWARDS,
} from 'providers/fyc-theme-provider';

const MDP_MOBILE_WIDTH = 599;

export const backgroundCss = (version: FycVersionType): SerializedStyles => {
  const customCssConfig = getVersionedComponentConfig<
    DetailsBannerConfigInterface['backgroundCss']
  >({
    version,
    component: FycConfigsEnum.DETAILS_BANNER_CONFIG,
    selector: 'backgroundCss',
  });

  let bg = customCssConfig?.background ?? 'none';

  if (version === FX) {
    bg = `#D9D9D9`;
  }

  return css`
    background: ${bg};
    label: backgroundCss;
  `;
};

export const wrapperCss = (
  url: string | undefined | null,
  hasPlain: boolean,
  version: FycVersionType,
): SerializedStyles => {
  if (hasPlain) {
    return css`
      padding-top: ${14 / 16}rem;

      background: rgba(24, 26, 37, 0.57);
      ${mediaQuery(FYC_TABLET_WIDTH)} {
        padding-top: ${32 / 16}rem;
      }
    `;
  }

  const customCssConfig = getVersionedComponentConfig<
    DetailsBannerConfigInterface['wrapperCss']
  >({
    version,
    component: FycConfigsEnum.DETAILS_BANNER_CONFIG,
    selector: 'wrapperCss',
  });

  let background = customCssConfig?.background ?? `rgba(24, 26, 37, 0.85)`;

  if (version === FREEFORM || version === NATGEO) {
    background = `rgba(27, 27, 27, 1)`;
  }

  if (version === ABC) {
    background = `rgba(24, 26, 37, 0.85)`;
  }

  if (version === DBT || version === DISNEYPLUS) {
    background = `#040D2B`;
  }

  if (version === DTS) {
    background = `#040D2B`;
  }

  if (version === HULU) {
    background = 'linear-gradient(136.53deg, #040405 -19.92%, #183949 88.86%)';
  }

  if (version === SEARCHLIGHT || version === SEARCHLIGHT_AWARDS) {
    background = `rgba(38, 38, 38, 1)`;
  }

  if (version === FX) {
    background = `rgba(7, 7, 7, 0.90)`;
  }

  return css`
    padding-top: ${14 / 16}rem;
    background: ${background};
    backdrop-filter: blur(60px);
    label: wrapperCss;

    width: 100%;
    ${mediaQuery(FYC_TABLET_WIDTH)} {
      padding-top: ${32 / 16}rem;
    }
  `;
};

export const containerCss = css`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
`;

export const contentCss = (hideImageSection: boolean): SerializedStyles => css`
  display: flex;
  width: ${(890 / 1024) * 100}%;
  ${hideImageSection ? `` : `aspect-ratio: 890 / 322`};
  ${mediaQuery(FYC_TABLET_WIDTH)} {
    width: ${(1178 / 1440) * 100}%;
  }
  border-radius: 8px 8px 0px 0px;
  overflow: hidden;
`;

export const imageContainerCss = css`
  width: ${(573 / 890) * 100}%;
  border-radius: 8px 0px 0px 0px;
  aspect-ratio: 16 / 9;

  ${mediaQuery(FYC_TABLET_WIDTH)} {
    width: ${(781 / 1178) * 100}%;
  }
`;

export const actionsContainerCss = (
  hideImageSection: boolean,
  version: FycVersionType,
  proxisSeries: boolean,
): SerializedStyles => {
  const customCssConfig = getVersionedComponentConfig<
    DetailsBannerConfigInterface['actionsContainerCss']
  >({
    version,
    component: FycConfigsEnum.DETAILS_BANNER_CONFIG,
    selector: 'actionsContainerCss',
  });

  let background =
    customCssConfig?.background ??
    `linear-gradient(0.48deg, #101922 0.41%, #0f1214 84.74%)`;

  if (
    version === ABC ||
    version === FREEFORM ||
    version === DBT ||
    version === DISNEYPLUS ||
    version === DTS ||
    version === HULU ||
    version === SEARCHLIGHT ||
    version === SEARCHLIGHT_AWARDS ||
    version === NATGEO
  ) {
    background = `rgba(255, 255, 255, 0.1)`;
  }

  if (version === FX) {
    background = `rgba(255, 255, 255, 0.05)`;
  }

  return css`
    background: ${background};
    border-radius: 0px 8px 0px 0px;
    ${hideImageSection ? 'width: 100%' : ''};

    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: ${proxisSeries ? `space-between` : `start`};
    label: actionsContainerCss;
  `;
};

export const actionWrapperCss = (
  noTitleTreatmentImage: boolean,
  hideImageSection: boolean,
): SerializedStyles => {
  return css`
    width: 60%;
    ${hideImageSection ? `width: ${(100 - (679 / 1178) * 100) * 0.6}%` : ''};

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    margin-top: ${(48 / 499) * 100}%;
    ${noTitleTreatmentImage && `margin-top: ${(48 / 439) * 100}%`};
    ${hideImageSection && `margin-top: ${(30 / 890) * 100}%`};
    ${noTitleTreatmentImage && hideImageSection && `margin-top: 0`};
  `;
};

export const titleInfoCss = (version: FycVersionType): SerializedStyles => {
  const customCssConfig = getVersionedComponentConfig<
    DetailsBannerConfigInterface['titleInfoCss']
  >({
    version,
    component: FycConfigsEnum.DETAILS_BANNER_CONFIG,
    selector: 'titleInfoCss',
  });

  let fontFamily = customCssConfig?.fontFamily ?? 'inherit';
  const fontWeight = customCssConfig?.fontWeight ?? 'inherit';

  if (version === NATGEO) {
    fontFamily = `Geograph Medium`;
  }

  return css`
    margin-top: 9.25px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: ${14 / 16}rem;
    margin-bottom: ${(32.5 / 437) * 100}%;
    flex-direction: row;
    font-family: ${fontFamily};
    font-weight: ${fontWeight};
    label: titleInfoCss;

    ${mediaQuery(MDP_MOBILE_WIDTH)} {
      flex-direction: column;
    }

    ${mediaQuery(FYC_TABLET_WIDTH)} {
      flex-direction: row;
    }

    .rating {
      border-radius: 18px;
      border: 1px solid #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 2.5px;
      padding-left: 8px;
      padding-right: 8px;
      flex-wrap: wrap;
      margin-left: 0;

      ${mediaQuery(FYC_TABLET_WIDTH)} {
        margin-left: 24px;
      }
    }

    .runtime {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0;

      ${mediaQuery(MDP_MOBILE_WIDTH)} {
        margin-bottom: 7px;
      }

      ${mediaQuery(FYC_TABLET_WIDTH)} {
        margin-bottom: 0;
      }

      img {
        width: 16px;
        margin-right: 10px;
      }

      span {
        margin-top: 3px;
        color: rgba(255, 255, 255, 0.87);
      }
    }
  `;
};

export const videoActionCss = css`
  margin-top: 30px;

  height: 100%;
  width: 100%;
  max-width: ${180 / 16}rem;

  div {
    margin-top: 0;
  }

  button {
    height: ${28 / 16}rem;
    width: 100%;
    text-transform: uppercase;
  }
  ${mediaQuery(751)} {
    button {
      height: ${44 / 16}rem;
      width: 100%;
    }
  }
`;

export const accessCodeWrapperCss = css`
  ${mediaQuery(MDP_MOBILE_WIDTH)} {
    display: none;
  }
  ${mediaQuery(FYC_TABLET_WIDTH)} {
    display: flex;
  }
`;

export const accessBtnWrapperCss = css`
  width: 100%;
  max-width: ${200 / 16}rem;

  ${mediaQuery(MDP_MOBILE_WIDTH)} {
    display: flex;
  }
  ${mediaQuery(FYC_TABLET_WIDTH)} {
    display: none;
  }
`;

export const plainTitleCss = css`
  width: 100%;
  margin-top: ${32 / 16}rem;
  text-align: center;
  font-size: ${24 / 16}rem;
  font-size: 1rem;

  ${mediaQuery(FYC_TABLET_WIDTH)} {
    font-size: ${32 / 16}rem;
  }
`;
