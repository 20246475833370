/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useRef, useEffect } from 'react';
import {
  detailsContainerCss,
  imageContainerCss,
  noResultsContainerCss,
  resultCss,
  resultsContainerCss,
} from './styles';
import SearchImage from 'components/search-bar/search-image';
import { minsToTime } from 'utils';
import { Link } from 'react-router-dom';
import { ActionTypeEnum } from 'models/enums';
import UnlockButton from 'components/unlock-button';
import type { NdaServicePropsInterface } from '../../../hocs/with-nda-service';
import withNdaService from '../../../hocs/with-nda-service';
import { useOutsideClick } from 'utils/hooks';

export interface SearchResultsPropsInterface {
  filteredResults?: any[];
  hide?: boolean;
  inputFocused?: boolean;
  searchTerm?: string;
  setResultsViewClicked?(value: boolean): void;
  showNoResults?: boolean;
  showSearchResults?: boolean;
}

const SearchResults: React.FC<SearchResultsPropsInterface> = (
  props: SearchResultsPropsInterface & NdaServicePropsInterface,
) => {
  const resultsRef = useRef<HTMLDivElement>(null);
  const clickedOutside = useOutsideClick(resultsRef);

  useEffect(() => {
    /* @ts-ignore TODO: TS2722: Cannot invoke an object which is possibly undefined. */
    props.setResultsViewClicked(!clickedOutside);
  }, [clickedOutside, props]);

  const unlockedItem = (result: any) => {
    const { runtime } = result;
    let displayRuntime = '';
    if (runtime && runtime > 0) {
      /* @ts-ignore TODO: TS2322: Type string | null is not assignable to type string. */
      displayRuntime = minsToTime(runtime);
    }

    return (
      <Link
        css={resultCss}
        key={result.id}
        to={
          '/' +
          result?.actions?.find((action) => action.type === ActionTypeEnum.PAGE)
            .target
        }
      >
        <div css={imageContainerCss}>
          <SearchImage item={result} />
        </div>

        <div
          css={detailsContainerCss}
          data-testid="search-result"
        >
          <p>{result.title}</p>
          {displayRuntime && <p>{displayRuntime}</p>}
        </div>
      </Link>
    );
  };

  const lockedItem = (result: any) => {
    const { runtime } = result;
    let displayRuntime = '';
    if (runtime && runtime > 0) {
      /* @ts-ignore TODO: TS2322: Type string | null is not assignable to type string. */
      displayRuntime = minsToTime(runtime);
    }
    return (
      <Link
        to={
          '/' +
          result?.actions?.find((action) => action.type === ActionTypeEnum.PAGE)
            .target
        }
        css={resultCss}
        key={result.id}
      >
        <div css={imageContainerCss}>
          <div className={'button-unlock-filter'}>
            <SearchImage item={result} />
          </div>
          <UnlockButton
            onClick={() => {
              return;
            }}
          />
        </div>

        <div
          css={detailsContainerCss}
          data-testid="search-result"
        >
          <p>{result.title}</p>
          {displayRuntime && <p>{displayRuntime}</p>}
        </div>
      </Link>
    );
  };

  const displayResultsView = () => {
    if (props.showSearchResults) {
      return (
        <div
          css={resultsContainerCss}
          data-testid="search-results-box"
        >
          <p>{'Explore titles'}</p>

          {props.filteredResults?.map((result) => {
            const isLocked = props.verifyNdaCompletion(
              result.entitlement?.ndaTemplate,
            );

            return isLocked ? unlockedItem(result) : lockedItem(result);
          })}
        </div>
      );
    }

    if (props.showNoResults) {
      return (
        <div
          css={noResultsContainerCss}
          data-testid="search-no-results-box"
        >
          <p>{`No results found for “${props.searchTerm}”`}</p>
          <p>
            Try searching for another title or contact
            <br />
            support@disneydebut.com
          </p>
        </div>
      );
    }

    return null;
  };

  return (
    <div
      style={{ display: `${props.hide ? 'none' : 'block'}` }}
      ref={resultsRef}
    >
      {displayResultsView()}
    </div>
  );
};

export default React.memo(withNdaService(SearchResults));
