/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import React, { useState } from 'react';
import Alert, { DEFAULT_INFO_TEXT } from 'components/alert';

export interface WithErrorBoundaryConfigInterface {
  code?: number | null;
  isOpen?: boolean;
  message?: string | null;
  retries?: number;
}

export interface WithErrorBoundaryInterface {
  errorAlert?: any;
  errorCode?: any;
  errorMessage?: any;
  errorReset?(): void;
  errorRetries?: any;
}

export interface WithErrorBoundaryPropsInterface {
  children?: React.ReactNode;
  errorBoundary: ErrorPropsInterface;
}

interface ErrorPropsInterface {
  alert: { isOpen: boolean; set(bool: boolean): void; toggle(): void };
  code: { set(c: number): void; value: number | null | undefined };
  hasErrors: boolean;
  message: { set(m: string): void; value: string };
  reset(): void;
  retries: { set(r: number): void; value: number };
}

function withErrorBoundary<P extends object>(
  WrappedComponent: React.FC<P & WithErrorBoundaryPropsInterface>,
) {
  const WithErrorBoundary: React.FC<P> = (props: P) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [message, setMessage] = useState<string | null | undefined>(null);
    const [retries, setRetries] = useState<number>(0);
    const [code, setCode] = useState<number | null | undefined>(null);
    const [hasErrors, setHasErrors] = useState<boolean>(false);

    const toggleAlert = () => {
      setIsOpen((prevState) => !prevState);
    };

    const resetErrors = () => {
      setCode(null);
      setHasErrors(false);
      setIsOpen(false);
      setMessage(null);
      setRetries(0);
    };

    const errorProps: ErrorPropsInterface = {
      alert: { isOpen, set: setIsOpen, toggle: toggleAlert },
      code: { value: code, set: setCode },
      hasErrors: hasErrors,
      message: { value: message || DEFAULT_INFO_TEXT, set: setMessage },
      reset: resetErrors,
      retries: { value: retries || 0, set: setRetries },
    };

    const rootStyles = css`
      // CSS Here
    `;

    return (
      <React.Fragment>
        <WrappedComponent
          {...(props as P)}
          errorBoundary={errorProps}
        />
        {isOpen && (
          <div
            className="error-boundary-container"
            css={rootStyles}
          >
            <Alert
              isOpen
              infoText={message || DEFAULT_INFO_TEXT}
              onClick={resetErrors}
            />
          </div>
        )}
      </React.Fragment>
    );
  };

  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || 'Component';
  WithErrorBoundary.displayName = `withErrorBoundary(${displayName})`;
  return WithErrorBoundary;
}

export default withErrorBoundary;
