/** @jsx jsx */
import { jsx } from '@emotion/core';
import ToastTypography, { toastTypographyStyles } from './toast-typography';
import styles from './styles';
import icon from 'public/images/Alert-Circle-Outline.svg';
import React from 'react';
import ReactMarkdown from 'react-markdown/with-html';
import { bodyText } from 'components/typography/styles';

const ToastNotification: React.FC<{ body: string; head: string }> = ({
  head,
  body,
}) => {
  const defaultHead = head || 'Browser temporarily unavailable';
  const defaultBody =
    body ||
    'Unfortunately, the browser version you are using is not supported. Please consider using Google Chrome or download the Disney Debut iOS mobile app.';
  const tag = `<p className="toast-body">${defaultBody}</p>`;
  const markdownCss = [bodyText, toastTypographyStyles];

  return (
    <div
      className="toast-notification"
      css={styles}
    >
      <img
        className="toast-icon"
        src={icon}
      />
      <div className="toast-copy-container">
        <ToastTypography>{defaultHead}</ToastTypography>
        <ReactMarkdown
          css={markdownCss}
          source={tag}
          escapeHtml={false}
        />
      </div>
    </div>
  );
};

export default ToastNotification;
