import type { SerializedStyles } from '@emotion/core';
import { css } from '@emotion/core';
import theme from 'styles/theme';
// Position passed here should be a % measurement
export default (position: number): SerializedStyles => {
  const borderRadius = 8;
  const positionOffset = 1;
  return css`
    height: 0.5rem;
    width: 14.6rem;
    background: rgba(170, 170, 170, 0.6);
    border-radius: ${borderRadius}px;
    position: relative;

    .progress {
      width: calc(${position >= 100 ? 100 : position}% + ${positionOffset}px);
      height: 100%;
      background: ${theme.palette.common.white};
      border-radius: ${borderRadius}px;
      position: absolute;
      left: -${positionOffset}px;
    }
  `;
};
