import React from 'react';
import Item from '../item';
import type {
  NSEpisodeInterface,
  NSFycSeriesComponentInterface,
} from 'models/cms';

interface PropsInterface {
  component: NSFycSeriesComponentInterface;
  currentSeason: number;
  details: any;
  episodes: NSEpisodeInterface[];
}

const ItemList: React.FC<PropsInterface> = ({
  episodes,
  component,
  currentSeason,
  details,
}) => {
  return (
    <div>
      {episodes.map((e) => {
        return (
          <Item
            currentSeason={currentSeason}
            component={component}
            key={e.episodeId}
            entitlement={e}
            details={details}
          />
        );
      })}
    </div>
  );
};

export default ItemList;
