/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { useParams } from 'react-router-dom';

import Player from 'components/player';
import { useQuery } from 'utils/hooks';

interface RouteParamsInterface {
  playId: string;
}

const NsPlayerComponent = () => {
  const { playId } = useParams<RouteParamsInterface>();
  const query = useQuery();

  const authToken = query.get('auth');

  return (
    <Player
      playId={playId}
      authToken={authToken}
    />
  );
};

export default NsPlayerComponent;
