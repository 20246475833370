import { css } from '@emotion/core';
import { mediaQuery } from 'utils/styles';
import theme, {
  appBarHeight,
  bottomActionContainerHeight,
  headerWithNavHeight,
} from 'styles/theme';
import { WelcomeStepperEnum } from 'models/enums';
import { hintCss } from 'components/input/styles';

// Override default styles from the component
export const containerCss = css`
  margin-top: 1.8rem;
  ${mediaQuery(theme.breakpoints.md)} {
    margin-top: 1.8rem;
  }
  ${mediaQuery(theme.breakpoints.lg)} {
    margin-top: 1.8rem;
  }
`;

export const descriptionCss = css`
  width: 100%;
  ${mediaQuery(theme.breakpoints.md)} {
    width: 20.6rem;
  }
`;

export const formCss = css`
  // Fix a footer overlap within the range.
  @media only screen and (min-width: 1025px) and (max-width: 1340px) {
    height: 100vh;
  }
`;

export const successContainerCss = css`
  min-height: 50vh;
`;

export const extraMargin = '3rem';

export const heightAdjustment = (hasExtraMargin?: boolean) => `calc(
  100vh - ${headerWithNavHeight}px - ${bottomActionContainerHeight}rem ${
    hasExtraMargin ? '- ' + extraMargin : ''
  }
)`;

export const stepCss = css`
  height: ${heightAdjustment()};
  min-height: unset;
  overflow: scroll;
`;

export const tfaCss = (step) => {
  let styles;
  if (step >= WelcomeStepperEnum.INFO_STEP) {
    styles = `
      ${mediaQuery(theme.breakpoints.md)} {
        .right-panel {
          min-height: unset;
          height: calc(100vh - 100px);
          padding-right: 0;
        }
      }
    
      ${mediaQuery(theme.breakpoints.lg)} {
        .right-panel {
          min-height: 120vh;
        }
      }
  `;
  }

  if (
    step === WelcomeStepperEnum.INFO_STEP ||
    step === WelcomeStepperEnum.ENTER_CODE_STEP
  ) {
    styles = `
      ${mediaQuery(theme.breakpoints.lg)} {
        .right-panel {
          height: 100vh;
          padding-right: 0;
        }
      }
    `;
  }

  return styles;
};

export const rootCss = ({ step }) => css`
  .go-back-btn {
    &--top {
      margin-left: ${theme.margin.sides.xs};
      margin-bottom: 1rem;
      text-align: left;
    }
  }

  .right-panel {
    min-height: ${heightAdjustment(true)};
    ${step >= WelcomeStepperEnum.APP_INSTALL_STEP && stepCss};
  }

  footer {
    display: ${step === WelcomeStepperEnum.ENROLL_STEP ? 'block' : 'none'};
  }

  .field-error-text {
    ${hintCss}
    color: #e4615d;
  }

  ${mediaQuery(theme.breakpoints.md)} {
    .go-back-btn {
      &--top {
        margin: 3rem 0 0 7%;
      }
    }

    .right-panel {
      overflow: unset;
      position: relative;
      min-height: calc(100vh - ${appBarHeight}px - ${extraMargin});
    }

    footer {
      display: block;
    }
  }

  ${tfaCss(step)}
`;

export const formContainerCss = css`
  &.hide {
    display: none;
  }
`;
