/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { ReactNode } from 'react';
import { categoriesTabData } from 'utils';
import {
  contentCss,
  wrapperCss,
  reactTabsCss,
  tabsWrapperCss,
  tabTopCss,
  tabTitleCss,
  ulWrapperCss,
} from './styles';
import { Tab, Tabs as ReactTabs, TabList, TabPanel } from 'react-tabs';
import { TabTypeEnum } from 'models/enums';
import MovieDetailsSynopsis from 'components/details-page-component/movie-details-synopsis';
import CategoriesPanel from 'components/fyc-component/categories-panel';
import CreditsPanel from '../credits-panel';
import DetailsEntitlementPanel, {
  BONUS,
} from 'components/details-page-component/details-entitlement-panel';
import { EPISODES } from 'components/details-page-component/details-entitlement-panel';

export interface ShowTabInterface {
  data: any;
  displayName: string | undefined;
  tabId: TabTypeEnum;
}

export interface TabsViewPropsInterface {
  defaultTabIndex?: number;
  showTabs: ShowTabInterface[];
}

const TabsView: React.FC<TabsViewPropsInterface> = ({
  showTabs,
  defaultTabIndex = 0,
}) => {
  const generateTabContent = () => {
    const tabListItems: JSX.Element[] = [];
    const tabPanelItems: JSX.Element[] = [];

    if (!showTabs) {
      return;
    }

    for (const tab of showTabs) {
      tabListItems.push(
        <Tab key={tab.tabId + 'list'}>
          <div
            css={tabTopCss}
            className="tab-selected"
          />
          <div
            css={tabTitleCss}
            data-testid="tab-selected-text"
          >
            {tab.displayName?.toUpperCase()}
          </div>
        </Tab>,
      );

      if (tab.tabId === TabTypeEnum.SYNOPSIS) {
        tabPanelItems.push(
          <TabPanel key={tab.tabId + 'panel'}>
            <MovieDetailsSynopsis
              center
              color={'white'}
              synopsis={tab.data}
            />
          </TabPanel>,
        );
      }

      if (tab.tabId === TabTypeEnum.CATEGORIES) {
        tabPanelItems.push(
          <TabPanel key={tab.tabId + 'panel'}>
            <CategoriesPanel
              data={categoriesTabData({ categories: tab.data }, false).result}
            />
          </TabPanel>,
        );
      }

      if (tab.tabId === TabTypeEnum.ACCOLADES) {
        tabPanelItems.push(
          <TabPanel key={tab.tabId + 'panel'}>
            <CategoriesPanel
              data={categoriesTabData({ accolades: tab.data }, true).result}
            />
          </TabPanel>,
        );
      }

      if (tab.tabId === TabTypeEnum.CREDITS) {
        tabPanelItems.push(
          <TabPanel key={tab.tabId + 'panel'}>
            <CreditsPanel credits={tab.data} />
          </TabPanel>,
        );
      }

      if (tab.tabId === TabTypeEnum.RELATED) {
        tabPanelItems.push(
          <TabPanel key={tab.tabId + 'panel'}>
            <DetailsEntitlementPanel
              type={BONUS}
              data={tab.data}
            />
          </TabPanel>,
        );
      }

      if (tab.tabId === TabTypeEnum.EPISODES) {
        tabPanelItems.push(
          <TabPanel key={tab.tabId + 'panel'}>
            <DetailsEntitlementPanel
              type={EPISODES}
              data={tab.data}
            />
          </TabPanel>,
        );
      }
    }

    return {
      tabListItems,
      tabPanelItems,
    };
  };

  const { tabListItems, tabPanelItems } = generateTabContent() || {};

  return (
    <div css={wrapperCss}>
      <div css={contentCss()}>
        <div css={tabsWrapperCss}>
          <ReactTabs
            css={reactTabsCss()}
            defaultIndex={defaultTabIndex}
          >
            <TabList>
              <div css={ulWrapperCss}>{tabListItems}</div>
            </TabList>
            {tabPanelItems}
          </ReactTabs>
        </div>
      </div>
    </div>
  );
};

export default TabsView;
