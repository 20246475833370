import {
  FYC_MOBILE_WIDTH,
  FYC_TABLET_WIDTH,
} from './../../../pages/fyc-page/helpers';
import { css } from '@emotion/core';
import { mediaQuery } from 'utils/styles';

export const containerCss = css`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  overflow-y: auto;
  padding-top: ${24 / 16}rem;

  ::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }

  ${mediaQuery(FYC_MOBILE_WIDTH)} {
    padding-top: ${87 / 16}rem;
  }

  ${mediaQuery(FYC_TABLET_WIDTH)} {
    padding-right: ${178 / 16}rem;
    padding-top: ${67 / 16}rem;
  }
`;

export const buttonsCss = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: ${32 / 16}rem;
  margin-bottom: ${32 / 16}rem;

  ${mediaQuery(FYC_MOBILE_WIDTH)} {
    flex-direction: row;
    button {
      margin-top: ${61 / 16}rem;
    }
    justify-content: start;
    gap: ${40 / 16}rem;
  }

  button {
    margin-top: 0rem;
  }

  .terms-button {
    flex-shrink: 1;
    :disabled {
      background: rgba(107, 107, 107, 0.41);
    }
    ${mediaQuery(FYC_MOBILE_WIDTH)} {
      width: ${250 / 16}rem;
    }
  }

  .cancel-button {
    border: none;
    color: #5e94f7;
    ${mediaQuery(FYC_MOBILE_WIDTH)} {
      width: auto;
    }
  }
`;

export const VPPAWrapperCss = css`
  margin-top: 2rem;
  ${mediaQuery(FYC_MOBILE_WIDTH)} {
    margin-top: ${40 / 16}rem;
  }
`;
