/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useState } from 'react';
import type {
  NSEntitlementDetailsComponentInterface,
  NSFycSeriesComponentInterface,
} from 'models/cms';
import Select from 'react-select';
import { DropdownIndicator } from 'components/fyc-component/category-select';
import { selectStyles } from 'components/details-page-component/styles';
import ItemList from './item-list';
import {
  buildOptions,
  getCurrentOption,
  getCurrentEpisodesArr,
} from 'components/fyc-component/episodes-panel/helpers';
import { selectContainerCss } from './styles';
import { useFycThemeContext } from 'providers/fyc-theme-provider';

interface PropsInterface {
  component: NSFycSeriesComponentInterface;
  details?: NSEntitlementDetailsComponentInterface;
}
const EpisodesPanel: React.FC<PropsInterface> = ({ component, details }) => {
  const [currentSeason, setCurrentSeason] = useState(
    component.seasons?.[0].seasonNumber,
  );

  const version = useFycThemeContext()?.version;

  const options = buildOptions(component);

  if (!component.seasons || !currentSeason) {
    return null;
  }

  return (
    <div>
      <div css={selectContainerCss}>
        <Select
          onChange={(e) => setCurrentSeason(e?.value)}
          options={options}
          components={{ DropdownIndicator }}
          styles={selectStyles(version)}
          value={{ ...getCurrentOption(options, currentSeason) }}
        />
      </div>
      <div>
        <ItemList
          details={details}
          currentSeason={currentSeason}
          component={component}
          episodes={getCurrentEpisodesArr(component, currentSeason) ?? []}
        />
      </div>
    </div>
  );
};

export default EpisodesPanel;
