import { css } from '@emotion/core';

export const wrapperCss = css`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`;

export const modalCss = css``;

export const containerCss = css`
  margin-top: ${268 / 16}rem;

  width: ${473 / 16}rem;
  height: ${322 / 16}rem;
  background: #27283c;
  box-shadow:
    0px 4px 5px rgba(0, 0, 0, 0.14),
    0px 1px 10px rgba(0, 0, 0, 0.12),
    0px 2px 4px -1px rgba(0, 0, 0, 0.2);
  border-radius: 1rem;
`;

export const iconContainerCss = css`
  height: ${40 / 16}rem;
  margin-top: ${25 / 16}rem;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const titleCss = css`
  font-weight: 600;
  font-size: ${22 / 16}rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${20 / 16}rem;
`;

export const contentCss = css`
  width: 90%;
  margin: 1rem auto;
  text-align: center;
  p {
    font-size: ${14 / 16}rem;
    font-weight: 400;
  }
`;

export const btnCss = css`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${27 / 16}rem;

  .action-button {
    height: ${50 / 16}rem;
    width: ${279 / 16}rem;
    // background: #236df5;
    font-size: ${17 / 16}rem;
    font-weight: 500;
    color: #ffffff;
  }
`;
