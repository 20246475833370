/** @jsx jsx */
import { jsx } from '@emotion/core';
import type { EpisodeSeasonTitleFormatterFunctionType } from 'providers/fyc-theme-component-interfaces';
import { FycConfigsEnum } from 'providers/fyc-theme-component-interfaces';
import { getVersionedComponentConfig } from 'providers/versions/fyc-theme-provider-utils';
import React, { useState } from 'react';
import NsImage from 'components/ns-component/ns-image';
import {
  wrapperCss,
  imageWrapperCss,
  nsImageCss,
  textWrapperCss,
  titleCss,
  contentCss,
  plainImageCss,
} from './styles';
import EpisodesInfoModal from 'components/fyc-component/episodes-info-modal';
import type {
  NSEpisodeInterface,
  NSFycSeriesComponentInterface,
} from 'models/cms';
import { FX, useFycThemeContext } from 'providers/fyc-theme-provider';
import { hideAccessPrompt } from 'components/fyc-component/fyc-details-banner/helpers';

interface PropsInterface {
  component: NSFycSeriesComponentInterface;
  currentSeason: number;
  details: any;
  entitlement: NSEpisodeInterface;
}

const Item: React.FC<PropsInterface> = ({
  entitlement,
  component,
  currentSeason,
  details,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const version = useFycThemeContext()?.version;

  const episodeTitleFormatter =
    getVersionedComponentConfig<EpisodeSeasonTitleFormatterFunctionType>({
      version,
      component: FycConfigsEnum.EPISODES_PANEL_ITEM_CONFIG,
      selector: 'episodeTitleFormatter',
    });

  const getTitle = () => {
    if (episodeTitleFormatter) {
      return (
        <div css={titleCss(version)}>
          {episodeTitleFormatter({
            currentSeason,
            episodeTitle: entitlement.title,
            episodeNumber: entitlement.episodeNumber,
          })}
        </div>
      );
    }

    if (version === FX) {
      return (
        <div
          css={titleCss(version)}
        >{`S${currentSeason} E${entitlement.episodeNumber} - ${entitlement.title}`}</div>
      );
    }

    return (
      <div
        css={titleCss(version)}
      >{`${entitlement.episodeNumber}. ${entitlement.title}`}</div>
    );
  };

  return (
    <div css={wrapperCss}>
      <EpisodesInfoModal
        currentSeason={currentSeason}
        component={component}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        entitlementComponent={entitlement}
        details={details}
        hideAccessCode={hideAccessPrompt(entitlement)}
      />
      <div
        css={imageWrapperCss}
        onClick={() => setIsOpen(true)}
      >
        {(() => {
          if (details.hasPlain) {
            return (
              <div css={plainImageCss(version)}>
                <div className="top">{entitlement?.title}</div>
              </div>
            );
          }

          if (entitlement.heroImage?.url) {
            return (
              <NsImage
                url={entitlement.heroImage.url}
                width={800}
                aspectRatio={'16/9'}
                styles={nsImageCss}
              />
            );
          }
          return null;
        })()}{' '}
      </div>
      <div css={textWrapperCss}>
        {getTitle()}
        <div css={contentCss(version)}>{entitlement.synopsis}</div>
      </div>
    </div>
  );
};

export default Item;
