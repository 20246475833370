import { FYC_TABLET_WIDTH, FYC_MOBILE_WIDTH } from 'pages/fyc-page/helpers';
import type { SerializedStyles } from '@emotion/core';
import { css } from '@emotion/core';
import type { BannerComponentConfigInterface } from 'providers/fyc-theme-component-interfaces';
import { FycConfigsEnum } from 'providers/fyc-theme-component-interfaces';
import { getVersionedComponentConfig } from 'providers/versions/fyc-theme-provider-utils';
import { mediaQuery } from 'utils/styles';
import type { FycVersionType } from 'providers/fyc-theme-provider';
import {
  ABC,
  DBT,
  DISNEYPLUS,
  DTS,
  FREEFORM,
  FX,
  HULU,
  NATGEO,
  SEARCHLIGHT,
  SEARCHLIGHT_AWARDS,
  TWDS,
  TWDS_AWARDS,
} from 'providers/fyc-theme-provider';

export const wrapperCss = (
  version: FycVersionType,
  hasPlain = false,
): SerializedStyles => {
  const customCssConfig = getVersionedComponentConfig<
    BannerComponentConfigInterface['wrapperCss']
  >({
    version,
    component: FycConfigsEnum.BANNER_CONFIG,
    selector: 'wrapperCss',
  });

  let bg = customCssConfig?.background ?? 'none';
  let borderRadius = customCssConfig?.borderRadius ?? 'inherit';

  if ((version === TWDS || version === TWDS_AWARDS) && hasPlain) {
    bg =
      'linear-gradient(359.43deg, rgba(0, 78, 146, 0.46) -294.8%, #000428 34.06%, #004E92 276.33%)';
    borderRadius = `8px`;
  }

  return css`
    overflow: hidden;
    position: relative;
    background: ${bg};
    border-radius: ${borderRadius};
    label: backgroundCss;
  `;
};

export const imgsWrapperCss = (
  index: number,
  componentJustUpdated: boolean,
): SerializedStyles => css`
  display: flex;
  flex-direction: row;

  transform: translateX(-${index * 100}%);
  ${componentJustUpdated ?
    'transition: transform 0s ease 0s;'
  : 'transition: transform 1s ease 0s;'}
`;

export const imageContainerCss = (
  version: FycVersionType,
  click: boolean,
): SerializedStyles => {
  const customCssConfig = getVersionedComponentConfig<
    BannerComponentConfigInterface['imageContainerCss']
  >({
    version,
    component: FycConfigsEnum.BANNER_CONFIG,
    selector: 'imageContainerCss',
  });

  let borderRadius = customCssConfig?.borderRadius ?? `${10 / 16}rem`;
  let cursor = `inherit`;

  if (click) {
    cursor = `pointer`;
  }

  if (
    version === FREEFORM ||
    version === HULU ||
    version === SEARCHLIGHT ||
    version === SEARCHLIGHT_AWARDS ||
    version === NATGEO ||
    version === FX
  ) {
    borderRadius = `${30 / 16}rem`;
  }

  if (version === DBT || version === DISNEYPLUS || version === DTS) {
    borderRadius = `${23 / 16}rem`;
  }

  return css`
    flex-shrink: 0;
    width: 100%;
    border-radius: ${borderRadius};
    overflow: hidden;
    cursor: ${cursor};
    label: imageContainerCss;
  `;
};

export const actionsWrapperCss = css`
  position: absolute;
  z-index: 10;
  display: flex;
  flex-direction: row;
  width: 100%;
  bottom: ${8 / 16}rem;
  justify-content: center;

  ${mediaQuery(FYC_MOBILE_WIDTH)} {
    justify-content: end;
    bottom: ${16 / 16}rem;
  }

  ${mediaQuery(FYC_TABLET_WIDTH)} {
    justify-content: end;
    bottom: ${23 / 16}rem;
  }
`;

export const prevBtnCss = css`
  margin-right: ${24 / 16}rem;
  cursor: pointer;
  transform: rotate(-180deg);
  width: ${18 / 16}rem;
  height: ${18 / 16}rem;
  display: flex;
  align-items: center;
  img {
    width: 100%;
  }

  ${mediaQuery(FYC_MOBILE_WIDTH)} {
    width: ${24 / 16}rem;
    height: ${24 / 16}rem;
  }

  ${mediaQuery(FYC_TABLET_WIDTH)} {
    width: ${24 / 16}rem;
    height: ${24 / 16}rem;
  }
`;

export const nextBtnCss = css`
  margin-left: ${24 / 16}rem;
  cursor: pointer;
  width: ${18 / 16}rem;
  height: ${18 / 16}rem;
  display: flex;
  align-items: center;
  img {
    width: 100%;
  }

  ${mediaQuery(FYC_MOBILE_WIDTH)} {
    width: ${24 / 16}rem;
    height: ${24 / 16}rem;
    margin-right: ${23 / 16}rem;
  }

  ${mediaQuery(FYC_TABLET_WIDTH)} {
    width: ${24 / 16}rem;
    height: ${24 / 16}rem;
    margin-right: ${29 / 16}rem;
  }
`;

export const indicatorsCss = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
`;

export const indicatorItemCss = (
  active: boolean,
  version: FycVersionType,
): SerializedStyles => {
  // TODO: This is a temporary solution to avoid effecting current FYC brands

  const customCssConfig = getVersionedComponentConfig<
    BannerComponentConfigInterface['indicatorItemCss']
  >({
    version,
    component: FycConfigsEnum.BANNER_CONFIG,
    selector: 'indicatorItemCss',
  });

  const borderRadius = '50%';
  const width = `${8 / 16}rem`;
  const height = `${8 / 16}rem`;
  const cursor = 'pointer';

  // If the config exists but is not active
  if (customCssConfig?.defaultStyle && !active) {
    const background =
      customCssConfig?.defaultStyle?.background ??
      'background: rgba(255, 255, 255, 0.9)';
    const opacity = customCssConfig?.defaultStyle?.opacity ?? 0.5;

    return css({
      cursor: cursor,
      width: width,
      height: height,
      borderRadius: borderRadius,
      background: background,
      opacity: opacity,
      label: 'imageContainerCss',
      [`${mediaQuery(FYC_TABLET_WIDTH)}`]: {
        width: `${12 / 16}rem`,
        height: `${12 / 16}rem`,
      },
    });
  }

  // If the config exists and is active
  if (customCssConfig?.activeStyle && active) {
    const background =
      customCssConfig?.activeStyle?.background ??
      'background: rgba(255, 255, 255, 0.9)';
    const opacity = customCssConfig?.activeStyle?.opacity ?? 0.5;
    const transform = customCssConfig?.activeStyle?.transform ?? 'scale(1.5)';
    const transformTablet =
      customCssConfig?.activeStyle?.transformTablet ?? 'scale(1.33)';

    return css({
      cursor: cursor,
      width: width,
      height: height,
      borderRadius: borderRadius,
      background: background,
      opacity: opacity,
      transform: transform,
      label: 'imageContainerCss',
      [`${mediaQuery(FYC_TABLET_WIDTH)}`]: {
        transform: transformTablet,
        width: `${12 / 16}rem`,
        height: `${12 / 16}rem`,
      },
    });
  }

  // Legacy Styling
  let bg = `background: rgba(255, 255, 255, 0.9);
  opacity: 0.5;`;

  if (version === FX) {
    bg = `background: rgba(255, 255, 255);
  opacity: 0.5;`;
  }

  if (active) {
    bg = `background: #C19A41;
    opacity: 1;
    transform: scale(1.5);
    ${mediaQuery(FYC_TABLET_WIDTH)} {
    transform: scale(1.33);
    }
    `;

    if (version === ABC) {
      bg = `background: #F2F2F2;
      opacity: 1;
      transform: scale(1.5);
      ${mediaQuery(FYC_TABLET_WIDTH)} {
      transform: scale(1.33);
    }
    `;
    }

    if (version === FREEFORM) {
      bg = `
        background: #FF44E1;
        opacity: 1;
      `;
    }

    if (version === DBT || version === DISNEYPLUS) {
      bg = `background: rgba(12, 29, 73, 1);`;
    }

    if (version === DTS) {
      bg = `background: #E8B446;`;
    }

    if (version === HULU) {
      bg = `background: #1CE783;`;
    }

    if (version === SEARCHLIGHT || version === SEARCHLIGHT_AWARDS) {
      bg = `background: rgba(255, 255, 255, 1);`;
    }

    if (version === NATGEO) {
      bg = `background: rgba(250, 204, 3, 1);`;
    }

    if (version === FX) {
      bg = `background: #FFF;`;
    }
  }

  return css`
    cursor: pointer;
    width: ${8 / 16}rem;
    height: ${8 / 16}rem;
    border-radius: 50%;
    label: imageContainerCss;
    ${bg}

    ${mediaQuery(FYC_TABLET_WIDTH)} {
      width: ${12 / 16}rem;
      height: ${12 / 16}rem;
    }
  `;
};
