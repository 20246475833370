import { FYC_MOBILE_WIDTH } from 'pages/fyc-page/helpers';
import type { SerializedStyles } from '@emotion/core';
import { css } from '@emotion/core';
import { mediaQuery } from 'utils/styles';
import type { FycVersionType } from 'providers/fyc-theme-provider';
import {
  ABC,
  SEARCHLIGHT,
  SEARCHLIGHT_AWARDS,
} from 'providers/fyc-theme-provider';

export const wrapperCss = css`
  display: flex;
`;

export const audioTitleCss = (
  isPlaying: boolean,
  version: FycVersionType,
): SerializedStyles => {
  let fontFamily = `futura-pt`;
  let fontStyle = `inherit`;
  let color = `rgba(255, 255, 255, 0.65)`;
  let playingColor = `color: #fff;`;

  if (version === ABC) {
    fontFamily = `gt-america-co`;
    fontStyle = `italic`;
  }

  if (version === SEARCHLIGHT || version === SEARCHLIGHT_AWARDS) {
    color = `rgba(38, 38, 38, 0.65)`;
    playingColor = `color: #000;`;
  }

  return css`
    display: flex;
    flex-direction: column;
    font-size: ${12 / 16}rem;
    font-weight: 400;
    margin-bottom: ${8 / 16}rem;

    color: ${color};
    ${isPlaying && playingColor}
    label: audioTitleCss;

    ${mediaQuery(FYC_MOBILE_WIDTH)} {
      font-weight: 500;
      font-size: 1rem;
      margin-right: ${32 / 16}rem;
      margin-bottom: 0;
    }

    .audio-title {
      font-weight: 500;
      margin-bottom: ${6 / 16}rem;
    }

    .audio-artist {
      font-family: ${fontFamily};
      font-style: ${fontStyle};
      font-weight: 400;
    }
  `;
};
export const contentWrapperCss = css`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${mediaQuery(FYC_MOBILE_WIDTH)} {
    flex-direction: row;
    align-items: start;
  }
`;

export const playerWrapperCss = css`
  width: 100%;
  ${mediaQuery(FYC_MOBILE_WIDTH)} {
    width: 48%;
    flex-shrink: 0;
  }
`;
