/** @jsx jsx */
import { jsx } from '@emotion/core';
import clsx from 'clsx';
import React from 'react';
import Button from '../button';
import {
  containerCss,
  outerCss,
  hidden,
  infoTextCss,
  secondaryButtonCss,
  titleCss,
} from './styles';

export interface AlertModalInterface {
  dataTestId?: string;
  infoText?: string;
  isOpen?: boolean;
  onClick?(e?: any): any;
  primaryButtonText?: string;
  rootClassName?: string;
  secondaryAction?(e: any): any;
  secondaryButtonText?: string;
  styles?: any;
  title?: string;
  zIndex?: number;
}

export const DEFAULT_INFO_TEXT = 'Alert';

const Alert: React.FC<AlertModalInterface> = ({
  secondaryAction,
  onClick,
  title,
  infoText = DEFAULT_INFO_TEXT,
  primaryButtonText = 'Yes',
  secondaryButtonText = 'No',
  isOpen = false,
  styles,
  zIndex = 1,
  rootClassName,
  dataTestId,
}) => {
  return (
    <div
      className={clsx(['alert-modal', rootClassName])}
      css={[outerCss(styles, zIndex), isOpen && hidden]}
      onClick={(e) => e.stopPropagation()}
      data-testid={dataTestId}
    >
      <div
        css={containerCss(styles)}
        data-testid="alert-inner-container"
      >
        {title && (
          <p
            css={titleCss}
            data-testid="alert-title"
          >
            {title}
          </p>
        )}
        <p
          css={infoTextCss}
          data-testid="alert-info"
        >
          {infoText}
        </p>
        <Button
          type="button"
          theme="primary"
          onClick={onClick}
          data-testid="alert-primary-button"
          className="primary-button"
        >
          {primaryButtonText}
        </Button>
        {secondaryAction && (
          <Button
            type="button"
            theme="secondary"
            onClick={secondaryAction}
            css={secondaryButtonCss}
            data-testid="alert-secondary-button"
            className="secondary-button"
          >
            {secondaryButtonText}
          </Button>
        )}
      </div>
    </div>
  );
};

export default Alert;
