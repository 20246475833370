import type { SerializedStyles } from '@emotion/core';
import { css } from '@emotion/core';
import theme from 'styles/theme';
import { mediaQuery } from 'utils/styles';
import { EntitlementGridSizesEnum } from 'models/enums';
import { CONFIG_KEYS } from 'providers/strings-provider';

export const NUMBER_OF_ITEMS = {
  mobile: 2,
  tablet: 3,
  desktop: 4,
};

export const MARGINS = {
  mobile:
    (100 - EntitlementGridSizesEnum.MOBILE * NUMBER_OF_ITEMS.mobile) /
    (NUMBER_OF_ITEMS.mobile - 1),
  tablet:
    (100 - EntitlementGridSizesEnum.TABLET * NUMBER_OF_ITEMS.tablet) /
    (NUMBER_OF_ITEMS.tablet - 1),
  desktop:
    (100 - EntitlementGridSizesEnum.DESKTOP * NUMBER_OF_ITEMS.desktop) /
    (NUMBER_OF_ITEMS.desktop - 1),
};

export default (
  getConfigByKey: (key: string) => string,
): SerializedStyles => css`
  display: flex;
  width: 100%;

  .entitlement {
    &-item {
      display: block;
      margin-bottom: 1.5rem;
      margin-right: ${MARGINS.mobile}%;
      padding: 0;
      position: relative;
      width: ${EntitlementGridSizesEnum.MOBILE}%;
      flex: 0 0 ${EntitlementGridSizesEnum.MOBILE}%;
    }

    &-title,
    &-nominations {
      text-align: start;
      margin-top: 16px;
      font-size: 20px;
      line-height: 24px;
      font-weight: 300;
    }
    &-link {
      text-decoration: none;
      cursor: pointer;
    }

    &-nominations {
      font-size: 1rem;
    }

    &-locked {
      width: 100%;
      position: relative;
      overflow: hidden;
      border-radius: 6px;

      .treatment-container {
        filter: blur(8px);
      }

      .button-unlock {
        position: absolute;
        width: 66%;
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgb(0 0 0 / 80%);
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        margin: 0;

        .lock-icon {
          height: 18px;
          width: 18px;
        }
      }
    }
  }

  .treatment-container {
    position: relative;
    border: 1px solid ${theme.palette.background.primary};
    background: linear-gradient(
      -180deg,
      ${getConfigByKey(CONFIG_KEYS.CARD_BACKGROUND_COLOR)} 0%,
      ${getConfigByKey(CONFIG_KEYS.CARD_BACKGROUND_COLOR)} 100%
    );
    border-radius: 6px;
    overflow: hidden;
    padding: 1rem 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow:
      0px 4px 5px 0px rgba(0, 0, 0, 0.14),
      0px 1px 10px 0px rgba(0, 0, 0, 0.12),
      0px 2px 4px -1px rgba(0, 0, 0, 0.2);
    &:hover {
      border: ${theme.border.entitlementFocus};
      transition: border 0.1s linear;
    }

    &.no-padding {
      padding: 0;
    }
  }

  .title-treatment {
    text-align: center;
    font-size: 1.6rem;

    &.text-shrink {
      font-size: 1.3rem;
    }
  }

  .no-border,
  .no-border:hover {
    border: none;
  }

  .glow {
    &:before {
      display: none;
      visibility: hidden;
      opacity: 0;
      content: ' ';
      position: absolute;
      bottom: 1rem;
      z-index: -1;
      width: 125%;
      height: 100%;
      background-image: radial-gradient(
        ellipse,
        ${theme.palette.primary.light} -100%,
        transparent 65%
      );
      display: block;
      visibility: visible;
      opacity: 1;
      animation: fadeIn ease 0.25s;
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  ${mediaQuery(theme.breakpoints.md)} {
    .entitlement {
      &-item {
        display: block;
        width: ${EntitlementGridSizesEnum.TABLET}%;
        margin-right: ${MARGINS.tablet}%;
        flex: 0 0 ${EntitlementGridSizesEnum.TABLET}%;
      }

      &-title {
        text-align: start;
      }

      &-title,
      &-link,
      &-locked {
        width: 100%;
      }
    }

    .treatment-container {
      width: 100%;
    }

    .content {
      &-plays-remaining,
      &-expiry {
        width: 80%;
      }
    }

    .content-expiry-container {
      width: 80%;

      .content-plays-and-expiry {
        width: auto;
      }
    }
  }

  ${mediaQuery(theme.breakpoints.lg)} {
    .entitlement {
      &-nominations {
        margin: 0;
      }
      &-item {
        flex: 0 0 ${EntitlementGridSizesEnum.DESKTOP}%;
        width: ${EntitlementGridSizesEnum.DESKTOP}%;
        margin-right: ${MARGINS.desktop}%;
        flex: 0 0 ${EntitlementGridSizesEnum.DESKTOP}%;
      }
    }

    .content {
      &-plays-remaining,
      &-expiry {
        width: 90%;
        font-size: 1.1vw;
      }
    }

    .content-expiry-container {
      width: 90%;

      .content-plays-and-expiry {
        width: auto;
      }
    }

    .title-treatment {
      font-size: 1.2vw;
    }

    .entitlement-title {
      font-size: 1.2vw;
      line-height: 1.5;
      font-weight: 400;
    }
  }
`;

export const progressBarStyles = css`
  position: absolute;
  width: 100%;
  bottom: 0.25rem;
  padding: 0 0.5rem;

  .progress-bar {
    width: 100%;
  }
`;

export const sliderCss = css`
  width: 100%;
`;

export const leftArrowCss = css`
  .icon-wrapper {
    display: none;
  }

  :hover {
    background: linear-gradient(
      270deg,
      #000000 0%,
      #1a1919 0.01%,
      rgba(0, 0, 0, 0.42) 81.53%,
      rgba(0, 0, 0, 0) 111.02%
    );
    .icon-wrapper {
      display: flex;
    }
  }

  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: end;
  width: ${59 / 16}rem;
  position: absolute;
  top: ${1 + 22 / 16}rem;
  bottom: 1.5rem;
  left: calc(0px);
  z-index: 1;
  border-radius: 0px 4px 4px 0px;

  transform: rotate(-180deg);
`;

export const rightArrowCss = css`
  .icon-wrapper {
    display: none;
  }

  :hover {
    background: linear-gradient(
      270deg,
      #000000 0%,
      #1a1919 0.01%,
      rgba(0, 0, 0, 0.42) 81.53%,
      rgba(0, 0, 0, 0) 111.02%
    );
    .icon-wrapper {
      display: flex;
    }
  }

  cursor: pointer;
  display: flex;
  justify-content: center;
  width: ${59 / 16}rem;
  position: absolute;
  top: ${1 + 22 / 16}rem;
  bottom: 1.5rem;
  right: calc(0px);
  z-index: 1;
  border-radius: 0px 4px 4px 0px;
`;

export const arrowIconCss = css`
  width: 21px;
  height: 21px;
  border-top: 4px solid #ccc;
  border-left: 4px solid #ccc;
  border-radius: 3px;
  transform: rotate(135deg);
`;

export const iconWrapperCss = (height: number): SerializedStyles => css`
  width: 100%;
  // background: red;
  height: ${height}px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const titleCss = css`
  margin-bottom: 1rem;
  font-size: ${18 / 16}rem;
  height: ${22 / 16}rem;
`;
