/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import React, { useState } from 'react';
import {
  containerCss,
  leftContainerCss,
  contentCss,
  rightContainerCss,
} from './styles';
import infoYellow from 'public/images/info-yellow.svg';
import close from 'public/images/close.svg';
import type { PropsInterface } from '../index';
import { saveDismissedAnnouncement, isDismissedAnnouncement } from './helpers';
import ComponentContainer from 'components/component-container';
import theme from 'styles/theme';
import { mediaQuery } from 'utils/styles';

const wrapperCss = css`
  margin: 0 -1rem;
  padding: 0 5.2%;

  ${mediaQuery(theme.breakpoints.xs)} {
    padding: 0;
  }
`;

const Announcement: React.FC<PropsInterface> = (props: PropsInterface) => {
  const [isDismissed, setIsDismissed] = useState(() =>
    isDismissedAnnouncement(props.component?.id),
  );

  const handleDismiss = () => {
    saveDismissedAnnouncement(props.component?.id);
    setIsDismissed(true);
  };

  if (isDismissed) {
    return null;
  }

  return (
    <ComponentContainer>
      <div
        className="row"
        css={wrapperCss}
      >
        <div css={containerCss}>
          <div css={leftContainerCss}>
            <img src={infoYellow} />
          </div>
          <div css={contentCss}>
            <div className="title">{props.component?.header}</div>
            <div className="body">{props.component?.body}</div>
          </div>
          <div
            css={rightContainerCss}
            onClick={handleDismiss}
          >
            <img src={close} />
          </div>
        </div>
      </div>
    </ComponentContainer>
  );
};

export default Announcement;
