import type { PlayerAPI } from 'bitmovin-player';
import type { NSPlayDataType } from 'models/cms';
import { useCallback } from 'react';
import useConcurrencyLegacy from './use-concurrency-legacy';
import useConcurrencyVPP from './use-concurrency-vpp';

export interface ConcurrencyConfigInterface {
  data?: NSPlayDataType | null;
  error: (error: Error) => void;
  isFycPublic: boolean;
  player?: PlayerAPI | null;
  sessionId?: string | null;
  token?: string | null;
}

export default function useConcurrency(config: ConcurrencyConfigInterface) {
  const concurrencyLegacy = useConcurrencyLegacy(config);
  const concurrencyVPP = useConcurrencyVPP(config);

  return useCallback(async () => {
    await concurrencyLegacy.current?.close();
    await concurrencyVPP.current?.close();
  }, [concurrencyLegacy, concurrencyVPP]);
}

export class ConcurrencyError extends Error {
  constructor() {
    super('ConcurrencyError');
  }
}

export class ShouldRefetchPlayerDataError extends Error {
  constructor() {
    super('ShouldRefetchPlayerDataError');
  }
}
