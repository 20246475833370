/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import ExpiryDate from 'components/expiry-date';
import NsImage from 'components/ns-component/ns-image';
import type { EntitlementInterface } from 'models/components/entitlement-component-interface';
import React from 'react';
import {
  wrapperCss,
  imageWrapperCss,
  textWrapperCss,
  descriptionWrapperCss,
  plainTitleCss,
} from './styles';
import { UnlockButton } from 'components/details-page-component/details-page-lock-view';
import ProgressOverlayWrapper from 'components/progress-bar/progress-overlay-wrapper';

interface ProgressDataInterface {
  progress: number;
  show: boolean;
}

export interface ItemPropsInterface {
  alt?: string;
  description?: string;
  entitlement: EntitlementInterface;
  // used as key
  hasPlain: boolean;
  id: string;
  imgUrl: string;
  isLocked: boolean;
  item?: any;
  onClickAction: any;
  openPlayer?: any;
  originalTitle?: string;
  progressData?: ProgressDataInterface;
  title: string;
}

interface PropsInterface {
  item: ItemPropsInterface;
}

const Item: React.FC<PropsInterface> = ({ item }) => {
  const originalTitle =
    item.originalTitle ?
      item.originalTitle.replace(/\s+/g, '-').replace(',', '').toLowerCase()
    : undefined;

  const generateImageView = () => {
    if (item.hasPlain) {
      if (item.imgUrl !== '') {
        return (
          <div css={plainTitleCss}>
            <NsImage
              alt={item.alt || ''}
              url={item.imgUrl}
              width={800}
              aspectRatio={'16/9'}
            />
          </div>
        );
      } else {
        return <div css={plainTitleCss}>{item.item.title}</div>;
      }
    } else {
      return (
        <div
          data-testid={
            item.isLocked &&
            originalTitle &&
            `locked-treatment-container_${originalTitle}`
          }
          css={css`
            ${item.isLocked && 'filter: blur(8px);'}
          `}
        >
          <NsImage
            alt={item.alt || ''}
            url={item.imgUrl}
            width={800}
            aspectRatio={'16/9'}
          />
        </div>
      );
    }
  };

  const handleClick = () => {
    if (!!item.isLocked) {
      return;
    }

    item.openPlayer(item.item.actions[0].target, item.item);
  };

  return (
    <div
      className="treatment-container"
      css={wrapperCss}
      data-testid={originalTitle && `entitlement-item-${originalTitle}`}
    >
      <div
        css={imageWrapperCss}
        className="imageWrapper"
        onClick={handleClick}
      >
        {generateImageView()}
        {item.isLocked && (
          <UnlockButton
            onclick={() => item.onClickAction(item.entitlement?.ndaTemplate)}
          />
        )}
        {item.progressData?.show && !item.isLocked && (
          <ProgressOverlayWrapper
            progress={item.progressData.progress}
            bookmark={item.item.bookmark}
          />
        )}
      </div>

      <div
        data-testid="title-treatment-item"
        className="textWrapper"
        css={[
          textWrapperCss,
          css`
            ${item.isLocked && 'filter: blur(8px);'}
          `,
        ]}
      >
        <span>{item.title}</span>
        <div className="expiryWrapper">
          <ExpiryDate entitlement={item.entitlement} />
        </div>
        {item.description && item.description !== '' && (
          <div
            className="descriptionWrapper"
            css={descriptionWrapperCss}
          >
            {item.description}
          </div>
        )}
      </div>
    </div>
  );
};

export default Item;
