import type { ToggleButtonConfig } from 'bitmovin-player-ui/dist/js/framework/components/togglebutton';
import type { UIInstanceManager } from 'bitmovin-player-ui/dist/js/framework/uimanager';
import type { PlayerAPI } from 'bitmovin-player';
import { css } from '@emotion/core';
import { CastToggleButton } from 'bitmovin-player-ui';

export const castButtonStyles = css`
  .bmpui-cast-onboarding-message {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxOCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE1Ljc1IDAuMjVDMTYuOTkyNiAwLjI1IDE4IDEuMjU3MzYgMTggMi41VjExLjVDMTggMTIuNzQyNiAxNi45OTI2IDEzLjc1IDE1Ljc1IDEzLjc1SDIuMjVDMS4wMDczNiAxMy43NSAwIDEyLjc0MjYgMCAxMS41VjIuNUMwIDEuMjU3MzYgMS4wMDczNiAwLjI1IDIuMjUgMC4yNUgxNS43NVpNMTYuNSA0Ljc1SDEuNVYxMS41QzEuNSAxMS45MTQyIDEuODM1NzkgMTIuMjUgMi4yNSAxMi4yNUgxNS43NUMxNi4xNjQyIDEyLjI1IDE2LjUgMTEuOTE0MiAxNi41IDExLjVWNC43NVpNMTUuNzUgMS43NUgyLjI1QzEuODM1NzkgMS43NSAxLjUgMi4wODU3OSAxLjUgMi41VjQuNzVIMTYuNVYyLjVDMTYuNSAyLjA4NTc5IDE2LjE2NDIgMS43NSAxNS43NSAxLjc1WiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==);
    background-color: transparent;
    background-origin: content-box;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 1.5em;
    border: 0;
    box-sizing: content-box;
    cursor: pointer;
    font-size: 1em;
    height: 1.5em;
    min-width: 1.5em;
    padding: 0.25em;
  }
`;

export interface CastToggleButtonConfigInterface extends ToggleButtonConfig {
  offText: string;
  onText: string;
}
export class CustomCastToggleButton extends CastToggleButton {
  constructor(config: CastToggleButtonConfigInterface) {
    super(config);
  }

  configure(player: PlayerAPI, uimanager: UIInstanceManager): void {
    super.configure(player, uimanager);

    const config = this.getConfig() as CastToggleButtonConfigInterface;

    // Toggle label
    this.onToggleOn.subscribe(() => {
      super.onToggleOn;
      switchLabel(config.onText);
    });
    this.onToggleOff.subscribe(() => {
      super.onToggleOff;
      switchLabel(config.offText);
    });
  }
}

const switchLabel = (text: string) => {
  const buttonLabel = document.querySelector(
    `.bmpui-ui-casttogglebutton .bmpui-label`,
  ) as HTMLElement;
  if (buttonLabel) {
    buttonLabel.textContent = text;
  }
};
