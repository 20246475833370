/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import Modal from 'components/modal';
import {
  wrapperCss,
  containerCss,
  iconContainerCss,
  titleCss,
  contentCss,
  btnCss,
  modalCss,
} from './styles';
import WarningIcon from 'public/images/Warning_round.svg';
import Button from 'components/button';
import { useHistory } from 'react-router-dom';
import { STRING_KEYS, useStringsContext } from 'providers/strings-provider';

interface PropsInterface {
  isOpen: boolean;
}

const ExpiredModal: React.FC<PropsInterface> = ({ isOpen }) => {
  const { getStringByKey } = useStringsContext();
  const alertTitle = getStringByKey(STRING_KEYS.EXPIRED_ENTITLEMENT_TITLE);
  const alertBody = getStringByKey(STRING_KEYS.EXPIRED_ENTITLEMENT);

  const history = useHistory();

  return (
    <Modal
      isOpen={isOpen}
      css={modalCss}
    >
      <div css={wrapperCss}>
        <div
          className="expired-modal"
          css={containerCss}
        >
          <div css={iconContainerCss}>
            <img src={WarningIcon} />
          </div>
          <div css={titleCss}>{alertTitle}</div>
          <div css={contentCss}>
            <p>{alertBody}</p>
          </div>
          <div css={btnCss}>
            <Button
              theme="primary"
              onClick={() => history.push('/')}
            >
              Return to Home Page
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ExpiredModal;
