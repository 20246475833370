import type { CSSObject, SerializedStyles } from '@emotion/core';
import { css } from '@emotion/core';
import type { DetailsPageComponentConfigInterface } from 'providers/fyc-theme-component-interfaces';
import { FycConfigsEnum } from 'providers/fyc-theme-component-interfaces';
import { getVersionedComponentConfig } from 'providers/versions/fyc-theme-provider-utils';
import { generateStyle, mediaQuery } from 'utils/styles';
import theme, { appBarHeight } from 'styles/theme';
import { EntitlementGridSizesEnum } from 'models/enums';
import type { FycVersionType } from 'providers/fyc-theme-provider';
import {
  FX,
  HULU,
  SEARCHLIGHT,
  SEARCHLIGHT_AWARDS,
} from 'providers/fyc-theme-provider';

const titleCss = (styles?: SerializedStyles) => {
  const style = `
    ${mediaQuery(theme.breakpoints.xl)} {
      font-size: 7rem;
    }
  `;
  const defaultCss = [{ ...theme.typography.headline }, style];

  return generateStyle({
    defaultStyle: defaultCss,
    extendedStyles: styles,
    key: 'titleCss',
    theme,
  });
};

const clockIcon = (styles?: SerializedStyles) => {
  const defaultCss = `
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
  margin-bottom: 4px;

  ${mediaQuery(theme.breakpoints.xl)} {
    width: 2.5rem;
    height: 2.5rem;
  }
`;

  return generateStyle({
    defaultStyle: defaultCss,
    extendedStyles: styles,
    key: 'clockIcon',
    theme,
  });
};

const textCss = (styles?: SerializedStyles) => {
  const defaultCss = [
    { ...theme.typography.defaultText },
    `margin-right: ${theme.spacing.unit * 2}px;`,
  ];

  return generateStyle({
    defaultStyle: defaultCss,
    extendedStyles: styles,
    key: 'textCss',
    theme,
  });
};

const runtimeStyle = (styles?: SerializedStyles) => {
  const defaultCss = [
    textCss(styles),
    'margin-bottom: 0.3rem; margin-top: 0; letter-spacing: -1;',
    '.no-margin--left { margin-left: 0; }',
    `
      .runtime-info {
        display: inline-block;
      }
    `,
    `${mediaQuery(theme.breakpoints.xl)} {
      font-size: 2.1rem;
    }`,
  ];

  return generateStyle({
    defaultStyle: defaultCss,
    extendedStyles: styles,
    key: 'runtimeCss',
    theme,
  });
};

const ratingStyle = (styles?: SerializedStyles) => {
  const defaultCss = `
  font-size: 0.7rem;
  padding: 1.5px 8.5px 0px 8.5px;
  border-radius: 8.5px;
  border: ${theme.border.medium};
  vertical-align: middle;
  margin-left: 1.2rem;
  letter-spacing: 0;
  line-height: 1;

  ${mediaQuery(theme.breakpoints.xl)} {
    font-size: 1.7rem;
    padding: 7.5px 20.5px 0px 20.5px;
    border-width: 2px;
    border-radius: 20.5px;
  }
  `;

  return generateStyle({
    defaultStyle: defaultCss,
    extendedStyles: styles,
    key: 'ratingCss',
    theme,
  });
};

/**
 * ////////////////////////
 * Hero Styles
 * ////////////////////////
 */
const moreInfoCss = css`
  ${mediaQuery(theme.breakpoints.md)} {
    margin-bottom: 0.8rem;
  }
  ${mediaQuery(theme.breakpoints.lg)} {
    margin-bottom: 1rem;
  }
`;

const ratingCss = css`
  margin-left: 1.6rem;
`;

// default Button has a set width for md breakpoint, this overwrites it.
const playButtonCss = css`
  width: 100%;
  margin: 0;
  ${mediaQuery(theme.breakpoints.md)} {
    width: 100%;
  }
`;

const runtimeCss = css`
  margin-bottom: 0.37rem;
`;

const awardsCss = css`
  margin-bottom: 0.33rem;
  letter-spacing: -0.8;
  padding-left: 1px;
`;

const buttonActionContainerCss = css`
  display: flex;
  margin: 0.8rem 0 0.4rem 0;
  order: 0;
  padding: 0;
  ${mediaQuery(theme.breakpoints.md)} {
    margin: 0;
    margin-right: 0.65rem;
  }
  ${mediaQuery(theme.breakpoints.lg)} {
    margin-right: 1rem;
  }
  ${mediaQuery(theme.breakpoints.xl)} {
    max-width: 35%;
  }
`;

const actionContainerCss = css`
  ${mediaQuery(theme.breakpoints.md)} {
    margin-bottom: 0.2rem;
  }
  ${mediaQuery(theme.breakpoints.lg)} {
    margin: 0.2rem 0 2.25rem 0;
  }
`;

const playIconCss = css`
  left: 8rem;

  ${mediaQuery(theme.breakpoints.md)} {
    left: 2.5rem;
  }
`;

/**
 * ////////////////////////
 * Body Styles
 * ////////////////////////
 */

const bodyContainerCss = css`
  margin-top: 2rem;
  margin-bottom: 100px;
  letter-spacing: -0.2;
  line-height: 1.2;
  padding: 0 ${theme.margin.sides.xs};
  min-height: 50vh;
  ${mediaQuery(theme.breakpoints.md)} {
    padding: 0 ${theme.margin.sides.md};
  }
  ${mediaQuery(theme.breakpoints.lg)} {
    padding: 0 ${theme.margin.sides.lg};
  }
`;

const bodyDefaultTextCss = (
  center?: boolean,
  color?: string,
): SerializedStyles => {
  let textColor;
  if (color) {
    textColor = color;
  } else {
    color = 'rgba(255, 255, 255, 0.67)';
  }
  if (center) {
    return css`
      color: ${textColor};
      margin: 0 auto;
      line-height: 1.5;
      width: 90%;

      ${mediaQuery(theme.breakpoints.md)} {
        width: 75%;
      }
      ${mediaQuery(theme.breakpoints.lg)} {
        width: 73%;
      }
    `;
  }

  return css`
    color: ${textColor};
    margin: 0;
    line-height: 1.5;

    ${mediaQuery(theme.breakpoints.md)} {
      width: 75%;
    }
    ${mediaQuery(theme.breakpoints.lg)} {
      width: 73%;
    }
  `;
};

const creditTypeContainerCss = css`
  margin: 1rem 0.1rem 1rem 0.1rem;
  border-bottom: 2px solid rgba(255, 255, 255, 0.1);
  padding-bottom: 1rem;
  padding: 0 1rem;
  ${mediaQuery(theme.breakpoints.lg)} {
    width: 75%;
  }
  ${mediaQuery(theme.breakpoints.lg)} {
    width: 50%;
  }
`;

const creditTypeCss = css`
  padding: 0;
  margin-block-start: 0.25em;
  margin-block-end: 0.25em;
`;

const creditCss = css`
  padding: 0;
  margin-block-start: 0.25em;
  margin-block-end: 0.25em;
  color: rgba(255, 255, 255, 0.67);
`;

export const seriesRootCss = css`
  .series-select-container {
    width: ${EntitlementGridSizesEnum.MOBILE}%;
    margin-bottom: 2rem;
  }

  .episode-title {
    margin-bottom: 0.5rem;
  }

  .synopsis {
    margin-top: 0.5rem;
  }

  .content-plays-and-expiry {
    margin: 0;
  }

  ${mediaQuery(theme.breakpoints.md)} {
    .series-select-container {
      width: ${EntitlementGridSizesEnum.TABLET}%;
    }
  }
  ${mediaQuery(theme.breakpoints.lg)} {
    .series-select-container {
      width: ${EntitlementGridSizesEnum.DESKTOP}%;
    }

    .entitlement-item {
      .content-plays-and-expiry,
      .separator {
        font-size: 1rem;
      }
    }
  }
`;

export const rootCss = css`
  min-height: calc(
    100vh - ${theme.height.footer.minHeight.md} - ${appBarHeight}px
  );

  /* Don't show plays left for Series  */
  &.sdp .meta-info,
  &.sdp .more-info {
    .content-plays-and-expiry,
    .separator {
      display: none !important;
    }
  }

  .header {
    ${bodyContainerCss}
    min-height: 10vh;
    margin-bottom: 3.75rem;
    margin-top: 3rem;

    ${mediaQuery(theme.breakpoints.lg)} {
      margin-top: 6rem;
      margin-bottom: 8rem;
      min-height: 25vh;
    }
  }

  .protected-content {
    min-height: 66vh;

    ${mediaQuery(theme.breakpoints.md)} {
      .typography-subheading {
        font-size: 2.67vw;
      }
    }

    ${mediaQuery(theme.breakpoints.lg)} {
      .typography-subheading {
        font-size: 1.67vw;
      }
    }
  }

  .clock-icon {
    ${clockIcon()}
  }

  .details {
    .col-sm-12 {
      padding: 0;
    }
  }

  .title h2 {
    ${titleCss()}
    margin-bottom: 1rem;
    font-size: 2rem;
    font-weight: 700;
    line-height: 1.25;
  }

  .seasons-info {
    margin-right: 2rem;
  }

  .text-long--shrink h2 {
    font-size: 1.875rem;

    ${mediaQuery(theme.breakpoints.md)} {
      font-size: 2rem;
    }
  }

  .rating {
    ${ratingStyle()}
    margin-left: 1.6rem;
  }

  .runtime {
    ${runtimeStyle()}
    margin-bottom: 1.5rem;
  }

  .play-series-button {
    margin-top: 1.5rem;
  }

  .progress-bar-container {
    width: 100%;
    order: 0;

    ${mediaQuery(theme.breakpoints.md)} {
      margin: 1rem 0;
    }
  }

  .treatment-container .progress-bar-container {
    ${mediaQuery(theme.breakpoints.md)} {
      margin: 0;
    }
  }

  .progress-bar {
    width: 100%;
  }

  /* Override default styles and media query hiearchy */
  .action-button button {
    width: 100%;

    ${mediaQuery(theme.breakpoints.md)} {
      width: 100%;
    }

    ${mediaQuery(theme.breakpoints.lg)} {
      width: 100%;
      max-width: 224px;
    }
  }
`;

export const progressBarStyles = css`
  ${mediaQuery(theme.breakpoints.md)} {
    padding-right: 0.65rem;
  }
  ${mediaQuery(theme.breakpoints.lg)} {
    max-width: calc(224px * 2 + 0.75rem);
    padding: 0;
  }
`;

export const markdownCss = css`
  font-family: ${theme.typography.fontFamily};
  color: ${theme.palette.text.secondary};
  margin: 0;
  line-height: 1.5;

  ${mediaQuery(theme.breakpoints.md)} {
    width: 66%;
  }
`;

interface SelectStylesReturnTypeInterface {
  control: (base: CSSObject, state: { isFocused: boolean }) => CSSObject;
  indicatorSeparator: (base: CSSObject) => CSSObject;
  menu: (base: CSSObject) => CSSObject;
  option: (
    base: CSSObject,
    options: { isDisabled: boolean; isSelected: boolean },
  ) => CSSObject;
  singleValue: (base: CSSObject) => CSSObject;
}

export const selectStyles = (
  version: FycVersionType,
): SelectStylesReturnTypeInterface => {
  const customCssConfig = getVersionedComponentConfig<
    DetailsPageComponentConfigInterface['selectStyles']
  >({
    version,
    component: FycConfigsEnum.DETAILS_PAGE_CONFIG,
    selector: 'selectStyles',
  });

  let singleValueFontFamily =
    customCssConfig?.singleValueFontFamily ?? 'inherit';
  let bg = customCssConfig?.bg ?? '#313145';
  let singleValueColor = customCssConfig?.singleValueColor ?? `#fff`;
  let singleValueFontWeight =
    customCssConfig?.singleValueFontWeight ?? 'inherit';
  const fontSize = customCssConfig?.fontSize ?? `inherit`;

  if (version === HULU) {
    singleValueFontFamily = `Graphik Medium`;
  }

  if (version === SEARCHLIGHT || version === SEARCHLIGHT_AWARDS) {
    bg = `rgba(243, 239, 230, 0.75)`;
    singleValueColor = `rgba(31, 31, 32, 1)`;
  }

  if (version === FX) {
    singleValueFontWeight = 600;
    bg = `rgba(69, 70, 81, 0.45)`;
  }

  return {
    control: (base, state) => {
      return {
        ...base,
        backgroundColor: `${bg}`,
        borderWidth: '1px',
        borderRadius: '6px',
        borderStyle: 'solid',
        borderColor: state.isFocused ? 'none' : '#313145',
        boxShadow: 'none',
        fontSize: fontSize,
        '&:hover': {
          border: '1px solid white',
        },
      };
    },
    menu: (base) => {
      return {
        ...base,
        backgroundColor: '#313145',
        border: '1px solid #313145',
        marginTop: 1,
      };
    },
    option: (base, { isDisabled, isSelected }) => ({
      ...base,
      backgroundColor: !isDisabled && isSelected ? '#404052' : undefined,
    }),
    singleValue: (base) => ({
      ...base,
      fontFamily: singleValueFontFamily,
      color: singleValueColor,
      fontWeight: singleValueFontWeight,
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: 'none',
    }),
  };
};

const styles = {
  moreInfoCss,
  ratingCss,
  playButtonCss,
  runtimeCss,
  awardsCss,
  buttonActionContainerCss,
  actionContainerCss,
  bodyContainerCss,
  bodyDefaultTextCss,
  creditTypeContainerCss,
  creditTypeCss,
  creditCss,
  playIconCss,
};

export default styles;
