/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logout } from 'store/slices/user';
import { HELP, LOGIN_LANDING } from 'router/constants';

import settingsIcon from 'public/images/Settings.svg';
import settingsToggledIcon from 'public/images/Settings_Toggled.svg';

import Alert from '../alert';

import {
  containerCss,
  toggleCss,
  linkCss,
  iconCss,
  linkListItemCss,
  listCss,
  settingsMenuContainerCss,
  transparentBackgroundCss,
} from './styles';

interface PropsInterface {
  styles?: any;
}

const Settings: React.FC<PropsInterface> = ({ styles }) => {
  const [isToggled, setIsToggled] = useState<boolean>(false);
  const [isLoggingOut, setIsLoggingOut] = useState<boolean>(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const toggleSettingsMenu = () => {
    setIsToggled(!isToggled);
  };

  const handleLogout = () => {
    history.push(LOGIN_LANDING);
    dispatch(logout());
    // force refresh just in case the TOC has been updated
    window.location.reload();
  };

  const handleAlertState = (state) => {
    setIsLoggingOut(state);
    setIsToggled(false);
  };

  const generateLinks = (currentPath) => {
    const help = { name: 'help', label: 'Help', path: HELP };
    const logout = {
      name: 'sign-out',
      label: 'Sign Out',
      path: '',
    };
    const links = [help, logout];

    const buildLink = ({ name, label, path }) => {
      switch (name) {
        case 'sign-out':
          return (
            <li
              key={name}
              css={[linkListItemCss(currentPath, path)]}
            >
              <a
                data-testid="setting-sign-out"
                css={linkCss}
                onClick={() => handleAlertState(true)}
              >
                {label}
              </a>
            </li>
          );
        default:
          return (
            <li
              key={name}
              css={linkListItemCss(currentPath, path)}
            >
              <Link
                data-testid="setting-help"
                to={path}
                css={linkCss}
                onClick={toggleSettingsMenu}
              >
                {label}
              </Link>
            </li>
          );
      }
    };

    return <ul css={listCss}>{links.map(buildLink)}</ul>;
  };

  return (
    <div
      data-testid="nav-setting-icon"
      css={containerCss(styles)}
    >
      <div
        css={[isToggled && transparentBackgroundCss]}
        onClick={toggleSettingsMenu}
      ></div>
      <img
        src={isToggled ? settingsToggledIcon : settingsIcon}
        onClick={toggleSettingsMenu}
        css={iconCss(styles)}
      />
      <div css={[settingsMenuContainerCss(styles), isToggled && toggleCss]}>
        {generateLinks(history.location.pathname)}
      </div>
      <Alert
        rootClassName="signout-modal"
        title="Confirmation"
        infoText="Are you sure you want to sign out?"
        onClick={handleLogout}
        isOpen={isLoggingOut}
        secondaryAction={() => handleAlertState(false)}
      />
    </div>
  );
};

export default Settings;
