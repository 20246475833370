import React from 'react';
import { LayoutContainer } from 'containers';
import { useHistory } from 'react-router-dom';
import NsAppBar from 'components/ns-app-bar';
import { goBackCss, rightPanelCss } from './styles';
import type { FooterPropsInterface } from 'models/components/footer';

interface PropsInterface {
  footerProps?: FooterPropsInterface;
  showAppBar?: boolean;
  styles?: any;
}

const FullWidthLayout: React.FC<PropsInterface> = ({
  children,
  showAppBar = true,
  styles,
  footerProps,
}) => {
  const history = useHistory();

  return (
    <LayoutContainer
      auth
      showBack
      onBack={history.goBack}
      styles={{
        rightPanelCss: rightPanelCss(showAppBar, styles),
        goBackCss: goBackCss(styles),
      }}
      fullWidth
      AppBarComponent={showAppBar && <NsAppBar />}
      footerProps={{
        isHidden: footerProps?.isHidden,
        styles: {
          footerContainerCss: footerProps?.styles?.footerContainerCss,
        },
      }}
    >
      {children}
    </LayoutContainer>
  );
};

export default FullWidthLayout;
