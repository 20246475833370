import React from 'react';
import UnsupportedDevice from 'components/unsupported-device';
import NsAppBar from 'components/ns-app-bar';
import { Footer } from 'components';
import { footerContainerCss } from 'containers/layout/styles';
import { useBowser } from 'utils/hooks';
import { DeviceTypeEnum } from 'models/enums';

interface PropsInterface {
  auth?: boolean;
}

const DownloadApp: React.FC<PropsInterface> = ({ auth = false }) => {
  const bowser = useBowser();
  const isTablet = bowser.platform.type === DeviceTypeEnum.TABLET;

  return (
    <div className="page">
      <NsAppBar showSettings={auth} />
      <UnsupportedDevice />
      <Footer
        fixed={isTablet}
        styles={{ footerContainerCss }}
      />
    </div>
  );
};

export default DownloadApp;
