/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import React, { useState, Fragment, useRef, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { FullWidthLayoutContainer } from 'containers';
import { Footer } from 'components';
import * as routes from 'router/constants';
import {
  useStringsContext,
  STRING_KEYS,
  CONFIG_KEYS,
} from 'providers/strings-provider';
import { useProfile } from 'utils/profile';
import {
  authenticationSelector,
  checkTosStatusSelector,
  turnOffCheckTosStatus,
  logout,
} from 'store/slices/user';
import Info from 'public/images/Info.svg';

import styles, {
  termsPageCss,
  termsTopCss,
  termsBottomCss,
  termsContentsCss,
  termsTitleCss,
  labelCheckboxCss,
  checkLabelCss,
  contentBodyCss,
  subTitleCss,
} from './styles';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

interface LocationStateInterface {
  from?: string;
}

const Terms: React.FC = () => {
  const [isScrolledToBottom, setIsScrolledToBottom] = useState<boolean>(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const history = useHistory();
  const location = useLocation<LocationStateInterface | undefined>();
  const dispatch = useDispatch();
  const { isAuthenticated, userId } = useSelector(authenticationSelector);
  const [profile, saveProfile] = useProfile();
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [isVPPAChecked, setIsVPPAChecked] = useState<boolean>(false);
  const { getStringByKey, getConfigByKey } = useStringsContext();
  const toggleCheck = () => setIsChecked((prevState) => !prevState);
  const toggleVPPACheck = () => setIsVPPAChecked((prevState) => !prevState);
  const checkTosStatus = useSelector(checkTosStatusSelector);
  const privacyTCUpdatedDate = getConfigByKey(
    CONFIG_KEYS.PRIVACY_TC_UPDATED_DATE,
  );
  const privacyTCConsentIntervalDays = getConfigByKey(
    CONFIG_KEYS.PRIVACY_TC_CONSENT_INTERVAL_DAYS,
  );
  const [showNormal, setShowNormal] = useState<boolean>(true);

  const onAgree = () => {
    // store T&C acceptance as ISO 8601 date in localstorage
    if (userId) {
      profile.termsAccepted = true;
      profile.termsAcceptedDate = new Date().toString();
      profile.tosDate = privacyTCUpdatedDate;
      saveProfile(profile);
    }

    // redirect user to intended destination
    if (location.state?.from) {
      history.push(location.state.from);
    } else {
      // otherwise direct them to index
      history.push(routes.INDEX);
    }

    dispatch(turnOffCheckTosStatus());
  };

  const onBack = () => {
    dispatch(logout());
    history.push(routes.LOGIN_LANDING);
  };

  useEffect(() => {
    const handleScroll = () => {
      // Only enforce scrolling 95% down the content to reduce customer care contacts (SR-8541)
      //@ts-ignore TODO: TS18047: 'contentRef.current' is possibly 'null'.
      const scrollTarget = Math.floor(contentRef.current.scrollHeight * 0.05);

      const scrolledHeight =
        //@ts-ignore TODO: TS18047: 'contentRef.current' is possibly 'null'.
        contentRef.current.scrollHeight - contentRef.current.clientHeight;

      setIsScrolledToBottom((prev) => {
        if (prev) {
          return prev;
        }

        //@ts-ignore TODO: TS18047: 'contentRef.current' is possibly 'null'.
        return scrolledHeight - contentRef.current.scrollTop <= scrollTarget;
      });
    };

    if (!contentRef.current) {
      return;
    }

    // Only enforce scrolling 95% down the content to reduce customer care contacts (SR-8541)
    const scrollTarget = Math.floor(contentRef.current.scrollHeight * 0.05);

    // initial check when ref current is available
    setIsScrolledToBottom(
      contentRef.current.scrollHeight -
        contentRef.current.clientHeight -
        contentRef.current.scrollTop <=
        scrollTarget,
    );

    contentRef.current.addEventListener('scroll', handleScroll);

    const cleanUp = () => {
      contentRef.current?.removeEventListener('scroll', handleScroll);
    };

    return cleanUp;
  }, [showNormal]);

  const content = (
    <Fragment>
      <div
        css={css`
          font-size: 1rem;
          font-weight: 500;
        `}
        data-testid="terms-title"
      >
        Terms and Conditions
      </div>
      <p
        css={subTitleCss}
        data-testid="terms-subtitle"
      >
        Please read and agree to our Terms and Conditions.
      </p>
      <p css={contentBodyCss}>{getStringByKey(STRING_KEYS.PRIVACY_TC_MSG)}</p>
    </Fragment>
  );
  const vppaContent = (
    <Fragment>
      <div
        css={css`
          font-size: 1rem;
          font-weight: 500;
        `}
        data-testid="terms-title"
      >
        {getStringByKey(STRING_KEYS.WEB_VPPA_TC_TITLE)}
      </div>

      <p css={contentBodyCss}>
        {getStringByKey(STRING_KEYS.PRIVACY_TC_VPPA_MSG)}
      </p>
    </Fragment>
  );

  // handle which result should be show
  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    if (checkTosStatus) {
      const now = dayjs();
      const tosDate = dayjs(privacyTCUpdatedDate, 'YYYY/MM/DD', true);
      const isInvalid =
        !tosDate.isValid() ||
        !dayjs(profile.tosDate, 'YYYY/MM/DD', true).isSame(tosDate, 'day');

      if (!profile.termsAccepted) {
        setShowNormal(false);
      } else if (!profile.termsAcceptedDate) {
        setShowNormal(false);
      } else if (isInvalid) {
        setShowNormal(false);
      } else if (
        dayjs(profile.termsAcceptedDate).diff(now, 'day', true) >
        // @ts-ignore TODO: TS2345: Argument of type 'string | undefined' is not assignable to parameter of type 'string'
        parseInt(privacyTCConsentIntervalDays)
      ) {
        setShowNormal(false);
      }
    } else {
      setShowNormal(true);
    }
  }, [
    checkTosStatus,
    isAuthenticated,
    privacyTCConsentIntervalDays,
    privacyTCUpdatedDate,
    profile.termsAccepted,
    profile.termsAcceptedDate,
    profile.tosDate,
  ]);

  if (showNormal && !isAuthenticated && profile.termsAccepted) {
    return (
      <FullWidthLayoutContainer
        showAppBar={true}
        styles={{
          rightPanelCss: styles.fullWidthRightPanelCss,
        }}
        footerProps={{
          isHidden: true,
        }}
      >
        <div
          className="full-width-content col-md-10"
          css={styles.fullWidthContainerCss}
        >
          {content}
        </div>
        <Footer
          styles={{
            footerContainerCss: styles.footerRootCss,
          }}
        />
      </FullWidthLayoutContainer>
    );
  }

  return (
    <div
      className="terms-page"
      css={termsPageCss}
    >
      <div
        className="terms-top"
        css={termsTopCss}
      >
        <div
          className="terms-title"
          css={termsTitleCss}
        >
          <div className="title-text">Almost there</div>
          <div className="waring-info">
            <div>
              <img src={Info} />
            </div>
            <div
              css={css`
                display: flex;
                align-items: center;
              `}
            >
              {getStringByKey(STRING_KEYS.IOS_WEB_SCROLL_TC_VPPA)}
            </div>
          </div>
        </div>
        <div
          css={termsContentsCss}
          ref={contentRef}
          data-testid="terms-text"
        >
          {content}
          {vppaContent}
        </div>
      </div>
      <div
        className="terms-bottom"
        css={termsBottomCss}
      >
        <div className="checkboxes">
          <label
            css={checkLabelCss(isScrolledToBottom)}
            className="terms-label"
          >
            <input
              data-testid="terms-checkbox1"
              css={labelCheckboxCss}
              onChange={toggleCheck}
              checked={isChecked}
              type="checkbox"
              name="terms"
              disabled={!isScrolledToBottom}
            />
            <span className="checkmark" />
            {getStringByKey(STRING_KEYS.iOS_WEB_I_AGREE_TC)}
          </label>
          <label
            css={checkLabelCss(isScrolledToBottom)}
            className="terms-label"
          >
            <input
              data-testid="terms-checkbox2"
              css={labelCheckboxCss}
              onChange={toggleVPPACheck}
              checked={isVPPAChecked}
              type="checkbox"
              name="terms"
              disabled={!isScrolledToBottom}
            />
            <span className="checkmark" />
            {getStringByKey(STRING_KEYS.iOS_WEB_I_AGREE_VPPA)}
          </label>
        </div>
        <div className="buttons">
          <button
            className="cancel-button"
            onClick={onBack}
          >
            Cancel
          </button>
          <button
            disabled={!isScrolledToBottom || !isChecked || !isVPPAChecked}
            onClick={onAgree}
            className="terms-button"
            data-testid="terms-agree"
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};

export default Terms;
