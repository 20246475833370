import type { SerializedStyles } from '@emotion/core';
import css from '@emotion/css';
import type { NavbarComponentConfigInterface } from 'providers/fyc-theme-component-interfaces';
import { FycConfigsEnum } from 'providers/fyc-theme-component-interfaces';
import { getVersionedComponentConfig } from 'providers/versions/fyc-theme-provider-utils';
import { mediaQuery } from 'utils/styles';
import { FYC_MOBILE_WIDTH, FYC_TABLET_WIDTH } from 'pages/fyc-page/helpers';
import type { FycVersionType } from 'providers/fyc-theme-provider';
import {
  ABC,
  DBT,
  DISNEYPLUS,
  DTS,
  FREEFORM,
  FX,
  HULU,
  NATGEO,
  SEARCHLIGHT,
  SEARCHLIGHT_AWARDS,
} from 'providers/fyc-theme-provider';

export const wrapperCss = (version: FycVersionType): SerializedStyles => {
  const customCssConfig = getVersionedComponentConfig<
    NavbarComponentConfigInterface['wrapperCss']
  >({
    version,
    component: FycConfigsEnum.NAVBAR_CONFIG,
    selector: 'wrapperCss',
  });

  let borderBottom = customCssConfig?.borderBottom ?? `none`;
  let background =
    customCssConfig?.background ??
    `linear-gradient(200.36deg, #000000 -21.93%, #01182f 97.03%),
  #27283c`;

  if (version === ABC) {
    borderBottom = 'none';
    background = `rgba(10, 19, 32, 1)`;
  }

  if (version === FREEFORM) {
    borderBottom = 'none';
    background = `#212799`;
  }

  if (
    version === DBT ||
    version === DISNEYPLUS ||
    version === DTS ||
    version === HULU
  ) {
    borderBottom = 'none';
    background = 'transparent';
  }

  if (version === SEARCHLIGHT || version === SEARCHLIGHT_AWARDS) {
    background = '#FBF7EE';
  }

  if (version === NATGEO) {
    background = '#FFF';
  }

  if (version === FX) {
    background = '#000';
  }

  return css`
    display: flex;
    align-items: center;
    justify-content: start;
    z-index: 100;
    left: 0;
    top: 0;
    right: 0;
    height: ${60 / 16}rem;
    text-transform: uppercase;
    border-bottom: ${borderBottom};
    background: ${background};
    backdrop-filter: blur(27.1828px);
    padding-left: 3%;
    label: wrapperCss;

    ${mediaQuery(FYC_MOBILE_WIDTH)} {
      padding-left: ${(90 / 1440) * 100}%;
    }

    ${mediaQuery(FYC_TABLET_WIDTH)} {
      height: ${69 / 16}rem;
    }
  `;
};

export const logoContainerCss = (size: any): SerializedStyles | undefined => {
  if (!size) {
    return;
  }

  return css`
    height: ${size.sm.height};

    cursor: pointer;

    ${mediaQuery(FYC_MOBILE_WIDTH)} {
      height: ${size.md.height};
    }

    ${mediaQuery(FYC_TABLET_WIDTH)} {
      height: ${size.lg.height};
    }

    img {
      height: 100%;
      width: auto;
      object-fit: cover;
    }
  `;
};

export const containerCss = css`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const titleCss = (version: FycVersionType): SerializedStyles => {
  const customCssConfig = getVersionedComponentConfig<
    NavbarComponentConfigInterface['titleCss']
  >({
    version,
    component: FycConfigsEnum.NAVBAR_CONFIG,
    selector: 'titleCss',
  });

  let color = customCssConfig?.color ?? 'white';

  if (
    version === SEARCHLIGHT ||
    version === NATGEO ||
    version === SEARCHLIGHT_AWARDS
  ) {
    color = 'rgba(0, 0, 0, 1)';
  }

  return css`
    font-size: ${24 / 16}rem;
    letter-spacing: 3px;
    font-weight: 500;
    cursor: pointer;
    font-family: futura-pt-condensed;
    color: ${color};
    white-space: nowrap;
    label: titleCss;
  `;
};

export const lineCss = (version: FycVersionType): SerializedStyles => {
  const customCssConfig = getVersionedComponentConfig<
    NavbarComponentConfigInterface['lineCss']
  >({
    version,
    component: FycConfigsEnum.NAVBAR_CONFIG,
    selector: 'lineCss',
  });

  let borderLeft =
    customCssConfig?.borderLeft ?? `2px solid rgba(193, 154, 65, 1)`;

  if (version === ABC) {
    borderLeft = `2px solid rgba(255, 255, 255, 0.3)`;
  }

  if (
    version === FREEFORM ||
    version === DBT ||
    version === DISNEYPLUS ||
    version === DTS ||
    version === HULU ||
    version === FX
  ) {
    borderLeft = `2px solid #FFFFFF`;
  }

  if (
    version === SEARCHLIGHT ||
    version === NATGEO ||
    version === SEARCHLIGHT_AWARDS
  ) {
    borderLeft = '2px solid #0A1320';
  }

  return css`
    height: ${33 / 16}rem;
    border-left: ${borderLeft};
    border-radius: ${2 / 16}rem;
    label: lineCss;
  `;
};

export const fycSpanCss = (version: FycVersionType): SerializedStyles => {
  const customCssConfig = getVersionedComponentConfig<
    NavbarComponentConfigInterface['fycSpanCss']
  >({
    version,
    component: FycConfigsEnum.NAVBAR_CONFIG,
    selector: 'fycSpanCss',
  });

  let fontFamily = customCssConfig?.fontFamily ?? `futura-pt-condensed`;
  let color = customCssConfig?.color ?? 'white';
  let fontSizeTablet = customCssConfig?.fontSizeTablet ?? `${18 / 16}rem`;
  let fontWeight = customCssConfig?.fontWeight ?? 400;

  if (version === ABC) {
    color = `rgba(222, 0, 43, 1)`;
    fontFamily = `gt-america-co`;
    fontSizeTablet = '1rem';
  }

  if (version === FREEFORM) {
    fontSizeTablet = `1rem`;
  }

  if (version === DBT || version === DISNEYPLUS) {
    color = `#FFFFFF`;
    fontFamily = `proxima-nova`;
  }

  if (version === DTS) {
    color = `#E8B446`;
    fontFamily = `Avenir`;
    fontWeight = 500;
  }

  if (version === HULU) {
    color = `#FFFFFF`;
    fontFamily = `Graphik Semibold`;
    fontWeight = 600;
    fontSizeTablet = `1rem`;
  }

  if (version === SEARCHLIGHT || version === SEARCHLIGHT_AWARDS) {
    color = `rgba(0, 0, 0, 1)`;
    fontSizeTablet = `1rem`;
    fontFamily = `'Open Sans', sans-serif`;
  }

  if (version === NATGEO) {
    color = `rgba(0, 0, 0, 1)`;
    fontSizeTablet = '1rem';
    fontFamily = `Geograph Medium`;
  }

  if (version === FX) {
    color = `#A5A5A5`;
    fontWeight = 600;
    fontSizeTablet = '1rem';
    fontFamily = `Bain`;
  }

  return css`
    color: ${color};
    font-size: ${14 / 16}rem;
    font-weight: ${fontWeight};
    font-family: ${fontFamily};
    letter-spacing: 2px;
    label: fycSpanCss;

    ${mediaQuery(FYC_TABLET_WIDTH)} {
      font-size: ${fontSizeTablet};
    }
  `;
};
