import React, { useState, useEffect } from 'react';
import { searchByTemplateIdsApi, verifyOrCreateApi } from 'api';
import { NdaTemplateStatusTypeEnum } from 'models/enums';
import { useSelector } from 'react-redux';
import { tokenSelector } from 'store/slices/user';
import { NDATemplateTypesEnum } from 'models/enums';
import { STRING_KEYS, useStringsContext } from 'providers/strings-provider';
import type { AlertModalInterface } from 'components/alert';
import Alert from 'components/alert';

import type { PlayerPropsInterface } from 'models/interfaces/player-provider';
import type { SearchResultsPropsInterface } from 'components/search-bar/search-results';
import { matchPath } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useDecodedJwt } from 'utils/hooks';

export interface NdaInterface {
  signingUrl?: string;
  status: NdaTemplateStatusTypeEnum.SENT | NdaTemplateStatusTypeEnum.COMPLETED;
  templateId: string;
}

export interface NdaListInterface {
  [templateId: string]: {
    status:
      | NdaTemplateStatusTypeEnum.SENT
      | NdaTemplateStatusTypeEnum.COMPLETED;
  };
}

export interface LoadStateInterface {
  isLoading: boolean;
  label?: string;
}
const normalizeData = (data: NdaInterface[]): NdaListInterface => {
  return data.reduce((acc, nda) => {
    acc[nda.templateId] = { status: nda.status };
    return acc;
  }, {});
};

export interface NdaServicePropsInterface {
  closePlayer?(): void;
  errors: Error | null;
  handleNdaClick(
    templateId: string,
    options: { onSecondaryClick?(): void },
  ): Promise<void>;
  isNdaModalOpen: boolean;
  loadState: LoadStateInterface;
  ndaList: NdaListInterface;
  verifyNdaCompletion(templateId: string): boolean;
}

const initialLoadState: LoadStateInterface = {
  isLoading: false,
  label: 'Loading',
};

const initialModalState = { isOpen: false };

const withNdaService = (
  WrappedComponent: React.ComponentType<any>,
  filterByStatus?: NdaTemplateStatusTypeEnum,
  isPlayer?: boolean,
): React.FC<
  | NdaServicePropsInterface
  | PlayerPropsInterface
  | SearchResultsPropsInterface
  | any
> => {
  const WithNdaService = (props) => {
    const [loadState, setLoadState] =
      useState<LoadStateInterface>(initialLoadState);
    /* @ts-ignore TODO: TS2345: Argument of type null is not assignable to parameter of type NdaListInterface | (() => NdaListInterface). */
    const [ndaList, setNdaList] = useState<NdaListInterface>(null);
    /* @ts-ignore TODO: TS2345: Argument of type null is not assignable to parameter of type Error | (() => Error). */
    const [errors, setErrors] = useState<Error>(null);
    const [modalState, setModalState] =
      useState<AlertModalInterface>(initialModalState);
    const { accessToken } = useSelector(tokenSelector);
    const { getStringByKey } = useStringsContext();
    const { authToken: isBaftaUser } = props;
    const decodedJwt = useDecodedJwt();

    const { pathname } = useLocation();
    const isFyc: any = matchPath(pathname, {
      path: '/fyc',
      exact: false,
      strict: false,
    });

    const filterNdaListByStatus = (
      ndaList: NdaInterface[],
      status: NdaTemplateStatusTypeEnum = NdaTemplateStatusTypeEnum.COMPLETED,
    ): NdaInterface[] =>
      ndaList.filter((nda) => {
        return nda.status === status;
      });

    const handleErrorState = (error: Error) =>
      /* @ts-ignore TODO: TS2345: Argument of type (prevState: AlertModalInterface) => { title: string; infoText: any; primaryButtonText: string; secondaryButtonText: null; onClick: () => void; secondaryAction: null; ... 4 more ...; zIndex?: number | undefined; } is not assignable to parameter of type SetStateAction<AlertModalInterface>. */
      setModalState((prevState) => ({
        ...prevState,
        title: 'Error',
        infoText:
          JSON.parse(error.message).message || 'Please try again later.',
        primaryButtonText: 'Okay',
        secondaryButtonText: null,
        onClick: () => setModalState(initialModalState),
        secondaryAction: null,
        isOpen: true,
      }));

    const handleSecondaryAction = (extraFunc?: () => void): void => {
      setModalState((prevState) => ({ ...prevState, isOpen: false }));
      if (extraFunc && typeof extraFunc === 'function') {
        extraFunc();
      }
    };

    const handleUnlockClick = (
      templateId: string,
      { onSecondaryClick }: { onSecondaryClick?(): void } = {},
    ) =>
      setModalState((prevState) => ({
        ...prevState,
        title: 'Notice',
        infoText: getStringByKey(STRING_KEYS.NDA_LEAVING),
        primaryButtonText: 'Okay',
        secondaryButtonText: 'Cancel',
        onClick: () => onClick(templateId),
        secondaryAction: () => handleSecondaryAction(onSecondaryClick),
        isOpen: true,
      }));

    const verifyNdaCompletion = (templateId: string): boolean => {
      if (!templateId || templateId === NDATemplateTypesEnum.CLICK_THROUGH) {
        return true;
      }

      return (
        ndaList?.[templateId]?.status === NdaTemplateStatusTypeEnum.COMPLETED
      );
    };

    const handleLoadState = (state: LoadStateInterface) =>
      setLoadState((prevState) => ({ ...prevState, ...state }));

    const handleErrors = (error: Error) => {
      handleErrorState(error);
      handleLoadState(initialLoadState);
      setErrors((prevState) => ({ ...prevState, ...error }));
    };

    async function onClick(templateId: string): Promise<void> {
      try {
        setModalState(initialModalState);
        handleLoadState({
          isLoading: true,
          label: 'Loading',
        });

        const { body } = await verifyOrCreateApi(
          /* @ts-ignore TODO: TS2345: Argument of type string | null | undefined is not assignable to parameter of type string. */
          accessToken,
          templateId,
          window.location.href,
        );

        if (body.status === NdaTemplateStatusTypeEnum.SENT && body.signingUrl) {
          handleLoadState({
            isLoading: true,
            label: 'Redirecting to Docusign',
          });
          // THE LINK IS HERE
          window.location.href = body.signingUrl;
        } else if (body.status === NdaTemplateStatusTypeEnum.COMPLETED) {
          setNdaList((prevState) => ({
            ...prevState,
            [templateId]: { status: NdaTemplateStatusTypeEnum.COMPLETED },
          }));
          handleLoadState(initialLoadState);
        }
      } catch (e) {
        handleErrors(e);
      }
    }

    useEffect(() => {
      let isCancelled = false;

      const fetchData = async () => {
        if (!decodedJwt) {
          return;
        }

        try {
          handleLoadState({ isLoading: true });
          /* @ts-ignore TODO: TS2345: Argument of type string | null | undefined is not assignable to parameter of type string. */
          const { body } = await searchByTemplateIdsApi(accessToken);
          let list = body.ndaTemplates;
          if (filterByStatus) {
            list = filterNdaListByStatus(list, filterByStatus);
          }

          list = normalizeData(list);

          if (!isCancelled) {
            setNdaList(list);
          }
        } catch (e) {
          if (!isCancelled) {
            handleErrors(e);
          }
        } finally {
          if (!isCancelled) {
            handleLoadState(initialLoadState);
          }
        }
      };

      // Omit NDA checks for BAFTA users
      !!!isBaftaUser && !!!isFyc && fetchData();

      return () => {
        // prevent no ops and memory leaks on unmount
        isCancelled = true;
      };
    }, []);

    return (
      <React.Fragment>
        {modalState?.isOpen && (
          <Alert
            title={modalState.title}
            infoText={modalState.infoText}
            primaryButtonText={modalState.primaryButtonText}
            secondaryButtonText={modalState.secondaryButtonText}
            onClick={modalState.onClick}
            secondaryAction={modalState.secondaryAction}
            isOpen={modalState.isOpen}
            zIndex={1000}
            rootClassName={'nda-service'}
          />
        )}
        {(!!ndaList || !!isBaftaUser || !!isFyc || !!isPlayer) && (
          <WrappedComponent
            {...props}
            loadState={loadState}
            errors={errors}
            ndaList={ndaList}
            isNdaModalOpen={modalState.isOpen}
            handleNdaClick={handleUnlockClick}
            verifyNdaCompletion={verifyNdaCompletion}
          />
        )}
      </React.Fragment>
    );
  };

  return WithNdaService;
};

export default withNdaService;
