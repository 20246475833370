import { css } from '@emotion/core';
import { generateStyle, mediaQuery } from 'utils/styles';
import theme from 'styles/theme';

export const iconCss = (styles?: any) => {
  const defaultStyle = `
    cursor: pointer;
    z-index: ${theme.zIndex.settingsDrawer};
  `;

  return generateStyle({
    defaultStyle,
    extendedStyles: styles,
    key: 'settingsIcon',
    theme,
  });
};

export const containerCss = (styles?: any) => {
  const defaultStyle = `
      display: flex;
      justify-content: flex-end;

      .signout-modal {
        .primary-button, .secondary-button {
          width: 100%;
          margin: 0 0 1rem 0;
        }

        .primary-button {
          margin-top: 1rem;
        }
      }

      ${mediaQuery(theme.breakpoints.md)} {
        .signout-modal {
          .primary-button, .secondary-button {
            width: 66%;
          }
        }
      }
    `;

  return generateStyle({
    defaultStyle,
    extendedStyles: styles,
    key: 'settingsContainer',
    theme,
  });
};

export const settingsMenuContainerCss = (styles?: any) => {
  const defaultStyle = `
      position: absolute;
      width: ${298 / 16}rem;
      right: -${298 / 16}rem;
      top: 3.77rem;
      box-shadow: ${theme.shadows[1]};
      background-color: ${theme.palette.background.secondary};
      height: calc(100vh - 3.77rem);
      transition: right 0.75s;
      z-index: ${theme.zIndex.settingsDrawer};
  
      ${mediaQuery(theme.breakpoints.lg)} {
          display: none;
          top: 3.75rem;
          right: 10.33%;
          width: ${200 / 16}rem;
          height: auto;
          border-bottom: 1px solid transparent;
          border-radius: 0 0 8px 8px;
      }
      `;

  return generateStyle({
    defaultStyle,
    extendedStyles: styles,
    key: 'settingsMenuContainer',
    theme,
  });
};

export const linkListItemCss = (location: string, path: any) => {
  const isActive = location === path;
  return css`
    cursor: pointer;
    display: flex;
    align-items: center;
    background: ${isActive && 'rgba(255, 255, 255, 0.07)'};
    height: ${72 / 16}rem;
    ${mediaQuery(theme.breakpoints.lg)} {
      height: ${50 / 16}rem;
    }
  `;
};

export const linkCss = [
  { ...theme.typography.defaultText },
  css`
    font-weight: 100;
    font-size: 1.3rem;
    text-decoration: none;
    padding-left: 2rem;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;

    ${mediaQuery(theme.breakpoints.lg)} {
      letter-spacing: 1;
      font-size: 0.8rem;
      padding-left: 1rem;
    }
  `,
];

export const listCss = css`
  padding-left: 0rem;
  margin: 0.5rem 0;
`;

export const toggleCss: any = css`
  right: 0;

  ${mediaQuery(theme.breakpoints.lg)} {
    display: block;
  }
`;

export const transparentBackgroundCss = css`
  position: absolute;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  z-index: ${theme.zIndex.clickOutLayer};
`;
