import { css } from '@emotion/core';
import { TABLET_X_SMALL } from 'components/details-page-component/details-banner/helpers';
import { mediaQuery } from 'utils/styles';

export const wrapperCss = css`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: ${24 / 16}rem;

  ${mediaQuery(TABLET_X_SMALL)} {
    flex-direction: row;
    gap: ${24 / 16}rem;
  }
`;

export const imageWrapperCss = css`
  cursor: pointer;
  position: relative;
  width: 100%;
  aspect-ratio: 16 / 9;
  border-radius: ${6 / 16}rem;
  overflow: hidden;

  flex-shrink: 0;

  ${mediaQuery(TABLET_X_SMALL)} {
    width: ${266 / 16}rem;
    height: ${149 / 16}rem;
  }
`;

export const textWrapperCss = css`
  font-weight: 500;
  font-size: 1rem;
  margin-top: 1rem;
  line-height: 19px;

  ${mediaQuery(TABLET_X_SMALL)} {
    margin-top: 0;
  }
`;

export const blurCss = css`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  // background: rgba(0, 0, 0, 0.0932583);
  backdrop-filter: blur(5.43656px);
`;

export const descriptionWrapperCss = css`
  font-weight: 400;
  font-size: 1rem;
`;

export const plainTitleCss = css`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: black;
  padding: ${40 / 16}rem;
`;

export const progressBarCss = css`
  position: absolute;
  z-index: 100;
  bottom: 8px;
  width: 90%;
  left: 50%;
  transform: translate(-50%, 0);

  .progress-bar-container {
    margin: 0;
  }

  .progress-bar {
    background: rgba(191, 191, 191, 0.4);
    height: 4px;
    width: 100%;
  }

  .progress {
    background: #ffffff !important;
  }
`;
