import type {
  NSAnnouncementComponentInterface,
  NSBannerComponentInterface,
  NSBrandGridComponentInterface,
  NSGalleryComponentInterface,
  NSMarketingComponentInterface,
  NSMessagingComponentInterface,
  NSMovieAdditionalInfoComponentInterface,
  NSMovieCreditComponentInterface,
  NSMovieSynopsisComponentInterface,
  NSPageComponentInterface,
  NSSeriesComponentInterface,
  NSTextComponentInterface,
  NSTitleComponentInterface,
} from 'models/cms';
import { ComponentTypeEnum } from 'models/enums';
import { DisplayTypeEnum } from 'models/enums';
import React from 'react';
import NsAnnouncementComponent from './ns-announcement-component';
import NsBannerComponent from './ns-banner-component';
import NSBrandGridComponent from './ns-brand-grid-component';
import NsCarousel from './ns-carousel';
import NsEntitlementsComponent from './ns-entitlements-component';
import NsImageGridComponent from './ns-image-grid-component';
import NsMarketingComponent from './ns-marketing-component';
import NsMessagingComponent from './ns-messaging-component';
import NsMovieAdditionalInfoComponent from './ns-movie-additional-info-component';
import NsMovieCreditComponent from './ns-movie-credit-component';
import NsMovieMarqueeComponent from './ns-movie-marquee-component';
import NsMovieSynopsisComponent from './ns-movie-synopsis-component';
import NsPlayerComponent from './ns-player-component';
import NsSeriesComponent from './ns-series-component';
import ShortBannerComponent from './ns-short-banner-component';
import NsTextComponent from './ns-text-component';
import NsTitleComponent from './ns-title-component';

interface NsComponentPropsInterface {
  className?: string;
  component: NSPageComponentInterface;
}

class NsComponent extends React.Component<NsComponentPropsInterface> {
  public render(): React.ReactNode {
    const { component } = this.props;

    switch (component.type) {
      case ComponentTypeEnum.TEXT:
        return (
          <NsTextComponent
            {...this.props}
            component={component as NSTextComponentInterface}
          />
        );
      case ComponentTypeEnum.TITLE:
        return (
          <NsTitleComponent
            {...this.props}
            component={component as NSTitleComponentInterface}
          />
        );
      case ComponentTypeEnum.ANNOUNCEMENT:
        return (
          <NsAnnouncementComponent
            {...this.props}
            component={component as NSAnnouncementComponentInterface}
          />
        );
      case ComponentTypeEnum.BANNER_HERO_COMBINE:
        return <NsCarousel {...this.props} />;
      case ComponentTypeEnum.MESSAGING:
        return (
          <NsMessagingComponent
            {...this.props}
            component={component as NSMessagingComponentInterface}
          />
        );
      case ComponentTypeEnum.MARKETING:
        return (
          <NsMarketingComponent
            {...this.props}
            component={component as NSMarketingComponentInterface}
          />
        );
      case ComponentTypeEnum.MOVIE_MARQUEE:
        // TODO: Details component
        return <NsMovieMarqueeComponent {...this.props} />;
      case ComponentTypeEnum.SYNOPSIS:
        return (
          <NsMovieSynopsisComponent
            {...this.props}
            component={component as NSMovieSynopsisComponentInterface}
          />
        );
      case ComponentTypeEnum.CREDIT:
        return (
          <NsMovieCreditComponent
            {...this.props}
            component={component as NSMovieCreditComponentInterface}
          />
        );
      case ComponentTypeEnum.ADDITIONAL_INFO:
        return (
          <NsMovieAdditionalInfoComponent
            {...this.props}
            component={component as NSMovieAdditionalInfoComponentInterface}
          />
        );
      case ComponentTypeEnum.GALLERY:
        return this.resolveQueryComponent();
      case ComponentTypeEnum.ENTITLEMENTS:
        // TODO: Wrapped Component return FC<any>
        return <NsEntitlementsComponent {...this.props} />;
      case ComponentTypeEnum.PLAYER:
        return <NsPlayerComponent />;
      case ComponentTypeEnum.BRAND_GRID:
        return (
          <NSBrandGridComponent
            {...this.props}
            component={component as NSBrandGridComponentInterface}
          />
        );
      case ComponentTypeEnum.BANNER:
        return (
          <NsBannerComponent
            {...this.props}
            component={component as NSBannerComponentInterface}
          />
        );
      case ComponentTypeEnum.SERIES:
        return (
          <NsSeriesComponent
            {...this.props}
            component={component as NSSeriesComponentInterface}
          />
        );
      case ComponentTypeEnum.SHORT_BANNER:
        return (
          <ShortBannerComponent
            {...this.props}
            component={component as NSBannerComponentInterface}
          />
        );
      default:
        return null;
    }
  }

  private resolveQueryComponent() {
    const component = this.props.component as NSGalleryComponentInterface;
    switch (component.displayType) {
      case DisplayTypeEnum.SLIDER:
        return (
          <NsEntitlementsComponent
            {...this.props}
            isSlider={true}
          />
        );
      case DisplayTypeEnum.GRID:
        return <NsImageGridComponent {...this.props} />;
      default:
        return <NsImageGridComponent {...this.props} />;
    }
  }
}

export default NsComponent;
