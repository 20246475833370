/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import Slider from 'react-slick';

interface PropsInterface {
  children: React.ReactNode;
  settings?: any;
}

const SimpleSlider: React.FC<PropsInterface> = ({ children, settings }) => {
  return <Slider {...settings}>{children}</Slider>;
};

export default SimpleSlider;
