/** @jsx jsx */
import { jsx } from '@emotion/core';
import type { ReactNode } from 'react';
import React from 'react';
import {
  bodyText,
  buttonTextCss,
  defaultCss,
  subHeadingCss,
  headingCss,
} from './styles';
import { TypographyStyleTypeEnum } from 'models/enums';
import clsx from 'clsx';
interface TypographyComponentInterface {
  additionalCss?: any;
  children: ReactNode;
  className?: string;
  isFullWidth?: boolean;
  style?: TypographyStyleTypeEnum;
}

const classnamesBuilder = ({ isFullWidth }) => {
  const classNamesList = [];

  if (isFullWidth) {
    /* @ts-ignore TODO: TS2345: Argument of type string is not assignable to parameter of type never. */
    classNamesList.push('full-width');
  } else {
    return;
  }

  return classNamesList.join(', ');
};

const Typography: React.FC<TypographyComponentInterface> = (props) => {
  const renderStyle = (props) => {
    const {
      style = TypographyStyleTypeEnum.DEFAULT,
      additionalCss,
      className,
      children,
    } = props;
    switch (style) {
      case TypographyStyleTypeEnum.SUBHEADING:
        return (
          <p
            className={clsx([
              'comp-typography typography-subheading',
              classnamesBuilder(props),
              className,
            ])}
            css={[subHeadingCss, additionalCss]}
          >
            {children}
          </p>
        );
      case TypographyStyleTypeEnum.HEADING:
        return (
          <p
            className={clsx([
              'comp-typography typography-heading',
              classnamesBuilder(props),
              className,
            ])}
            css={[headingCss, additionalCss]}
          >
            {children}
          </p>
        );
      case TypographyStyleTypeEnum.BUTTON_TEXT:
        return (
          <p
            className={clsx([
              'comp-typography typography-button-text',
              classnamesBuilder(props),
              className,
            ])}
            css={[buttonTextCss, additionalCss]}
          >
            {children}
          </p>
        );
      case TypographyStyleTypeEnum.BODY:
        return (
          <p
            className={clsx([
              'comp-typography typography-body-text',
              classnamesBuilder(props),
              className,
            ])}
            css={[bodyText, additionalCss]}
          >
            {children}
          </p>
        );
      default:
        return (
          <p
            className={clsx([
              'comp-typography typography-default',
              classnamesBuilder(props),
              className,
            ])}
            css={[defaultCss, additionalCss]}
          >
            {children}
          </p>
        );
    }
  };

  return renderStyle(props);
};

export default Typography;
