/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import type {
  NSActionInterface,
  NSMovieMarqueeComponentInterface,
} from 'models/cms';
import { EntitlementTypeEnum } from 'models/enums';
import React, { Fragment, ReactNode, useState } from 'react';
import clsx from 'clsx';

import { useHistory } from 'react-router-dom';
import Button from 'components/button';
import playIcon from 'public/images/Play.svg';
import theme from 'styles/theme';
import { ProgressBarEnum } from 'models/enums';
import { useComponentTypeFinder } from 'utils/hooks';
import { ComponentTypeEnum } from 'models/enums';
import restartIcon from 'public/images/restart.svg';
import restartHoverIcon from 'public/images/restart-hover.svg';
import { mediaQuery } from 'utils/styles';
import {
  TABLET,
  TABLET_SMALL,
} from 'components/details-page-component/details-banner/helpers';

interface EntitlementInterface {
  docType: string;
  endAt: number;
  playsRemaining: number;
  tags?: string[];
}

interface BookmarkInterface {
  progress: number;
}
interface PropsInterface {
  buttonContentCss: any;
  buttonCss: any;
  buttonIconCss: any;
  buttonStyles: any;
  buttonTextCss: any;
  componentType?: string;
  containerClassName?: string;
  containerStyles: any;
  icon?: boolean;
  isLinkStyle: boolean;
  linkStyles: any;
  meta: {
    bookmark?: BookmarkInterface;
    entitlement?: EntitlementInterface;
  };
  onClick: any;
}

const secondaryButtonCss = css`
  background: transparent;
  color: ${theme.palette.primary.light};
  border: 2px solid ${theme.palette.primary.light};
  margin-left: 0.75rem;
`;

const fullWidthCss = css`
  width: 100%;
`;

const ActionButton = ({
  onClick,
  buttonCss,
  buttonContentCss,
  buttonIconCss,
  buttonTextCss,
  displayValue,
  hideIcon = false,
}) => (
  <Button
    onClick={onClick}
    theme="primary"
    css={buttonCss}
    className="action-btn"
  >
    <div css={buttonContentCss}>
      {hideIcon || (
        <img
          src={playIcon}
          css={buttonIconCss}
        />
      )}
      <p css={[buttonTextCss, hideIcon && fullWidthCss]}>{displayValue}</p>
    </div>
  </Button>
);

const LinkAction = ({ target, linkStyles, history, meta, displayValue }) => (
  <a
    href={target}
    css={linkStyles}
    onClick={(e) => {
      e.preventDefault();
      history.push(target, {
        ...history.location,
        ...meta.entitlement,
      });
    }}
  >
    {displayValue}
  </a>
);

const NsAction: React.FC<NSActionInterface & PropsInterface> = (props) => {
  const {
    target,
    type,
    displayValue,
    onClick,
    containerStyles,
    linkStyles,
    containerClassName,
    buttonCss,
    buttonIconCss,
    buttonTextCss,
    buttonContentCss,
    meta,
    isLinkStyle = false,
    componentType,
    icon = false,
  } = props;

  const [restartOnHover, setRestartOnHover] = useState(false);

  const history = useHistory();

  const component = useComponentTypeFinder<NSMovieMarqueeComponentInterface>(
    ComponentTypeEnum.MOVIE_MARQUEE,
  );

  const tags = component?.itemEntitlement?.tags;

  const hasProxiesTag = tags?.some(
    (t) => t.toLowerCase() === 'proxis' || t.toLowerCase() === 'proxis-hulu',
  );

  const hasProxiesTagInMeta = meta?.entitlement?.tags?.some(
    (t) => t.toLowerCase() === 'proxis' || t.toLowerCase() === 'proxis-hulu',
  );

  const hasReachedMaxProgress = (progress: number) => {
    if (progress > 0 && progress < ProgressBarEnum.MAX_PROGRESS) {
      return false;
    }

    return true;
  };

  const renderStartOverButton = (icon: boolean): React.ReactNode => {
    if (icon) {
      return (
        <span
          css={css`
            width: ${40 / 16}rem;
            height: ${40 / 16}rem;
          `}
          data-testid="start-over-button"
          onClick={onClick.restart}
        >
          <img
            css={css`
              cursor: pointer;
              width: ${40 / 16}rem;
              height: ${40 / 16}rem;
            `}
            onMouseOver={() => {
              setRestartOnHover(true);
            }}
            onMouseOut={() => {
              setRestartOnHover(false);
            }}
            src={restartOnHover ? restartHoverIcon : restartIcon}
          />
        </span>
      );
    }
    return (
      <Button
        data-testid="start-over-button"
        onClick={onClick.restart}
        theme="secondary"
        css={[
          buttonCss,
          secondaryButtonCss,
          css`
            flex-shrink: 0;
            margin-left: 0;
          `,
        ]}
        className={
          componentType?.toLowerCase() === EntitlementTypeEnum.SERIES ?
            'play-series-button'
          : ''
        }
      >
        <p css={buttonTextCss}>Start Over</p>
      </Button>
    );
  };

  const renderAction = () => {
    switch (type) {
      case 'PAGE':
        if (isLinkStyle) {
          return (
            <Fragment>
              <LinkAction
                displayValue={displayValue}
                history={history}
                linkStyles={linkStyles}
                meta={meta}
                target={target}
              />
              {hasProxiesTagInMeta && (
                <div
                  css={css`
                    display: flex;
                    align-items: center;
                    margin-top: 1rem;
                    font-size: ${12 / 16}rem;
                  `}
                >
                  For the highest quality of this content, please watch on the
                  Debut app available on Apple tvOS
                </div>
              )}
            </Fragment>
          );
        } else {
          return (
            <ActionButton
              buttonContentCss={buttonContentCss}
              buttonCss={buttonCss}
              buttonIconCss={buttonIconCss}
              buttonTextCss={buttonIconCss}
              displayValue={displayValue}
              /* @ts-ignore TODO: TS2769: No overload matches this call. */
              onClick={() => history.push(target)}
              hideIcon
            />
          );
        }
      case 'PLAY':
        const isMaxProgressReached = hasReachedMaxProgress(
          /* @ts-ignore TODO: TS2345: Argument of type number | undefined is not assignable to parameter of type number. */
          meta.bookmark?.progress,
        );
        return (
          <React.Fragment>
            <div className="buttonsWrapper">
              <Button
                data-testid="resume-button"
                onClick={onClick.play}
                theme="primary"
                css={[
                  buttonCss,
                  css`
                    flex-shrink: 0;
                    ${isMaxProgressReached && 'margin: 0 auto;'}
                  `,
                ]}
                className={
                  componentType?.toLowerCase() === EntitlementTypeEnum.SERIES ?
                    'play-series-button'
                  : ''
                }
              >
                <div css={buttonContentCss}>
                  <p css={buttonTextCss}>
                    {!isMaxProgressReached ? 'Resume' : displayValue}
                  </p>
                </div>
              </Button>
              {!isMaxProgressReached && renderStartOverButton(icon)}
            </div>
            {hasProxiesTag && (
              <div
                className="proxis-message"
                css={css`
                  display: none;
                  align-items: center;
                  font-size: ${10 / 16}rem;
                  text-align: center;

                  ${mediaQuery(TABLET_SMALL)} {
                    display: flex;
                  }

                  ${mediaQuery(TABLET)} {
                    font-size: ${14 / 16}rem;
                  }
                `}
              >
                For the highest quality of this content, please watch on the
                Debut app available on Apple tvOS
              </div>
            )}
          </React.Fragment>
        );
      default:
        return <p>Unknown action</p>;
    }
  };

  return (
    <div
      className={clsx('action-button-container col-sm-12', containerClassName)}
      css={containerStyles}
    >
      {renderAction()}
    </div>
  );
};

export default NsAction;
