import type { NSMovieCreditComponentInterface } from 'models/cms';
import { CreditTypeEnum } from 'models/enums';
import type { FunctionComponent } from 'react';
import React from 'react';
import clsx from 'clsx';
import { createUseStyles } from 'react-jss';

import { mediaQuery } from 'utils/styles';

const useStyles = createUseStyles<any>((theme) => ({
  root: {
    margin: `${theme.spacing.unit * 2} ${theme.spacing.unit * 5}`,
    display: 'flex',
  },
  cast: {
    [mediaQuery(theme.breakpoints.xs)]: {
      width: '100%',
    },
    [mediaQuery(theme.breakpoints.sm)]: {
      width: '50%',
    },
    padding: theme.spacing.unit * 3,
  },
  director: {
    [mediaQuery(theme.breakpoints.xs)]: {
      width: '100%',
    },
    [mediaQuery(theme.breakpoints.sm)]: {
      width: '25%',
    },
    padding: theme.spacing.unit * 3,
  },
  producer: {
    [mediaQuery(theme.breakpoints.xs)]: {
      width: '100%',
    },
    [mediaQuery(theme.breakpoints.sm)]: {
      width: '25%',
    },
    padding: theme.spacing.unit * 3,
  },
  label: {
    ...theme.typography.subheading,
  },
  text: {
    ...theme.typography.defaultText,
  },
}));

interface PropsInterface {
  className?: string;
  component: NSMovieCreditComponentInterface;
}

const NsMovieCreditComponent: FunctionComponent<PropsInterface> = (
  props: PropsInterface,
) => {
  const className = props.className;
  const classes = useStyles();
  const types = [
    { type: CreditTypeEnum.CAST, sectionClass: classes.cast },
    { type: CreditTypeEnum.DIRECTOR, sectionClass: classes.director },
    { type: CreditTypeEnum.PRODUCER, sectionClass: classes.producer },
  ];

  const renderSection = ({ type, sectionClass }) => {
    const credits = props.component.credits?.find((c) => c.type === type);

    return credits ?
        <div
          key={credits.type}
          className={sectionClass}
        >
          <h3 className={classes.label}>{credits.displayValue}</h3>
          <p className={classes.text}>{credits.names.join(', ')}</p>
        </div>
      : null;
  };

  return (
    <div className={clsx(className, classes.root)}>
      {types.map(renderSection)}
    </div>
  );
};

export default NsMovieCreditComponent;
