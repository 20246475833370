/**
 * BrowserCompatibility.tsx
 *
 * This container displays a dismissable modal to notify the user that
 * their browser is not compatible.
 */
import React, { useState, useEffect } from 'react';
import { useStringsContext, STRING_KEYS } from 'providers/strings-provider';
import Alert from 'components/alert';
import Bowser from 'bowser';
import { isBrowserCompatible } from './utils';

interface PropsInterface {
  children: React.ReactNode;
}

export const BROWSER_COMPATIBILITY = {
  Chrome: { name: 'Chrome', id: 'Chrome', minVersion: 81 },
  Safari: { name: 'Safari', id: 'Version', minVersion: 13 },
  Firefox: { name: 'Firefox', id: 'Firefox', minVersion: 75 },
  Edge: { name: 'Microsoft Edge', id: 'Edg', minVersion: 85 },
};

const BrowserCompatibility: React.FC<PropsInterface> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { getStringByKey } = useStringsContext();
  const { browser } = Bowser.parse(window.navigator.userAgent);

  const infoText =
    browser.name === BROWSER_COMPATIBILITY.Firefox.name ?
      getStringByKey(STRING_KEYS.BROWSER_UNSUPPORTED)
    : getStringByKey(STRING_KEYS.GEN_BROWSER_VERSION_UNSUPPORTED);

  const title =
    browser.name === BROWSER_COMPATIBILITY.Firefox.name ?
      'Browser not supported'
    : 'Browser version not supported';

  useEffect(() => {
    if (
      !isBrowserCompatible() &&
      !localStorage.getItem('compatibility_alert_dismissal')
    ) {
      setIsOpen(true);
    }
  }, []);

  const handleClose = () => {
    localStorage.setItem(
      'compatibility_alert_dismissal',
      new Date().toISOString(),
    );
    setIsOpen(false);
  };

  return (
    <>
      {/* Only render if browser isn't compatible and user has not dismissed the alert */}
      {isOpen && (
        <Alert
          isOpen={isOpen}
          onClick={handleClose}
          primaryButtonText="Dismiss"
          infoText={infoText}
          title={title}
          data-testid="compatibility-alert"
        />
      )}
      {children}
    </>
  );
};

export default BrowserCompatibility;
