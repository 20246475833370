import { Loader } from 'components';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  loginWithSSO,
  logout,
  userErrorSelector,
  userSSOLoadingSelector,
} from 'store/slices/user';
import { useQuery } from 'utils/hooks';
import { STRING_KEYS, useStringsContext } from './strings-provider';

export const SSOContext = React.createContext(null);

export const useSSOContext = () => React.useContext(SSOContext);

export const removeQueryParam = (search, paramToRemove) => {
  const queryParams = search.replace(/^\?/, '').split('&');
  const filteredQueryParams = queryParams.filter((param) => {
    const [key] = param.split('=');
    return key !== paramToRemove;
  });
  return filteredQueryParams.length > 0 ?
      '?' + filteredQueryParams.join('&')
    : '';
};

export const SSOProvider: React.FC = ({ children }) => {
  const atasToken = useQuery().get('atas_token');
  const { getStringByKey } = useStringsContext();
  const error = useSelector(userErrorSelector);
  const isSSOLoading = useSelector(userSSOLoadingSelector);
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = history.location;

  const historyReplaced = useRef(false);

  const locationState = useMemo(() => {
    const state: any = history.location.state;

    if (state && state.from?.search) {
      return {
        ...state,
        search: removeQueryParam(state.from.search, 'atas_token'),
      };
    }

    return state;
  }, [history.location.state]);

  const handleSSOLogin = useCallback(
    (token) => {
      dispatch(logout());
      dispatch(loginWithSSO(token));
    },
    [dispatch],
  );

  useEffect(() => {
    if (atasToken && !error) {
      handleSSOLogin(atasToken);
    }
  }, []);

  useEffect(() => {
    if (isSSOLoading) {
      if (!locationState) {
        return;
      }

      // prevent duplicate history.replace been called
      if (historyReplaced?.current === true) {
        return;
      }

      // remove token from url after success
      const params = new URLSearchParams(locationState.from.search);
      params.delete('atas_token');
      const newLocationState = {
        ...locationState,
        from: {
          ...locationState.from,
          search: params.toString(),
        },
      };

      history.replace({
        pathname,
        state: newLocationState,
      });

      historyReplaced.current = true;
    }
  }, [isSSOLoading, history, locationState, pathname]);

  return (
    <SSOContext.Provider value={null}>
      {isSSOLoading && (
        <Loader text={getStringByKey(STRING_KEYS.SSO_SIGNING_IN_TV_ACADEMY)} />
      )}
      {children}
    </SSOContext.Provider>
  );
};
