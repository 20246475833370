/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import React from 'react';
import { itemWrapperCss, imageWrapperCss, textWrapperCss } from './styles';
import { useFycThemeContext } from 'providers/fyc-theme-provider';
import NsImage from 'components/ns-component/ns-image';

interface PropsInterface {
  date: string;
  description: string;
  fileUrl: string;
  imgUrl: string;
  ratio: string;
  title: string;
}

const Item: React.FC<PropsInterface> = ({
  imgUrl,
  date,
  title,
  description,
  fileUrl,
  ratio,
}) => {
  const version = useFycThemeContext()?.version;

  return (
    <div css={itemWrapperCss}>
      <a
        href={fileUrl}
        download
      >
        <div css={imageWrapperCss()}>
          <NsImage
            url={imgUrl}
            width={800}
            aspectRatio={ratio || '16/9'}
            styles={{
              nsImageCss: css`
                border-radius: ${8 / 16}rem;
              `,
            }}
          />
        </div>
      </a>

      <div css={textWrapperCss(version)}>
        <div className="date">{date}</div>
        <div className="title">{title}</div>
        <div className="description">{description}</div>
      </div>
    </div>
  );
};

export default Item;
