import { ComponentTypeEnum } from 'models/enums';
import type { FycVersionType } from 'providers/fyc-theme-provider';
import { matchPath } from 'react-router-dom';
import type { HelmetDataInterface } from './fyc-helmet';

export const FYC_MOBILE_WIDTH = 600;
export const FYC_TABLET_WIDTH = 1025;
export const FYC_SMALL_MOBILE_WIDTH = 376;
export const FYC_DESKTOP_WIDTH = 1441;

export const FYCPage = (pathname: string) =>
  matchPath(pathname, { path: '/fyc', exact: false, strict: false });

export const DetailsPage = (pathname: string) =>
  matchPath(pathname, {
    path: '/fyc/:version/:type/:target',
    exact: true,
    strict: false,
  });

export const LandingPage = (pathname: string) =>
  matchPath(pathname, {
    path: '/fyc/:version/:type',
    exact: true,
    strict: false,
  });

export const MainPage: any = (pathname: string) =>
  matchPath(pathname, {
    path: '/fyc/:version',
    exact: true,
    strict: false,
  });

export const ContactUsPage: any = (pathname: string) =>
  matchPath(pathname, {
    path: '/fyc/:version/contact-us',
    exact: true,
    strict: false,
  });

export const saveCurrentFycVersion = (
  pathname: string,
  versions: string[],
): boolean => {
  const splittedPath = pathname.split('/');
  const version = splittedPath[2];
  if (versions.indexOf(version) !== -1) {
    sessionStorage.setItem('fycVersion', version);
    return true;
  }

  return false;
};

export const getCurrentFycVersion = () => {
  const version: FycVersionType | null = sessionStorage.getItem('fycVersion');

  return version;
};

export const isPlainView = (components: any): boolean => {
  const details = components?.find(
    (item: any) => item.type === ComponentTypeEnum.ENTITLEMENT_DETAILS,
  );

  return details?.hasPlain;
};

export const getPageMetaData = (data: any): HelmetDataInterface => {
  const metaData =
    data?.components?.filter((c) => c.type === ComponentTypeEnum.HTML_META) ||
    [];

  let titleText = '';
  const metaTags: { content: any; name: string }[] = [];

  for (const item of metaData) {
    if (item.element === 'title') {
      titleText = item.titleText;
    }

    if (item.element === 'meta') {
      metaTags.push({ name: item.name, content: item.content });
    }
  }

  return {
    titleText,
    metaTags,
  };
};
