/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import type { FormikHelpers } from 'formik/dist/types';
import LocalStorageKeysEnum from 'models/enums/local-storage-keys-enum';
import React, { useCallback, useEffect, useState } from 'react';
import { Field, Formik, Form } from 'formik';
import { Link, useHistory } from 'react-router-dom';
import { Input, Button, Loader } from 'components';
import { LayoutContainer } from 'containers';
import {
  removeLocalStorage,
  retrieveAndRemoveLocalStorageValue,
  setLocalStorage,
} from 'utils';
import {
  headerTextCss,
  actionButtonCss,
  actionButtonContainerCss,
  successInfoContainerCss,
  loginInfoCss,
} from './styles';
import { activateApi } from 'api';
import { tokenSelector } from 'store/slices/user';
import { useSelector } from 'react-redux';
import { STRING_KEYS, useStringsContext } from 'providers/strings-provider';
import { LOGIN_LANDING } from 'router/constants';
import type { LoadStateInterface } from 'hocs/with-nda-service';
import { HttpStatusCodeEnum } from 'models/enums';

const inputCss = css`
  text-align: center;
  padding: 0 0 0 2rem;
  letter-spacing: 2rem;
  text-transform: uppercase;
`;

interface ErrorStateInterface {
  code?: string | null;
}

interface FormValuesInterface {
  code: string;
}

const LoginActivate: React.FC = () => {
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const { accessToken, refreshToken } = useSelector(tokenSelector);
  const { getStringByKey } = useStringsContext();
  const history = useHistory();

  const storedActivationCode = retrieveAndRemoveLocalStorageValue(
    LocalStorageKeysEnum.ACTIVATION_CODE,
  );

  const licensePlate =
    typeof storedActivationCode === 'string' ? storedActivationCode : undefined;

  const initialLoadState: LoadStateInterface = {
    label: 'Loading',
    isLoading: false,
  };

  const [loadState, setLoadState] =
    useState<LoadStateInterface>(initialLoadState);

  const [errorState, setErrorState] = useState<ErrorStateInterface>({
    code: null,
  });

  const ERROR_MSG = getStringByKey(STRING_KEYS.AUTH_PLATE_ACTIVATION_ERROR);
  const SIGN_IN_MSG = 'Signing into your device';

  interface ActivateDeviceInterface {
    code: string;
    errorReporter:
      | FormikHelpers<FormValuesInterface>['setFieldError']
      | typeof setErrorState;
    isOnLoad: boolean;
  }

  const activateDevice = useCallback(
    async ({ code, errorReporter, isOnLoad }: ActivateDeviceInterface) => {
      setLocalStorage(LocalStorageKeysEnum.ACTIVATION_CODE, code);

      // User needs to login
      if (!accessToken && !refreshToken) {
        history.push(LOGIN_LANDING, { activate: true });
        return;
      }

      try {
        if (isOnLoad) {
          setLoadState({
            label: SIGN_IN_MSG,
            isLoading: true,
          });
        }

        await activateApi(
          code,
          accessToken ?? undefined,
          refreshToken ?? undefined,
        );

        if (isOnLoad) {
          setLoadState({
            isLoading: false,
          });
        }

        setIsSuccess(true);
        removeLocalStorage(LocalStorageKeysEnum.ACTIVATION_CODE);
      } catch (error) {
        if (isOnLoad) {
          setLoadState({
            isLoading: false,
          });
        }

        if (error?.response?.statusCode === HttpStatusCodeEnum.UNAUTHORIZED) {
          history.push(LOGIN_LANDING, { activate: true });
          return;
        }

        errorReporter('code', ERROR_MSG);
        removeLocalStorage(LocalStorageKeysEnum.ACTIVATION_CODE);
      }
    },
    [ERROR_MSG, accessToken, history, refreshToken],
  );

  const onActivate = async (
    values: FormValuesInterface,
    { setFieldError }: FormikHelpers<FormValuesInterface>,
  ) =>
    await activateDevice({
      code: values.code,
      isOnLoad: false,
      errorReporter: setFieldError,
    });

  useEffect(() => {
    if (!licensePlate) {
      return;
    }

    activateDevice({
      code: licensePlate,
      isOnLoad: true,
      errorReporter: setErrorState,
    });
  }, [activateDevice, licensePlate]);

  const form = (
    <Formik
      initialValues={{
        code: licensePlate || '',
      }}
      onSubmit={onActivate}
    >
      {({ isSubmitting, errors, values, isValid }) => (
        <Form>
          {isSubmitting && <Loader text={SIGN_IN_MSG} />}
          <h2 css={headerTextCss}>Log into your TV</h2>

          <Field name="code">
            {({ field }) => (
              <Input
                id="activate-code"
                type="text"
                placeholder="Enter your code"
                disabled={isSubmitting}
                label={getStringByKey(STRING_KEYS.AUTH_PLATE_ACTIVATION)}
                data-testid="activate-code-input"
                error={errors.code || errorState.code}
                maxLength={6}
                css={!!values.code && inputCss}
                size={6}
                autoFocus
                {...field}
              />
            )}
          </Field>

          <div css={actionButtonContainerCss}>
            <Button
              type="submit"
              theme="primary"
              data-testid="activate-code-submit"
              disabled={!values.code || !isValid}
              css={actionButtonCss}
            >
              {accessToken || refreshToken ? 'Next' : 'Log-in to continue'}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );

  const successInfo = (
    <div
      css={successInfoContainerCss}
      className="col-sm-12"
    >
      <h2
        css={headerTextCss}
        data-testid="activate-success-title"
      >
        You successfully signed into your device
      </h2>

      <p css={loginInfoCss}>
        {getStringByKey(STRING_KEYS.AUTH_PLATE_ACTIVATION_SUCCESS)}
      </p>

      <Link
        to="/"
        data-testid="activate-success-link"
      >
        <Button theme="primary">Go to Browse</Button>
      </Link>
    </div>
  );

  return (
    <React.Fragment>
      {loadState.isLoading && <Loader text={loadState.label} />}

      <LayoutContainer auth>{isSuccess ? successInfo : form}</LayoutContainer>
    </React.Fragment>
  );
};

export default LoginActivate;
