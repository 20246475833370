/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import type { NSBrandGridComponentInterface } from 'models/cms';
import React from 'react';
import { brandFilterRootCss } from './styles';
import ComponentContainer from 'components/component-container';
import type { BrandItemPropsInterface } from './brand-item';
import BrandItem from './brand-item';
import type { ClassValue } from 'clsx';
import clsx from 'clsx';
import { mediaQuery } from 'utils/styles';
import theme from 'styles/theme';
import { CONFIG_KEYS, useStringsContext } from 'providers/strings-provider';

interface NsBrandGridComponentPropsInterface {
  component: NSBrandGridComponentInterface;
}

const renderBrandItem = (
  brand: BrandItemPropsInterface,
  i: number,
  total: number,
  isGridView: boolean,
): React.ReactNode => {
  const addClassNames = (): ClassValue => {
    // start our count from 1 to make counting divisible by 5 in the logic below
    return (i = i + 1);
  };

  return (
    <React.Fragment key={`brandkey-${brand.id}`}>
      <BrandItem
        {...brand}
        className={clsx(addClassNames())}
        isGridView={isGridView}
      />
    </React.Fragment>
  );
};

const NsBrandGridComponent: React.FC<NsBrandGridComponentPropsInterface> = ({
  component,
}): React.ReactElement => {
  const { getConfigByKey } = useStringsContext();

  return (
    <ComponentContainer
      className={clsx(['brand-filter', `layout-style--grid`])}
      rootCss={brandFilterRootCss(
        component.items.length,
        /* @ts-ignore TODO: TS2345: Argument of type string | undefined is not assignable to parameter of type string. */
        getConfigByKey(CONFIG_KEYS.CARD_GRADIENT_COLOR_WEB),
        getConfigByKey(CONFIG_KEYS.CARD_BACKGROUND_COLOR),
      )}
    >
      <div
        className={'row'}
        css={css`
          margin-top: 16px !important;
          ${mediaQuery(theme.breakpoints.sm)} {
            margin-top: 24px !important;
          }
          ${mediaQuery(theme.breakpoints.lg)} {
            margin-top: 32px !important;
          }
        `}
      >
        {component.items?.length > 0 &&
          component.items.map((brand, i) =>
            renderBrandItem(
              brand,
              i,
              component.items.length,
              // view === EntitlementViewTypeEnum.GRID
              true,
            ),
          )}
      </div>
    </ComponentContainer>
  );
};

export default NsBrandGridComponent;
