import TrackThumb from 'public/images/TrackThumb.svg';
import type { SerializedStyles } from '@emotion/core';
import { css } from '@emotion/core';
import { FYC_MOBILE_WIDTH } from 'pages/fyc-page/helpers';
import { mediaQuery } from 'utils/styles';
import type { FycVersionType } from 'providers/fyc-theme-provider';
import { SEARCHLIGHT, SEARCHLIGHT_AWARDS } from 'providers/fyc-theme-provider';

export const audioStyle = css`
  width: 100%;
  display: flex;
  align-items: center;

  .range-input {
    border: none;
    width: 100%;
    height: ${2 / 16}rem;
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
    padding: 0;
    border-radius: ${13 / 16}rem;
  }

  .range-input::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: ${7.1 / 16}rem;
    height: ${8 / 16}rem;
    background-size: cover;
    background-image: url(${TrackThumb});
    cursor: pointer;
  }
`;

export const controlCss = (
  currentTime: number,
  duration: number,
  isPlaying: boolean,
  version: FycVersionType,
): SerializedStyles => {
  let percent = 0;
  if (duration !== 0) {
    percent = currentTime / duration;
  }
  let color = `rgba(255, 255, 255, 0.65)`;
  let playingColor = `color: #fff;`;

  if (version === SEARCHLIGHT || version === SEARCHLIGHT_AWARDS) {
    color = `rgba(38, 38, 38, 0.65)`;
    playingColor = `color: black;`;
  }

  return css`
    display: flex;
    width: 100%;

    .duration {
      display: flex;
      align-items: center;
      font-size: ${12 / 16}rem;
      line-height: ${14 / 16}rem;

      color: ${color};

      ${isPlaying && playingColor}

      ${mediaQuery(FYC_MOBILE_WIDTH)} {
        font-size: ${14 / 16}rem;
        line-height: 1rem;
      }
    }

    .progress-bar {
      position: relative;
      display: flex;
      align-items: center;
      margin: 0 ${8 / 16}rem;
      flex: 1;
    }

    input {
      top: 0;
      left: 0;
      right: 0;
    }

    .slider-value {
      position: absolute;
      width: ${percent * 100}%;
      background: #c19a41;
      height: 2px;
      z-index: 10;
      border-radius: 13px;
    }
  `;
};

export const ctaButtonCss = css`
  display: flex;
  align-items: center;
  width: ${18 / 16}rem;
  height: ${18 / 16}rem;
  margin-right: ${8 / 16}rem;
  img {
    width: 100%;
  }
  cursor: pointer;

  ${mediaQuery(FYC_MOBILE_WIDTH)} {
    margin-right: ${16 / 16}rem;
    width: ${22 / 16}rem;
    height: ${22 / 16}rem;
  }
`;
