import { SubtitleOverlay } from 'bitmovin-player-ui/dist/js/framework/components/subtitleoverlay';
import { SettingsPanelPage } from 'bitmovin-player-ui/dist/js/framework/components/settingspanelpage';
import { SettingsPanelItem } from 'bitmovin-player-ui/dist/js/framework/components/settingspanelitem';
import { AudioTrackSelectBox } from 'bitmovin-player-ui/dist/js/framework/components/audiotrackselectbox';
import { SettingsPanel } from 'bitmovin-player-ui/dist/js/framework/components/settingspanel';
import { SubtitleSettingsPanelPage } from 'bitmovin-player-ui/dist/js/framework/components/subtitlesettings/subtitlesettingspanelpage';
import { SettingsPanelPageOpenButton } from 'bitmovin-player-ui/dist/js/framework/components/settingspanelpageopenbutton';
import { SubtitleSettingsLabel } from 'bitmovin-player-ui/dist/js/framework/components/subtitlesettings/subtitlesettingslabel';
import { SubtitleSelectBox } from 'bitmovin-player-ui/dist/js/framework/components/subtitleselectbox';
import { ControlBar } from 'bitmovin-player-ui/dist/js/framework/components/controlbar';
import type { ContainerConfig } from 'bitmovin-player-ui/dist/js/framework/components/container';
import { Container } from 'bitmovin-player-ui/dist/js/framework/components/container';
import {
  PlaybackTimeLabel,
  PlaybackTimeLabelMode,
} from 'bitmovin-player-ui/dist/js/framework/components/playbacktimelabel';
import { SeekBarLabel } from 'bitmovin-player-ui/dist/js/framework/components/seekbarlabel';
import { PlaybackToggleButton } from 'bitmovin-player-ui/dist/js/framework/components/playbacktogglebutton';
import { VolumeToggleButton } from 'bitmovin-player-ui/dist/js/framework/components/volumetogglebutton';
import { VolumeSlider } from 'bitmovin-player-ui/dist/js/framework/components/volumeslider';
import { Spacer } from 'bitmovin-player-ui/dist/js/framework/components/spacer';
import { FullScreenModeButton } from './full-screen-mode-button';
import { UIContainer } from 'bitmovin-player-ui/dist/js/framework/components/uicontainer';
import { BufferingOverlay } from 'bitmovin-player-ui/dist/js/framework/components/bufferingoverlay';
import { PlaybackToggleOverlay } from 'bitmovin-player-ui/dist/js/framework/components/playbacktoggleoverlay';
import { CastStatusOverlay } from 'bitmovin-player-ui/dist/js/framework/components/caststatusoverlay';
import { TitleBar } from 'bitmovin-player-ui/dist/js/framework/components/titlebar';
import { RecommendationOverlay } from 'bitmovin-player-ui/dist/js/framework/components/recommendationoverlay';
import { ErrorMessageOverlay } from 'bitmovin-player-ui/dist/js/framework/components/errormessageoverlay';
import { CloseButton } from 'bitmovin-player-ui/dist/js/framework/components/closebutton';
import {
  MetadataLabel,
  MetadataLabelContent,
} from 'bitmovin-player-ui/dist/js/framework/components/metadatalabel';
import { PlayerUtils } from 'bitmovin-player-ui/dist/js/framework/playerutils';
import type { UIConditionContext } from 'bitmovin-player-ui/dist/js/framework/uimanager';
import { UIManager } from 'bitmovin-player-ui/dist/js/framework/uimanager';
import type { UIConfig } from 'bitmovin-player-ui/dist/js/framework/uiconfig';
import type { PlayerAPI } from 'bitmovin-player';
import { i18n } from 'bitmovin-player-ui/dist/js/framework/localization/i18n';
import CustomSeekBar from './custom-seek-bar';
import { SeekBar } from 'bitmovin-player-ui';
import { CustomPlaybackSpeedSelectBox } from './custom-playback-speed-select-box';
import { SettingsToggleButton } from 'bitmovin-player-ui/dist/js/framework/components/settingstogglebutton';
import { CustomClosePlayerButton } from './custom-close-button';
import { SubtitlesToggleButton } from './subtitles-toggle-button';
import { TheaterModeButton } from './theater-mode-button';
import { Label } from 'bitmovin-player-ui/dist/js/framework/components/label';
import { CustomCastToggleButton } from './custom-chromecast-toggle-button';
import { CustomPlayerToggleButton } from './custom-player-toggle-button';
import { CustomCastOverlay } from './custom-cast-overlay';
import { CustomPoster } from './custom-poster';
import type { NSImageInterface } from 'models/cms';
import type { KeyArtImageInterface } from 'models/cms/cms-component-interface';

/**
 * Helper to build control bar
 */
const buildControlBar = (
  subtitleSettingsPanel: SettingsPanel,
  controlBarContainer: Container<ContainerConfig>,
): ControlBar => {
  const controlBar = new ControlBar({});
  const container = new Container({
    components: [
      new PlaybackTimeLabel({
        timeLabelMode: PlaybackTimeLabelMode.CurrentTime,
        hideInLivePlayback: true,
      }),
      new CustomSeekBar({
        label: new SeekBarLabel(),
      }),
      new PlaybackTimeLabel({
        timeLabelMode: PlaybackTimeLabelMode.TotalTime,
        cssClasses: ['text-right'],
      }),
    ],
    cssClasses: ['controlbar-top'],
  });

  if (subtitleSettingsPanel) {
    controlBar.addComponent(subtitleSettingsPanel);
  }

  controlBar.addComponent(container);

  if (controlBarContainer) {
    controlBar.addComponent(controlBarContainer);
  }

  return controlBar;
};

/**
 * Helper to build out a generic settings panel
 */
const buildSettingsPanel = (
  components: Container<ContainerConfig>[],
): SettingsPanel =>
  new SettingsPanel({
    components: components,
    hidden: true,
    pageTransitionAnimation: false,
    hideDelay: -1,
  });

/**
 * Modifier helper for main settings panel
 */
const modifyMainSettingsPanelPage = (
  mainSettingsPanelPage: SettingsPanelPage,
  componentConfig: ComponentConfigInterface,
): void => {
  if (componentConfig.enablePlaybackSpeedControls) {
    const playbackSpeedControls = new SettingsPanelItem(
      i18n.getLocalizer('speed'),
      new CustomPlaybackSpeedSelectBox(
        {},
        {
          tenTimes: componentConfig.tenTimesSpeedOptions ? true : false,
        },
      ),
    );

    mainSettingsPanelPage.addComponent(playbackSpeedControls);
  }
};

/**
 * Helper
 */
const buildSettingsPanelPage = () =>
  new SettingsPanelPage({
    components: [
      new SettingsPanelItem(
        i18n.getLocalizer('settings.audio.track'),
        new AudioTrackSelectBox({
          cssClass: 'ui-language-select-box',
        }),
      ),
    ],
  });

/**
 * Helper
 */
const buildSubtitleSettingsOpenButton = (
  subtitleSettingsPanelPage: SubtitleSettingsPanelPage,
  subtitleSettingsPanel: SettingsPanel,
) =>
  new SettingsPanelPageOpenButton({
    targetPage: subtitleSettingsPanelPage,
    container: subtitleSettingsPanel,
    ariaLabel: i18n.getLocalizer('settings.subtitles'),
    text: i18n.getLocalizer('open'),
  });

/**
 * Helper
 */
const modifyControlBarContainer = (
  controlBarContainer: Container<ContainerConfig>,
  componentConfig: ComponentConfigInterface,
  subtitleSettingsPanel: SettingsPanel,
) => {
  if (
    subtitleSettingsPanel &&
    (componentConfig.isAudioTracksAvailable ||
      componentConfig.isSubtitlesAvailable)
  ) {
    controlBarContainer.addComponent(
      new SubtitlesToggleButton({ settingsPanel: subtitleSettingsPanel }),
    );
  }
};

/**
 * Helper
 */
const modifyTitleBar = (
  titleBar: TitleBar,
  componentConfig: ComponentConfigInterface,
  closeButton: CustomClosePlayerButton,
) => {
  if (closeButton && !componentConfig.disableCloseButton) {
    titleBar.addComponent(closeButton);
  }

  if (closeButton && componentConfig.disableCloseButton) {
    titleBar.removeComponent(closeButton);
  }
};

/**
 * Helper
 */
const modifyMiniTitleBar = (
  titleBar: TitleBar,
  componentConfig: ComponentConfigInterface,
) => {
  const titleLabel = new Label({
    /* @ts-ignore TODO: TS18048: componentConfig.metadata is possibly undefined. */
    text: componentConfig.metadata.entitlementTitle,
    cssClasses: ['mini-player-title'],
  });

  const castDevice =
    window.cast.framework.CastContext?.getInstance()
      ?.getCurrentSession()
      ?.getCastDevice().friendlyName ?? 'Loading';
  const castLabel = new Label({ cssClass: 'ui-cast-label-deviceName' });

  castLabel.setText(`Casting on ${castDevice}`);

  titleBar.addComponent(titleLabel);
  titleBar.addComponent(castLabel);
};

/**
 * Helper
 */
const customCloseButton = (componentConfig: ComponentConfigInterface) =>
  new CustomClosePlayerButton({
    onClick: componentConfig.closePlayer,
  });

/**
 * Helper
 */
const modifySubtitleSettings = (
  subtitleSettings: SettingsPanelPage,
  subtitleSettingsOpenButton: SettingsPanelPageOpenButton,
  subtitleSelectBox: SubtitleSelectBox,
) => {
  subtitleSettings.addComponent(
    new SettingsPanelItem(
      new SubtitleSettingsLabel({
        text: i18n.getLocalizer('settings.subtitles'),
        opener: subtitleSettingsOpenButton,
      }),
      subtitleSelectBox,
      {
        role: 'menubar',
      },
    ),
  );
};

interface EntitlementMetadataInterface {
  entitlementTitle?: string;
  keyArtImage?: KeyArtImageInterface;
  titleTreatmentImage?: NSImageInterface;
}

export interface ComponentConfigInterface {
  castingDeviceName?: string;
  closePlayer(): void;
  disableCloseButton?: boolean;
  enablePlaybackSpeedControls?: boolean;
  hideTheaterModeButton?: boolean;
  isAudioTracksAvailable?: boolean;
  isSubtitlesAvailable?: boolean;
  loadMiniPlayer(): void;
  metadata?: EntitlementMetadataInterface;
  tenTimesSpeedOptions?: boolean;
}

/**
 * Entry function to build the default ui, it calls upon buildModernUi.
 *
 * @param player player API
 * @param config a UI config
 * @param componentConfig extra component configurations
 * @returns an instance of UI Manager
 */
export function buildDefaultUI(
  player: PlayerAPI,
  config: UIConfig = {},
  componentConfig: ComponentConfigInterface,
): UIManager {
  return buildModernUI(player, config, componentConfig);
}

/**
 * Modern UI used for desktop and tablet devices.
 *
 * @param componentConfig
 * @returns a UI Container
 */
export function modernUI(
  componentConfig: ComponentConfigInterface,
): UIContainer {
  const mainSettingsPanelPage = new SettingsPanelPage({
    components: [],
  });
  modifyMainSettingsPanelPage(mainSettingsPanelPage, componentConfig);
  const settingsPanel = buildSettingsPanel([mainSettingsPanelPage]);

  const subtitleSettings = buildSettingsPanelPage();
  const subtitleSettingsPanel = buildSettingsPanel([subtitleSettings]);
  const subtitleOverlay = new SubtitleOverlay();
  const subtitleSettingsPanelPage = new SubtitleSettingsPanelPage({
    settingsPanel: subtitleSettingsPanel,
    overlay: subtitleOverlay,
  });
  const subtitleSelectBox = new SubtitleSelectBox({
    cssClass: 'ui-language-select-box',
  });
  const subtitleSettingsOpenButton = buildSubtitleSettingsOpenButton(
    subtitleSettingsPanelPage,
    subtitleSettingsPanel,
  );
  modifySubtitleSettings(
    subtitleSettings,
    subtitleSettingsOpenButton,
    subtitleSelectBox,
  );
  subtitleSettingsPanel.addComponent(subtitleSettingsPanelPage);

  const playbackToggleButton = new PlaybackToggleButton();
  const controlBarContainer = new Container({
    components: [
      playbackToggleButton,
      new VolumeToggleButton(),
      new VolumeSlider(),
      new Spacer(),
      new SettingsToggleButton({ settingsPanel: settingsPanel }),
    ],
    cssClasses: ['controlbar-bottom'],
  });

  modifyControlBarContainer(
    controlBarContainer,
    componentConfig,
    subtitleSettingsPanel,
  );

  const { hideTheaterModeButton } = componentConfig;

  const fullScreenModeButton = new FullScreenModeButton({});
  const theaterModeButton = new TheaterModeButton({
    onText: 'Default Player',
    offText: 'Full Window Mode',
    fullScreenModeButton,
  });

  const componentsArr = [
    theaterModeButton,
    fullScreenModeButton,
    new CustomCastToggleButton({
      offText: 'Cast on TV',
      onText: 'Casting on TV',
    }),
  ];

  if (hideTheaterModeButton) {
    componentsArr.shift();
  }

  componentsArr.forEach((component) =>
    controlBarContainer.addComponent(component),
  );

  const controlBar = buildControlBar(
    subtitleSettingsPanel,
    controlBarContainer,
  );

  const titleBar = new TitleBar({
    components: [],
    cssClasses: ['top-text'],
  });
  const closeButton = customCloseButton(componentConfig);
  modifyTitleBar(titleBar, componentConfig, closeButton);

  const playbackToggleOverlay = new PlaybackToggleOverlay();
  const recommendationOverlay = new RecommendationOverlay();

  const uiContainer = new UIContainer({
    components: [
      playbackToggleOverlay,
      subtitleOverlay,
      new BufferingOverlay(),
      new CustomCastOverlay({
        onClick: componentConfig.loadMiniPlayer,
        /* @ts-ignore TODO: TS2322: Type string | undefined is not assignable to type string. */
        /* @ts-ignore TODO: TS18048: componentConfig.metadata is possibly undefined. */
        entitlementTitle: componentConfig.metadata.entitlementTitle,
      }),
      controlBar,
      settingsPanel,
      titleBar,
      recommendationOverlay,
      new ErrorMessageOverlay(),
    ],
    hideDelay: 2000,
    hidePlayerStateExceptions: [
      PlayerUtils.PlayerState.Prepared,
      PlayerUtils.PlayerState.Paused,
      PlayerUtils.PlayerState.Finished,
    ],
  });

  return uiContainer;
}

/**
 * Modern UI used for desktop and tablet devices.
 *
 * @param componentConfig
 * @returns a UI Container
 */
export function miniPlayerUI(
  componentConfig: ComponentConfigInterface,
): UIContainer {
  let miniPoster = null;
  /* @ts-ignore TODO: TS18048: componentConfig.metadata is possibly undefined. */
  if (componentConfig.metadata.titleTreatmentImage) {
    /* @ts-ignore TODO: TS2322: Type CustomPoster is not assignable to type null. */
    miniPoster = new CustomPoster({
      /* @ts-ignore TODO: TS18048: componentConfig.metadata is possibly undefined. */
      imageData: componentConfig.metadata.titleTreatmentImage.url,
      cssClass: 'miniplayer-poster',
    });
  }

  const seekBar = new SeekBar({
    cssClasses: ['controlbar-bottom'],
  });

  const toggleButton = new CustomPlayerToggleButton({
    onClick: componentConfig.loadMiniPlayer,
  });

  const titleBar = new TitleBar();

  modifyMiniTitleBar(titleBar, componentConfig);

  const playbackToggleButton = new PlaybackToggleButton();

  let contentContainer;
  if (miniPoster === null) {
    contentContainer = new Container({
      components: [toggleButton, titleBar, playbackToggleButton],
    });
  } else {
    contentContainer = new Container({
      components: [miniPoster, toggleButton, titleBar, playbackToggleButton],
    });
  }

  const uiContainer = new UIContainer({
    components: [
      contentContainer,
      seekBar,
      new BufferingOverlay(),
      new ErrorMessageOverlay(),
    ],
    hideDelay: 2000,
    hidePlayerStateExceptions: [
      PlayerUtils.PlayerState.Prepared,
      PlayerUtils.PlayerState.Paused,
      PlayerUtils.PlayerState.Finished,
    ],
  });

  return uiContainer;
}

/**
 * Modern UI for small screens
 *
 * @param componentConfig
 * @returns a UI Container
 */
export function modernSmallScreenUI(
  componentConfig: ComponentConfigInterface,
): UIContainer {
  const mainSettingsPanelPage = new SettingsPanelPage({
    components: [],
  });
  modifyMainSettingsPanelPage(mainSettingsPanelPage, componentConfig);
  const settingsPanel = buildSettingsPanel([mainSettingsPanelPage]);

  const subtitleSettings = buildSettingsPanelPage();
  const subtitleSettingsPanel = buildSettingsPanel([subtitleSettings]);
  const subtitleOverlay = new SubtitleOverlay();
  const subtitleSettingsPanelPage = new SubtitleSettingsPanelPage({
    settingsPanel: subtitleSettingsPanel,
    overlay: subtitleOverlay,
  });
  const subtitleSelectBox = new SubtitleSelectBox();
  const subtitleSettingsOpenButton = buildSubtitleSettingsOpenButton(
    subtitleSettingsPanelPage,
    subtitleSettingsPanel,
  );
  modifySubtitleSettings(
    subtitleSettings,
    subtitleSettingsOpenButton,
    subtitleSelectBox,
  );
  subtitleSettingsPanel.addComponent(subtitleSettingsPanelPage);

  settingsPanel.addComponent(new CloseButton({ target: settingsPanel }));

  subtitleSettingsPanel.addComponent(
    new CloseButton({ target: subtitleSettingsPanel }),
  );

  /* @ts-ignore TODO: TS2345: Argument of type null is not assignable to parameter of type SettingsPanel. */
  const controlBar = buildControlBar(null, null);

  const titleBar = new TitleBar({
    components: [
      new CustomCastToggleButton({
        offText: 'Cast on TV',
        onText: 'Casting on TV',
      }),
      new MetadataLabel({ content: MetadataLabelContent.Title }),
      new VolumeToggleButton(),
      new SettingsToggleButton({ settingsPanel: settingsPanel }),
    ],
  });
  modifyTitleBar(titleBar, componentConfig, customCloseButton(componentConfig));

  const playbackToggleOverlay = new PlaybackToggleOverlay();
  const recommendationOverlay = new RecommendationOverlay();

  const uiContainer = new UIContainer({
    components: [
      subtitleOverlay,
      new BufferingOverlay(),
      new CastStatusOverlay(),
      playbackToggleOverlay,
      recommendationOverlay,
      controlBar,
      titleBar,
      settingsPanel,
      subtitleSettingsPanel,
      new ErrorMessageOverlay(),
    ],
    cssClasses: ['ui-skin-smallscreen'],
    hideDelay: 2000,
    hidePlayerStateExceptions: [
      PlayerUtils.PlayerState.Prepared,
      PlayerUtils.PlayerState.Paused,
      PlayerUtils.PlayerState.Finished,
    ],
  });

  return uiContainer;
}

/**
 * Factory to build the modern UI components.
 *
 * @param player player API
 * @param config UI config
 * @param componentConfig Extra component configurations
 * @returns an instance of UI Manager
 */
export function buildModernUI(
  player: PlayerAPI,
  config: UIConfig = {},
  componentConfig: ComponentConfigInterface,
): UIManager {
  // show smallScreen UI only on mobile/handheld devices
  const smallScreenSwitchWidth = 600;
  return new UIManager(
    player,
    [
      {
        ui: modernSmallScreenUI(componentConfig),
        condition: (context: UIConditionContext) => {
          return (
            !context.isAd &&
            !context.adRequiresUi &&
            context.isMobile &&
            context.documentWidth < smallScreenSwitchWidth
          );
        },
      },
      {
        ui: modernUI(componentConfig),
        condition: (context: UIConditionContext) => {
          return !context.isAd && !context.adRequiresUi;
        },
      },
    ],
    config,
  );
}

/**
 * Factory to build the modern UI components.
 *
 * @param player player API
 * @param config UI config
 * @param componentConfig Extra component configurations
 * @returns an instance of UI Manager
 */
export function buildMiniUI(
  player: PlayerAPI,
  config: UIConfig = {},
  componentConfig: ComponentConfigInterface,
): UIManager {
  return new UIManager(
    player,
    [
      {
        ui: miniPlayerUI(componentConfig),
        condition: (context: UIConditionContext) => {
          return !context.isAd && !context.adRequiresUi;
        },
      },
    ],
    config,
  );
}
