/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useState, useRef, useEffect } from 'react';
import { audioStyle, controlCss } from './styles';
import PlayTrackCTA from 'public/images/PlayTrackCTA.svg';
import PauseTrackCTA from 'public/images/PauseTrackCTA.svg';
import { ctaButtonCss } from './styles';
import { useFycThemeContext } from 'providers/fyc-theme-provider';

interface PropsInterface {
  index: number;
  passRef: any;
  setStatus?: (status: boolean) => void;
  src: string;
}

export const AUDIO_ENDED = 'audio_ended';
export const AUDIO_PLAY = 'audio_play';

export const AudioPlayer: React.FC<PropsInterface> = ({
  src,
  setStatus,
  passRef,
  index,
}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const audioRef = useRef<HTMLAudioElement | null>(null);

  const version = useFycThemeContext()?.version;

  function handlePlayPause() {
    const audio = audioRef.current;
    if (!audio) {
      return;
    }

    if (isPlaying) {
      audio.pause();
    } else {
      audio.play();
    }
    setIsPlaying(!isPlaying);
  }

  function handleTimeUpdate() {
    const audio = audioRef.current;
    if (!audio) {
      return;
    }

    setCurrentTime(audio.currentTime);
  }

  function handleLoadedMetadata() {
    const audio = audioRef.current;
    if (!audio) {
      return;
    }

    setDuration(audio.duration);
  }

  function handleScrubberChange(event) {
    const audio = audioRef.current;
    if (!audio) {
      return;
    }

    audio.currentTime = event.target.value;
    setCurrentTime(audio.currentTime);
  }

  function formatDuration(seconds) {
    const date = new Date(seconds * 1000);
    const minutes =
      date.getUTCMinutes() < 10 ?
        '0' + date.getUTCMinutes()
      : date.getUTCMinutes();
    const secondsFormatted = ('0' + date.getUTCSeconds()).slice(-2);

    return `${minutes}:${secondsFormatted}`;
  }

  function handleEnded() {
    window.dispatchEvent(
      new CustomEvent(AUDIO_ENDED, {
        detail: {
          index,
        },
      }),
    );
  }

  function handleOnPlay() {
    setIsPlaying(true);

    window.dispatchEvent(
      new CustomEvent(AUDIO_PLAY, {
        detail: {
          index,
        },
      }),
    );
  }

  useEffect(() => {
    if (!setStatus) {
      return;
    }

    if (isPlaying) {
      setStatus(true);
    } else {
      setStatus(false);
    }
  }, [isPlaying, setStatus]);

  useEffect(() => {
    passRef(index, audioRef.current);
  }, [index, passRef]);

  return (
    <div
      className="audio-player"
      css={audioStyle}
    >
      <audio
        ref={audioRef}
        src={src}
        onPlay={handleOnPlay}
        onPause={() => setIsPlaying(false)}
        onTimeUpdate={handleTimeUpdate}
        onLoadedMetadata={handleLoadedMetadata}
        onEnded={handleEnded}
      />
      <div
        className="controls"
        css={controlCss(currentTime, duration, isPlaying, version)}
      >
        <div
          css={ctaButtonCss}
          onClick={handlePlayPause}
        >
          {isPlaying ?
            <img src={PauseTrackCTA} />
          : <img src={PlayTrackCTA} />}
        </div>
        <div className="duration">{formatDuration(currentTime)}</div>
        <div className="progress-bar">
          <div className="slider-value"></div>
          <input
            type="range"
            min={0}
            max={duration}
            value={currentTime}
            onChange={handleScrubberChange}
            step={0.01}
            className="range-input"
          />
        </div>
        <div className="duration">{formatDuration(duration)}</div>
      </div>
    </div>
  );
};
