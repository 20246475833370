/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import type { NSBannerComponentInterface } from 'models/cms';
import { FycComponentTypeEnum } from 'models/enums';

import React from 'react';
import FycPageLink from 'components/fyc-component/fyc-page-link';
import NsBannerComponent from 'components/ns-component/ns-banner-component';
import { getDataByType } from 'utils/fyc';

interface PropsInterface {
  pageData: any;
}

const Landing: React.FC<PropsInterface> = ({ pageData }) => {
  const pageLinkData = getDataByType(
    FycComponentTypeEnum.PAGE_LINK,
    pageData?.components,
  );

  const bannerData = getDataByType<NSBannerComponentInterface>(
    FycComponentTypeEnum.BANNER,
    pageData?.components,
  );

  return (
    <div>
      {bannerData && (
        <NsBannerComponent
          isFycGrid
          component={bannerData}
        />
      )}
      {pageLinkData && (
        <FycPageLink
          data={pageLinkData}
          styles={css`
            margin-bottom: ${180 / 16}rem;
          `}
        />
      )}
    </div>
  );
};

export default Landing;
