import type { ToggleButtonConfig } from 'bitmovin-player-ui/dist/js/framework/components/togglebutton';
import { ToggleButton } from 'bitmovin-player-ui/dist/js/framework/components/togglebutton';
import type { PlayerAPI } from 'bitmovin-player';
import { ViewMode } from 'bitmovin-player';
import type { UIInstanceManager } from 'bitmovin-player-ui';
import { css } from '@emotion/core';

export const FULLSCREEN_BUTTON_NAME = 'ui-fullscreenmodebutton';

/**
 * Styles for the fullscreen button on the player
 */
export const fullscreenButtonStyles = css`
  .bmpui-${FULLSCREEN_BUTTON_NAME} {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M3.52559 5.77969H7.05059V4.05469H3.52559C2.55059 4.05469 1.72559 4.87969 1.72559 5.85469V9.37969H3.52559V5.77969Z' fill='white'/%3e%3cpath d='M3.52529 14.625H1.80029V18.15C1.80029 19.125 2.62529 19.95 3.60029 19.95H7.12529V18.15H3.52529V14.625Z' fill='white'/%3e%3cpath d='M20.4747 4.05469H16.9497V5.85469H20.4747V9.37969H22.2747V5.77969C22.1997 4.80469 21.4497 4.05469 20.4747 4.05469Z' fill='white'/%3e%3cpath d='M20.4747 18.2203H16.9497V20.0203H20.4747C21.4497 20.0203 22.2747 19.1953 22.2747 18.2203V14.6953H20.4747V18.2203Z' fill='white'/%3e%3c/svg%3e ");
    background-color: transparent;
    background-origin: content-box;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 1.5em;
    border: 0;
    box-sizing: content-box;
    cursor: pointer;
    font-size: 1em;
    height: 1.5em;
    min-width: 1.5em;
    padding: 0.25em;
  }

  .bmpui-${FULLSCREEN_BUTTON_NAME}.bmpui-on {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M3 5.25L6.75 5.25V3.75H3.3C2.325 3.75 1.5 4.575 1.5 5.55V9H3V5.25Z' fill='%231FABE2'/%3e%3cpath d='M2.99999 15H1.49999V18.15C1.49999 19.125 2.39999 19.5 3.37499 19.5H6.74999V18H2.99999V15Z' fill='%231FABE2'/%3e%3cpath d='M20.625 3.75H17.25V5.25H21V9H22.5V5.625C22.425 4.65 21.6 3.75 20.625 3.75Z' fill='%231FABE2'/%3e%3cpath d='M21 18H17.25V19.5H20.625C21.6 19.5 22.5 19.1955 22.5 18.2205V15L21 15V18Z' fill='%231FABE2'/%3e%3cpath opacity='0.302' fill-rule='evenodd' clip-rule='evenodd' d='M4.5 7.75C4.5 7.19772 4.94772 6.75 5.5 6.75H18.5C19.0523 6.75 19.5 7.19772 19.5 7.75V15.5C19.5 16.0523 19.0523 16.5 18.5 16.5H5.5C4.94772 16.5 4.5 16.0523 4.5 15.5V7.75Z' fill='%231FABE2'/%3e%3c/svg%3e ");
    background-size: 1.5em;
  }
`;

export class FullScreenModeButton extends ToggleButton<ToggleButtonConfig> {
  constructor(config: ToggleButtonConfig) {
    super(config);

    this.config = this.mergeConfig(
      config,
      {
        cssClass: FULLSCREEN_BUTTON_NAME,
        text: 'Full Screen',
        role: 'button',
        ariaLabel: 'Fullscreen',
      } as ToggleButtonConfig,
      this.config,
    );

    this.off();
  }

  configure(player: PlayerAPI, uimanager: UIInstanceManager): void {
    super.configure(player, uimanager);

    this.onClick.subscribe(() => {
      if (this.isOn()) {
        this.off();
      } else {
        this.on();
      }
    });

    this.onToggleOn.subscribe(() => {
      super.onToggleOn;
      const buttonLabel = document.querySelector(
        `.bmpui-${FULLSCREEN_BUTTON_NAME} .bmpui-label`,
      ) as HTMLElement;
      if (buttonLabel) {
        buttonLabel.textContent = 'Full Screen';
      }

      player.setViewMode(ViewMode.Fullscreen);
    });
    this.onToggleOff.subscribe(() => {
      super.onToggleOff;
      const buttonLabel = document.querySelector(
        `.bmpui-${FULLSCREEN_BUTTON_NAME} .bmpui-label`,
      ) as HTMLElement;
      if (buttonLabel) {
        buttonLabel.textContent = 'Full Screen';
      }
      player.setViewMode(ViewMode.Inline);
    });
  }
}
