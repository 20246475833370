/** @jsx jsx */
import { jsx } from '@emotion/core';
import styles from './styles';
import { Fragment } from 'react';

export interface MovieDetailsSynopsisPropsInterface {
  center?: boolean;
  color?: string;
  synopsis: string;
}

const MovieDetailsSynopsis: React.FC<MovieDetailsSynopsisPropsInterface> = ({
  synopsis,
  center,
  color,
}) => {
  return (
    <Fragment>
      {synopsis.split('\n').map((text, index) =>
        text.trim().length ?
          <p
            key={`p-${index}`}
            css={styles.bodyDefaultTextCss(center, color)}
          >
            {text}
          </p>
        : <br key={`br-${index}`} />,
      )}
    </Fragment>
  );
};

export default MovieDetailsSynopsis;
