const fontFamily = 'inspire';
const textPrimary = '#fff';
const textSecondary = 'rgba(255, 255, 255, 0.65)';
const textTertiary = '#1F1F20';
const fontWeightRegular = 400;
const fontWeightMedium = 500;
const fontWeightBold = 700;
export const appBarHeight = 60;
export const headerWithNavHeight = 150;
export const bottomActionContainerHeight = 6.7; // rem

export default {
  aspectRatios: {
    xs: '2/3',
    md: '4/3',
    lg: '16/9',
    xl: '239/100',
  },
  breakpoints: {
    xs: 0,
    sm: 376,
    md: 641,
    lg: 1025,
    xl: 3996,
  },
  direction: 'ltr',
  border: {
    light: '1px solid rgba(255, 255, 255, 0.15)',
    medium: '1px solid rgba(255, 255, 255, 0.65)',
    cardFocus: '4px solid #A6A6A6',
    entitlementFocus: '2px solid #A6A6A6',
  },
  button: {
    primary: {
      color: textPrimary,
      background: '#2056F8',
      border: 'none',
      '&:hover': {
        background: '#608EE3',
      },
    },
    secondary: {
      color: textSecondary,
      background: 'transparent',
      border: '2px solid rgb(94, 148, 247)',
      '&:hover': {
        background: 'rgba(255, 255, 255, 1)',
        color: '#000',
      },
    },
  },
  palette: {
    common: {
      black: '#000',
      white: '#fff',
      darkblue: '#404264',
      blue: 'rgb(94, 148, 247)',
    },
    primary: {
      light: '#5E94F7',
      main: '#236DF5',
      dark: '#2338F5',
      contrastText: '#fff',
    },
    secondary: {
      light: '#4DCDD5',
      main: '#00B8C7',
      dark: '#0698A3',
      contrastText: '#1F1F20',
    },
    error: {
      light: '#E57373',
      main: '#E4615D',
      dark: '#d32f2f',
      contrastText: '#1F1F20',
    },
    disabled: {
      main: 'rgba(255, 255, 255, 0.12)',
      contrastText: 'rgba(255, 255, 255, 0.37)',
    },
    grey: {
      50: '#fafafa',
      100: '#f5f5f5',
      200: '#eeeeee',
      300: '#e0e0e0',
      400: '#bdbdbd',
      500: '#9e9e9e',
      600: '#757575',
      700: '#616161',
      800: '#424242',
      900: '#212121',
      A100: '#d5d5d5',
      A200: '#aaaaaa',
      A400: '#303030',
      A700: '#616161',
    },
    text: {
      primary: textPrimary,
      secondary: textSecondary,
      tertiary: textTertiary,
      // TODO:
      tertiaryHover: '',
      error: '#e4615d',
      disabled: 'rgba(255, 255, 255, 0.37)',
      hint: 'rgba(0, 0, 0, 0.38)',
    },
    divider: 'rgba(0, 0, 0, 0.12)',
    background: {
      paper: '#fff',
      primary: '#161729',
      secondary: '#313145',
      light: '#292B3D',
      dark: '#0F101C',
      gradient: `linear-gradient(
        -225deg,
      rgba(0, 0, 0, 0) 0%,
      rgb(34, 35, 55) 100%
      )`,
    },
    border: {
      primary: '#404264',
    },
    action: {
      active: 'rgba(0, 0, 0, 0.54)',
      hover: 'rgba(0, 0, 0, 0.08)',
      hoverOpacity: 0.08,
      selected: 'rgba(0, 0, 0, 0.14)',
      disabled: 'rgba(0, 0, 0, 0.26)',
      disabledBackground: 'rgba(0, 0, 0, 0.12)',
    },
  },
  typography: {
    fontFamily,
    fontSize: 16,
    fontWeightLight: 300,
    fontWeightRegular,
    fontWeightMedium,
    headline: {
      fontSize: '2rem',
      fontWeight: fontWeightBold,
      fontFamily,
      lineHeight: 1.5,
      color: textPrimary,
    },
    title: {
      fontSize: '1.5rem',
      fontWeight: fontWeightBold,
      fontFamily,
      lineHeight: 1.5,
      color: textPrimary,
    },
    subheading: {
      fontSize: '1.25rem',
      fontWeight: fontWeightMedium,
      fontFamily,
      lineHeight: 1.5,
      color: textSecondary,
    },
    defaultText: {
      color: textPrimary,
      fontFamily,
      fontWeight: fontWeightRegular,
      fontSize: '1.1rem',
      lineHeight: 1.5,
      letterSpacing: '0.00938em',
    },
    legalText: {
      color: textSecondary,
      fontFamily,
      fontWeight: fontWeightRegular,
      fontSize: '0.875rem',
      lineHeight: 1,
      letterSpacing: '0.00938em',
    },
    copyrightText: {
      color: textPrimary,
      fontFamily,
      fontWeight: fontWeightRegular,
      fontSize: '1rem',
      lineHeight: 1,
      letterSpacing: '0.00938em',
    },
  },
  shadows: [
    'none',
    '0px 1px 3px 0px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 2px 1px -1px rgba(0,0,0,0.12)',
    '0px 1px 5px 0px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 3px 1px -2px rgba(0,0,0,0.12)',
    '0px 1px 8px 0px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 3px 3px -2px rgba(0,0,0,0.12)',
    '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
    '0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)',
    '0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12),\n    0px 2px 4px -1px rgba(0, 0, 0, 0.2)',
  ],
  shape: {
    borderRadius: 4,
  },
  spacing: {
    unit: 8,
  },
  padding: {
    sides: {
      xs: '4%',
      md: '9%',
      lg: '8.33%',
    },
    top: {
      xs: '1.75rem',
      md: '2.75rem',
      lg: '3.75rem',
    },
    innerPage: {
      bottom: {
        xs: '22.75rem',
        md: '17.75rem',
        lg: '14.75rem',
      },
    },
    footer: {
      sides: {
        xs: '9%',
        md: '10%',
        lg: '9.33%',
      },
    },
  },
  margin: {
    sides: {
      xs: '4%',
      md: '9%',
      lg: '8.33%',
    },
    top: {
      xs: '1.75rem',
      md: '2.75rem',
      lg: '3.75rem',
    },
  },
  zIndex: {
    loader: 999,
    appBar: 1100,
    drawer: 1200,
    modal: 1300,
    snackbar: 1400,
    tooltip: 1500,
    clickOutLayer: 1525,
    settingsDrawer: 1550,
    alert: 1575,
    tcModal: 2000,
  },
  height: {
    footer: {
      minHeight: {
        xs: '0',
        md: '13.9rem',
        lg: '13.9rem',
      },
    },
  },
};
