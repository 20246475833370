import type { NSMovieSynopsisComponentInterface } from 'models/cms';
import type { FunctionComponent } from 'react';
import React from 'react';
import clsx from 'clsx';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles<any>((theme) => ({
  root: {
    margin: `${theme.spacing.unit * 2} ${theme.spacing.unit * 5}`,
    ...theme.typography.defaultText,
  },
}));

interface PropsInterface {
  className?: string;
  component: NSMovieSynopsisComponentInterface;
}

const NsMovieSynopsisComponent: FunctionComponent<PropsInterface> = (
  props: PropsInterface,
) => {
  const { className, component } = props;

  const classes = useStyles();

  return <p className={clsx(className, classes.root)}>{component.text}</p>;
};

export default NsMovieSynopsisComponent;
