import { css } from '@emotion/core';
import { mediaQuery } from 'utils/styles';
import { MOBILE_VIEW } from '../details-banner/helpers';

export const containerCss = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: ${72 / 16}rem;

  .entitlement-title {
    margin-top: 8px;
  }
`;

export const textWrapperCss = css`
  width: 90%;
  max-width: ${468 / 16}rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .title {
    font-size: ${24 / 16}rem;
  }

  .content {
    margin-top: ${19 / 16}rem;
    margin-bottom: ${40 / 16}rem;
    font-size: 1rem;
    color: rgba(255, 255, 255, 0.87);
  }
`;

export const imgWrapperCss = css`
  position: relative;
  width: 90vw;
  height: ${(90 / 16) * 9}vw;

  ${mediaQuery(MOBILE_VIEW)} {
    width: ${398 / 16}rem;
    height: ${232 / 16}rem;
  }

  .blur {
    position: absolute;
    background: rgba(0, 0, 0, 0.0932583);
    backdrop-filter: blur(5.43656px);

    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
`;

export const unlockButtonCss = css`
  cursor: pointer;
  position: absolute;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 11px 35px;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 6px;
  color: white;

  display: flex;
  justify-content: center;
  alight-items: center;

  img {
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
  }

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    flex-shrink: 0;
  }
`;
