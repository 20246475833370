import { css } from '@emotion/core';
import theme from 'styles/theme';
import { mediaQuery } from 'utils/styles';

export const rootStyles = css`
  margin: 1.5rem 0;
  position: relative;

  ${mediaQuery(theme.breakpoints.md)} {
    .row {
      padding: 0;
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  ${mediaQuery(theme.breakpoints.lg)} {
    .row {
      padding: 0 5.2%;
    }
  }
`;
