import React from 'react';
import { Global, css } from '@emotion/core';

import flexboxGrid from './flexbox-grid';
import theme from './theme';
import reactTabs from './react-tabs';
import bitMovinPlayer from './bit-movin-player';
import type { ContextPropsInterface } from 'providers/strings-provider';
import { useStringsContext, CONFIG_KEYS } from 'providers/strings-provider';

const globalStyles = (
  getConfigByKey: ContextPropsInterface['getConfigByKey'],
) => css`
  html {
    width: 100%;
    font-size: 16px;
    color: ${getConfigByKey(CONFIG_KEYS.APP_TEXT_COLOR)};
    font-family: inspire;

    background: var(
      --Page-BG-23,
      ${getConfigByKey(CONFIG_KEYS.PAGE_GRADIENT_CONFIG_WEB)},
      ${getConfigByKey(CONFIG_KEYS.PAGE_BACKGROUND_COLOR)}
    );
  }

  h2 {
    font-size: 3rem;
    font-weight: 300;
    margin: 0;
  }

  body {
    margin: 0;
    padding: 0;
  }

  * {
    outline: none;
    box-sizing: border-box;
  }

  button,
  input {
    border-style: unset;
  }

  a {
    color: ${theme.palette.text.primary};
  }

  .page {
    overflow-x: hidden;
  }

  .hide {
    display: none;
  }

  .border {
    border: 1px solid black;

    &-rounded {
      border-radius: 6px;
      overflow: hidden;
    }

    &-color {
      &--white {
        border-color: white;
      }
    }
  }

  .background {
    &-color {
      &--white {
        background-color: white;
      }
    }
  }

  .no-margin {
    margin: 0;
    &--right {
      margin-right: 0 !important;
    }
  }
`;

const GlobalStyles: React.FC = () => {
  const { getConfigByKey, isLoading } = useStringsContext();

  if (isLoading) {
    return null;
  }

  return (
    <Global
      styles={[
        flexboxGrid,
        reactTabs,
        bitMovinPlayer,
        globalStyles(getConfigByKey),
      ]}
    />
  );
};

export default GlobalStyles;
