import type { ListSelectorConfig } from 'bitmovin-player-ui/dist/js/framework/components/listselector';
import { PlaybackSpeedSelectBox } from 'bitmovin-player-ui/dist/js/framework/components/playbackspeedselectbox';

interface CustomSpeedOptionsInterface {
  tenTimes: boolean;
}

export class CustomPlaybackSpeedSelectBox extends PlaybackSpeedSelectBox {
  constructor(
    config: ListSelectorConfig = {},
    speedOptions: CustomSpeedOptionsInterface,
  ) {
    super(config);

    if (speedOptions.tenTimes) {
      // https://github.com/bitmovin/bitmovin-player-ui/blob/develop/src/ts/components/playbackspeedselectbox.ts#L51
      // it used string sort
      this.defaultPlaybackSpeeds = [1, 2, 5, 6, 7, 8, 9, 10];
    } else {
      this.defaultPlaybackSpeeds = [1, 2, 5];
    }
  }
}
