import { createSelector, createSlice } from '@reduxjs/toolkit';
import type { RootStateType } from '../root-reducer';
import type { AppThunkType } from 'store';
import { isArray } from 'lodash';

export interface ProgressDataInterface {
  id: string;
  progress: number | undefined;
}

export interface ProgressStateInterface {
  [id: string]: {
    progress: number | undefined;
  } | null;
  error?: any;
}

export const initialState: ProgressStateInterface = {
  error: null,
};

const progressSlice = createSlice({
  name: 'entitlementProgress',
  initialState,
  reducers: {
    updateProgress(state, action) {
      if (isArray(action.payload)) {
        action.payload.forEach((data: ProgressDataInterface) => {
          state[data.id] = { progress: data.progress };
        });
      } else {
        state[action.payload.id] = { progress: action.payload.progress };
      }
    },
    updateFailed(state, action) {
      state[action.payload.id] = null;
      state.error = action.payload;
    },
  },
});

export const { updateProgress, updateFailed } = progressSlice.actions;

export const setProgress =
  (
    entitlementProgress: ProgressDataInterface | ProgressDataInterface[],
  ): AppThunkType =>
  async (dispatch) => {
    try {
      dispatch(updateProgress(entitlementProgress));
    } catch (error) {
      dispatch(updateFailed(error));
    }
  };

// selectors
const progressSelector = (state: RootStateType) => state.entitlementProgress;

export const progressDataSelector = createSelector(
  progressSelector,
  (entitlementProgress: ProgressStateInterface) => entitlementProgress,
);

export default progressSlice.reducer;
