/** @jsx jsx */
import { jsx } from '@emotion/core';
import type {
  NSBannerComponentInterface,
  NSEntitlementDetailsComponentInterface,
  NSEntitlementsComponentInterface,
  NSGalleryComponentInterface,
  NSHeroInterface,
  NSItemInterface,
  NSPageComponentInterface,
} from 'models/cms';
import React, { useState, useEffect } from 'react';
import { getDataByType, getDataArrayByType } from 'utils/fyc';
import { DisplayTypeEnum, FycComponentTypeEnum } from 'models/enums';
import {
  wrapperCss,
  leftColumnCss,
  rightColumnCss,
  cateContainer,
  cateListItemCss,
  noContentContainerCss,
  buttonContainerCss,
  dropDownContainerCss,
  bannerWrapperCss,
} from './styles';
import { AllContent, FycSlider } from 'components/fyc-component';
import { useHistory, useLocation } from 'react-router-dom';
import { useQuery } from 'utils/hooks';
import FycButton from 'components/fyc-component/fyc-button';
import { getCurrentFycVersion } from '../helpers';
import CategorySelect from 'components/fyc-component/category-select';
import { FycBanner } from 'components/fyc-component/fyc-banner';
import { useFycThemeContext } from 'providers/fyc-theme-provider';

interface PropsInterface {
  pageData?: {
    components?: any;
  };
}

interface FycBannerInterface extends NSPageComponentInterface {
  sliderTitles?: string;
  visibilityRule?: string;
}

interface CombinedBannersInterface {
  items: NSItemInterface[];
}

const AllContentLanding: React.FC<PropsInterface> = ({ pageData }) => {
  const version = useFycThemeContext()?.version;
  const [cateIndex, setCateIndex] = useState(0);
  const history = useHistory();
  const { pathname } = useLocation();
  const query = useQuery();
  const category = query.get('category');

  const [selectedCate, setSelectedCate] = useState(category);

  const entitlements = getDataByType<NSEntitlementsComponentInterface>(
    FycComponentTypeEnum.ENTITLEMENTS,
    pageData?.components,
  );

  const hero = getDataByType<NSHeroInterface>(
    FycComponentTypeEnum.HERO,
    pageData?.components,
  );

  const banner = getDataArrayByType<NSBannerComponentInterface>(
    FycComponentTypeEnum.BANNER,
    pageData?.components,
  );

  const fycBanner = getDataArrayByType<NSBannerComponentInterface>(
    FycComponentTypeEnum.FYC_BANNER,
    pageData?.components,
  ) as FycBannerInterface[] | undefined | null;

  // combine all the banners/heros that should be displayed in landing page
  const combineHeroNBannerNFycBanner = () => {
    const result: CombinedBannersInterface = {
      items: [],
    };

    if (hero && hero.items) {
      result.items = [...result.items, ...hero.items];
    }

    if (banner) {
      for (const b of banner) {
        const item = {
          id: b.id,
          image: b.image,
          actions: b.actions,
        };
        result.items.push(item);
      }
    }

    if (fycBanner) {
      for (const b of fycBanner) {
        if (b.visibilityRule !== 'slider') {
          const item = {
            id: b.id,
            image: b.image,
            actions: b.actions,
          };
          result.items.push(item);
        }
      }
    }

    return result;
  };

  const generateSliderPageBanner = (category: string) => {
    const result: CombinedBannersInterface = {
      items: [],
    };

    if (fycBanner) {
      for (const b of fycBanner) {
        if (b.visibilityRule === 'slider' || b.visibilityRule === 'all') {
          const titles = b.sliderTitles?.split(';');

          if (!titles) {
            return;
          }

          if (titles.indexOf(category) !== -1) {
            const item = {
              id: b.id,
              image: b.image,
              actions: b.actions,
            };
            result.items.push(item);
          }
        }
      }
    }

    return result;
  };

  const entitlementsDetails =
    getDataByType<NSEntitlementDetailsComponentInterface>(
      FycComponentTypeEnum.ENTITLEMENT_DETAILS,
      pageData?.components,
    );

  const galleries = getDataArrayByType<NSGalleryComponentInterface>(
    FycComponentTypeEnum.FYC_GALLERY,
    pageData?.components,
  );

  const handleSelectCate = (title: string) => {
    if (title === 'ALL CONTENT') {
      setSelectedCate(null);
      history.push(pathname);
      return;
    }

    setSelectedCate(title);
    history.push(`${pathname}?category=${title}`);
  };

  const displayRightSection = () => {
    if (!selectedCate) {
      return (
        <AllContent
          items={entitlements?.items}
          hasPlain={entitlementsDetails?.hasPlain}
        />
      );
    }

    const data = galleries?.find((g) => g.title === selectedCate);
    if (data?.displayType === DisplayTypeEnum.GRID) {
      return (
        <AllContent
          items={data?.items}
          hasPlain={entitlementsDetails?.hasPlain}
        />
      );
    } else {
      return (
        <FycSlider
          hasPlain={entitlementsDetails?.hasPlain ?? false}
          data={data}
          category={selectedCate}
        />
      );
    }
  };

  useEffect(() => {
    if (!category) {
      setSelectedCate(null);
      setCateIndex(0);
      return;
    }

    const index = galleries?.findIndex((g) => g.title === category);

    if (!index) {
      setCateIndex(0);
      return;
    }
    setCateIndex(index + 1);
  }, [category, galleries]);

  // display no-content page when no entitlements
  if (!entitlements || entitlements.items?.length === 0) {
    return (
      <div css={noContentContainerCss}>
        <p className="no-content-body">
          Sorry, we don’t have content for you at this moment.
        </p>
        <FycButton
          styles={buttonContainerCss}
          displayValue="BACK TO MAIN"
          target={`fyc/${getCurrentFycVersion()}`}
        />
      </div>
    );
  }

  const galleryCategories = galleries?.filter((gallery) => gallery.title) as {
    title: string;
  }[];

  return (
    <div>
      {!category && (
        <div
          className="fyc-banner-container"
          css={bannerWrapperCss}
        >
          <FycBanner
            component={combineHeroNBannerNFycBanner()}
            hasPlain={entitlementsDetails?.hasPlain}
          />
        </div>
      )}

      {category && (
        <div
          className="fyc-banner-container"
          css={bannerWrapperCss}
        >
          <FycBanner
            component={generateSliderPageBanner(category)}
            hasPlain={entitlementsDetails?.hasPlain}
          />
        </div>
      )}

      {galleries && (
        <div css={dropDownContainerCss}>
          <CategorySelect
            handleSelectCate={handleSelectCate}
            categories={galleryCategories}
            currentCate={selectedCate}
          />
        </div>
      )}
      <div css={wrapperCss(!!selectedCate)}>
        <div css={leftColumnCss(version)}>
          <div css={cateContainer(cateIndex, version)}>
            <div
              css={cateListItemCss(version)}
              style={{ marginTop: 0 }}
              onClick={() => history.push(pathname)}
            >
              All Content
            </div>
            {galleries?.map((g) => (
              <div
                onClick={() => {
                  g.title && handleSelectCate(g.title);
                }}
                css={cateListItemCss(version)}
                key={g.id}
              >
                {g.title}
              </div>
            ))}
          </div>
        </div>
        <div css={rightColumnCss}>{displayRightSection()}</div>
      </div>
    </div>
  );
};

export default AllContentLanding;
