import React, { useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';

import * as routes from './constants';
import type { TokenInfoInterface } from 'utils/hooks';
import { useDecodedJwt, useIsDeviceSupported } from 'utils/hooks';
import { useDispatch, useSelector } from 'react-redux';
import {
  authenticationSelector,
  checkTosStatusSelector,
  logoutWithError,
  tokenSelector,
} from 'store/slices/user';
import { LoginFailureCodesEnum } from 'models/enums';
import { getProfile, saveProfile } from 'utils/profile';
import DownloadApp from 'pages/download-app';
import JwtDecode from 'jwt-decode';
import { useStringsContext, CONFIG_KEYS } from 'providers/strings-provider';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

interface PropsInterface {
  children: React.ReactNode;
  exact?: boolean;
  path?: string | string[];
  requireTerms?: boolean;
}

const PrivateRoute: React.FC<PropsInterface> = ({
  children,
  requireTerms = true,
  ...rest
}) => {
  const decodedJwt = useDecodedJwt();
  const { accessToken } = useSelector(tokenSelector);
  const dispatch = useDispatch();
  const { userId } = useSelector(authenticationSelector);
  const checkTosStatus = useSelector(checkTosStatusSelector);
  // @ts-ignore TODO: TS2345: Argument of type 'string | undefined' is not assignable to parameter of type 'string'
  const profile = getProfile(userId);
  const isDeviceSupported = useIsDeviceSupported();
  const { getConfigByKey } = useStringsContext();
  const privacyTCUpdatedDate = getConfigByKey(
    CONFIG_KEYS.PRIVACY_TC_UPDATED_DATE,
  );
  const privacyTCConsentIntervalDays =
    getConfigByKey(CONFIG_KEYS.PRIVACY_TC_CONSENT_INTERVAL_DAYS) || '';

  const isTokenExpiredWithin5Minutes = (accessToken) => {
    const jwt = JwtDecode<TokenInfoInterface>(accessToken);

    return Date.now() - jwt.exp * 1000 < 5 * 60 * 1000;
  };

  // handle show tos after login succeed
  const [showTOS, setShowTOS] = useState(false);
  useEffect(() => {
    if (!checkTosStatus) {
      return;
    }

    const now = dayjs();
    const tosDate = dayjs(privacyTCUpdatedDate, 'YYYY/MM/DD', true);
    const previousTosDate = dayjs(profile.tosDate, 'YYYY/MM/DD', true);

    if (!decodedJwt) {
      // 1. not logged in
      setShowTOS(false);
    } else if (
      (decodedJwt && requireTerms && !profile.termsAccepted) ||
      !profile.termsAcceptedDate
    ) {
      // 2. logged in user without agreeing with TOS or no accept date exists
      // @ts-ignore TODO: TS2345: Argument of type 'string | undefined' is not assignable to parameter of type 'string'
      saveProfile(userId, { ...profile, termsAccepted: false });
      setShowTOS(true);
    } else if (
      (decodedJwt &&
        tosDate.isValid() &&
        !previousTosDate.isSame(tosDate, 'day')) ||
      (decodedJwt && !tosDate.isValid())
    ) {
      // 3. logged in user agreed with expired TOS (signed date is before the TOS date)
      // @ts-ignore TODO: TS2345: Argument of type 'string | undefined' is not assignable to parameter of type 'string'
      saveProfile(userId, { ...profile, termsAccepted: false });
      setShowTOS(true);
    } else if (
      dayjs(profile.termsAcceptedDate).diff(now, 'day', true) >
      parseInt(privacyTCConsentIntervalDays)
    ) {
      // 4. logged in user agreed with the proper TOS but passed the interval requirement
      // @ts-ignore TODO: TS2345: Argument of type 'string | undefined' is not assignable to parameter of type 'string'
      saveProfile(userId, { ...profile, termsAccepted: false });
      setShowTOS(true);
    }
  }, [
    checkTosStatus,
    decodedJwt,
    privacyTCConsentIntervalDays,
    privacyTCUpdatedDate,
    profile,
    requireTerms,
    userId,
  ]);

  useEffect(() => {
    if (!!decodedJwt && !profile.termsAccepted) {
      setShowTOS(true);
    }
  }, [decodedJwt, profile.termsAccepted]);

  const renderRoute = (props) => {
    // If we have an access token and a null value for decoded jwt, it means our session expired
    if (accessToken && !decodedJwt) {
      // We need a timeout here to prevent bad setStates from other components.
      // if accessToken is expired within 5 minutes, we will dispatch logoutWithError
      if (isTokenExpiredWithin5Minutes(accessToken)) {
        setTimeout(
          () =>
            dispatch(
              logoutWithError({
                code: LoginFailureCodesEnum.TOKEN_EXPIRED,
              }),
            ),
          100,
        );
      }

      return (
        <Redirect
          to={{
            pathname: routes.LOGIN_LANDING,
            state: { from: props.location },
          }}
        />
      );
    }

    // If there is no decoded token or access token, the user was never logged-in
    if (!decodedJwt && !accessToken) {
      return (
        <Redirect
          to={{
            pathname: routes.LOGIN_LANDING,
            state: { from: props.location },
          }}
        />
      );
    }

    // check if user agreed to terms & conditions
    if (showTOS) {
      return (
        <Redirect
          to={{
            pathname: routes.TERMS,
            state: { from: props.location },
          }}
        />
      );
    }

    // check if user is using an iPhone or iPad
    // redirect them to app download page
    if (!isDeviceSupported) {
      return <DownloadApp auth={accessToken && decodedJwt ? true : false} />;
    }

    return children;
  };

  return (
    <Route
      render={renderRoute}
      {...rest}
    />
  );
};

export default PrivateRoute;
