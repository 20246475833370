import { css } from '@emotion/core';
import theme from 'styles/theme';

const defaultStyle = css`
  letter-spacing: 0.5px;

  &.full-width {
    width: 100%;
  }
`;

export const defaultCss = css([
  { ...theme.typography.defaultText },
  defaultStyle,
]);

const buttonText = css`
  font-size: 18px;
  font-weight: 400;
  height: 25px;
`;
export const buttonTextCss = css([
  { ...theme.typography.defaultText },
  defaultStyle,
  buttonText,
]);

const subHeading = css`
  font-size: 24px;
  font-weight: 700;
  line-height: 35px;
  letter-spacing: 0.71px;
  text-align: center;
`;
export const subHeadingCss = css([
  { ...theme.typography.defaultText },
  defaultStyle,
  subHeading,
]);

const heading = css`
  font-size: 2rem;
  font-weight: 700;
  line-height: 35px;
  letter-spacing: 0.71px;
  text-align: center;
  margin: 0;
`;
export const headingCss = css([
  { ...theme.typography.defaultText },
  defaultStyle,
  heading,
]);

const body = css`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.71px;
  color: #fff;
  opacity: 65%;
`;
export const bodyText = css([
  { ...theme.typography.defaultText },
  defaultStyle,
  body,
]);
