/** @jsx jsx */
import { jsx } from '@emotion/core';
import type { LinkObjectInterface } from 'components/footer';
import Footer from './footer';
import { HELP, TERMS } from 'router/constants';
import { setCookie } from 'store/cookies';
import { useSelector } from 'react-redux';
import { isKidsLoginSelector } from 'store/slices/user';
import { STRING_KEYS, useStringsContext } from 'providers/strings-provider';

const FooterV2 = () => {
  const { getStringByKey } = useStringsContext();

  const isKidsLogin =
    useSelector(isKidsLoginSelector) ||
    location.pathname.split('/')[2] === 'family';

  const WEB_COPPA_FOOTER_LINKS = getStringByKey(
    STRING_KEYS.WEB_COPPA_FOOTER_LINKS,
  );
  const WEB_FOOTER_LINKS = getStringByKey(STRING_KEYS.WEB_FOOTER_LINKS);

  const generateTosPart = () => {
    setCookie('one_trust', 'empty');
    const results: JSX.Element[] = [];
    let links: LinkObjectInterface[] = [];

    try {
      links = JSON.parse(
        /* @ts-ignore TODO: TS2345: Argument of type string | undefined is not assignable to parameter of type string. */
        !!isKidsLogin ? WEB_COPPA_FOOTER_LINKS : WEB_FOOTER_LINKS,
      );
    } catch (error) {
      console.error(error);
    }

    links.map((l, i) => {
      results.push(
        <a
          rel="noreferrer"
          target="_blank"
          href={l.url}
          id={l.id}
          className={l.class}
          key={i}
        >
          {l.value}
        </a>,
      );
      if (i < links.length - 1) {
        results.push(
          <span
            className="spans-group"
            key={`span-${i}`}
          >
            |
          </span>,
        );
      }
    });

    return results;
  };

  return (
    <Footer
      links={[
        { text: 'The Walt Disney Studios Debut Agreement', url: TERMS },
        { text: 'Help Center', url: HELP },
      ]}
      tosData={generateTosPart()}
    />
  );
};

export default FooterV2;
