import type { NSActionInterface } from 'models/cms';
import React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles<any>(() => ({
  root: {
    textDecoration: 'none',
  },
}));

interface PropsInterface {
  actions?: NSActionInterface[] | null;
  children: any;
  className?: string;
}

const NsActionLink: React.FC<PropsInterface> = (props: PropsInterface) => {
  const classes = useStyles();

  if (!props.actions) {
    return props.children;
  } else {
    const primaryAction = props.actions.find((a) => a.name === 'primary');
    if (!primaryAction) {
      return props.children;
    } else {
      const { id, type, target } = primaryAction;
      if (type.toLowerCase() === 'external') {
        return (
          <a
            className={clsx(props.className, classes.root)}
            /* @ts-ignore TODO: TS2322: Type string | null | undefined is not assignable to type string | undefined. */
            href={target}
          >
            {props.children}
          </a>
        );
      } else if (['page'].includes(type.toLowerCase())) {
        return (
          <Link
            className={clsx(props.className, classes.root)}
            key={id}
            to={'/' + target}
          >
            {props.children}
          </Link>
        );
      } else if (type.toLowerCase() === 'play') {
        return props.children;
      } else {
        console.log('unsupported action type', type);
        return props.children;
      }
    }
  }
};

export default NsActionLink;
