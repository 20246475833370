/** @jsx jsx */
import type { SerializedStyles } from '@emotion/core';
import { jsx, css } from '@emotion/core';
import React from 'react';
import FycButton from 'components/fyc-component/fyc-button';

const wrapperCss = (styles?: SerializedStyles) => {
  const defaultStyle = css`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: ${(149 / 1440) * 100}%;
  `;

  if (styles) {
    return [defaultStyle, styles];
  }
};

interface PropsInterface {
  data: any;
  styles?: SerializedStyles;
}

const FycPageLink: React.FC<PropsInterface> = ({ data, styles }) => {
  return (
    <div css={wrapperCss(styles)}>
      {data?.actions.map((l) => {
        return (
          <FycButton
            key={l.id}
            target={l.target}
            displayValue={l.displayValue.toUpperCase()}
            styles={css`
              font-size: ${data.fontSize};
              height: ${data.height};
              width: ${data.width};
            `}
          />
        );
      })}
    </div>
  );
};

export default FycPageLink;
