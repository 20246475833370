import JwtDecode from 'jwt-decode';
import type { EntitlementComponentInterface } from '../../../models/components/entitlement-component-interface';
import bcrypt from 'bcryptjs';

export const FYC_CODE_VALID = 'fycCodeValid';
export const SHOW_FYC_T_AND_C = 'showFycTAndC';

const FYC_CODES = 'fycCodes';
const FYC_CODE_TERMS_ACCEPTED = 'fycCodeTermsAccepted';

const saltRounds = 10;

interface TokenInfoInterface {
  aud: string;
  client_id: string;
  email: string;
  exp: number;
  iss: string;
  orgs?: [string];
  sub: string;
}

export interface FycCodeItemInterface {
  accessToken: string;
  playIds: string[];
}

export const saveFycCodes = (
  accessToken: string,
  playIds: string[],
  code: string,
): void => {
  if (!accessToken || !playIds.length) {
    return;
  }

  const fycCodes = localStorage.getItem(FYC_CODES);
  const fycCodesArray = fycCodes ? JSON.parse(fycCodes) : [];

  const validCodesArr = removeExpired(fycCodesArray);

  validCodesArr.push({ accessToken, playIds });

  localStorage.setItem(FYC_CODES, JSON.stringify(validCodesArr));

  updateTermsIsAccepted(code);
};

export const encryptAccessCode = (code: string): string => {
  const salt = bcrypt.genSaltSync(saltRounds);
  const hash = bcrypt.hashSync(code, salt);
  return hash;
};

export const checkTermsIsAccepted = (code: string): boolean => {
  const savedHash = localStorage.getItem(FYC_CODE_TERMS_ACCEPTED);
  const savedHashArr = !!savedHash ? JSON.parse(savedHash) : [];

  const checked = savedHashArr.some((item) => {
    return bcrypt.compareSync(code, item);
  });
  return checked;
};

export const updateTermsIsAccepted = (code: string): void => {
  const hash = encryptAccessCode(code);
  const savedHash = localStorage.getItem(FYC_CODE_TERMS_ACCEPTED);
  const savedHashArr = !!savedHash ? JSON.parse(savedHash) : [];
  savedHashArr.push(hash);

  localStorage.setItem(FYC_CODE_TERMS_ACCEPTED, JSON.stringify(savedHashArr));
};

export const showVideoActions = (
  id?: string | null,
): FycCodeItemInterface | undefined => {
  const fycCodes = localStorage.getItem(FYC_CODES);
  const fycCodesArray: FycCodeItemInterface[] =
    !!fycCodes ? JSON.parse(fycCodes) : [];

  if (fycCodesArray && fycCodesArray.length) {
    const expiredFree = removeExpired(fycCodesArray);
    localStorage.setItem(FYC_CODES, JSON.stringify(expiredFree));

    if (!id) {
      return;
    }

    return expiredFree.find((item) => {
      return item.playIds.includes(id);
    });
  }
};

export const shouldShowVideoAction = (id?: string | null) => {
  return !!showVideoActions(id);
};

const removeExpired = (arr: FycCodeItemInterface[]): FycCodeItemInterface[] => {
  const validCodesArr = arr.filter((c) => {
    const jwt = JwtDecode<TokenInfoInterface>(c.accessToken);
    if (!!jwt && Date.now() < jwt.exp * 1000) {
      return true;
    }
    return false;
  });

  return validCodesArr;
};

export const tokenCheckNReload = (
  component: EntitlementComponentInterface,
  setIsFyc,
  setFycAccessToken,
): void => {
  setIsFyc(true);

  const show =
    component.entitlement?.playId ?
      showVideoActions(component.entitlement?.playId)
    : false;

  if (!show) {
    window.location.reload();
    return;
  }

  const { accessToken } = show;

  if (accessToken) {
    setFycAccessToken(accessToken);
  }
};
