import { css } from '@emotion/core';

export const headerTextStyles = css`
  color: #403f3f;
`;

export const containerStyles = css`
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  position: absolute;
`;

export const cardStyles = css`
  background: #ffffff;
  border: 0.5px solid #e4e4e4;
  box-shadow:
    0px 2px 2px rgba(0, 0, 0, 0.05),
    0px 3px 1px -2px rgba(0, 0, 0, 0.07),
    0px 1px 5px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  width: 960px;
  justify-content: space-around;
  padding: 3rem 6rem;
`;

export const centerStyles = css`
  display: flex;
  align-items: center;
  justify-items: center;
  flex-flow: row nowrap;
`;

export const appStoreStyles = css`
  position: absolute;
  top: 4rem;
  left: 11rem;
`;

export const mobileImgStyles = css`
  width: 224px;
  text-align: center;
`;

export const mobileAppContainerStyles = css`
  position: relative;
`;

export const linkStyles = css`
  color: blue;
  margin-top: 1rem;
`;

export const verionNumberStyles = css`
  color: #b6b6b8;
`;
