import Bowser from 'bowser';
import type { EntitlementComponentInterface } from 'models/components/entitlement-component-interface';
import {
  CONFIG_KEYS,
  STRING_KEYS,
  useStringsContext,
} from 'providers/strings-provider';
import { BROWSER_COMPATIBILITY } from '.';

export const useVerifyBrowserSupport = (): {
  data: any;
  isCompatible: boolean;
} => {
  const { getStringByKey, getConfigByKey } = useStringsContext();
  let browserCompatibility: boolean;

  let toastStrings = getStringByKey(STRING_KEYS.UNSUPPORTED_BROWSERS_MSG);
  const jsonString = getConfigByKey(CONFIG_KEYS.UNSUPPORTED_BROWSERS_LIST);

  try {
    const unsupportedBrowsersList =
      !!jsonString && (JSON.parse(jsonString) as BrowserListInterface[]);
    browserCompatibility =
      unsupportedBrowsersList &&
      getBrowserCompatability(unsupportedBrowsersList);
  } catch (e) {
    browserCompatibility = false;
  }

  try {
    toastStrings = toastStrings && JSON.parse(toastStrings);
  } catch (e) {}

  return { isCompatible: browserCompatibility, data: toastStrings };
};

export const parseUserAgentVersion = (
  version?: string | null,
): number | undefined => {
  if (!version) {
    return;
  }
  return Number(version.split('.')[0]);
};

export const isBrowserCompatible = (
  userAgent: string = window.navigator.userAgent,
): boolean => {
  const { browser } = Bowser.parse(userAgent);
  const browserVersion = parseUserAgentVersion(browser.version) || 0;

  switch (browser.name) {
    case BROWSER_COMPATIBILITY.Firefox.name:
      return true; // SR-2958: set back to false once firefox event is over
    case BROWSER_COMPATIBILITY.Chrome.name:
      return browserVersion >= BROWSER_COMPATIBILITY.Chrome.minVersion;
    case BROWSER_COMPATIBILITY.Safari.name:
      return browserVersion >= BROWSER_COMPATIBILITY.Safari.minVersion;
    case BROWSER_COMPATIBILITY.Edge.name:
      return browserVersion >= BROWSER_COMPATIBILITY.Edge.minVersion;
    default:
      return false;
  }
};

export const isSafari = (
  userAgent: string = window.navigator.userAgent,
): boolean => {
  const { browser } = Bowser.parse(userAgent);

  return browser.name === BROWSER_COMPATIBILITY.Safari.name;
};

export interface BrowserListInterface {
  name: string;
  version: string;
}
export const getBrowserCompatability = (
  browserList: BrowserListInterface[],
  component?: EntitlementComponentInterface,
): boolean => {
  const firefoxTag = component?.entitlement?.tags?.includes('firefox');
  const { browser } = Bowser.parse(window.navigator.userAgent);

  // support for Googlebot and Bingbot
  if (browser.name === 'Googlebot' || browser.name === 'Bingbot') {
    return true;
  }

  const isIncompatibleBrowser = browserList.find(
    (item) => item.name === browser.name && item.version === browser.version,
  );

  if (isIncompatibleBrowser) {
    return false;
    // If user has "firefox" tag under entitlements, show player in firefox browser
  } else if (!firefoxTag && browser.name === 'Firefox') {
    return false;
  }

  return true;
};
