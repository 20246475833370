/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import Item from './item';
import { wrapperCss } from './styles';

interface PropsInterface {
  data: any;
}

const DocumentsPanel: React.FC<PropsInterface> = ({ data }) => {
  return (
    <div css={wrapperCss}>
      <div className="title">Documents</div>
      <div className="subtitle">
        {data.length > 1 ? `${data.length} items` : '1 item'}
      </div>
      {data?.map((item, index) => (
        <Item
          key={item.id}
          filename={item.title}
          fileLink={item.fileUrl}
          isLast={index === data.length - 1}
          usePdfViewer={item.usePdfViewer}
        />
      ))}
    </div>
  );
};

export default DocumentsPanel;
