/** @jsx jsx */
import { jsx } from '@emotion/core';
import type {
  NSAwardsCategoryInterface,
  NSCastInterface,
  NSConfigurationComponentInterface,
  TabConfigurationInterface,
} from 'models/cms';
import { ComponentTypeEnum } from 'models/enums';
import { TabTypeEnum } from 'models/enums';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { pageDataSelector } from 'store/slices/page';
import { isMDPDataValid } from 'utils';
import type { TabsViewPropsInterface } from './tabs-view';
import TabsView from './tabs-view';

interface PropsInterface {
  accolades?: NSAwardsCategoryInterface[] | null | undefined;
  awardCategories?: NSAwardsCategoryInterface[] | null | undefined;
  credits?: NSCastInterface[] | null | undefined;
  defaultTabIndex?: number;
  progressData?: any;
  relatedContents?: any;
  series?: any;
  synopsis?: string | null | undefined;
}

const DetailsPart: React.FC<PropsInterface> = ({
  synopsis,
  credits,
  awardCategories,
  series,
  relatedContents,
  defaultTabIndex,
  accolades,
}) => {
  const { components } = useSelector(pageDataSelector);

  const hasEpisodes = isMDPDataValid({ series: series.component });
  const hasCredits = isMDPDataValid({ credits });
  const hasAwardCategories = isMDPDataValid({ awardCategories });
  const hasAccolades = isMDPDataValid({ accolades });
  const hasSynopsis = isMDPDataValid({ synopsis });
  const hasRelatedContent = isMDPDataValid({
    relatedContents: relatedContents.components,
  });

  const tabConfiguration = useMemo<
    TabConfigurationInterface[] | undefined
  >(() => {
    const configurationComponent = components?.find(
      (c) => c.type === ComponentTypeEnum.CONFIGURATION,
    ) as NSConfigurationComponentInterface | undefined;

    if (!configurationComponent || !configurationComponent.tabConfiguration) {
      return;
    }

    try {
      return JSON.parse(configurationComponent.tabConfiguration);
    } catch (error) {
      return;
    }
  }, [components]);

  const generateTabsViewData = (
    currentTabConfiguration: TabConfigurationInterface[] | undefined,
  ) => {
    if (!currentTabConfiguration) {
      return;
    }

    const showTabs: TabsViewPropsInterface['showTabs'] = [];

    for (const tab of currentTabConfiguration) {
      if (!tab.displayName || !tab.tabId) {
        return;
      }

      const { displayName, tabId } = tab;
      let tabData;

      switch (tab.tabId) {
        case TabTypeEnum.EPISODES:
          if (hasEpisodes) {
            tabData = series;
          }
          break;

        case TabTypeEnum.RELATED:
          if (hasRelatedContent) {
            tabData = relatedContents;
          }
          break;

        case TabTypeEnum.SYNOPSIS:
          if (hasSynopsis) {
            tabData = synopsis;
          }
          break;

        case TabTypeEnum.CREDITS:
          if (hasCredits) {
            tabData = credits;
          }
          break;

        case TabTypeEnum.CATEGORIES:
          if (hasAwardCategories) {
            tabData = awardCategories;
          }
          break;

        case TabTypeEnum.ACCOLADES:
          if (hasAccolades) {
            tabData = accolades;
          }

          break;

        default:
          break;
      }

      if (tabData) {
        const theTab = {
          displayName,
          tabId,
          data: tabData,
        };

        showTabs.push(theTab);
      }
    }

    return showTabs;
  };

  const showTabs = generateTabsViewData(tabConfiguration);

  if (!showTabs) {
    return null;
  }

  return (
    <React.Fragment>
      <TabsView
        showTabs={showTabs}
        defaultTabIndex={defaultTabIndex}
      />
    </React.Fragment>
  );
};

export default React.memo(DetailsPart);
