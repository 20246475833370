import type { FycComponentConfigurationTypeInterface } from 'providers/fyc-theme-component-interfaces';
import type { FycVersionType } from 'providers/fyc-theme-provider';
import { VERSION_CONFIGS } from 'providers/versions/index';

interface GetVersionedComponentConfigInterface {
  component: keyof FycComponentConfigurationTypeInterface;
  selector?: string;
  version: FycVersionType;
}

export const getVersionedComponentConfig = <T>({
  version,
  component,
  selector,
}: GetVersionedComponentConfigInterface): T | undefined => {
  if (!version) {
    return undefined;
  }

  if (!selector) {
    return VERSION_CONFIGS[version]?.[component];
  }

  return VERSION_CONFIGS[version]?.[component]?.[selector];
};
