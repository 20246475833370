import type { SerializedStyles } from '@emotion/core';
import { css } from '@emotion/core';

export const wrapperCss = css`
  position: relative;
`;

export const imageContainerCss = css`
  width: 100%;
  aspect-ratio: 2 / 3;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const plainViewCss = css`
  width: 100%;
  background: linear-gradient(0.48deg, #101922 0.41%, #0f1214 84.74%);
  padding: ${32 / 16}rem 0;
  text-align: center;
`;

export const actionWrapperCss = (absolute: boolean): SerializedStyles => {
  return css`
    ${absolute ? 'position: absolute;' : ''}
    bottom: ${24 / 16}rem;
    width: ${(340 / 375) * 100}%;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  `;
};

export const maskCss = css`
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 5e-5) 0%,
      rgba(0, 0, 0, 0.00985662) 50.13%,
      rgba(0, 0, 0, 0.5) 100%
    ),
    linear-gradient(
      152.44deg,
      rgba(0, 0, 0, 5e-5) 16.4%,
      rgba(0, 0, 0, 0.00985662) 62.27%,
      rgba(0, 0, 0, 0.5) 100%
    ),
    linear-gradient(
      231.32deg,
      rgba(0, 0, 0, 8e-5) 0%,
      rgba(0, 0, 0, 0.0157706) 0%,
      rgba(0, 0, 0, 0.8) 89.63%
    );
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
`;

export const titleTreatmentContainerCss = css`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  font-weight: 600;
`;

export const titleInfoCss = css`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  flex-wrap: wrap;

  .runtime {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
    margin-right:${24 / 16}rem;


    img {
      width: 16px;
      margin-right: 10px;
    }

    span {
      margin-top: 3px;
      color: rgba(255, 255, 255, 0.87);
    }
  }

  .rating {
    border-radius: ${18 / 16}rem;
    border: 1px solid #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 2.5px;
    padding-left: 8px;
    padding-right: 8px;
    height: ${19 / 16}rem;
    line-height: ${19 / 16}rem;
`;

export const enterCodeBtnCss = css`
  height: ${44 / 16}rem;
  line-height: ${44 / 16}rem;
`;

export const progressWrapperCss = css``;

export const videoActionWrapperCss = css`
  .buttonsWrapper {
    display: flex;
    margin: 0 auto;
    justify-content: space-between;
    width: 100%;
    gap: 1rem;
  }

  .action-button-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 0;
  }

  button {
    text-transform: uppercase;
    flex: 1;
  }

  img {
    width: ${40 / 16}rem;
  }
`;

export const expiryWrapperCss = css`
  display: flex;
  justify-content: center;

  p {
    margin: 0;
  }
`;
