import React from 'react';
import DetailsPageComponent from 'components/details-page-component';
import type { NSSeriesComponentInterface } from 'models/cms';
import Typography from 'components/typography';
import { TypographyStyleTypeEnum } from 'models/enums';
import css from '@emotion/css';
import { mediaQuery } from 'utils/styles';
import theme from 'styles/theme';

export interface NsSeriesComponentPropsInterface {
  component: NSSeriesComponentInterface;
}

export interface SeriesStorageTypeInterface {
  ['id']: {
    episode: number;
    season: number;
  };
}

export interface SeriesStorageInterface {
  seriesStorage: SeriesStorageTypeInterface;
  setSeriesStorage(v: SeriesStorageTypeInterface);
}

export interface SeriesPropsInterface {
  id: string;
  storage: SeriesStorageInterface;
}

const isValidComponent = (component: NSSeriesComponentInterface): boolean => {
  if (
    !component ||
    !component.seasons?.length ||
    !component.seasons.some(
      (season) => season.episodes?.length && season.episodes.length > 0,
    )
  ) {
    return false;
  }

  return true;
};

const rootStyles = css`
  .runtime-info {
    display: none;
  }

  .rating {
    margin-left: 0;
  }

  ${mediaQuery(theme.breakpoints.md)} {
    .meta-info {
      position: relative;
      display: flex;
      flex-flow: column nowrap;
      justify-content: space-between;
    }
  }
`;

const NsSeriesComponent: React.FC<NsSeriesComponentPropsInterface> = (
  props,
) => {
  const { component } = props;
  const isValid = isValidComponent(component);

  if (!isValid) {
    return (
      <Typography style={TypographyStyleTypeEnum.SUBHEADING}>
        Please check back again later
      </Typography>
    );
  }

  // massage the data into Details component
  const newShape: NSSeriesComponentInterface = {
    ...component.seasons?.[0].episodes?.[0],
    ...component,
    keyArtImage: component.keyArtImage,
    titleTreatmentImage: component.titleTreatmentImage,
    title: component.title,
    synopsis: component.synopsis,
    awardCategories: component.awardCategories,
  };

  return (
    <DetailsPageComponent
      {...props}
      component={newShape}
      rootCss={rootStyles}
    />
  );
};

export default NsSeriesComponent;
