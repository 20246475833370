/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import {
  fetchPage,
  pageDataSelector,
  pageErrorSelector,
  pageFetchingSelector,
} from 'store/slices/page';
import { logoutWithError, tokenSelector } from 'store/slices/user';
import NsPageComponents from './ns-page-components';
import NsAppBar from '../../components/ns-app-bar';
import { appBarHeight } from 'styles/theme';
import * as routes from 'router/constants';
import { useDecodedJwt, useQuery } from 'utils/hooks';
import { Loader } from 'components';
import { LoginFailureCodesEnum } from 'models/enums';
import { useVerifyBrowserSupport } from 'containers/browser-compatibility/utils';
import ToastNotification from 'components/toast-nofication';
import ComponentContainer from 'components/component-container';
import { isDetailsPage } from './helpers';
import FooterV2 from 'components/footer-v2';

const rootCss = (theme) => ({
  background: theme.palette.background.default,
  paddingTop: appBarHeight,
  flex: 1,
});

const NsPage: React.FC = () => {
  const { accessToken } = useSelector(tokenSelector);
  const isFetching = useSelector(pageFetchingSelector);
  const pageData = useSelector(pageDataSelector);
  const error = useSelector(pageErrorSelector);
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const history = useHistory();
  const query = useQuery();
  const decodedJwt = useDecodedJwt();
  const browser = useVerifyBrowserSupport();

  const queryToken = query.get('auth');

  useEffect(() => {
    dispatch(
      fetchPage({
        slug: pathname,
        accessToken: queryToken || accessToken,
      }),
    );
  }, [pathname, dispatch, accessToken, queryToken]);

  useEffect(() => {
    if (error?.status === 401) {
      dispatch(
        logoutWithError({
          code: LoginFailureCodesEnum.TOKEN_EXPIRED,
        }),
      );
    } else if (error?.status === 404) {
      history.push(routes.NOT_FOUND);
    }
  }, [error, dispatch, history]);

  // bafta page
  if (queryToken && pageData.components) {
    return (
      <div>
        {isFetching ?
          <Loader />
        : <NsPageComponents components={pageData.components} />}
      </div>
    );
  }

  return (
    <div
      id="main-page"
      className="page"
      css={css`
        display: flex;
        flex-direction: column;
        min-height: 100vh;
      `}
    >
      {isDetailsPage(pathname) ?
        <NsAppBar
          showSettings={!!decodedJwt}
          styles={{
            appbarContainer: css`
              background: linear-gradient(
                  135.63deg,
                  rgba(8, 5, 73, 0.0001) 0%,
                  #050524 105.01%
                ),
                #060528;
              border: none;
            `,
          }}
        />
      : <NsAppBar showSettings={!!decodedJwt} />}

      <div css={rootCss}>
        {!browser.isCompatible && (
          <ComponentContainer>
            <div className="row">
              <ToastNotification
                head={browser.data?.header}
                body={browser.data?.body_message}
              />
            </div>
          </ComponentContainer>
        )}
        {isFetching ?
          <Loader />
        : pageData.components && (
            <NsPageComponents components={pageData.components} />
          )
        }
      </div>
      <FooterV2 />
    </div>
  );
};

export default NsPage;
