enum WatermarkTypeEnum {
  USERID_CENTERED = 'userid-centered',
  USERID_CENTERED_TEMPORARY = 'userid-centered-temporary',
  USERID_CENTERED_STROKE = 'userid-centered-blackstroke',
  USERID_CENTERED_TEMPORARY_STROKE = 'userid-centered-temporary-blackstroke',
  USERID_CORNER = 'userid-corner',
  USERID_CORNER_TEMPORARY = 'userid-corner-temporary',
  USERID_CORNER_ROTATING = 'userid-corner-rotating',
  USERID_CORNER_ROTATING_TEMPORARY = 'userid-corner-rotating-temporary',
  USERID_CORNER_LOWER_RIGHT = 'userid-lower-right',
  USERID_CORNER_LOWER_LEFT = 'userid-lower-left',
  USERID_CORNER_UPPER_RIGHT = 'userid-upper-right',
  USERID_CORNER_UPPER_LEFT = 'userid-upper-left',
  USERID_CORNER_LOWER_RIGHT_TEMPORARY = 'userid-lower-right-temporary',
  USERID_CORNER_LOWER_LEFT_TEMPORARY = 'userid-lower-left-temporary',
  USERID_CORNER_UPPER_RIGHT_TEMPORARY = 'userid-upper-right-temporary',
  USERID_CORNER_UPPER_LEFT_TEMPORARY = 'userid-upper-left-temporary',
  USERID_LOWER_TEMPORARY_STROKE = 'userid-lower3rd-temporary-blackstroke',
  USERID_LOWER_STROKE = 'userid-lower3rd-blackstroke',
  USERID_LOWER = 'userid-lower3rd',
  USERID_LOWER_TEMPORARY = 'userid-lower3rd-temporary',
  USERID_DIAGONAL_ONE_MINI_WHITESTROKE = 'userid-diagonal-one-mini-whitestroke',
  LOGO_CORNERS = 'logo-corners',
  LOGO_CORNERS_TEMPORARY = 'logo-corners-temporary',
  LOGO_STATIONARY = 'logo-stationary',
  LOGO_STATIONARY_TEMPORARY = 'logo-stationary-temporary',
  NAME_DATE_UPPER_RIGHT = 'name-date-upper-right',
}

export default WatermarkTypeEnum;
