/** @jsx jsx */
import { jsx, ClassNames, css } from '@emotion/core';
import type { NSTextComponentInterface } from 'models/cms';
import React from 'react';
import Markdown from 'react-markdown/with-html';
import theme, { appBarHeight } from 'styles/theme';
import { mediaQuery } from 'utils/styles';

interface PropsInterface {
  component: NSTextComponentInterface;
  styles?: any;
}

const divCss = css`
  min-height: calc(
    100vh - ${theme.height.footer.minHeight.md} - ${appBarHeight}px
  );
`;

const defaultCss = `
  font-family: ${theme.typography.fontFamily};
  padding: 1rem ${theme.padding.sides.xs} 3.5rem ${theme.padding.sides.xs};
  margin: ${theme.margin.top.xs} 0;
  ${mediaQuery(theme.breakpoints.md)} {
    margin: ${theme.margin.top.md} 0;
    padding: 0 0 0 ${theme.padding.sides.md};
    width: 76%;
  }
  ${mediaQuery(theme.breakpoints.lg)} {
    margin: ${theme.margin.top.lg} 0;
    padding: 0 0 0 ${theme.padding.sides.lg};
  }

  h1 {
    font-size: ${34 / 16}rem;
    font-weight: 300;
    margin: 2rem 0 3.5rem 0;
  }

  h2 {
    font-size: 1.5rem;
    margin: 2rem 0;
  }

  h3, h4 {
    font-size: 1.19rem;
    margin: 2rem 0;
  }

  h4 {
    font-weight: 400;
  }

  p, ul {
    margin-bottom: 1.19rem;
  }

  p, li {
    color: ${theme.palette.text.secondary};
    line-height: 1.5rem;
  }

  hr {
    border: ${theme.border.light};
    margin: 0.5rem 0;
  }
`;

const NsTextComponent: React.FC<PropsInterface> = (props: PropsInterface) => {
  return (
    <ClassNames>
      {({ css }) => {
        const defaultStyle = css`
          ${defaultCss}
          ${props?.styles}
        `;

        return (
          <div css={divCss}>
            <Markdown
              className={defaultStyle}
              source={props.component.text}
              escapeHtml={false}
            />
          </div>
        );
      }}
    </ClassNames>
  );
};

export default NsTextComponent;
