/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import type { NSEntitlementDetailsComponentInterface } from 'models/cms';
import React from 'react';
import NsImage from 'components/ns-component/ns-image';
import { useComponentTypeFinder } from 'utils/hooks';
import { ComponentTypeEnum } from 'models/enums';

const imageContainerCss = (isPlain: boolean) => {
  const bg =
    isPlain &&
    `background: linear-gradient(-180deg, #09090d 0%, #09090d 100%);`;
  return css`
    width: ${110 / 16}rem;
    height: ${60.8 / 16}rem;
    overflow: hidden;
    border-radius: ${6 / 16}rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    ${bg}

    p {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 0;
      font-size: ${10 / 16}rem;
    }
  `;
};

const nsImageContainerCss = (isPlain: boolean) => {
  if (isPlain) {
    return css`
      width: ${110 / 16}rem;
      height: ${60.8 / 16}rem;
      border-radius: ${6 / 16}rem;
      display: flex;
      justify-content: center;
      align-items: center;
      transform: Scale(0.7);
    `;
  }

  return css`
    width: ${110 / 16}rem;
    height: ${60.8 / 16}rem;
    border-radius: ${6 / 16}rem;
  `;
};

interface PropsInterface {
  item: any;
  radio?: string;
}

const SearchImage: React.FC<PropsInterface> = ({
  item,
  radio = '16/9',
}: PropsInterface) => {
  const entitlementDetails =
    useComponentTypeFinder<NSEntitlementDetailsComponentInterface>(
      ComponentTypeEnum.ENTITLEMENT_DETAILS,
    );

  const imgInfo =
    entitlementDetails?.hasPlain ? item.titleTreatmentImage : item.keyArtImage;

  const title =
    entitlementDetails?.hasPlain && !item.titleTreatmentImage && item.title;

  return (
    /* @ts-ignore TODO: TS2345: Argument of type boolean | undefined is not assignable to parameter of type boolean. */
    <div css={imageContainerCss(entitlementDetails?.hasPlain)}>
      {title ?
        <p>{title}</p>
      : <NsImage
          styles={{
            nsImageContainerCss: nsImageContainerCss(
              /* @ts-ignore TODO: TS2345: Argument of type boolean | undefined is not assignable to parameter of type boolean. */
              entitlementDetails?.hasPlain,
            ),
          }}
          url={imgInfo?.url}
          aspectRatio={radio}
          alt={imgInfo?.alt}
        />
      }
    </div>
  );
};

export default SearchImage;
