/* istanbul ignore file */
import React, { useState, useContext, useEffect } from 'react';
import { getStringsApi } from 'api';

interface PropsInterface {
  children: React.ReactNode;
}

export interface ContextPropsInterface {
  config: { key?: string; value?: string }[];
  getConfigByKey(key: string): string | undefined;
  getStringByKey(key: string): string | undefined;
  getStrings?: any;
  isLoading: boolean;
  strings: { key?: string; value?: string }[];
}

export const StringsContext = React.createContext<ContextPropsInterface>({
  strings: [],
  config: [],
  getStringByKey: () => undefined,
  getConfigByKey: () => undefined,
  isLoading: true,
});

export const useStringsContext = () => useContext(StringsContext);

export const STRING_KEYS = {
  PRIVACY_TC_MSG: 'PRIVACY_TC_MSG',
  PRIVACY_TC_VPPA_MSG: 'PRIVACY_TC_VPPA_MSG',
  APP_STORE_BUILD_URL: 'APP_STORE_BUILD_URL',
  AUTH_LOGIN_EMAIL_INVALID: 'AUTH_LOGIN_EMAIL_INVALID',
  AUTH_LOGIN_EMAIL_ERROR: 'AUTH_LOGIN_EMAIL_ERROR',
  AUTH_RESET_PASSWORD: 'AUTH_RESET_PASSWORD',
  AUTH_LOGIN_CODE: 'AUTH_LOGIN_CODE',
  AUTH_LOGIN_EXPIRED: 'AUTH_LOGIN_EXPIRED',
  AUTH_LOGIN_CODE_ERROR: 'AUTH_LOGIN_CODE_ERROR',
  AUTH_LOGIN_CODE_EXPIRED: 'AUTH_LOGIN_CODE_EXPIRED',
  AUTH_LOGIN_START: 'AUTH_LOGIN_START',
  AUTH_FORGOT_PW_INVALID: 'AUTH_FORGOT_PW_INVALID',
  PLAY_TITLE_ERROR: 'PLAY_TITLE_ERROR',
  GEN_404_ERROR: 'GEN_404_ERROR',
  GEN_BROWSER_VERSION_UNSUPPORTED: 'GEN_BROWSER_VERSION_UNSUPPORTED',
  GEN_COPYRIGHT: 'GEN_COPYRIGHT',
  AUTH_WELCOME: 'AUTH_WELCOME',
  AUTH_WELCOME_INVALID_PW: 'AUTH_WELCOME_INVALID_PW',
  AUTH_WELCOME_INVALID_TOKEN: 'AUTH_WELCOME_INVALID_TOKEN',
  AUTH_WELCOME_SUBMISSION_FAIL: 'AUTH_WELCOME_SUBMISSION_FAIL',
  AUTH_WELCOME_SUCCESS: 'AUTH_WELCOME_SUCCESS',
  AUTH_WELCOME_PW_HINT: 'AUTH_WELCOME_PW_HINT',
  AUTH_WELCOME_SUCCESS_TITLE: 'AUTH_WELCOME_SUCCESS_TITLE',
  AUTH_FORGOT_PW_INVALID_DISNEY: 'AUTH_FORGOT_PW_INVALID_DISNEY',
  AUTH_RESET_PASSWORD_SUCCESS_DESCRIPTION:
    'AUTH_RESET_PASSWORD_SUCCESS_DESCRIPTION',
  AUTH_LOGIN_INACTIVE: 'AUTH_LOGIN_INACTIVE',
  PLAY_CONCURRENCY_ERROR: 'PLAY_CONCURRENCY_ERROR',
  AUTH_TWO_FACTOR_AUTH_ENTER_CODE_CTA: 'AUTH_TWO-FACTOR-AUTH_ENTER-CODE_CTA',
  AUTH_TWO_FACTOR_AUTH_ENTER_CODE_DESC: 'AUTH_TWO-FACTOR-AUTH_ENTER-CODE_DESC',
  AUTH_TWO_FACTOR_AUTH_ENTER_CODE_LOGIN_CTA:
    'AUTH_TWO-FACTOR-AUTH_ENTER-CODE_LOGIN-CTA',
  AUTH_TWO_FACTOR_AUTH_ENTER_CODE_TITLE:
    'AUTH_TWO-FACTOR-AUTH_ENTER-CODE_TITLE',
  AUTH_TWO_FACTOR_AUTH_ENTER_CODE_TITLE2:
    'AUTH_TWO-FACTOR-AUTH_ENTER-CODE_TITLE2',
  AUTH_TWO_FACTOR_AUTH_INSTALL_CTA: 'AUTH_TWO-FACTOR-AUTH_INSTALL_CTA',
  AUTH_TWO_FACTOR_AUTH_INSTALL_DESC: 'AUTH_TWO-FACTOR-AUTH_INSTALL_DESC',
  AUTH_TWO_FACTOR_AUTH_INSTALL_DESC2: 'AUTH_TWO-FACTOR-AUTH_INSTALL_DESC2',
  AUTH_TWO_FACTOR_AUTH_INSTALL_TITLE: 'AUTH_TWO-FACTOR-AUTH_INSTALL_TITLE',
  AUTH_TWO_FACTOR_AUTH_INSTALL_TITLE2: 'AUTH_TWO-FACTOR-AUTH_INSTALL_TITLE2',
  AUTH_TWO_FACTOR_AUTH_SCANQR_CTA: 'AUTH_TWO-FACTOR-AUTH_SCANQR_CTA',
  AUTH_TWO_FACTOR_AUTH_SCANQR_DESC: 'AUTH_TWO-FACTOR-AUTH_SCANQR_DESC',
  AUTH_TWO_FACTOR_AUTH_SCANQR_TITLE: 'AUTH_TWO-FACTOR-AUTH_SCANQR_TITLE',
  AUTH_TWO_FACTOR_AUTH_SCANQR_TITLE2: 'AUTH_TWO-FACTOR-AUTH_SCANQR_TITLE2',
  AUTH_TWO_FACTOR_AUTH_SETUP_CTA: 'AUTH_TWO-FACTOR-AUTH_SETUP_CTA',
  AUTH_TWO_FACTOR_AUTH_SETUP_DESC: 'AUTH_TWO-FACTOR-AUTH_SETUP_DESC',
  AUTH_TWO_FACTOR_AUTH_SETUP_TITLE: 'AUTH_TWO-FACTOR-AUTH_SETUP_TITLE',
  PLAYBACK_ACCEPT: 'PLAYBACK_ACCEPT',
  NDA_LEAVING: 'NDA_LEAVING',
  AUTH_PLATE_ACTIVATION: 'AUTH_PLATE_ACTIVATION',
  AUTH_PLATE_ACTIVATION_ERROR: 'AUTH_PLATE_ACTIVATION_ERROR',
  AUTH_PLATE_ACTIVATION_SUCCESS: 'AUTH_PLATE_ACTIVATION_SUCCESS',
  MOBILE_FEATURE_UNAVAILABLE: 'MOBILE_FEATURE_UNAVAILABLE',
  BROWSER_UNSUPPORTED: 'BROWSER_UNSUPPORTED',
  MOBILE_FULLSCREEN_UNAVAILABLE: 'MOBILE_FULLSCREEN_UNAVAILABLE',
  PRIVACY_POLICY: 'PRIVACY_POLICY',
  SSO_SIGNING_IN_TV_ACADEMY: 'SSO_SIGNING_IN_TV_ACADEMY',
  SSO_SIGN_IN_FAIL: 'SSO_SIGN_IN_FAIL',
  AUTH_2FA_FAIL: 'AUTH_2FA_FAIL',
  AUTH_2FA_FAIL_X2: 'AUTH_2FA_FAIL_X2',
  AUTH_2FA_APP_LABEL: 'AUTH_2FA_APP_LABEL',
  PLAY_EXPIRING_TOKEN: 'PLAY_EXPIRING_TOKEN',
  ERROR_ACCOUNT_DISABLED: 'ERROR_ACCOUNT_DISABLED',
  UNSUPPORTED_BROWSERS_MSG: 'UNSUPPORTED_BROWSERS_MSG',
  UNSUPPORTED_WATERMARK_ERROR: 'UNSUPPORTED_WATERMARK_ERROR',
  EXPIRED_ENTITLEMENT_TITLE: 'EXPIRED_ENTITLEMENT_TITLE',
  EXPIRED_ENTITLEMENT: 'EXPIRED_ENTITLEMENT',
  WEB_VPPA_TC_BTN: 'WEB_VPPA_TC_BTN',
  WEB_VPPA_TC_TITLE: 'WEB_VPPA_TC_TITLE',
  FYC_FOOTER_LINKS: 'FYC_FOOTER_LINKS',
  FYC_FOOTER_TERMS_LABEL: 'FYC_FOOTER_TERMS_LABEL',
  RELATED_CONTENT_TAB_LABEL: 'RELATED_CONTENT_TAB_LABEL',
  AVENTRI_ERROR_TITLE: 'AVENTRI_ERROR_TITLE',
  AVENTRI_ERROR_DESCRIPTION: 'AVENTRI_ERROR_DESCRIPTION',
  iOS_WEB_I_AGREE_VPPA: 'iOS_WEB_I_AGREE_VPPA',
  IOS_WEB_SCROLL_TC_VPPA: 'IOS_WEB_SCROLL_TC_VPPA',
  iOS_WEB_I_AGREE_TC: 'iOS_WEB_I_AGREE_TC',
  WEB_FOOTER_LINKS: 'WEB_FOOTER_LINKS',
  WEB_COPPA_FOOTER_LINKS: 'WEB_COPPA_FOOTER_LINKS',
  CHROMECAST_ONBOARDING_TITLE: 'CHROMECAST_ONBOARDING_TITLE',
  FYC_RSVP_CARD_THEME: 'FYC_RSVP_CARD_THEME',
};

export const CONFIG_KEYS = {
  APP_TEXT_COLOR: 'APP_TEXT_COLOR',
  APP_BACKGROUND_COLOR1: 'APP_BACKGROUND_COLOR1',
  APP_BACKGROUND_COLOR2: 'APP_BACKGROUND_COLOR2',
  CARD_BACKGROUND_COLOR: 'CARD_BACKGROUND_COLOR',
  UNSUPPORTED_BROWSERS_LIST: 'UNSUPPORTED_BROWSERS_LIST',
  FF_SEARCH_WEB: 'FF_SEARCH_WEB',
  FF_CHROMECAST_WEB: 'FF_CHROMECAST_WEB',
  FF_CHROMECAST_FYC_BONUS_WEB: 'FF_CHROMECAST_FYC_BONUS_WEB',
  LEGAL_FYC_ONETRUST_SCRIPT_ID_WEB: 'LEGAL_FYC_ONETRUST_SCRIPT_ID_WEB',
  FF_FYC_PORTALS: 'FF_FYC_PORTALS',
  PRIVACY_TC_UPDATED_DATE: 'PRIVACY_TC_UPDATED_DATE',
  PRIVACY_TC_CONSENT_INTERVAL_DAYS: 'PRIVACY_TC_CONSENT_INTERVAL_DAYS',
  PAGE_BACKGROUND_COLOR: 'PAGE_BACKGROUND_COLOR',
  PAGE_GRADIENT_CONFIG_WEB: 'PAGE_GRADIENT_CONFIG_WEB',
  PRIMARY_COLOR: 'PRIMARY_COLOR',
  PRIMARY_HOVER_COLOR: 'PRIMARY_HOVER_COLOR',
  SECONDARY_COLOR: 'SECONDARY_COLOR',
  SECONDARY_HOVER_COLOR: 'SECONDARY_HOVER_COLOR',
  SUCCESS_COLOR: 'SUCCESS_COLOR',
  ERROR_COLOR: 'ERROR_COLOR',
  WARNING_COLOR: 'WARNING_COLOR',
  CARD_GRADIENT_COLOR_WEB: 'CARD_GRADIENT_COLOR_WEB',
  CHROMECAST_ONBOARDING_ENABLED: 'CHROMECAST_ONBOARDING_ENABLED',
  FF_CHROME_UI_ISSUE: 'FF_CHROME_UI_ISSUE',
};

const StringsProvider: React.FC<PropsInterface> = ({ children }) => {
  const [strings, setStrings] = useState<ContextPropsInterface['strings']>([]);
  const [config, setConfig] = useState<ContextPropsInterface['config']>([]);
  const [isLoading, setIsLoading] =
    useState<ContextPropsInterface['isLoading']>(true);

  const getStrings = async () => {
    const query = `{
      strings {
        key
        value
      }
      clientconfig {
        key
        value
      }
    }`;

    const res = await getStringsApi(query);

    // reduce strings array to object
    const reducedStrings = res.body.data?.strings?.reduce((acc, cur) => {
      acc[cur.key] = cur.value;
      return acc;
    }, {});

    const reducedConfig = res.body.data?.clientconfig?.reduce((acc, cur) => {
      acc[cur.key] = cur.value;
      return acc;
    }, {});

    setStrings(reducedStrings);
    setConfig(reducedConfig);
    setIsLoading(false);
  };

  useEffect(() => {
    getStrings();
  }, []);

  const getStringByKey = (key: string): string => strings[key] || '';
  const getConfigByKey = (key: string): string => config[key] || '';

  return (
    <StringsContext.Provider
      value={{
        strings,
        config,
        getConfigByKey,
        getStringByKey,
        isLoading,
        getStrings,
      }}
    >
      {children}
    </StringsContext.Provider>
  );
};

export default StringsProvider;
