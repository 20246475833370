/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { DropdownIndicator } from 'components/details-page-component/movie-details-related';
import React from 'react';
import type { ItemPropsInterface } from '../item';
import Item from '../item';
import { wrapperCss } from './styles';
import Select from 'react-select';
import { FYC_MOBILE_WIDTH } from 'pages/fyc-page/helpers';
import { mediaQuery } from 'utils/styles';
import { selectStyles } from 'components/details-page-component/styles';

export interface ListPropsInterface {
  listData: ItemPropsInterface[];
  selectData?: any;
}

const selectContainerCss = css`
  width: 100%;
  margin-bottom: 2rem;

  ${mediaQuery(FYC_MOBILE_WIDTH)} {
    max-width: ${270 / 16}rem;
  }
`;

const List: React.FC<ListPropsInterface> = ({ listData, selectData }) => {
  return (
    <div css={wrapperCss}>
      {selectData?.show && (
        <div
          css={selectContainerCss}
          data-testid="drop-down-selector-container"
        >
          <Select
            onChange={({ value }) => selectData.onChange(value)}
            options={selectData.options}
            components={{ DropdownIndicator }}
            styles={selectStyles('')}
            value={selectData.value}
          />
        </div>
      )}
      {listData.map((item) => (
        <Item
          key={item.id}
          item={item}
        />
      ))}
    </div>
  );
};

export default List;
