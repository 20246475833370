/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useEffect, useState } from 'react';
import Card from './card';
import { containerCss } from './styles';
import { getSwoogoEvents } from 'api';
import Loader from 'components/loader';

interface PropsInterface {
  folderId: string;
}

export interface SwoogoEventTypeInterface {
  description: string;
  domain: string;
  folder_id: number;
  id: number;
  location: {
    city: string;
    line_1: string;
    name: string;
    state: string;
    zip: string;
  };
  start_date: string;
  start_time: string;
  timezone: string;
  url: string;
}

const RSVPList: React.FC<PropsInterface> = ({ folderId }) => {
  const [swoogoData, setSwoogoData] = useState<SwoogoEventTypeInterface[]>([]);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    const getEvents = async () => {
      setIsFetching(true);
      try {
        const { body } = await getSwoogoEvents(folderId);
        setSwoogoData(body);
        setIsFetching(false);
      } catch (error) {
        console.error(error);
        setIsFetching(false);
      }
    };

    getEvents();
  }, [folderId]);

  return (
    <React.Fragment>
      {isFetching && <Loader />}
      <div css={containerCss}>
        {swoogoData?.length > 0 &&
          swoogoData.map((data) => (
            <Card
              key={data.id}
              eventData={data}
            />
          ))}
      </div>
    </React.Fragment>
  );
};

export default RSVPList;
