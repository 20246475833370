/** @jsx jsx */
import { jsx } from '@emotion/core';
import type { InputPropsInterface } from 'components/input';
import type {
  NSEntitlementItemInterface,
  NSEntitlementsComponentInterface,
  NSPageComponentInterface,
} from 'models/cms';
import type { ChangeEvent } from 'react';
import { useCallback } from 'react';
import React, { useEffect, useState, useRef } from 'react';
import { Input } from 'components';
import SearchResults from 'components/search-bar/search-results';
import {
  wrapperCss,
  containerCss,
  inputCss,
} from 'components/search-bar/styles';
import deleteIcon from 'public/images/X_Circle.svg';
import magnifierIconBlur from 'public/images/Magnifier_Glass.svg';
import magnifierIconFocus from 'public/images/Magnifier_Glass_2.svg';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { tokenSelector } from 'store/slices/user';
import { getSearchResults } from 'api';
import { ComponentTypeEnum } from 'models/enums';
import { useLocation } from 'react-router-dom';

type HandleSearchTermChangeType =
  | ChangeEvent<HTMLInputElement>
  | { target: { value: string } };

const SearchBar: React.FC = () => {
  const { accessToken } = useSelector(tokenSelector);
  const [isFocused, setIsFocused] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const [searchResults, setSearchResults] = useState<
    NSEntitlementItemInterface[]
  >([]);

  const [filteredResults, setFilteredResults] = useState<
    NSEntitlementItemInterface[]
  >([]);

  const [showSearchResults, setShowSearchResults] = useState(false);
  const [showNoResults, setShowNoResults] = useState(false);
  const [canSearch, setCanSearch] = useState(false);
  const [hideSearchResults, setHideSearchResults] = useState(false);
  const [resultsViewClicked, setResultsViewClicked] = useState(false);
  const location = useLocation();
  const isMounted = useRef(false);

  const handleSearchTermChange = useCallback(
    (ev: HandleSearchTermChangeType) => {
      const term = ev.target.value;
      setSearchTerm(term);

      // remove all whitespace
      if (term.replace(/\s+/g, '') === '') {
        setFilteredResults([]);
        setShowSearchResults(false);
        setShowNoResults(false);
        return;
      }

      // get filtered results
      const filtered = searchResults.filter((result) => {
        const reformattedTerm = term.toLowerCase().replace(/\s+/g, '');
        const formattedTitle =
          result.title?.toLowerCase().replace(/\s+/g, '') ?? '';
        return formattedTitle.includes(reformattedTerm);
      });

      setFilteredResults(filtered || []);

      // logics to show results (with content)
      if (!_.isEmpty(filtered || [])) {
        setShowSearchResults(true);
        setShowNoResults(false);
        return;
      }

      // logics to show results (without content)
      setShowSearchResults(false);
      setShowNoResults(true);
    },
    [searchResults],
  );

  const handlerIconClick = () => {
    setSearchTerm('');
    setFilteredResults([]);
    setShowSearchResults(false);
    setShowNoResults(false);
  };

  useEffect(() => {
    let cancel = false;

    const fetchSearchResults = async () => {
      if (!accessToken) {
        return [];
      }

      const { body } = await getSearchResults(accessToken);

      if (_.isEmpty(body?.components)) {
        return [];
      }

      const component = (body.components as NSPageComponentInterface[]).find(
        (component) => component.type === ComponentTypeEnum.ENTITLEMENTS,
      ) as NSEntitlementsComponentInterface | undefined;

      const items = component?.items?.filter((item) => {
        const tags = item.entitlement?.tags || [];
        return !tags.includes('viewer');
      });

      if (!cancel) {
        setSearchResults(items ?? []);
        setCanSearch(true);
      }
    };

    fetchSearchResults().catch((e) => console.error(e));

    return () => {
      cancel = true;
    };
  }, [accessToken]);

  /*
   * handle hide search results view
   * */
  useEffect(() => {
    if (isMounted.current) {
      if (isFocused || resultsViewClicked) {
        setHideSearchResults(false);
      }
      if (!isFocused && !resultsViewClicked) {
        setHideSearchResults(true);
      }
    } else {
      isMounted.current = true;
    }
  }, [isFocused, resultsViewClicked]);

  useEffect(() => {
    if (isMounted.current) {
      if (!resultsViewClicked) {
        setSearchTerm('');
        handleSearchTermChange({ target: { value: '' } });
      }
      if (resultsViewClicked) {
        setHideSearchResults(true);
      }
    } else {
      isMounted.current = true;
    }
  }, [handleSearchTermChange, location, resultsViewClicked]);

  const inputIconConfig: InputPropsInterface['icon'] =
    searchTerm !== '' ?
      {
        src: deleteIcon,
        onClick: () => {
          handlerIconClick();
        },
        'data-testid': 'search-term-clear',
      }
    : undefined;

  return (
    <div
      className={'search-bar'}
      css={wrapperCss}
    >
      {canSearch && (
        <Input
          placeholder={'Search title or show'}
          styles={{
            containerCss,
            inputCss,
          }}
          value={searchTerm}
          onChange={(ev) => handleSearchTermChange(ev)}
          onBlur={() => setIsFocused(false)}
          onFocus={() => {
            setResultsViewClicked(false);
            setIsFocused(true);
          }}
          name={'search'}
          icon={inputIconConfig}
          labelIcon={{
            src: isFocused ? magnifierIconFocus : magnifierIconBlur,
          }}
          data-testid="search-field"
        />
      )}

      <SearchResults
        hide={hideSearchResults}
        setResultsViewClicked={setResultsViewClicked}
        inputFocused={isFocused}
        showSearchResults={showSearchResults}
        showNoResults={showNoResults}
        searchTerm={searchTerm}
        filteredResults={filteredResults}
      />
    </div>
  );
};

export default SearchBar;
