/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { EntitlementTypeEnum } from 'models/enums';
import React from 'react';
import {
  wrapperCss,
  imageContainerCss,
  plainViewCss,
  actionWrapperCss,
  titleInfoCss,
  maskCss,
  titleTreatmentContainerCss,
  progressWrapperCss,
  videoActionWrapperCss,
  expiryWrapperCss,
} from './styles';
import NsImage from 'components/ns-component/ns-image';
import clock from 'public/images/Clock.svg';
import { minsToTime, numOfSeasons } from 'utils';
import VideoActions from 'components/video-actions';
import TvEpisodicIcon from 'public/images/TV_Episodic.svg';
import { showProxisMsg } from '../helpers';
import type { NDAPropsInterface } from '../large-banner';
import { progressDataSelector } from 'store/slices/progress';
import { useSelector } from 'react-redux';
import ProgressBar from 'components/progress-bar';
import Loader from 'components/loader';
import ExpiryDate from 'components/expiry-date';
import ExpiredModal from 'components/expired-modal';
import { usePlayer } from 'providers/player-provider';

interface PropsInterface {
  data: any;
  hasPlain: boolean;
  ndaProps?: NDAPropsInterface;
}

const SmallBanner: React.FC<PropsInterface> = ({
  data,
  ndaProps,
  hasPlain,
}) => {
  const progressData = useSelector(progressDataSelector);
  const { isCheckingExpired, isExpired } = usePlayer();

  const componentType = data?.type.toLowerCase();

  const actionPart = (absolute: boolean) => (
    <div css={actionWrapperCss(absolute)}>
      <div css={titleTreatmentContainerCss}>
        {data.titleTreatmentImage?.url ?
          <NsImage
            url={data.titleTreatmentImage?.url}
            width={1200}
            aspectRatio={'16/9'}
            hasGradientOverlay={false}
          />
        : <div data-testid="title-treatment-item">{data.title}</div>}
      </div>
      <div css={titleInfoCss}>
        {data?.entitlement?.docType === 'movie' ?
          typeof data.runtime === 'number' &&
          data.runtime > 0 && (
            <div className="runtime">
              <img src={clock} />
              <span>{minsToTime(data.runtime)}</span>
            </div>
          )
        : <div className="runtime">
            <img
              src={TvEpisodicIcon}
              className="clock-icon"
            />
            <span>{numOfSeasons(data)}</span>
          </div>
        }
        {data.rating && <div className="rating">{data.rating}</div>}
      </div>

      <div css={expiryWrapperCss}>
        {isCheckingExpired && <Loader />}
        <ExpiredModal isOpen={isExpired} />
        <ExpiryDate entitlement={data.entitlement} />
      </div>

      {progressData[data.bookmark?.docId]?.progress && (
        <div
          className="progressWrapper"
          css={progressWrapperCss}
        >
          {componentType !== EntitlementTypeEnum.SERIES ?
            data.bookmark &&
            data.bookmark?.progress > 0 &&
            data.bookmark?.progress < 0.96 && (
              <ProgressBar
                progress={progressData[data.bookmark.docId]?.progress ?? 0}
                fullWidth={true}
              />
            )
          : null}
        </div>
      )}

      <div
        className="videoAction"
        css={videoActionWrapperCss}
      >
        {componentType !== EntitlementTypeEnum.SERIES && (
          <VideoActions
            component={data}
            progressData={progressData}
            ndaProps={ndaProps}
            icon={true}
          />
        )}
      </div>

      {showProxisMsg(data.entitlement.tags) && (
        <div
          css={css`
            font-size: ${12 / 16}rem;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
          `}
        >
          For the highest quality of this content, please watch on the Debut app
          available on Apple tvOS
        </div>
      )}
    </div>
  );

  return (
    <div css={wrapperCss}>
      {hasPlain ?
        <div css={plainViewCss}>{actionPart(false)}</div>
      : <div>
          <div css={imageContainerCss}>
            {data.heroImage?.url && (
              <NsImage
                url={data.heroImage?.url}
                width={1500}
                aspectRatio={'2/3'}
                isBackground
                hasGradientOverlay={false}
              />
            )}
          </div>
          <div css={maskCss} />
          {actionPart(true)}
        </div>
      }
    </div>
  );
};

export default SmallBanner;
