import type { SerializedStyles } from '@emotion/core';
import { css } from '@emotion/core';
import theme from 'styles/theme';

export const wmContainerCss = css`
  padding: 0 12%;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;

  .watermark-text {
    margin: auto;
    text-align: center;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .stroke-black {
    color: rgba(255, 255, 255, 0.3);
    -webkit-text-stroke: 0.125rem rgba(0, 0, 0, 0.3);
  }

  .diagonal-userid,
  .diagonal-userid--mini {
    color: rgba(255, 255, 255, 0.28);
    -webkit-text-stroke: 0.125rem rgba(255, 255, 255, 0.75);
  }

  &.watermark-container--textfit {
    width: 85%;
    height: 85%;

    &.nameDateTopRightCss {
      margin: unset;
      left: 42%;
      top: -27%;
      .watermark-textfit {
        height: 5%;
        width: 40%;
      }
    }

    &.watermark-container--diagonal {
      .watermark-textfit {
        height: 33%;
      }
    }

    &.lower-third {
      position: absolute;
      transform: translate(-50%, -50%);
      top: 70%;
      left: 50%;
      margin: unset;
    }

    &.userIdCornerTopRightCss {
      margin: unset;
      left: 23%;
      top: -20%;
    }

    &.userIdCornerTopLeftCss {
      margin: unset;
      left: -8%;
      top: -20%;
    }

    &.userIdCornerBottomLeftCss {
      margin: unset;
      left: -8%;
      top: 35%;
    }

    &.userIdCornerBottomRightCss {
      margin: unset;
      left: 23%;
      top: 35%;
    }
  }

  &.watermark-container--textfit-mini {
    width: 80%;
    height: 80%;

    &.watermark-container--diagonal-mini {
      top: 12%;
      left: 9.5%;
      width: 50%;
      height: 10%;
      margin: unset;
    }
  }

  .watermark-textfit {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const rotateCss = (): SerializedStyles => css`
  transform: rotate(-25deg);
`;

export const textFitCss = css`
  opacity: 0.3;
  font-weight: ${theme.typography.fontWeightLight};
`;

export const logoContainerCss = css`
  position: absolute;
  opacity: 0.3;
  letter-spacing: 0.5rem;
  max-width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

// rotate text to 9pm clockface position
export const logoTextCss = css`
  width: 100%;
  transform: rotate(160deg);
`;

export const logoCss = css`
  width: 100px;
  margin: auto;
  position: absolute;
`;

export const logoImageCss = css`
  height: auto !important;
`;

export const topRightCss = css`
  top: 16%;
  right: 5%;
`;

export const topLeftCss = css`
  top: 16%;
  left: 5%;
`;

export const bottomLeftCss = css`
  bottom: 16%;
  left: 5%;
`;

export const bottomRightCss = css`
  bottom: 16%;
  right: 5%;
`;

export const watermarkVisiblityCss = (isVisible) => {
  const style = css`
    display: ${isVisible ? 'flex' : 'none'};
  `;

  return style;
};
