import type { SerializedStyles } from '@emotion/core';
import { css } from '@emotion/core';

const warningColor = '#FAC266';

export const expiryText = css`
  font-size: 0.9rem;
  font-weight: 300;
  line-height: 20px;
`;

export const expiryContainerRootCss = css`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  .separator {
    margin: 0 0.25rem;
  }
`;

export const iconSpanCss = css`
  display: inline-block;
  margin-right: ${4.75 / 16}rem;
  height: ${20 / 16}rem;
  width: ${16.5 / 16}rem;

  img {
    width: ${16.5 / 16}rem;
    vertical-align: text-top;
  }
`;

export const playsSpanCss = (warning: boolean): SerializedStyles => {
  return css`
    color: ${warning ? warningColor : ''};
    margin-left: 0;
  `;
};

export const dateSpanCss = (warning: boolean): SerializedStyles => {
  return css`
    color: ${warning ? warningColor : ''};
  `;
};
