import type { SerializedStyles } from '@emotion/core';
import { css } from '@emotion/core';
import { FYC_MOBILE_WIDTH, FYC_TABLET_WIDTH } from 'pages/fyc-page/helpers';
import { mediaQuery } from 'utils/styles';

export const footerCss = css`
  overflow-y: hidden;

  margin-top: ${56 / 16}rem;
  padding: 0 1rem;

  ${mediaQuery(FYC_MOBILE_WIDTH)} {
    padding: 0 2rem;
  }

  ${mediaQuery(FYC_TABLET_WIDTH)} {
    padding: 0 ${74 / 16}rem;
  }
`;

export const contactCss = (): SerializedStyles => {
  const fontWeight = 500;
  const fontSize = `${14 / 16}rem`;

  return css`
    margin-bottom: ${18 / 16}rem;
    font-weight: ${fontWeight};
    font-size: ${fontSize};
    line-height: ${18 / 16}rem;
    text-transform: uppercase;
    .contact-us {
      margin-right: ${23 / 16}rem;
    }
  `;
};

export const wrapperCss = (): SerializedStyles => {
  return css`
    display: flex;
    flex-direction: column;
    width: 100%;

    .linksPart {
      margin-right: ${3 / 16}rem;
    }

    ${mediaQuery(FYC_MOBILE_WIDTH)} {
      flex-direction: row;
      justify-content: space-between;
    }
  `;
};

export const tosCss = css`
  a,
  span {
    text-decoration: none;
    text-transform: uppercase;
    font-size: ${10 / 16}rem;
    line-height: ${12 / 16}rem;
    margin-bottom: ${20 / 16}rem;
    color: rgba(255, 255, 255, 0.67);
  }

  ${mediaQuery(FYC_MOBILE_WIDTH)} {
    max-width: ${533 / 16}rem;
  }

  ${mediaQuery(FYC_TABLET_WIDTH)} {
    max-width: initial;
  }
  display: flex;
  flex-wrap: wrap;
  gap: ${4 / 16}rem;

  #ot-sdk-btn.ot-sdk-show-settings {
    color: rgba(255, 255, 255, 0.67);
    border: none;
    padding: 0;
    font-size: ${10 / 16}rem;
    line-height: ${12 / 16}rem;
    transition: none;
    :hover {
      color: rgba(255, 255, 255, 0.67);
      background: none;
    }
  }
`;
