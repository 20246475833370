import React from 'react';

import { LoginFailureCodesEnum } from 'models/enums';
import { STRING_KEYS, useStringsContext } from 'providers/strings-provider';
import Alert from 'components/alert';

export interface NsErrorInterface {
  code: string;
  message?: string;
}

export interface ErrorModalPropsInterface {
  error: NsErrorInterface;
  onClick(): void;
}

/**
 * Displays an error modal
 *
 * This modal doesn't use a state to decide if it should be open or not.
 * To close this modal, the onClick action should clear the error that is being passed to this component
 */
const ErrorModal: React.FC<ErrorModalPropsInterface> = ({ error, onClick }) => {
  const { getStringByKey } = useStringsContext();
  let title, infoText;
  switch (error.code) {
    case LoginFailureCodesEnum.SESSION_EXPIRED:
      title = 'An error occurred!';
      infoText = error.message;
      break;
    case LoginFailureCodesEnum.SSO_UNAUTHORIZED:
      title = 'Unable to sign in';
      infoText = getStringByKey(STRING_KEYS.SSO_SIGN_IN_FAIL);
      break;
    case LoginFailureCodesEnum.TOKEN_EXPIRED:
      title = 'Session Expired';
      infoText = getStringByKey(STRING_KEYS.AUTH_LOGIN_EXPIRED);
      break;
    default:
      // return null for non existent error codes
      return null;
  }

  return (
    <Alert
      isOpen={!!error ? true : false}
      primaryButtonText="Close"
      infoText={infoText}
      title={title}
      onClick={onClick} // clear user state to close alert and clear params
    />
  );
};

export default ErrorModal;
