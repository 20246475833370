import { css } from '@emotion/core';
import { BackgroundVideoEnum } from 'models/enums';

const styles = css`
  position: relative;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  opacity: 1;

  &.fade-out {
    opacity: 0;
    animation: fadeOut ease ${BackgroundVideoEnum.FADE_TIME}s;
  }

  .lottie {
    height: 100%;
    width: 100%;
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;

export default styles;
