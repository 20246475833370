/** @jsx jsx */
import { jsx } from '@emotion/core';

import type { NSAnnouncementComponentInterface } from 'models/cms';

import React from 'react';
import Announcement from './announcement';

export interface PropsInterface {
  component: NSAnnouncementComponentInterface;
}

const NsAnnouncementComponent: React.FC<PropsInterface> = (
  props: PropsInterface,
) => {
  return <Announcement {...props} />;
};

export default NsAnnouncementComponent;
