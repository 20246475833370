import request from 'superagent';
import type { RequestHeadersInterface } from 'store/slices/page';
import { isValidToken } from 'store/slices/page';
import * as endpoints from 'api/endpoints';

export const ENTITLEMENT_EXPIRED = 'ENTITLEMENT_EXPIRED';
export const CHECK_EXPIRED = 'CHECK_EXPIRED';

export const checkIsExpired = async (
  slug: string,
  accessToken: string,
): Promise<boolean> => {
  const url = ['/', '/index', '/index.html'].includes(slug) ? '/' : slug;
  try {
    const headers: RequestHeadersInterface = {
      'NS-Platform': 'WEB',
    };
    // only attach a valid token
    if (accessToken && isValidToken(accessToken)) {
      headers.Authorization = `Bearer ${accessToken}`;
    }
    const res = await request
      .get(endpoints.PAGES(url))
      .accept('json')
      .set(headers);
    return !!!res.body;
  } catch (err) {
    return true;
  }
};

// fix SR-7984 Chrome issue
export const changePageOpacity = (on: boolean, opacity: string): void => {
  if (on) {
    const mainPage = document.getElementById('main-page');
    if (!mainPage) {
      return;
    }
    mainPage.style.opacity = opacity;
  }
};
