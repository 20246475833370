import type { SettingsToggleButtonConfig } from 'bitmovin-player-ui/dist/js/framework/components/settingstogglebutton';
import { SettingsToggleButton } from 'bitmovin-player-ui/dist/js/framework/components/settingstogglebutton';
import { i18n } from 'bitmovin-player-ui/dist/js/framework/localization/i18n';

export class SubtitlesToggleButton extends SettingsToggleButton {
  constructor(config: SettingsToggleButtonConfig) {
    super({ settingsPanel: config.settingsPanel });

    if (!config.settingsPanel) {
      throw new Error('Required SettingsPanel is missing');
    }

    this.config = this.mergeConfig(
      config,
      /* @ts-ignore TODO: TS2352: Conversion of type { cssClass: string; text: Localizer; settingsPanel: null; autoHideWhenNoActiveSettings: true; role: string; ariaLabel: string; } to type SettingsToggleButtonConfig may be a mistake because neither type sufficiently overlaps with the other. If this was intentional, convert the expression to unknown first. */
      {
        cssClass: 'ui-subtitlestogglebutton',
        text: i18n.getLocalizer('settings.subtitles'),
        settingsPanel: null,
        autoHideWhenNoActiveSettings: true,
        role: 'pop-up button',
        ariaLabel: 'Subtitles',
      } as SettingsToggleButtonConfig,
      <SettingsToggleButtonConfig>this.config,
    );

    const el = this.getDomElement();
    el.attr('aria-label', 'Languages');
    el.removeClass('bmpui-ui-settingstogglebutton');
    el.addClass('bmpui-ui-subtitlestogglebutton');
    el.find('.bmpui-label').html('Languages');
  }
}
