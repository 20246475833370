import type { EntitlementInterface } from 'models/components/entitlement-component-interface';
import dayjs from 'dayjs';

interface ExpiryInfoInterface {
  show: boolean;
  text: string;
  warning: boolean;
}

export const hasExpiry = (entitlement: EntitlementInterface): boolean => {
  return Boolean(
    entitlement?.maxPlays || entitlement?.playsRemaining || entitlement?.endAt,
  );
};

export const generatePlayCountInfo = (
  entitlement: EntitlementInterface,
): ExpiryInfoInterface => {
  const { playsRemaining, maxPlays } = entitlement;
  const show = !!playsRemaining || !!maxPlays;
  let text =
    maxPlays === 1 ? `${maxPlays} play left` : `${maxPlays} plays left`;

  if (!!playsRemaining) {
    text =
      playsRemaining === 1 ?
        `${playsRemaining} play left`
      : `${playsRemaining} plays left`;
  }

  const warning = playsRemaining === 1 || maxPlays === 1;

  return { show, text, warning };
};

export const generateDateInfo = (
  entitlement: EntitlementInterface,
): ExpiryInfoInterface => {
  const { startAt, endAt } = entitlement;
  const show = !!endAt;
  const date = dayjs(endAt);
  // Wednesday, Nov 17, 2021 12:01 AM
  const text = `Expires ${date.format('MMM D, YYYY')} ${date.format('h:mm A')}`;

  // warning logic
  let warning = false;
  const endAtDate = dayjs(endAt);
  const startDate = dayjs(startAt);
  const nowDate = dayjs();
  const hoursBetweenEndAndStart = endAtDate.diff(startDate, 'hour');
  const hoursBetweenNowAndEnd = endAtDate.diff(nowDate, 'hour');

  if (!startAt && hoursBetweenNowAndEnd <= 24) {
    warning = true;
  }

  if (
    !!hoursBetweenEndAndStart &&
    hoursBetweenNowAndEnd / hoursBetweenEndAndStart <= 0.25
  ) {
    warning = true;
  }

  return { show, text, warning };
};
