import { css } from '@emotion/core';
import { FYC_MOBILE_WIDTH } from 'pages/fyc-page/helpers';
import { mediaQuery } from 'utils/styles';

export const headerWrapperCss = css`
  cursor: pointer;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${38 / 16}rem;
  background: rgba(69, 70, 81, 0.45);
  border-radius: 3px;
  padding: 0 8px;

  .title {
    text-transform: uppercase;
    font-size: 12px;
  }

  .subtitle {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    color: rgba(104, 104, 105, 1);
  }

  ${mediaQuery(FYC_MOBILE_WIDTH)} {
    padding: 0 1rem;
    height: ${44 / 16}rem;

    .title {
      font-size: 14px;
    }

    .subtitle {
      font-size: 14px;
    }
  }
`;

export const rightSectionCss = css`
  display: flex;
  gap: 1rem;
`;

export const imgWrapperCss = (close: boolean) => {
  return css`
    ${close && 'transform: rotate(-90deg);'}
  `;
};
