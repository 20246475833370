import { css } from '@emotion/core';
import theme from 'styles/theme';

export default css`
  display: flex;
  position: relative;
  width: 100%;
  min-height: 76px;
  background: #fad4d3;
  border-radius: 4px;
  border-left: 4px solid #e4615d;

  a {
    position: relative;
    z-index: ${theme.zIndex.tooltip};
    color: blue;
  }

  .toast- {
    &icon,
    &copy-container {
      margin: 0.5rem 1rem;
    }

    &icon {
      width: 30px;
      height: 30px;
      margin-left: 1.5rem;
    }

    &copy-container {
      max-width: 885px;
    }

    &body,
    &link,
    &body--end {
      display: inline;
    }
  }
`;
