import { TABLET_SMALL } from './../../../details-banner/helpers';
import { css } from '@emotion/core';
import { mediaQuery } from 'utils/styles';

export const wrapperCss = css`
  display: flex;
  width: 100%;
  margin: 0 auto;
  flex-wrap: wrap;

  margin-bottom: ${48 / 16}rem;

  ${mediaQuery(TABLET_SMALL)} {
    width: ${(735 / 895) * 100}%;
  }
`;

export const rowCss = css`
  width: 50%;
`;

export const itemCss = css`
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  margin-bottom: 2px;
  font-weight: 500;
`;

export const titleCss = css`
  font-size: 16px;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.65);
  text-transform: uppercase;
  margin-bottom: 4px;
  font-weight: 400;
`;
