const DISMISSED = 'dismissed-announcements';

export const saveDismissedAnnouncement = (id: string): void => {
  const dismissed = localStorage.getItem(DISMISSED);
  const dismissedArray = dismissed ? JSON.parse(dismissed) : [];
  if (dismissedArray.indexOf(id) === -1) {
    dismissedArray.push(id);
  }
  localStorage.setItem(DISMISSED, JSON.stringify(dismissedArray));
};

export const isDismissedAnnouncement = (id: string): boolean => {
  const dismissed = localStorage.getItem(DISMISSED);
  const dismissedArray = dismissed ? JSON.parse(dismissed) : [];
  return dismissedArray.indexOf(id) !== -1;
};

// delete when user logs in/out and token expires
export const deleteAllDismissedAnnouncement = (): void => {
  localStorage.removeItem(DISMISSED);
};
