import type { FunctionComponent } from 'react';
import React, { useEffect } from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router-dom';

interface PropsInterface extends RouteComponentProps {
  children: any;
}

const ScrollToTop: FunctionComponent<PropsInterface> = (
  props: PropsInterface,
) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [props.location.pathname]);
  return props.children;
};

export default withRouter(ScrollToTop);
