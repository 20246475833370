/** @jsx jsx */
/** @jsxFrag */
import type NSEntitlementInterface from 'models/cms/ns-entitlement-interface';
import { css, jsx } from '@emotion/core';
import React, { useState, useEffect, useMemo } from 'react';
import { WatermarkTypeEnum } from 'models/enums';
import NsImage from 'components/ns-component/ns-image';
import { getRandomInt } from 'utils';
import {
  logoContainerCss,
  topRightCss,
  bottomRightCss,
  bottomLeftCss,
  topLeftCss,
  logoTextCss,
  logoCss,
  logoImageCss,
  watermarkVisiblityCss,
  wmContainerCss,
  textFitCss,
  rotateCss,
} from './styles';
import clsx from 'clsx';
import WatermarkContainer from './watermark-container';
import WatermarkTextFit from './watermark-text-fit';
import theme from 'styles/theme';

interface PropsInterface {
  entitlement: NSEntitlementInterface | null | undefined;
  meta?: {
    currentTime?: number | null;
  };
  playData?: {
    runtime: number | null | undefined;
  };
}

const Watermark: React.FC<PropsInterface> = ({
  entitlement,
  meta,
  playData,
}) => {
  const [position, setPosition] = useState<number>(0);

  const isExecutive = useMemo(() => {
    return {
      nameDateUpperRight:
        entitlement?.watermarkType === WatermarkTypeEnum.NAME_DATE_UPPER_RIGHT,
    };
  }, [entitlement]);

  const isLogo = useMemo(() => {
    return {
      rotatingPersistent:
        entitlement?.watermarkType === WatermarkTypeEnum.LOGO_CORNERS,
      stationaryPersistent:
        entitlement?.watermarkType === WatermarkTypeEnum.LOGO_STATIONARY,
      rotatingTemporary:
        entitlement?.watermarkType === WatermarkTypeEnum.LOGO_CORNERS_TEMPORARY,
      stationaryTemporary:
        entitlement?.watermarkType ===
        WatermarkTypeEnum.LOGO_STATIONARY_TEMPORARY,
    };
  }, [entitlement]);

  const isUserId = useMemo(() => {
    return {
      persistent:
        entitlement?.watermarkType === WatermarkTypeEnum.USERID_CENTERED,
      temporary:
        entitlement?.watermarkType ===
        WatermarkTypeEnum.USERID_CENTERED_TEMPORARY,
      stroke:
        entitlement?.watermarkType === WatermarkTypeEnum.USERID_CENTERED_STROKE,
      temporaryStroke:
        entitlement?.watermarkType ===
        WatermarkTypeEnum.USERID_CENTERED_TEMPORARY_STROKE,
    };
  }, [entitlement]);

  const isUserIdLower = useMemo(() => {
    return {
      persistent: entitlement?.watermarkType === WatermarkTypeEnum.USERID_LOWER,
      temporary:
        entitlement?.watermarkType === WatermarkTypeEnum.USERID_LOWER_TEMPORARY,
      stroke:
        entitlement?.watermarkType === WatermarkTypeEnum.USERID_LOWER_STROKE,
      temporaryStroke:
        entitlement?.watermarkType ===
        WatermarkTypeEnum.USERID_LOWER_TEMPORARY_STROKE,
    };
  }, [entitlement]);

  const isUserIdCorner = useMemo(() => {
    return {
      stationaryPersistent:
        entitlement?.watermarkType === WatermarkTypeEnum.USERID_CORNER,
      rotatingPersistent:
        entitlement?.watermarkType === WatermarkTypeEnum.USERID_CORNER_ROTATING,
      stationaryTemporary:
        entitlement?.watermarkType ===
        WatermarkTypeEnum.USERID_CORNER_TEMPORARY,
      rotatingTemporary:
        entitlement?.watermarkType ===
        WatermarkTypeEnum.USERID_CORNER_ROTATING_TEMPORARY,
    };
  }, [entitlement]);

  // corner watermarks, with selected corner position
  const isUserIdCornerPositioned = useMemo(() => {
    return {
      // stationary
      lowerRight:
        entitlement?.watermarkType ===
        WatermarkTypeEnum.USERID_CORNER_LOWER_RIGHT,
      lowerLeft:
        entitlement?.watermarkType ===
        WatermarkTypeEnum.USERID_CORNER_LOWER_LEFT,
      upperRight:
        entitlement?.watermarkType ===
        WatermarkTypeEnum.USERID_CORNER_UPPER_RIGHT,
      upperLeft:
        entitlement?.watermarkType ===
        WatermarkTypeEnum.USERID_CORNER_UPPER_LEFT,
      // temp
      lowerRightTemporary:
        entitlement?.watermarkType ===
        WatermarkTypeEnum.USERID_CORNER_LOWER_RIGHT_TEMPORARY,
      lowerLeftTemporary:
        entitlement?.watermarkType ===
        WatermarkTypeEnum.USERID_CORNER_LOWER_LEFT_TEMPORARY,
      upperRightTemporary:
        entitlement?.watermarkType ===
        WatermarkTypeEnum.USERID_CORNER_UPPER_RIGHT_TEMPORARY,
      upperLeftTemporary:
        entitlement?.watermarkType ===
        WatermarkTypeEnum.USERID_CORNER_UPPER_LEFT_TEMPORARY,
    };
  }, [entitlement]);

  /**
   * Calculates the visibility of the watermarks based on the current runtime
   *
   * @returns true or false
   */
  const calculateVisibility = () => {
    if (
      isLogo.rotatingTemporary ||
      isLogo.stationaryTemporary ||
      isUserIdCorner.rotatingTemporary ||
      isUserIdCorner.stationaryTemporary ||
      isUserId.temporary ||
      isUserId.temporaryStroke ||
      isUserIdLower.temporary ||
      isUserIdLower.temporaryStroke ||
      isUserIdCornerPositioned.lowerRightTemporary ||
      isUserIdCornerPositioned.lowerLeftTemporary ||
      isUserIdCornerPositioned.upperRightTemporary ||
      isUserIdCornerPositioned.upperLeftTemporary
    ) {
      const runtimeInSeconds = Math.floor(playData?.runtime ?? 0);
      const currentTime = Math.floor(meta?.currentTime ?? 0);
      const firstWm = Math.floor(runtimeInSeconds * 0.05);
      const secondWm = Math.floor(runtimeInSeconds * 0.6);
      const durationInSeconds = 8;

      // toggle first watermark 5% into the movie and second at 60%
      if (
        // if the current time is between the calculated time and calculated time + 8 seconds
        (currentTime >= firstWm &&
          currentTime <= firstWm + durationInSeconds) ||
        (currentTime >= secondWm && currentTime <= secondWm + durationInSeconds)
      ) {
        return true;
      }
    }

    return false;
  };

  const isVisible = calculateVisibility();

  /**
   * Set position based on which corner watermark is selected
   * 0 - bottom right
   * 1 - bottom left
   * 2 - upper left
   * 3 - upper right
   *
   * @returns number value
   */
  const selectedPosition = useMemo(() => {
    if (
      isUserIdCornerPositioned.lowerLeft ||
      isUserIdCornerPositioned.lowerLeftTemporary
    ) {
      return 1;
    }

    if (
      isUserIdCornerPositioned.upperLeft ||
      isUserIdCornerPositioned.upperLeftTemporary
    ) {
      return 2;
    }

    if (
      isUserIdCornerPositioned.upperRight ||
      isUserIdCornerPositioned.upperRightTemporary
    ) {
      return 3;
    }

    return 0;
  }, [isUserIdCornerPositioned]);

  // animate logo watermark
  useEffect(() => {
    if (
      isLogo.rotatingPersistent ||
      isLogo.rotatingTemporary ||
      isUserIdCorner.rotatingPersistent ||
      isUserIdCorner.rotatingTemporary
    ) {
      const tenSeconds = 10000;
      const twoMinutes = 120000;

      const intervalID = setInterval(
        () => {
          setPosition((prevState) => {
            if (prevState === 3) {
              return 0;
            }

            return prevState + 1;
          });
          // randomize between 10s to 2mins
        },
        getRandomInt(tenSeconds, twoMinutes),
      );

      return () => {
        clearInterval(intervalID);
      };
    }

    setPosition(selectedPosition);
  }, [isLogo, isUserIdCorner, selectedPosition]);

  const logoStyles = useMemo(() => {
    const styles = [logoContainerCss];

    switch (position) {
      case 0:
        styles.push(bottomRightCss);
        return styles;
      case 1:
        styles.push(bottomLeftCss);
        return styles;
      case 2:
        styles.push(topLeftCss);
        return styles;
      case 3:
        styles.push(topRightCss);
        return styles;
      default:
        return styles;
    }
  }, [position]);

  // to see which styling to apply
  const userIdCornerStyles = useMemo(() => {
    switch (position) {
      case 1:
        return 'userIdCornerBottomLeftCss';
      case 2:
        return 'userIdCornerTopLeftCss';
      case 3:
        return 'userIdCornerTopRightCss';
      case 0:
      default:
        return 'userIdCornerBottomRightCss';
    }
  }, [position]);

  if (!entitlement) {
    return null;
  }

  /* user id diagonal with mini */
  if (
    entitlement.watermarkType ===
    WatermarkTypeEnum.USERID_DIAGONAL_ONE_MINI_WHITESTROKE
  ) {
    return (
      <div
        className={clsx(['watermark-container'])}
        css={[wmContainerCss, rotateCss()]}
      >
        <WatermarkContainer classNames="watermark-container--textfit-mini watermark-container--diagonal-mini">
          <WatermarkTextFit
            className="diagonal-userid--mini"
            center
          >
            {entitlement.watermarkText}
          </WatermarkTextFit>
        </WatermarkContainer>
        <WatermarkContainer classNames="watermark-container--textfit watermark-container--diagonal">
          <WatermarkTextFit
            className="diagonal-userid"
            center
          >
            {entitlement.watermarkText}
          </WatermarkTextFit>
        </WatermarkContainer>
      </div>
    );
  }

  // user id lower 3rd watermark
  if (
    isUserIdLower.stroke ||
    isUserIdLower.temporaryStroke ||
    isUserIdLower.persistent ||
    isUserIdLower.temporary
  ) {
    const watermarkVisibility =
      (isUserIdLower.temporary || isUserIdLower.temporaryStroke) &&
      watermarkVisiblityCss(isVisible);

    const serializedCss = [textFitCss];

    if (watermarkVisibility) {
      serializedCss.push(watermarkVisibility);
    }

    if (!entitlement.watermarkText) {
      return null;
    }

    return (
      <WatermarkContainer
        classNames="watermark-container--textfit lower-third"
        css={wmContainerCss}
      >
        <WatermarkTextFit
          className={clsx([
            'watermark-text',
            (isUserIdLower.stroke || isUserIdLower.temporaryStroke) &&
              'stroke-black',
          ])}
          css={serializedCss}
        >
          {entitlement.watermarkText}
        </WatermarkTextFit>
      </WatermarkContainer>
    );
  }

  // user id corner watermark persistent & rotating watermark
  if (
    isUserIdCorner.stationaryPersistent ||
    isUserIdCorner.stationaryTemporary ||
    isUserIdCorner.rotatingPersistent ||
    isUserIdCorner.rotatingTemporary ||
    isUserIdCornerPositioned.lowerRight ||
    isUserIdCornerPositioned.lowerLeft ||
    isUserIdCornerPositioned.upperLeft ||
    isUserIdCornerPositioned.upperRight ||
    isUserIdCornerPositioned.lowerRightTemporary ||
    isUserIdCornerPositioned.lowerLeftTemporary ||
    isUserIdCornerPositioned.upperRightTemporary ||
    isUserIdCornerPositioned.upperLeftTemporary
  ) {
    const serializedCss = [textFitCss];
    const visibility = watermarkVisiblityCss(isVisible);

    if (
      (isUserIdCorner.stationaryTemporary ||
        isUserIdCorner.rotatingTemporary ||
        isUserIdCornerPositioned.lowerRightTemporary ||
        isUserIdCornerPositioned.lowerLeftTemporary ||
        isUserIdCornerPositioned.upperRightTemporary ||
        isUserIdCornerPositioned.upperLeftTemporary) &&
      visibility
    ) {
      serializedCss.push(visibility);
    }

    return (
      <WatermarkContainer
        classNames={`watermark-container--textfit ${userIdCornerStyles}`}
        css={wmContainerCss}
      >
        <WatermarkTextFit css={serializedCss}>
          {entitlement.watermarkText}
        </WatermarkTextFit>
      </WatermarkContainer>
    );
  }

  // executive watermark
  if (isExecutive.nameDateUpperRight) {
    return (
      <WatermarkContainer
        css={wmContainerCss}
        classNames="watermark-container--textfit nameDateTopRightCss"
      >
        <WatermarkTextFit
          css={css`
            opacity: 30%;
            font-weight: ${theme.typography.fontWeightMedium};
          `}
        >
          {entitlement.watermarkText}
        </WatermarkTextFit>
      </WatermarkContainer>
    );
  }

  // text watermark - user center
  if (
    isUserId.persistent ||
    isUserId.temporary ||
    isUserId.stroke ||
    isUserId.temporaryStroke
  ) {
    const visibility =
      (isUserId.temporary || isUserId.temporaryStroke) &&
      watermarkVisiblityCss(isVisible);

    const serializedCss = [textFitCss];

    if (visibility) {
      serializedCss.push(visibility);
    }

    const strokeBlack =
      (isUserId.stroke || isUserId.temporaryStroke) && 'stroke-black';

    return (
      <WatermarkContainer
        classNames="watermark-container--textfit"
        css={wmContainerCss}
      >
        <WatermarkTextFit
          className={clsx([strokeBlack])}
          css={serializedCss}
        >
          {entitlement.watermarkText}
        </WatermarkTextFit>
      </WatermarkContainer>
    );
  }

  // logo watermark
  if (
    (isLogo.rotatingPersistent ||
      isLogo.rotatingTemporary ||
      isLogo.stationaryPersistent ||
      isLogo.stationaryTemporary) &&
    entitlement.watermarkLogo
  ) {
    const { watermarkLogo, watermarkText } = entitlement;

    return (
      <div
        className="watermark-container"
        css={wmContainerCss}
      >
        <div
          data-testid="watermark-logo"
          css={[
            logoStyles,
            (isLogo.stationaryTemporary || isLogo.rotatingTemporary) &&
              watermarkVisiblityCss(isVisible),
          ]}
        >
          {/* curved text around logo */}
          <svg
            data-testid="watermark-svg"
            viewBox="0 0 200 200"
            css={logoTextCss}
          >
            <path
              id="curve"
              d="M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0"
              fill="none"
              transform="translate(200),scale(-1,1)"
            />
            <text width="200">
              <textPath
                alignmentBaseline="auto"
                xlinkHref="#curve"
              >
                {watermarkText}
              </textPath>
            </text>
          </svg>
          <NsImage
            alt={watermarkLogo.alt}
            aspectRatio={watermarkLogo.aspectRatioFractions[0]}
            url={watermarkLogo.url}
            css={logoCss}
            styles={{
              nsImageCss: logoImageCss,
            }}
          />
        </div>
      </div>
    );
  }

  return null;
};

export default Watermark;
