import type { SerializedStyles } from '@emotion/core';
import { css } from '@emotion/core';
import { generateStyle, mediaQuery } from 'utils/styles';
import theme from 'styles/theme';

export const containerCss = (styles?: SerializedStyles) => {
  const defaultStyle = `
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  z-index: ${theme.zIndex.alert};
  background: ${theme.palette.background.secondary};
  padding: 1.5rem 1rem;
  border: 1px solid ${theme.palette.border.primary};
  border-radius: 8px;
  width: 75%;
  ${mediaQuery(theme.breakpoints.md)} {
    width: 425px;
  }
`;

  return generateStyle({
    defaultStyle,
    extendedStyles: styles,
    key: 'containerCss',
    theme,
  });
};

export const outerCss = (styles?: SerializedStyles, zIndex = 1) => {
  const defaultStyle = `
  visibility: hidden;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  transition: visibility 0.2s, opacity 0.2s linear;
  z-index: ${zIndex};
`;

  return generateStyle({
    defaultStyle,
    extendedStyles: styles,
    key: 'outerCss',
    theme,
  });
};

export const hidden = css`
  visibility: visible;
  opacity: 1;
`;

export const infoTextCss = css`
  text-align: center;
  margin: 1rem 0;
  max-width: 20rem;
`;

export const secondaryButtonCss = css`
  margin-top: 1rem;
`;

export const titleCss = css`
  text-align: center;
  font-size: 1.25rem;
`;
